<template>
    <section class="row">
        <!-- 220901 meej isListPage 일때 목록칸, 입력칸 너비 조정 -->
        <i-list
            :class="[isListPage ? 'col-md-8' : 'col-md-4']"
            :searchDate="searchDate"
        />
        <!-- <i-resource
            v-show="showResource"
            :initMenu="resType"
            class="col-12 col-md-4 q-pa-md"
        /> -->
        <i-content
            class="col-12 q-pa-md"
            :class="[isListPage ? 'col-md-4' : 'col-md-8']"
            @event-input-date="handleInputDate"
        />
    </section>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import debug from '@/debug'
import ScheduleList from '@/components/schedule/List.vue'
import ScheduleContent from '@/components/schedule/Content.vue'
//import Resource from '@/components/common/Resource.vue'
export default {
    name: 'Schedule',
    components: {
        'i-list': ScheduleList,
        'i-content': ScheduleContent,
        //        'i-resource' : Resource
    },
    data() {
        return {
            searchDate: '',
        }
    },
    created() {
        this.setPageStatus(this.PAGE_STATUS.LIST)
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            request: (state) => state.resource.request,
        }),
        ...mapGetters({
            isListPage: 'page/isListPage',
            isEditPage: 'page/isEditPage',
            isRegisterPage: 'page/isRegisterPage',
        }),
        // showResource () {
        //     return this.isRegisterPage || (this.isEditPage && (this.request !== null))
        // }
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
        }),
        handleInputDate(date) {
            debug.log('handleInputDate , ', date, 'page = ', this.isListPage)
            if (!this.isListPage) {
                this.searchDate = date
            }
        },
    },
}
</script>

<style>
/* common */

/* xs */
@media only screen and (max-width: 599px) {
}

/* sm */
@media only screen and (max-width: 1023px) {
}

/* md */
@media only screen and (max-width: 1439px) {
}

/* lg */
@media only screen and (max-width: 1919px) {
}

/* xl */
@media only screen and (min-width: 1920px) {
}

.q-table__middle {
    max-width: 100%;
    overflow-y:scroll !important;
    overflow-x:hidden !important;
}
</style>
