<template>
    <article>
        <div class="flex justify-end q-pa-xs"><i-btn label="취소" @click="getContentClose()"/></div>
        <q-card>
            <q-card-section>
                <div class="col q-gutter-sm">
                    <div class="row q-my-sm">
                        <div class="col-12 col-sm-6">
                            <i-select v-model="client" inputLabel="제휴언론사" :options="itemList" option-label="trnsmisNm" option-value="trnsmisId" />
                        </div>
                    </div>
                    <div class="row q-my-sm">
                        <div class="col-12 col-sm-6">
                            <i-datetime date v-model="item.sendDate" dateLabel="전송날짜" />
                        </div>
                        <div class="col-12 col-sm-6">
                            <i-datetime time v-model="item.sendTime" timeLabel="전송시간" />
                        </div>
                    </div>
                    <div class="row q-my-sm">
                        <div class="col-12 col-sm-6 q-pa-xs">
                            <i-stock-search @get="getStockAdd" label="종목검색" v-model="stock"/>
                        </div>
                    </div>
                    <div class="row q-my-sm">
                        <div class="col-12 col-sm-12">
                            <div class="flex justify-start" style="marginLeft:30px; min-height: 30px;">
                                <q-chip
                                    v-for="(item, index) in selectedStocks"
                                    :key="'stock' + index"
                                    outline
                                    square
                                    color="black"
                                    text-color="white"
                                    size="sm"
                                    removable
                                    @remove="getStockRemove(index)"
                                >
                                    {{ item.name }} ( {{ item.code }} )
                                </q-chip>
                            </div>
                        </div>
                    </div>
                    <div class="row q-my-sm">
                        <div class="col-12 col-sm-12">
                            <i-input v-model="item.title" label="제목" />
                        </div>
                    </div>
                    <div class="row q-my-sm">
                        <div class="col-12">
                            <i-editor v-model="item.content" @input-html="inputcontent"/>
                        </div>
                    </div>
                </div>
            </q-card-section>
        </q-card>
        <!-- 하단 버튼 -->
        <div class="q-my-xs">
            <i-btn v-show="item.id == null" class="full-width" label="등록" @click="getSave" />
            <i-btn v-show="item.id != null" class="full-width" label="수정" @click="getSave" />
        </div>
    </article>
</template>
<script>
import Select from '@/components/common/Select.vue';
import StockSearch from "@/components/common/StockSearch";
import Button from "@/components/common/Button";
import Editor from "@/components/common/Editor";
import Input from "@/components/common/Input";
import DateTime from "@/components/common/DateTime"
import { stringToMapValue } from '@/util'
import { mapState, mapMutations, mapActions } from "vuex";

export default {
    name: "Content",
    components: {
        'i-select' : Select,
        "i-input": Input,
        "i-stock-search": StockSearch,
        "i-datetime": DateTime,
        "i-btn": Button,
        "i-editor": Editor
    },
    data() {
        return {
            selectedStocks: [],
            client:'',
            stock:null
        };
    },
    watch: {
        // 종목 개수 제한하는 watch
        selectedStocks() {
            let message;
            if (this.selectedStocks.length > 5) {
                message = "5개 까지만 선택이 가능합니다.?ㄴ";
                this.showMessage({ vc: this, message: message });
                this.selectedStocks.pop();
            }
        },
    },
    computed: {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            stockItems : state => state.resource.stockItems
        }),
        ...mapState('manage', ['item', 'list', 'itemList']),
    },
    methods: {
        ...mapMutations('manage', ['INIT_ITEM', 'SET_ITEM', 'SET_LIST']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        //종목추가
        getStockAdd(stock) {
            // 중복종목 판별 후, 입력
            let stockNames = [];
            for (const item of this.selectedStocks) {
                stockNames.push(item.name);
            }
            const include = stockNames.includes(stock.name);
            if (include) {
                this.showError({ vc: this, error: '같은 종목이 포함되어 있습니다.' })
            } else {
                this.selectedStocks.push(stock)
            }
        },
        //종목삭제
        getStockRemove(index) {
            this.selectedStocks.splice(index, 1);
        },
        getContentClose(){
            this.$parent.getContentClose('');
        },
        //저장
        getSave() {
            if (this.client.trnsmisId == null) {
                this.showMessage({ vc:this, message:'제휴언론사를 선택하세요.' });
                return;
            }
            if (this.item.sendTime == "00") {
                this.showMessage({ vc:this, message:'전송시간을 입력하세요.' });
                return;
            }
            // if (this.selectedStocks.length == 0) {
            //     this.showMessage({ vc:this, message:'종목을 선택하세요.' });
            //     return;
            // }
            if (!this.item.title) {
                this.showMessage({ vc:this, message:'제목을 입력하세요.' });
                return;
            }
            if (!this.item.content) {
                this.showMessage({ vc:this, message:'내용을 입력하세요.' });
                return;
            }
            this.showAlert({ vc:this, message:'<span style="color:#1976D2;">저장</span> 하시겠습니까?' }).then(()=>{
                var sStocks = "";
                var iCnt = 1;
                this.selectedStocks.forEach(row => {
                    sStocks += "stocks" + (iCnt++) + ":" + row['code'] + ",";
                });
                let param = {
                    'id': this.item.id,
                    'client': this.client.trnsmisId,
                    'title': this.item.title,
                    'content': this.item.content,
                    'sendDate': this.item.sendDate,
                    'sendTime': this.item.sendTime.substring(0, 4),
                    'stocks': sStocks,
                    'CALL_API_URL': '/ir/manage/save'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'저장 되었습니다.' });
                    this.$parent.getContentClose('');
                    this.$parent.getList();
                }).catch((err)=>{
                    this.showError({ vc:this, error:err });
                });
            }).catch(() => { });
        },
        getTrnsmisNmChk(obj){
            this.selectedStocks = [];
            if(obj != null){
                var list = [];
                try {
                    list = JSON.parse(this.stockItems);
                } catch (e) {
                    list = this.stockItems;
                }
                var ds_stocks = [];
                for(var i = 1; i < 6; i++){
                    var sTmp = list.filter(function (entry){ return entry.code === stringToMapValue('stocks'+i, obj.stocks); })[0];
                    if(sTmp != null){
                        ds_stocks.push(sTmp);
                    }
                }
                this.selectedStocks = ds_stocks;
            }
            if(this.itemList.length == 2){
                this.client = this.itemList[1];
            }else{
                var sClient = this.$parent.trnsmisId.trnsmisId;
                this.client = this.itemList.filter(function (entry){ return entry.trnsmisId === sClient; })[0];
            }
        },
        inputcontent(val){
            // debug.log('inputcontent = ', val)
            this.item.content = val
        },          
    }
};

</script>
