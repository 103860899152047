<template>
    <section class="row">
        <q-toolbar class="row q-mt-md text-left">
            <i-datetime date v-model="openDate" label="입력일"  @input="getView()"/>
            <div class="q-pa-xs"><i-btn label="등록" @click="getSave()" v-show="bChkbtn == true"/> <q-btn label="수정" color="primary" @click="getSave()" v-show="bChkbtn == false"/></div>
            <q-space />
            <div class="q-pa-xs"><i-datetime date v-model="dateStr" label="입력일"/></div>
            <div class="q-pa-xs">~</div>
            <div class="q-pa-xs"><i-datetime date v-model="dateEnd" label="입력일"/></div>
            
            <div class="q-pa-xs"><i-btn icon="search" @click="getList()"/></div>

            
            

        </q-toolbar>
        <!-- <i-list class="col-12" ref="todayWorldList"/> -->
        <article>
            <q-table flat bordered dense separator="cell"  :class="{ 'no-data' : list.length == 0 }"
                :columns="columns" :data="list" row-key="RNUM" :rows-per-page-options="[0]" style="height:auto;"
                hide-pagination no-data-label="조회되는 데이터가 없습니다.">
                <template v-slot:header="props">
                    <!-- <q-tr :props="props">
                        <q-th v-for="col in props.cols" :key="col.name" :props="props">
                            <div v-if="(col.name == 'WTI' || col.name == 'BRENT_OIL' || col.name == 'GOLD' || col.name == 'SILVER' || col.name == 'COPPER') " style="width:150px;" class="row q-mx-auto">
                                <div class="q-pa-xs number"><q-input v-model="data[col.name]" outlined dense input-class="text-right" style="width:80px;" @keyup="getNumberChk(props.row, col.name)" @blur="getNumberChk(props.row, col.name)"/></div>
                                <div class="q-pa-xs number"><q-input v-model="data[col.name+'_WM']" outlined dense input-class="text-right" style="width:25px;" @keyup="getNumberChk(props.row, col.name)" @blur="getNumberChk(props.row, col.name)"/></div>
                                
                            </div>
                            <div v-else-if="col.name == 'openDate'" class="date"><i-datetime date v-model="data[col.name]" label="입력일" style="width:135px" @input="getView(props.row)"/></div>
                            <div v-else class="q-mx-auto number" style="display:table;"><q-input v-model="data[col.name]" outlined dense input-class="text-right" style="width:80px;" @keyup="getNumberChk(props.row, col.name)" @blur="getNumberChk(props.row, col.name)"/></div>
                        </q-th>
                    </q-tr> -->
                    <q-tr :props="props">
                        <q-th v-for="col in props.cols" :key="col.name" :props="props" v-html="col.label" :style="textColor(col.name)"></q-th>
                    </q-tr>
                </template>
                
                <template v-slot:body="props">
                    <q-tr :props="props" :style="(props.row.rowAt==='Y'?'background:#f5ebf5':'background:white')">
                        <q-td v-for="col in props.cols" :key="col.name" :props="props">
                            <div v-if="(col.name == 'WTI' || col.name == 'BRENT_OIL' || col.name == 'GOLD' || col.name == 'SILVER' || col.name == 'COPPER') && props.row.rowAt == 'Y'" :style="(col.name == 'GOLD' || col.name == 'SILVER' || col.name == 'COPPER')?'width:80px':'width:90px' " class="row ">
                                
                                <div class="number"><q-input v-model="props.row[col.name]" outlined dense input-class="text-right" style="background:white" :style="(col.name == 'GOLD' || col.name == 'SILVER' || col.name == 'COPPER')?'width:50px;':'width:60px;'" @keyup="getNumberChk(props.row, col.name)" @blur="getNumberChk(props.row, col.name)"/></div>
                                <div class="number q-ml-xs"><q-input v-model="props.row[col.name+'_WM']" outlined dense input-class="text-right" style="width:25px;background:white" @keyup="getNumberChk(props.row, col.name)" @blur="getNumberChk(props.row, col.name)"/></div>
                            </div> 
                            <div v-else-if="col.name == 'WTI' || col.name == 'BRENT_OIL' || col.name == 'GOLD' || col.name == 'SILVER' || col.name == 'COPPER'">
                            <!-- <div v-if="col.name == 'WTI' || col.name == 'BRENT_OIL' || col.name == 'GOLD' || col.name == 'SILVER' || col.name == 'COPPER'"> -->
                                {{props.row[col.name]}} ({{props.row[col.name+'_WM']}})
                            </div>
                            
                            <div v-else-if="col.name == 'openDate' && props.row.rowAt != 'Y'">{{ col.value }}</div> 
                            <div v-else-if="col.name == 'openDate' && props.row.rowAt == 'Y'"  style="width:200px; ">
                                <b> {{ formatOpenDate }} </b>
                                <!-- <i-datetime date v-model="props.row[col.name]" label="입력일" style="width:80px" input-class="text-right" @input="getView(props.row)"/> -->
                                </div>
                            <div v-else-if="props.row.rowAt == 'Y'" class="q-mx-auto number" style="display:table;"><q-input v-model="props.row[col.name]" outlined dense   input-class="text-right"    :style="(col.name == 'CALL_D1' || col.name == 'ENTRPRS_BIL_M3' || col.name == 'NTPBND_Y10')?'width:30px;':'width:50px;'"   style="background:white" @keyup="getNumberChk(props.row, col.name)" @blur="getNumberChk(props.row, col.name)"/></div>
                            <div v-else>{{ col.value }}</div>
                        </q-td>
                    </q-tr>
                </template>
                <template v-slot:no-data="{ icon, message }"><div class="full-width row flex-center q-gutter-sm"><q-icon size="2em" :name="icon" /> <span>{{message}}</span></div></template>
            </q-table>

            <q-table flat bordered dense separator="cell"  :class="{ 'no-data' : list.length == 0 }"
                :columns="columns2" :data="list" row-key="RNUM" :rows-per-page-options="[0]" style="height:auto; width:60%;"
                hide-pagination no-data-label="조회되는 데이터가 없습니다.">
                <template v-slot:header="props">
                    <!-- <q-tr :props="props">
                        <q-th v-for="col in props.cols" :key="col.name" :props="props">
                            <div v-if="(col.name == 'WTI' || col.name == 'BRENT_OIL' || col.name == 'GOLD' || col.name == 'SILVER' || col.name == 'COPPER') " style="width:150px;" class="row q-mx-auto">
                                <div class="q-pa-xs number"><q-input v-model="data[col.name]" outlined dense input-class="text-right" style="width:80px;" @keyup="getNumberChk(props.row, col.name)" @blur="getNumberChk(props.row, col.name)"/></div>
                                <div class="q-pa-xs number"><q-input v-model="data[col.name+'_WM']" outlined dense input-class="text-right" style="width:25px;" @keyup="getNumberChk(props.row, col.name)" @blur="getNumberChk(props.row, col.name)"/></div>
                                
                            </div>
                            <div v-else-if="col.name == 'openDate'" class="date"><i-datetime date v-model="data[col.name]" label="입력일" style="width:135px" @input="getView(props.row)"/></div>
                            <div v-else class="q-mx-auto number" style="display:table;"><q-input v-model="data[col.name]" outlined dense input-class="text-right" style="width:80px;" @keyup="getNumberChk(props.row, col.name)" @blur="getNumberChk(props.row, col.name)"/></div>
                        </q-th>
                    </q-tr> -->
                    <q-tr :props="props">
                        <q-th v-for="col in props.cols" :key="col.name" :props="props" v-html="col.label" :style="textColor(col.name)"></q-th>
                    </q-tr>
                </template>
                
                <template v-slot:body="props">
                    <q-tr :props="props" :style="(props.row.rowAt==='Y'?'background:#f5ebf5':'background:white')">
                        <q-td v-for="col in props.cols" :key="col.name" :props="props">
                            <div v-if="col.name == 'openDate' && props.row.rowAt == 'Y'" style="width:200px;">
                                <b>{{ formatOpenDate }}</b>
                                <!-- <i-datetime date v-model="props.row[col.name]" label="입력일" style="width:80px" input-class="text-right" @input="getView(props.row)"/> -->
                            </div>
                            <div v-else-if="props.row.rowAt == 'Y'" class="q-mx-auto number" style="display:table;"><q-input v-model="props.row[col.name]" outlined dense   input-class="text-right"    :style="(col.name == 'CALL_D1' || col.name == 'ENTRPRS_BIL_M3' || col.name == 'NTPBND_Y10')?'width:30px;':'width:50px;'"   style="background:white" @keyup="getNumberChk(props.row, col.name)" @blur="getNumberChk(props.row, col.name)"/></div>
                            <div v-else>{{ col.value }}</div>
                        </q-td>
                    </q-tr>
                </template>
            <template v-slot:no-data="{ icon, message }"><div class="full-width row flex-center q-gutter-sm"><q-icon size="2em" :name="icon" /> <span>{{message}}</span></div></template>
        </q-table>
    </article>
    </section>
</template>
<script>
import Button from '@/components/common/Button';
import DateTime from '@/components/common/DateTime';
//import List from "@/components/newspaper/todayWorld/List.vue";
import { FormatDate, numberFormat } from '@/util'
import { mapState, mapMutations, mapActions } from 'vuex'
import debug from '@/debug'

export default {
    name: "TodayWorld",
    components: {
        "i-btn" :  Button,
        'i-datetime': DateTime,
        //"i-list": List
    },
    data() {
        var now = new Date();
        var dEnd = now.getFullYear() + '' + ((now.getMonth()+1)>9 ? ''+(now.getMonth()+1) : '0'+(now.getMonth()+1)) + '' + (now.getDate()>9 ? ''+now.getDate() : '0'+now.getDate());
        now.setDate(now.getDate()-1);
        var dStr = now.getFullYear() + '' + ((now.getMonth()+1)>9 ? ''+(now.getMonth()+1) : '0'+(now.getMonth()+1)) + '' + (now.getDate()>9 ? ''+now.getDate() : '0'+now.getDate());
        return {
            bChkbtn: true,
            
            dateStr: dStr,
            dateEnd: dEnd,
            itemList: [],
            columns: [
                { headerStyle:'text-align:center;', name: 'openDate', label: '날짜', align: 'center', field: (row) => FormatDate(row.openDate)},
                { headerStyle:'text-align:center;', name: 'WTI', label: 'WTI</br>,월물 ($)', align: 'right', field: (row) => numberFormat(row.WTI)},
                { headerStyle:'text-align:center;', name: 'BRENT_OIL',  label: '브렌트유</br>,월물 ($)', align: 'right', field: (row) => numberFormat(row.BRENT_OIL)},
                { headerStyle:'text-align:center;', name: 'GOLD',  label: '금</br>,월물 ($)', align: 'right', field: (row) => numberFormat(row.GOLD)},
                { headerStyle:'text-align:center;', name: 'SILVER',  label: '은</br>,월물 ($)', align: 'right', field: (row) => numberFormat(row.SILVER)},
                { headerStyle:'text-align:center;', name: 'COPPER',  label: '동</br>,월물 ($)', align: 'right', field: (row) => numberFormat(row.COPPER)},
                { headerStyle:'text-align:center;', name: 'DDR3_4G',  label: 'DDR4</br>16G</br>($)', align: 'right', field: (row) => numberFormat(row.DDR3_4G)},
                { headerStyle:'text-align:center;', name: 'DDR3_2G',  label: 'DDR4</br>8G</br>($)', align: 'right', field: (row) => numberFormat(row.DDR3_2G)},
                { headerStyle:'text-align:center;', name: 'NAND_64G',  label: 'Nand</br>64G</br>($)', align: 'right', field: (row) => numberFormat(row.NAND_64G)},
                { headerStyle:'text-align:center;', name: 'JPY_USD',  label: 'Yen</br>/ Dollar</br>(￥)', align: 'right', field: (row) => numberFormat(row.JPY_USD)},
                { headerStyle:'text-align:center;', name: 'EUR_USD',  label: 'Euro</br>/ Dollar</br>(E)', align: 'right', field: (row) => numberFormat(row.EUR_USD)},
                { headerStyle:'text-align:center;', name: 'KRW_EUR',  label: '원</br>/ 유로</br>(₩)', align: 'right', field: (row) => numberFormat(row.KRW_EUR)},
                { headerStyle:'text-align:center;', name: 'KRW_JPY',  label: '원</br>/ 엔</br>(₩)', align: 'right', field: (row) => numberFormat(row.KRW_JPY)},
                { headerStyle:'text-align:center;', name: 'KRW_CNY',  label: '원</br>/ 위안</br>(₩)', align: 'right', field: (row) => numberFormat(row.KRW_CNY)},
                { headerStyle:'text-align:center;', name: 'US_NTPBND_Y10',  label: '美국채선물</br>/ (10년)</br>(%)', align: 'right', field: (row) => numberFormat(row.US_NTPBND_Y10)},
                { headerStyle:'text-align:center;', name: 'US_NTPBND_Y2',  label: '美국채선물</br>/ (2년)</br>(%)', align: 'right', field: (row) => numberFormat(row.US_NTPBND_Y2)},
                { headerStyle:'text-align:center;', name: 'KOSPI200_NIGHT_FUTURES',  label: 'KOSPI200</br>야간선물', align: 'right', field: (row) => numberFormat(row.KOSPI200_NIGHT_FUTURES)},
                { headerStyle:'text-align:center;', name: 'MSCI_KOREA_ETF',  label: 'MSCI</br>KOREA</br>ETF', align: 'right', field: (row) => numberFormat(row.MSCI_KOREA_ETF)},                
                { headerStyle:'text-align:center;', name: 'CALL_D1',  label: '콜</br>(1일물)</br>(%)', align: 'right', field: (row) => numberFormat(row.CALL_D1)},              
            ],  
                // 
            columns2: [
                { headerStyle:'text-align:center;', name: 'openDate', label: '날짜', align: 'center', field: (row) => FormatDate(row.openDate)},
                { headerStyle:'text-align:center;', name: 'KRW_USD',  label: 'Won</br>/ Dollar</br>(₩)', align: 'right', field: (row) => numberFormat(row.KRW_USD)},
                { headerStyle:'text-align:center;', name: 'CPRND_Y3AA',  label: '회사채</br>(무보증3년)</br>AA- (%)', align: 'right', field: (row) => numberFormat(row.CPRND_Y3AA)},
                { headerStyle:'text-align:center;', name: 'NTPBND_Y3',  label: '국고채</br>(3년)</br>(%)', align: 'right', field: (row) => numberFormat(row.NTPBND_Y3)},
                { headerStyle:'text-align:center;', name: 'NTPBND_Y5',  label: '국고채</br>(5년)</br>(%)', align: 'right', field: (row) => numberFormat(row.NTPBND_Y5)},
                { headerStyle:'text-align:center;', name: 'MSTZBD_Y1',  label: '통안채</br>(1년)</br>(%)', align: 'right', field: (row) => numberFormat(row.MSTZBD_Y1)},
                { headerStyle:'text-align:center;', name: 'MSTZBD_Y2',  label: '통안채</br>(2년)</br>(%)', align: 'right', field: (row) => numberFormat(row.MSTZBD_Y2)},
                { headerStyle:'text-align:center;', name: 'INFDBT_Y1',  label: '산금채</br>(1년)</br>(%)', align: 'right', field: (row) => numberFormat(row.INFDBT_Y1)},
                { headerStyle:'text-align:center;', name: 'KEPCOD_Y3',  label: '한전채</br>(3년)</br>(%)', align: 'right', field: (row) => numberFormat(row.KEPCOD_Y3)},
                { headerStyle:'text-align:center;', name: 'CD_D91',  label: 'CD</br>(91일물)</br>(%)', align: 'right', field: (row) => numberFormat(row.CD_D91)},
                { headerStyle:'text-align:center;', name: 'ENTRPRS_BIL_M3',  label: '기업</br>어음</br>(3개월)</br>(%)', align: 'right', field: (row) => numberFormat(row.ENTRPRS_BIL_M3)},
                { headerStyle:'text-align:center;', name: 'NTPBND_Y10',  label: '국고채</br>(10년)</br>(%)', align: 'right', field: (row) => numberFormat(row.NTPBND_Y10)},
                // { headerStyle:'text-align:center;', name: 'FED_ASSETS',  label: 'Fed_assets', align: 'right', field: (row) => row.FED_ASSETS}
            ],
            openDate:''
        }
    },
    computed: {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId
        }),
        ...mapState('manage', ['list']),
        formatOpenDate() {
            return FormatDate(this.openDate)
        }
    },
    created () {

        this.getList();
    },
    methods: {
        ...mapMutations('manage', ['SET_DATA']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        textColor(name){
            var sClass = "";
            if(name == 'WTI' || name == 'BRENT_OIL' || name == 'GOLD' || name == 'SILVER' || name == 'COPPER' || name == 'DDR3_4G' || name == 'DDR3_2G' 
            || name == 'NAND_64G' || name == 'JPY_USD' || name == 'EUR_USD' || name == 'KRW_EUR' || name == 'KRW_JPY' || name == 'KRW_CNY' || name == 'CALL_D1' 
            || name == 'US_NTPBND_Y10' || name == 'US_NTPBND_Y2' || name == 'KOSPI200_NIGHT_FUTURES' || name == 'MSCI_KOREA_ETF') {
                sClass = 'color:#a20000;';
            }
            return sClass;
        },
        //리스트
        getList() {
            let param = {
                dateStr: this.dateStr,
                dateEnd: this.dateEnd,
                CALL_API_URL: '/todayWorld/list'
            }
            console.log("paramparamparam  : ", param)
            this.GET_CALL(param).then((data) => {
                console.log(" 데 이 이 터  : ", data)
                this.itemList = data.itemList;
                var now = new Date();
                var dt = now.getFullYear() + '' + ((now.getMonth()+1)>9 ? ''+(now.getMonth()+1) : '0'+(now.getMonth()+1)) + '' + (now.getDate()>9 ? ''+now.getDate() : '0'+now.getDate());
                this.openDate = dt
                var ds_list = [];
                var ds_obj = {};
                var sTmpDt = "";
                var iCnt = 0;
                data.items.forEach(row => {
                    if(row.openDate == dt){ dt = ""; }
                    if(sTmpDt == ""){
                        sTmpDt = row.openDate;
                    }
                    if(sTmpDt != row.openDate){
                        ds_obj['rowCnt'] = iCnt;
                        ds_list.push(ds_obj);
                        sTmpDt = "";
                        ds_obj = {};
                        iCnt = 0;
                    }
                    ds_obj['openDate'] = sTmpDt;
                    ds_obj[row.indexId] = row.closePrice;
                    iCnt++;
                });
                ds_obj['rowCnt'] = iCnt;
                ds_list.push(ds_obj);
                if(dt != ""){
                    this.getBtn(true);
                    ds_list.unshift({'openDate':dt, 'rowCnt':0, 'rowAt':'Y'});
                }else{
                    this.getBtn(false);
                    ds_list.unshift({'openDate':dt, 'rowCnt':data.itemList.length, 'rowAt':'Y'});
                    this.itemList.forEach(row2 => {
                        ds_list[0][row2.indexId] = ds_list[1][row2.indexId];
                    });
                }
                ds_list.forEach(row => {
                    if(row.rowCnt != data.itemList.length){
                        this.itemList.forEach(row2 => {
                            if(!row[row2.indexId]){
                                row[row2.indexId] = 0;
                            }
                        });
                    }
                });
                data.items = ds_list;
                this.SET_DATA(data);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        //저장
        getSave() {
            
            var obj = this.list[0];
            debug.log('getSave = ', obj)
            this.showAlert({ vc:this, message:' '+FormatDate(this.openDate)+ ' 일자 데이터를 <span style="color:#1976D2;">저장</span> 하시겠습니까?' }).then(()=>{
                debug.log('getSave >>>>>>>>>>>>>>>> 1')
                var ds_list = [];
                this.itemList.forEach(row => {
                    ds_list.push({
                        indexId : row.indexId,
                        openDate : this.openDate,
                        closePrice : (obj[row.indexId]? obj[row.indexId].trim():obj[row.indexId]),
                        userId : this.userId
                    });
                });
                debug.log('getSave >>>>>>>>>>>>>>>> 2')
                let param = {
                    ds_list,
                    'CALL_API_URL': '/todayWorld/save'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'저장 되었습니다.' });
                    this.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },
        //상세
        getView(){
            // let param = {
            //     dateStr: obj.openDate,
            //     dateEnd: obj.openDate,
            //     CALL_API_URL: '/todayWorld/list'
            // }
            let param = {
                dateStr: this.openDate,
                dateEnd: this.openDate,
                CALL_API_URL: '/todayWorld/list'
            }
            this.GET_CALL(param).then((data) => {
                var ds_list = this.list;
                data.itemList.forEach(row => {
                    ds_list[0][row.indexId] = 0;
                });
                if(data.items == null || data.items.length == 0){
                    this.getBtn(true);
                }else{
                    this.getBtn(false);
                }
                data.items.forEach(row => {
                    ds_list[0][row.indexId] = row.closePrice;
                });
                data.items = ds_list;
                this.SET_DATA(data);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        getNumberChk(obj, nm){
            var val = String(obj[nm]);
            val = val.replace(/[^-\0-9]/g, '');
            val = val.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
            obj[nm] = val;
            console.log('obj: ', obj, ', nm: ', nm, ', val: ', val)
        },
        getBtn(bChk) {
            this.bChkbtn = bChk;
        }
    }
    // methods: {
    //     getList() {
    //         this.$refs.todayWorldList.getList();
    //     },
    //     getSave() {
    //         this.$refs.todayWorldList.getSave();
    //     },
    //     getBtn(bChk) {
    //         this.bChkbtn = bChk;
    //     }
    // }
};

</script>
<style type="text/css">
    .date .q-field--dense{ height: 25px !important; }
    .date .q-field__control{ padding:0px 0px 0px 3px !important; height: 25px !important; }
    .number .q-field__control{ padding:0 !important; height: 25px !important; }
    .number .q-field__native{ padding-right:3px !important; }
</style>
