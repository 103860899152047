<template>
    <article>
        <q-toolbar>
            <q-space/>
            <i-btn v-show="isDetailPage || isFlashPage" label="취소" @click="goListPage()"/>
        </q-toolbar>
        <!-- 등록 화면 -->
        <!-- 220701 meej 등록시 한번에 여러개 등록가능하게 수정, 등록화면과 수정화면 분리함 -->
        <q-scroll-area class="i-content-wrap">
            <div v-show="isListPage">
            <q-card flat bordered>
                    <q-card-section
                        class="q-py-sm"
                        v-for="(item, index) in featuredList"
                        :key="index"    
                    >
                        <div class="row">
                            <span style="font-size:12pt;margin-right:10px;">{{index+1}}.</span> 
                            <q-btn
                                color="deep-orange"
                                icon="delete"
                                size="xs"
                                @click="deleteCard(index)"
                                round
                                unelevated
                            />
                        </div>
                        <div class="row">
                            <div v-if="showTime" class="col-12 col-lg-6 q-pa-xs">
                                <i-select v-model="item.newsType"
                                    :options="timeOptions" inputLabel="시간구분" />
                            </div>
                            <div class="col-12 col-lg-6 q-pa-xs">
                                <i-stock-search @get="getStockToList" :extra="{ 'index' : index }" label="종목검색"/>
                            </div>
                        </div>
                        <div class="row">
                            <q-chip v-for="(stock,i) in item.stocks" :key="'stocks'+i"
                                outline square color="black" text-color="white" removable @remove="removeStock(i, index)" >
                                <!-- 220630 meej 종목 순서변경 추가 -->
                                <span 
                                    v-if ="item.stocks.length > 1"
                                    style="cursor:pointer;margin:0 -5px 0 0px" 
                                    @click="beforeStockChip(i, index)">
                                    <q-avatar class="chipAvatar" icon="navigate_before"></q-avatar>
                                </span>
                                <span>{{ stock.name }}( {{ stock.code }} )</span>
                                <span 
                                    v-if ="item.stocks.length > 1"
                                    style="cursor:pointer;margin:0 -10px 0 15px" 
                                    @click="nextStockChip(i, index)">
                                    <q-avatar class="chipAvatar" icon="navigate_next"></q-avatar>
                                </span>
                            </q-chip>
                        </div>
                        <div v-if="item.stocks.length > 1" class="row">
                            <div class="col-12 col-lg-12 q-pa-xs">
                                <i-input v-model="item.GROUP_NAME" inputLabel="그룹명" />
                            </div>
                        </div>
                        <div class="row">
                            <!-- 220624 meej 등락률에 따라 멘트 수정을 위해 이슈요약 두칸으로 나눔 -->
                            <div class="col-8 q-pa-xs">
                                <i-input v-model="item.title" inputLabel="이슈요약" @focus="goEditPage" />
                            </div>
                            <div class="col-4 q-pa-xs">
                                <i-input v-model="item.RATE_STATE0" inputLabel="" label="미입력시 등락률 상태 자동 입력 " @focus="goEditPage" />
                            </div>
                            <div class="col-12 q-pa-xs">
                                <i-input v-model="item.content" inputLabel="이슈내용" type="textarea" :rows="20" @focus="goEditPage"/>
                            </div>
                        </div>
                    </q-card-section>
                </q-card>
            </div>
            <!-- 상세 수정 화면 -->
            <div v-show="isDetailPage">
                <q-card flat bordered>
                    <q-card-section>
                        <div class="row">
                            <div v-if="showTime" class="col-12 col-lg-6 q-pa-xs">
                                <i-select v-model="time"
                                    :options="timeOptions" inputLabel="시간구분" />
                            </div>
                            <div class="col-12 col-lg-6 q-pa-xs">
                                <i-stock-search @get="getStock" label="종목검색"/>
                            </div>
                        </div>
                        <div class="row">
                            <q-chip v-for="(stock,i) in featured.stocks" :key="'stocks'+i"
                                    outline square color="black" text-color="white" removable @remove="removeStock(i, null)" >
                                    <!-- 220630 meej 종목 순서변경 추가 -->
                                    <span
                                        v-if ="featured.stocks.length > 1" 
                                        style="cursor:pointer;margin:0 -5px 0 0px" 
                                        @click="beforeStockChip(i, null)">
                                        <q-avatar class="chipAvatar" icon="navigate_before"></q-avatar>
                                    </span>
                                    <span>{{ stock.name }}( {{ stock.code }} )</span>
                                    <span 
                                        v-if ="featured.stocks.length > 1" 
                                        style="cursor:pointer;margin:0 -10px 0 15px"
                                        @click="nextStockChip(i, null)">
                                        <q-avatar class="chipAvatar" icon="navigate_next"></q-avatar>
                                    </span>
                            </q-chip>
                        </div>
                        <div v-if="featured.stocks.length > 1" class="row">
                            <div class="col-12 col-lg-12 q-pa-xs">
                                <i-input v-model="featured.GROUP_NAME" inputLabel="그룹명" />
                            </div>
                        </div>
                        <div class="row">
                            <!-- 220624 meej 등락률에 따라 멘트 수정을 위해 이슈요약 두칸으로 나눔 -->
                            <div class="col-8 q-pa-xs">
                                <i-input v-model="featured.title" inputLabel="이슈요약" @focus="goEditPage" />
                            </div>
                            <div class="col-4 q-pa-xs">
                                <i-input v-model="featured.RATE_STATE0" inputLabel="" label="미입력시 등락률 상태 자동 입력 " @focus="goEditPage" />
                            </div>
                            <div class="col-12 q-pa-xs">
                                <i-input v-model="featured.content" inputLabel="이슈내용" type="textarea" :rows="20" @focus="goEditPage"/>
                            </div>
                        </div>
                    </q-card-section>
                </q-card>

            </div>
            <!-- 속보 페이지 -->
            <div v-show="isFlashPage">
                <q-card flat bordered>
                    <q-card-section>
                        <i-preview
                            :flashData="newsFlashData"
                            disableCancel editSendTime />
                    </q-card-section>
                </q-card>
            </div>
        </q-scroll-area>
        <!-- 하단 버튼 -->
        <div class="i-bottom-wrap">
            <div v-show="isListPage" class="row">
                <!-- 220701 meej 등록시 한번에 여러개 등록가능하게 '추가'버튼 추가 -->
                <div class="col q-pa-xs">
                    <i-btn class="full-width" label="추가" @click="addCard()"/>
                </div>
                <div class="col q-pa-xs">
                    <i-btn class="full-width" label="등록" @click="writeList()"/>
                </div>
            </div>
            <i-btn v-show="isDetailPage" class="full-width" label="수정" @click="write()"/>
            <i-btn v-show="isFlashPage" class="full-width" label="속보 보내기" @click="flash()"/>
        </div>
    </article>
</template>
<script>
import Debug from '@/debug'
import CONSTANTS from '@/constants'
import { objToString, clone, morning } from '@/util'

import Button from '@/components/common/Button'
import StockSearch from '@/components/common/StockSearchOri'
import Input from '@/components/common/Input'
import Select from '@/components/common/Select'
// import DateTime from '@/components/common/DateTime'
import Preview from '@/components/common/Preview'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
//event 처리
//update list : event-update-list
export default {
    name: 'Content',
    components: {
        'i-input': Input,
        'i-select': Select,
        'i-stock-search': StockSearch,
        // 'i-datetime': DateTime,
        'i-btn' :  Button,
        'i-preview': Preview
    },
    props: {
        newsType: {
            type: Object,
            default: null,
        },
    },
    data () {
        return {
            time: null,
            timeOptions: CONSTANTS.STOCK_FEATURED_TIME_TYPE,
            cardList: [],
            newsFlashData: {},
            featuredList :[], // 220701 meej 등록시 입력값 리스트
            featured: {
                id: '',
                stocks:[],
                GROUP_NAME: '',
                title: '',
                content: '',
                RATE_STATE0: '', // 220624 meej 등락률 멘트
            },
            showTime: true,
            remove_Stocks: '', // 22.02.14 한수예
        }
    },
    created () {
        if(this.newsType === null) {
            if(this.timeOptions.length == 3) {
                this.timeOptions = clone(CONSTANTS.STOCK_FEATURED_TIME_TYPE)
                this.timeOptions.shift()
            }
            Debug.log("morning?", morning())
            this.time = (morning() === 0?this.timeOptions[0]:this.timeOptions[1])
        } else {
            this.showTime = false
        }
        // 220701 meej 초기화 추가
        this.initCard()
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
            item : state => state.stock_news.item,
            flashType : state => state.stock_news.flashType
        }),
        ...mapGetters( 'page', ['isListPage', 'isDetailPage', 'isEditPage', 'isRegisterPage', 'isFlashPage']),
    },
    methods: {
        ...mapMutations( 'page', ['setPageStatus']),
        ...mapMutations( 'stock_news', ['INIT_DETAIL']),

        ...mapActions( 'alert', ['showError', 'showMessage']),
        ...mapActions( 'stock_news', [ 'ASYNC_LIST', 'WRITE_FEATURED_ITEM','GET_FEATURED_DETAIL', 'WRITE_FEATURED_ITEM_LIST']),

        // 입력카드 초기화 , 220701 meej 등록시 여러개 입력가능 
        // 220726 meej this.showTime = false 일경우 SPOT입력 - 특징주(장마감) 조건 추가
        initCard() {
            this.featuredList = [
                {   
                    newsType: this.showTime ? this.time : this.newsType,
                    stocks:[],
                    GROUP_NAME: '',
                    title: '',
                    content: '',
                    RATE_STATE0: '', 
                }
            ]
        },
        // 입력카드 추가 , 220701 meej 등록시 여러개 입력가능 
        // 220726 meej this.showTime = false 일경우 SPOT입력 - 특징주(장마감) 조건 추가
        addCard(){
            var card = {
                newsType: this.showTime ? this.time : this.newsType,
                stocks:[],
                GROUP_NAME: '',
                title: '',
                content: '',
                RATE_STATE0: '', 
            }
            this.featuredList.push(card)
        },
        // 입력카드 삭제 , 220701 meej 등록시 여러개 입력가능 
        deleteCard(index) {
            this.featuredList.splice(index, 1)
            if(this.featuredList.length == 0){
                this.addCard()
            }
        },
        // 해당 입력카드에 맞게 종목 입력 , 220701 meej 등록시 여러개 입력가능 
        getStockToList(stock, idx) {
            this.featuredList[idx.index].stocks.push(stock)
            if(this.featuredList[idx.index].stocks.length > 1 ) {
                this.featuredList[idx.index].GROUP_NAME = ''
                for(let s of this.featuredList[idx.index].stocks) {
                    this.featuredList[idx.index].GROUP_NAME += s.name + '/'
                }
                
                this.featuredList[idx.index].GROUP_NAME = this.featuredList[idx.index].GROUP_NAME.slice(0, -1)
            }else{
                // 220627 meej 추가된 종목이 1이하일경우 그룹명 초기화
                this.featuredList[idx.index].GROUP_NAME = ''
            }
        },
        getStock(stock) {
            this.featured.stocks.push(stock)
            if(this.featured.stocks.length > 1 ) {
                this.featured.GROUP_NAME = ''
                for(let s of this.featured.stocks) {
                    this.featured.GROUP_NAME += s.name + '/'
                }
                
                this.featured.GROUP_NAME = this.featured.GROUP_NAME.slice(0, -1)
            }else{
                // 220627 meej 추가된 종목이 1이하일경우 그룹명 초기화
                this.featured.GROUP_NAME = ''
            }
        },
        initFeatured() {
            var obj = {
                id: '',
                stocks:[],
                GROUP_NAME: '',
                title: '',
                content: '',
                RATE_STATE0: '', // 220624 meej 등락률 멘트
            }
            this.featured = obj
        },
        goListPage() {
            this.INIT_DETAIL()
            this.initFeatured()

            // 220627 meej 시간 구분값 설정, 입력카드 초기화 추가
            this.time = (morning() === 0?this.timeOptions[0]:this.timeOptions[1])
            this.initCard()

            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        goEditPage() {
        },
        // 종목 삭제하기 , 220701 meej 주석처리
        // removeStock(index) {
        //     this.featured.stocks.splice(index,1)
        // },
        // 220701 meej 종목삭제하기 변경 : 등록시 리스트로 넣기 때문에 리스트 index 추가
        removeStock(index, listIdx) {
            if(listIdx == null){
                this.featured.stocks.splice(index,1)
            }else{
                this.featuredList[listIdx].stocks.splice(index,1)
            }
        },
        getDetail(item) {

            let params = {
                newsType: item.newsType1,
                id: item.id
            }

            this.featured.id = item.id
            this.featured.title = item.title
            this.featured.RATE_STATE0 = item.RATE_STATE0 // 220624 meej 등락률 멘트
            this.featured.GROUP_NAME = item.GROUP_NAME
            this.featured.content = item.content
            Debug.log('getDetail featured', this.featured)
            this.time = this.timeOptions.find( v => v.value == item.newsType1)
            Debug.log('getDetail this.time', this.time)
            this.featured.stocks = []
            this.GET_FEATURED_DETAIL(params)
            .then((data) => {
                Debug.log('getDetail result data', data)
                for(let i in data.items) {
                    let temp = data.items[i]
                    let stock = {
                        name: temp.stockName,
                        code: temp.stockCode
                    }
                    this.featured.stocks.push(stock)
                    
                    // 220624 meej title, RATE_STATE0 나누기
                    this.featured.title = temp.title.replace(" "+temp.RATE_STATE0, "")
                }
            })
            .catch((error) => {
                this.showError({ vc:this, error:error })
            })
        },
        // 220701 meej 등록하기 : 리스트로 담아서 보냄
        writeList() {
            let paramsList = {
                stockNewsList :[]
            }

            // 입력값 체크
            for(let i=0;i<this.featuredList.length;i++){
                const item = this.featuredList[i]
                if(item.stocks.length == 0) {
                    this.showMessage({ vc:this, message: (i+1)+'번 종목을 선택하세요.' })
                    return
                }
                if (!item.newsType
                    || !item.title
                    || !item.content ) {
                    this.showMessage({ vc:this, message: (i+1)+'번 내용을 입력해주세요.' })
                    return
                }

                let news0 = {
                    title: item.title.trimEnd() +" "+ item.RATE_STATE0,
                    content: item.content
                }

                // 220627 meej 종목수 1 이하일경우 그룹명 초기화
                if(item.stocks.length <= 1){
                    item.GROUP_NAME = ''
                }

                let stocks = ''
                for(let s of item.stocks) {
                    stocks += s.code + ';'
                }
                stocks = stocks.slice(0, -1)
                
                let options = {
                    GROUP_NAME: item.GROUP_NAME ? item.GROUP_NAME : '',
                    STOCKS: stocks,
                    RATE_STATE0: item.RATE_STATE0.trimEnd(),
                }
                news0.options = objToString(options)
                
                let stockNews = []
                for(let stock of item.stocks) {
                    let news = clone(news0)
                    news.stockCode = stock.code
                    news.stockName = stock.name
                    stockNews.push(news)
                }

                if(item.stocks.length > 1){
                    var step;
                    var add_Stocks = '\n[종목]: ';
                    var removeStocks;

                    if(removeStocks != add_Stocks) {
                        for (step = 0; step < item.stocks.length; step++) {
                            stockNews[step].content = stockNews[step].content.replace(removeStocks, '')
                        }                
                        for (step = 0; step < item.stocks.length; step++) {
                            if (step < item.stocks.length-1) {
                                add_Stocks = add_Stocks.concat(stockNews[step].stockName, ', ')
                            } else{
                                add_Stocks = add_Stocks.concat(stockNews[step].stockName)
                            }
                        }
                        removeStocks = add_Stocks;
                    
                        for (step = 0; step < item.stocks.length; step++) {
                            if (removeStocks == add_Stocks) { 
                                var idx = stockNews[step].content.indexOf("\n[종목]: ");
                                if(idx != -1){ 
                                    stockNews[step].content = stockNews[step].content.substring(idx, -1)
                                }
                            }
                            stockNews[step].content = stockNews[step].content + add_Stocks
                        }     
                        console.log('stockNews: ',  stockNews);
                    }
                }
                
                var params = {}
                if(item.newsType !== null) {
                    params.newsType = item.newsType.value
                } 
                params.stockNews = stockNews
                paramsList.stockNewsList.push(params)
            }
            
            this.WRITE_FEATURED_ITEM_LIST(paramsList)
            .then((el) => {
                this.showMessage({ vc:this, message:  "총 "+ paramsList.stockNewsList.length + "개 중에 " + el.success + "개" +' 등록 완료되었습니다.' })

                this.$nextTick(function () {
                    this.goListPage()
                    this.$emit("event-update-list")
                })
            })
            .catch((error) => {
                this.showError({ vc:this, error:error })
                Debug.log('error = ', error)
            })
        },
        // 220701 meej 수정하기
        write() {
            // 입력값 체크
            if(this.featured.stocks.length == 0) {
                this.showMessage({ vc:this, message:'종목을 선택하세요.' })
                return
            }
            if (!(this.time || this.newsType)
                || !this.featured.title
                || !this.featured.content ) {
                this.showMessage({ vc:this, message:'내용을 입력해주세요.' })
                return
            }
            // default 뉴스
            let news0 = {
                // 220624 meej 제목에 등락률 멘트 추가
                title: this.featured.title.trimEnd() +" "+ this.featured.RATE_STATE0,
                content: this.featured.content
            }
            // if (this.isDetailPage) {
            //     news0.id = this.featured.id
            // }
            // 220627 meej 종목수 1 이하일경우 그룹명 초기화
            if(this.featured.stocks.length <= 1){
                this.featured.GROUP_NAME = ''
            }
            //2021.11.17 suki : 그룹일때 그룹에 모든 종목코드를 option 에 넣음(특징주 속보 보낼때 필요 )
            let stocks = ''
            for(let s of this.featured.stocks) {
                stocks += s.code + ';'
            }
            stocks = stocks.slice(0, -1)
            
            let options = {
                GROUP_NAME: this.featured.GROUP_NAME ? this.featured.GROUP_NAME : '',
                STOCKS: stocks,
                // 220624 등락률 멘트 추가
                RATE_STATE0: this.featured.RATE_STATE0.trimEnd(),
            }
            news0.options = objToString(options)
            
            let stockNews = []
            for(let stock of this.featured.stocks) {
                let news = clone(news0)
                news.stockCode = stock.code
                news.stockName = stock.name
                stockNews.push(news)
            }

            // 22.02.14 한수예 종목명 추가 
            if(this.featured.stocks.length > 1){
                var step;
                var add_Stocks = '\n[종목]: ';

                if(this.remove_Stocks != add_Stocks) {
                    for (step = 0; step < this.featured.stocks.length; step++) {
                        stockNews[step].content = stockNews[step].content.replace(this.remove_Stocks, '')
                    }                
                for (step = 0; step < this.featured.stocks.length; step++) {
                    if (step < this.featured.stocks.length-1) {
                        // add_Stocks = add_Stocks.concat(stockNews[step].stockName, '(', stockNews[step].stockCode, '), ')
                        add_Stocks = add_Stocks.concat(stockNews[step].stockName, ', ')
                    } else{
                        // add_Stocks = add_Stocks.concat(stockNews[step].stockName, '(', stockNews[step].stockCode, ')')
                        add_Stocks = add_Stocks.concat(stockNews[step].stockName)
                    }
                }
                this.remove_Stocks = add_Stocks;
                
                for (step = 0; step < this.featured.stocks.length; step++) {
                    if (this.remove_Stocks == add_Stocks) { 
                        var idx = stockNews[step].content.indexOf("\n[종목]: ");
                        if(idx != -1){ 
                        stockNews[step].content = stockNews[step].content.substring(idx, -1)
                    }
                }
                    stockNews[step].content = stockNews[step].content + add_Stocks
            }     
            console.log('stockNews: ',  stockNews);
        }
    }


            var params = {}
            if(this.newsType === null) {
                params.newsType = this.time.value
            } else {
                params.newsType = this.newsType.value
            }
            
            params.stockNews = stockNews

            if (this.isDetailPage) {
                params.id = this.featured.id
            }

            this.writeStockFeatured(params)
        },
        // 작성 및 수정 API
        writeStockFeatured(params) {

            this.WRITE_FEATURED_ITEM(params)
            .then(() => {
                var message = this.isListPage ? '등록되었습니다.' : '수정되었습니다.'
                this.showMessage({ vc:this, message:message })

                this.$nextTick(function () {
                    this.goListPage()
                    this.$emit("event-update-list")
                })
            })
            .catch((error) => {
                this.showError({ vc:this, error:error })
                Debug.log('error = ', error)
            })
        },
        // 220701 meej 그룹주 입력시 종목 순서 변경 : 앞으로 이동
        beforeStockChip(i, index){
            let arr = []
            if(index == null){
                arr = this.featured.stocks
            }else{
                arr = this.featuredList[index].stocks
            }
            if(i!=0){
                const item = arr.splice(i,1)
                arr.splice(i-1, 0, item[0])
            }
        },
        // 220701 meej 그룹주 입력시 종목 순서 변경 : 뒤로 이동
        nextStockChip(i, index){
            let arr = []
            if(index == null){
                arr = this.featured.stocks
            }else{
                arr = this.featuredList[index].stocks
            }
            if(i != 0 || i != arr.length-1){
                const item = arr.splice(i,1)
                arr.splice(i+1, 0, item[0])
            }
        }
    },
    watch: {
        item (val, old) {
            if(val !== null && (val !== old)) {
                this.getDetail(val)
            }
        }
    }
}
</script>
<style>
/* 220701 meej 종목 순서변경 버튼 hover */
.chipAvatar:hover{
    background-color: black;
    color:white;
}
</style>


