import api from '@/services/InfostockService'
import Debug  from '@/debug'

const state = () => ({
    list: [],
    nextKey: null
})

const getters = {
}

const mutations = {
    SET_LIST(state, { data }) {
        var arr = state.list
        arr.push(...data.items)

        // 인덱스 추가
        arr.forEach((row, index) => {
            row.index = index
        })

        state.list = arr
        state.nextKey = data.nextKey
    },
    INIT_LIST(state) {
        state.list = []
        state.nextKey = null
    }
}

const actions = {
    // 목록 조회
    async GET_LIST({ state, commit }, params) {
        params.nextKey = state.nextKey

        Debug.log('GET_LIST params', params)
        const { data } = await api.stockNewsDiscl(params)
        Debug.log('GET_LIST data', data)

        commit('SET_LIST', { data })
    }
}

export const stock_discl = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
