<template>
    <article>
        <div class="flex justify-end q-pa-xs">
            <i-btn v-if="newsType.value == 'BOARD_SCHEDULE_FORM' && isDetailPage" label="신규등록" @click="goRegisterPage()"/>
        </div>
        <q-scroll-area visible class="i-content-wrap">
            <article v-if="newsType.value == 'BOARD_SCHEDULE_FORM'">
                <q-card flat bordered>
                    <q-card-section v-if="isListPage || isRegisterPage" class="row">
                        <div class="col-12 q-pa-xs">
                            <i-input v-model="formTitle" label="스케줄 형식 제목" />
                        </div>
                        <div class="col-12 q-pt-xs">
                            <i-select
                                v-model="flag[0]"
                                :options="categoryOptions"
                                inputLabel="카테고리"
                                option-label="label"
                                option-value="value"
                            />
                        </div>
                        <div class="col-12 q-pa-xs">
                            <i-input v-model="scheduleTitle" label="제목" />
                        </div>
                        <div class="col-12 q-pa-xs">
                            <i-input v-model="content" label="내용" type="textarea" :rows="rows"/>
                        </div>
                    </q-card-section>
                    <q-card-section v-if="isDetailPage">
                        <div class="col-12 q-pa-xs">
                            <i-input v-model="formTitle" label="스케줄 형식 제목" />
                        </div>
                        <div class="col-12 q-pt-xs">
                            <i-select
                                v-model="flag[0]"
                                :options="categoryOptions"
                                inputLabel="카테고리"
                                option-label="label"
                                option-value="value"
                            />
                        </div>
                        <div class="row q-gutter-sm">
                            <div class="col-12">
                                <i-input v-model="scheduleTitle" label="제목" />
                            </div>
                            <div class="col-12">
                                <i-input v-model="item.content" label="내용" type="textarea" :rows="rows"/>
                            </div>
                        </div>
                    </q-card-section>
                </q-card>
            </article>
        </q-scroll-area>
        <!-- 하단 버튼 -->
        <div class="q-my-xs">
            <i-btn 
                class="full-width" 
                :label="isListPage || isRegisterPage ? '등록' : '수정'" 
                @click="write" 
            />
        </div>
    </article>
</template>
<script>
import CONSTANTS from "@/constants";

import Select from '@/components/common/Select.vue'
import Input from '@/components/common/Input'
import Button from '@/components/common/Button'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Content',
    components: {
        'i-select': Select,
        'i-input': Input,
        'i-btn': Button,
    },
    props: {
        newsType: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            formTitle: '',           // 스케줄 형식 제목
            scheduleTitle: '',       // 스케줄 제목 
            titles: [],              // 제목 분리 
            content: '',    
            flag: [],                // 카테고리 구분값 label,value
            categoryOptions: CONSTANTS.SCHEDULE_INDEX,
            rows: 30,
        }
    },
    created() {
        console.warn('newsType', this.newsType)
    },
    updated() {},
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            item: (state) => state.manage.item,
            items: (state) => state.manage.items,
            cutPoint: (state) => state.manage.cutPoint,
        }),
        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isRegisterPage',
            'isFlashPage',
            'isEditPage',
        ]),
    },
    watch: {
        item(val){
            if(val){
                if(val.title.includes(this.cutPoint)){
                    this.titles = val.title.split(this.cutPoint)
                    // console.log('titles',this.titles)
                    this.formTitle = this.titles[0] // 형식제목
                    this.scheduleTitle = this.titles[1] // 스케줄제목
                }
                // console.log('flag', val.flag)
                const category = this.categoryOptions.find(e => e.value == val.flag)
                this.flag = [ {'label': category.label, 'value': category.value} ]
            }
        },
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('manage', ['INIT_LIST', 'SET_LIST']),
        ...mapActions('manage', ['GET_BOARD_LIST', 'WRITE_SCHEDULE_FORM',]),
        ...mapActions('alert', ['showError', 'showMessage']),


        goRegisterPage() {
            this.resetValues()
            this.setPageStatus(this.PAGE_STATUS.REGISTER)
        },

        resetValues(){
            this.formTitle = ''
            this.scheduleTitle = ''
            this.titles = []
            this.content = ''
            this.flag = []
        },

        getList() {
            this.INIT_LIST()
            let param = {
                newsType: this.newsType.value,
            }
            this.GET_BOARD_LIST(param)
            .then((data) => {
                // console.log('data', data)
                this.SET_LIST(data)
            })
            .catch((error) => {
                this.showError({ vc:this, error:error })
            })
        },

        async write() {
            var params = {}
            params.newsType = this.newsType.value
            // title => cutPoint 기준 앞: 형식제목, 뒤: 스케줄제목
            if (this.newsType.value == 'BOARD_SCHEDULE_FORM') {
                if (this.isDetailPage) {
                    params.id = this.item.id
                    params.title = this.formTitle + this.cutPoint + this.scheduleTitle
                    params.content = this.item.content
                    params.flag = this.flag[0].value
                } else {
                    params.title = this.formTitle + this.cutPoint + this.scheduleTitle 
                    params.content = this.content
                    params.flag = this.flag[0].value
                }
            } 
            this.WRITE_SCHEDULE_FORM(params)
            .then(() => {
                this.showMessage({ vc:this, message:'저장 되었습니다.' })
                this.getList()
            })
            .catch((err) => {
                this.showError({ vc:this, error:err })
            })
        }
    },
}
</script>
