<template>
    <section class="row">
        <i-list class="col-6 q-pa-md" />
        <i-content class="col-6 q-pa-md" />
    </section>
</template>
<script>
import List from '@/components/manage/stock-company/List.vue'
import Content from '@/components/manage/stock-company/Content.vue'
import { mapMutations } from 'vuex'

export default {
    name: 'StockCompany',
    components: {
        'i-list' : List,
        'i-content' : Content
    },
    created () {
        this.INIT_ITEM()
    },
    methods: {
        ...mapMutations( 'manage', ['INIT_ITEM']),
    }
}
</script>

<style>
    /* common */

    /* xs */
    @media only screen and (max-width: 599px) {
    }

    /* sm */
    @media only screen and (max-width: 1023px) {
    }

    /* md */
    @media only screen and (max-width: 1439px) {
    }

    /* lg */
    @media only screen and (max-width: 1919px) {
    }

    /* xl */
    @media only screen and (min-width: 1920px) {
    }

</style>


