import api from '@/services/InfostockService'
import Debug  from '@/debug'


const state = () => ({
    list: [],
    item: {},
    nextKey: null,
    issueItems:[],
    paperType: JSON.parse(localStorage.getItem('paperType')) || {},
})

const getters = {
}

const mutations = {
    setList(state, data) {
        Debug.log('setList data', data)
        var arr = state.list
        arr.push(...data.items)

        // 인덱스 추가
        arr.forEach((row, index) => {
            row.index =  index
            row.label = (row.sendFlag === 0?'실패':'전송')
        })

        state.list = arr
        state.nextKey = data.nextKey
        Debug.log('setList arr', arr)
    },
    setFlashList(state, { data }) {
        Debug.log('setFlashList data', data)
        state[data.newsType] = data.newsTypes
    },
    INIT_LIST(state) {
        state.list = []
        state.nextKey = null
    },
    SET_PREVIEW(state,  data ) {
        Debug.log('SET_PREVIEW data', data)
        state.preview = data
        localStorage.setItem('preview', JSON.stringify(data))
    },
    SET_ITEM(state, item) {
        state.item = item
    },
    SET_ISSUE_ITEMS(state, items) {
        state.issueItems = items
    },
    SET_PAPER_TYPE(state, type) {
        localStorage.setItem('paperType', JSON.stringify(type))
        state.paperType = type
    },
}

const actions = {
    // 목록 조회
    async ASYNC_LIST({ commit, state, dispatch }, params ) {
        params.nextKey = state.nextKey
        if(state.NewsFlashType.length == 0 || params.newsType === undefined) {
            await dispatch('ASYNC_NEWSTYPE', 'NewsFlashType')
            params = {newsType : state.NewsFlashType[0].value}
        }

        const { data } = await api.flashList(params)
        commit('setList', data)

    },
    WRITE_ISSUE(_, params) {
        Debug.log('WRITE_ITEM params', params)
        return new Promise((resolve, reject) => {
            api.issueWrite(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })

    },
    UPDATE_ISSUE(_, params) {
        Debug.log('UPDATE_ISSUE params', params)
        return new Promise((resolve, reject) => {
            api.issueUpdate(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })

    },
    PREVIEW_ISSUE(_, params) {
        Debug.log('UPDATE_ISSUE params', params)
        return new Promise((resolve, reject) => {
            api.issuePreview(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })

    },
    
    PAPER_PREVIEW(_, params) {
        Debug.log('PREVIEW_ITEM params', params)
        return new Promise((resolve, reject) => {
            api.newsPaperPreview(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })

    },
    PAPER_CONFIRM(_, params) {
        Debug.log('PREVIEW_ITEM params', params)
        return new Promise((resolve, reject) => {
            api.newsPaperConfirm(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })

    },
    SEND_FLASH(_, params) {
        Debug.log('SEND_ITEM params', params)
        api.flashSend(params);
    },
    PAPER_SEND(_, params) {
        Debug.log('SEND_PAPER params', params)
        api.newsPaperSend(params);
    },
    // 20230906 재전송버튼
    NOT_PAPER_SEND(_, params) {
        Debug.log('SEND_PAPER params', params)
        api.notnewsPaperSend(params);
    },
    PAPER_SHOW(_, params) {
        Debug.log('SEND_PAPER params', params)
        return new Promise((resolve, reject) => {
            api.newsPaperShow(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },
    
    PAPER_MAKE(_, params) {
        Debug.log('PAPER_MAKE params', params)
        return new Promise((resolve, reject) => {
            api.newsPaperMake(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },
    
    GET_DISCL_LIST(_, params) {
        return new Promise((resolve, reject) => {
            api.newsDisclList(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },
    // 220927 meej 주식신문 조회수
    GET_VIEWCOUNT_LIST(_, params) {
        return new Promise((resolve, reject) => {
            api.getViewCountList(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },
    // 22.12.02 SUYE 신문 상단표
    GET_CLOSE_INDEX_LIST(_, params) {
        return new Promise((resolve, reject) => {
            api.getCloseIndexList(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },
    // 22.12.02 SUYE 신문 상단표 저장
    SAVE_CLOSE_INDEX(_, params) {
        return new Promise((resolve, reject) => {
            api.saveCloseIndex(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },
}

export const newspaper = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
