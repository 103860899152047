<template>
    <article>
        <i-table
            :columns="columns"
            :list="items"
            :nextKey="nextKey"
            :needCheckBox="false"
            @row-click="getItem"
            @next-click="getThemeHistory"
            :dense="true"
        />
    </article>
</template>
<script>
import Debug from '@/debug'
import { FormatDate } from '@/util'

import Table from '@/components/common/Table'

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'List',
    components: {
        'i-table': Table,
    },
    props: {
        initTheme: {
            type: Object,
            default: null,
        },
        type: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            columns: [
                {
                    name: 'showDate', // createTime(작성일), showDate(제시일)
                    label: '입력일자',
                    align: 'left',
                    // 220603 meej createTime -> showDate 변경
                    field: (row) => FormatDate(row.showDate),
                },
                {
                    name: 'CREATE_WRITER',
                    label: '최초 작성자',
                    align: 'left',
                    field: (row) => row.CREATE_WRITER,
                },
                {
                    name: 'lastUpdateTime',
                    label: '변경일자',
                    align: 'left',
                    // 220708 meej 삭제 시 빨간색으로 표시
                    style: (row) => (row.crud === 'D' ? 'color:#dc3545' : 'color:#000'),
                    field: (row) =>
                        row.crud === 'D'
                            ? '삭제'
                            : row.crud === 'C'
                            ? ''
                            : FormatDate(row.lastUpdateTime),
                },
                {
                    name: 'writer',
                    label: '변경자',
                    align: 'left',
                    field: (row) => row.writer,
                },
                {
                    name: 'theme',
                    label: '테마명',
                    align: 'left',
                    field: (row) => row.themeName,
                },
                {
                    name: 'content',
                    label: '히스토리',
                    align: 'left',
                    field: (row) => row.content,
                },
            ],

            theme: null,
            themeOptions: null,
        }
    },
    created() {
        this.INIT_LIST()
        Debug.log('ThemeHistory List create theme = ', this.initTheme)
        if (this.initTheme !== undefined && this.initTheme !== null) {
            this.theme = this.initTheme
            Debug.log('created this.themeCdoe = ', this.theme)
            this.getThemeHistory(this.theme.code)
        }
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            themeList: (state) => state.theme.items,
            themeType: (state) => state.resource.themeType,
        }),
        ...mapState('theme_news', ['items', 'nextKey']),

        ...mapGetters('page', ['isListPage', 'isEditPage', 'isRegisterPage']),
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
            SET_ITEM: 'theme_news/SET_ITEM',
            INIT_LIST: 'theme_news/INIT_LIST',
        }),
        ...mapActions({
            ASYNC_THEMENEWS_LIST: 'theme_news/ASYNC_THEMENEWS_LIST',
            GET_THEME_LIST: 'theme/GET_THEME_LIST',
        }),
        ...mapActions('alert', ['showError', 'showMessage']),
        filterTheme(val, update) {
            if (
                this.themeList === undefined ||
                this.themeList === null ||
                this.themeList.length === 0
            ) {
                let params = { type: this.type.value0 }
                this.GET_THEME_LIST(params).then(() => {
                    this.themeOptions = this.themeList
                    update()
                })
            } else {
                this.themeOptions = this.themeList.filter(
                    (v) => v.name.indexOf(val) > -1
                )
                update()
            }
        },
        goRegister() {
            if (!this.theme) {
                const message = '테마를 선택하세요!'
                this.showError({ vc: this, error: message })
            } else {
                this.setPageStatus(this.PAGE_STATUS.REGISTER)
            }
        },

        getThemeHistory() {
            /* 
                220428 meej 테마히스토리 목록 조회, 페이징 오류수정
            */
            Debug.log(' theme = ', this.theme, ', initTheme = ',this.initTheme)

            if (this.theme === null && this.initTheme === null) {
                return
            } else if (this.theme === null && this.initTheme !== undefined){
                this.theme = this.initTheme
            } 

            const params = {
                newsType: 'THEME_HISTORY',
                themeCode: this.theme.code,
                nextKey: this.nextKey
            }
            Debug.log('params = ', params)

            this.ASYNC_THEMENEWS_LIST(params)
        },
        goEditPage() {
            this.setPageStatus(this.PAGE_STATUS.EDIT)
        },
        goDetailPage() {
            Debug.log('Theme list goDetailPage')
            this.setPageStatus(this.PAGE_STATUS.DETAIL)
        },
        getItem(item) {
            Debug.log('getItem', item)
            item.CHART = item.CHART == 1 ? true : false

            this.SET_ITEM(item)
            this.goDetailPage()
        },
    },
    /* 
        220428 meej 목록 오류로 인한 주석처리
    */
    // watch: {
    //     pageStatus: function() {
    //         Debug.log('watch pageStatus')
    //         if (this.isListPage) {
    //             // this.INIT_LIST()
    //             this.getThemeHistory()
    //         }
    //     },
    // },
}
</script>
