<template>
    <article>
        <q-dialog v-model="resultPopup" persistent>
            <q-card style="min-width: 70%;min-height:50%;" >
                <q-card-section >
                    <div class="row q-my-sm">
                         <div class="col-12 col-sm-4">
                        <i-input v-model="work_person" inputLabel="담당자" style="width:500px; margin: 0px 0px 0px 15px; text-align : center;"/>
                        </div>
                    </div>

                  
                    <body class="wrap">
                        <div>
                            <div class="row q-my-sm">
                                <label class="i-label" style="padding: 30px 0px 0px 50px; color:gray; width:100px;">상태</label>
                               <div><i-select v-model="state" :options="['예정','진행중','완성']" option-value="value" option-label="name" style="padding: 20px 0px 0px 6px; text-align : center;"/></div>
                            </div>

                            <div class="row q-my-sm">
                                <q-input class="full-width" dense outlined v-model="result" type="textarea" :input-style="{width:'100%'}"><template v-slot:before><label class="i-label" style="padding: 70px 0px 0px 20px; color:gray; width:100px; text-align : center;">결과</label></template></q-input>
                            </div>
                        </div>
                        <div class="row q-my-sm">
                            <label class="i-label" style="padding: 30px 0px 0px 50px; color:gray; width:100px;">난이도</label>
                            <div><i-select v-model="level" :options="['1','2','3','4','5']" option-value="value" option-label="name" style="padding: 20px 0px 0px 6px; text-align : center;"/></div>
                        </div>
                    </body>
                </q-card-section>
                <q-card-actions align="center" class="text-primary">
                    <div class="q-pa-xs"><q-btn label="등록" @click="getUpdatePopupSave()"/></div>
                    <div class="q-pa-xs"><q-btn color="white" text-color="black" label="닫기" v-close-popup/></div>
                </q-card-actions>
            </q-card>
        </q-dialog>
    </article>
</template>
<script>
import Select from "@/components/common/Select";
import { mapState, mapActions } from 'vuex'
import Input from "@/components/common/Input";

export default {
    name: 'Workmanager-List',
    props: {
        hide_selected: {
            type: Boolean,
            default: true
        }
    },
    components: {
        'i-select' : Select,
        "i-input": Input,
    },
    data () {
        return {
            level: '',
            result_date: '',
            resultPopup: false,
            result: '',
            state: '',
            request_id: '',
            work_person: ''
            // stateOptions: {'value':'', 'name':'상태'}.concat(CONSTANTS.STATE_TYPE)
            // state: '',
            // file: {'value':'', 'name':'파일'},
        }
    },
    computed : {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
        })
    },
    // created () {
    //     this.getInit();
    // },
    methods: {

        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),

        initList() {

        },
        initData() {
            // this.work_person = '';
            // this.result_date = '';
            this.state = '';
            this.result = '';
            this.level = '';
            this.request_id = '';
            this.work_person = '';
        },

        // 작성 팝업
        getResultPopup(checked_req_id) {
            this.initData()
            this.resultPopup = true;
            this.request_id = checked_req_id;
        },


        //수정팝업
        getUpdatePopupSave() {

            if (this.state == null || this.state === "") {
                this.showMessage({ vc:this, message:'상태를 선택하세요.' });
                return;
            }

            if (this.work_person == null || this.work_person === "") {
                this.showMessage({ vc:this, message:'담당자를 입력하세요.' });
                return;
            }

            if (this.result == null || this.department === "") {
                this.showMessage({ vc:this, message:'결과를 입력하세요.' });
                return;
            }

            if (this.result.length > 512){
                this.showMessage({ vc:this, message:'결과를 512자 이내로 작성해주세요.' });
                return;
            }

            if (this.level == null || this.level === "") {
                this.showMessage({ vc:this, message:'난이도를 선택하세요.' });
                return;
            }

            this.showAlert({ vc:this, message:'<span style="color:#1976D2;">저장</span> 하시겠습니까?' }).then(()=>{
            this.updatePopup = false;
                
                let param = {
                    // 'result_date': this.result_date,
                    'work_person': this.work_person,
                    'state': this.state,
                    'result': this.result,
                    'level': this.level,
                    'request_id': this.request_id,
                    'CALL_API_URL': '/request/result',
                }
                
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'저장되었습니다.' });
                    this.initData()
                    this.resultPopup = false;
                    this.$parent.getList();
                }).catch((err)=>{
                    this.showError({ vc:this, error:err });
                });
            }).catch(() => { });

        },
    }
}

</script>
<style>

.wrap {
    border: 1px solid #C3C3C3;
    padding: 0px 10px 10px 10px;
}
</style>