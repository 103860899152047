<template>
    <article>
        <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : list.length == 0 }"
            :columns="columns" :data="list" row-key="RNUM" :rows-per-page-options="[0]" style="height:auto;"
            hide-pagination no-data-label="조회되는 데이터가 없습니다.">
            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th v-for="col in props.cols" :key="col.name" :props="props">{{ col.label }}</q-th>
                </q-tr>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td v-for="col in props.cols" :key="col.name" :props="props">
                        <!-- <div v-if="col.name == 'closePrice'" class="number"><q-input v-model="props.row.closePrice" filled dense input-class="text-right" @keyup="getNumberChk(props.row, col.name)" @blur="getNumberChk(props.row, col.name)"/></div> -->
                        <div v-if="col.name == 'closePrice'" class="number">
                            <q-input v-model="props.row.closePrice" filled dense input-class="text-right"
                                @input="handleClosePriceInput(props.row)" />
                        </div>
                        <div v-else-if="col.name == 'compareDay'" class="number"><q-input v-model="props.row.compareDay" filled dense input-class="text-right" @keyup="getNumberChk(props.row, col.name)" @blur="getNumberChk(props.row, col.name)"/></div>
                        <div v-else-if="col.name == 'changeRate'" class="number"><q-input v-model="props.row.changeRate" filled dense input-class="text-right" @keyup="getNumberChk(props.row, col.name)" @blur="getNumberChk(props.row, col.name)"/></div>
                        <div v-else-if="col.name == 'savebutton'" class="number"><q-btn size="xs" label="저장" @click="getSave(props.row)"/></div>
                        <div v-else-if="col.name == 'deletebutton'"><q-btn size="xs" label="삭제" color="red" @click="getDelete(props.row)"/></div>
                        <div v-else>{{ col.value }}</div>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:no-data="{ icon, message }"><div class="full-width row flex-center q-gutter-sm"><q-icon size="2em" :name="icon"/> <span>{{message}}</span></div></template>
        </q-table>
        <div v-show="list.length != 0" class="flex flex-center q-mt-md">
            <q-pagination
            v-model="pagination.page"
            :max="pagesNumber"
            :max-pages="pagination.maxPages"
            :direction-links="true"
            :boundary-numbers="false"
            :boundary-links="true"
            :ellipses="false"
            @click="getList()"
        />
        </div>
    </article>
</template>
<script>
import CONSTANTS from '@/constants'
import { FormatDate } from '@/util'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    data () {
        return {
            columns: [
                { headerStyle:'text-align:center;', style:'width:*;', name: 'openDate', label: '날짜', align: 'center', field: (row) => FormatDate(row.openDate) },
                { headerStyle:'text-align:center;', style:'width:20%;', name: 'closePrice', label: '가격', align: 'right' },
                { headerStyle:'text-align:center;', style:'width:20%;', name: 'compareDay', label: '변동폭', align: 'right' },
                { headerStyle:'text-align:center;', style:'width:20%;', name: 'changeRate', label: '등락률(%)', align: 'right' },
                { headerStyle:'text-align:center;', style:'width:10%;', name: 'savebutton', label: '저장', align: 'center'},
                { headerStyle:'text-align:center;', style:'width:10%;', name: 'deletebutton', label: '삭제', align: 'center'}
              ],
            pagination: {
                page: 1,
                rowsPerPage: CONSTANTS.LIST_COUNT_PER_PAGE,
                nextCount: 0,
                maxPages: 1
            }
        }
    },
    computed : {
        ...mapState('manage', ['list', 'totcount']),
        pagesNumber () {
            return Math.ceil(Number(this.totcount) / Number(this.pagination.rowsPerPage));
        }
    },
    methods: {
        ...mapMutations('manage', ['SET_DATA']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        //리스트
        getList() {
            let param = {
                indexId: this.$parent.indexId.indexId,
                pageStr: (Number(this.pagination.page) - 1) * Number(this.pagination.rowsPerPage),
                pageSize: this.pagination.rowsPerPage,
                CALL_API_URL: '/indexPrice/list'
            }
            console.log(" param : ", param)
            this.GET_CALL(param).then((data) => {
                console.log(" 데 이 터 입 니 다 : ", data)
                this.SET_DATA(data);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        //저장
        getSave(obj) {
            this.showAlert({ vc:this, message:' '+FormatDate(obj.openDate)+ ' 일자 데이터를 <span style="color:#1976D2;">저장</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    'indexId': obj.indexId,
                    'openDate': obj.openDate,
                    'closePrice': obj.closePrice,
                    'compareDay': obj.compareDay,
                    'changeRate': obj.changeRate,
                    'CALL_API_URL': '/indexPrice/save'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'저장 되었습니다.' });
                    this.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },
        //삭제
        getDelete(obj) {
            this.showDeleteAlert({ vc:this, message:' '+FormatDate(obj.openDate)+ ' 일자 데이터를 <span style="color:red;">삭제</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    'indexId': obj.indexId,
                    'openDate': obj.openDate,
                    'CALL_API_URL': '/indexPrice/delete'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'삭제 되었습니다.' });
                    this.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },
        getNumberChk(obj, nm){
            var val = String(obj[nm]);
            val = new Intl.NumberFormat('ko-KR').format(val.replace(/,/gi, ''))

            // val = val.replace(/[^-\0-9]/g, '');
            // val = val.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
            obj[nm] = val;
        },
        handleClosePriceInput(row) {
            if (row.indexId === 'FED_ASSETS' && this.list.length > 1) {
                let firstRow = this.list[1]; // 첫 번째 데이터를 6월 14일 데이터로 사용
                let firstClosePrice = parseFloat(firstRow.closePrice.replace(/,/g, ''));
                let currentClosePrice = parseFloat(row.closePrice.replace(/,/g, ''));

                if (!isNaN(firstClosePrice) && !isNaN(currentClosePrice)) {
                    // 변동폭 계산
                    let compareDayValue = currentClosePrice - firstClosePrice;
                    row.compareDay = compareDayValue.toLocaleString('ko-KR');

                    // 등락률 계산
                    let changeRateValue = (compareDayValue / firstClosePrice) * 100;
                    row.changeRate = changeRateValue.toFixed(2);
                }
            }
        },
    }
}
</script>
<style type="text/css">
    .number .q-field__control{ padding:0 !important; height: 25px !important; }
</style>
