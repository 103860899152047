<template>
    <section>
        <div class="row q-pa-sm" ref="topWrap">
            <div class="flex justify-start" :class="[isRegisterPage ? 'col-4' : 'col-6']">
                <div class="q-pa-sm">
                <i-btn label="선택삭제" @click="deleteItems()" :disabled="isRegisterPage" />
                </div>
                <!-- <div class="col-12 q-pa-xs" :class="[isListPage? 'col-sm-2':'col-sm-3']">
                    <i-select v-model="showFlag" :options="showFlagOptions" label="상태" :disable="this.isDetailPage || this.isEditPage"/>
                </div> -->
                <!-- <div
                    class="col-10 q-pa-xs"
                    :class="[isListPage ? 'col-md-8' : 'col-sm-7']"
                
                > -->
                <div
                    class="q-pa-sm col"
                >
                    <i-select
                        v-model="newsType"
                        :options="marketNewsType"
                        label="뉴스타입"
                        option-label="name"
                        option-value="value"
                        :disabled="this.isDetailPage || this.isEditPage"

                    />
                </div>
            </div>
            
            
            
            <div class="q-pa-xs flex justify-end" :class="[isRegisterPage ? 'col-8' : 'col-6']">
                <i-btn
                    v-show="isRegisterPage || isDetailPage"
                    label="취소"
                    @click="goListPage()"
                />
                <i-btn
                    v-show="isFlashPage"
                    label="취소"
                    @click="goDetailPage()"
                />
            </div>
        </div>
        <div class="row">
            <i-list
                v-show="isListPage || isDetailPage || isEditPage || isFlashPage"
                @event-check="selectItem"
                @event-click="handleClickItem"
                class="col-6 q-pa-md"
                :newsType="newsType"
                :showFlag="showFlag"
            />
            <i-resource
                v-if="isRegisterPage"
                class="col-4 q-pa-md"
                :initMenu="resType"
            />
            <i-content
                class="q-pa-md"
                :newsType="newsType"
                :showFlag="showFlag"
                :class="[isRegisterPage ? 'col-8' : 'col-6']"
            />
        </div>
    </section>
</template>
<script>
import CONSTANTS from '@/constants'
import debug from '@/debug'
import Button from '@/components/common/Button'
import Select from '@/components/common/Select'
import List from '@/components/market/List.vue'
import Content from '@/components/market/market-news/Content.vue'
import Resource from '@/components/common/Resource.vue'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import { nowTime, addWorkDay, today } from '@/util'

export default {
    name: 'MarketNews',
    components: {
        'i-list': List,
        'i-content': Content,
        'i-resource': Resource,
        'i-btn': Button,
        'i-select': Select,
    },
    data() {
        return {
            newsType: null,
            showFlag: CONSTANTS.MARKETNEWS_TYPE[0],
            showFlagOptions: CONSTANTS.MARKETNEWS_TYPE,
            selected: [],
            resType: 'Market',
        }
    },
    computed: {
        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isEditPage',
            'isRegisterPage',
            'isFlashPage',
        ]),
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            marketNewsType: (state) => state.resource.MarketNewsType,
            pageStatus: (state) => state.page.pageStatus,
        }),
    },
    created() {
        if (this.marketNewsType.length == 0) {
            this.GET_NEWSTYPE_LIST({ newsType: 'MarketNewsType' })
                .then(() => {
                    this.newsType = this.marketNewsType[0]
                })
                .catch((error) => {
                    this.showError({ vc: this, error: error })
                })
        } else {
            this.newsType = this.marketNewsType[0]
        }
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('market_news', ['INIT_LIST']),

        ...mapActions('resource', ['GET_NEWSTYPE_LIST']),
        ...mapActions('market_news', ['ASYNC_LIST', 'DELETE_ITEMS']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),

        goDetailPage() {
            this.setPageStatus(this.PAGE_STATUS.DETAIL)
        },
        goListPage() {
            this.INIT_LIST()
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        deleteItems() {
            if (this.selected.length == 0) {
                this.showError({
                    vc: this,
                    error: '삭제할 항목을 선택해주세요.',
                })
                return
            }
            // 220819 meej 삭제시 확인 메시지 추가
            this.showAlert({vc:this,message: '삭제하시겠습니까'})
                .then(()=>{
                    var params = {}
                    params.ids = this.selected.map((x) => x.id).join(',')
                    // 마감시황은 완전 삭제!
                    // 이미 속보로 나간거는 속보용이므로 현재 데이터는 delete
                    params.force = 1
                    this.DELETE_ITEMS(params)
                        .then(() => {
                            this.showMessage({ vc: this, message: '삭제되었습니다.' })
                            this.INIT_LIST()
                            this.getList()
                            this.setPageStatus(this.PAGE_STATUS.LIST)
                            // 220819 meej 삭제후 리스트 초기화 추가
                            this.selected = []
                        })
                        .catch((err) => {
                            var error = err.message
                            this.showError({ vc: this, error: error })
                        })
                })
            
        },
        selectItem(list) {
            this.selected = list
        },
        // 시황 목록 조회하기
        getList() {
            this.INIT_LIST()
            var params = {}
            params.newsType = this.newsType.value
            params.showFlag = this.showFlag.value
            
            // 220802 meej 시황 전체(당일만조회)시 전송일자 기준으로 조회
            if(this.newsType.value == 'MARKET_CLOSED_ALL'){
                let sendDate
                if (Number(nowTime('HH')) > 13) sendDate = addWorkDay(1)
                else sendDate = today()
                params.date = sendDate
            }
            this.ASYNC_LIST(params)
        },
        handleClickItem(item) {
            debug.log('handle ', item)
            this.newsType = this.marketNewsType.find(v => v.value === item.newsType1)
            //this.newsType = item.newsType1
        }
    },
    watch: {
        pageStatus(val, old) {
            debug.log('watch pageStatus = ', val, ', old = ', old)
            if (val !== old) {
                if (val === 'List') {
                    this.getList()
                }
            }
        },
    },
}
</script>
