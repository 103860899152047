<template>
    <!-- 테마기업요약 수정화면 -->
    <article>
        <q-toolbar>
            <q-space />
            <i-btn label="취소" @click="goListPage()" />
        </q-toolbar>
        <q-scroll-area visible class="i-content-wrap">
            <div class="row">
                <div class="col-12 q-pa-xs">
                    <i-field inputLabel="테마명" :text="themeStock.themeName" />
                </div>
            </div>
            <div class="row">
                <div class="col-12 q-pa-xs">
                    <i-field
                        inputLabel="입력일"
                        :text="themeStock.createTime"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12 q-pa-xs">
                    <i-input
                        v-model="themeStock.outline"
                        inputLabel="테마기업요약"
                        type="textarea"
                        :rows="20"
                        :disable="isDeleteType"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-6 q-pa-xs">
                    <i-select
                        v-model="themeStock.type"
                        inputLabel="구분"
                        :options="themeStockType"
                        option-label="name0"
                        option-value="value0"
                    />
                    <!-- :disable="isDeleteType" -->
                </div>
                <div v-show="isDeleteType" class="col-6 q-pa-xs">
                    <!-- TODO: 편출일이 따로 필요한건지 확인 필요.  -->
                    <i-datetime
                        date
                        class="full-width"
                        v-model="themeStock.DELETE_DATE"
                        dateLabel="날짜"
                        :disable="isDeleteType"
                    />
                </div>
                
                <!-- 
                    220405 meej 구분이 공개, 준비일때도 사유 입력 가능하게 처리
                    <div v-show="isDeleteType" class="col-12 q-pa-xs"> 
                -->
                <div class="col-12 q-pa-xs">
                    <i-input
                        class="full-width"
                        v-model="themeStock.cause"
                        inputLabel="사유"
                        type="textarea"
                        :rows="5"
                    />
                    <!-- :disable="isDeleteType" -->
                </div>
                <!-- 220503 meej 메모 추가 -->
                <div class="col-12 q-pa-xs">
                    <i-input
                        class="full-width"
                        v-model="themeStock.memo"
                        inputLabel="메모"
                        type="textarea"
                        :rows="5"
                    />
                </div>
            </div>
            <div class="history">
                <div
                    v-for="(item, index) in themeStock.items"
                    :key="'history-' + index"
                >
                    <q-field dense filled disable class="q-pa-xs">
                        <template v-slot:before>
                            <label class="i-label">
                                <span v-show="index == 0"
                                    >테마기업요약 <br />
                                    수정내역</span
                                >
                            </label>
                        </template>
                        <template v-slot:control>
                            <div
                                class="self-center full-width no-outline"
                                tabindex="0"
                            >
                                {{ item.title }} <br />
                                {{ item.content }}
                            </div>
                        </template>
                    </q-field>
                </div>
            </div>
        </q-scroll-area>
        <div class="q-my-md">
            <i-btn class="full-width" label="저장" @click="write()" />
        </div>
        <!-- v-show="!isDeleteType" -->
    </article>
</template>
<script>
import Debug from '@/debug'

import Button from '@/components/common/Button'
import Input from '@/components/common/Input'
import Field from '@/components/common/Field'
import DateTime from '@/components/common/DateTime'
import Select from '@/components/common/Select'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'ThemeContent',
    components: {
        'i-btn': Button,
        'i-input': Input,
        'i-field': Field,
        'i-datetime': DateTime,
        'i-select': Select,
    },
    data() {
        return {}
    },
    created() {},
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            newsType: (state) => state.resource.newsType,
            themeStockType: (state) => state.resource.themeStockType,
            themeStock: (state) => state.stock.themeStock,
            stockType: (state) => state.resource.stockType,
        }),
        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isEditPage',
            'isRegisterPage',
        ]),
        isDeleteType() {
            return this.themeStock.type.value0 == 'DELETE'
        },
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('stock', ['SET_DETAIL', 'SET_THEME_STOCK']),
        ...mapActions('stock', ['GET_DETAIL', 'GET_THEME_STOCK']),
        ...mapActions('alert', ['showError', 'showMessage']),
        ...mapActions('stock', ['WRITE_THEME_STOCK']),

        goListPage() {
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        write() {
            var message

            if (
                !this.themeStock.outline ||
                (this.themeStock.type.value0 == 'DELETE' &&
                    !this.themeStock.cause)
            ) {
                message = '내용을 입력해주세요.'
                this.showMessage({ vc: this, message: message })
                return
            }

            var params = {}
            params.themeCode = this.themeStock.themeCode
            params.stockCode = this.themeStock.stockCode
            params.outline = this.themeStock.outline
            params.type = this.themeStock.type.value0
            /* 
                220405 meej 구분이 공개, 준비일때도 사유 입력 가능하게 처리
                220503 meej 메모 추가
            */
            params.cause = this.themeStock.cause
            params.memo = this.themeStock.memo

            if (params.type == 'DELETE') { // 편출
                params.deleteDate = this.themeStock.DELETE_DATE
            } else {
                // TODO: '편출' 이후 다시 '공개 또는 준비'로 변경했을때 처리 어떻게 할것인지
                params.deleteDate = ''
            }

            this.WRITE_THEME_STOCK(params)
                .then(() => {
                    message = '수정되었습니다.'
                    this.showMessage({ vc: this, message: message })
                    
                    /* 
                        220329 meej 수정 후 수정된 테마 종목으로 재검색 처리
                    */
                    this.getDetail(this.themeStock.stockCode)
                    //this.goListPage()
                    
                    var paramsTheme = {}
                    paramsTheme.themeCode = params.themeCode
                    paramsTheme.stockCode = params.stockCode

                    this.GET_THEME_STOCK(params)
                    .then((data) => {
                        data.type = this.themeStockType.find(
                            (v) => v.value0 == data.type
                        )
                        this.SET_THEME_STOCK(data)
                    })
                    .catch((error) => {
                        console.log('??????')
                        this.showError({ vc: this, error: error })
                    })
                })
                .catch((error) => {
                    Debug.log('error = ', error)
                    this.showError({ vc: this, error: error })
                })
        },

        getDetail(code) {
            var params = {}
            params.code = code

            this.GET_DETAIL(params)
                .then((data) => {
                    console.log
                    data.type = this.stockType.find(
                        (v) => v.value0 == data.type
                    )
                    this.SET_DETAIL(data)
                })
                .catch((error) => {
                    Debug.log('error = ', error)
                    this.showError({ vc: this, error: error })
                })
        },
    },
}
</script>
