<template>
    <article>
        <!-- <div class="flex justify-end q-pa-xs" style="height: 43.99px;"><i-btn label="신규등록" @click="getView()"/></div> -->
        <q-card>
            <q-card-section>
                <div class="row q-gutter-sm">
                    <div class="col-12 text-subtitle2" style="text-align:left;" v-show="item.id != null">
                        <span v-if="item.writerName != null">{{item.writerName}}</span><span v-else>{{item.writer}}</span>
                        ({{$moment(item.lastUpdateTime).format('YYYY-MM-DD HH:MI:SS')}})
                    </div>
                    <div class="col-12"><i-input v-model="item.title" :label="item.id == null || (item.id != null && userId == item.writer) ? '제목' : ''" :readonly="item.id == null || (item.id != null && userId == item.writer) ? false : true"/></div>
                    <div class="col-12"><i-input v-model="item.content" :label="item.id == null || (item.id != null && userId == item.writer) ? '내용' : ''" type="textarea" :rows="22" :readonly="item.id == null || (item.id != null && userId == item.writer) ? false : true"/></div>
                    <div class="col-12" v-show="item.id == null || (item.id != null && userId == item.writer)"><q-input v-model="item.upfile" filled type="file"/></div>
                    <div class="col-12" style="text-align:left;" v-show="item.file != null"><q-btn icon-right="archive" :label="item.file" no-caps @click="getItem"/></div>
                </div>
            </q-card-section>
        </q-card>
        <!-- 하단 버튼 -->
        <div class="q-my-xs">
            <div class="row">
                <i-btn v-show="item.id == null && item.pid == null" class="full-width" label="등록" @click="getSave" />
                <i-btn v-show="item.id != null && userId != item.writer" class="full-width" label="답글달기" @click="getView(item.id)" />
                <i-btn v-show="item.pid != null && item.id == null" class="full-width" label="답글등록" @click="getSave" />
                <div class="col q-pa-xs"><i-btn v-show="item.id != null && userId == item.writer" class="full-width" label="수정" @click="getSave" /></div>
                <div class="col q-pa-xs"><i-btn v-show="item.id != null && userId == item.writer" class="full-width" label="삭제" @click="getDelete" /></div>
            </div>
        </div>
    </article>
</template>
<script>
import Input from '@/components/common/Input'
import Button from '@/components/common/Button'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Content',
    components: {
        'i-input': Input,
        'i-btn': Button,
    },
    data() {
        return {
              title: '',
              content: '',
              upfile: { name: '' }
        }
    },
    computed: {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId
        }),
        ...mapState('manage', ['item', 'items']),
    },
    methods: {
        ...mapMutations('manage', ['INIT_ITEM', 'SET_ITEM', 'SET_LIST']),
        ...mapActions('manage', ['GET_CALL', 'GET_CALL_UPLOAD', 'GET_CALL_DOWNLOAD']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions( 'market_news', ['ASYNC_LIST', 'UPLOAD_ITEM','GET_PDF']),
        getView(id) {
            if(id != undefined){
                this.$parent.getView({"pid":id});
            }else{
                this.$parent.getView(null);
            }
        },
        //저장
        getSave() {
            if (!this.item.title) {
                this.showMessage({ vc:this, message:'제목을 입력하세요.' });
                return;
            }
            let param =  {}
            var formData = new FormData();
            if(this.item.id != undefined){
                formData.append('id', this.item.id);
                param.id=this.item.id
            }
            if(this.item.pid != undefined){
                formData.append('pid', this.item.pid);
                param.pid=this.item.pid
            }
            formData.append('title', this.item.title);
            formData.append('content', this.item.content);
            param.title=this.item.title
            param.content=this.item.content
            formData.append('CALL_API_URL', '/inboard/save');
            this.showAlert({ vc:this, message:'<span style="color:#1976D2;">저장</span> 하시겠습니까?' }).then(()=>{
                if(!(this.item.upfile == null || this.item.upfile == undefined || this.item.upfile[0].name == null)){
                    var fileName = this.item.upfile[0].name;
                    var fileExt = (fileName.indexOf(".") == -1 ? "" : fileName.substr(fileName.lastIndexOf(".") + 1).toLowerCase());
                    if(fileExt == "exe" || fileExt == "bat"){
                        this.showMessage({ vc:this, message:'확장자가 실행파일은 등록할수 없습니다.' });
                        return;
                    }
                    param.fname=fileName
                    // 파라미터
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        // use a regex to remove data url part
                        const base64String = reader.result
                            .replace('data:', '')
                            .replace(/^.+,/, '');

                        this.doUpload(base64String,param)
                    };
                    reader.readAsDataURL(this.item.upfile[0])
                }     
                else{
                    this.doUpload(null,param)
                }           

            }).catch(() => { });
        },
        doUpload(dataStr,param) {
            param.upfile= dataStr
            // 업로드
            console.log('param========',param)
            this.GET_CALL_UPLOAD(param)
            .then(() => {
                this.showMessage({ vc:this, message:'저장 되었습니다.' });
                this.$parent.getView(null);
                this.$parent.getList();
            })
            .catch((err) => {
                this.showError({ vc:this, error:err })
            })
        },
        //삭제
        getDelete() {
            this.showDeleteAlert({ vc:this, message:'<span style="color:red;">삭제</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    'id': this.item.id,
                    'CALL_API_URL': '/inboard/delete'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'삭제 되었습니다.' });
                    this.$parent.getView(null);
                    this.$parent.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },
        getFileDownload(){
            let param = {
                'id': this.item.id,
                'FILE_NAME': this.item.file,
                'CALL_API_URL': '/inboard/download'
            }
            this.GET_CALL_DOWNLOAD(param);
        },
        getItem() {
            let params = {}
            params.newsType = 'IN_BOARD'
            params.id = this.item.id

            this.GET_PDF(params)
            .then((res) => {
                this.makePdf(res.data)
            })
            .catch((err) => {
                var error = err.message
                this.showError({ vc:this, error:error })
            })
        },          
        makePdf(data) {
            const pdf = data.content
            const byteCharacters = atob(pdf)
            const byteNumbers = new Array(byteCharacters.length)
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i)
            }
            const byteArray = new Uint8Array(byteNumbers)
            const file = new Blob([byteArray], {
              type: 'application/pdf;base64',
            })

            const newFile = new File([file], `${data.title}.pdf`, {
              type: 'application/pdf;base64',
            })
            const fileURL = URL.createObjectURL(newFile)
            window.open(fileURL, data.title)
        },      
    }
}
</script>
