<template>
    <article>
        <!-- 구분 -->
        <div class="row i-top-wrap">
            <div class="col-12 col-sm-4 q-pa-xs">
                <i-select
                    v-model="type"
                    :options="typeOptions"
                    @input="
                        clearCondition(),
                        getThemeList()
                    "
                    option-label="name0"
                    option-value="value0"
                />
            </div>
            <div class="col-12 col-sm-8">
                <div class="flex justify-end">
                    <div class="q-pa-xs">
                        <i-select
                            v-model="condition.type"
                            :options="condition.options"
                            @input="clearCondition"
                        />
                    </div>
                    <div
                        v-if="searchFromName"
                        class="q-pa-xs"
                        @keyup.enter="getThemeList()"
                    >
                        <i-input v-model="condition.keyword" />
                    </div>
                    <div v-else class="q-pa-xs">
                        <i-stock-search
                            @get="getStock"
                            :hide_selected="false"
                            ref="stock_search"
                        />
                    </div>
                    <div class="q-pa-xs">
                        <i-btn icon="search" @click="getThemeList()" />
                    </div>
                    <div v-if="isListPage" class="q-pa-xs">
                        <i-btn label="신규" @click="goRegisterPage()" />
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-between q-my-xs q-pr-md">
            <div class="q-pa-xs text-left i-text-small flex flex-center">
                총 {{ this.allItems.length }} 개
            </div>
        </div>
        <!-- 목록 -->
        <q-table
            flat
            bordered
            dense
            class="i-table"
            :class="{ 'no-data': allItems.length == 0 }"
            :columns="columns"
            :data="allItems"
            row-key="index"
            :pagination.sync="pagination"
            hide-pagination
            no-data-label="조회되는 데이터가 없습니다."
        >
            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                    >
                        {{ col.label }}
                    </q-th>
                    <q-th v-if="isListPage" class="text-left">
                        관련
                    </q-th>
                </q-tr>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                        @click="goDetailPage(props.row)"
                    >
                        {{ col.value }}
                    </q-td>
                    <q-td v-if="isListPage" class="text-left">
                        <q-btn
                            class="q-ml-sm q-px-sm"
                            label="종목"
                            size="sm"
                            dense
                            outline
                            @click="goStock(props.row)"
                        />
                        <q-btn
                            class="q-ml-sm q-px-sm"
                            label="히스토리"
                            size="sm"
                            dense
                            outline
                            @click="goHistory(props.row)"
                        />
                    </q-td>
                </q-tr>
            </template>
        </q-table>
        <div class="row justify-center q-mt-md">
            <q-pagination
                v-model="pagination.page"
                :max="pagesNumber"
                :max-pages="pagination.maxPages"
            />
        </div>
    </article>
</template>
<script>
import Debug from '@/debug'
import CONSTANTS from '@/constants'

import Button from '@/components/common/Button'
import Select from '@/components/common/Select'
import Input from '@/components/common/Input'
import StockSearch from '@/components/common/StockSearch'

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { isEmpty, FormatDate } from '@/util'

export default {
    name: 'List',
    components: {
        'i-btn': Button,
        'i-select': Select,
        'i-input': Input,
        'i-stock-search': StockSearch,
    },
    data() {
        return {
            condition: {
                type: null,
                options: [
                    { index: 0, label: '테마명', value: 'name' },
                    { index: 1, label: '종목코드', value: 'code' },
                ],
                keyword: null,
                stock: null,
            },
            // columns: [
            //     { name: 'name', label: '테마명', align: 'left', field: row => row.name },
            //     { name: 'label', label: '상태', align: 'left', field: row => row.label },
            //     { name: 'createTime', label: '입력일', align: 'left', field: row => FormatDate(row.createTime) },
            //     { name: 'lastUpdateTime', label: '수정일', align: 'left', field: row =>  FormatDate(row.lastUpdateTime) },

            // ],
            editColumns: ['테마명', '상태'],
            type: { name0: '전체', value0: '' },
            pagination: {
                page: 1,
                rowsPerPage: CONSTANTS.LIST_COUNT_PER_PAGE,
                nextCount: 0,
                maxPages: CONSTANTS.DEFAULT_PAGE_NUM
            },
        }
    },
    created() {
        this.condition.type = this.condition.options[0]
        this.getThemeList()
    },
    computed: {
        ...mapState({
            themeType: (state) => state.resource.themeType,
            totalCount: (state) => state.theme.totalCount,
            allItems: (state) => state.theme.items,
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
        }),
        ...mapGetters({
            isListPage: 'page/isListPage',
            isEditPage: 'page/isEditPage',
            isRegisterPage: 'page/isRegisterPage',
        }),
        typeOptions() {
            return [{ name0: '전체', value0: '' }].concat(this.themeType)
        },
        pagesNumber() {
            //return Math.ceil(this.allItems.length / this.pagination.rowsPerPage)
            return ((this.allItems.length / this.pagination.rowsPerPage) < (this.pagination.rowsPerPage * this.pagination.maxPages)?Math.ceil(this.allItems.length / this.pagination.rowsPerPage):(this.allItems.length / this.pagination.rowsPerPage))
        },
        searchFromName() {
            return this.condition.type.value === this.condition.options[0].value
        },
        columns() {
            let cols = [
                /* 220630 meej 테마코드 추가 */
                {
                    name: 'code',
                    label: '테마코드',
                    align: 'left',
                    field: (row) => row.code,
                    sortable: true,
                },
                {
                    name: 'name',
                    label: '테마명',
                    align: 'left',
                    field: (row) => row.name,
                    sortable: true,
                },
                {
                    name: 'label',
                    label: '상태',
                    align: 'left',
                    field: (row) => row.label,
                    sortable: true,
                },
                {
                    name: 'createTime',
                    label: '입력일',
                    align: 'left',
                    field: (row) => FormatDate(row.createTime),
                    sortable: true,
                },
                {
                    name: 'writer',
                    label: '작성자',
                    align: 'left',
                    field: (row) => row.CREATE_WRITER,
                    sortable: true,
                },
            ]
            if (this.isListPage) {
                cols.push({
                    name: 'lastUpdateTime',
                    label: '수정일',
                    align: 'left',
                    field: (row) => FormatDate(row.lastUpdateTime),
                    sortable: true,
                })
            }

            return cols
        },
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
            SET_THEME: 'theme/SET_THEME',
        }),
        ...mapActions({
            ASYNC_THEME_LIST: 'theme/ASYNC_THEME_LIST',
        }),
        ...mapActions('alert', ['showMessage']),

        getThemeList() {
            const params = {}
            if (this.type.value0 !== '') {
                params['type'] = this.type.value0
            }
            Debug.log(
                'this.condition = ',
                this.condition.type,
                ', this.stock=',
                this.condition.stock
            )
            if (
                this.condition.type.index === 0 &&
                !isEmpty(this.condition.keyword)
            ) {
                params[this.condition.type.value] = this.condition.keyword
            } else if (
                this.condition.type.index === 1 &&
                this.condition.stock !== null
            ) {
                params[this.condition.type.value] = this.condition.stock.code
            }

            this.ASYNC_THEME_LIST(params)
        },
        goStock(item) {
            if (item.code == -999) {
                this.showMessage({
                    vc: this,
                    message: '테마종목을 제공하지 않는 테마입니다.',
                })
            } else {
                this.SET_THEME(item)
                this.$emit('goThemeStock')
            }
        },
        goHistory(item) {
            if (item.code == -999) {
                this.showMessage({
                    vc: this,
                    message: '테마히스토리를 제공하지 않는 테마입니다.',
                })
            } else {
                this.SET_THEME(item)
                this.$emit('goThemeHistory')
            }
        },
        // 등록화면으로 이동하기
        goRegisterPage() {
            this.setPageStatus(this.PAGE_STATUS.REGISTER)
        },
        // 테마 상세 조회하기
        goDetailPage(item) {
            if (item.code == -999) {
                this.showMessage({
                    vc: this,
                    message: '상세내용을 제공하지 않는 테마입니다.',
                })
            } else {
                this.SET_THEME(item)
                this.setPageStatus(this.PAGE_STATUS.DETAIL)
            }
        },
        getStock(stock) {
            Debug.log('getStock = ', stock)
            this.condition.stock = stock
        },
        clearCondition() {
            this.condition.keyword = null
            this.condition.stock = null
            if (this.$refs.stock_search !== undefined) {
                this.$refs.stock_search.clear()
            }
        },
    },
    watch: {
        pageStatus: function() {
            if (this.isListPage) {
                this.getThemeList()
            }
        },
    },
}
</script>
