<template>
  <article>
    <div>
      <i-table
        :columns="columns"
        :list="financialStocksOptions"
        @order-event="handleOrderSave"
        title="금융주 목록"
      />
    </div>
  </article>
</template>
<script>
import Table from "@/components/earning/financial/Table.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "Content",
  props: {
    hide_selected: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    "i-table": Table,
  },
  data() {
    return {
      financialStocks: "",
      columns: [{ name: "name", label: "name", field: "name", align: "left" }],
      financialStocksOptions: [],
    };
  },
  computed: {
    ...mapState({
      stockItems: (state) => state.resource.stockItems,
    }),
  },
  created() {
    this.getSetting();
  },
  methods: {
    ...mapActions("alert", ["showError", "showMessage"]),
    ...mapActions("resource", ["GET_SETTINGS", "SET_SETTINGS"]),

    // 가져오기
    getSetting() {
      this.GET_SETTINGS()
        .then((res) => {
          this.initial(res);
        })
        .catch((err) => {
          this.showError({ vc: this, error: err });
        });
    },
    // 가공
    initial(res) {
      let stockList = [];
      // 금융주 목록만 가져오기 (코드만)
      if (res.FINANCIAL_COMPANY_LIST !== undefined) {
        stockList = res.FINANCIAL_COMPANY_LIST.split(",");
      }
      this.financialStocksOptions = [];
      // 종목명 찾아서 같이 넣어줌
      stockList.forEach((el) => {
        const found = this.stockItems.find((e) => e.code == el);
        this.financialStocksOptions.push({
          name: found.name + " (" + found.code + ")",
          code: found.code,
        });
      });
    },
    handleOrderSave(str) {
      this.saveSetting({
        items: [{ FINANCIAL_COMPANY_LIST: str }],
      });
    },
    // 저장
    saveSetting(params) {
      this.SET_SETTINGS(params)
        .then(() => {
          this.showMessage({ vc: this, message: "업데이트 완료" });
          this.getSetting();
        })
        .catch((err) => {
          this.showError({ vc: this, error: err });
        });
    },
  },
};
</script>
