<template>
  <div></div>
</template>

<script>
import api from '@/services/InfostockService'

export default {
    name: 'IssuccessAdmin',
    data() {
        return {
            data: null,
        }
    },
    created() {
        this.callSuccess()
    },
    methods: {
        callSuccess(){
            this.getSuccess()   
        },
        async getSuccess() {
            const params = {
            }
            const data = await api.getSuccessAdmin(params)
            this.data = data
            console.log('admin success', this.data)
        },
    },
}
</script>

<style>

</style>