<template>
    <div id="app">
        <!-- <home v-if="!isPopupPage"/>
        <popup v-else /> -->
        <popup v-if="isPopupPage" />
        <home v-else />
    </div>
</template>

<script>
import Debug from '@/debug'
import { mapState, mapGetters, mapActions } from 'vuex'
import Home from './Home.vue'
import Popup from './Popup.vue'

export default {
    components: {
        home: Home,
        popup: Popup,
    },
    name: 'App',
    props: {
        popup: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {}
    },
    beforeCreate() {
        this.$store.dispatch('resource/GET_INIT')
        //Debug.log('App beforeCreate!!! 2',this.popup)
    },
    created() {
        Debug.log('App Create!!! 1', this.$router)
        Debug.log('App Create!!! 2', this.isPopupPage)
    },
    mounted() {
        console.log('mounted')
    },
    beforeDestroy() {
        // 화면 resize 이벤트 해제
    },
    computed: {
        ...mapState({
            pageStatus: (state) => state.page.pageStatus,
        }),
        ...mapGetters({
            isPopupPage: 'page/isPopupPage',
        }),
    },
    methods: {
        ...mapActions('resource', ['GET_INIT']),
    },
    watch: {}
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100vh;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0;
}
.full {
    height: 100% !important;
    width: 100% !important;
}
p {
    word-wrap: break-word;
}
.q-page-container {
    min-width: 1440px;
}
.i-text-small {
    font-size: 12px;
}
.i-label {
    width: 80px;
    font-size: 12px;
}
.i-wrap {
    height: calc(100vh - 50px) !important;
}

.i-content-wrap {
    height: 75vh !important;
}

/* 20210723 shb 시간외 단일가 특이종목 좌측 content 관련 추가  */
article.twincon .i-content-wrap.q-scrollarea {
    height: 30vh !important;
}
div.sn-date .q-field__before {
    padding-right: 0;
}

div.sn-date .q-field {
    padding: 4px 4px 4px 0px;
}
/* ================================================ */

/* q-table 관련 css */
.i-table {
    height: 75vh;
}
.i-table.no-data {
    height: auto;
}
.i-table .q-table__top,
.i-table .q-table__bottom,
.i-table thead tr:first-child th {
    background-color: grey;
    color: white;
}
.i-table thead tr th {
    position: sticky;
    z-index: 1;
}
.i-table thead tr:first-child th {
    top: 0;
}
.i-table.q-table--loading thead tr:last-child th {
    top: 48px;
}

.i-small-table .q-table__top,
.i-small-table .q-table__bottom,
.i-small-table thead tr:first-child th {
    background-color: grey;
}
</style>
