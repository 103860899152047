<template>
    <article>
        <!-- 구분 -->
        <q-toolbar class="i-top-wrap">
            <q-space />
            <i-btn label="삭제" @click="deleteItems()"/>
        </q-toolbar>
        <!-- 목록 -->
        <i-table
            :columns="columns" :list="list" :nextKey="nextKey"
            @row-click="getItem" @next-click="getList()" @check-event="handleCheckList"
        />
    </article>
</template>
<script>
import Debug from '@/debug'
import { FormatDate } from "@/util";

import Button from '@/components/common/Button'
import Table from "@/components/common/Table";

import { mapState, mapMutations, mapActions } from "vuex";

export default {
    name: "IRinsight",
    components: {
        'i-btn' : Button,
        "i-table": Table,
    },
    props: {
        newsType: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            selected: [],
            columns: [
                { name: "createTime", label: "등록일", align: "left", field: (row) => FormatDate(row.createTime) },
                { name: "title", label: "제목", align: "left", field: (row) => row.title },
            ]
        }
    },
    created () {
        this.INIT_LIST()
        this.getList()
    },
    computed: {
        ...mapState( 'market_news', ['list', 'nextKey']),
    },
    methods: {
        ...mapMutations( 'market_news', ['INIT_LIST']),

        ...mapActions( 'alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions( 'market_news', ['ASYNC_LIST', 'DELETE_ITEMS']),
        ...mapActions( 'market_news', ['GET_PDF']),

        // 목록 조회하기
        getList() {
            var params = {}
            params.newsType = this.newsType.value

            this.ASYNC_LIST(params)
        },

        // 삭제하기
        deleteItems() {
            if (this.selected.length == 0) {
                this.showMessage({ vc:this, message: '삭제할 항목을 선택해주세요.' })
                return
            }

            this.showAlert({vc:this, message: '선택한 항목을 삭제하시겠습니까?'})
                .then(() => {
                    this.doDelete()
            })
        },

        doDelete() {
            let params = {}
            params.ids = this.selected.map( x => x.id ).join(',')

            this.DELETE_ITEMS(params)
            .then(() => {
                this.showMessage({ vc:this, message: '삭제되었습니다.' })

                this.INIT_LIST()
                this.getList()
            })
            .catch((err) => {
                var error = err.message
                this.showError({ vc:this, error:error })
                Debug.log('error = ', error)
            })
        },

         // pdf 생성
        makePdf(data) {
            const pdf = data.content
            const byteCharacters = atob(pdf)
            const byteNumbers = new Array(byteCharacters.length)
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i)
            }
            const byteArray = new Uint8Array(byteNumbers)
            const file = new Blob([byteArray], {
              type: 'application/pdf;base64',
            })

            const newFile = new File([file], `${data.title}.pdf`, {
              type: 'application/pdf;base64',
            })

            Debug.log('newFile=', newFile)

            const fileURL = URL.createObjectURL(newFile)
            Debug.log('fileURL=', fileURL)
            Debug.log('title=', data.title)
            window.open(fileURL, data.title)
        },

        // pdf 열기
        getItem(item) {
            let params = {}
            params.newsType = item.newsType1
            params.id = item.id

            this.GET_PDF(params)
            .then((res) => {
                this.makePdf(res.data)
            })
            .catch((err) => {
                var error = err.message
                this.showError({ vc:this, error:error })
                Debug.log('error = ', error)
            })
        },

        handleCheckList(list) {
            this.selected = list;
        },
    },
};
</script>
