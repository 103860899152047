<template>
    <section>
        <!-- 구분 -->
        <div v-show="!isHistoryPage" class="row i-top-wrap">
            <div class="row col-8 q-pt-md q-px-md">
                <div class="col-3 q-pa-xs">
                    <i-select
                        v-model="condition.type"
                        :options="stockTypeOptions"
                        label="분류"
                        @input="getList()"
                        option-label="name0"
                        option-value="value0"
                    />
                </div>
                <div class="col-3 q-pa-xs">
                    <i-select
                        v-model="condition.corpCls"
                        :options="condition.corpClsOptions"
                        label="회사구분"
                        @input="getList()"
                    />
                </div>
                <div class="col-5 row">
                    <!-- 220928 meej 내용 검색 추가 -->
                    <div v-show="!isRegisterPage && !isEditPage" class="flex q-pa-xs">
                        <q-input
                            dense
                            outlined
                            v-model="keyword"
                            placeholder="내용검색"
                            @keyup.enter="getList()"
                        />
                    </div>
                    <div v-show="!isRegisterPage && !isEditPage" class="flex q-pa-xs">
                        <q-btn
                            color="black"
                            label="검색"
                            @click="getList()"
                        />
                    </div>
                </div>
            </div>
            <div class="row col-4 q-pt-md q-px-md">
                <div class="col-6 q-pa-xs">
                    <div v-show="!isEditPage" class="flex justify-end">
                        <q-btn-group v-if="!isRegisterPage" outline>
                            <q-btn
                                outline
                                color="black"
                                label="신규"
                                @click="goRegisterPage()"
                            />
                            <q-btn
                                outline
                                color="black"
                                label="히스토리"
                                @click="goHistoryPage()"
                            />
                        </q-btn-group>
                    </div>
                </div>
                <div class="col-6 q-pa-xs">
                    <div class="flex justify-end">
                        <i-btn
                            v-if="isRegisterPage"
                            class="q-ml-xs"
                            label="취소"
                            @click="goListPage()"
                        />
                    </div>
                    <i-stock-search
                        v-show="!isRegisterPage && !isEditPage"
                        @get="getStock"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <i-list
                v-show="isListPage || isRegisterPage"
                class="col-6 q-pa-md"
                :condition="condition"
            />
            <i-content
                v-show="!isHistoryPage"
                class="col-6 q-pa-md"
                :condition="condition"
            />
            <i-theme-content v-if="isEditPage" class="col-6 q-pa-md" />
            <i-history v-if="isHistoryPage" class="col-12 q-pa-sm" />
        </div>
    </section>
</template>
<script>
import Debug from '@/debug'
import CONSTANTS from '@/constants'

import Button from '@/components/common/Button'
import Select from '@/components/common/Select'
import StockSearch from '@/components/common/StockSearch'
import List from '@/components/stock/stock/List.vue'
import Content from '@/components/stock/stock/Content.vue'
import ThemeContent from '@/components/stock/stock/ThemeContent.vue'
import History from './History.vue'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Stock',
    components: {
        'i-btn': Button,
        'i-stock-search': StockSearch,
        'i-list': List,
        'i-content': Content,
        'i-theme-content': ThemeContent,
        'i-history': History,
        'i-select': Select,
    },
    data() {
        return {
            // 조건
            condition: {
                type: { name0: '전체', value0: null },
                corpCls: { label: '전체', value: null },
                corpClsOptions: [{ label: '전체', value: null }],
                stock: '',
            },
            keyword : null,
        }
    },
    created() {
        this.condition.corpClsOptions.push(...CONSTANTS.CORPCLS_TYPE)
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            stockType: (state) => state.resource.stockType,
            stockItem: (state) => state.stock.item,
            list: (state) => state.stock.list,
        }),

        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isEditPage',
            'isRegisterPage',
            'isHistoryPage',
        ]),

        stockTypeOptions() {
            var arr = [{ name0: '전체', value0: null }]
            arr.push(...this.stockType)
            return arr
        },
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('stock', [
            'SET_LIST',
            'SET_DETAIL',
            'SET_CODE',
            'SET_THEME_STOCK',
            'INIT_LIST',
            'INIT_DETAIL',
        ]),
        ...mapActions('stock', [
            'GET_LIST',
            'GET_DETAIL',
            'GET_HISTORY',
            'GET_THEME_STOCK',
            'WRITE_ITEM',
            'DELETE_ITEM',
            'ORDER_ITEMS',
        ]),
        ...mapActions('alert', ['showError']),

        getStock(stock) {
            this.getDetail(stock.code)
        },
        goListPage() {
            this.setPageStatus(this.PAGE_STATUS.LIST)
            this.getDetail(this.list[0].code)
        },
        goEditPage() {
            this.setPageStatus(this.PAGE_STATUS.EDIT)
        },
        goHistoryPage() {
            // this.SET_CODE(this.stockItem.code)
            var params = {
                code: this.stockItem.code,
            }
            this.GET_HISTORY(params)

            this.setPageStatus(this.PAGE_STATUS.HISTORY)
        },
        goRegisterPage() {
            this.setPageStatus(this.PAGE_STATUS.REGISTER)

            this.INIT_DETAIL()
        },
        // 목록 조회하기
        getList() {
            // 220928 meej 내용검색추가 : 기업개요 like 검색
            var params = {
                type: this.condition.type.value0,
                corpCls: this.condition.corpCls.value,
                keyword : this.keyword
            }

            this.GET_LIST(params)
                .then((data) => {
                    var items = data.items
                    this.SET_LIST(items)

                    if (items[0]) {
                        this.getDetail(items[0].code)
                    }
                })
                .catch((err) => {
                    Debug.log('error = ', err)
                    var error = err.message
                    this.showError({ vc: this, error: error })
                })
        },
        // 상세 조회하기
        getDetail(code) {
            var params = {}
            params.code = code

            this.GET_DETAIL(params)
                .then((data) => {
                    data.type = this.stockType.find(
                        (v) => v.value0 == data.type
                    )
                    this.SET_DETAIL(data)
                })
                .catch((error) => {
                    Debug.log('error = ', error)
                    this.showError({ vc: this, error: error })
                })
        },
    },
    watch : {
        keyword(val){
            // 20928 meej 앞뒤 공백 제거
            this.keyword = val.trimStart().trimEnd()
        }
    }
}
</script>

<style>
/* common */

/* xs */
@media only screen and (max-width: 599px) {
}

/* sm */
@media only screen and (max-width: 1023px) {
}

/* md */
@media only screen and (max-width: 1439px) {
}

/* lg */
@media only screen and (max-width: 1919px) {
}

/* xl */
@media only screen and (min-width: 1920px) {
}
</style>
