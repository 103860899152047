<template>
    <section>
        <div class="row">
                <!-- List -->
                <div class="q-pa-md col-6" stype="text-align:left" v-show="!hideList">
                    <div class="row">
                        <div class="col">
                            <q-radio v-model="radioType" val="MARKET_THEME_NEWS" label="테마시황" />
                        </div>
                        <div class="col">
                            <q-radio v-model="radioType" val="MARKET_THEME_DAILY" label="데일리테마" />
                        </div>
                        <div class="col">
                            <i-btn  label="삭제" @click="deleteItems()" />
                        </div>
                    </div>
                    <i-list
                        :newsType="radioType"
                        ref="listRef"
                    />
                </div>
                
                <i-content
                    v-if="(radioType ==='MARKET_THEME_NEWS')"
                    class="q-pa-md col-6"
                    :newsType="radioType"
                    @event-made-daily="handleMadeDaily"
                />
                <i-daily
                    v-if="(radioType ==='MARKET_THEME_DAILY')"
                    class="q-pa-md "
                    :class="hideList?'col-12':'col-6'"
                    :newsType="radioType"
                />
        </div>
    </section>
</template>
<script>
import List from '@/components/featured/daily/List.vue'
import Button from '@/components/common/Button.vue'
import Content from '@/components/featured/daily/ThemeMarket.vue'
import Daily from '@/components/featured/daily/Daily.vue'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import debug from '@/debug'
export default {
    name: 'Theme',
    components: {
        'i-list': List,
        'i-content': Content,
        'i-daily': Daily,
        'i-btn':Button
    },
    data() {
        return {
            radioType: null,
            newsType: {
                label: '',
                dailyType: 'MARKET_THEME_DAILY',
                marketType: 'MARKET_THEME_NEWS',
                flashType: 'MARKET_FLASH_THEME_FEATURED',
            },
            
        }
    },
    created() {
        this.setPageStatus(this.PAGE_STATUS.LIST)
        this.radioType = 'MARKET_THEME_NEWS'
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
        }),
        ...mapGetters('page', ['isEditPage', 'isRegisterPage', 'isListPage', 'isDetailPage']),
        hideList() {
            return this.isEditPage && (this.radioType === 'MARKET_THEME_DAILY')
        }
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
            REQUEST_RESOURCE: 'resource/REQUEST_RESOURCE',
            SET_PREVIEW: 'flash/SET_PREVIEW',
            SET_ITEM:'theme_featured/SET_ITEM'
        }),

        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),

        ...mapActions('market_news', ['GET_DETAIL']),
        ...mapActions('flash', ['WRITE_FLASH']),

        // 삭제하기
        deleteItems() {
            this.$refs.listRef.deleteItems()
        },
        handleMadeDaily() {
            debug.log('handleMadeDaily!!!!!!')
            this.radioType='MARKET_THEME_DAILY'
        }
    },
    watch: {
        radioType(val, old) {
            if(val !== old) {
                this.SET_ITEM(null)
            }
        }
    },
}
</script>

<style>
/* common */

/* xs */
@media only screen and (max-width: 599px) {
}

/* sm */
@media only screen and (max-width: 1023px) {
}

/* md */
@media only screen and (max-width: 1439px) {
}

/* lg */
@media only screen and (max-width: 1919px) {
}

/* xl */
@media only screen and (min-width: 1920px) {
}
</style>
