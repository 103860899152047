<template>
    <article>
        <q-scroll-area visible class="i-content-wrap">
            <q-card flat bordered>
                <!-- 등록 -->
                <q-card-section>
                    <div v-if="!isRegisterPage" class="col-12 col-md-12 q-pa-xs">
                        <span style="font-size:15px;font-weight:bold"> {{this.item.themeName}} </span>
                    </div>
                    <div v-if="!isRegisterPage" class="row q-my-xs">
                        <!-- 220603 meej createTime -> showDate 변경 -->
                        <i-field
                            class="col-12 col-md-6"
                            inputLabel="입력날짜"
                            :text="formatDate(item.showDate)"
                        />
                        <i-field
                            class="col-12 col-md-6"
                            inputLabel="변경날짜"
                            :text="formatDate(item.lastUpdateTime)"
                        />
                    </div>
                    <i-datetime
                        class="row q-my-xs"
                        v-if="isRegisterPage"
                        date
                        v-model="insertDate"
                        dateLabel="입력날짜"
                    />
                    <!-- <div  class="row q-my-xs">
                        <i-field
                            class="col-12 col-md-6"
                            inputLabel="입력날짜"
                        />
                    </div> -->
                    <div class="row q-my-xs">
                        <q-btn
                            class="col-md-2 text-left focus-non"
                            flat
                            rounded
                            color="primary"
                            label="관련종목"
                        />
                        <div class="col-md-8 text-left">
                            <q-chip
                                v-for="(stock, i) in checkList.stocks"
                                :key="'stock' + i"
                                color="secondary"
                                :disable="disableDelete || isDetailPage"
                                text-color="white"
                                removable
                                @remove="removeStock(i)"
                            >
                                {{ stock.stockName }}({{ stock.stockCode }})
                            </q-chip>
                        </div>
                    </div>
                    <div class="row q-my-xs">
                        <q-btn
                            class="col-md-2 text-left focus-non"
                            flat
                            rounded
                            color="primary"
                            label="주도주"
                        />
                        <div class="col-md-8 text-left">
                            <q-chip
                                v-for="(stock, i) in checkList.leads"
                                :key="'stock' + i"
                                color="secondary"
                                :disable="disableDelete || isDetailPage"
                                text-color="white"
                                removable
                                @remove="removeLEAD(i)"
                            >
                                {{ stock.stockName }}({{ stock.stockCode }})
                            </q-chip>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 q-pa-xs">
                        <i-input
                            v-model="content"
                            type="textarea"
                            inputLabel="히스토리"
                            :rows="15"
                            class="full-width"
                            :disabeld="isDetailPage || disableDelete"
                            @focus="goEditPage(item)"
                        />
                    </div>
                    <div class="col-12 ">
                        <i-field inputLabel="차트표시" check v-model="chart" />
                    </div>
                    <div class="col-12 " v-if="!isRegisterPage">
                        <i-field
                            check
                            v-model="checkDelete"
                            inputLabel="삭제"
                            :disable="disableDelete"
                        />
                        <i-input
                            v-if="checkDelete"
                            v-model="item.comment"
                            :disable="disableDelete || isDetailPage"
                            class="offset-2 col-10 q-pa-xs"
                            type="textarea"
                            label="사유"
                            @input="selectContent"
                        />
                    </div>
                </q-card-section>
            </q-card>
        </q-scroll-area>
        <!-- 하단 버튼 -->
        <div class="q-my-md">
            <i-btn
                v-if="isRegisterPage"
                class="full-width"
                label="등록"
                @click="register()"
            />
            <i-btn
                v-else
                class="full-width"
                label="수정"
                @click="edit()"
                :disable="disableDelete"
            />
        </div>
    </article>
</template>
<script>
import Debug from '@/debug'
// import CONSTANTS from '@/constants'
import {
    optionsValue,
    objToString,
    stringToArray,
    FormatDate,
    isEmpty,
} from '@/util'

import Button from '@/components/common/Button'
import Field from '@/components/common/Field'
import Input from '@/components/common/Input'
import DateTime from '@/components/common/DateTime'

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'List',
    components: {
        'i-btn': Button,
        'i-field': Field,
        'i-input': Input,
        'i-datetime': DateTime,
    },
    props: {
        theme: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            content: '',
            chart: false,
            checkDelete: false,
            comment: '',
            disableDelete: false,
            insertDate: '',
        }
    },
    created() {
        // EventBus를 통한 리소스 추가하기
        Debug.log('ThemeHistory Content create theme = ', this.theme)
        this.INIT_CHECKLIST()
        if (!this.isRegisterPage) {
            this.initItem()
        }
    },
    computed: {
        ...mapState({
            item: (state) => state.theme_news.item,
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            checkList: (state) => state.theme_stock.checkList,
            selectedDate: (state) => state.theme_stock.selectedDate,
        }),
        ...mapGetters({
            isDetailPage: 'page/isDetailPage',
            isEditPage: 'page/isEditPage',
            isRegisterPage: 'page/isRegisterPage',
        }),
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
        }),
        ...mapMutations('resource', ['REQUEST_RESOURCE']),
        ...mapMutations('theme_news', ['INIT_LIST', 'SET_ITEM']),
        ...mapActions('theme_news', ['WRITE_ITEM', 'DELETE_ITEM', 'ASYNC_THEMENEWS_LIST', 'GET_THEMENEWS_LIST']),
        ...mapMutations('theme_stock', [
            'REMOVE_CHECKLIST_ITEM',
            'INIT_CHECKLIST',
            'SET_CHECKLIST',
            'SET_SELETED_DATE'
        ]),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),

        initItem() {
            if (this.item !== undefined && this.item !== null) {
                this.INIT_CHECKLIST()

                this.chart = this.item.CHART
                let stocks = stringToArray(
                    this.item.STOCKS,
                    'stockName',
                    'stockCode'
                )
                let leads = stringToArray(
                    this.item.LEAD_STOCK,
                    'stockName',
                    'stockCode'
                )
                Debug.log('stocks ', stocks, 'ledads', leads)
                this.SET_CHECKLIST({
                    stocks: stocks ? stocks : [],
                    leads: leads ? leads : [],
                })

                this.content = this.item.content
                this.checkDelete = this.item.crud === 'D'
                this.comment = isEmpty(this.item.COMMENT)
                    ? ''
                    : this.item.COMMENT
                this.disableDelete = this.checkDelete
            }
        },
        goEditPage() {
            if (!this.isRegisterPage) {
                /* 
                    220523 meej 입력일의 종목리스트 검색되도록 설정
                    220603 meej createTime -> showDate 변경
                 */
                this.SET_SELETED_DATE(this.item.showDate)
                // this.setPageStatus(this.PAGE_STATUS.EDIT)
                this.$emit('set-content-theme', this.item)
            }
        },
        goListPage() {
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        goDetailPage() {
            this.setPageStatus(this.PAGE_STATUS.DETAIL)
        },
        /* 
            220523 meej 테마히스토리 조회 추가
        */
        getThemeHistory(code) {
            Debug.log('code = ', code, ', theme = ', this.theme)
            if (code === undefined && this.theme === null) {
                return
            } else if (code === undefined && this.theme !== null) {
                code = this.theme.code
            }

            const params = {
                newsType: 'THEME_HISTORY',
                themeCode: code,
            }
            Debug.log('params = ', params)

            this.ASYNC_THEMENEWS_LIST(params)
        },
        register() {
            //NewsOption.STOCKS, NewsOption.CHART, NewsOption.LEAD_STOCK
            const params = {
                newsType: 'THEME_HISTORY',
                themeCode: this.theme.code,
                content: this.content,
                createTime: this.insertDate,
            }
            params.options = this.makeOptions()
            this.showAlert({ vc: this, message: '등록하시겠습니까?' }).then(
                () => {
                    this.write(params, '등록되었습니다.')
                }
            )
        },
        makeOptions() {
            let options = {}
            if (this.checkList.stocks && this.checkList.stocks.length > 0) {
                var obj = {}
                for (var stock of this.checkList.stocks) {
                    obj[stock.stockCode] = stock.stockName
                }
                options.STOCKS = optionsValue(obj)
            }
            if (this.checkList.leads && this.checkList.leads.length > 0) {
                var obj2 = {}
                for (var lead of this.checkList.leads) {
                    obj2[lead.stockCode] = lead.stockName
                }
                options.LEAD_STOCK = optionsValue(obj2)
            }
            options.CHART = this.chart ? 1 : 0

            return objToString(options)
        },

        edit() {
            const params = {
                newsType: 'THEME_HISTORY',
                themeCode: this.item.themeCode,
                content: this.content,
                id: this.item.id,
            }
            params.options = this.makeOptions()
            const status = isEmpty(this.item.lastUpdateTime)
            if (status !== this.checkDelete) {
                if (this.checkDelete) {
                    this.doDelete()
                    return
                }
            }
            this.showAlert({ vc: this, message: '수정하시겠습니까?' }).then(
                () => {
                    this.write(params, '수정되었습니다.')
                }
            )
        },
        write(params, message) {
            this.WRITE_ITEM(params)
                .then(() => {
                    this.showMessage({ vc: this, message: message })
                    this.INIT_CHECKLIST()
                    
                    /* 
                        220523 meej 등록, 수정 후 새로 조회, detailpage로 이동
                     */
                    // this.goListPage()
                    // const param = {
                    //     newsType: 'THEME_HISTORY',
                    //     themeCode: params.themeCode,
                    // }
                    // this.GET_THEMENEWS_LIST(param).then((el)=>{
                    //     el.items[0].CHART = el.items[0].CHART == 1 ? true : false
                    //     this.SET_ITEM(el.items[0])
                    //     this.goDetailPage()
                    // })

                    this.$emit('get-themenews-list', params)
                })
                .catch((err) => {
                    var error = err.message
                    this.showError({ vc: this, error: error })
                })
        },
        removeStock(index) {
            this.REMOVE_CHECKLIST_ITEM({ stock: index })
        },
        removeLEAD(index) {
            this.REMOVE_CHECKLIST_ITEM({ LEAD: index })
        },
        doDelete() {
            const params = {
                id: this.item.id,
                content: this.comment,
                newsType: 'THEME_HISTORY',
            }
            this.showAlert({
                vc: this,
                message: '히스토리를 삭제하시겠습니까?',
            }).then(() => {
                this.delete(params)
            })
        },
        delete(params) {
            this.DELETE_ITEM(params)
                .then(() => {
                    this.showMessage({
                        vc: this,
                        message: '삭제가 완료됬습니다',
                    })
                    /* 
                        220523 meej 삭제 후 새로 조회, detailpage로 이동
                     */
                    // this.goListPage()
                    this.INIT_LIST()
                    // this.getThemeHistory(params.themeCode)
                    this.$emit('get-history-list')
                    this.disableDelete = true
                    this.goDetailPage()
                })
                .catch((err) => {
                    var error = err.message
                    this.showError({ vc: this, error: error })
                })
        },
        formatDate(val) {
            return isEmpty(val) ? '' : FormatDate(val)
        },
        selectContent() {
            this.$emit('set-content-theme', this.item)
        }
    },
    watch: {
        theme(val, old) {
            if (this.isRegisterPage) {
                if (val !== old) {
                    // 221025 meej 에러_주석처리
                    // this.requestResource()
                }
            }
        },
        item(val, old) {
            if (val !== old) {
                if (val !== null) {
                    this.initItem()
                }
            }
        },
        /* 
            220523 meej 입력일 선택시 stockList도 해당날짜에 맞춰 자동 조회
         */
        insertDate(val){
            this.SET_SELETED_DATE(val)
        },
        /* 220603 meej date 바꾸면 content의 입력일도 바뀌게 설정 */
        selectedDate(val){
            this.insertDate = val
        }
    },
}
</script>
<style>
/* .focus-non {
    cursor: default !important;
} */
</style>
