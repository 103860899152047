<template>
    <article>
        <!-- 구분 -->
        <div class="row i-top-wrap">
            <div class="col-3 q-pa-xs">
                <q-select v-model="type" :options="flashNewsType" label="분류" option-label="name" option-value="value"
                    @input="selectIntput" dense filled />
            </div>
            <!-- 220708 meej 속보예약만 삭제 -->
            <!-- <div class="col-5 q-pa-xs">
                <i-field check v-model="onlyReserve" label="속보예약만" @input="checkReserve"/>
            </div> -->
            <!-- 220708 meej 제휴언론사포함 추가, 뉴스ID삭제 추가 -->
            <div class="col-2 q-pa-xs">
                <i-field check v-model="withArticle" label="제휴언론사포함" @input="checkArticle"/>
            </div>
            <div class="col-5 q-pa-xs q-gutter-xs row">
                <span class="flex"><q-input v-model="newsId" dense outlined placeholder="뉴스 ID"/></span>
                <span class="q-pb-xs flex">
                    <i-btn label="삭제" @click="deleteById()"/></span>
            </div>
            <div class="col-2 q-pa-xs q-gutter-xs row">
                <span class="q-pb-xs flex"><i-btn label="재전송" @click="resendNewsFlash()"/></span>
                <span class="q-pb-xs flex"><q-btn round size="md" icon="refresh" @click="selectIntput()"/></span>
            </div>
        </div>
        <!-- 목록 -->
        <!-- 221026 meej min-height 설정 -->
        <q-table
            flat bordered dense class="i-table" hide-bottom
            :class="{ 'no-data' : list.length == 0 }" row-key="index"
            :columns="columns" :data="list" selection="multiple"
            :selected.sync="selected"
            :pagination.sync="pagination" hide-pagination no-data-label="조회되는 데이터가 없습니다."
            style="min-height:612.25px"
            >
            <template v-slot:header="props">
                
                <q-tr :props="props">
                    <q-th class="text-left">
                        
                    </q-th>
                    <q-th
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                    >
                        {{ col.label }}
                    </q-th>
                    <q-th class="text-left" />
                </q-tr>
            </template>

            <template v-slot:body="props">
                
                <q-tr :props="props">
                    <q-td class="text-left">
                        <q-checkbox
                            @input="handleCheckBox(props.row)"
                            v-model="props.row.checked"
                            size="xs"
                        />
                    </q-td>
                    <!-- 22.10.26 SUYE 뉴스속보 삭제도 현출, 삭제일 경우 빨간색 처리 -->
                    <q-td
                        :class="{'crudColourRed': props.row.crud == 'D' && col.value == '삭제'}"
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                        @click="getItem(props.row)"
                    >
                        <!-- 22.01.28 한수예 개장전★주요이슈 점검 제어문 start -->
                        <!-- <span v-if="props.row.title == '개장전 주요이슈'" mainIssue = props.row.title>
                            <span v-if="col.value == props.row.title">{{ mainIssue }}</span>
                            <span v-else>{{ col.value }}</span>
                         </span> 
                         <span v-else> -->
                             {{col.value}}
                         <!-- </span> -->
                        <!-- 22.01.28 한수예 개장전★주요이슈 점검 제어문 end -->
                    </q-td>
                    <q-td class="text-left" >
                        <!-- 22.10.26 SUYE 뉴스속보 삭제권한 제한 -->
                        <q-btn size="sm" v-if="acceptedID.includes(userId) && !(props.row.crud == 'D')" outline dense @click="deleteItem(props.row)" label="삭제" />
                        <q-btn size="sm" v-else-if="!acceptedID.includes(userId) && !(props.row.crud == 'D')" outline dense label="삭제권한없음" disabled />
                        <!-- 230315 meej 뉴스속보 삭제 재요청 추가 -->
                        <q-btn size="sm" v-else-if="acceptedID.includes(userId) && props.row.crud == 'D'" outline dense @click="deleteItem(props.row)" label="삭제재요청"/>
                        <q-btn size="sm" v-else-if="!acceptedID.includes(userId) && props.row.crud == 'D'" outline dense label="삭제됨" disabled />
                    </q-td>
                </q-tr>
            </template>

        </q-table>
        <div class="row justify-center q-mt-md">
            <q-pagination
                v-model="pagination.page"
                :max="pagesNumber"
                :max-pages="pagination.maxPages"
                boundary-numbers
            />
            <div>
                <q-btn flat icon="navigate_next" color="dark" @click="goNext()" />
            </div>
        </div>
        <!-- <div v-else class="row">
            <i-table :columns="columns" :list="list" :nextKey="nextKey"
                @next-click="getList()" @check-event="handleCheckList" />
        </div> -->
    </article>
</template>
<script>
import CONSTANTS from '@/constants'
import { mapState, mapMutations, mapActions,mapGetters } from 'vuex'
import Debug from '@/debug'
import Button from '@/components/common/Button'
import Field from '@/components/common/Field'

import { CutDownText,FormatTime, FormatDate } from '@/util'
export default {
    name: 'List',
    components: {
        'i-btn' : Button,
        'i-field': Field
    },
    props: {
        refreshList: {
            type: Boolean,
            default: true,
        }
    },
    data () {
        return {
            type: null,
            selected: [],
            timer: null,
            onlyReserve: false,
            columns: [
                { name: 'writer', label: '작성자', align: 'left', field: row => row.userName ? row.userName : '-' }, // 22.10.26 SUYE 작성자 추가
                { name: 'sendDate', label: '일자', align: 'left', field: row => FormatDate(row.sendDate) },
                { name: 'sendTime', label: '시간', align: 'left', field: row => FormatTime(row.sendTime) },
                { name: 'title', label: '제목', align: 'left',field: row => CutDownText(row.title, 30) }, // 220818 meej 50 -> 30자로 줄임
                { name: 'label', label: '상태', align: 'left', field: row => row.crud == 'D' ? '삭제' : row.label }
            ],
            pagination: {
                page: 1,
                rowsPerPage: CONSTANTS.LIST_COUNT_PER_PAGE,
                nextCount: 0,
                maxPages: CONSTANTS.DEFAULT_PAGE_NUM
            },
            withArticle: false,
            newsId: null,
            acceptedID: ['dongdong2', 'kangwook', 'meeJ123', 'nestamaldini', 'silverju208', 'thflatk2', 'thory112', 'yb080707'], // 뉴스속보 삭제권한 ID
        }
    },
    created () {
        this.refresh()
        // this.INIT_LIST()
        // this.getFlashList()
        
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
            list : state => state.flash.list,
            nextKey : state => state.flash.nextKey,
            flashNewsType: state => state.flash.NewsFlashType,
            userId: state => state.session.userId,
            username: state => state.session.username,
        }),
        ...mapGetters('page', [
            'isPopupPage',
        ]),
        pagesNumber () {
            return ((this.list.length / this.pagination.rowsPerPage) < (this.pagination.rowsPerPage * this.pagination.maxPages)?Math.ceil(this.list.length / this.pagination.rowsPerPage):(this.list.length / this.pagination.rowsPerPage))
        },
        isFailed() {
            return (this.type != null && this.type.value === 'MARKET_NEWSFLASH_FAIL')
        }
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('flash', ['INIT_LIST', 'SET_PREVIEW']),
        ...mapActions("flash", ["ASYNC_LIST", "RESEND_FLASH", "DELETE_LIST","DETAIL_FLASH"]),

        ...mapActions("alert", ["showError", "showMessage", "showAlert"]),

        getItem(item) {
            Debug.log('getItem',item)
            const param = {
                newsType: item.newsType1,
                id: item.id
            }
            this.DETAIL_FLASH(param)
                .then((res) => {
                    this.SET_PREVIEW(res)
                    this.getFlashItem()
                })
                .catch((e) => {
                    Debug.log(`e=${e}`)
                    this.showError({vc:this, error:e})
                })
            // debug.log('getItem item = ', item)
            // todo 선택시 어떻게?
        },
        goNext() {
            this.pagination.nextCount++
            this.pagination.page = this.pagination.nextCount * 5
            this.getFlashList()
        },
        //flash
        getFlashItem() {
            this.windowRef = window.open(
                    `/view/popup.htm`,
                    '시황속보',
                    'width=620,height=820'
                )
            if(this.windowRef) {
                this.windowRef.addEventListener('beforeunload', this.closePreview)
            }
        },
        // closePreview() {
        //     this.windowRef = null;
        //     this.showMessage({vc:this,message:"전송되었습니다."})
        // },
        selectIntput() {
            Debug.log('selectIntput = ',this.type)
            this.INIT_LIST()
            this.getFlashList()
        },
        getFlashList() {
            var params ={}

            if(this.type !== null) {
                params.newsType = this.type.value
            }
            // if(this.onlyReserve) {
            //     //속보예약만 
            //     params.reserve = 1
            // }
            if(this.withArticle) {
                // 제휴언론사 기사도 같이 
                params.article = 1
            }
            // 속보 목록 API
            this.ASYNC_LIST(params)
        },
        refresh() {
            if(this.type === null && this.flashNewsType.length > 0) {
                this.type = this.flashNewsType[0]
            }
            if(this.isPopupPage) {
                Debug.log('isPopup!!!!!!!!!!!!!!!!!!')
                const self = this
                self.INIT_LIST()
                self.getFlashList()
                this.timer = setInterval(()=>{
                    if(self.refreshList) {
                        self.INIT_LIST()
                        self.getFlashList()
                    }
                    
                }, 20000)
            } else {
                this.INIT_LIST()
                this.getFlashList()
            }
            
        },
        resendNewsFlash() {
            if (this.selected.length == 0) {
                this.showMessage({ vc:this, message:'재전송할 속보를 선택해주세요.' })
                return
            }

            this.showAlert({ vc:this, message:'선택한 속보를 재전송 하시겠습니까?' })
                .then(()=>{
                    let params = {}
                        params.ids = this.selected.map( x => x.id ).join(',')
                    this.RESEND_FLASH(params)
                })

        },
        handleCheckBox(item) {
            Debug.log('handleCheckBox ', item)
            //item.checked = !item.checked
            if (item.checked) {
                this.selected.push(item);
            } else {
                var index = this.selected.indexOf(item);
                if (index !== -1) {
                this.selected.splice(index, 1);
                }
            }
        },
        
        checkReserve() {
            Debug.log('checkReserve')
            this.INIT_LIST()
            this.getFlashList()
        },
        // 220708 meej 제휴언론사 포함
        checkArticle() {
            Debug.log('checkArticle')
            this.INIT_LIST()
            this.getFlashList()
        },
        // 220708 meej 뉴스아이디로 삭제
        deleteById(){
            if (this.newsId == null || this.newsId.trim() == '') {
                this.showMessage({ vc:this, message:'삭제하려는 속보 ID 를 입력해주세요.' })
                return
            }

            this.showAlert({ vc:this, message: '[ '+this.newsId+' ] 삭제하시겠습니까?' })
                .then(()=>{
                    this.doDeleteById()
                })
            this.newsId
        },
        // 220708 meej 뉴스아이디로 삭제
        doDeleteById() {
            var params = {}
            params.ids = this.newsId
            this.DELETE_LIST(params)
                .then(()=>{
                     let message = '성공하였습니다.'
                    this.showMessage({ vc:this, message:message })
                    this.INIT_LIST()
                    this.getFlashList()
                    this.selected = []
                })
                .catch((err) => {
                    this.showError({ vc:this, error:err })
                })
        },
        deleteItem(row) {
            this.selected.push(row)
            this.showAlert({ vc:this, message:'선택한 속보를 삭제하시겠습니까?' })
                .then(()=>{
                    this.doDelete()
                })
        },
        deleteItems() {

            if (this.selected.length == 0) {
                this.showMessage({ vc:this, message:'삭제할 속보를 선택해주세요.' })
                return
            }

            this.showAlert({ vc:this, message:'선택한 속보를 삭제하시겠습니까?' })
                .then(()=>{
                    this.doDelete()
                })
        },
        doDelete() {
            var params = {}
            params.ids = this.selected.map( x => x.id ).join(',')
            this.DELETE_LIST(params)
                .then(()=>{
                    //let total = res.fail + res.success
                   // let message = total+'중 '+res.success+'개 성공하였습니다.'
                     let message = '성공하였습니다.'
                    this.showMessage({ vc:this, message:message })
                    this.INIT_LIST()
                    this.getFlashList()
                    this.selected = []
                })
                .catch((err) => {
                    this.showError({ vc:this, error:err })
                })
        }
    },
    beforeDestroy(){
        clearInterval(this.timer)
        this.timer = null
    },
    watch: {
        selected(val) {
            // 선택되있으면  refresh 를 멈춘다.
            if(val.length > 0) {
                if(this.timer  !== null) {
                    Debug.log('watch stop timer')
                    clearInterval(this.timer)
                    this.timer = null
                }
            } else {
                 Debug.log('watch =', val.length)
                //있으면 다시 timer
                if(this.timer === null) {
                    Debug.log('watch start timer')
                    this.refresh()
                }
            }
        },
    }
}
</script>
<style>
.crudColourRed{
    color: red;
}
</style>