<template>
    <article>
        <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : list.length == 0 }"
            :columns="columns" :data="list" row-key="RNUM" :rows-per-page-options="[0]" style="height:auto;"
            :v-model="pagination" hide-pagination no-data-label="조회되는 데이터가 없습니다.">
            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th v-for="col in props.cols" :key="col.name" :props="props" :style="col.style">
                        <div v-if="col.name == 'checked'"><q-checkbox v-model="allCheck" dense @input="getAllCheck()"/></div>
                        <div v-else>{{ col.label }}</div>
                    </q-th>
                </q-tr>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td v-for="col in props.cols" :key="col.name" :props="props">
                        <div v-if="col.name == 'checked'"><q-checkbox v-model="props.row.checked" dense/></div>
                        <div v-else>{{ col.value }}</div>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:no-data="{ icon, message }"><div class="full-width row flex-center q-gutter-sm"><q-icon size="2em" :name="icon" /> <span>{{message}}</span></div></template>
        </q-table>
        <div v-show="list.length != 0" class="flex flex-center q-mt-md">
            <q-pagination
            v-model="pagination.page"
            :max="pagesNumber"
            :max-pages="pagination.maxPages"
            :direction-links="true"
            :boundary-numbers="false"
            :boundary-links="true"
            :ellipses="false"
            @click="getList()"
        />
        </div>
    </article>
</template>
<script>
import CONSTANTS from '@/constants'

import { FormatDate, numberFormat, stringToStockValue } from '@/util'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    components: {

    },
    data () {
        return {
            allCheck: false,
            yearOptions: [{'value':'', 'name':'연도'}],
            ifrsOptions : CONSTANTS.COM_IFRS2,
            quarterOptions : CONSTANTS.COM_QUARTER2,
            unitOptions: CONSTANTS.COM_UNIT2,
            stockDivisionOptions: CONSTANTS.COM_STOCK_DIV2,
            columns: [
                    { headerStyle:'text-align:center;', name: 'checked',  label: 'checked', align: 'center'}, // 221011 meej checkbox 맨앞으로 이동
                    { headerStyle:'text-align:center;', name: 'createTime',  label: '보고서날짜', align: 'center', field: (row) => FormatDate(row.reportDate) },
                    { headerStyle:'text-align:center;', name: 'stockCode', label: '종목', align: 'center', field: (row) => stringToStockValue(row.stockCode, this.stockItems) + ' (' + row.stockCode + ')' },
                    { headerStyle:'text-align:center;', name: 'stockDivision',  label: '구분', align: 'center', field: (row) => this.stockDivisionOptions[row.stockDivision] },
                    { headerStyle:'text-align:center;', name: 'companyCodeNm',  label: '증권사', align: 'center', field:'companyCodeNm' },
                    { headerStyle:'text-align:center;', name: 'ifrs',  label: '연결/별도', align: 'center', field: (row) => this.ifrsOptions[row.ifrs] },
                    { headerStyle:'text-align:center;', name: 'year',  label: '연도', align: 'center', field:'year' },
                    { headerStyle:'text-align:center;', name: 'quarter',  label: '분기', align: 'center', field: (row) => this.quarterOptions[row.quarter] },
                    { headerStyle:'text-align:center;', name: 'unit',  label: '단위', align: 'center', field: (row) => this.unitOptions[row.unit] },
                    { headerStyle:'text-align:center;', name: 'sales',  label: '매출액', align: 'right', field: (row) => row.sales == null ? 'N/A':numberFormat(row.sales) },
                    { headerStyle:'text-align:center;', name: 'operatingIncome',  label: '영업이익', align: 'right', field: (row) => row.operatingIncome == null ? 'N/A':numberFormat(row.operatingIncome) },
                    { headerStyle:'text-align:center;', name: 'pretaxIncome',  label: '세전 영업이익', align: 'right', field: (row) => row.pretaxIncome == null ? 'N/A':numberFormat(row.pretaxIncome) },
                    { headerStyle:'text-align:center;', name: 'netIncome',  label: '순이익', align: 'right', field: (row) => row.netIncome == null ? 'N/A':numberFormat(row.netIncome) },
                    { headerStyle:'text-align:center;', name: 'ciNetIncome',  label: '지배주주 순이익', align: 'right', field: (row) => row.ciNetIncome == null ? 'N/A':numberFormat(row.ciNetIncome) },
                    { headerStyle:'text-align:center;', name: 'totalAsset',  label: '자산총계', align: 'right', field: (row) => row.totalAsset == null ? 'N/A':numberFormat(row.totalAsset) },
                    { headerStyle:'text-align:center;', name: 'totalLiabilities',  label: '부채총계', align: 'right', field: (row) => row.totalLiabilities == null ? 'N/A':numberFormat(row.totalLiabilities) },
                    { headerStyle:'text-align:center;', name: 'totalSTEquity',  label: '자본총계', align: 'right', field: (row) => row.totalSTEquity == null ? 'N/A':numberFormat(row.totalSTEquity) },
                    { headerStyle:'text-align:center;', name: 'ciTotalSTEquity',  label: '지배지주 자본총계', align: 'right', field: (row) => row.ciTotalSTEquity == null ? 'N/A':numberFormat(row.ciTotalSTEquity) },
                    { headerStyle:'text-align:center;', name: 'operatingCashFlow',  label: '영업현금흐름', align: 'right', field: (row) => row.operatingCashFlow == null ? 'N/A':numberFormat(row.operatingCashFlow) },
                    { headerStyle:'text-align:center;', name: 'investCashFlow',  label: '투자현금흐름', align: 'right', field: (row) => row.investCashFlow == null ? 'N/A':numberFormat(row.investCashFlow) },
                    { headerStyle:'text-align:center;', name: 'finacialCashFlow',  label: '재무현금흐름', align: 'right', field: (row) => row.finacialCashFlow == null ? 'N/A':numberFormat(row.finacialCashFlow) },
                    { headerStyle:'text-align:center;', name: 'totalCashFlow',  label: '총현금흐름', align: 'right', field: (row) => row.totalCashFlow == null ? 'N/A':numberFormat(row.totalCashFlow) },
                    { headerStyle:'text-align:center;', name: 'netDebt',  label: '순차입금', align: 'right', field: (row) => row.netDebt == null ? 'N/A':numberFormat(row.netDebt) },
                    { headerStyle:'text-align:center;', name: 'ebitda',  label: 'EBITDA', align: 'right', field: (row) => row.ebitda == null ? 'N/A':numberFormat(row.ebitda) },
                    { headerStyle:'text-align:center;', name: 'assetAmort',  label: '자산상각비', align: 'right', field: (row) => row.assetAmort == null ? 'N/A':numberFormat(row.assetAmort) },
                    // 20231116 csm추가
                    { headerStyle:'text-align:center;', name: 'csm',  label: 'CSM', align: 'right', field: (row) => row.csm == null ? 'N/A':numberFormat(row.csm) },
                    { headerStyle:'text-align:center;', name: 'dps',  label: '주당 배당금(원)', align: 'right', field: (row) => row.dps == null ? 'N/A':numberFormat(row.dps) },
                ],
            pagination: {
                page: 1,
                rowsPerPage: CONSTANTS.LIST_COUNT_PER_PAGE,
                nextCount: 0,
                maxPages: CONSTANTS.DEFAULT_PAGE_NUM
            }
        }
    },
    computed : {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            stockItems : state => state.resource.stockItems
        }),
        ...mapState('manage', ['list', 'totcount']),
        pagesNumber () {
            return Math.ceil(Number(this.totcount) / Number(this.pagination.rowsPerPage));
        }
    },
    created () {
        this.getList();
    },
    methods: {
        ...mapMutations('manage', ['SET_DATA', 'INIT_DATA_VIEW', 'SET_DATA_VIEW']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        //리스트
        getList() {
            let param = {
                stockCode: this.$parent.stockCode.code,
                year: this.$parent.year.value,
                quarter: this.$parent.quarter.value,
                companyCode: this.$parent.companyCode.code,
                ifrs: this.$parent.ifrs.value,
                pageStr: (Number(this.pagination.page) - 1) * Number(this.pagination.rowsPerPage),
                pageSize: this.pagination.rowsPerPage,
                CALL_API_URL: '/consensus/list'
            }
            this.GET_CALL(param).then((data) => {
                data.items.forEach(row => {
                    row.checked = this.allCheck;
                });
                
                // 20230518 추정 컨센서스 리스트 수정
                // 기본열 설정
                const baseColumns = [
                    { headerStyle:'text-align:center;', name: 'checked',  label: 'checked', align: 'center'}, // 221011 meej checkbox 맨앞으로 이동
                    { headerStyle:'text-align:center;', name: 'createTime',  label: '보고서날짜', align: 'center', field: (row) => FormatDate(row.reportDate) },
                    { headerStyle:'text-align:center;', name: 'stockCode', label: '종목', align: 'center', field: (row) => stringToStockValue(row.stockCode, this.stockItems) + ' (' + row.stockCode + ')' },
                    { headerStyle:'text-align:center;', name: 'stockDivision',  label: '구분', align: 'center', field: (row) => this.stockDivisionOptions[row.stockDivision] },
                    { headerStyle:'text-align:center;', name: 'companyCodeNm',  label: '증권사', align: 'center', field:'companyCodeNm' },
                    { headerStyle:'text-align:center;', name: 'ifrs',  label: '연결/별도', align: 'center', field: (row) => this.ifrsOptions[row.ifrs] },
                    { headerStyle:'text-align:center;', name: 'year',  label: '연도', align: 'center', field:'year' },
                    { headerStyle:'text-align:center;', name: 'quarter',  label: '분기', align: 'center', field: (row) => this.quarterOptions[row.quarter] },
                    { headerStyle:'text-align:center;', name: 'unit',  label: '단위', align: 'center', field: (row) => this.unitOptions[row.unit] },
                    { headerStyle:'text-align:center;', name: 'sales',  label: '매출액', align: 'right', field: (row) => row.sales == null ? 'N/A':numberFormat(row.sales) },
                    { headerStyle:'text-align:center;', name: 'operatingIncome',  label: '영업이익', align: 'right', field: (row) => row.operatingIncome == null ? 'N/A':numberFormat(row.operatingIncome) },
                    { headerStyle:'text-align:center;', name: 'pretaxIncome',  label: '세전 영업이익', align: 'right', field: (row) => row.pretaxIncome == null ? 'N/A':numberFormat(row.pretaxIncome) },
                    { headerStyle:'text-align:center;', name: 'netIncome',  label: '순이익', align: 'right', field: (row) => row.netIncome == null ? 'N/A':numberFormat(row.netIncome) },
                    { headerStyle:'text-align:center;', name: 'ciNetIncome',  label: '지배주주 순이익', align: 'right', field: (row) => row.ciNetIncome == null ? 'N/A':numberFormat(row.ciNetIncome) },
                    { headerStyle:'text-align:center;', name: 'totalAsset',  label: '자산총계', align: 'right', field: (row) => row.totalAsset == null ? 'N/A':numberFormat(row.totalAsset) },
                    { headerStyle:'text-align:center;', name: 'totalLiabilities',  label: '부채총계', align: 'right', field: (row) => row.totalLiabilities == null ? 'N/A':numberFormat(row.totalLiabilities) },
                    { headerStyle:'text-align:center;', name: 'totalSTEquity',  label: '자본총계', align: 'right', field: (row) => row.totalSTEquity == null ? 'N/A':numberFormat(row.totalSTEquity) },
                    { headerStyle:'text-align:center;', name: 'ciTotalSTEquity',  label: '지배지주 자본총계', align: 'right', field: (row) => row.ciTotalSTEquity == null ? 'N/A':numberFormat(row.ciTotalSTEquity) },
                    { headerStyle:'text-align:center;', name: 'operatingCashFlow',  label: '영업현금흐름', align: 'right', field: (row) => row.operatingCashFlow == null ? 'N/A':numberFormat(row.operatingCashFlow) },
                    { headerStyle:'text-align:center;', name: 'investCashFlow',  label: '투자현금흐름', align: 'right', field: (row) => row.investCashFlow == null ? 'N/A':numberFormat(row.investCashFlow) },
                    { headerStyle:'text-align:center;', name: 'finacialCashFlow',  label: '재무현금흐름', align: 'right', field: (row) => row.finacialCashFlow == null ? 'N/A':numberFormat(row.finacialCashFlow) },
                    { headerStyle:'text-align:center;', name: 'totalCashFlow',  label: '총현금흐름', align: 'right', field: (row) => row.totalCashFlow == null ? 'N/A':numberFormat(row.totalCashFlow) },
                    { headerStyle:'text-align:center;', name: 'netDebt',  label: '순차입금', align: 'right', field: (row) => row.netDebt == null ? 'N/A':numberFormat(row.netDebt) },
                    { headerStyle:'text-align:center;', name: 'ebitda',  label: 'EBITDA', align: 'right', field: (row) => row.ebitda == null ? 'N/A':numberFormat(row.ebitda) },
                    { headerStyle:'text-align:center;', name: 'assetAmort',  label: '자산상각비', align: 'right', field: (row) => row.assetAmort == null ? 'N/A':numberFormat(row.assetAmort) },
                    // 20231116 csm추가
                    { headerStyle:'text-align:center;', name: 'csm',  label: 'CSM', align: 'right', field: (row) => row.csm == null ? 'N/A':numberFormat(row.csm) },
                    { headerStyle:'text-align:center;', name: 'lossRate',  label: '손해율', align: 'right', field: (row) => row.lossRate == null ? 'N/A':numberFormat(row.lossRate) },
                    { headerStyle:'text-align:center;', name: 'expenseRate',  label: '사업비율', align: 'right', field: (row) => row.expenseRate == null ? 'N/A':numberFormat(row.expenseRate) },
                    { headerStyle:'text-align:center;', name: 'aumRate',  label: '운용자산이익률(투자이익률)', align: 'right',field: (row) => row.aumRate == null ?'N/A':numberFormat(row.aumRate) },
                    { headerStyle:'text-align:center;', name: 'dps',  label: '주당 배당금(원)', align: 'right', field: (row) => row.dps == null ? 'N/A':numberFormat(row.dps) },
                ];
                // 0인 항목 필터링 or null인 항목 필터링 및 동적할당
                const filteredColumns = baseColumns.filter(col => {
                const value = data.items.some(row => row[col.name] !== null && row[col.name] !== 0);
                return value;
                });
                this.columns = filteredColumns;

                this.SET_DATA(data);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        //선택삭제
        getDeleteChk() {
            var iCnt = 0;
            var ds_list = [];
            this.list.forEach(row => {
                if(row.checked){
                    iCnt++;
                    ds_list.push({
                        id : row['id']
                    });
                }
            });
            if(iCnt == 0){
                this.showMessage({ vc:this, message:'체크항목을 선택하세요.' });
                return;
            }
            this.showDeleteAlert({ vc:this, message: iCnt+'건 <span style="color:red;">선택삭제</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    ds_list,
                    'CALL_API_URL': '/consensus/deleteChk'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'선택삭제 되었습니다.' });
                    this.allCheck = false;
                    this.$parent.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },
        //전체선택
        getAllCheck(){
            this.list.forEach(row => {
                row.checked = this.allCheck;
            });
        }
    }
}

</script>
