<template>
    <article>
        <div v-if="isEditPage" class="row i-top-wrap">
            <div class="q-pa-xs" style="text-align:left;border:1px solid; "> 
                이미지 교체시 본문의 image 태그는 변경하지 마세요<br/>
                이미지 삭제시 본문의 image 태그(##image:{이미지 아이디}}####)를 삭제하거나 이미지삭제를 통해서<br/>
                <b>수정시 특징테마 추가만 가능합니다. <br/>
                특징테마 삭제시 본문에서 지워주세요.</b>
            </div>
            <q-space />
            <i-btn class="q-pa-md" label="취소" @click="goListPage" /> 
        </div>
        <div v-else class="row i-top-wrap">
            <q-space />
            <i-btn label="신규등록" @click="goEditPage(true)" /> 
        </div>
        <div class="row q-mt-sm">
            <div v-if="isEditPage" class="col">
                <q-card >
                    <q-card-section>
                        <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : items.length == 0 }"
                                    :columns="columns" :data="items" row-key="id" :rows-per-page-options="[0]" style="height:auto;" selection="multiple"
                                    :selected.sync="selected"
                                    hide-pagination no-data-label="조회되는 데이터가 없습니다.">
                                    <template v-slot:header="props">
                                        <q-tr :props="props">
                                            <!-- <q-th auto-width /> -->
                                            <q-th auto-width class="text-left">
                                                <q-checkbox v-model="allCheck" size="xs" />
                                            </q-th>
                                            <q-th v-for="col in props.cols" :key="col.name" :props="props" v-html="col.label"></q-th>
                                        </q-tr>
                                    </template>
                                    <template v-slot:body="props">
                                        
                                        <q-tr :props="props">
                                            <q-td  class="text-left">
                                            <q-checkbox
                                                v-model="props.row.checked"
                                                @input="handleCheckBox0(props.row)"
                                                size="xs"
                                                :disable="props.row.crud == 'D'"
                                            />
                                            
                                            </q-td>
                                            <q-td v-for="col in props.cols" :key="col.name" :props="props" @click="props.row.row=!props.row.detail">
                                                <div>{{ col.value }}</div>
                                            </q-td>
                                        </q-tr>
                                        <!-- 220811 meej 특징테마 - 테마종목 시세 표 자동생성 추가 -->
                                        <!-- <q-tr v-show="props.row.checked && !props.row.checkImg" v-for="theme in props.row.themes" :key="theme.name" :props="props"> -->
										<q-tr v-show="props.row.checked && !props.row.checkImg" class="text-left" :props="props">
											<q-td colspan="2">
                                            <q-checkbox
                                                        v-model="props.row.checkImg"
                                                        @input="handleCheckBox1(props.row)"
                                                        size="xs"
                                                    />이미지등록
                                            </q-td>
                                            <q-td colspan="2">
												<span v-for="theme, idx in props.row.themes" :key="theme.name" :props="props">
                                                    <q-checkbox
                                                        v-model="theme.checkTheme"
                                                        @input="handleCheckBox2(props.row, theme)"
                                                        size="xs"
                                                    /> {{ theme.name }}
                                                    <!-- 220822 meej 특징테마의 테마 갯수 4개 넘을 경우 3개 마다 다음줄로 넘어가게 처리 -->
                                                    <br v-if="props.row.themes.length > 3 && idx != 0 && (idx+1)%3==0"/>
                                                </span>
											</q-td>
											<!-- <q-td colspan="2" class="text-left">
												<p class="q-pa-xs" v-html="theme.tableContent" />
											</q-td> -->
										</q-tr>

                                        <q-tr v-if="props.row.checked && props.row.checkImg" class="text-left" :props="props">
                                            <q-td colspan="4">
                                         <q-checkbox
                                                    v-model="props.row.checkImg"
                                                    @input="handleCheckBox1(props.row)"
                                                    size="xs"
                                                />이미지등록
                                                </q-td>
                                        </q-tr>
                                        <q-tr v-if="props.row.checked && props.row.checkImg" :props="props">
                                            <q-td colspan="3">
                                                이미지 id: {{ props.row.id }}
                                            </q-td>
                                            <q-td >
                                                <div class="row ">
                                                    <q-file outlined bottom-slots v-model="props.row.upload" style="max-width: 240px" accept="image/*" clearable dense>
                                                        <template v-slot:prepend>
                                                            <q-icon name="attach_file" />
                                                        </template>
                                                        <template v-slot:hint>
                                                            이미지를 선택하세요 
                                                        </template>
                                                    </q-file>
                                                    <!-- 220811 meej 이미지 삭제 주석처리 -->
                                                    <!-- <i-btn dense label="이미지삭제" v-if="props.row.IMG_CHECK==='1'" @click="deleteIamge(props.row)"/> -->
                                                </div>
                                            </q-td>
                                        </q-tr>
                                    </template>
                        </q-table> 
                        <div class="row">
                            
                        </div>
                    </q-card-section>
                </q-card>
            </div>
            <div :class="(!isEditPage ? 'col-12': 'col-6')">
                
                <q-card  >
                    <q-card-section v-if="isDetailPage || isListPage">
                        <div class="q-pa-xs"> 
                            <h6>데일리테마 미리보기 </h6>
                            <p style="text-align:left; width:100%; font-size:16px"> 제목 : {{ title }} </p>
                            <div style="height:590px">
                                <p class="q-pa-xs " 
                                    style="text-align:left; overflow-y:scroll; width:100%; height:100%; background-color:white; border:1px solid; "
                                    v-html="content"
                                />
                            </div>
                            <!-- 220726 meej 게시날짜 수정 : 게시된 날짜도 표기 -->
                            <i-datetime
                                v-if="this.news.sendFlag === 1"
                                class="q-pt-xs"
                                date
                                dateLabel="게시된 날짜"
                                disable
                                v-model="news.sendDate"
                                style="max-width:360px" />
                            <i-datetime
                                v-if="this.news.sendFlag !== 1"
                                class="q-pt-xs"
                                date
                                dateLabel="게시 날짜"
                                v-model="showDate"
                                style="max-width:360px" />
                        </div>
                        
                    </q-card-section>
                    <q-card-section v-else-if="isEditPage">
                        <div class="q-pa-xs row">
                            <div class="col-12"><i-input v-model="news.title" inputLabel="제목" /></div>
                        </div>
                        <div class="q-pa-xs">
                            <!-- <i-editor :value="news.content" :height="450" @input-html="inputcontent"/> -->
                            <!-- <i-input
                                v-model="news.content"
                                type="textarea"
                                :rows="rows"
                                autogrow
                                inputLabel="내용"
                            /> -->
                            <!-- 220811 meej i-input -> q-editor로 수정 -->
                            <q-editor
                                style="text-align:left"
                                v-model="news.content"
                                height="600px"
                                min-height="5rem"
                                @keyup.enter.stop
                                :toolbar="[]"
                            />
                        </div>
                            <!-- <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : items.length == 0 }"
                                :columns="columns" :data="items" row-key="id" :rows-per-page-options="[0]" style="height:auto;" selection="multiple"
                                :selected.sync="selected"
                                hide-pagination no-data-label="조회되는 데이터가 없습니다.">
                                <template v-slot:header="props">
                                    
                                    <q-tr :props="props">
                                        <q-th auto-width />
                                        <q-th v-for="col in props.cols" :key="col.name" :props="props" v-html="col.label"></q-th>
                                    </q-tr>
                                </template>
                                <template v-slot:body="props">
                                    
                                    <q-tr :props="props">
                                        <q-td  class="text-left">
                                        <q-checkbox
                                            v-model="props.row.checked"
                                            @input="handleCheckBox(props.row)"
                                            size="xs"
                                            :disable="props.row.crud == 'D'"
                                        />
                                        
                                        </q-td>
                                        <q-td v-for="col in props.cols" :key="col.name" :props="props" @click="getSelected(props.row)">
                                            <div>{{ col.value }}</div>
                                        </q-td>
                                    </q-tr>
                                    <q-tr v-if="props.row.checked" :props="props">
                                        <q-td colspan="100%">
                                            <q-file outlined bottom-slots v-model="props.row.upload" style="max-width: 360px" accept="image/*" clearable dense>
                                                <template v-slot:prepend>
                                                    <q-icon name="attach_file" />
                                                </template>
                                                <template v-slot:hint>
                                                    이미지를 선택하세요 
                                                </template>
                                        </q-file>
                                        </q-td>
                                    </q-tr>
                                    
                                </template>
                            </q-table> -->
                            
                    </q-card-section>
                
                </q-card>
                <!-- 하단 버튼 -->
                <div class="q-my-xs">
                    <div v-show="isDetailPage" class="row full-width">
                        <div class="col q-pa-xs" ><i-btn class="full-width" :label="btnName" @click="getCnfirm" /></div>
                        <div class="col q-pa-xs" ><i-btn class="full-width" label="수정" @click="goEditPage" /></div>
                    </div>
                    <!-- <div class="row q-pa-xs">
                                    <i-btn label="홈페이지 데일리테마 생성" @click="makeDaily" class="col q-ml-xs" /> -->
                    <div v-show="isEditPage" class="row full-width">
                        <div class="col q-pa-xs" ><i-btn class="full-width" label="등록하기" @click="getSave" /></div>
                    </div>
                </div>
        </div>
        
        </div>
    </article>
</template>
<script>
// import Editor from '@/components/common/Editor'
import Button from '@/components/common/Button'
import DateTime from '@/components/common/DateTime'
import Input from '@/components/common/Input'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { today, clone, nowTime, numberFormat, isEmpty, numberFormatDecimal } from '@/util'
import CONSTANTS from '@/constants'
import debug from '@/debug'
export default {
    name: 'Content',
    components: { 
        'i-datetime': DateTime,
    //    'i-editor': Editor,
        'i-btn': Button,
        'i-input': Input,
    },
    props: {
        newsType: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            showDate: today(),
            sendTime: null,
            S3_STATIC_LOC: CONSTANTS.S3_STATIC,
            news: {
                content: '',
                marketnews:'',
                title: ''
            },
            themeNews: null,
            images: [],
            rows: 32,
            themeType: 'THEME_FEATURED_ALL',
            columns: [
                {
                    name: 'themeName',
                    label: '테마명',
                    align: 'left',
                    field: (row) => row.themeName,
                },
                {
                    name: 'title',
                    label: '이슈요약',
                    align: 'left',
                    field: (row) => row.title,
                },
            ],
            options: [
                {
                    label: '오전장',
                    themeType: 'THEME_FEATURED_AM',
                    marketType: 'MARKET_THEME_TRAND_AM',
                    flashType: 'MARKET_FLASH_THEME_AM',
                },
                {
                    label: '오후장',
                    themeType: 'THEME_FEATURED_PM',
                    marketType: 'MARKET_THEME_TRAND_PM',
                    flashType: 'MARKET_FLASH_THEME_PM',
                },
            ],
            items: [],
            selected: [],
            selection: 'multiple',
            allCheck: null
        }
    },
    computed: {
        ...mapState({
            role: (state) => state.session.role,
            team: (state) => state.session.team,
            userId: (state) => state.session.userId,
            PAGE_STATUS: (state) => state.page.status,
            daily: (state) => state.theme_featured.item,
        }),
        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isRegisterPage',
            'isFlashPage',
            'isEditPage',
        ]),
        buttonName() {
            return (this.isEditPage? '수정하기':'등록하기')
        },
        title() {
            return (this.news ? this.news.title: '')
        },
        content() {
            return (this.news ? this.news.content: '')
        },
        btnName() {
            return (this.news.sendFlag === 1? '홈페이지 게시 취소':'홈페이지 게시')
        }
    },
    created() {
        debug.log('Daily create!!!!!!!!!!!!!!!!!!!!')
        this.showDate = (this.news? this.news.sendDate : today())
        this.columns.splice(0, 0, {
            name: 'type',
            label: '시간구분',
            align: 'left',
            field: (row) =>
                row.newsType1 === this.options[0].themeType
                    ? '오전장'
                    : '오후장',
        })
        this.allCheck = null
        //this.getDailyTheme()
    },
    methods: {
        ...mapActions('alert', [
            'showError',
            'showMessage',
            'showAlert',
            'showDeleteAlert',
        ]),
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('theme_featured', ['INIT_LIST', 'SET_LIST', 'SET_ITEM']),
        ...mapActions('theme_featured', [
            'GET_FEATURED_THEME_LIST',
            'GET_DAILY_TODAY',
            'GET_MARKET_NEWS',
            'MAKE_DAILY',
            'UPLOAD_ITEM',
            'MAKE_BASE64',
            'CONFIRM_DAILY',
            'DELETE_DAILY_IMAGE',
            'GET_FEATURED_THEME_PRICE'
        ]),
        ...mapActions('flash', [
            'GET_NEWS_LIST',
            'WRITE_ITEM',
        ]),

        goListPage() {
            this.SET_ITEM(null)
            this.news = {title:'',content:'', marketnews:''}
            this.setPageStatus(this.PAGE_STATUS.LIST)
            this.allCheck = null
        },
        goEditPage(newContent) {
            this.allCheck = null
            if(newContent) {
                this.news = {title:'',content:'', marketnews:''}
                this.showDate = today()
                this.getThemeNews()
            } else {
                this.news = clone(this.daily)
                // this.news.content = this.htmlToText(this.news.content)
                // 220811 meej 테마시황과 특징테마 내용 구분을 위해 content에 주석 추가
                this.news.marketnews = this.news.content.split('<!-- 특징테마 시작 -->')[0]
                this.getFeaturedList(this.news.createTime.substring(0,8))
            }
            this.setPageStatus(this.PAGE_STATUS.EDIT)
        },
        // 특징테마 선택
        handleCheckBox0(row) {
            if (row.checked) {
                this.selected.push(row);
            } else {
                var index = this.selected.indexOf(row);
                if (index !== -1) {
                    this.selected.splice(index, 1);
                }
            }
            this.makeTitleContent()
            // if(!this.news.id) {
            //      this.makeTitleContent()
            // } else {
            //     if (row.checked) {
            //         this.addThemeNews(row)
            //     }
            // }
            
        },
        // 특징테마의 이미지 등록 선택
        handleCheckBox1() {
            this.makeTitleContent()
        },
        // 특징테마의 표생성 선택
        handleCheckBox2(row, theme) {
            var index = this.selected.indexOf(row);
            var idx = this.selected[index].themes.indexOf(theme);
            if(theme.checkTheme){
                this.selected[index].themes.splice(idx, 1);
                this.selected[index].themes.push(theme);
            } else {
                this.selected[index].themes.splice(idx, 1);
                this.selected[index].themes.push(theme);
            }
            // 오름차순 정렬
            this.selected[index].themes.sort(function(a, b) { 
                return a.index - b.index;
            });
            this.makeTitleContent()
        },
        deleteIamge(item) {
            this.showAlert({
                    vc: this,
                    message:
                        '이미지를 삭제 하시겠습니까?',
                    })
                    .then(() => {
                        this.doDeleteImage(item)
                    })
                    .catch(() => {})
        },
        doDeleteImage(item) {
            this.DELETE_DAILY_IMAGE({id:item.id})
                .then(()=> {
                    this.showMessage({
                        vc: this,
                        message: '삭제되었습니다.',
                    })
                    item.IMG_CHECK = '0'
                })
                .catch((err) => {
                    this.showError({vc:this, error:err})
                })
        },
        makeTitleInit() {
            let content ='', title = '데일리 테마'
            // 신규 등록
            if(this.themeNews != undefined && this.themeNews.content!= undefined){
                content = '- 테마시황 -'
                content += '<br><br>'
                content += this.themeNews.content.replaceAll("\n","<br>")
                content += '<br><br><br>'
                // content += '\n\n'
                // content += this.themeNews.content
                // content += '\n\n'
    
                this.news.marketnews = content
                this.news.content = content
            }else{
                // 수정 
                content = this.news.marketnews
            }
            this.news.title = title

            return [title, content]
        },
        makeTitleContent() {
            let content = '', title = ''

            const init = this.makeTitleInit()
            title = init[0] 
            // content = init[1] 
            content = this.news.marketnews

            title += "("
            this.selected.sort(function(a, b) { // 오름차순
                // return a.order - b.order;
                return a.index - b.index;
            });
            content += '<!-- 특징테마 시작 -->'
            for (let i in this.selected) {
                let temp = this.selected[i]
                temp.order = i
                content += '- '
                content += temp.themeName
                content += ' -'
                // content += '\n\n' 
                content += '<br><br>'
                content += temp.title
                // content += '\n\n'
                content += '<br><br>'
                content += temp.content
                // content += '\n\n'
                content += '<br><br>'
                if(temp.checkImg){
                    // 이미지 등록
                    content += '##image:'+temp.id
                    content += '####'
                }else {
                    // 표 생성
                    for(let i=0;i<temp.themes.length;i++){
                        if(temp.themes[i].checkTheme){
                            content += temp.themes[i].tableContent
                        }
                    }
                }
                content += '<br>'
                title += temp.themeName + ','
            }

            this.news.title = title.slice(0, -1) + ')'
            this.news.content = content
            
        },
        addThemeNews(item) {
            let content = this.news.content
            content += '- '
            content += item.themeName
            content += ' -'
            content += '\n\n'
            content += item.title
            content += '\n'
            content += item.content
            content += '\n\n'
            content += '##image:'+item.id
            content += '####'
            content += '\n\n'
            this.news.content = content
        },
        // 테마별 시세 데이터 가져오기
        async handleThemeTable(index, i, code, trend){
            const params = {
                themeCode : code,
                trend : trend,
                date : this.news.id != undefined? this.news.createTime.substring(0,8) : ''
            }
            console.log("parms : ", params)
            await this.GET_FEATURED_THEME_PRICE(params)
            .then((res)=>{
                this.items[index].themes[i].name = res.name
                this.items[index].themes[i].tableContent = this.getThemeStockPrice(res)
            })
            .catch(()=>{
                this.showError({vc:this, error:"관리자에 문의하십시오."})
            })
        },
        // 테마별 시세 테이블 생성
         getThemeStockPrice(theme){
            let makeTable = '<!-- '+theme.name+' 테이블 시작 -->'
            makeTable += '<div class="div_float" style="width:780px">'
            makeTable += '<table border="1" class="dataframe">'
            makeTable += '<tbody>'
            makeTable += '<tr>'
            makeTable +=    '<th style="width:15%;background-color:rgb(021,062,118);color:white">테마명</th>'
            makeTable +=    '<th style="width:10%;background-color:rgb(021,062,118);color:white;border-right:double">등락률</th>'
            makeTable +=    '<th style="width:15%;background-color:rgb(021,062,118);color:white">종목명</th>'
            makeTable +=    '<th style="width:10%;background-color:rgb(021,062,118);color:white">종가(원)</th>'
            makeTable +=    '<th style="width:10%;background-color:rgb(021,062,118);color:white">등락률</th>'
            makeTable +=    '<th style="width:10%;background-color:rgb(021,062,118);color:white">거래량(주)</th>'
            makeTable +=    '<th style="width:10%;background-color:rgb(021,062,118);color:white">시가(원)</th>'
            makeTable +=    '<th style="width:10%;background-color:rgb(021,062,118);color:white">고가(원)</th>'
            makeTable +=    '<th style="width:10%;background-color:rgb(021,062,118);color:white">저가(원)</th>'
            makeTable += '</tr>'
            makeTable += '<tr>' 
            
            // 테마 내용, 테마 종목 총 길이 넣기
            makeTable +=    '<td rowspan = "'
            makeTable += theme.items.length
            makeTable +=    '">'
            // makeTable +=        '<a href="https://web.dev.infostock.co.kr/Theme/ThemeDB/'
            makeTable +=        '<a href="https://new.infostock.co.kr/Theme/ThemeDB/'
            makeTable += theme.code
            makeTable +=        '" class="popup" onclick="window.open(this.href, '
            makeTable +=        "'SECTOR', 'width=700px,height=850,scrollbars=1,resizable=yes'); return false;"
            makeTable +=        '" target="_blank" style="color:black">'
            makeTable += theme.name
            makeTable +=        '</a>'
            makeTable +=    '</td>'
            makeTable +=    '<td rowspan = "'
            makeTable += theme.items.length+'"'
            if(Number(theme.rate) > 0 ){
                makeTable += 'style="color:#FF3300;border-right:double;border-right-color:#d3d3d3;"> +'
            } else if(Number(theme.rate) < 0 ){
                makeTable += 'style="color:#0000FF;border-right:double;border-right-color:#d3d3d3;"> '
            } else {
                makeTable += 'style="color:#000000;border-right:double;border-right-color:#d3d3d3;"> '
            }  
            makeTable += numberFormatDecimal(theme.rate)+'% </td>'

            // for 문 시작 : 테마 종목[0~반복]
            for(let i=0;i<theme.items.length;i++){
                var item = theme.items[i]
                if(i != 0) {
                    makeTable +=  '<tr>'
                }
                // makeTable +=    '<td><a href="https://web.dev.infostock.co.kr/stockitem?code='
                makeTable +=    '<td><a href="https://new.infostock.co.kr/stockitem?code='
                makeTable += item.stockCode
                makeTable +=        '" class="popup"' 
                makeTable +=        ' onclick="window.open(this.href,'
                makeTable +=        " 'STOCK', 'width=700px,height=850,scrollbars=1,resizable=yes');"
                makeTable +=        ' return false;" target="_blank" style="color:black">'
                makeTable +=item.stockName
                makeTable +=    '</a></td>'
                makeTable +=    '<td>'
                makeTable +=numberFormat(item.currPrice)
                makeTable +=    '</td>'
                makeTable +=    '<td '
                if(Number(item.changeRate) > 0 ){
                    makeTable += 'style="color:#FF3300"> +'
                }else if(Number(item.changeRate) < 0 ){
                    makeTable += 'style="color:#0000FF"> '
                }else {
                    makeTable += 'style="color:#000000"> '
                }
                makeTable +=numberFormatDecimal(item.changeRate)
                makeTable +=    '% </td>'
                makeTable +=    '<td>'
                makeTable +=numberFormat(item.volume)
                makeTable +=    '</td>'
                makeTable +=    '<td>'
                makeTable +=numberFormat(item.openPrice)
                makeTable +=    '</td>'
                makeTable +=    '<td>'
                makeTable +=numberFormat(item.highPrice)
                makeTable +=    '</td>'
                makeTable +=    '<td>'
                makeTable +=numberFormat(item.lowPrice)
                makeTable +=    '</td>'
                makeTable += '</tr>'
            }
            
            makeTable +='</tbody>'
            makeTable +='</table>'
            makeTable +='</div></div>'
            makeTable +='<br/>'
            makeTable += '<!-- '+theme.name+' 테이블 끝 -->'

            return makeTable
        },
        getDailyTheme() {
            this.GET_DAILY_TODAY()
            .then((data) => {
                if(data) {
                    debug.log('GET_DAILY_TODAY , ', data)
                    this.SET_ITEM(data)
                    this.getThemeNews()
                }
            })
            .catch((err) => {
                    debug.log('catch , ', err)
                    //if(err.status === '906') {
                        this.goEditPage()
                        this.getThemeNews()
                    // } else {
                    //     this.showError({ vc: this, error: err })
                    // }
                })
        },
        getThemeNews() {
            let params = {
                newsType: 'MARKET_THEME_NEWS',
            }
            this.GET_MARKET_NEWS(params)
                .then((data) => {
                    debug.log('getTrendNews , ', data)
                    if (data.items.length > 0) {
                        this.themeNews = clone(data.items[0])
                        this.makeTitleContent()
                        this.getFeaturedList(this.themeNews.showDate)
                    } else {
                        this.showError({ vc: this, error: '테마시황을 먼저 입력하세요 ' })
                    }
                })
                .catch((err) => {
                    this.showError({ vc: this, error: err })
                })
        },
        // 특징 테마 조회
        getFeaturedList(date) {
            let params = {
                newsType: this.themeType,
                date: date,
            }
            this.GET_FEATURED_THEME_LIST(params)
                .then((data) => {
                    this.items = data.items
                    //인덱스 추가
                    this.selected = []
                    this.initOrder()
                    this.items.forEach((row, index) => {
                        // 230223 meej 테마 코드 없을경우 에러메시지 보여주기
                        if(row.themeCodes == undefined){
                            console.log("row = ",row)
                            this.showError({ vc: this, error: "[ "+row.themeName+" ] 테마 입력이 잘못되었습니다. [ 특징테마 ]에서 테마를 선택하여 입력해주세요." })
                            return
                        }
                        row.index = index
                        let checkList = null
                        // 신규 등록 일 경우 체크 모두 해제
                        if(this.news.id != undefined && (row.IMG_CHECK == "1" || !isEmpty(row.CHECK))){
                            row.checked = true
                            this.selected.push(row)
                        }else{
                            row.checked = false
                        }
                        if(this.news.id != undefined && row.IMG_CHECK == "1"){
                            row.checkImg = true
                        }else{
                            row.checkImg = false
                        }
                        if (!isEmpty(row.CHECK)) {
                            checkList = row.CHECK.split('|')
                        } 

                        // 급등 강세 상승 -> trend = 1 | 급락 약세 하락 -> trend = 0
                        var keyword = ["급등", "강세", "상승", "급락", "약세", "하락"]
                        var kwIndex = [0, 0, 0, 0, 0, 0]
                        var maxIndex = -1
                        for(var msg in keyword){
                            kwIndex[msg] = row.title.lastIndexOf(keyword[msg])
                            if(kwIndex[msg] == -1) continue
                            if(maxIndex == -1) {
                                maxIndex = msg
                                continue
                            } 
                            if(msg >=1 && kwIndex[maxIndex]<=kwIndex[msg]) maxIndex = msg
                        }
                        let trend = -1
                        if(maxIndex == -1) trend = -1
                        else if(maxIndex < 3) trend = 1
                        else if (maxIndex >= 3) trend = 0
                        

                        // 220824 meej replace(/\n/gi, "<br>") 추가
                        row.content = row.content.replace(/\n/gi, "<br>") 

                        let themeCodes = row.themeCodes.split("_")
                        row.themes = []
                        for(let i in themeCodes){
                            let list = themeCodes[i].split("|")
                            this.items[row.index].themes[i] = {}
                            this.items[row.index].themes[i].index = i
                            this.items[row.index].themes[i].code = list[0]
                            this.items[row.index].themes[i].name = list[1]
                            this.items[row.index].themes[i].tableContent = this.handleThemeTable(row.index, i, list[0], trend)
                            this.items[row.index].themes[i].checkTheme = false
                            // 신규등록일 때 각 특징테마의 첫번째 테마 자동 체크
                            if(this.news.id == undefined && i == 0 ){
                                this.items[row.index].themes[i].checkTheme = true
                            } else if(this.news.id != undefined && checkList != null && !row.checkImg){
                                for(let j in checkList){
                                    if(list[0] == checkList[j]){
                                        this.items[row.index].themes[i].checkTheme = true
                                    }
                                }
                            }
                        }   
                        
                        row.detail = false
                        debug.log('check', row.checked)
                    })
                    // this.initOrder()
                })
                .catch((err) => {
                    this.showError({ vc: this, error: err })
                })
        },
        doShowDate() {
            // 220321 meej sendTime 추가
            // 220726 meej 홈페이지 게시 취소 오류 수정
            this.showTime = nowTime()
            let param = {
                id: this.news.id,
                showDate: this.news.sendFlag === 1 ? '' : this.showDate,
                showTime: this.news.sendFlag === 1 ? '' : this.showTime,
                showFlag: this.news.sendFlag === 1 ? -1 : 1 
            }
            this.CONFIRM_DAILY(param)
                .then(() => {
                    this.showMessage({
                        vc: this,
                        message: (this.news.sendFlag===1?'홈페이지에 개시가 취소되었습니다.':'홈페이지에 개시되었습니다.') ,
                    })
                    this.setPageStatus(this.PAGE_STATUS.LIST)
                    this.INIT_LIST()
                    this.getList()
                })
                .catch((err) => {
                    this.showError({ vc: this, error: err })
                })
        },
        async doWrite() {
            let files = []
            for(let i in this.selected ){
                let data = this.selected[i]
                debug.log(data.upload)
                if(data.upload) {
                    let dataURL = await this.MAKE_BASE64(data.upload)
                    const base64String = dataURL
                            .replace('data:', '')
                            .replace(/^.+,/, '');
                    
                    files.push({id:data.id, value:base64String})
                } 
            }
            // 파라미터
            // let ids = ''
            // for(let i in this.selected) {
            //     ids += this.selected[i].id + ','
            // }
            // //이미지 추가
            // ids = ids.slice(0, -1)

            // 파라미터
            let themes = []
            if(!this.news.checkImg){
                for(let i in this.selected) {
                    let theme = {}
                    theme.id = this.selected[i].id
                    theme.codes = ''
                    for(let j in this.selected[i].themes){
                        if(this.selected[i].themes[j].checkTheme){
                            theme.codes += this.selected[i].themes[j].code+","
                        }
                    }
                    theme.codes = theme.codes.slice(0, -1)
                    themes.push(theme)
                }
            }
            //이미지 추가
            // ids = ids.slice(0, -1)

            let content0 = this.news.content;
            debug.log('write this.news , ', this.news)

            debug.log('write content0 , ', content0)
            let content = this.textToHtml(content0)
            let param = {
                id: this.news.id,
                newsType: this.newsType,
                content: content,
                title:this.news.title,
                items:files,
                // ids:ids
                themes:themes
            }
            this.MAKE_DAILY(param)
                .then(() => {
                    this.showMessage({
                        vc: this,
                        message: '저장 되었습니다.',
                    })
                    this.news.content = content
                    this.INIT_LIST()
                    this.getList()
                    this.goListPage()
                })
                .catch((err) => {
                    this.showError({ vc: this, error: err })
                })
                
        },

        //저장
        getSave() {
            // 220727 meej 유효성체크 : 제목, 내용 조건 추가
            if (!this.news.title || this.news.title.trim() == "" ) {
                this.showMessage({ vc: this, message: '제목을 입력하세요.' })
                return
            }
            if (!this.news.content || this.news.content.trim() == "" ) {
                this.showMessage({ vc: this, message: '내용을 입력하세요.' })
                return
            }
            this.showAlert({
                vc: this,
                message:
                    '<span style="color:#1976D2;">저장</span> 하시겠습니까?',
            })
                .then(() => {
                    this.doWrite()
                })
                .catch(() => {})
        },
        //게시
        getCnfirm() {
            // 220726 meej 홈페이지 게시 취소 오류 수정
            const msg = this.news.sendFlag === 1 ? 
                        '홈페이지 게시 취소 하시겠습니까?'
                        : '홈페이지에 ( 게시날자 : '+this.showDate+') <br> <span style="color:#1976D2;">게시</span></span> 하시겠습니까?'

            this.showAlert({ vc:this, message: msg }).then(()=>{
                this.doShowDate(this.showDate)
            }).catch(() => { });
        },
        getList() {
            this.setPageStatus(this.PAGE_STATUS.LIST)
            this.INIT_LIST()
            var params = {
                newsType: this.newsType
            }
            this.GET_NEWS_LIST(params)
                .then((data)=> {
                    
                    this.SET_LIST(data)
                })
                .catch((err) => {
                    this.showError({ vc: this, error: err })
                })
        },
        
        // 순서 조정
        initOrder() {
            this.items.sort(function(a, b) {
                // 220713 meej 특징테마 순서와 동일하게 수정
                if(a.DAY_INDEX == undefined || b.DAY_INDEX == undefined ){
                    if(a.newsType1 === b.newsType1){
                        return a.ZINDEX - b.ZINDEX 
                    }else if(a.newsType1 < b.newsType1){
                        return 1
                    }else if(a.newsType1 > b.newsType1){
                        return -1
                    }
                    
                }else{
                    return a.DAY_INDEX - b.DAY_INDEX
                }
                // if (a.DAY_INDEX > b.DAY_INDEX) {
                //     return 1
                // }
                // if (a.DAY_INDEX < b.DAY_INDEX) {
                //     return -1
                // }
                // return 0
            })
        },
        textToHtml(val) {
            let html = val.replace(/##image:/gi, `<img src='https://${this.S3_STATIC_LOC}/MARKET_THEME_DAILY/`) 
            html = html.replace(/####/gi, `.png' onerror="this.style.display='none'" border=0>`) 
            html = html.replace(/\n/gi, "<br>") 
            
            debug.log('textToHtml' ,html)
            return html
        },
        htmlToText(val) {
            let text = val.replace(/<br>/gi, "\n");
            text = text.replace(/<img src=/gi, '##image:')
            
            text = text.replace(/'https:\/\/news.infostock.co.kr\/MARKET_THEME_DAILY\//gi, "") 
            text = text.replace(/".png' onerror="this.style.display='none'" border=0>/gi, "####") 
            
            return text
        }
    },
    
    watch:{
        // newsType(val) {
        //     if(val === 'MARKET_THEME_DAILY') {
                
        //     }
        // },
        daily(val) {
            if(this.newsType === 'MARKET_THEME_DAILY') {
                debug.log('watch!!! daily ',val)
                if(val) {
                    this.setPageStatus(this.PAGE_STATUS.DETAIL)
                    this.news = clone(val)

                    this.news.content = this.textToHtml(this.news.content) //this.textToHtml(this.news.content, true)
                } else {
                    this.news.content = ''
                }
            }
            
        },
        allCheck : function(val, old) {
            // if(this.news && this.news.id) return
            if(this.news && this.news.id){
                // 수정일 경우 처음에 생성시 등록된 내용 보여지도록 return 함
                if(val == null || old == null){
                    return
                }
            }
            if (val) {
                this.selected = []
                this.items.forEach((row) => {
                    row.checked = val
                    this.selected.push(row)
                })
                this.makeTitleContent()
            } else {
                this.items.forEach((row) => {
                    row.checked = val
                })
                this.selected = []
                this.makeTitleInit()
            }
        },
        selected() {
            // if(this.news && this.news.id) return
            const selectedLength = this.selected.length
            const totalLength = this.items.length
            
            if(selectedLength === 0) {
                this.allCheck = false
                return
            }

            if(selectedLength === totalLength) {
                this.allCheck = true
            }
        },
        // 220812 meej 특징종목 정보를 받아오는 도중 체크 표시를 할경우 메시지 표시
        'news.content'(){
            if(this.news.content.indexOf('object Promise') > -1){
                this.allCheck = false
                this.news.content = ''
                this.showMessage({vc:this, message:"특징테마 정보를 가져오는 중입니다. 특징테마를 다시 선택해주세요"})
            }
        }
    }
}
</script>
