<template>
    <section class="row q-pl-xs">
        <q-toolbar class="row q-mt-md text-left">
            <div class="q-pa-xs"><q-input v-model="sendDate" bg-teal text-white outlined dense type="date"/></div>
            <div class="q-pa-xs"><i-select v-model="trnsmis" :options="trnsmisIdOptions" option-value="trnsmisId" option-label="trnsmisNm"  style="width:200px;"/></div>
            <div class="q-pa-xs"><i-btn icon="search" @click="getList()"  /></div>
            <div class="q-pa-xs"><i-btn label="선택삭제" @click="getDeleteChk()"/> <i-btn label="선택확인" @click="getCnfirmChk()" v-show="role == 'superuser' || role == 'poweruser'"/></div>
            <div class="col q-pa-xs text-right"><i-btn label="신규등록" @click="getView(null)"/></div>
        </q-toolbar>
        <i-list ref="advertisingList" :trnsmis="trnsmis" :sendDate="sendDate" class="col-12 q-pa-xs"/>
        <i-content ref="advertisingContent" :trnsmis="trnsmis"/>
    </section>
</template>
<script>
import CONSTANTS from '@/constants'
import Select from '@/components/common/Select.vue';
import Button from '@/components/common/Button';
import List from "@/components/marketing/advertising/List.vue";
import Content from "@/components/marketing/advertising/Content.vue";
import { mapState, mapActions } from 'vuex'
import debug from '@/debug'
export default {
    name: "Advertising",
    components: {
        'i-select' : Select,
        'i-btn': Button,
        "i-list": List,
        'i-content': Content
    },
    data() {
        return {
            trnsmis: null,
            trnsmisIdOptions:[],
            sendDate: null
        }
    },
    computed: {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            TRANS_TYPE : state => state.manage.transType,
        }),
    },
    created () {
        
        this.getPage();
    },
    methods: {
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        //페이지 검색
        getPage() {
            let param = {
                trnsmisType:this.TRANS_TYPE.ADVRTS_CTMMNY,
                CALL_API_URL: '/adnews/page'
            }
            this.GET_CALL(param).then((data) => {
                this.trnsmisIdOptions = data.selectList
                if(this.role != CONSTANTS.ROLE.CU) {
                    this.trnsmisIdOptions.unshift({trnsmisId:'', trnsmisNm:'전체'});
                }
                
                debug.log('getPage', this.trnsmisIdOptions)
                this.trnsmis = this.trnsmisIdOptions[0];
                
                this.getList();
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        getList() {
            debug.log('ad getlist', this.trnsmis)
            this.$refs.advertisingList.getList();
        },
        getView(item) {
            debug.log('ad getView', this.trnsmis)
            if(this.trnsmis.trnsmisId == '') {
                this.showError({vc:this, error:'광고사를 선택하세요'})
                return
            }
            this.$refs.advertisingList.getView(item);
        },
        getUpdatePopup(item) {
            debug.log('getUpdatePopup', this.item)
            this.$refs.advertisingContent.getUpdatePopup(item);
        },
        getDeleteChk() {
            this.$refs.advertisingList.getDeleteChk();
        },
        getCnfirmChk() {
            this.$refs.advertisingList.getCnfirmChk();
        }
    },
    
};

</script>
