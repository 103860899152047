<template>
    <article>
        <q-card>
            <q-card-section>
                <div class="col q-gutter-sm">
                    <div class="col-12" v-if="trnsmisNm && trnsmisNm.code !== ''"><h5 style="background-color:lightgrey;"> {{ trnsmisNm.value }} </h5></div>
                    <div class="col-12">
                        <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : itemList.length == 0 }"
                            :columns="columns" :data="itemList" row-key="code" :rows-per-page-options="[0]" style="height:auto;"
                            hide-pagination no-data-label="조회되는 데이터가 없습니다.">
                            <template v-slot:header="props">
                                <q-tr :props="props">
                                    <q-th v-for="col in props.cols" :key="col.name" :props="props" v-html="col.label"></q-th>
                                </q-tr>
                            </template>
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td v-for="col in props.cols" :key="col.name" :props="props" @click="getCheckbox(props.row, col.name)">
                                        <div v-if="col.name == 'trnsmisChk0'"><q-checkbox :disable="!trnsmisNm || trnsmisNm.code === ''" v-model="props.row.trnsmisChk0" :value ="props.row.trnsmisChk0" :true-value="props.row.code0" false-value="N" dense/><div v-if="props.row.trnsmisChk0!=='N'"><i-input label="PORT" v-model="props.row.port0" /> </div></div>
                                        <div v-else-if="col.name == 'trnsmisChk1' && props.row.code1 != null"><q-checkbox :disable="!trnsmisNm || trnsmisNm.code === ''" v-model="props.row.trnsmisChk1" :value ="props.row.trnsmisChk1" :true-value="props.row.code1" false-value="N" dense/><div v-if="props.row.trnsmisChk1!=='N'"><i-input  label="PORT" v-model="props.row.port1" /> </div></div>
                                        <div v-else-if="col.name == 'trnsmisChk2' && props.row.code2 != null"><q-checkbox :disable="!trnsmisNm || trnsmisNm.code === ''" v-model="props.row.trnsmisChk2" :value ="props.row.trnsmisChk2" :true-value="props.row.code2" false-value="N" dense/><div v-if="props.row.trnsmisChk2!=='N'"><i-input  label="PORT" v-model="props.row.port2" /> </div></div>
                                        <div v-else>{{ col.value }} </div>
                                        
                                    </q-td>
                                </q-tr>
                            </template>
                            <template v-slot:no-data="{ icon, message }"><div class="full-width row flex-center q-gutter-sm"><q-icon size="2em" :name="icon" /> <span>{{message}}</span></div></template>
                        </q-table>
                    </div>
                </div>
            </q-card-section>
        </q-card>
        <!-- 하단 버튼 -->
        <div class="q-my-xs">
            <div class="row">
                <div class="col q-pa-xs"><i-btn v-show="item.trnsmisId != null" class="full-width" label="수정" @click="getSave" /></div>
            </div>
        </div>
    </article>
</template>
<script>
import CONSTANTS from '@/constants'
import Button from '@/components/common/Button'
import Input from '@/components/common/Input'
import { mapState, mapMutations, mapActions } from 'vuex'
import debug from '@/debug'
export default {
    name: 'Content',
    components: {
        'i-btn': Button,
        'i-input' : Input
    },
    data() {
        return {
            columns: [
                { headerStyle:'text-align:center;', style:'width:*;', name: 'name0', label: '증권사', align: 'left', field:'name0' },
                { headerStyle:'text-align:center;', style:'width:20%;', name: 'trnsmisChk0', label: '전송여부', align: 'center' },
                { headerStyle:'text-align:center;', style:'width:*;', name: 'name1', label: '증권사', align: 'left', field:'name1' },
                { headerStyle:'text-align:center;', style:'width:20%;', name: 'trnsmisChk1', label: '전송여부', align: 'center' },
                { headerStyle:'text-align:center;', style:'width:*;', name: 'name2', label: '증권사', align: 'left', field:'name2' },
                { headerStyle:'text-align:center;', style:'width:20%;', name: 'trnsmisChk2', label: '전송여부', align: 'center' }
              ],
            trnsmisNm: null,
            trnsmisNmOptions: [{'code':'', 'value':'선택'}].concat(CONSTANTS.TRNSMISMANAGE_INFOSTOCK_B2B)
        }
    },
    computed: {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            TRANS_TYPE : state => state.manage.transType,
        }),
        ...mapState('manage', ['item', 'items', 'itemList']),
    },
    created () {
        this.$parent.getView(null);
    },
    methods: {
        ...mapMutations('manage', ['INIT_ITEM', 'SET_ITEM', 'SET_LIST']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        getView() {
            this.$parent.getView(null);
        },
        //저장
        getSave() {
            if (!this.trnsmisNm) {
                this.showMessage({ vc:this, message:'타입을 선택하세요.' });
                return;
            }
            this.showAlert({ vc:this, message:'<span style="color:#1976D2;">저장</span> 하시겠습니까?' }).then(()=>{
                var ds_list = [];
                this.itemList.forEach(row => {
                    for(var i = 0; i < 3; i++){
                        if(row['trnsmisChk'+i] != null && row['trnsmisChk'+i] != 'N'){
                            ds_list.push({
                                trnsmisId : this.item.trnsmisId,
                                code : row['trnsmisChk'+i],
                                port : row['port'+i],
                            });
                        }
                    }
                });
                let param = {
                    ds_list,
                    'trnsmisId': this.item.trnsmisId,
                    'trnsmisNm': this.trnsmisNm.code,
                    'trnsmisType': this.TRANS_TYPE.INFOSTOCKB2B,
                    'CALL_API_URL': '/trnsmis/save'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'저장 되었습니다.' });
                    this.$parent.getView(this.item);
                    this.$parent.getList();
                }).catch((err)=>{
                    this.showError({ vc:this, error:err });
                });
            }).catch(() => { });
        },
        getCheckbox(obj, name){
            if(this.trnsmisNm && this.trnsmisNm.coe !== '') {
                debug.log('getCheckbox = ', obj , ',', name)
                if(obj['trnsmisChk'+name.substring(4,5)] == 'N'){
                    obj['trnsmisChk'+name.substring(4,5)] = obj['code'+name.substring(4,5)];
                }else{
                    obj['trnsmisChk'+name.substring(4,5)] = 'N';
                }
            }
            
        },
        getTrnsmisNmChk(val){
            debug.log('getTrnsmisNmChk', val)
            debug.log('getTrnsmisNmChk', this.trnsmisNmOptions)
            this.trnsmisNm = this.trnsmisNmOptions.filter(function (entry){ return entry.code === val; })[0];
            debug.log('getTrnsmisNmChk', this.trnsmisNm)
        },
    }
}

</script>
