<template>
    <section class="row">
    </section>
</template>
<script>
// import List from "@/components/marketing/IRmanage/List.vue";
// import Content from "@/components/marketing/IRmanage/Content.vue";
// import { mapState, mapGetters } from "vuex";

export default {
    name: "IRdaily",
    // components: {
    //     "i-list": List,
    //     "i-content": Content,
    // },
    // computed: {
    //     ...mapState({
    //         PAGE_STATUS: (state) => state.page.status,
    //         pageStatus: (state) => state.page.pageStatus,
    //     }),
    //     ...mapGetters({
    //         isListPage: "page/isListPage",
    //         isEditPage: "page/isEditPage",
    //         isRegisterPage: "page/isRegisterPage",
    //         isHistoryPage: "page/isHistoryPage",
    //     }),
    // },
};
</script>
<style>
/* common */

/* xs */
@media only screen and (max-width: 599px) {
}

/* sm */
@media only screen and (max-width: 1023px) {
}

/* md */
@media only screen and (max-width: 1439px) {
}

/* lg */
@media only screen and (max-width: 1919px) {
}

/* xl */
@media only screen and (min-width: 1920px) {
}
</style>
