<template>
    <article>
        <!-- 구분 -->
        <div class="flex justify-start q-pa-xs" style="height:43.99px">
            <!-- <i-btn label="삭제" @click="deleteItems()"/> -->
        </div>
        <!-- 목록 -->
        <i-table :columns="columns" :list="list" :needCheckBox="false" :verticalScroll="true"
            @row-click="getItem" />
    </article>
</template>
<script>
import Debug from '@/debug'

// import Button from '@/components/common/Button'
import Table from '@/components/common/SimpleTable'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    components: {
        // 'i-btn' : Button,
        'i-table' : Table
    },
    props: {
    },
    data () {
        return {
            columns: [
                { name: 'code', label: '증권사코드', align: 'left', field: row => row.code },
                { name: 'name', label: '증권사명', align: 'left', field: row => row.name, sortable: true },
                { name: 'opinion', label: '투자의견', align: 'left', field: row => row.opinion },
            ],
            selected:[]
        }
    },
    created () {
        this.INIT_LIST()
        this.getList()
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus
        }),
        ...mapState( 'manage', ['list']),

        ...mapGetters( 'page', ['isListPage', 'isDetailPage', 'isEditPage', 'isRegisterPage']),
    },
    methods: {
        ...mapMutations( 'page', ['setPageStatus']),
        ...mapMutations( 'manage', ['INIT_LIST', 'SET_ITEM']),

        ...mapActions( 'alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions( 'manage', ['ASYNC_COMPANY_LIST']),

        // 목록 조회하기
        getList() {
            this.setPageStatus(this.PAGE_STATUS.LIST)
            this.ASYNC_COMPANY_LIST()
        },
        // 상세 조회
        getItem(item) {
            Debug.log("getItem = ",item)
            this.setPageStatus(this.PAGE_STATUS.DETAIL)
            this.SET_ITEM(item)
        },
        handleCheckList(list) {
            this.selected = list
        },
         // 삭제하기
        deleteItems() {
            if (this.selected.length == 0) {
                this.showMessage({ vc:this, message:'삭제할 항목을 선택해주세요.' })
                return
            } else {
                this.showAlert({vc:this, message:'선택한 항목을 삭제 하시겠습니까?'})
                    .then(()=>{
                        this.doDelete()
                    })
            }
        }
    },
    watch: {
    }
}
</script>


