<template>
    <article>
        <q-toolbar class="q-ma-xs">
            <q-input
                v-show="!isFlashPage"
                class="full-width"
                dense
                outlined
                v-model="textLine"
                @keyup="textLineHandler"
                type="textarea"
                :input-style="{ height: '70px' }"
                placeholder="Excel Header를 제외한 Data를 복사한 후 붙여넣기 하세요."
            />
            <i-btn label="취소" @click="cancelInput()" />
        </q-toolbar>
        <!-- <q-card>
            <q-card-section> -->
                <!-- <div class="col q-gutter-sm"> -->
        <q-scroll-area visible class="i-content-wrap">
            <!-- 리스트 화면 / 등록 화면 -->
            <div v-if="excelData.length > 0 && !isFlashPage">
                <i-datetime
                    class="col-6 q-pa-xs"
                    v-model="insertDate"
                    date
                    dateLabel="입력일"
                />
                <q-markup-table flat bordered dense>
                    <thead>
                        <tr>
                            <th class="text-left">종목</th>
                            <th class="text-left">등락률</th>
                            <th class="text-left">내용</th>
                            <th v-if="excelData.length > 0">
                                <q-icon
                                    name="remove_circle_outline"
                                    size="xs"
                                    @click="clear()"
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="excelData.length > 0">
                        <tr
                            v-for="(item, index) in excelData"
                            :key="'excelData-' + index"
                            :class="item.valid ? 'bg-white-3' : 'bg-red-3'"
                        >
                            <td v-if="item.valid" class="text-left">
                                <q-field
                                    dense
                                    outlined
                                    stack-label
                                    :disable="item.valid"
                                >
                                    <template v-slot:control>
                                        <div
                                            class="self-center full-width no-outline"
                                            tabindex="0"
                                        >
                                            {{ displayStock(item) }}
                                        </div>
                                    </template>
                                </q-field>
                            </td>
                            <td v-else class="text-left">
                                <q-input
                                    v-model="item.stockCode"
                                    :disable="item.valid"
                                    outlined
                                    dense
                                />
                            </td>
                            <td class="text-left">
                                <!-- option = changeRate  -->
                                <q-input v-model="item.option" dense />
                            </td>
                            <td class="text-left">
                                <q-input v-model="item.content" dense />
                            </td>
                            <td>
                                <q-icon
                                    name="remove_circle_outline"
                                    size="xs"
                                    @click="remove(index)"
                                />
                            </td>
                        </tr>
                    </tbody>
                </q-markup-table>
            </div>
            <div v-else>
                <!-- 상세 화면 / 수정 화면 -->
                <q-card flat bordered>
                    <q-card-section v-show="!isFlashPage">
                        <div class="row">
                            <div
                                class="col-6 q-pa-xs"
                                v-show="isRegisterPage || isListPage"
                            >
                                <i-stock-search
                                    @get="getStock"
                                    label="종목검색"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <i-datetime
                                class="col-6 q-pa-xs"
                                v-model="insertDate"
                                date
                                dateLabel="입력일"
                            />
                            <i-datetime
                                class="col-6 q-pa-xs"
                                v-model="updateDate"
                                date
                                :disable="true"
                                dateLabel="수정일"
                            />
                        </div>

                        <div class="row">
                            <div class="col-6 q-pa-xs">
                                <i-field
                                    :text="marketNews.stockName"
                                    inputLabel="종목명"
                                />
                            </div>
                            <div class="col-6 q-pa-xs">
                                <i-field
                                    :text="marketNews.stockCode"
                                    inputLabel="종목코드"
                                />
                            </div>
                        </div>

                        <div
                            class="q-pa-xs"
                        >
                            <i-input
                                v-model="changeRate"
                                inputLabel="등락률"
                            />
                        </div>
                        <div class="q-pa-xs">
                            <i-input
                                type="textarea"
                                v-model="marketNews.content"
                                inputLabel="사유"
                                :rows="10"
                            />
                        </div>
                    </q-card-section>
                </q-card>
            
            <!-- <div class="q-pa-xs">
            <i-editor
                    v-model="content"
                    :height="350"
                    @input-html="text"
                />
            </div> -->
            </div>
                <div class="q-pa-xs">
                    <i-datetime
                        class="col-6 q-pa-xs"
                        v-model="showDate"
                        date
                        dateLabel="게시일"
                    />
            </div>

        <!-- 하단 버튼 -->
        <div class="q-my-xs">
            <div class="row">
                <!-- 다중 엑셀 등록시 -->
                <i-btn
                    v-show="item.id == null && isExcel && excelData.length > 0"
                    class="full-width"
                    label="등록"
                    @click="getSave()"
                    :disable="excelData.length == 0"
                />
                <!-- 개별 등록시 -->
                <i-btn
                    v-show="item.id == null && !isExcel && excelData.length == 0"
                    class="full-width"
                    label="등록"
                    @click="getSave()"
                />
                 <!-- <i-btn
                    v-show="item.id !== null"
                    class="full-width"
                    label="수정"
                    @click="getSave()"
                /> -->
                <!-- <div
                    class="col q-pa-xs"
                    v-show="item.id != null && team == item.team && isShowDate()"
                >
                    <i-btn
                        class="full-width"
                        label="홈페이지 게시"
                        @click="getCnfirm"
                    />
                </div> -->
                <div
                    class="col q-pa-xs"
                    v-show="item.id != null && team == item.team"
                >
                    <i-btn class="full-width" label="수정" @click="getSave" />
                </div>
            </div>
        </div>

        </q-scroll-area>
           
                    <!-- <div class="q-pa-xs" style="text-align:left;">
                        <q-checkbox
                            v-model="check"
                            dense
                            label="바로 홈페이지 게시"
                            v-show="item.id == null"
                        />
                    </div> -->
                <!-- </div> -->
            <!-- </q-card-section> -->
        <!-- </q-card> -->

    </article>
</template>
<script>
import Debug from '@/debug'

// import Editor from '@/components/common/Editor'
import Button from '@/components/common/Button'
import Input from '@/components/common/Input'
import Field from '@/components/common/Field'
import DateTime from '@/components/common/DateTime'
import StockSearch from '@/components/common/StockSearch'

import { today, isEmpty, isNumeric, clone } from '@/util'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
    name: 'Content',
    components: {
        // 'i-editor': Editor,
        'i-datetime': DateTime,
        'i-btn': Button,
        'i-input': Input,
        'i-field': Field,
        'i-stock-search': StockSearch,
    },
    props: {
        newsType: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            check: false,
            textLine: '',
            showDate: '',
            content:
                '☞ 금일 급등락 기업중 주담 통화결과 특이사항이 없고(통화실패포함), 커뮤니티사이트에서도 특이사항을 찾을 수 없는 종목 리스트',
            isExcel: false, 
            excelData: [],
            insertDate: '',
            updateDate: '',
            changeRate: '',
            marketNews: {
                stockName: '',
                stockCode: '',
                content: '',
            },
            crudChk: '',
        }
    },
    created() {
        this.INIT_DATA_VIEW()   
        if (!this.item.showDate) this.item.showDate = today()   
        if (this.showDate == '') this.showDate = today()        
        this.insertDate = today()
        this.updateDate = today()
        this.changeRate = this.marketNews.DAYS 
        Debug.log('create insertDate', this.insertDate)  
    },
    computed: {
        ...mapState({
            role: (state) => state.session.role,
            team: (state) => state.session.team,
            userId: (state) => state.session.userId,
            selectItem: (state) => state.stock_news.item,
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            stockItems: (state) => state.resource.stockItems,
        }),
        ...mapState('manage', ['item']),
        // test 
        ...mapState('flash', ['preview']),
        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isRegisterPage',
            'isFlashPage',
        ]),
        dynaColumns() {
            const columns = [
                { label: '종목', value: 'stockCode' },
                { label: '옵션', value: 'option' },
                { label: '내용', value: 'content' },
            ]
            return columns
        },
    },
    watch: {
        selectItem(val, old) {
            Debug.log('watch selectItem val=', val, ', old = ', old)
            if (val !== null && val !== old) {
                this.isExcel = false
                this.setPageStatus(this.PAGE_STATUS.DETAIL)
                this.marketNews = clone(val)
                this.insertDate = this.marketNews.showDate
                this.updateDate = this.marketNews.lastUpdateTime
            }
        },
        pageStatus(val) {
            Debug.log('watch pageStatus val=', val)
            if (val === this.PAGE_STATUS.LIST) {
                this.isExcel = false
                this.initItem()
            }
        },
    },
    methods: {
        ...mapActions('alert', [
            'showError',
            'showMessage',
            'showAlert',
            'showDeleteAlert',
        ]),
        ...mapActions('stock_news', [
            'ASYNC_LIST',
            'EXCEL_WRITE_ITEM',
            'WRITE_ITEM',
        ]),
        ...mapMutations('manage', ['INIT_DATA_VIEW']),
        ...mapMutations('page', ['setPageStatus']),
        ...mapActions('manage', ['GET_CALL']),

        isShowDate() {
            return isEmpty(this.item.showDate)
        },

        // 목록화면으로 이동하기
        goListPage() {
            this.excelData = []
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        // 목록 조회
        // getList(param) {
        //     var params = {
        //         newsType: this.newsType.value,
        //     }
        //     if (param) {
        //         params.code = param.stockCode
        //     }

        //     this.ASYNC_LIST(params)
        // },
        //저장
        getSave() {
            if (!this.content) {
                this.showMessage({ vc: this, message: '내용을 입력하세요.' })
                return
            }
             if (this.isExcel) { // 엑셀 인풋 체크 
                
                // 빈값 체크
                for (var item of this.excelData) {
                    if (!item.stockCode) {
                        this.showMessage({
                            vc: this,
                            message: '엑셀 내용을 확인해주세요.',
                        })
                        return
                    }
                }
                // 종목코드 유효체크
                for (let i = 0; i < this.excelData.length; i++) {
                    let row = this.excelData[i]
                    let stock = this.stockItems.find(
                        (element) => element.code == row.stockCode
                    )
                    if (stock == null) {
                        this.showMessage({
                            vc: this,
                            message: '종목코드를 확인해주세요.',
                        })
                        return
                    }
                    // 종목명 추가
                    this.excelData[i].stockName = stock.name
                }
                this.showAlert({ vc: this, message: '등록하시겠습니까?' }).then(
                    () => {
                    
                    let sCrud =
                        this.item.id !== undefined &&
                        this.item.id !== null &&
                        this.item.id !== ''
                            ? 'U'
                            : 'C'
                    this.crudChk = sCrud
                    // let sShowDate = ''
                    // if (
                    //     this.item.id !== undefined &&
                    //     this.item.id !== null &&
                    //     this.item.id !== ''
                    // ) {
                    //     // sShowDate = this.item.showDate 
                    //     this.item.showDate = this.showDate
                    // } else {
                    //     // if (this.check) {
                    //     //     sShowDate = today()
                    //     // }
                    // }        
                    let items = this.excelData
                    // let list = []

                    // items.forEach((row) => {
                    //     let obj = {}
                    //     obj.stockCode = row.stockCode
                    //     obj.stockName = row.stockName
                    //     obj.content = row.content
                    //     const rate = parseFloat(row.option) * 100
                    //     obj.changeRate = rate    
                    //     obj.showDate = sShowDate
                    //     obj.crud = sCrud
                    //     list.push(obj)
                    // })

                    if(this.insertDate == null || this.insertDate == '') this.insertDate = today()
                    if(!this.showDate) this.showDate = today()
                    this.item.showDate = this.showDate

                    let res = null 
                    items.forEach((row) => {
                        let obj = {}
                        obj.stockCode = row.stockCode
                        obj.stockName = row.stockName
                        obj.content = row.content
                        const rate = parseFloat(row.option) * 100
                        obj.changeRate = rate    
                        obj.showDate = this.showDate
                        obj.crud = sCrud
                        obj.CALL_API_URL = '/dividend/save'
                        // list.push(obj)
                        res = this.GET_CALL(obj)
                    })
                    res.then(() => {
                        this.showMessage({
                            vc: this,
                            message: '저장 되었습니다.',
                        })
                        this.initItem()
                        this.$parent.getView(null)
                        this.$parent.getList()
                        // test
                        // this.clear()
                        // this.INIT_LIST()
                        // this.getList()
                        // this.goListPage()
                    })
                    .catch((err) => {
                        this.showError({ vc: this, error: err })
                        console.log('/dividend/save err')
                    })
                    
                    // let param = obj
                    // param.CALL_API_URL = '/dividend/save'
                    // {
                    //     // list: list,
                    //     CALL_API_URL: '/dividend/save',
                    // }
                    // if(this.insertDate == null || this.insertDate == '') this.insertDate = today()
                    // items.forEach((row) => {
                    //     param.stockCode = row.stockCode
                    //     param.stockName = row.stockName
                    //     param.content = row.content
                    //     const rate = parseFloat(row.option) * 100
                    //     param.changeRate = rate    
                    //     param.showDate = this.insertDate
                    //     param.crud = sCrud
                    //     // list.push(obj)
                    // })

                    // Debug.log('GET_CALL param = ', param)

                    // this.GET_CALL(param)
                    //     .then(() => {
                    //         this.showMessage({
                    //             vc: this,
                    //             message: '저장 되었습니다.',
                    //         })
                    //         this.$parent.getView(null)
                    //         this.$parent.getList()
                    //         // test
                    //         // this.clear()
                    //         // this.INIT_LIST()
                    //         // this.getList()
                    //         // this.goListPage()
                    //     })
                    //     .catch((err) => {
                    //         this.showError({ vc: this, error: err })
                    //     })
                        
                    }
                )
                    
            } else {  // 엑셀 인풋 아닐때 
               
                this.showAlert({
                    vc: this,
                    message:
                        '<span style="color:#1976D2;">저장</span> 하시겠습니까?',
                })
                .then(() => {
                    let sCrud =
                        this.item.id !== undefined &&
                        this.item.id !== null &&
                        this.item.id !== ''
                            ? 'U'
                            : 'C'
                    this.crudChk = sCrud
                    // let sShowDate = ''
                    // if (
                    //     this.item.id !== undefined &&
                    //     this.item.id !== null &&
                    //     this.item.id !== ''
                    // ) {
                    //     // sShowDate = this.item.showDate 
                    //     // sShowDate = this.showDate
                        
                    // } else {
                    //     // if (this.check) {
                    //     //     sShowDate = today()
                    //     // }
                    // }
                    // if(!sShowDate) sShowDate = today()
                    if(this.insertDate == null || this.insertDate == '') this.insertDate = today()
                    if(!this.showDate) this.showDate = today()
                    this.item.showDate = this.showDate

                    let param = {
                        id: this.item.id,
                        // title: this.content,
                        content: this.marketNews.content,
                        showDate: this.showDate,
                        stockCode: this.marketNews.stockCode,
                        stockName: this.marketNews.stockName,
                        changeRate: this.changeRate, // marketNews.DAYS
                        crud: sCrud,
                        CALL_API_URL: '/dividend/save',
                    }

                    this.GET_CALL(param)
                        .then(() => {
                            this.showMessage({
                                vc: this,
                                message: '저장 되었습니다.',
                            })
                            this.initItem()
                            this.$parent.getView()
                            this.$parent.getList()
                        })
                        .catch((err) => {
                            this.showError({ vc: this, error: err })
                        })
                })
                .catch(() => {})

                }
        },

        // 등록하기
        // writeExcel() {
        //     // 뉴스타입 체크
        //     // if (this.newsType.value === 'STOCK_ALL' || this.newsType === null) {
        //     //     this.showMessage({
        //     //         vc: this,
        //     //         message: '뉴스타입을 확인해주세요.',
        //     //     })
        //     //     return
        //     // }
        //     // 빈값 체크
        //     for (var item of this.excelData) {
        //         if (!item.stockCode) {
        //             this.showMessage({
        //                 vc: this,
        //                 message: '엑셀 내용을 확인해주세요.',
        //             })
        //             return
        //         }
        //     }
        //     // 종목코드 유효체크
        //     for (let i = 0; i < this.excelData.length; i++) {
        //         let row = this.excelData[i]
        //         let stock = this.stockItems.find(
        //             (element) => element.code == row.stockCode
        //         )
        //         if (stock == null) {
        //             this.showMessage({
        //                 vc: this,
        //                 message: '종목코드를 확인해주세요.',
        //             })
        //             return
        //         }
        //         // 종목명 추가
        //         this.excelData[i].stockName = stock.name
        //     }
        //     this.showAlert({ vc: this, message: '등록하시겠습니까?' }).then(
        //         () => {
        //             this.doWriteExcelItem()
        //         }
        //     )
        // },

        // // 엑셀 입력 API
        // doWriteExcelItem() {
        //     let items = this.excelData
        //     let list = []

        //     items.forEach((row) => {
        //         let obj = {}
        //         obj.stockCode = row.stockCode
        //         obj.stockName = row.stockName
        //         obj.content = row.content
        //         const rate = parseFloat(row.option) * 100
        //         obj.changeRate = rate    
        //         list.push(obj)
        //     })

        //     var params = {
        //         // newsType: this.newsType.value, // MARKET_HIGH_COMMENT
        //         marketNews: list,
        //     }
        //     params.showDate = this.insertDate

        //     this.EXCEL_WRITE_ITEM(params)
        //         .then(() => {
        //             this.showMessage({ vc: this, message: '등록되었습니다.' })

        //             this.clear()
        //             // this.INIT_LIST()
        //             // this.getList()
        //             // this.goListPage()
        //         })
        //         .catch((error) => {
        //             Debug.log('error = ', error)
        //             this.showError({ vc: this, error: error })
        //         })
        // },

        //게시
        getCnfirm() {
            this.showAlert({
                vc: this,
                message:
                    '게시일자 : ' +
                    this.showDate +
                    '<br> <span style="color:#1976D2;">에 해당하는 데이터를 게시</span></span> 하시겠습니까?',
            })
                .then(() => {
                    let param = {
                        id: this.item.id,
                        crud: 'U',
                        showDate: this.item.showDate,
                        CALL_API_URL: '/dividend/saveCnfirm',
                    }
                    this.crudChk = param.crud
                    this.GET_CALL(param)
                        .then(() => {
                            this.showMessage({
                                vc: this,
                                message: '확인 되었습니다.',
                            })
                            this.$parent.getView(null)
                            this.$parent.getList()
                        })
                        .catch((err) => {
                            this.showError({ vc: this, error: err })
                        })
                })
                .catch(() => {
                    console.log('getCnfirm err')
                })
        },
        text(val) {
            this.content = val
        },

        cancelInput() {
            this.initItem()
            this.goListPage()
            this.$emit('event-refresh-list')
        },

        initItem() {
            this.isExcel = false
            this.insertDate = today()
            this.updateDate = today()
            this.excelData = []
            this.showDate = ''
            this.marketNews.stockName = ''
            this.marketNews.stockCode = ''
            // this.marketNews.DAYS = ''
            this.changeRate = ''
            this.marketNews.content = ''
        },

        displayStock(item) {
            console.log(`${item.stockName}(${item.stockCode})`)
            return item.valid
                ? item.stockName + '(' + item.stockCode + ')'
                : item.stockCode
        },

        getStock(stock) {
            this.setPageStatus(this.PAGE_STATUS.REGISTER)
            this.marketNews.stockName = stock.name
            this.marketNews.stockCode = stock.code
        },

        // 20211115 shb 수정 / 금일 급등락 기업중 주담 하단 추가글 색상
        textLineHandler(e) { 
            this.isExcel = true
            if (this.textLine === '') return
            if (e.code == 'KeyV' || e.code == 'MetaLeft') {
                // .replace('%) : ', '%</span>) : ') '<br/><br/>'
                // var sLine = this.setTextStyle(
                //     this.textLine,
                //     '%) : ',
                //     '%</span>) : ',
                //     '<br/><br/>'
                // )
                this.excelData = []
                this.convertToJson(this.textLine)
                this.textLine = ''
                // const infoMessege = this.content
                // 20211101 shb 요청 사항 있어 주석 처리 하였습니다.
                // this.content = ''
                // this.content = this.content.replace('☞ 금일 급등락 기업중 주담 통화결과 특이사항이 없고(통화실패포함), 커뮤니티사이트에서도 특이사항을 찾을 수 없는 종목 리스트','');
                // this.content =
                //     sLine +
                //     this.setTextStyle(infoMessege, '%)', '%</span>)', '')
                // '<br/>☞ 금일 급등락 기업중 주담 통화결과 특이사항이 없고(통화실패포함), 커뮤니티사이트에서도 특이사항을 찾을 수 없는 종목 리스트'
            }
            Debug.log('handle excelData ', this.excelData)
        },

        convertToJson(str) {
            // const reg = new RegExp('.*\\(|\\)', 'g')
            // const colon = new RegExp(':')
            const newLine = new RegExp('\\n')
            let rowList
            if (str !== null) {
                if (str.search(newLine) > 0) {
                    rowList = str.split('\n')
                } else {
                    rowList = [str]
                }
            } else {
                // debug.log('str null')
            }
            let convRow = []
            let json = ''
            rowList.forEach((row) => {
                if (row.length === 0) return

                convRow = row.split('\t')
                Debug.log('convertToJson row = ', row)
                json = this.generateJson(convRow)
        
                Debug.log('convertToJson json = ', json)
                this.excelData.push(json)
            })
        },

        generateJson(row) {
            let json = {}

            for (let i = 0; i < this.dynaColumns.length; i++) {
                Debug.log('generateJson i = ', i)
                if (i === 0) {
                    //첫번째가 종목코드인지 이름인지 확인해서 해당 코드/네임이 있는지 확인
                    let first = row[i]
                    let stock = isNumeric(first)
                        ? this.stockItems.find(
                              (element) => element.code == first
                          )
                        : this.stockItems.find(
                              (element) => element.name == first
                          )
                    if (stock !== null && stock !== undefined) {
                        Debug.log('generateJson stock not null = ', stock)
                        json['valid'] = true
                        json['stockCode'] = stock.code
                        json['stockName'] = stock.name
                    } else {
                        Debug.log('generateJson stock null')
                        json['valid'] = false
                        json[this.dynaColumns[i].value] = first
                    }
                } else {
                    json[this.dynaColumns[i].value] = row[i]
                }
            }
            return json
        },
        clear() {
            this.excelData = []
        },
        remove(index) {
            this.excelData = [
                ...this.excelData.slice(0, index),
                ...this.excelData.slice(index + 1),
            ]
        },

        getViewItem(obj) {
            this.check = false
            this.textLine = ''
            this.content =
                '☞ 금일 급등락 기업중 주담 통화결과 특이사항이 없고(통화실패포함), 커뮤니티사이트에서도 특이사항을 찾을 수 없는 종목 리스트'
            if (obj != null) {
                this.marketNews.content = obj.content
                this.marketNews.stockCode = obj.stockCode
                this.marketNews.stockName = obj.stockName
                // this.marketNews.DAYS = obj.changeRate
                this.changeRate = obj.changeRate
                this.insertDate = obj.showDate
                this.updateDate = obj.showDate
            }
        },

        // setTextStyle(text, oldT, newT, br) {
        //     var sLine = ''
        //     var row = text.split('\n')
        //     for (var i = 0; i < row.length; i++) {
        //         if (row[i] == null || row[i].length == 0) {
        //             continue
        //         }
        //         sLine +=
        //             row[i]
        //                 .replace('. ', '. <strong>')
        //                 .replace('(', '</strong>(')
        //                 .replace('원(-', '원(<span style="color:blue">-')
        //                 .replace('원(+', '원(<span style="color:red">+')
        //                 .replace(oldT, newT) + br
        //     }
        //     return sLine
        // },
    },
}
</script>
