<template>
    <q-layout view="hHh lpR fFf">
        <!-- <q-header elevated class="bg-primary text-white">
      <q-toolbar>
        <q-toolbar-title>
          <q-avatar>
            <img src="https://cdn.quasar.dev/logo-v2/svg/logo-mono-white.svg">
          </q-avatar>
          Title
        </q-toolbar-title>
      </q-toolbar>
    </q-header> -->

        <router-view :key="$route.fullPath" />
    </q-layout>
</template>

<script>
import Debug from '@/debug'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
    name: 'Popup',
    data() {
        return {}
    },
    beforeCreate() {
        Debug.log('popup beforeCreate!!!')
    },
    created() {},
    mounted() {
        Debug.log('popup mounted')
        Debug.log('popup', this.isLoggedIn)
        if (!this.isLoggedIn) {
            Debug.log('popup', this.isLoggedIn)
        } else {
            Debug.log('popup', this.isLoggedIn)
        }
    },
    beforeDestroy() {},
    computed: {
        ...mapState({
            username: (state) => state.session.username,
            team: (state) => state.session.team,
            role: (state) => state.session.role,
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
        }),
        ...mapGetters({
            isLoggedIn: 'session/isLoggedIn',
        }),
    },
    methods: {
        ...mapActions({
            logout: 'session/logout_action',
        }),

        doLogout: function() {
            this.logout(true)
        },
        getPath(path, route) {
            return path + '/' + route
        },
    },
    watch: {
        isLoggedIn: function(newVal, oldVal) {
            console.log('watch')
            if (!oldVal && newVal) {
                this.menuDrawer = true
            } else if (oldVal && !newVal) {
                this.menuDrawer = false
            }
        },
    },
}
</script>

<style></style>
