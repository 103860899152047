<template>
    <section>
        <div class="row q-pa-lg">
            <h5>주식신문 보내기, 홈페이지 개시는 </h5>
            <h5>미리보기 화면에서 "저장" 버튼을 눌러 주식신문을 저장 한 후 보내주세요</h5>
        </div>
        <div class="row q-pa-md">
            <i-select class="col-12 col-md-5" inputLabel="선택" v-model="paperType" :options="paperOptions" :bgColor="bgColor"/>
            <i-btn  class="q-ml-md" label="미리보기" @click="onClickPreview()"/>
            <i-select v-model="keywordGb" :options="keywordGbOptions" option-value="keywordGb" option-label="keywordGbNm" style="width:120px;margin-left:100px;"/>
            <i-input class="q-ml-xs" v-model="keyword" style="width:200px;"/>
            <i-btn  class="q-ml-md" label="개별회원 전송" @click="indSend()"/>            
        </div>
        
        <div class="row q-pa-lg">
            <div class="col">
                <i-btn label="홈페이지 개시" @click="showPage"/>
            </div>
            <div class="col">
                <i-btn label="유료회원 전송" @click="send('REGULAR')"/>
                <i-btn label="관리" @click="showList('REGULAR')"/>
                
            </div>
            <div class="col">
                <i-btn label="신한투자증권 전송" @click="send('SHINHAN')"/>
                <i-btn label="관리" @click="showList('SHINHAN')"/>
                
            </div>
            <div class="col">
                <div >
                    <i-btn label="마케팅 전송" @click="send('MARKETING')"/>
                    <i-btn label="관리" @click="showList('MARKETING')"/>
                </div>
                
                <!-- <div v-if="show=='MARKETING'">
                    <i-table :columns="columns" :list="listRegular" :needCheckBox="true" :verticalScroll="true"
                        @check-event="clickRegular" />
                </div> -->
            </div>           
        </div>
        <!-- 20230906 미전송 인원 전송기능 -->
        <div class="row q-pa-lg">
            <div class="col">
                <div>
                    <i-btn label="미전송 인원 전송(유료회원)" @click="sendNotListSend('REGULAR')"/>
                    <i-btn label="전송확인(유료회원)" @click="SendNotshowList('REGULAR')"/>
                </div>
            </div>
            <div class="col">
                <div>
                    <i-btn label="미전송 인원 전송(신한)" @click="sendNotListSend('SHINHAN')"/>
                    <i-btn label="전송확인(신한)" @click="SendNotshowList('SHINHAN')"/>
                </div>
            </div>
            <div class="col">
                <div>
                    <i-btn label="미전송 인원 전송(마케팅)" @click="sendNotListSend('MARKETING')"/>
                    <i-btn label="전송확인(마케팅)" @click="SendNotshowList('MARKETING')"/>
                </div>
            </div>
        </div>
        <div v-if="show !==''">
            <i-table :columns="columns" :list="list" :needCheckBox="true" :verticalScroll="true"
                @check-event="checkList" />
        </div>
        <!-- <div class="q-pa-md">
            {{ newsTypeStr }}
        </div> -->
    </section>
</template>
<script>
import Input from '@/components/common/Input.vue'
import Button from '@/components/common/Button.vue'
import Select from '@/components/common/Select.vue'
import Table from '@/components/common/SimpleTable.vue'
import constants from '../../constants'
import debug from '@/debug.js'
import {  mapState, mapActions, mapMutations } from 'vuex'
import { closingCheck } from '@/util'

export default {
    name: 'PreView',
    components: {
        'i-input' : Input,
        'i-select' : Select,
        'i-btn' : Button,
        'i-table' : Table,
    },
    data () {
        return {
            bgColor: "red-11", //조간신문 : red, 석간신문 : blue
            newsType:'',
            newsId:'',
            newsTypeStr: '',
            content: '',
            paperOptions : constants.PAPER_TYPE,
            paperType: null,
            keyword: '',
            show: '',
            list: [],
            listRegular: [],
            listShinhan: [],
            listMarketing: [],
            columns: [
                { name: 'phon', label: '휴대번호', align: 'left', field: row => row.code },
                { name: 'memo', label: '이름', align: 'left', field: row => row.name, sortable: true },
                // 20230905 미전송리스트
                { name: 'id', label: 'ID', align: 'left', field: row => row.id, sortable: true },
                { name: 'type', label: '멤버타입', align: 'left', field: row => row.type, sortable: true },
            ],
            keywordGb: {'keywordGb':'id', 'keywordGbNm':'ID'},
            keywordGbOptions:[
                    {'keywordGb':'id', 'keywordGbNm':'ID'},
                    {'keywordGb':'phoneNo', 'keywordGbNm':'전화번호'}
                ],            
        }
    },
    created(){
        /* 
            220520 장중에는 조간, 장마감에는 석간으로 설정
         */
        // this.paperType = this.paperOptions[0]
        this.paperType = this.paperOptions[closingCheck()]
        this.keywordGb = this.keywordGbOptions[0]
    },
    computed : {
        ...mapState ({
            settings: state => state.resource.settings
        }),

    },
    methods: {
        ...mapMutations({
            SET_PAPER_TYPE: 'newspaper/SET_PAPER_TYPE',

        }),
        ...mapActions( 'alert', ['showError', 'showMessage']),
        ...mapActions( 'newspaper', ['PAPER_SHOW', 'PAPER_SEND', 'PAPER_MAKE','NOT_PAPER_SEND']),
        ...mapActions('manage', ['GET_CALL']),

        onClickPreview() {
            debug.log("onClickPreview = ", this.paperType)
            this.SET_PAPER_TYPE(this.paperType)
            this.PAPER_MAKE({newsType:this.paperType.value})
                .then(()=>{
                     window.open(
                        `/newspaper/popup.htm`,
                        "주식신문 미리보기",
                        "width=620,height=820"
                    );
                })
                .catch((err) => {
                    this.showError({vc:this, error:err} )
                })
           
        },
        // 20230905 미전송리스트
        SendNotshowList(type) {
            this.show = type
            let param = {
                type: type,
                CALL_API_URL: '/member/sendnotList'
            }
            this.GET_CALL(param).then((data) => {
                this.list = data.items
                
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        showPage() {
            let param = {
                    newsType:this.paperType.value,
                }
            this.PAPER_SHOW(param)
                .then(() => {
                    this.showMessage({vc:this, message:"홈페이지에 게시되었습니다."})
                })
                .catch((err) => {
                    this.showError({vc:this, error:err} )
                })
        },
        showList(type) {
            this.show = type
            let param = {
                type: type,
                CALL_API_URL: '/member/list'
            }
            this.GET_CALL(param).then((data) => {
                this.list = data.items
                
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        send(type) {
            let param = {
                    newsType:this.paperType.value,
                    memberType:type,
                }
            this.PAPER_SEND(param)
            .then(() => {
                this.showMessage({vc:this, message:"전송되었습니다."})
            })
            .catch((err) => {
                this.showError({vc:this, error:err} )
            })
            //this.showError({vc:this, error:"작업중입니다."} )
        },
        indSend() {
            let param = {
                    newsType:this.paperType.value,
                    memberType:'REGULAR',
                    keywordGb:this.keywordGb.keywordGb,
                    keyword:this.keyword
                }
            this.PAPER_SEND(param)
            .then(() => {
                this.showMessage({vc:this, message:"전송되었습니다."})
            })
            .catch((err) => {
                this.showError({vc:this, error:err} )
            })
            //this.showError({vc:this, error:"작업중입니다."} )
        }, 
        // 20230906 재전송버튼
        sendNotListSend(type) {
            this.show = type
            let param = {
                    newsType:this.paperType.value,
                    memberType: type,
                    keywordGb:'id',
                    keyword: this.selectedIds,
                }
            console.log("params : ", param)
            this.NOT_PAPER_SEND(param)
            .then(() => {
                this.showMessage({vc:this, message:"전송되었습니다."})
            })
            .catch((err) => {
                this.showError({vc:this, error:err} )
            })
            //this.showError({vc:this, error:"작업중입니다."} )
        },    
        checkList(selectedItems) {
            console.log("Selected Items:", selectedItems);
            
            this.selectedIds = selectedItems.map(item => item.id);
            console.log("Selected IDs:", this.selectedIds);
        }
    },
    watch:{
        /* 
            220523 meej 미리보기 - 조간신문, 석간신문 선택시 색변경 추가
         */
        paperType(val){
            if(val.value == "MARKET_MOBILENEWS_AM"){ 
                // 조간신문
                this.bgColor = "red-11"
            }else{
                //석간신문
                this.bgColor = "blue-11"
            }
        }
    }
}
</script>


