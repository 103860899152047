<template>
    <article>
        <q-scroll-area visible>
            <div class="row">
                <div class="col-12 col-sm-4">
                    <q-radio v-model="type" val="ir" label="IR업체" />
                </div>
                <div class="col-12 col-sm-8">
                    <div class="row">
                        <div class="col-6">
                            <q-radio v-model="type" val="stock" label="종목필터링" />
                        </div>
                        <div class="col-6">
                            <div v-if="type == 'stock'">
                                <i-stock-search @get="getStock"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="type == 'ir'">
                <q-markup-table class="q-my-xs" flat bordered dense>
                    <thead>
                        <tr>
                            <th class="text-center">IR업체</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in irList" :key="'ir'+index">
                            <td class="text-left">{{ item }}</td>
                        </tr>
                    </tbody>
                </q-markup-table>
            </div>
            <div v-show="type == 'stock'">
                <q-markup-table class="q-my-xs" flat bordered dense>
                    <thead>
                        <tr>
                            <th class="text-center">종목</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in stockFilteringList" :key="'sf'+index">
                            <td class="text-left">{{ item }}</td>
                        </tr>
                    </tbody>
                </q-markup-table>
            </div>
        </q-scroll-area>
    </article>
</template>
<script>
// import Debug from '@/debug'
import StockSearch from '@/components/common/StockSearch'
// import InfostockService from '@/services/InfostockService'
// import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Resource',
    components: {
        'i-stock-search': StockSearch
    },
    data () {
        return {
            type: 'ir',
            stock: null,
            irList: [
                '로코조이', '동부', '티엘아이', '바텍', '동화기업', '씨그널엔터테인먼트그룹', 'SGA', 'SGA솔루션즈',
                '케이사인', '유비케어', '테크윙', '제이엠아이', '와이디온라인', '미래컴퍼니', '우진비앤지', '텔레필드'
            ],
            stockFilteringList: [
                 '삼진엘앤디', '소프트캠프', '오성첨단소재', 'GH신소재', '한세실업', '이수화학', '이화산업',
                '서연탑메탈', '로지시스', '세미콘라이트', '케이씨에스', '신성이엔지', 'KT서브마린', 'YG PLUS', '한솔홈데코',
                '시스웍', '세진중공업', '모아텍', '사조씨푸드', '모비스', '셀레믹스', '셀트리온', '케이피엠테크', '피플바이오',
                '켐온', '해마로푸드서비스', '박셀바이오', '삼성전자', '남성',  '신원종합개발', '이오플로우', '한솔홈데코',
                '케이피엠테크', '파미셀', 'TCC스틸', '이지웰', '신성통상', '버킷스튜디오',  '엔투텍', '이오플로우',
                '자안', '피플바이오', '텔콘RF제약', '줌인터넷', '엘아이에스', '신라젠', '모바일어플라이언스', '사조씨푸드', '녹십자'
            ]
        }
    },
    methods: {
        getStock(item) {
            this.stock = item
        },
    }
}
</script>
