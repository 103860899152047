<template>
    <article class="bg-grey-4 i-wrap">
        <!-- 구분 -->
        <div class="i-top-wrap">
            <div class="row q-my-xs">
                <!-- TODO: 메뉴 타입에 따라 리소스 목록 조회하기 -->
                <div class="col-6 col-md-12 q-pa-xs">
                    <!-- <q-select
                        v-model="condition.menu"
                        :options="menuOptions"
                        label="메뉴"
                        @input="selectMenu"
                        option-label="label"
                        option-value="option"
                        dense
                        filled
                    /> -->
                </div>
                <div class="col-6 col-md-12 q-pa-xs" v-if="showSubMenu">
                    <q-select
                        v-model="condition.subMenu"
                        :options="subMenuOptions"
                        label="분류"
                        @input="selectSubMenu()"
                        option-label="name"
                        option-value="value"
                        @filter="fitlerSubMenu"
                        dense
                        filled
                    />
                </div>
            </div>
            <div class="row q-my-xs">
                <div v-if="showFilter" class="col-12 q-pa-xs">
                    <i-input label="검색" v-model="filter" ref="search" />
                </div>
            </div>
            <div
                :disabled="selected.length == 0"
                class="col-12 q-my-xs q-pr-md"
            >
                <i-btn label="적용" @click="apply()" class="full-width" />
            </div>
        </div>
        <!-- 소스 목록  @row-click="getItem" v-if="!isThemeStock" -->
        <div v-if="isTheme">
            <i-simple-table
                ref="tableRef"
                :columns="columns"
                :list="filterList"
                @row-click="handleClickedRow"
                @check-event="handleCheckList"
            />
        </div>
        <div v-else>
            <i-table
                ref="tableRef"
                :columns="columns"
                :list="filterList"
                :nextKey="nextKey"
                :checkNextKey="checkNextKey"
                :haveSubItem="haveSubItem"
                @next-click="getList()"
                @row-click="handleClickedRow"
                @check-event="handleCheckList"
            >
            </i-table>
        </div>
    </article>
</template>
<script>
import Input from '@/components/common/Input'
import Table from '@/components/common/Table'
import SimpleTable from '@/components/common/SimpleTable'
import Button from '@/components/common/Button'
import { isEmpty, rawDateTime } from '@/util'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import debug from '@/debug'
export default {
    name: 'Resource',
    components: {
        'i-input': Input,
        'i-table': Table,
        'i-btn': Button,
        'i-simple-table': SimpleTable,
    },
    props:{
        initMenu:String
    },
    data() {
        return {
            // 조건
            condition: {
                menu: null,
                //subMenu: null,
            },
            filter: '',
            selected: [],
            columns: null,
            checkNextKey: false,
            haveSubItem: false,
            subMenuOptions:[],
            eventNewsColumns: [
                {
                    name: 'eventDate',
                    label: '스케쥴날짜',
                    align: 'left',
                    field: (row) => row.date,
                },
                {
                    name: 'title',
                    label: '제목',
                    align: 'left',
                    field: (row) => row.title,
                },
            ],
            newsColumns: [
                {
                    name: 'showTime',
                    label: '작성일자',
                    align: 'left',
                    field: (row) => row.date,
                },
                {
                    name: 'title',
                    label: '제목',
                    align: 'left',
                    field: (row) => row.title,
                },
            ],
            stockNewsColumns: [
                {
                    name: 'showTime',
                    label: '작성일자',
                    align: 'left',
                    field: (row) => row.date,
                },
                {
                    name: 'content',
                    label: '내용',
                    align: 'left',
                    field: (row) => row.content,
                },
            ],
            stockColums: [
                {
                    name: 'code',
                    label: '종목코드',
                    align: 'left',
                    field: (row) => row.code,
                },
                {
                    name: 'name',
                    label: '종목명',
                    align: 'left',
                    field: (row) => row.name,
                },
            ],
            themeColums: [
                {
                    name: 'type',
                    label: '상태',
                    align: 'left',
                    field: (row) => row.type,
                },
                {
                    name: 'name',
                    label: '테마명',
                    align: 'left',
                    field: (row) => row.name,
                },
            ],
            themeStockColums: [
                {
                    name: 'code',
                    label: '종목코드',
                    align: 'left',
                    field: (row) => row.stockCode,
                    sortable: true,
                },
                {
                    name: 'name',
                    label: '종목명',
                    align: 'left',
                    field: (row) => row.stockName,
                    sortable: false,
                },
                {
                    name: 'changeRate',
                    label: '등락률',
                    align: 'left',
                    field: (row) => row.changeRate / 100 + '%',
                    sortable: true,
                    style: (val) =>
                        val.changeRate > 0 ? 'color: red;' : 'color: black;',
                },
            ],
            resourceList: [],
            filterList: [],
        }
    },
    created() {
        //초기 셋팅
        this.condition.menu = this.initMenu
        this.selectMenu()
        console.log('created')
    },
    computed: {
        ...mapState({
            stockList: (state) => state.resource.stockItems,
            themeList: (state) => state.theme.items,
            pageStatus: (state) => state.page.pageStatus,
            list: (state) => state.resource.resourceList,
            marketNewsType: (state) => state.resource.MarketNewsType,
            stockNewsAllType: (state) => state.resource.StockNewsAllType,
            eventtNewsType: (state) => state.resource.EventNewsType,
            nextKey: (state) => state.resource.nextKey,
            request: (state) => state.resource.request,
        }),
        ...mapGetters('page', ['isRegisterPage']),

        
        isTheme() {
            return isEmpty(this.condition.menu)
                ? false
                : this.condition.menu === 'Theme'
        },
        showFilter() {
            return isEmpty(this.condition.menu)
                ? false
                : this.condition.menu === 'Theme'
        },
        showSubMenu() {
            return isEmpty(this.condition.menu)
                ? false
                : this.condition.menu === 'Market'
        },

    },
    methods: {
        ...mapMutations('resource', [
            'INIT_CHECK',
            'INIT_LIST',
            'INIT_REQUEST',
        ]),
        ...mapActions('resource', [
            'GET_NEWSTYPE_LIST',
            'GET_MARKET_RESOURCE_LIST',
            'GET_STOCK_RESOURCE_LIST',
            'GET_THEME_RESOURCE_LIST',
            'GET_EVENT_RESOURCE_LIST',
        ]),
        ...mapActions({
            GET_THEME_LIST: 'theme/GET_THEME_LIST',
            GET_THEMESTOCK_LIST: 'theme_stock/GET_THEMESTOCK_LIST',
            showError: 'alert/showError',
        }),
        fitlerSubMenu(val, update) {
            debug.log('fitlerSubMenu = ', this.condition.menu)
            var param
            if (this.condition.menu === 'Market') {
                if (this.marketNewsType.length === 0) {
                    param = { newsType: 'MarketNewsType' }
                    this.GET_NEWSTYPE_LIST(param).then(() => {
                        this.subMenuOptions = this.marketNewsType
                        update()
                    })
                } else {
                    this.subMenuOptions = this.marketNewsType
                    update()
                }
            } else if (this.condition.menu === 'Stock') {
                if (this.stockNewsAllType.length === 0) {
                    param = { newsType: 'StockNewsAllType' }
                    this.GET_NEWSTYPE_LIST(param).then(() => {
                        this.subMenuOptions = this.stockNewsAllType
                        update()
                    })
                } else {
                    this.subMenuOptions = this.stockNewsAllType
                    update()
                }
            } else if (this.condition.menu === 'Event') {
                if (this.eventtNewsType.length === 0) {
                    param = { newsType: 'EventNewsType' }
                    this.GET_NEWSTYPE_LIST(param).then(() => {
                        this.subMenuOptions = this.eventtNewsType
                        update()
                    })
                } else {
                    this.subMenuOptions = this.eventtNewsType
                    update()
                }
            } else {
                this.subMenuOptions = []
                update()
            }
        },
        selectMenu() {
            debug.log('selectMenu!!! menu', this.condition.menu)

            this.condition.subMenu = null
            this.INIT_LIST()
            this.resourceList = []
            this.filterList = []
            this.haveSubItem = false


            if (this.condition.menu === 'Stock') {
                this.columns = this.stockColums
                const self = this
                this.stockList.forEach(function(item) {
                    self.resourceList.push({ code: item.code, name: item.name })
                })
                //this.filterList = this.resourceList
            } else if (this.condition.menu === 'Theme') {
                const self = this
                this.columns = this.themeColums
                const params = { type: 'PUBLIC,INTERNAL_PUBLIC' }
                this.GET_THEME_LIST(params).then(() => {
                    self.themeList.forEach(function(item) {
                        self.resourceList.push({
                            name: item.name,
                            type: item.type,
                            code: item.code,
                            checked: false,
                        })
                    })
                    self.filterList = self.resourceList
                })
                
            } else {
                this.columns = this.newsColumnss
            }
        },
        selectSubMenu(req) {
            debug.log('selectSubMenu req = ', req)
            if (req !== undefined && req !== null) {
                this.condition.subMenu = req.newsType
            }

            debug.log('selectSubMenu newsType = ', this.condition.subMenu)
            if (this.condition.menu === 'Event') {
                this.columns = this.eventNewsColumns
            } else if (this.condition.menu === 'Stock') {
                this.columns = this.stockNewsColumns
            } else {
                this.columns = this.newsColumns
            }
            this.resourceList = []
            this.INIT_LIST()
            let params = {
                newsType: this.condition.subMenu.value,
            }
            if (req !== undefined && req !== null && !isEmpty(req.date)) {
                params.date = rawDateTime(req.date)
            }
            this.getList(params)
        },
        // 리소스 목록 조회하기
        getList(params) {
            switch (this.condition.menu) {
                case 'Market':
                    this.haveSubItem = true

                    this.GET_MARKET_RESOURCE_LIST(params).then(() => {
                        //update!
                        //this.filterList = this.list
                        this.resourceList = this.list
                    })
                    break
                case 'Stock':
                    {
                        if (this.condition.subMenu.value) {
                            params.newsType = this.condition.subMenu.value
                            this.GET_STOCK_RESOURCE_LIST(params).then(() => {
                                //update!
                                this.resourceList = this.list
                                //this.filterList = this.list
                            })
                        }
                    }
                    break
                case 'Theme':
                    {
                        // if (this.condition.subMenu.value) {
                        //     params.newsType = this.condition.subMenu.value
                        //     this.GET_THEME_RESOURCE_LIST(params).then(() => {
                        //         //update!
                        //         //this.filterList = this.list
                        //         this.resourceList = this.list
                        //     })
                        // }
                    }
                    break
                case 'Event': {
                    if (this.condition.subMenu.value) {
                        const self = this
                        params.newsType = this.condition.subMenu.value
                        this.GET_EVENT_RESOURCE_LIST(params).then(() => {
                            //update!
                            this.resourceList = this.list
                            let resource = {}
                            if (self.request) {
                                resource = self.request
                            }
                            resource.zindex = self.list.length
                            self.$parent.$emit('sendResource', resource)
                        })
                    }
                }
            }
        },
        apply() {
            this.filter = ''
            let resource = {}
            if (this.request) {
                resource = this.request
            }
            resource.items = this.selected

            this.$parent.$emit('sendResource', resource)
            this.$refs.tableRef.parentClearCheck()
            this.selected = []
            this.$refs.search.$el.focus() // 22.09.06 suye  
        },

        /**  table callback */
        handleClickedRow(item) { 
            debug.log('handleClickedRow = ', this)
            if (this.condition.menu === 'Theme') {
                this.selected.push(item)
                this.apply()
            }
            this.$refs.search.$el.focus() // 22.09.06 suye  
        },
        // 리소스 카드 추가하기
        // addResourceCard(item) {
        //     // 해당 리소스 check값이 true일 경우에만 입력창에 리소스 카드 추가
        //     if (item.check) {
        //         var card = {
        //             title: item.title,
        //             content: item.content,
        //             textBytes: getByteSize(item.content)
        //         }
        //         // 리소스 카드를 Content.vue에 전달
        //         this.$parent.$emit("sendResource", card)
        //     }
        // },
        handleCheckList(list) {
            this.selected = list
        },
        // FormatDate(datetime) {
        //     return datetime ? FormatDate(datetime) : null
        // }
    },
    watch: {
        pageStatus: function() {
            if (this.isRegisterPage) {
                this.INIT_CHECK()
            }
        },
        filter: function(val) {
            if (this.condition.menu === 'Stock') {
                this.filterList = this.resourceList.filter(
                    (v) => v.name0.includes(val) || v.value0.includes(val)
                )
            } else {
                this.filterList = this.resourceList.filter(
                    (v) => v.name.includes(val) || v.type.includes(val)
                )
            }
        },
        resourceList: function(list) {
            debug.log('watch resourceList size = ', list.length)
            this.filterList = list
            console.log(this.filterList)
        },
        request(req, old) {
            debug.log('watch request1', req.menu)
            // 221017 meej 테마 초기 조회시 중복 데이터 표출 오류 수정
            if (!isEmpty(req.menu) && old != null) {
                this.selected = []

                debug.log('watch request2', this.request)
                this.condition.menu = req.menu
                if (!isEmpty(req.newsType)) {
                    this.selectMenu()
                    this.selectSubMenu(req)
                } else {
                    this.selectMenu(this.condition.menu)
                }
            }
        },
    },
}
</script>
