<template>
    <section class="row q-pl-xs">
        <q-toolbar class="row q-mt-md text-left">
            <div class="q-pa-xs"><i-select v-model="financialGb" :options="financialGbOptions" option-value="value" option-label="name" @input="getList" style="width:130px;"/></div>
            <div class="q-pa-xs">
                <q-select v-model="stockCode" :options="stockOptions" option-label="name" @filter="filterFn" input-debounce="0" @input="getList" dense outlined :hide-selected="hide_selected" :fill-input="hide_selected" use-input hide-dropdown-icon options-dense style="width:200px;">
                    <q-tooltip anchor="top middle" self="bottom middle" :offset="[5, 5]">한글로 검색 시, enter를 눌러주세요.</q-tooltip>
                    <template v-slot:no-option><q-item><q-item-section class="text-grey">검색된 데이터가 없습니다.</q-item-section></q-item></template>
                </q-select>
            </div>
            <div class="q-pa-xs"><i-select v-model="ifrs" :options="ifrsOptions" option-value="value" option-label="name" @input="getList" style="width:120px;"/></div>
            <div class="q-pa-xs"><i-select v-model="closingDate" :options="closingDateOptions" option-value="value" option-label="name" @input="getList" style="width:90px;"/></div>
            <div class="q-pa-xs"><i-select v-model="reportType" :options="reportTypeOptions" option-value="value" option-label="name" @input="getList" style="width:140px;"/></div>
            <div class="q-pa-xs"><i-btn icon="search" @click="getList()"/></div>
            <div class="q-pa-xs"><i-btn label="선택삭제" @click="getDeleteChk()"/></div>
            <div class="col q-pa-xs text-right"><i-btn label="간편등록" @click="getUpdatePopup()"/> <i-btn label="신규등록" @click="getView(null)"/></div>
        </q-toolbar>
        <i-list class="col-6 q-pa-sm" ref="financialList"/>
        <!-- 220707 meej 금융주 목록 추가 -->
        <i-stockTable class="col-2 q-pt-sm"/>
        <i-content class="col-4 q-pa-sm" ref="financialContent"/>
    </section>
</template>
<script>
import CONSTANTS from '@/constants'
import Select from '@/components/common/Select.vue';
import Button from '@/components/common/Button'
import List from "@/components/earning/financial/List.vue";
import Content from "@/components/earning/financial/Content.vue";
import stockTable from "@/components/earning/financial/stockTable.vue";
import { mapState, mapActions } from 'vuex'

export default {
    name: "Financial",
    props: {
        hide_selected: {
            type: Boolean,
            default: true
        }
    },
    components: {
        'i-select' : Select,
        'i-btn': Button,
        "i-list": List,
        'i-content': Content,
        'i-stockTable': stockTable
    },
    data() {
        return {
            stockList: [],
            stockCode: '',
            stockOptions: [],
            financialGb:{'value':'', 'name':'구분'},
            financialGbOptions: [{'value':'', 'name':'구분'}].concat(CONSTANTS.FINANCIAL_FINANCIAL_GB),
            ifrs: {'value':'', 'name':'연결/별도'},
            ifrsOptions: [{'value':'', 'name':'연결/별도'}].concat(CONSTANTS.COM_IFRS),
            closingDate: {'value':'', 'name':'연도'},
            closingDateOptions: [{'value':'', 'name':'연도'}],
            reportType: {'value':'', 'name':'리포트'},
            reportTypeOptions: [{'value':'', 'name':'연결/별도'}].concat(CONSTANTS.FINANCIAL_REPORT_TYPE)
        }
    },
    computed: {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            stockItems : state => state.resource.stockItems
        })
    },
    created () {
        this.getPage();
    },
    methods: {
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        getPage() {
            var iYear = Number(new Date().getFullYear())+2;
            for(var i=iYear; i >= 2016; i--){
                this.closingDateOptions.push({
                    value: i+'',
                    name: i+''
                });
            }
            var list = [];
            try {
                list = JSON.parse(this.stockItems);
            } catch (e) {
                list = this.stockItems;
            }
            var ds_list = [];
            list.forEach(row => {
                ds_list.push({
                    code : row['code'],
                    name : row['name'] + ' (' + row['code'] + ')'
                });
            });
            this.stockList = ds_list;
        },
        getList() {
            this.$refs.financialList.getList();
        },
        getView(item) {
            this.$refs.financialList.getView(item);
        },
        getDeleteChk() {
            this.$refs.financialList.getDeleteChk();
        },
        filterFn (val, update, abort) {
            // 종목검색 시, 1자리 이하일 경우 검색되지 않음.
            if (val.length < 2) {
                if (val.length == 0) {
                    this.stockCode = '';
                    this.getList();
                }
                abort();
                return;
            }
            update(() => {
                const needle = val.toLowerCase();
                this.stockOptions = this.stockList.filter(
                    v => v.name.toLowerCase().indexOf(needle) > -1 || v.code.indexOf(val) > -1
                )
            })
        },
        getUpdatePopup(){
            this.$refs.financialList.getUpdatePopup();
        }
    }
};

</script>
