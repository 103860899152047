import api from '../../../services/InfostockService'
import Debug  from '../../../debug'
import { FormatDate, nowTime, addWorkDay, today } from '@/util'
const state = () => ({
    // stockItems: JSON.parse(localStorage.getItem("stocks")) || [],
    stockItems: localStorage.getItem('stocks') || [],
    stockCompany: [],
    stockType: [],
    themeType: [],
    themeStockType: [],
    recommendType: [],
    newsOption:[],
    newsType:[],
    MarketNewsType: [],
    StockNewsType: [],
    StockNewsAllType: [],
    EventNewsType: [],
    MemberType: [],
    resourceList: [],
    stockPart: [],
    nextKey: null,
    currMenu: null,
    request: null,
    setting:[],
    /* 
        220502 meej OrderTable.vue의 type
    */
    orderTbType:{},
})

const getters = {}

const mutations = {
    SET_MENU(state, menu ){
        state.currMenu = menu
    },
    setInit(state, { data }) {
        state.stockItems = data.stockItems
        // 22.09.15 suye 종목검색 홈페이지와 동일한 정렬 순서로 변경 
        let testStockItem = state.stockItems
        testStockItem.sort((a, b) => {

            if(a.name.length > b.name.length) { return 1 } 
            if(a.name.length < b.name.length) { return -1 }

            if(a.name > b.name) { return -1 } 
            if(a.name < b.name) { return 1 }

            if(a.name.localeCompare(b.name) > b.name.localeCompare(a.name)) { return -1 }
            if(a.name.localeCompare(b.name) < b.name.localeCompare(a.name)) { return 1 }

        }) 
        state.stockItems = testStockItem
        // console.warn('CHECK state.stockItems=', state.stockItems)

        state.stockType = data.stockType
        state.themeType = data.themeType
        state.themeStockType = data.themeStockType
        state.recommendType = data.recommendType
        state.newsOption = data.newsOption
        state.newsType = data.newsType
        state.memberType = data.memberType
        localStorage.setItem('stocks', JSON.stringify(data.stockItems))
    },
    setNewsTypeList(state, { data }) {
        state[data.newsType] = data.newsTypes
    },
    setResourceList(state, { data }) {
        var arr = state.resourceList
        arr.push(...data.items)

        // 데이터 가공
        arr.forEach((row, index) => {
            row.index = index
            row.check = false
            row.expand = false
        })

        state.resourceList = arr
        state.nextKey = data.nextKey
    },
    INIT_CHECK(state) {
        for (var item of state.resourceList) {
            item.check = false
        }
    },
    INIT_LIST(state) {
        state.resourceList = []
        state.nextKey = null
    },
    INIT_REQUEST(state) {
        state.request = {}
    },
    REQUEST_RESOURCE (state, req) {
        state.request = req
    },
    SET_STOCK_COMPANY(state, { data }) {
        const userId = localStorage.getItem('userId') 
        const staffOrders = [ // 아이디별 담당증권사
            // {id: 'dongdong2', code0: '001', code1:'002', code2: '003',}, // test
            {id: 'dydrhkd333', code0: '030', code1:'050', code2: '009', code3: '022'}, // dydrhkd333
            {id: 'woonge92', code0: '002', code1:'003', code2: '021', code3: '006'}, // woonge92 
            {id: 'kyk2039', code0: '004', code1:'025', code2: '071', code3: '063', code4: '023'}, // kyk2039
            {id: 'jb319', code0: '012', code1:'005', code2: '013', code3: '301'}, // jb319
            {id: 'lucas', code0: '017', code1:'056', code2: '068', code3: '010', 
            code4: '004', code5: '002', code6:'031', code7: '022', code8: '013'}, // lucas
        ]

        // 담당 증권사코드로 우선정렬
        staffOrders.forEach(e => {
            if(e.id == userId){
                // console.log('id matches ', userId)
                data.items.sort((a, b) => {

                    if(a.code == e.code0 && b.code !== e.code0) return -1
                    if(a.code !== e.code0 && b.code == e.code0) return 1
    
                    if(a.code == e.code1 && b.code !== e.code1) return -1
                    if(a.code !== e.code1 && b.code == e.code1) return 1

                    if(a.code == e.code2 && b.code !== e.code2) return -1
                    if(a.code !== e.code2 && b.code == e.code2) return 1

                    if(a.code == e.code3 && b.code !== e.code3) return -1
                    if(a.code !== e.code3 && b.code == e.code3) return 1

                    if(e.code4 || e.code5 || e.code6 || e.code7 || e.code8){

                        if(a.code == e.code4 && b.code !== e.code4) return -1
                        if(a.code !== e.code4 && b.code == e.code4) return 1
    
                        if(a.code == e.code5 && b.code !== e.code5) return -1
                        if(a.code !== e.code5 && b.code == e.code5) return 1
    
                        if(a.code == e.code6 && b.code !== e.code6) return -1
                        if(a.code !== e.code6 && b.code == e.code6) return 1

                        if(a.code == e.code7 && b.code !== e.code7) return -1
                        if(a.code !== e.code7 && b.code == e.code7) return 1
    
                        if(a.code == e.code8 && b.code !== e.code8) return -1
                        if(a.code !== e.code8 && b.code == e.code8) return 1
                    }

                });
            }
        });
        state.stockCompany = data.items
    },
    SET_STOCK_PART(state, { data }) {
        state.stockPart = data.items
    },
    settings(state, {data}) {
        Debug.log('settings data', data)
        state.settings = data.items
    },
    /* 220502 meej OrderTable.vue의 type */
    SET_ORDERTBTYPE(state, data){
        state.orderTbType = data
    },
}

const actions = {
    async GET_INIT({ commit }) {
        const { data } = await api.initial()
        Debug.log('GET_INIT data', data)
        commit('setInit', { data })
    },
    //뉴스타입 조회
    async ASYNC_NEWSTYPE_LIST({ commit }, params) {
        Debug.log('GET_NEWSTYPE_LIST params', params)
        const { data } = await api.newsType(params)
        Debug.log('GET_NEWSTYPE_LIST data', data)

        data.newsType = params.newsType

        commit('setNewsTypeList', { data })
    },
    GET_NEWSTYPE_LIST({ commit }, params) {
        Debug.log('GET_NEWSTYPE_LIST params', params)

        return new Promise((resolve, reject) => {
            api.newsType(params)
            .then((response) => {
                const { data } = response

                data.newsType = params.newsType
                commit('setNewsTypeList', { data })
                Debug.log('GET_NEWSTYPE_LIST data', data)
                resolve(data.newsTypes)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    // 시황 리소스 목록 조회
    GET_MARKET_RESOURCE_LIST({ commit }, params) {
        params.inclSub = 1
        params.nextKey = state.nextKey
        // 220802 meej 시황 전체(당일만조회)시 전송일자 기준으로 조회
        if(params.newsType == 'MARKET_CLOSED_ALL'){
            let sendDate
            if (Number(nowTime('HH')) > 13) sendDate = addWorkDay(1)
            else sendDate = today()
            params.date = sendDate
        }
        return new Promise((resolve, reject) => {
            api.marketNewsList(params)
            .then((response) => {
                const { data } = response
                data.items.forEach((row) => {
                    row.date = FormatDate(row.createTime)
                })

                commit('setResourceList', { data })

                resolve()
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    async ASYNC_SETTINGS({commit}) {
        const  data  = api.settings();
        Debug.log('ASYNC_SETTINGS = ', data)
        commit('settings', {data})
    },
    GET_SETTINGS() {
        return new Promise((resolve, reject) => {
            api.settings()
            .then((res) => {
                Debug.log('GET_SETTINGS = ', res)
                resolve(res.data)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    SET_SETTINGS(_, params) {
        return new Promise((resolve, reject) => {
            api.setSetting(params)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    async WRITE_MACRO(_, params) {
        await api.writeTest(params.service, params.body)

    },
    WRITE_TODAYWORLD(_, params) {
        Debug.log('WRITE_TODAYWORLD params', params)
        return new Promise((resolve, reject) => {
            api.setTodayWorld(params)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })

    },
    GET_TODAYWORLD(_, params ) {
        return new Promise((resolve, reject) => {
            api.getTodayWorld(params)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    // 종목 리소스 목록 조회
    GET_STOCK_RESOURCE_LIST({ state, commit }, params) {
        params.inclSub = 1
        params.nextKey = state.nextKey

        Debug.log('GET_STOCK_RESOURCE_LIST params', params)

        return new Promise((resolve, reject) => {
            api.stockNewsList(params)
            .then((response) => {
                const { data } = response
                data.items.forEach((row) => {
                    row.date = FormatDate(row.createTime)
                })
                commit('setResourceList', { data })

                resolve()
            })
            .catch((error) => {
                reject(error)
            })
        })

    },
    // 테마 리소스 목록 조회
    GET_THEME_RESOURCE_LIST({ state, commit }, params) {
        params.nextKey = state.nextKey
        Debug.log('GET_THEME_RESOURCE_LIST params', params)
        return new Promise((resolve, reject) => {
            api.themeNews(params)
            .then((response) => {
                const { data } = response
                data.items.forEach((row) => {
                    row.date = FormatDate(row.createTime)
                })

                commit('setResourceList', { data })

                resolve()
            })
            .catch((error) => {
                reject(error)
            })
        })
        // const { data } = await api.themeNews(params)
        // Debug.log('GET_THEME_RESOURCE_LIST data', data)

        // commit('SET_RESOURCE_LIST', { data })
    },
    // 증권사 조회
    async GET_STOCK_COMPANY({ commit }, params) {
        Debug.log('GET_STOCK_COMPANY params', params)
        const { data } = await api.stockCompanyList(params)
        Debug.log('GET_STOCK_COMPANY data', data)

        commit('SET_STOCK_COMPANY', { data })
    },
    // 이벤트 리소스 목록 조회
    async GET_EVENT_RESOURCE_LIST({ state, commit }, params) {
        params.nextKey = state.nextKey

        Debug.log('GET_EVENT_RESOURCE_LIST params', params)

        return new Promise((resolve, reject) => {
            api.scheduleList(params)
            .then((response) => {
                const { data } = response
                data.items.forEach((row) => {
                    row.date = FormatDate(row.eventDate)
                })

                commit('setResourceList', { data })

                resolve()
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    // 업종 조회
    async GET_STOCK_PART({ commit }) {
        const { data } = await api.stockPartList()
        Debug.log('GET_STOCK_PART data', data)

        commit('SET_STOCK_PART', { data })
    },
    MACRO_UPLOAD(_,params) {
        Debug.log('MACRO_UPLOAD data', params)
        return new Promise((resolve, reject) => {
            api.macroUpload(params)
            .then((response)=> {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    }
}

export const resource = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
