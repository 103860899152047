<template>
    <section class="row q-pa-lg">
        <div class="col-12 col-sm-8">
            <q-tabs v-model="tab" class="bg-grey-4" dense align="justify">
                <q-tab name="B" label="B2B 타입" @click="getTep('B')"/>
                <q-tab name="P" label="기사"  @click="getTep('P')"/>
            </q-tabs>
        </div>
        <i-list class="col-6 q-pa-md" ref="infostockList" v-bind:is="impotList"/>
        <i-content class="col-6 q-pa-md" ref="infostockContent" v-bind:is="impotContent"/>
    </section>
</template>
<script>
import List from "@/components/trnsmis/infostockTitle/List.vue";
import ListB2B from "@/components/trnsmis/infostockB2B/List.vue";
import Content from "@/components/trnsmis/infostockTitle/Content.vue";
import ContentB2B from "@/components/trnsmis/infostockB2B/Content.vue";

export default {
    name: "infostock",
    components: {
        List,
        ListB2B,
        Content,
        ContentB2B
    },
    data() {
        return {
            tab:'B',
            impotList : 'ListB2B',
            impotContent : 'ContentB2B'
        }
    },
    methods: {
        getList() {
            this.$refs.infostockList.getList();
        },
        getView(item) {
            this.$refs.infostockList.getView(null, item);
        },
        getTep(val) {
            if(val == "B"){
                this.impotList = 'ListB2B';
                this.impotContent = 'ContentB2B';
            }else{
                this.impotList = 'List';
                this.impotContent = 'Content';
            }
        },
    }
};

</script>
