import api from '../../../services/InfostockService'
import Debug  from '../../../debug'

const state = () => ({
    items: [],
    item: null,
    nextKey: null,
    list: []
})

const mutations = {
    SET_LIST(state, data ) {
        var arr = state.list
        arr.push(...data.items)

        // 인덱스 추가
        arr.forEach((row, index) => {
            row.index = index
        })

        state.list = arr
        state.nextKey = data.nextKey

    },
    setItems(state, { data }) {
        let arr = state.items
        arr.push(...data.items)

        // 인덱스 추가
        arr.forEach((row, index) => {
            row.index = index
            row.checked = false
        })
        arr.sort(function (a, b) {
                if (a.ZINDEX > b.ZINDEX) {
                    return 1
                }
                if (a.ZINDEX < b.ZINDEX) {
                    return -1
                }
                return 0
            })
        state.items = arr
        state.nextKey = data.nextKey

    },
    INIT_LIST(state) {
        state.list = []
        state.nextKey = null
    },
    INIT_ITEMS(state) {
        state.items = []
        state.nextKey = null
    },
    SET_ITEM(state, item) {
        state.item = item
    },
    /* 220623 meej 페이징 오류 수정 */
    SET_NEXTKEY(state, nextKey) {
        state.nextKey = nextKey
    }
    
}

const actions = {
    async ASYNC_FEATURED_THEME_LIST({ state, commit }, params) {
        params.nextKey = state.nextKey

        const { data } = await api.themeFeatures(params)
        commit('setItems', { data })
    },
    GET_FEATURED_THEME_LIST(_, params) {
        return new Promise((resolve, reject) => {
            api.themeFeatures(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },
    GET_DETAIL(_, params) {
        return new Promise((resolve, reject) => {
            api.themeFeaturesDetail(params)
                .then((response) => {
                    Debug.log('GET_DETAIL success')
                    resolve(response.data)
                })
                .catch((error) =>{
                    Debug.log('GET_DETAIL fail')
                    reject(error)
                })
        })
        // data.news.themeName = rootState.theme.items.find(v => v.code === data.news.themeCode) ==undefined ? '' : rootState.theme.items.find(v => v.code === data.news.themeCode).name
        // Debug.log('GET_DETAIL data', data)
        // commit('SET_DETAIL', { data })
    },
    async WRITE_ITEMS(_, params) {
        Debug.log('WRITE_ITEMS params', params)
        if (params.newsType == '오후장'){
            params.newsType = 'THEME_FEATURED_PM'
        }
        else if(params.newsType == '오전장'){
            params.newsType = 'THEME_FEATURED_AM'
        }
        await api.themeFeaturesWrite(params)
    },
    async DELETE_ITEM(_, params) {
        Debug.log('DELETE_ITEMS params', params)
        await api.themeFeaturesDelete(params)

    },
    // 여러개 삭제
    DELETE_ITEMS(_, params) {
        return new Promise((resolve, reject) => {
            api.themeNewsMultiDelete(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    CONFIRM_FEATURED(_, params){
        return new Promise((resolve, reject) => {
            api.themeFeaturedConfirm(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error.data)
                })
        })
    },
    FEAURED_ORDER(_, params) {
        return new Promise((resolve, reject) => {
            api.themeFeaturesOrder(params)
                .then((response) => {
                    Debug.log('GET_DETAIL success')
                    resolve(response.data)
                })
                .catch((error) =>{
                    Debug.log('GET_DETAIL fail')
                    reject(error)
                })
        })
    },
    GET_MARKET_NEWS(_, params) {
        Debug.log('GET_DETAIL params = ', params)
        return new Promise((resolve, reject) => {
            api.marketNewsByDate(params)
                .then((response) => {
                    Debug.log('GET_DETAIL success')
                    resolve(response.data)
                })
                .catch((error) =>{
                    Debug.log('GET_DETAIL fail')
                    reject(error)
                })
        })
    },
    SET_FEATURED_HISTORY(_, params) {
        Debug.log('SET_FEATURED_HISTORY params = ', params)
        return new Promise((resolve, reject) => {
            api.themeFeaturedHistory(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },
    MAKE_DAILY(_, params) {
        return new Promise((resolve, reject) => {
            api.themeFeaturedDaily(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },
    GET_DAILY_TODAY() {
        return new Promise((resolve, reject) => {
            api.themeFeaturedDailyToday()
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },
    async MAKE_BASE64(_, file) {
        let result_base64 = await new Promise((resolve) => {
                let fileReader = new FileReader();
                fileReader.onload = (evt) => resolve(evt.target.result) //resolve(fileReader.result);
                fileReader.readAsDataURL(file);
            });

            //console.log(result_base64); // aGV5IHRoZXJl...

            return result_base64;
        //await api.themeFeaturesWrite(params)
    },
    CONFIRM_DAILY(_, params) {
        return new Promise((resolve, reject) => {
            api.themeFeaturedDailyConfirm(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },
    DELETE_DAILY_IMAGE(_, params) {
        return new Promise((resolve, reject) => {
            api.themeFeaturedDailyDelImage(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },
    // 220811 meej 데일리테마 - 테마 종목 시세 가져오기
    GET_FEATURED_THEME_PRICE(_, params) {
        return new Promise((resolve, reject) => {
            api.themeFeaturesPrice(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },
    
}
const getters = {
}

export const theme_featured = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
