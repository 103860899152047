<template>
    <section>
        <!-- 구분 -->
        <div class="row">

            <div class="col-6 q-pa-md" >
                <div class="row i-top-wrap">
                    <div class="col-12 col-sm-6 q-pa-xs">
                        <i-select v-model="condition.stockCompany" :options="stockCompanyOptions"
                            option-label="name" option-value="code"
                            label="증권사" @input="selectStockCompany()" />
                    </div>
                    <div class="col-12 col-sm-6 q-pa-xs">
                        <i-select v-model="condition.investOpinion" :options="condition.investOpinionOptions"
                            label="투자의견" @input="selectOpinion()" />
                    </div>
                </div>
                <i-list :condition="condition" :stockSet="stockSet"/>
            </div>

            <i-content class="col-6 q-pa-md" :condition="condition" 
                @event-get-list="handleGetList"
            />
        </div>
    </section>
</template>
<script>
import { objToString } from '@/util'

import Select from '@/components/common/Select'
import List from '@/components/stock/stock-invest-opinion/List.vue'
import Content from '@/components/stock/stock-invest-opinion/Content.vue'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'StockFeatured',
    components: {
        'i-list' : List,
        'i-content' : Content,
        'i-select': Select,
    },
    data () {
        return {
            // 조건
            condition: {
                stockCompany: '전체',
                investOpinion: '전체',
                investOpinionOptions: ['전체'],
            },
            investOpinionOptions: ['전체'],
            stockSet : null,
        }
    },
    created () {
        this.setPageStatus(this.PAGE_STATUS.LIST)
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
            isMobile: state => state.page.isMobile,
            stockCompanyItems : state => state.resource.stockCompany,
        }),
        ...mapGetters( 'page', ['isListPage', 'isEditPage', 'isRegisterPage']),

        stockCompanyOptions () {
            var arr = [{name: '전체', code: null}]
            arr.push(...this.stockCompanyItems)
            return arr
        },
    },
    methods: {
        ...mapMutations( 'page', ['setPageStatus']),
        ...mapMutations( 'stock_invest_opinion', ['SET_LIST', 'SET_DETAIL', 'INIT_LIST', 'INIT_DETAIL', 'SET_OPTIONS']),
        ...mapActions( 'stock_invest_opinion', ['GET_LIST','GET_DETAIL','DELETE_ITEMS']),

        getList(params) {
            params.newsType = this.newsType

            this.INIT_LIST()
            this.GET_LIST(params)
        },
        // 증권사 선택
        selectStockCompany() {
            var params = {}

            if (this.condition.stockCompany.code) {
                // 투자의견 옵션
                var opinionString = this.stockCompanyOptions.find(v => v.code == this.condition.stockCompany.code).opinion
                this.condition.investOpinionOptions = opinionString.split(',')
                this.condition.investOpinionOptions.unshift('전체')

                var options = {}
                options.STOCK_COMPANY0 = this.condition.stockCompany.code

                params.options = objToString(options)
            } else {
                this.condition.investOpinionOptions = ['전체']
                this.condition.investOpinion = this.condition.investOpinionOptions[0]
            }

            this.getList(params)
        },
        selectOpinion() {
            var params = {}

            if (this.condition.stockCompany.code) {
                var options = {}
                options.STOCK_COMPANY0 = this.condition.stockCompany.code
                if (this.condition.investOpinion != '전체')
                    options.OPINION0 = this.condition.investOpinion

                params.options = objToString(options)
            }

            this.getList(params)
        },
        // 221115 meej 증권사, 종목 선택시 리스트 조회
        handleGetList(condition){
            this.stockSet = {}
            this.stockSet.code = condition.stockCode
            this.stockSet.name = condition.stockName
            this.condition.stockCompany = condition.STOCK_COMPANY0
            this.condition.investOpinion = '전체'
        }
    }
}
</script>

<style>
    /* common */

    /* xs */
    @media only screen and (max-width: 599px) {
    }

    /* sm */
    @media only screen and (max-width: 1023px) {
    }

    /* md */
    @media only screen and (max-width: 1439px) {
    }

    /* lg */
    @media only screen and (max-width: 1919px) {
    }

    /* xl */
    @media only screen and (min-width: 1920px) {
    }

</style>


