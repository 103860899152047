<template>
    <q-field
        dense
        :borderless="check"
        :standout="!check"
        :label="label"
        stack-label
        :disable="!check"
        @input="input_handler"
        @focus="focus_handler"
    >
        <template v-if="inputLabel" v-slot:before>
            <label class="i-label">{{ inputLabel }}</label>
        </template>
        <template v-slot:control>
            <div class="self-center full-width no-outline" tabindex="0">
                <div v-if="check">
                    <q-checkbox
                        :value="value"
                        @input="myChange"
                        size="xs"
                        :disable="disable"
                        :color="ckbColor"
                    />
                </div>
                <div v-else>
                    <span v-if="text" :style="{ color: color }">{{
                        text
                    }}</span>
                </div>
            </div>
        </template>
    </q-field>
</template>

<script>
/*
    * 데이터 표시용
        ex. <i-field label="labelName" :text="text"/>
    * checkbox용
        ex. <i-field check v-model="modelName" label="labelName" />
    * label vs inputLabel 차이
        - label: input 안에 label이 써져있음.
        - inputLabel: input밖 왼쪽에 label이 써져있음.
*/

export default {
    name: 'Field',
    props: {
        label: String,
        inputLabel: String,
        text: String,
        color: {
            type: String,
            default: '',
        },
        check: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Boolean,
            default: false,
        },
        disable: {
            type: Boolean,
            default: false,
        },
        /* 220623 meej color 추가 특징테마에서 테마히스토리 삭제시 red로 지정 */
        ckbColor: {
            type : String,
            default : "primary",
        }
    },
    data() {
        return {
            pvalue: this.value,
        }
    },
    created() {},
    methods: {
        myChange(val) {
            this.$emit('input', val)
        },
        input_handler(val) {
            this.$emit('input_handler', val)
        },
        focus_handler(val) {
            this.$emit('focus_handler', val)
        },
    },
}
</script>
