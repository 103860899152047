import api from '@/services/InfostockService'
import Debug  from '@/debug'
import { FormatDate, getByteSize } from '@/util'
const state = () => ({
    newsTypeList: [],
    newsType: '',
    list: [],
    nextKey: null,
    item: null,
    details: [],
    worldIndexItem: {}
})

const getters = {}

const mutations = {
    SET_NEWSTYPE_LIST(state, items) {
        state.newsTypeList = items
    },
    SET_LIST(state, { data }) {
        var arr = state.list
        arr.push(...data.items)

        // 인덱스 추가
        arr.forEach((row, index) => {
            row.index = index
        })

        state.list = arr
        state.nextKey = data.nextKey

        // TODO: 필요한지 확인
        state.newsType = data.newsType
    },
    setDetail(state, { data }) {
        // TODO: 데이터 가공, 화면에서 처리할 수 있으면 화면에서 처리하기
        data.items.forEach((row) => {
            row.textBytes = getByteSize(row.content)
            row.createTime = FormatDate(row.createTime)
            row.lastUpdateTime = FormatDate(row.lastUpdateTime)
        })
        //  division 으로 sorting
        data.items.sort(function (a, b) {
                if (a.DIVISION > b.DIVISION) {
                    return 1
                }
                if (a.DIVISION < b.DIVISION) {
                    return -1
                }
                return 0
            })
        state.details = data.items

        // TODO: 필요한지 확인
        state.newsType = data.newsType
    },
    /* 
        220406 meej
        마감시황(KS1)에 전일시황내용 없이 빈칸으로 출력하도록 수정, 주석처리
    */
    // SET_WORLD_INDEX(state, { data }) {
    //     // TODO: 월드인덱스
    //     var obj = {}
    //     /* 
    //         220331 meej
    //         title은 components에서 처리
    //     */
    //     // obj.title='월드인덱스'
    //     obj.content = ''

    //     for (var item of data.items) {
    //         var temp = '-' + item.title + '\n' + item.content + '\n\n'
    //         obj.content += temp
    //     }
    //     obj.textBytes = getByteSize(obj.content)
    //     state.worldIndexItem = obj
    // },
    SET_ITEM(state, item) {
        if (typeof item.THEMES == 'string') {
            item.THEMES = item.THEMES.split('|')

            var themes = []
            for (var i = 0; i < item.THEMES.length; i++) {
                var temp = item.THEMES[i]
                var arr = temp.split('-')
                var obj = {}
                obj.code = arr[0]
                obj.name = arr[1]
                obj.index = i
                themes.push(obj)
            }
            item.THEMES = themes
        }

        state.item = item
    },
    INIT_LIST(state) {
        state.list = []
        state.nextKey = null
    }
}

const actions = {
    // 시황 목록 조회
    async ASYNC_LIST({ state, commit }, params) {
        params.nextKey = state.nextKey
        params.inclSub = 0
        params.count = 100

        Debug.log('ASYNC_LIST params', params)
        const { data } = await api.marketNewsList(params)
        Debug.log('ASYNC_LIST data', data)

        commit('SET_LIST', { data })
    },
    // 시황 목록 조회
    async GET_LIST(_, params) {
        params.inclSub = 0
        params.count = 100

        Debug.log('GET_LIST params', params)
        return new Promise((resolve, reject) => {
            api.marketNewsList(params)
                .then((response) => {
                    let data = response.data
                    resolve(data)
                })
                .catch((error) =>{
                    Debug.log('GET_LIST fail')
                    reject(error)
                })
        })
        
    },
    // 시황 조회
    async GET_DETAIL({ commit }, params) {
        Debug.log('GET_DETAIL params', params)
        const { data } = await api.marketNewsDetail(params)
        Debug.log('GET_DETAIL data', data)

        commit('setDetail', { data })
    },
    // 220829 meej 주요 종목 공시 _ 인포봇으로 입력된 데이터 가져옴
    async GET_INIT_CONTENT(_, params) {
        return new Promise((resolve, reject) => {
            api.marketNewsInitContent(params)
                .then((response) => {
                    Debug.log('GET_INIT_CONTENT success')
                    resolve(response.data)
                })
                .catch((error) =>{
                    Debug.log('GET_INIT_CONTENT fail')
                    reject(error)
                })
        })
    },
    // 시황 조회
    async GET_DETAIL_ITEM(_, params) {
        return new Promise((resolve, reject) => {
            api.marketNewsDetail(params)
                .then((response) => {
                    Debug.log('GET_DETAIL success')
                    resolve(response.data)
                })
                .catch((error) =>{
                    Debug.log('GET_DETAIL fail')
                    reject(error)
                })
        })
        
    },

    /* 
        220406 meej
        마감시황(KS1)에 전일시황내용 없이 빈칸으로 출력하도록 수정, 주석처리
    */
    // 여러개 시황 조회
    // async GET_MULTI_DETAIL({ commit }, params) {
    //     Debug.log('GET_MULTI_DETAIL params', params)
    //     const { data } = await api.marketNewsMultiDetail(params)
    //     Debug.log('GET_MULTI_DETAIL data', data)

    //     commit('SET_WORLD_INDEX', { data })
    // },
    // senddate로  시황 조회
    // async GET_LIST_SENDATE({ commit }, params) {
    //     Debug.log('GET_MULTI_DETAIL params', params)
    //     const { data } = await api.marketNewsListBySendDate(params)
    //     Debug.log('GET_MULTI_DETAIL data', data)

    //     commit('SET_WORLD_INDEX', { data })
    // },

    // 시황 작성 및 수정
    async WRITE_ITEM(_, params) {
        Debug.log('WRITE_ITEM params', params)
        const { data } = await api.marketNewsWrite(params)
        Debug.log('WRITE_ITEM data', data)
    },
    // 시황 여러개 삭제
    async DELETE_ITEMS(_, params) {
        Debug.log('DELETE_ITEMS params', params)
        // TODO: 완전삭제인지 남기기인지 확인 필요

        const { data } = await api.marketNewsMultiDelete(params)
        Debug.log('DELETE_ITEMS data', data)
    },
    // 시황 최근 조회
    GET_NEWS_RECENT(_, params) {
        return new Promise((resolve, reject) => {
            api.marketNewsRecent(params)
                .then((response) => {
                    Debug.log('GET_DETAIL success')
                    resolve(response.data)
                })
                .catch((error) =>{
                    Debug.log('GET_DETAIL fail')
                    reject(error)
                })
        })
    },
    REMOVE_THEME(state, index) {
        state.item.THEMES.splice(index, 1)
    },
     
    UPLOAD_ITEM(_, params) {
        return new Promise((resolve, reject) => {
            api.pdfUpload(params)
                .then((response) => {
                    Debug.log('UPLOAD_ITEM success')
                    resolve(response.data)
                })
                .catch((error) =>{
                    Debug.log('UPLOAD_ITEM fail')
                    reject(error)
                })
        })
    },
    GET_PDF(_, params) {
        Debug.log('GET_PDF params', params)
        return new Promise((resolve, reject) => {
            api.getPdf(params)
                .then((response) => {
                   console.log(response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
     // 220831 meej 자동공시 수정
    async EDIT_AUTO_DISCL(_, params) {
        Debug.log('EDIT_AUTO_DISCL params', params)
        const { data } = await api.editAutoDiscl(params)
        Debug.log('EDIT_AUTO_DISCL data', data)
    },
    // 220923 meej marketNews 완전 삭체 처리
    DELETE_NEWS(_, params) {
        Debug.log('GET_PDF params', params)
        return new Promise((resolve, reject) => {
            api.marketNewsForceDelete(params)
                .then((response) => {
                   console.log(response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}

export const market_news = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
