import api from '@/services/InfostockService'
import Debug  from '@/debug'

const state = () => ({
    newsType: 'STOCK_SUMMARY_EXTRA',
    list: [],
    nextKey: null,
    item: {
        stockCode: '',
        stockName: '',
        content: ''
    }
})

const getters = {}

const mutations = {
    SET_LIST(state, { data }) {
        var arr = state.list
        arr.push(...data.items)

        // 인덱스 추가
        arr.forEach((row, index) => {
            row.index = index
        })

        state.list = arr
        state.nextKey = data.nextKey

    },
    SET_DETAIL(state, item) {
        state.item = item
    },
    INIT_LIST(state) {
        state.list = []
        state.nextKey = null
    },
    INIT_DETAIL(state) {
        state.item = {
            stockCode: '',
            stockName: '',
            content: ''
        }
    }
}

const actions = {
    // 목록 조회
    async GET_LIST({ state, commit }, params) {
        params.newsType = state.newsType
        params.nextKey = state.nextKey

        Debug.log('GET_LIST params', params)
        const { data } = await api.stockNewsList(params)
        Debug.log('GET_LIST data', data)

        commit('SET_LIST', { data })
    },
    // 상세 조회
    async GET_DETAIL({ state, commit }, params) {
        params.newsType = state.newsType

        Debug.log('GET_DETAIL params', params)
        const { data } = await api.stockNewsDetail(params)
        Debug.log('GET_DETAIL data', data)

        commit('SET_DETAIL', data.item)
    },
    // 작성 및 수정
    async WRITE_ITEM({ state }, params) {
        params.newsType = state.newsType

        Debug.log('WRITE_ITEM params', params)
        const { data } = await api.stockNewsWrite(params)
        Debug.log('WRITE_ITEM data', { data })
    },
    // 여러개 삭제
    async DELETE_ITEMS(_, params) {
        Debug.log('DELETE_ITEM params', params)
        const { data } = await api.stockNewsMultiDelete(params)
        Debug.log('DELETE_ITEM data', { data })
    },
}

export const stock_summary_extra = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
