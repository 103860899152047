<template>
    <q-select
        dense
        :outlined="!disable"
        :standout="disable ? 'bg-teal text-white' : false"
        :value="value"
        @input="inputfn"
        :label="label"
        :options="options"
        :disable="disable"
        :clearable="clearable"
        :option-label="optionLabel"
        :option-value="optionValue"
        :bg-color="bgColor"
    >
        <template v-if="inputLabel" v-slot:before>
            <label class="i-label">{{ inputLabel }}</label>
        </template>
        <q-tooltip
            v-if="tooltip"
            transition-show="flip-right"
            transition-hide="flip-left"
        >
            {{ tooltip }}
        </q-tooltip>
    </q-select>
</template>
<script>
/*
    - 기본 사용: <i-select v-model="modelName" :options="options" />

    1) 목록 검색 시 사용
        ex. <i-select :label="labelName" />
    2) 등록 시 사용
        ex. <i-select :inputLabel="labelName" />
    3) options 값이 { name0: a, value0: b }일 때,
        ex. <i-select :option-label="name0" :option-value="value0" />
    4) disable 속성이 필요한 경우
        ex. <i-select :disable="value" />
    5) disable 속성이 무조건 true인 경우
        ex. <i-select disable />

*/

export default {
    name: 'Select',
    props: {
        value: {
            required: true,
        },
        label: String,
        options: Array,
        disable: {
            type: Boolean,
            default: false,
        },
        optionLabel: {
            type: [String, Function],
            default: 'label',
        },
        optionValue: {
            type: String,
            default: 'value',
        },
        inputLabel: String,
        tooltip: String,
        clearable: {
            type: Boolean,
            default: false,
        },
        /* 
            220523 meej 미리보기 - 조간신문, 석간신문 선택시 색변경 추가
         */
        bgColor:{
            type: String,
        }
    },
    data() {
        return {
            pvalue: this.value,
        }
    },
    methods: {
        inputfn(val) {
            this.$emit('input', val)
        },
    },
}
</script>
