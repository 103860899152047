<template>
    <section>
        <div class="row">
            <div :class="[listWide? 'col-12':'col-6']" class="q-pa-md">
                <div class="row justify">
                    <i-select
                        :class="[isHighCommentNewsType ? 'col-8' : 'col-12']"
                        v-model="type"
                        :options="stockNewsType"
                        inputLabel="구분"
                        option-label="name"
                        option-value="value"
                        @input="selectType()"
                    />
                    <i-btn
                        class="row justify-end"
                        v-show="isHighCommentNewsType"
                        label="뉴스생성"
                        @click="confirmNews"
                        dense
                    />
                </div>

                <i-list 
                    :newsType="type" 
                    @event-list-wide = "handleListWide"
                />
            </div>
            <div v-if="isFeatured" class="col-6 q-pa-md">
                <i-featured-content class="full-width" :newsType="type" @event-update-list="getList(true)" />
            </div>
            <div v-else v-show="!listWide" class="col-6 q-pa-md">
                <i-content
                    class="full-width"
                    :newsType="type"
                    @event-update-list="handleUpdateList"
                />
            </div>
        </div>
    </section>
</template>
<script>
//import Debug from '@/debug'
import Button from '@/components/common/Button.vue'
import Select from '@/components/common/Select.vue'
import List from '@/components/stock/stock-excel-input/List.vue'
import Content from '@/components/stock/stock-excel-input/Content.vue'
import FeaturedContent from '@/components/featured/stock/Content.vue'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
    name: 'StockExcelInput',
    components: {
        'i-list': List,
        'i-content': Content,
        'i-select': Select,
        'i-btn': Button,
        'i-featured-content': FeaturedContent,
    },
    data() {
        return {
            type: null,
            listWide : false, // 220914 meej 표 전체보기
        }
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            stockNewsType: (state) => state.resource.StockNewsType,
            searchedStockName: (state) => state.stock.searchedStockName,
            keyword: (state) => state.stock_news.keyword,
        }),
        isHighCommentNewsType: function() {
            if (this.type) {
                return this.type.value == 'STOCK_HIGH_COMMENT'
            } else {
                return false
            }
        },
        isFeatured() {
            return this.type !== null && this.type.value === 'STOCK_FEATURED'
        },
    },
    created() {
        this.INIT_LIST()

        if (this.stockNewsType.length == 0) {
            this.getNewsTypeList()
        } else {
            this.type = this.stockNewsType[0]
            this.getList()
        }
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('stock_news', ['INIT_LIST', 'SET_KEYWORD']),
        ...mapMutations('stock', ['SET_SEARCHED_STOCK_NAME']),

        ...mapActions('alert', ['showError', 'showMessage']),
        ...mapActions('resource', ['GET_NEWSTYPE_LIST']),
        ...mapActions('stock_news', [
            // 230215 meej SPOT 입력 리스트 조회 API 변경
            // 'ASYNC_LIST',
            'ASYNC_SPOT_LIST',
            'ASYNC_DATE_LIST',
            'CONFIRM_NEWS',
        ]),

        getNewsTypeList() {
            var params = {}
            params.newsType = 'StockNewsType'

            this.GET_NEWSTYPE_LIST(params)
                .then(() => {
                    this.stockNewsType[this.stockNewsType.length - 1].name =
                        '특징주(장중)'
                    this.type = this.stockNewsType[0]
                    this.getList()
                })
                .catch((err) => {
                    this.showError({ vc: this, error: err })
                })
        },
        getList(init) {
            if(init) {
                this.INIT_LIST()
            }
            /* 
                220412 meej 특징주(장마감) : 삭제된 내용 표시
                220511 meej 나머지도 삭제내용 표시
                220527 meej SPOT입력 조회여부 추가
                230215 meej SPOT 입력 리스트 조회 API 변경
            */
            // this.ASYNC_LIST({
            this.ASYNC_SPOT_LIST({
                newsType: this.type.value,
                delete: true,
                isSpotList : true,
            })
           
            // 220511 meej 나머지도 삭제내용 표시, 주석처리
            // if (this.type.value == 'STOCK_FEATURED_ALL' || this.type.value == 'STOCK_FEATURED') {
            //     this.ASYNC_LIST({
            //         newsType: this.type.value,
            //         delete: true,
            //     })
            // } else {
            //     this.ASYNC_LIST({ newsType: this.type.value })
            // }
        },
        // 타입별 목록 조회
        selectType() {
            this.setPageStatus(this.PAGE_STATUS.LIST)

            this.INIT_LIST()
            this.getList()
            // 230215 meej 리스트 조회시 오류로 인해 null 설정 추가
            this.SET_SEARCHED_STOCK_NAME(null)
            this.SET_KEYWORD(null)
        },
        confirmNews() {
            this.CONFIRM_NEWS({ newsType: this.type.value })
        },
        handleUpdateList() {
            this.INIT_LIST()
            this.getList()
        },
        /* 220914 meej 표 전체보기 기능 추가 */
        handleListWide(val){
            this.listWide = val   
        }
    },
}
</script>

<style>
/* common */

/* xs */
@media only screen and (max-width: 599px) {
}

/* sm */
@media only screen and (max-width: 1023px) {
}

/* md */
@media only screen and (max-width: 1439px) {
}

/* lg */
@media only screen and (max-width: 1919px) {
}

/* xl */
@media only screen and (min-width: 1920px) {
}
</style>
