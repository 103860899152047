<template>
    <article>
        <div class="flex justify-end q-pa-xs">
            <i-btn v-show="isDetailPage" label="취소" @click="goListPage()"/>
        </div>
       <q-card flat bordered>
            <q-card-section v-if="isListPage || isRegisterPage">
                <div class="row">
                    <div class="col-12 col-lg-4 q-pa-xs">
                        <i-stock-search @get="getStock" label="종목검색"/>
                    </div>
                    <div class="col-12 col-lg-4 q-pa-xs">
                        <i-input v-model="code" inputLabel="종목코드" disable />
                    </div>
                    <div class="col-12 col-lg-4 q-pa-xs">
                        <i-input v-model="name" inputLabel="종목명" disable />
                    </div>
                </div>
                <div class="q-pa-xs">
                    <i-input v-model="cause" inputLabel="필터링 사유" />
                </div>
            </q-card-section>
            <q-card-section v-if="isDetailPage">
                <i-field v-if="item.code" v-model="item.code" label="종목코드"/>
                <i-field v-if="item.name" v-model="item.name" label="종목명"/>
                <i-field v-if="item.cause" v-model="item.cause" label="종목명"/>
            </q-card-section>
        </q-card>
        <div class="flex justify-end q-my-sm">
            <i-btn v-show="isListPage || isRegisterPage" label="등록" @click="write()"/>
            <i-btn v-show="isDetailPage" label="삭제" @click="deleteItems()"/>
        </div>
    </article>
</template>
<script>
import Button from '@/components/common/Button'
import StockSearch from '@/components/common/StockSearch'
import Field from '@/components/common/Field'
import Input from '@/components/common/Input'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Content',
    components: {
        'i-input' : Input,
        'i-btn' :  Button,
        'i-stock-search' : StockSearch,
        'i-field' : Field,
    },
    data () {
        return {
            code: '',
            name: '',
            cause: ''
        }
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
            list : state => state.flash.stockFilteringList
        }),
        ...mapState( 'flash', ['item']),

        ...mapGetters( 'page', ['isListPage', 'isDetailPage', 'isEditPage', 'isRegisterPage']),
    },
    methods: {
        ...mapMutations ({
            INIT_LIST : "flash/INIT_STOCK_FILTERING_LIST"
        }),
        ...mapMutations( 'page', ['setPageStatus']),

        ...mapMutations ({
            WRITE_ITEM : state => state.flash.WRITE_STOCK_FILTERING
        }),
        ...mapActions( 'alert', ['showError', 'showMessage']),
        ...mapActions( 'flash', ['ASYNC_LIST', 'GET_DETAIL']),

        getStock(stock) {
            this.code = stock.code
            this.name = stock.name
        },

        // 등록
        write() {
            var params = {}
            params.title= this.title
            params.content= this.content
            params.newsType = this.newsType.value

            this.WRITE_STOCK_FILTERING(params)
        },
    }
}
</script>
<style>
</style>
