<template>
    <section class="row">
        <!-- 220624 meej 표 전체보기 기능 추가 -->
        <div v-show="isListPage || isDetailPage" :class="[listWide? 'col-12':'col-6']">
            <div class="q-pa-xs q-my-xs">
            <div class="flex q-gutter-xs">
                <q-space />
                <i-select v-model="titleReq.type" :options="titleReq.options" label="선택" />
                <!-- 220622 meej enter 검색 실행 추가 -->
                <span @keyup.enter="INIT_LIST(); getList()">
                    <i-input v-model="keyword"/>
                </span>
                <i-btn icon="search" @click="INIT_LIST(); getList()" dense/>
            </div>
            <i-list
                :columns="columns" :list="list" :nextKey="nextKey" :typeOption="newsTypeOptions"
                @event-click-row="handleGetItem" @event-get-list="handleGetList"
                @event-delete="handleDelete" @event-confirm="handleConfirm" 
                :orderButton="true" :orderColumns="orderColumns"
                @event-order-featured="handleOrderList"
                @event-list-wide = "handleListWide"
                />
            </div>
        </div>

        <i-resource v-if="(isRegisterPage || isEditPage) && !isThemeStock()" 
                    class="col-12 col-md-4 q-pa-md"
                    :initMenu="resType"
                     />
        <i-stock-list
                v-if="(isRegisterPage || isEditPage) && isThemeStock()"
                class="col-12 col-md-4 q-pa-md"
                :themeCode="themeCode"
                :index="index"
                :newsDate = "newsDate"
            />
        <!-- 220624 meej 표 전체보기 기능 추가 -->
        <i-content
            v-show="!listWide"
            class="col-6 q-pa-xs q-my-xs"
            :class="[(isListPage || isDetailPage)?'col-md-6':'col-md-8']"
            @event-update-list="handleUpdateList"
            @event-request-themestock="handleThemeStock"
            :checkStocks="stocks"
            ref="detailRef"
        />

    </section>
</template>
<script>

import Debug from '@/debug'
import CONSTANTS from '@/constants'
import { FormatDate, isEmpty } from '@/util'

import List from '@/components/featured/List.vue'
import ThemeContent from '@/components/featured/theme/Content.vue'

import Resource from '@/components/common/Resource.vue'
import StockList from '@/components/theme/theme-history/StockList.vue'
import Select from '@/components/common/Select'

import Input from '@/components/common/Input'
import Button from '@/components/common/Button'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'ThemeFeatured',
    components: {
        'i-list' : List,
        'i-resource' : Resource,
        'i-content' : ThemeContent,
        'i-input' :  Input,
        'i-select' :  Select,
        'i-btn' :  Button,
        'i-stock-list': StockList,
    },
    data () {
        return {
            keyword : null,
            resType:"Theme",
            columns: [
                {
                    name: "createTime",
                    label: "일자",
                    align: "left",
                    field: (row) => FormatDate(row.createTime),
                },
                {
                    name: "type",
                    label: "오전/오후",
                    align: "left",
                    field: (row) => (row.newsType1 === 'THEME_FEATURED_AM'?'오전장':'오후장')
                },
                {
                    name: 'writer',
                    label: '작성/수정',
                    align: 'left',
                    field: (row) => {
                        if (row.crud === 'U' && row.CREATE_WRITER)
                            return row.CREATE_WRITER +'/' + row.writer 
                        else 
                            return row.writer
                    },
                },
                {
                    name: "themeName",
                    label: "테마명",
                    align: "left",
                    field: (row) => row.themeName.replace(/&/gi, ",")
                }
            ],
            newsTypeOptions: CONSTANTS.THEME_FEATURED_TIME_TYPE,
            condition: {},
            titleReq: {
                type: null,
                options:[
                    {label: '테마명', value: 'themeName'},
                    {label: '제목', value: 'title'},
                    {label: '내용', value: 'content'},
                ],
            },
            dateOptions: [
                    {label: '전체', value: 'ALL'},
                    {label: '당일', value: 'TODAY'}
                ],
            themeCode: -1,
            stocks: {},
            index: -1,
            orderColumns:[
                    {
                        name: "type",
                        label: "오전/오후",
                        align: "left",
                        field: (row) => (row.newsType1 === 'THEME_FEATURED_AM'?'오전장':'오후장')
                    },
                    {
                        name: "themeName",
                        label: "테마명",
                        align: "left",
                        field: (row) => row.themeName
                    },
                    {
                        name: "title",
                        label: "이슈",
                        align: "left",
                        field: (row) => row.title
                    }
                ],
            list:[],
            listWide : false, // 220624 meej 표 전체보기 기능 추가
            newsDate : null, // 220701 meej content의 createTime를 관련종록리스트 조회시 넘겨줌
        }
    },
    created () {
        this.setPageStatus(this.PAGE_STATUS.LIST)
        this.titleReq.type = this.titleReq.options[0]
        this.condition.newsType = this.newsTypeOptions[0]
        //당일
        this.themeCode = -1
        this.index = -1
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
            nextKey: state => state.theme_featured.nextKey,
            themeList: state => state.theme.items,
            
        }),
        ...mapGetters( 'page', ['isListPage', 'isEditPage', 'isRegisterPage', 'isDetailPage']),
    },
    methods: {
        ...mapMutations( 'page', ['setPageStatus']),
        ...mapMutations( 'theme_featured', ['SET_ITEM', 'INIT_ITEMS', 'INIT_LIST', 'SET_NEXTKEY']),
        ...mapMutations('theme_stock', ['INIT_CHECKLIST']),
        ...mapActions( 'theme', ['ASYNC_THEME_LIST']),
        ...mapActions( 'theme_featured', ['GET_FEATURED_THEME_LIST', 'DELETE_ITEMS', 'CONFIRM_FEATURED','FEAURED_ORDER']),
        ...mapActions( 'alert', ['showError', 'showMessage', 'showAlert']),

        isThemeStock() {
            return (this.themeCode > -1 && this.index > -1)
        },
        goListPage() {
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        goDetailPage() {
            this.setPageStatus(this.PAGE_STATUS.DETAIL)
        },
        getList() {
            let params = {
                newsType:this.condition.newsType,
                // 221103 meej 삭제 포함 여부 확인 추가
                // 221111 meej 전체목록에 삭제 포함으로 변경
                // type: (this.condition.all? 'ALL': this.condition.delete? 'DELETE':''),
                type: (this.condition.all? 'ALL': ''),
                date: this.condition.date,
                nextKey : this.nextKey
            }

            if(!isEmpty(this.keyword)) {
                params[this.titleReq.type.value] = this.keyword;
            }
            Debug.log('newsTypeReq params = ', params)

            this.GET_FEATURED_THEME_LIST(params)
                .then((data)=>{
                    if(params.nextKey == null){
                        this.list = data.items;
                    }else{
                        /* 220622 meej 페이징 오류 수정 */
                        let arr= this.list
                        arr.push(...data.items)
                        this.list = arr
                    }
                    //인덱스 추가
                    this.list.forEach((row, index) => {
                        row.index = index
                        row.checked = false
                    })
                    this.initOrder()
                    this.SET_NEXTKEY(data.nextKey)
                })
                .catch((err)=>{
                    this.showError({vc:this, error:err})
                })
        },
        // 순서 조정
        initOrder() {
            // 221027 meej 전체기간 조회 일 경우 순서조정 pass 
            if(this.condition.all) return
            
            Debug.log('sort ',this.condition.newsType, ', ',this.newsTypeOptions[0].value)
            const isAll = (this.condition.newsType === this.newsTypeOptions[0].value)
            /* 
                220506 meej 특징테마 순서 오류 수정. DAY_INDEX없을 경우 오전장 오후장 구분하여 각각 index로 정렬
                            DAY_INDEX있을 경우 DAY_INDEX로 정렬
             */
            this.list.sort(function (a, b) {
                Debug.log(isAll)
                if (isAll) {
                    if(a.DAY_INDEX == undefined || b.DAY_INDEX == undefined  ){
                        if(a.newsType1 === b.newsType1){
                            return a.ZINDEX - b.ZINDEX 
                        }else if(a.newsType1 < b.newsType1){
                            return 1
                        }else if(a.newsType1 > b.newsType1){
                            return -1
                        }
                        
                    }else{
                        return a.DAY_INDEX - b.DAY_INDEX
                    }
                } else {
                    return a.ZINDEX - b.ZINDEX
                }
            })
            // this.list.sort(function (a, b) {
            //     Debug.log(isAll)
            //     if (isAll) {
            //         if (a.DAY_INDEX > b.DAY_INDEX) {
            //             return 1
            //         }
            //         if (a.DAY_INDEX < b.DAY_INDEX) {
            //             return -1
            //         }
            //     } else {
            //         if (a.ZINDEX > b.ZINDEX) {
            //             return 1
            //         }
            //         if (a.ZINDEX < b.ZINDEX) {
            //             return -1
            //         }
            //     }
                
            //     return 0
            // })
        },
        handleUpdateList() {
            this.INIT_LIST()
            this.getList()
        },
        // handle List component
        handleGetList(condition) {
            Debug.log('handleGetList')
            this.condition.all = condition.all
            this.condition.date = condition.date
            this.condition.newsType = condition.newsType
            // 221103 meej 삭제 포함 여부 확인 추가
            // this.condition.delete = condition.delete
            if(condition.init) {
                this.INIT_LIST()
            }
            this.getList()
        },
        // 상세 조회
        handleGetItem(item) {
            Debug.log('handleGetItem',item)
            this.goDetailPage()
            this.SET_ITEM(item)
            /* 
                220615 meej 상세내용에 테마 chip 안보이는 오류 수정
             */
            if(this.themeList.length > 0){
                this.$refs.detailRef.getThemeNewsDetail()
            }else{
                const params = { type: 'PUBLIC,INTERNAL_PUBLIC' }
                this.ASYNC_THEME_LIST(params).then(() => {
                    this.$refs.detailRef.getThemeNewsDetail()
                })
            }
        },
        // 확인
        handleConfirm(array) {
            Debug.log('handleConfirm',array)
            let params = {}
            params.ids = array.map( x => x.id ).join(',')
            params.status = array.map( x => (x.showFlag === 1?0:1) ).join(',')
            params.force = 1
            this.CONFIRM_FEATURED(params)
            .then(() => {
                this.showMessage({ vc:this, message:'적용되었습니다.' })
                this.INIT_LIST()
                this.getList()
                this.goListPage()
            })
            .catch((error) => {
                this.showError({ vc:this, error:error })
            })
        },
        // 삭제하기
        handleDelete(array) {
            let params = {}
            params.ids = array.map( x => x.id ).join(',')
            // 특징주는 delete!!!, 특징주 아닌것들은 이슈로 넘어가므놀
            // 221103 meej 완전삭제아니라 crud상태변경으로 force = 1 -> force = 1
            params.force = 0
            this.DELETE_ITEMS(params)
            .then(() => {
                this.showMessage({ vc:this, message:'삭제되었습니다.' })
                this.INIT_LIST()
                this.getList()
                this.goListPage()
            })
            .catch((error) => {
                this.showError({ vc:this, error:error })
            })
        },
        handleThemeStock(param) {
            Debug.log('handleThemeStock param = ', param)
            this.themeCode = param.code
            this.index = param.index
            /* 220701 meej newsDate 추가  */
            this.newsDate = param.newsDate.substring(0,8)
        },
        handleOrderList(orderList) {
            let newsType = (this.condition.newsType === this.newsTypeOptions[0].value?'MARKET_THEME_NEWS':this.condition.newsType)
            const params = {
                newsType:newsType,
                items: orderList
            }
            this.FEAURED_ORDER(params)
                .then(()=> {
                    this.showMessage({vc:this, message:'완료되었습니다'})
                    this.INIT_LIST()
                    this.getList()
                })
                .catch((err)=>{
                    this.showError({vc:this, error:err})
                })
        },
        handleResTheme() {
            Debug.log('handleResTheme')
        },
        // 220624 meej 표 전체보기 기능 추가
        handleListWide(val){
            this.listWide = val   
        }
    },
    watch: {
        pageStatus(val, old) {
            if(val != old) {
                if(val == this.PAGE_STATUS.LIST) {
                    this.themeCode = -1
                    this.index = -1
                }
            }
        }
    }
}
</script>

<style>
    /* common */

    /* xs */
    @media only screen and (max-width: 599px) {
    }

    /* sm */
    @media only screen and (max-width: 1023px) {
    }

    /* md */
    @media only screen and (max-width: 1439px) {
    }

    /* lg */
    @media only screen and (max-width: 1919px) {
    }

    /* xl */
    @media only screen and (min-width: 1920px) {
    }

</style>


