<template>
    <section class="row">
        <q-toolbar class="row q-ma-md justify-center">
            <q-space />
            <div class="q-pr-xl"><i-select v-model="type" :options="typeOptions" option-value="type" option-label="typeNm" style="width:160px;"/></div>
            <div><i-select v-model="dateGb" :options="dateGbOptions" option-value="dateGb" option-label="dateGbNm" style="width:130px;"/></div>
            <div><i-datetime v-if="dateGb.dateGb != ''" date v-model="dateStr" label="입력일"/></div>
            <div v-if="dateGb.dateGb != ''" class="q-py-xs"> ~ </div>
            <div class="q-pr-xl"><i-datetime v-if="dateGb.dateGb != ''" date v-model="dateEnd" label="입력일"/></div>

            <div class="q-pr-xs"><i-select v-model="keywordGb" :options="keywordGbOptions" option-value="keywordGb" option-label="keywordGbNm" style="width:120px;"/></div>
            <div class="q-pr-lg" @keyup.enter="getList()"><i-input v-model="keyword" style="width:200px;"/></div>
            <div class="q-pr-lg"><i-btn label="검색" @click="getList()"/></div>
            <q-space />
            <!-- 220831 meej  회원 등록 -->
            <div><i-btn label="간편추가" @click="showPopup()"/></div>
        </q-toolbar>
        <i-list class="col-12" ref="memberList"/>

        <!-- 220831 meej  회원 등록  팝업 start -->
        <q-dialog v-model="isShowPopup" persistent>
          <q-card style="min-width: 370px">
            <q-card-section class="column">
              <q-card-section>
                <div class="q-pa-md"> ▶▶▶ 회원 등록 </div>
                <div class="q-pb-md">
                    <q-select v-model="newMember.userType" :options="memberTypeOpt" option-value="type" option-label="typeNm" label="회원구분"/>
                </div>
                <div>
                    <q-input
                        v-model="newMember.userName"
                        autofocus
                        @keyup.enter.stop
                        label="이름 (2자 이상)"
                        :rules="[
                            val => !!val || '이름을 입력하세요',
                            val => val.length >= 3 || '2자 이상 입력하세요'
                        ]"
                    />
                </div>
                <div>
                    <q-input
                        v-model="newMember.userPhone"
                        autofocus
                        @keyup.enter.stop
                        label="전화번호 ( - 제외)"
                        mask="###########"
                        :rules="[
                            val => !!val || '전화번호를 입력하세요',
                            val => val.length >= 9 || '전화번호가 너무 짧습니다.',
                            val => val.length <= 11 || '전화번호가 너무 깁니다',
                        ]"
                    />
                </div>
              </q-card-section>
            </q-card-section>
            <q-card-actions align="right" class="text-primary">
                <q-btn color="white" text-color="black" label="닫기" @click="closePopup()"/>
                <q-btn label="저장" @click="getSave()"/>
            </q-card-actions>
          </q-card>
        </q-dialog>
        <!-- 220831 meej  회원 등록  팝업 end -->
    </section>
</template>
<script>
import CONSTANTS from '@/constants'
import Select from '@/components/common/Select.vue';
import DateTime from '@/components/common/DateTime';
import Input from '@/components/common/Input';
import Button from '@/components/common/Button'
import List from "@/components/manage/member/List.vue"
import { mapActions } from 'vuex'

export default {
    name: 'Member',
    components: {
        'i-select' : Select,
        'i-datetime': DateTime,
        'i-input': Input,
        'i-btn': Button,
        "i-list": List
    },
    data () {
        var now = new Date();
        var dEnd = now.getFullYear() + '' + ((now.getMonth()+1)>9 ? ''+(now.getMonth()+1) : '0'+(now.getMonth()+1)) + '' + (now.getDate()>9 ? ''+now.getDate() : '0'+now.getDate());
        now.setMonth(now.getMonth()-6);
        var dStr = now.getFullYear() + '' + ((now.getMonth()+1)>9 ? ''+(now.getMonth()+1) : '0'+(now.getMonth()+1)) + '' + (now.getDate()>9 ? ''+now.getDate() : '0'+now.getDate());
        return {
            type: {'type':'', 'typeNm':'전체회원'},
            typeOptions: [{'type':'', 'typeNm':'전체회원'}].concat(CONSTANTS.MEMBER_STATUS_TYPE),
            /* 
                220516 meej 기간 선택없이 전체조회추가
            */
            dateGb: {'dateGb':'', 'dateGbNm':'전체기간'},
            dateGbOptions:[
                    {'dateGb':'', 'dateGbNm':'전체기간'},
                    {'dateGb':'createTime', 'dateGbNm':'회원가입일'},
                    {'dateGb':'loginTime', 'dateGbNm':'최근로그일'}
                ],
            dateStr: dStr,
            dateEnd: dEnd,
            /* 
                220517 회원관리 통합검색(id, name, phoneNo) 추가
             */
            keywordGb: {'keywordGb':'', 'keywordGbNm':'통합검색'},
            keywordGbOptions:[
                    {'keywordGb':'', 'keywordGbNm':'통합검색'},
                    {'keywordGb':'id', 'keywordGbNm':'ID'},
                    {'keywordGb':'name', 'keywordGbNm':'닉네임'},
                    {'keywordGb':'phoneNo', 'keywordGbNm':'전화번호'}
                ],
            keyword: '',
            isShowPopup : false, 
            newMember : {
                userName : "",
                userPhone : "",
                userType : {'type':'SHINHAN', 'typeNm':'신한투자증권'},
            },
            memberTypeOpt : [
                {'type':'SHINHAN', 'typeNm':'신한투자증권'},
                {'type':'MARKETING', 'typeNm':'마케팅'}
            ],
        }
    },
    methods: {
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions('manage', ['GET_CALL']),

        getList() {
            this.$refs.memberList.getList();
        },
        // 220831 meej  회원 등록 팝업
        showPopup(){
            this.isShowPopup = this.isShowPopup ? false:true;
        },
        // 220831 meej  회원 등록 
        getSave(){
            if(this.newMember.userName.length < 2 ){
                return
            }
            if(this.newMember.userPhone.length < 9 || this.newMember.userPhone.length > 11){
                return
            }
            this.showAlert({ vc:this, message:'등록 하시겠습니까?'})
            .then(()=>{
                let param = {
                    'userName' : this.newMember.userName,
                    'userPhone' : this.newMember.userPhone,
                    'userType' : this.newMember.userType.type,
                    'CALL_API_URL': '/member/register'
                }

                this.GET_CALL(param).then(() => { 
                    this.showMessage({ vc:this, message:'등록 되었습니다.' });
                    this.initMember()
                    this.isShowPopup = false
                    this.getList()
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            })
        },
        // 220831 meej  회원 초기화
        initMember(){
            this.newMember.userName = ""
            this.newMember.userPhone = ""
        },
        closePopup(){
            this.initMember()
            this.isShowPopup = false
        }
    }
}
</script>
