<template>
    <article>
        <!-- 구분 -->
        <div class="row i-top-wrap">
            <div class=" row col-12 ">
                <!-- 220711 meej 삭제시에도 현재날짜로 설정되어서 i-datetime -> q-input 변경 -->
                <!-- <div > -->
                <!-- <i-datetime
                    class="col-sm-4 sn-date"
                    date
                    v-model="showTime"
                    label="입력일"
                    @input="
                        INIT_LIST()
                        getDateList()
                    "
                /> -->
                <q-input
                    class="col-sm-4 sn-date q-pa-xs"
                    outlined
                    type="date"
                    dense
                    v-model="showTime"
                    @input="INIT_LIST()
                        searchList()"
                >
                    <template v-slot:before>
                        <label v-if="dateLabel" class="i-label">
                            {{ dateLabel }}
                        </label>
                    </template>
                </q-input>

                <div class="col-12 col-sm-4 q-pa-xs">
                    <i-stock-search @get="getStock" />
                </div>
                <div class="col-12 col-sm-4 q-pa-xs">
                    <div class="flex justify-end q-gutter-xs">
                        <i-btn
                            label="전체검색"
                            @click="
                                INIT_LIST()
                                initShowTime()
                                getList()
                            "
                        />
                        <i-btn label="삭제" @click="deleteItems()" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 목록 -->
        <!-- {{ list }} -->
        <i-table
            :columns="columns"
            :list="list"
            :nextKey="nextKey"
            @row-click="getItem"
            @next-click="searchList()"
            @check-event="handleCheckList"
        />
    </article>
</template>
<script>
import Debug from '@/debug'
import { FormatDate, CutDownText } from '@/util'

import Button from '@/components/common/Button'
import StockSearch from '@/components/common/StockSearch'
import Table from '@/components/common/Table'
// import DateTime from '@/components/common/DateTime'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'StockNewsList',
    components: {
        'i-btn': Button,
        'i-stock-search': StockSearch,
        'i-table': Table,
        // 'i-datetime': DateTime,
    },
    props: {
        newsType: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            showTime: null,
            stock: '',
            selected: [],
            // 20210830 shb | 생성 되었을때 datetime 컴포넌트에 null 값을 넘겨주면 당일로 데이터를 넘겨줌.
            // input emit 이벤트 발생으로 생성시 날짜별, 전체 로 조회 두번 일어남 최초 생성시 날짜검색 막기위한 변수
            createSearch: false,
            columns: [
                {
                    /* 220128 meej
                        creatTime -> showDate로 변경
                     */
                    // name: 'createTime',
                    name: 'showDate',
                    label: '입력일자',
                    align: 'left',
                    // field: (row) => FormatDate(row.createTime),
                    field: (row) => FormatDate(row.showDate),
                },
                {
                    name: 'stockCode',
                    label: '종목',
                    align: 'left',
                    field: (row) => `${row.stockName} ( ${row.stockCode} )`,
                },
                //20210726 shb 추가
                {
                    name: 'writer',
                    label: '작성자',
                    align: 'left',
                    field: (row) => row.writer,
                },
                //==============
                {
                    name: 'content',
                    label: '내용',
                    align: 'left',
                    field: (row) => CutDownText(row.content, 30),
                },
            ],
        }
    },
    created() {
        this.INIT_LIST()
        this.getList()
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
        }),
        ...mapState('stock_news', ['list', 'nextKey']),
        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isEditPage',
            'isRegisterPage',
            'isFlashPage',
        ]),
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('stock_news', [
            'INIT_LIST',
            'SET_DETAIL',
            'INIT_DETAIL',
        ]),

        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions('flash', ['PREVIEW_ITEM']),
        ...mapActions('stock_news', [
            'ASYNC_LIST',
            'ASYNC_DATE_LIST',
            'DELETE_ITEMS',
        ]),

        // TODO: 속보
        goFlashPage() {
            this.setPageStatus(this.PAGE_STATUS.FLASH)
        },
        handleCheckList(list) {
            this.selected = list
        },
        // 종목 가져오기 + 목록 조회
        getStock(stock) {
            this.INIT_LIST()

            var params = {
                code: stock.code,
            }

            this.getDateList(params)
        },
        // 날짜별 목록 조회하기
        getDateList(params) {
            if (this.createSearch) {
                if (params == null) {
                    params = {}
                }
                // 220708 meej 날짜검색 오류 수정, replaceAll 추가
                params.date = this.showTime.replaceAll("-","")
                params.newsType = this.newsType.value

                this.ASYNC_DATE_LIST(params)
            } else {
                this.createSearch = true
            }
        },
        // 220708 meej 페이징 오류 수정 및 목록 조회 오류 수정
        //              showTime에 따라 목록 조회 선택
        searchList(){
            if(this.showTime != null && this.showTime != ''){
                this.getDateList()
            }else{
                this.getList()
            }
        },
        // 목록 조회하기
        getList() {
            var params = {}
            params.newsType = this.newsType.value
            this.ASYNC_LIST(params)
        },
        // 220711 날짜 검색 초기화
        initShowTime(){
            this.showTime = ''
        },
        // 상세 조회하기
        getItem(item) {
            Debug.log('getItem item = ', item)
            this.SET_DETAIL(item)
        },
        // 삭제 알림
        deleteItems() {
            if (this.selected.length == 0) {
                this.showMessage({
                    vc: this,
                    message: '삭제할 항목을 선택해주세요.',
                })
            } else {
                this.showAlert({
                    vc: this,
                    message: '선택한 항목을 삭제하시겠습니까?',
                }).then(() => {
                    this.doDelete()
                })
            }
        },
        // 삭제하기
        doDelete() {
            var params = {}
            params.ids = this.selected.map((x) => x.id).join(',')
            //공시, 이슈, 상한가, 하한가, 급등락, 52주, 시간외 단일가는 Update
            params.force = 0
            this.DELETE_ITEMS(params)
                .then(() => {
                    this.showMessage({ vc: this, message: '삭제되었습니다.' })
                    this.INIT_LIST()
                    this.INIT_DETAIL()
                    // 220711 meej getDateList -> searchList 수정
                    // this.getDateList()
                    this.searchList()
                })
                .catch((err) => {
                    var error = err.message
                    this.showError({ vc: this, error: error })
                    Debug.log('error = ', error)
                })
        },
    },
    watch: {
        // newsType (val, old) {
        //     if(val != old) {
        //         this.INIT_LIST()
        //         this.getDateList()
        //     }
        // }
    },
}
</script>
