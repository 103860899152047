<template>
    <article>
        <q-scroll-area visible class="q-my-md" style="height: 85vh;">
            <div class="row">
                <!-- 코스피 -->
                <q-card flat bordered name="kospi" :class="[isFlashPage ? 'col-12' : 'col-6']">
                    <q-card-section>
                        <div class="row q-my-sm">
                            <div class="col-10">
                                <q-input v-model="kospi.title" filled dense />
                            </div>
                            <div class="col-2">
                                <q-radio v-model="checkItem" :val="kospi" @input="validate" />
                            </div>
                        </div>
                        <div class="q-my-sm">
                            <q-input v-model="kospi.content" type="textarea" :rows="20" filled dense />
                        </div>
                        <div class="row q-my-sm">
                            <div class="col-12 col-md-4">
                                <i-stock-search @get="(stock) => { kospi.stocks.push(stock) }" label="종목코드" />
                            </div>
                            <div class="col-12 col-md-8 flex justify-start">
                                <q-chip v-for="(item,index) in kospi.stocks" :key="'stock'+index"
                                   outline square color="black" text-color="white" size="sm" removable
                                    @remove="kospi.stocks.splice(index,1)">
                                    {{ item.name }} ({{ item.code }})
                                </q-chip>
                            </div>
                        </div>
                    </q-card-section>
                </q-card>
                <!-- 코스닥 -->
                <q-card flat bordered name="kosdaq" :class="[isFlashPage ? 'col-12' : 'col-6']">
                    <q-card-section>
                        <div class="row q-my-sm">
                            <div class="col-10">
                                <q-input v-model="kosdaq.title" filled dense />
                            </div>
                            <div class="col-2">
                                <q-radio v-model="checkItem" :val="kosdaq" @input="validate" />
                            </div>
                        </div>
                        <div class="q-my-sm">
                            <q-input v-model="kosdaq.content" type="textarea" :rows="20" filled dense />
                        </div>
                        <div class="row q-my-sm">
                            <div class="col-12 col-md-4">
                                <i-stock-search @get="(stock) => { kosdaq.stocks.push(stock) }" label="종목코드" />
                            </div>
                            <div class="col-12 col-md-8 flex justify-start">
                                <q-chip v-for="(item,index) in kosdaq.stocks" :key="'stock'+index"
                                    outline square color="black" text-color="white" size="sm" removable
                                    @remove="kosdaq.stocks.splice(index,1)">
                                    {{ item.name }} ({{ item.code }})
                                </q-chip>
                            </div>
                        </div>
                        </q-card-section>
                    </q-card>
                </div>
                <div class="row">
                    <!-- 특징테마 -->
                    <q-card flat bordered name="featuredTheme" :class="[isFlashPage ? 'col-12' : 'col-6']">
                        <q-card-section>
                            <div class="row q-my-sm">
                                <div class="col-10">
                                    <q-input v-model="featuredTheme.title" filled dense />
                                </div>
                                <div class="col-2">
                                    <q-radio v-model="checkItem" :val="featuredTheme" @input="validate" />
                                </div>
                            </div>
                            <div class="row q-my-sm co-12">
                                <i-input class="full-width" v-model="featuredTheme.content" :verticalScroll="true" type="textarea" inputLabel='테마시황' dense />
                            </div>
                            <div class="row q-my-sm ">
                                <i-table class="full-width" :columns="featuredThemecolumns" :list="featuredTheme.items" :haveSubItem="true"
                                     @check-event="handleFeaturedTheme" :heightStyle="{height: '330px'}" :rows=100 :showPagenation="false"/>
                            </div>
                            <div class="row q-my-sm">
                                <div class="col-12 col-md-4">
                                    <i-stock-search @get="(stock) => { featuredTheme.stocks.push(stock) }" label="종목코드" />
                                </div>
                                <div class="col-12 col-md-8 flex justify-start">
                                    <q-chip v-for="(item,index) in featuredTheme.stocks" :key="'stock'+index"
                                        outline square color="black" text-color="white" size="sm" removable
                                        @remove="featuredTheme.stocks.splice(index,1)">
                                        {{ item.name }} ({{ item.code }})
                                    </q-chip>
                                </div>
                            </div>
                        </q-card-section>
                    </q-card>
                    <!-- 특징주 (코스피) -->
                    <q-card flat bordered name="featuredStockKospi" :class="[isFlashPage ? 'col-12' : 'col-6']">
                        <q-card-section>
                            <div class="row q-my-sm">
                                <div class="col-10">
                                    <q-input v-model="featuredStockKospi.title" filled dense />
                                </div>
                                <div class="col-2">
                                    <q-radio v-model="checkItem" :val="featuredStockKospi" @input="validate" />
                                </div>
                            </div>
                            <div class="q-my-sm">
                                <i-table :columns="featuredStockcolumns" :list="featuredStockKospi.items" :haveSubItem="true"
                                     @check-event="handlefeaturedStockKospi" :heightStyle="{height: '400px'}" :rows=100 :showPagenation="false"  />
                            </div>
                            <div class="row q-my-sm">
                            <div class="col-12 col-md-4">
                                <i-stock-search @get="(stock) => { featuredStockKospi.stocks.push(stock) }" label="종목코드" />
                            </div>
                            <div class="col-12 col-md-8 flex justify-start">
                                <q-chip v-for="(item,index) in featuredStockKospi.stocks" :key="'stock'+index"
                                    outline square color="black" text-color="white" size="sm" removable
                                    @remove="featuredStockKospi.stocks.splice(index,1)">
                                    {{ item.name }} ({{ item.code }})
                                </q-chip>
                            </div>
                            </div>
                        </q-card-section>
                    </q-card>
                </div>
                <div class="row" >
                    <!-- 특징주 (코스닥) -->
                    <q-card flat bordered name="featuredStockKosdaq" :class="[isFlashPage ? 'col-12' : 'col-6']">
                        <q-card-section>
                            <div class="row q-my-sm">
                                <div class="col-10">
                                    <q-input v-model="featuredStockKosdaq.title" filled dense />
                                </div>
                                <div class="col-2">
                                    <q-radio v-model="checkItem" :val="featuredStockKosdaq" @input="validate" />
                                </div>
                            </div>
                            <div class="q-my-sm">
                                <i-table :columns="featuredStockcolumns" :list="featuredStockKosdaq.items" :haveSubItem="true"
                                     @check-event="handlefeaturedStockKosdaq" :heightStyle="{height: '400px'}" :rows=100 :showPagenation="false"  />
                            </div>
                            <div class="row q-my-sm">
                            <div class="col-12 col-md-4">
                                <i-stock-search @get="(stock) => { featuredStockKosdaq.stocks.push(stock) }" label="종목코드" />
                            </div>
                            <div class="col-12 col-md-8 flex justify-start">
                                <q-chip v-for="(item,index) in featuredStockKosdaq.stocks" :key="'stock'+index"
                                    outline square color="black" text-color="white" size="sm" removable
                                    @remove="featuredStockKosdaq.stocks.splice(index,1)">
                                    {{ item.name }} ({{ item.code }})
                                </q-chip>
                            </div>
                            </div>
                        </q-card-section>
                    </q-card>
                    <!-- 상한가 및 급등종목 -->
                     <q-card flat bordered name="stockJump" :class="[isFlashPage ? 'col-12' : 'col-6']">
                        <q-card-section>
                            <div class="row q-my-sm">
                                <div class="col-10">
                                    <q-input v-model="stockJump.title" filled dense />
                                </div>
                                <div class="col-2">
                                    <q-radio v-model="checkItem" :val="stockJump" @input="validate" />
                                </div>
                            </div>
                            <div class="q-my-sm">
                                <i-table :columns="stockJumpcolumns" :list="stockJump.items" :haveSubItem="true"
                                     @check-event="handleStockJump" :heightStyle="{height: '400px'}" :rows=100 :showPagenation="false" />
                            </div>
                            <div class="row q-my-sm">
                            <div class="col-12 col-md-4">
                                <i-stock-search @get="(stock) => { stockJump.stocks.push(stock) }" label="종목코드" />
                            </div>
                            <div class="col-12 col-md-8 flex justify-start">
                                <q-chip v-for="(item,index) in stockJump.stocks" :key="'stock'+index"
                                    outline square color="black" text-color="white" size="sm" removable
                                    @remove="stockJump.stocks.splice(index,1)">
                                    {{ item.name }} ({{ item.code }})
                                </q-chip>
                            </div>
                            </div>
                        </q-card-section>
                    </q-card>
                </div>
                <div class="row">
                    <!-- 기술적 분석 특징주 A (코스피) -->
                    <q-card flat bordered name="techAkospi" :class="[isFlashPage ? 'col-12' : 'col-6']">
                        <q-card-section>
                            <div class="row q-my-sm">
                                <div class="col-10">
                                    <q-input v-model="techAkospi.title" filled dense />
                                </div>
                                <div class="col-2">
                                    <q-radio v-model="checkItem" :val="techAkospi" @input="validate" />
                                </div>
                            </div>
                            <div class="q-my-sm">
                                <q-input v-model="techAkospi.content" type="textarea" :rows="20" filled dense />
                            </div>
                            <div class="row q-my-sm">
                            <div class="col-12 col-md-4">
                                <i-stock-search @get="(stock) => { techAkospi.stocks.push(stock) }" label="종목코드" />
                            </div>
                            <div class="col-12 col-md-8 flex justify-start">
                                <q-chip v-for="(item,index) in techAkospi.stocks" :key="'stock'+index"
                                    outline square color="black" text-color="white" size="sm" removable
                                    @remove="techAkospi.stocks.splice(index,1)">
                                    {{ item.name }} ({{ item.code }})
                                </q-chip>
                            </div>
                            </div>
                        </q-card-section>
                    </q-card>
                    <!-- 기술적 분석 특징주 B (코스피) -->
                    <q-card flat bordered name="techBkospi" :class="[isFlashPage ? 'col-12' : 'col-6']">
                        <q-card-section>
                            <div class="row q-my-sm">
                                <div class="col-10">
                                    <q-input v-model="techBkospi.title" filled dense />
                                </div>
                                <div class="col-2">
                                    <q-radio v-model="checkItem" :val="techBkospi" @input="validate" />
                                </div>
                            </div>
                            <div class="q-my-sm">
                                <q-input v-model="techBkospi.content" type="textarea" :rows="20" filled dense />
                            </div>
                            <div class="row q-my-sm">
                            <div class="col-12 col-md-4">
                                <i-stock-search @get="(stock) => { techBkospi.stocks.push(stock) }" label="종목코드" />
                            </div>
                            <div class="col-12 col-md-8 flex justify-start">
                                <q-chip v-for="(item,index) in techBkospi.stocks" :key="'stock'+index"
                                    outline square color="black" text-color="white" size="sm" removable
                                    @remove="techBkospi.stocks.splice(index,1)">
                                    {{ item.name }} ({{ item.code }})
                                </q-chip>
                            </div>
                            </div>
                        </q-card-section>
                    </q-card>
                </div>
                <div class="row">
                    <!-- 기술적 분석 특징주 A (코스닥) -->
                    <q-card flat bordered name="techAkosdaq" :class="[isFlashPage ? 'col-12' : 'col-6']">
                        <q-card-section>
                            <div class="row q-my-sm">
                                <div class="col-10">
                                    <q-input v-model="techAkosdaq.title" filled dense />
                                </div>
                                <div class="col-2">
                                    <q-radio v-model="checkItem" :val="techAkosdaq" @input="validate" />
                                </div>
                            </div>
                            <div class="q-my-sm">
                                <q-input v-model="techAkosdaq.content" type="textarea" :rows="20" filled dense />
                            </div>
                            <div class="row q-my-sm">
                            <div class="col-12 col-md-4">
                                <i-stock-search @get="(stock) => { techAkosdaq.stocks.push(stock) }" label="종목코드" />
                            </div>
                            <div class="col-12 col-md-8 flex justify-start">
                                <q-chip v-for="(item,index) in techAkosdaq.stocks" :key="'stock'+index"
                                    outline square color="black" text-color="white" size="sm" removable
                                    @remove="techAkosdaq.stocks.splice(index,1)">
                                    {{ item.name }} ({{ item.code }})
                                </q-chip>
                            </div>
                            </div>
                        </q-card-section>
                    </q-card>
                    <!-- 기술적 분석 특징주 B (코스닥) -->
                    <q-card flat bordered name="techBkosdaq" :class="[isFlashPage ? 'col-12' : 'col-6']">
                        <q-card-section>
                            <div class="row q-my-sm">
                                <div class="col-10">
                                    <q-input v-model="techBkosdaq.title" filled dense />
                                </div>
                                <div class="col-2">
                                    <q-radio v-model="checkItem" :val="techBkosdaq" @input="validate" />
                                </div>
                            </div>
                            <div class="q-my-sm">
                                <q-input v-model="techBkosdaq.content" type="textarea" :rows="20" filled dense />
                            </div>
                            <div class="row q-my-sm">
                            <div class="col-12 col-md-4">
                                <i-stock-search @get="(stock) => { techBkosdaq.stocks.push(stock) }" label="종목코드" />
                            </div>
                            <div class="col-12 col-md-8 flex justify-start">
                                <q-chip v-for="(item,index) in techBkosdaq.stocks" :key="'stock'+index"
                                    outline square color="black" text-color="white" size="sm" removable
                                    @remove="techBkosdaq.stocks.splice(index,1)">
                                    {{ item.name }} ({{ item.code }})
                                </q-chip>
                            </div>
                            </div>
                        </q-card-section>
                    </q-card>
                </div>
                <!-- 220503 meej 특징테마/종목/상한가 텍스트 모아보기 추가 -->
                <!-- 220725 meej 특징테마/종목/상한가 시황 등록 버튼 -->
                <div class="row">
                    <q-card flat bordered name="textAll" class="col-12">
                        <q-card-section>
                        <div class="row q-my-sm">
                            <div class="col-7">
                                <q-input v-model="textAll.title" filled dense readonly/>
                            </div>
                            <div class="col-3">
                                <i-datetime
                                    date
                                    dateLabel="전송 날짜"
                                    v-model="sendDate"
                                />
                            </div>
                            <div class="col-2 q-pl-sm flex">
                                <q-btn class="full-width" dense color="dark" label="시황 등록하기" @click="insertMarket" />
                            </div>
                        </div>
                            <div class="q-my-sm">
                                <q-input v-model="textAll.content" type="textarea" :rows="20" filled dense readonly/>
                            </div>
                        </q-card-section>
                    </q-card>
                </div>
        </q-scroll-area>
    </article>
</template>
<script>
import debug from '@/debug'
import {CutDownText, isEmpty, nowTime, addWorkDay, today} from '@/util'

import StockSearch from '@/components/common/StockSearch'
import Table from '@/components/common/Table'
import Input from '@/components/common/Input'
import DateTime from '@/components/common/DateTime'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'FirstContent',
    components: {
        'i-stock-search': StockSearch,
        'i-table' : Table,
        'i-input': Input,
        'i-datetime': DateTime,
    },
    data () {
        return {
            kospi:{ title: '코스피 마감시황', check: false, content: '', stocks: [], flashNewsType:{name:'증시요약(1) - 코스피 마감시황', value:'MARKET_FLASH_KOSPI_SUMMARY'} },
            kosdaq: { title: '코스닥 마감시황', check: false, content: '', stocks: [], flashNewsType:{name:'증시요약(2) - 코스닥 마감시황', value:'MARKET_FLASH_KOSDAQ_SUMMARY'}},

            featuredTheme : { title: '특징테마', check: false, content: '', items: [], selectedItems:[], stocks: [], flashNewsType:{name:'증시요약(3) - 특징 테마', value:'MARKET_FLASH_THEME_FEATURED'} },
            featuredStockKospi: { title: '특징종목 (코스피)', check: false, items: [], selectedItems:[], stocks: [], flashNewsType:{name:'증시요약(4) - 특징 종목(코스피)', value:'MARKET_FLASH_STOCK_FEATURED_KOSPI'} },
            featuredStockKosdaq: { title: '특징종목 (코스닥)', check: false, items: [], selectedItems:[], stocks: [], flashNewsType:{name:'증시요약(5) - 특징 종목(코스닥)', value:'MARKET_FLASH_STOCK_FEATURED_KOSDAQ'} },
            stockJump: { title: '특징상한가 및 급등종목', check: false, items: [], selectedItems:[], stocks: [], flashNewsType:{name:'증시요약(6) - 특징 상한가 및 급등종목', value:'MARKET_FLASH_STOCK_JUMP'} },
            techAkospi: { title: '기술적분석 특징주 A (코스피)', check: false, content: '', stocks: [], flashNewsType:{name:'증시요약(7) - 기술적 분석 특징주 A(코스피)', value:'MARKET_FLASH_TECH_ANALYSIS_A_KOSPI'} },
            techAkosdaq: { title: '기술적분석 특징주 A (코스닥)', check: false, content: '', stocks: [] , flashNewsType:{name:'증시요약(9) - 기술적 분석 특징주 A(코스닥)', value:'MARKET_FLASH_TECH_ANALYSIS_A_KOSDAQ'}},
            techBkospi: { title: '기술적분석 특징주 B (코스피)', check: false, content: '', stocks: [] , flashNewsType:{name:'증시요약(8) - 기술적 분석 특징주 B(코스피)', value:'MARKET_FLASH_TECH_ANALYSIS_B_KOSPI'}},
            techBkosdaq: { title: '기술적분석 특징주 B (코스닥)', check: false, content: '', stocks: [], flashNewsType:{name:'증시요약(10) - 기술적 분석 특징주 B(코스닥)', value:'MARKET_FLASH_TECH_ANALYSIS_B_KOSDAQ'} },
            checkItem: null,
            featuredThemecolumns: [
                { name: 'title', label: '테마', align: 'left', field: row => row.themeName },
                { name: 'summary', label: '요약', align: 'left', field: row => row.title },
                { name: 'content', label: '내용', align: 'left', field: row => CutDownText(row.content, 15) },
            ],
            stockJumpcolumns: [
                { name: 'stock', label: '종목', align: 'left', field: row =>  `${row.stockName} ( ${row.stockCode} )`},
                { name: 'DAYS', label: '상한가 일수', align: 'left', field: row => row.DAYS },
                { name: 'content', label: '사유', align: 'left', field: row => row.content },
            ],
            featuredStockcolumns: [
                { name: 'stock', label: '종목', align: 'left', field: row =>  (isEmpty(row.GROUP_NAME)?`${row.stockName} ( ${row.stockCode} )`:row.GROUP_NAME)  },
                { name: 'title', label: '요약', align: 'left', field: row => row.title },
                { name: 'content', label: '내용', align: 'left', field: row => CutDownText(row.content, 30) },
            ],
            textAll:{
                title: '특징테마/종목/상한가',
                content: "",
                themeFeatured: "",
                stockFeatured: "",
                stockJump:"",
            },
            sendDate: '',
        }
    },
    // beforeCreate() {
    //     this.$parent.$off('initCheckItem')
    // },
    created () {
        this.setPageStatus(this.PAGE_STATUS.EDIT)
        this.initSummary()
        this.INIT_PREVIEW()
        this.$parent.$on('initCheckItem', this.initCheckItem)
        this.sendDateChanger()
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
            newsType : state => state.resource.newsType,
            stockItems: (state) => state.resource.stockItems, 
        }),
        ...mapGetters ({
            isEditPage : 'page/isEditPage',
            isFlashPage : 'page/isFlashPage',
        }),
    },
    methods: {
        ...mapMutations( 'page', ['setPageStatus']),
        ...mapMutations( 'flash', ['SET_PREVIEW','INIT_PREVIEW']),

        ...mapActions( 'alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions( 'flash', ['GET_SUMMARY_ITEMS', 'PREVIEW_SUMMARY']),
        ...mapActions('market_news', ['WRITE_ITEM']),
        
        // 220725 meej 전송일자 : 당일 14시 이후 입력 다음날로 변경
        sendDateChanger() {
            if (Number(nowTime('HH')) > 13) {
                this.sendDate = addWorkDay(1)
            } else {
                this.sendDate = today()
            }
        },
        initCheckItem(val) {
            this.checkItem = val
            this.setPageStatus(this.PAGE_STATUS.EDIT)
        },
        validate(item) {
            var newsType = item.flashNewsType.value
            // 제목 체크
            if (!item.title) {
                alert('제목을 입력하세요.')
                this.checkItem = null
                return
            }

            // 내용 체크
            if (newsType == 'MARKET_FLASH_KOSPI_SUMMARY'
                || newsType == 'MARKET_FLASH_KOSDAQ_SUMMARY'
                || newsType == 'MARKET_FLASH_THEME_FEATURED') {
                    if (!item.content) {
                        alert('내용을 입력하세요.')
                        this.checkItem = null
                        return
                    }
            }

            // 테마 체크
            if (newsType == 'MARKET_FLASH_THEME_FEATURED') {
                if (item.items.length == 0) {
                    alert('테마가 없습니다.')
                    this.checkItem = null
                    return
                }
            }

            // 종목 체크
            if (newsType == 'MARKET_FLASH_STOCK_FEATURED_KOSPI'
                || newsType == 'MARKET_FLASH_STOCK_FEATURED_KOSDAQ'
                || newsType == 'MARKET_FLASH_STOCK_JUMP' ) {
                if (item.items.length == 0) {
                    alert('종목이 없습니다.')
                    this.checkItem = null
                    return
                }
            }

            this.checkFlash(item)
        },
        checkFlash(item) {
            this.setPageStatus(this.PAGE_STATUS.FLASH)
            debug.log(item)

            // 파라미터 생성
            var params = {}
            params.newsType = item.flashNewsType.value
            params.title = item.title

            // 기술적 분석 뉴스타입인 경우 바로 API 호출한다.
            // 기술적 분석 뉴스타입이 아닌 경우

            if (params.newsType == 'MARKET_FLASH_KOSPI_SUMMARY'
                || params.newsType == 'MARKET_FLASH_KOSDAQ_SUMMARY'
                || params.newsType == 'MARKET_FLASH_THEME_FEATURED') {
                params.content = item.content
            }

            if (params.newsType == 'MARKET_FLASH_THEME_FEATURED') {
                params.featuredThemes = item.items
            }

            if (params.newsType == 'MARKET_FLASH_STOCK_FEATURED_KOSPI'
                || params.newsType == 'MARKET_FLASH_STOCK_FEATURED_KOSDAQ'
                || params.newsType == 'MARKET_FLASH_STOCK_JUMP' ) {
                params.featuredStocks = item.items
            }

            debug.log(params)
            this.PREVIEW_SUMMARY(params)
            .then((data) => {
                if (item.stocks.length > 0) {
                    data.stocks = item.stocks
                }
                else{
                    if (params.newsType == 'MARKET_FLASH_STOCK_FEATURED_KOSPI'
                    || params.newsType == 'MARKET_FLASH_STOCK_FEATURED_KOSDAQ'
                    || params.newsType == 'MARKET_FLASH_STOCK_JUMP' ) {
                        let stocks = []
                        item.items.some((row, index) => {
                            if(index > 4) {return false;}
                            else{
                                stocks[index] = {'code':row.stockCode,'name':row.stockName}
                            }
                        })
                        data.stocks = stocks 

                    // 22.09.19 suye 기술적분석 관련종목 추가                             
                    } 
                    else if (params.newsType == 'MARKET_FLASH_TECH_ANALYSIS_A_KOSPI' 
                    || params.newsType == 'MARKET_FLASH_TECH_ANALYSIS_A_KOSDAQ' 
                    || params.newsType == 'MARKET_FLASH_TECH_ANALYSIS_B_KOSPI' 
                    || params.newsType == 'MARKET_FLASH_TECH_ANALYSIS_B_KOSDAQ'){ 

                        // 22.10.05 suye 기술적분석 관련종목 수정  
                        if(data && data.sendData && data.sendData.stock_codes) {
                            let stocks = []
                            const relatedStocks = []
                            let codeList = data.sendData.stock_codes.split(';')
                            
                            // console.log('code = ',codeList)
                            if(codeList) {
                                for(let index in codeList) {
                                    if(this.stockItems){
                                        let stock = this.stockItems.find(
                                        (element) => element.code == codeList[index]
                                    )
                                    stocks.push(stock)
                                    }    
                                }
                                stocks.forEach(e => {
                                    if(e && e.code && e.name) relatedStocks.push({'code':e.code,'name':e.name}) 
                                });
                                // console.log('relatedStocks = ',relatedStocks)
                                data.stocks = relatedStocks
                            }  
                        }
                    }
                }
                this.SET_PREVIEW(data)
                // this.$parent.$emit('goFlashPage', data)
            })
            .catch((err) => {
                this.showError({vc: this, error:err})
                this.setPageStatus(this.PAGE_STATUS.EDIT)
            })
        },
        initSummary() {
            this.GET_SUMMARY_ITEMS()
                .then((data) => {
                    //전문과 맞추기
                    this.handleData(data)
                })
                .catch((err) => {
                    this.showError({vc: this, error:err})
                })
        },
        handleFeaturedTheme(items) {
            this.featuredTheme.selectedItems = items
        },
        handleStockJump(items) {
            this.stockJump.selectedItems = items
        },
        handlefeaturedStockKospi(items) {
            this.featuredStockKospi.selectedItems = items
        },
        handlefeaturedStockKosdaq(items) {
            this.featuredStockKosdaq.selectedItems = items
        },

        // 22.09.16 suye 기술적분석 상단 관련종목 추가 
        // techRelatedStocks(tech){ 

        //     let content = tech.content
        //     this.stockList = []
        //     // 종목코드에 종목명 합치기
        //     if(tech && tech.stocks) {
        //         let codeList = tech.stocks.split(';')
                
        //         console.log('code = ',codeList)
        //         if(codeList) {
        //             for(let index in codeList) {
        //                 if(this.stockItems){
        //                     let stock = this.stockItems.find(
        //                     (element) => element.code == codeList[index]
        //                 )
        //                 this.stockList.push(stock)
        //                 }
                        
        //             }
        //             console.log('stockList = ',this.stockList)
        //         }  
        //     }
        //     // console.warn('content=', content)
        //     return content
        // },
        handleData(data) {
            debug.log('handleData data = ',data)
            this.kospi.content = (data.kospi === null? '':data.kospi.content)
            this.kosdaq.content = (data.kosdaq === null? '':data.kosdaq.content)
            this.featuredTheme.content = (data.themeNews === null? '':data.themeNews.content)

            var featuredThemeText = ''
            data.featuredTheme.forEach((row, index) => {
                row.index = index
                row.check = false
                row.expand = false
                featuredThemeText += "- "+row.themeName+" -\n\n"
                featuredThemeText += row.title+"\n\n"
                featuredThemeText += row.content+"\n\n"
            })

            var featuredStockKospiText = ''
            data.featuredStockKospi.forEach((row, index) => {
                row.index = index
                row.check = false
                row.expand = false
                // 220802 meej 그룹주일 경우 그룹명으로 나오게 수정
                if(!isEmpty(row.GROUP_NAME)){
                    featuredStockKospiText += row.GROUP_NAME+" : "+row.title+"\n"
                }else{
                    featuredStockKospiText += row.stockName+"("+row.stockCode+") : "+row.title+"\n"
                }
                featuredStockKospiText += row.content+"\n\n"
            })

            var featuredStockKosdaqText = ''
            data.featuredStockKosdaq.forEach((row, index) => {
                row.index = index
                row.check = false
                row.expand = false
                // 220802 meej 그룹주일 경우 그룹명으로 나오게 수정
                if(!isEmpty(row.GROUP_NAME)){
                    featuredStockKosdaqText += row.GROUP_NAME+" : "+row.title+"\n"
                }else{
                    featuredStockKosdaqText += row.stockName+"("+row.stockCode+") : "+row.title+"\n"
                }
                featuredStockKosdaqText += row.content+"\n\n"
            })

            var stockJumpText = ''
            data.stockJump.sort((a, b) => b.changeRate - a.changeRate);
            data.stockJump.forEach((row, index) => {
                row.index = index
                row.check = false
                row.expand = false
                stockJumpText += row.stockName+"("+row.stockCode+") : "+row.content+"\n"
            })
            this.featuredTheme.items = data.featuredTheme
            this.featuredStockKospi.items = data.featuredStockKospi
            this.featuredStockKosdaq.items = data.featuredStockKosdaq
            this.stockJump.items = data.stockJump

            this.techAkospi.content = (data.techAkospi === null? '':data.techAkospi.content)
            this.techAkosdaq.content = (data.techAkosdaq === null? '':data.techAkosdaq.content)
            this.techBkospi.content = (data.techBkospi === null? '':data.techBkospi.content)
            this.techBkosdaq.content = (data.techBkosdaq === null? '':data.techBkosdaq.content)

            // 22.09.16 suye 기술적분석 관련종목 추가
            // this.techAkospi.content = (data.techAkospi === null? '':this.techRelatedStocks(data.techAkospi))
            // this.techAkosdaq.content = (data.techAkosdaq === null? '':this.techRelatedStocks(data.techAkosdaq))
            // this.techBkospi.content = (data.techBkospi === null? '':this.techRelatedStocks(data.techBkospi))
            // this.techBkosdaq.content = (data.techBkosdaq === null? '':this.techRelatedStocks(data.techBkosdaq))
            

            /* 
                220503 meej 특징테마/종목/상한가 텍스트 모아보기 추가
                220725 meej 특징테마/종목/상한가 시황 등록 버튼 추가로 인한 내용 수정
             */
            this.textAll.content = ''
            if(!isEmpty(this.featuredTheme.content) || !isEmpty(featuredThemeText)){
                if(!isEmpty(this.featuredTheme.content)){
                    this.textAll.themeFeatured = "- 테마시황 -\n\n"
                    this.textAll.themeFeatured += this.featuredTheme.content+"\n\n"
                }
                if(!isEmpty(featuredThemeText)){
                    this.textAll.themeFeatured += featuredThemeText
                }

                this.textAll.content += this.textAll.themeFeatured+"\n"
            }
            
            if(!isEmpty(featuredStockKospiText) || !isEmpty(featuredStockKosdaqText)){
                if(!isEmpty(featuredStockKospiText)){
                    this.textAll.stockFeatured += "- 코스피 시장 -\n\n"
                    this.textAll.stockFeatured += featuredStockKospiText 
                }
                if(!isEmpty(featuredStockKosdaqText)){
                    this.textAll.stockFeatured += "- 코스닥 시장 -\n\n"
                    this.textAll.stockFeatured += featuredStockKosdaqText 
                }

                this.textAll.content += "[특징 종목]\n\n"
                this.textAll.content += this.textAll.stockFeatured+"\n"
            }

            if(!isEmpty(stockJumpText)){
                this.textAll.stockJump += stockJumpText
            
                this.textAll.content += "[특징 상한가 및 급등주]\n\n"
                this.textAll.content += this.textAll.stockJump+"\n"
            }
        },
        // 220725 meej 특징테마/종목/상한가 시황 등록하기
        insertMarket(){
            if(isEmpty(this.sendDate)){
                this.showError({vc: this, error:"전송일자를 입력하세요."})
                return
            }
            if(isEmpty(this.textAll.content)){
                this.showError({vc: this, error:"입력된 내용이 없습니다."})
                return
            }
            let msg = ""
            let subMsg = ""
            let items = []
            if(isEmpty(this.textAll.themeFeatured)){
                msg += "특징테마 입력내용이 없습니다. <br>"
            }else{
                subMsg += "[특징테마] "
                items.push({
                    content : this.textAll.themeFeatured,
                    newsType : 'MARKET_THEME_FEATURED',
                    title : '특징테마',
                    sendDate : this.sendDate,
                    showDate : this.sendDate
                })
            }

            if(isEmpty(this.textAll.stockFeatured)){
                msg += "특징종목 입력내용이 없습니다. <br>"
            } else{
                subMsg += "[특징종목] "
                items.push({
                    content : this.textAll.stockFeatured,
                    newsType : 'MARKET_STOCK_FEATURED',
                    title : '특징종목',
                    sendDate : this.sendDate,
                    showDate : this.sendDate
                })
            }

            if(isEmpty(this.textAll.stockJump)){
                msg += "특징상한가 입력내용이 없습니다. <br>"
            } else {
                subMsg += "[특징상한가] "
                items.push({
                    content : this.textAll.stockJump,
                    newsType : 'MARKET_STOCK_JUMP',
                    title : '특징상한가 ',
                    sendDate : this.sendDate,
                    showDate : this.sendDate
                })
            }

            this.showAlert({
                vc: this,
                message: msg+subMsg+'시황을 등록하시겠습니까?',
            }).then(() => {
                for(let i=0;i<items.length;i++){
                    const params = items[i]
                    this.WRITE_ITEM(params)
                    .then(() => {
                        this.showMessage({ vc: this, message: "["+items[i].title+"] "+'등록 되었습니다.' })
                    })
                    .catch(() => {
                        this.showError({ vc: this, error: "["+items[i].title+"] "+"등록 실패하였습니다." })
                    })
                }
                
                this.sendDate = ''
            })
        },
    },
    watch: {
        pageStatus: function() {
            if (this.isEditPage) {
                this.checkItem = null
            }
        }
    }
}
</script>