<template>
    <section class="row">
        <q-toolbar class="row q-mt-md justify-center">
            <div class="q-py-xs q-pr-xl"><i-select v-model="type" :options="typeOptions" option-value="type" option-label="typeNm" style="width:160px;"/></div>
            <div class="q-py-xs"><i-select v-model="dateGb" :options="dateGbOptions" option-value="dateGb" option-label="dateGbNm" style="width:130px;"/></div>
            <div class="q-py-xs"><i-datetime v-show="dateGb.dateGb!=''" date v-model="dateStr" label="입력일"/></div>
            <div class="q-py-xs"><span v-show="dateGb.dateGb!=''" > ~ </span></div>
            <div class="q-py-xs q-pr-xl"><i-datetime v-show="dateGb.dateGb!=''" date v-model="dateEnd" label="입력일"/></div>
            <div class="q-py-xs q-pr-xs"><i-select v-model="keywordGb" :options="keywordGbOptions" option-value="keywordGb" option-label="keywordGbNm" style="width:120px;"/></div>
            <div class="q-py-xs q-pr-lg"><i-input v-model="keyword" style="width:200px;"/></div>
            <div class="q-py-xs"><i-btn label="검색" @click="getList()"/></div>
        </q-toolbar>
        <article class="col-12 q-qa-md">
            <q-table
                flat
                bordered
                dense
                separator="cell"
                class="i-table row"
                :class="{ 'no-data': list.length == 0 }"
                :columns="columns"
                :data="list"
                row-key="RNUM"
                :rows-per-page-options="[0]"
                style="height:auto;"
                hide-pagination
                no-data-label="조회되는 데이터가 없습니다."
            >
                <template v-slot:header="props">
                    <q-tr :props="props">
                        <q-th
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props"
                            >{{ col.label }}</q-th
                        >
                    </q-tr>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props"
                        >
                            <div
                                v-if="
                                    col.name == 'button' &&
                                        role == 'superuser' &&
                                        props.row.role != 'superuser'
                                "
                            >
                                <q-btn
                                    size="xs"
                                    label="수정"
                                    @click="getUpdatePopup(props.row)"
                                />
                                <q-btn
                                    size="xs"
                                    label="삭제"
                                    color="red"
                                    @click="getDelete(props.row)"
                                />
                            </div>
                            <div v-else>{{ col.value }}</div>
                        </q-td>
                    </q-tr>
                </template>
                <template v-slot:no-data="{ icon, message }"
                    ><div class="full-width row flex-center q-gutter-sm">
                        <q-icon size="2em" :name="icon" />
                        <span>{{ message }}</span>
                    </div></template
                >
            </q-table>
            <div v-show="list.length != 0" class="flex flex-center q-mt-md">
                <q-pagination
                    v-model="pagination.page"
                    :max="pagesNumber"
                    :max-pages="pagination.maxPages"
                    :direction-links="true"
                    :boundary-numbers="false"
                    :boundary-links="true"
                    :ellipses="false"
                    @click="getList()"
                />
            </div>
            <q-dialog v-model="updatePopup" persistent>
                <q-card style="min-width: 650px">
                    <q-card-section class="row">
                        <div class="q-my-auto q-pr-xs">
                            <label class="i-label">등급 변경 : </label>
                        </div>
                        <div class="q-my-auto q-pr-xl">
                            <label class="i-label">{{ U_OldRoleType }}</label>
                        </div>
                        <div class="q-my-auto q-pr-xl">▶▶▶</div>
                        <div class="q-pa-xs">
                            <i-select
                                v-model="U_RoleType"
                                :options="U_RoletypeOptions"
                                option-value="type"
                                option-label="typeNm"
                                style="width:150px;"
                                @input="selectChk()"
                            />
                        </div>
                        <div class="q-pa-xs">
                            <i-select
                                v-model="U_trnsmisId"
                                :options="U_trnsmisIdOptions"
                                option-value="trnsmisId"
                                option-label="trnsmisNm"
                                style="width:210px;"
                                :disable="U_trnsmisIdDisable"
                            />
                        </div>
                    </q-card-section>
                    <q-card-section class="row">
                        <div class="q-my-auto q-pr-xs">
                            <label class="i-label">팀 변경 : </label>
                        </div>
                        <div class="q-my-auto q-pr-xl">
                            <label class="i-label">{{ U_OldTeamType }}</label>
                        </div>
                        <div class="q-my-auto q-pr-xl">▶▶▶</div>
                        <div class="q-pa-xs">
                            <i-select
                                v-model="U_TeamType"
                                :options="U_TeamtypeOptions"
                                option-value="type"
                                option-label="typeNm"
                                style="width:150px;"
                                @input="selectChk()"
                            />
                        </div>
                    </q-card-section>
                    <q-card-actions align="right" class="text-primary">
                        <q-btn
                            color="white"
                            text-color="black"
                            label="닫기"
                            v-close-popup
                        />
                        <q-btn label="수정" @click="getSave()" />
                    </q-card-actions>
                </q-card>
            </q-dialog>
        </article>
        <!-- <i-list class="col-12" ref="staffList"/> -->
    </section>
</template>
<script>
import CONSTANTS from '@/constants'
import Select from '@/components/common/Select.vue';
import DateTime from '@/components/common/DateTime';
import Input from '@/components/common/Input';
import Button from '@/components/common/Button'
import { FormatDateTime } from '@/util'
import { mapState, mapMutations, mapActions } from 'vuex'
import debug from '@/debug'

export default {
    name: 'Staff',
    components: {
        'i-select' : Select,
        'i-datetime': DateTime,
        'i-input': Input,
        'i-btn': Button,
    },
    data () {
        var now = new Date();
        var dEnd = now.getFullYear() + '' + ((now.getMonth()+1)>9 ? ''+(now.getMonth()+1) : '0'+(now.getMonth()+1)) + '' + (now.getDate()>9 ? ''+now.getDate() : '0'+now.getDate());
        now.setMonth(now.getMonth()-6);
        var dStr = now.getFullYear() + '' + ((now.getMonth()+1)>9 ? ''+(now.getMonth()+1) : '0'+(now.getMonth()+1)) + '' + (now.getDate()>9 ? ''+now.getDate() : '0'+now.getDate());
        return {
            type: {'type':'', 'typeNm':'전체관리자'},
            typeOptions: [{'type':'', 'typeNm':'전체관리자'}].concat(CONSTANTS.STAFF_ROLE_TEAM),
            dateGb: {'dateGb':'', 'dateGbNm':'전체'}, // 220908 meej 전체 기간 추가
            dateGbOptions:[
                    {'dateGb':'', 'dateGbNm':'전체'},
                    {'dateGb':'createTime', 'dateGbNm':'가입일'},
                    {'dateGb':'loginTime', 'dateGbNm':'최근로그인'}
                ],
            dateStr: dStr,
            dateEnd: dEnd,
            keywordGb: {'keywordGb':'id', 'keywordGbNm':'ID'},
            keywordGbOptions:[
                    {'keywordGb':'id', 'keywordGbNm':'ID'},
                    {'keywordGb':'name', 'keywordGbNm':'닉네임'}
                ],
            keyword: '',
            roleType: CONSTANTS.STAFF_ROLE,
            teamType: CONSTANTS.STAFF_TEAM,
            columns: [
                {
                    headerStyle: 'text-align:center;',
                    style: 'width:4%;',
                    name: 'RNUM',
                    label: '순번',
                    align: 'center',
                    field: (row) =>
                        Number(this.totcount) - Number(row.RNUM) + 1,
                },
                {
                    headerStyle: 'text-align:center;',
                    style: 'width:10%;',
                    name: 'createTime',
                    label: '회원가입일',
                    align: 'center',
                    field: (row) => FormatDateTime(row.createTime),
                },
                {
                    headerStyle: 'text-align:center;',
                    style: 'width:15%;',
                    name: 'id',
                    label: 'ID',
                    align: 'center',
                    field: 'id',
                },
                {
                    headerStyle: 'text-align:center;',
                    style: 'width:*;',
                    name: 'name',
                    label: '닉네임',
                    align: 'center',
                    field: 'name',
                },
                {
                    headerStyle: 'text-align:center;',
                    style: 'width:12%;',
                    name: 'team',
                    label: '팀',
                    align: 'center',
                    field: (row) =>
                        row.team && this.findTeamtype(row.team).typeNm,
                },
                {
                    headerStyle: 'text-align:center;',
                    style: 'width:12%;',
                    name: 'type',
                    label: '회원구분',
                    align: 'center',
                    field: (row) =>
                        row.role && this.findRoletype(row.role).typeNm,
                },
                {
                    headerStyle: 'text-align:center;',
                    style: 'width:10%;',
                    name: 'loginTime',
                    label: '최근로그인',
                    align: 'center',
                    field: (row) => FormatDateTime(row.loginTime),
                },
                {
                    headerStyle: 'text-align:center;',
                    style: 'width:10%;',
                    name: 'loginIp',
                    label: 'IP',
                    align: 'center',
                    field: 'loginIp',
                },
                {
                    headerStyle: 'text-align:center;',
                    style: 'width:8%;',
                    name: 'button',
                    label: '수정/삭제',
                    align: 'center',
                },
            ],
            pagination: {
                page: 1,
                rowsPerPage: CONSTANTS.LIST_COUNT_PER_PAGE,
                nextCount: 0,
                maxPages: CONSTANTS.DEFAULT_PAGE_NUM,
            },
            updatePopup: false,
            U_OldRoleType: '',
            U_OldTeamType: '',
            U_RoleType: null,
            U_TeamType: null,
            U_RoletypeOptions: [],
            U_TeamtypeOptions: [],
            U_trnsmisId: null,
            U_trnsmisIdOptions: [],
            U_trnsmisIdDisable: true,
            U_id: '',
            listCoprtnOxpr: [],
            listAdvrtsCtmmny: [],
            listRoleTypeOptions: [{ type: 'everyone', typeNm: '선택' }].concat(
                CONSTANTS.STAFF_ROLE
            ),
            listTeamTypeOptions: [{ type: 'BLANK', typeNm: '선택' }].concat(
                CONSTANTS.STAFF_TEAM
            ),
        }
    },
    computed: {
        ...mapState('manage', ['list', 'totcount']),

        ...mapState({
            role: (state) => state.session.role,
            userId: (state) => state.session.userId,
        }),
        pagesNumber() {
            return Math.ceil(
                Number(this.totcount) / Number(this.pagination.rowsPerPage)
            )
        },
    },
    created() {
        this.getPage()
        this.getList()
    },
    methods: {
        
        ...mapMutations('manage', ['SET_DATA']),
        ...mapActions('alert', [
            'showError',
            'showMessage',
            'showAlert',
            'showDeleteAlert',
        ]),
        ...mapActions('manage', ['GET_CALL']),
        //페이지
        getPage() {
            let param = {
                CALL_API_URL: '/staff/page',
            }
            this.GET_CALL(param)
                .then((data) => {
                    this.listCoprtnOxpr = data.listCoprtnOxpr
                    this.listAdvrtsCtmmny = data.listAdvrtsCtmmny
                })
                .catch((error) => {
                    this.showError({ vc: this, error: error })
                })
        },
        //리스트
        getList() {
            var sRole = ''
            var sTeam = ''
            if (this.type != null) {
                var arr = this.type.type.split('_')
                sRole = arr[0]
                sTeam = arr[1]
            }
            let param = {
                role: sRole,
                team: sTeam,
                dateGb: this.dateGb.dateGb == "" ? null : this.dateGb.dateGb, // 220908 meej 전체 기간 추가
                dateStr: this.dateGb.dateGb == "" ? null : this.dateStr, // 220908 meej 전체 기간 추가
                dateEnd: this.dateEnd, 
                keywordGb: this.keywordGb.keywordGb,
                keyword: this.keyword,
                pageStr:
                    (Number(this.pagination.page) - 1) *
                    Number(this.pagination.rowsPerPage),
                pageSize: this.pagination.rowsPerPage,
                CALL_API_URL: '/staff/list',
            }
            this.GET_CALL(param)
                .then((data) => {
                    this.SET_DATA(data)

                })
                .catch((error) => {
                    this.showError({ vc: this, error: error })
                })
        },
        //수정팝업
        getUpdatePopup(obj) {
            this.U_RoletypeOptions = []
            this.U_TeamtypeOptions = []
            if (this.role == 'superuser') {
                for (var i = 0; i < this.listRoleTypeOptions.length; i++) {
                    this.U_RoletypeOptions.push(this.listRoleTypeOptions[i])
                }
                for (var j = 0; j < this.listTeamTypeOptions.length; j++) {
                    this.U_TeamtypeOptions.push(this.listTeamTypeOptions[j])
                }
            } else if (this.role == 'poweruser') {
                for (i = 0; i < this.listRoleTypeOptions.length; i++) {
                    if (
                        this.listRoleTypeOptions[i].type != 'superuser' &&
                        this.listRoleTypeOptions[i].type != 'poweruser'
                    ) {
                        this.U_RoletypeOptions.push(this.listRoleTypeOptions[i])
                    }
                }
            }
            var sel = this.U_RoletypeOptions.filter(function(entry) {
                return entry.type === obj.role
            })[0]
            var selTeam = this.U_TeamtypeOptions.filter(function(entry) {
                return entry.type === obj.team
            })[0]
            this.U_RoleType =
                sel == null ? { type: 'everyone', typeNm: '선택' } : sel
            this.U_TeamType =
                selTeam == null ? { type: 'BLANK', typeNm: '선택' } : selTeam
            this.U_id = obj.id
            this.U_OldRoleType =
                this.findRoletype(obj.role) == null
                    ? '승인전'
                    : this.findRoletype(obj.role).typeNm
            this.U_OldTeamType =
                this.findTeamtype(obj.team) == null
                    ? '승인전'
                    : this.findTeamtype(obj.team).typeNm
            this.selectChk(obj.trnsmisId)
            this.updatePopup = true
        },
        //저장
        getSave() {
            this.showAlert({
                vc: this,
                message:
                    '데이터를 <span style="color:#1976D2;">저장</span> 하시겠습니까?',
            })
                .then(() => {
                    var changeRole = this.U_RoleType.type
                    var changeTeam = this.U_TeamType.type
                    this.updatePopup = false
                    var sTrnsmisId = ''
                    if (this.U_trnsmisId != null) {
                        sTrnsmisId = this.U_trnsmisId.trnsmisId
                    }
                    let param = {
                        id: this.U_id,
                        role: changeRole,
                        team: changeTeam,
                        trnsmisId: sTrnsmisId,
                        CALL_API_URL: '/staff/save',
                    }
                    this.GET_CALL(param)
                        .then(() => {
                            this.showMessage({
                                vc: this,
                                message: '저장 되었습니다.',
                            })
                            this.getList()
                        })
                        .catch((error) => {
                            this.showError({ vc: this, error: error })
                        })
                })
                .catch(() => {})
        },
        //삭제
        getDelete(obj) {
            this.showDeleteAlert({
                vc: this,
                message:
                    '정말로 <span style="color:red;">삭제</span> 하시겠습니까?',
            })
                .then(() => {
                    let param = {
                        id: obj.id,
                        CALL_API_URL: '/staff/delete',
                    }
                    this.GET_CALL(param)
                        .then(() => {
                            this.showMessage({
                                vc: this,
                                message: '삭제 되었습니다.',
                            })
                            this.getList()
                        })
                        .catch((error) => {
                            this.showError({ vc: this, error: error })
                        })
                })
                .catch(() => {})
        },
        selectChk(val) {
            if (this.U_RoleType != null && this.U_RoleType.type == CONSTANTS.ROLE.CU) {
                if(this.U_TeamType) {
                    if(this.U_TeamType.type == CONSTANTS.TEAM.MARKETING) {
                        this.U_trnsmisIdOptions = [
                            { trnsmisId: '', trnsmisNm: '선택' },
                        ].concat(this.listAdvrtsCtmmny)
                    } else if(this.U_TeamType.type == CONSTANTS.TEAM.IR) {
                    this.U_trnsmisIdOptions = [
                            { trnsmisId: '', trnsmisNm: '선택' },
                        ].concat(this.listCoprtnOxpr)
                    }
                    this.U_trnsmisId = this.U_trnsmisIdOptions[0]
                    this.U_trnsmisIdDisable = false  
                } 
                
            } else {
                this.U_trnsmisId = null
                this.U_trnsmisIdOptions = []
                this.U_trnsmisIdDisable = true
            }
                
            
            debug.log('this.U_trnsmisIdOptions',this.U_trnsmisIdOptions)
            if (val) {
                this.U_trnsmisId = this.U_trnsmisIdOptions.filter(function(entry) {
                    return entry.trnsmisId === val
                })[0]
            }
        },
        findRoletype(role) {
            return this.roleType.find((el) => el.type == role)
        },
        findTeamtype(team) {
            return this.teamType.find((el) => el.type == team)
        },
    },
    
}
</script>
