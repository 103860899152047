<template>
  <q-table
    dense
    :columns="columns"
    :data="items"
    row-key="name"
    selection="single"
    :selected.sync="selected"
    hide-bottom
    :rows-per-page-options="[0]"
    virtual-scroll
  >
    <template v-slot:top>
      <h5>{{ title }}</h5>
      <q-space />
      <!-- <i-btn class="q-ml-xs" label="취소" @click="cancelOrder" dense /> -->
      <i-btn class="q-ml-xs" label="추가" @click="addItem" dense />
      <i-btn class="q-ml-xs" label="저장" @click="doneOrder" dense />
      <i-btn class="q-ml-xs" label="취소" @click="cancelOrder" dense />
      <!-- <q-btn  flat color="dark" icon="arrow_upward"
                    @click="moveUp()"  />
            <q-btn  flat color="dark" icon="arrow_downward"
                    @click="moveDown()"  /> -->
    </template>
    <template v-slot:header="props">
      <q-tr :props="props">
        <q-th v-for="col in props.cols" :key="col.name" :props="props">
          {{ col.label }}
        </q-th>
        <q-th auto-width />
        <q-th auto-width />
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="name" :props="props">
          {{ props.row.name }}
          <q-popup-edit v-model="props.row.name">
            <q-input
              v-model="props.row.name"
              dense
              autofocus
              counter
              outlined
              hint="새로운 문구를 입력후 엔터를 치세요"
            />
          </q-popup-edit>
        </q-td>
        <q-td auto-width>
          <!-- 221026 meej useColor 추가 -->
          <div v-if="useColor">
            <q-radio keep-color v-model="props.row.color" val="teal" color="teal" />
            <q-radio keep-color v-model="props.row.color" val="orange" color="orange" />
            <q-radio keep-color v-model="props.row.color" val="blue" color="blue" />
            <q-radio keep-color v-model="props.row.color" val="red" color="red" />
          </div>
        </q-td>
        <q-td auto-width>
          <q-btn size="sm" color="red" round dense @click="delClick(props)" icon="remove" />
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>
<script>
import Button from "@/components/common/Button";
import { clone, isEmpty } from "@/util";
import debug from "@/debug";
export default {
  name: "Table",
  components: {
    "i-btn": Button,
  },
  props: {
    columns: {
      type: Array,
    },
    list: {
      type: Array,
    },
    title: null,
    // 221026 meej useColor 추가
    useColor :{ 
      type: Boolean,
      defalut:false
    },
  },
  data() {
    return {
      items: [],
      selected: [],
    };
  },
  created() {
    this.items = clone(this.list)
    this.items.forEach((row, index) => {
        row.index = index
    })
  },
  methods: {
    delClick(val) {
      debug.log("delClick = ", val.row.index);
      if (val.row.index !== undefined) {
        this.items.splice(val.row.index, 1);
      }
    },

    cancelOrder() {
      // 기존 순서로 번경
      debug.log("cancel = ", this.list);
      this.items = clone(this.list);
    },
    doneOrder() {
      let change = "";
      this.items.forEach((row) => {
        debug.log("row =", row.name);
        // 221026 meej color 추가
        // if(!isEmpty(row.name)) change += row.name + ',' 
        if (!isEmpty(row.name)) change += row.name +"|"+row.color+ ",";
      });

      this.$emit("order-event", change.slice(0, -1));
    },
    swap(array, idx1, idx2) {
      const temp1 = this.items[idx1];
      const temp2 = this.items[idx2];
      this.$set(this.items, idx1, temp2);
      this.$set(this.items, idx2, temp1);
    },
    moveUp() {
      let index = this.items.indexOf(this.selected[0]);
      if (index === 0) return;
      this.swap(this.items, index, index - 1);
    },
    moveDown() {
      let index = this.items.indexOf(this.selected[0]);
      if (index === this.items.length - 1) return;
      this.swap(this.items, index, index + 1);
    },
    addItem() {
      this.items.push({ index: this.items.length, name: "" });
    },
  },
  watch: {
    list(val, old) {
      if (val !== old) {
        this.items = clone(this.list);
        this.items.forEach((row, index) => {
          row.index = index;
        });
      }
    },
  },
};
</script>

