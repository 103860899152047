<template>
    <article>
        <div>
            <div class="row col-12 q-my-xs" v-if="newsTypeTitle !== null">
                <i-field :text="newsTypeTitle" />
            </div>
            <p
                class="q-pa-xs" style="overflow:scroll; height:100%; background-color:lightgrey"
                v-html="flashData.content">
            </p>
            <div v-if="editSendTime" class="row">
                <i-datetime class="col-lg-6" v-model="flashData.sendDate" date dateLabel="전송일자" />
                <i-datetime class="col-lg-6" v-model="flashData.sendTime" time timeLabel="전송시간" />
            </div>
            <div v-else>전송일시 : {{ formatDateTime }}
            </div>
            <div class="row">
                <i-btn class="col-12 col-sm-5 "  v-if="!disableCancel" :label="cancelButton" @click="cancelFunc()"/>
                <i-btn class="col-12 col-sm-5 q-ml-md" label="보내기" @click="send()"/>
            </div>
        </div>
    </article>
</template>
<script>
//import Debug from '@/debug'

import Button from '@/components/common/Button'
import Field from '@/components/common/Field'
import DateTime from '@/components/common/DateTime'
import { FormatDateTime,isEmpty, today, nowTime } from '@/util';

import { mapMutations, mapActions, mapGetters } from 'vuex'

export default {
    name: 'Preview',
    components: {
        'i-btn' :  Button,
        'i-field' :  Field,
        'i-datetime': DateTime
    },
    props: {
        flashData: {
            type:Object,
            default: function() {
                return {
                    preview: '',
                    newsId:'',
                    newsType:null,
                    afterSend: null,
                    pageStatus: 0,
                    sendDate: null,
                    sendTime: null
                }
            },
            required: true
        },
        newsTypeTitle: {
            type: String,
            default: null,
        },
        cancelButton: {
            type: String,
            default: '취소'
        },
        disableCancel: {
            type: Boolean,
            default: false
        },
        cancelFunc: {
            type: Function,
            default: function() {
                this.setPageStatus(this.flashData.pageStatus)
            }
        },
        editSendTime: {
            type: Boolean,
            default: false
        },
    },
    created () {
        // 전송시간 오늘날짜로 설정 
        if(this.editSendTime) {
            this.flashData.sendDate = null
            this.flashData.sendTime = null
        }
    },
    computed:{
        formatDateTime() {
            let datetime = (isEmpty(this.flashData.sendDate)?today():this.flashData.sendDate)+(isEmpty(this.flashData.sendTime)?nowTime():this.flashData.sendTime)
            return FormatDateTime(datetime)
        }
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus'
        }),
        ...mapActions({
            SEND_ITEM: 'flash/SEND_ITEM',
            showError: 'alert/showError',
        }),
        ...mapGetters ({
            today : 'calendar/today'
        }),
        send() {
            var params = {
                newsType: this.flashData.newsType.value,
                id: this.flashData.newsId
            }

            this.SEND_ITEM(params)
            .then(() => {
                this.flashData.afterSend()
            })
            .catch((err) => {
                this.showError({vc:this, error:err} )
            })
        }
        
    }
}
</script>
<style>
</style>
