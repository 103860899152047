<template>
  <article>
    <div>
      <div class="q-ma-sm" style="border: 1px solid #dee2e6; border-radius: 0.25rem">
          <div class="q-ma-xs bg-grey-4" style="font-weight: bold;font-size:14pt">
              <span class="q-ml-xs"> 금일 전송된 속보 목록</span>
          </div>
          <!-- <div v-if="list.length > 0">
            <div class="q-ma-xs" v-for="(item, index) in list" :key="index">
                [{{item.sendTime}}] {{item.title}}
            </div>
          </div>
          <div v-if="list.length == 0" class="q-ma-xs" >
              조회되는 데이터가 없습니다.
          </div> -->
      </div>
      <div v-if="list.length > 0" class="q-ma-sm" >
          <i-simple-table
              ref="tableRef"
              :columns="flashColums"
              :list="list"
              :needCheckBox="false"
          />
      </div>
      <div v-if="list.length == 0" class="q-ma-xs" >
            조회되는 데이터가 없습니다.
      </div> 
    </div>
  </article>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'
import SimpleTable from '@/components/common/SimpleTable'
import { FormatTimeSeconds } from '@/util'

export default {
  name: "FlashListPopup",
  components: {
        'i-simple-table': SimpleTable,
    },
  data() {
    return {
        list: [],
        flashColums: [
            {
                name: 'sendTime',
                label: '전송시간',
                align: 'left',
                field: (row) => row.sendTime,
            },
            {
                name: 'themeName',
                label: '테마명',
                align: 'left',
                field: (row) => row.themeName,
            },
            {
                name: 'title',
                label: '제목',
                align: 'left',
                field: (row) => row.title,
            },
        ],
    };
  },
  created() {
      this.INIT_LIST()
      this.getList()
  },
  computed: {
  },
  methods: {
    ...mapMutations('flash', ['INIT_LIST']),
    ...mapActions('flash', ['GET_NEWS_LIST_DAY']),

    getList(){
        var params = {}
        params.newsType = 'MARKET_FLASH_THEME'
        if(this.$route.query.themeName != undefined){
            params.themeName = this.$route.query.themeName
        }
        this.GET_NEWS_LIST_DAY(params)
        .then((el)=>{
          el.items.forEach((row)=>{
            let content = row.content.replace('<div class="mttl">관련 테마분석</div> <br> <div><span class="tldot">▶</span><b>','').split('</b></div> <table class="tbl">')
            row.themeName = content[0]
            row.sendTime = FormatTimeSeconds(row.sendTime)
          })
          this.list = el.items
        })
    }
  },
  watch: {},
};
</script>
<style>
</style>
