import InfostockService from '../../../services/InfostockService'
import Debug from '../../../debug'
import CONSTANTS from '../../../constants'

//TODO : 현재 progress component 가 없음.. 추후에 삭제하던가..progress component 추가가 필요함
const state = () => {
    return {
        userId: localStorage.getItem('userId') || '',
        username: localStorage.getItem('username') || '',
        team: localStorage.getItem('team') || '',
        role: localStorage.getItem('role') || '',
        position: ''
    }
}

const getters = {
    isLoggedIn: state => (!!state.userId && !!state.username && (state.userId ==='root' || !!state.team) && !!state.role)
}

const actions = {
    clear_user_action({commit}) {
        commit('logout')
    },
    logout_action({commit, dispatch}, move) {
        InfostockService.logout()
        .then((response) => {
          Debug.log('logout response ', response)
        })
        .catch((err) => {
            Debug.log('logout err '+ err)
        })
        commit('logout')
        if(move) dispatch('history/login_page', null, {root:true})
    },
    login_action({commit}, {uid, password}) {
        Debug.log('action login')
        return new Promise((resolve, reject) => {
            let data = {
                id: uid,
                password: password
            }
            InfostockService.login(data)
            .then((response) => {
                if(response.success) {
                    const userId = response.data.id
                    const username = response.data.name
                    const team = response.data.team
                    const role = response.data.role
                    commit('login_success', { userId, username, team, role})
                    Debug.log('login_success: ', userId, username, team, role)
                    if(role !== CONSTANTS.ROLE.CU) {
                        resolve(CONSTANTS.LOGINED)    
                    }
                    
                } else {
                    // if(response.status === CONSTANTS.SERVER_CODE_2FA) {
                    //     resolve(CONSTANTS.REQUIRE_2FA)
                    // } else {
                        reject(response)
                    //}
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    invalid_session({ commit, dispatch }) {
        commit('logout')
        dispatch('history/login_page', null, {root:true})
    },
    permission_denined({dispatch}) {
        dispatch('history/main_page', null, {root:true})
    },
    async check_session ({ state }) {

        return new Promise((resolve, reject) => {
            if (state.username === null || state.username === undefined || state.username === '') {
                reject({invalid: true, data:'올바르지 않은 세션입니다.'})
                return;
            }
            InfostockService.hello()
                .then((response) => {
                    Debug.log("----------------check_session success", response.data, ", username=", state.username)
                    resolve(response.data)
                })
                .catch((error) => {
                    Debug.log("----------------check_session error.response =", error.response)
                    Debug.log("----------------check_session error.response.data =", error.response.data)
                    if(error.response.data.status === '902') {
                        reject({invalid: true, data:error.response.data.message})
                    } else {
                        reject({invalid: false , data:error})
                    }

                })
    })
  },
}
const mutations = {
    login_success(state, { userId, username, team, role}){
        state.logined = true

        state.userId = userId
        state.username = username
        state.team = team
        state.role = role

        localStorage.setItem('userId', userId)
        localStorage.setItem('username', username)
        localStorage.setItem('team', team)
        localStorage.setItem('role', role)
    },
    logout(state) {
        state.userId = ''
        state.username = ''
        state.team = ''
        state.role = ''
        state.position = ''
        localStorage.removeItem('userId')
        localStorage.removeItem('username')
        localStorage.removeItem('team')
        localStorage.removeItem('role')
    },
    changePos(state, pos) {
        state.position = pos
    }
}
export const session = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}