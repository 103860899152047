<template>
    <article v-if="data.gubun!=4" id="data">
        <div class="i-content-wrap">
            <div class="row i-top-wrap q-pa-sm">
                <div class="col-12 col-lg-2 q-pa-xs">
                    <i-input v-model="stockCode1" label="관련종목1" />
                </div>
                <div class="col-12 col-lg-2 q-pa-xs">
                    <i-input v-model="stockCode2" label="관련종목2" />
                </div>
                <div class="col-12 col-lg-2 q-pa-xs">
                    <i-input v-model="stockCode3" label="관련종목3" />
                </div>
                <div class="col-12 col-lg-2 q-pa-xs">
                    <i-input v-model="stockCode4" label="관련종목4" />
                </div>
                <div class="col-12 col-lg-2 q-pa-xs">
                    <i-input v-model="stockCode5" label="관련종목5" />
                </div>
                <div class="col-12 col-lg-10 q-pa-xs">
                    <i-datetime time v-model="time" timeLabel="예약시간" />
                </div>                        
            </div>            
            <div v-if="data.gubun==1" id="data">
                <p v-html="data.tableNews1"></p>          
            </div>
            <div v-if="data.gubun==2" id="data">
                <p v-html="data.tableNews2"></p>          
            </div>      
            <div v-if="data.gubun==3" id="data">
                <p v-html="data.tableNews3"></p>              
            </div>            
            <div class="col-12 col-lg-6 q-py-xl">
                <i-btn class="full-width" label="속보예약" @click="write()"/>
            </div>
        </div>
    </article>
    <!-- TODO: 수정 시 화면 필요 -->
</template>
<script>
// import CONSTANTS from '@/constants'
import { today } from '@/util'
// import StockSearch from '@/components/common/StockSearch'
import Button from '@/components/common/Button'
import Input from '@/components/common/Input'
import DateTime from '@/components/common/DateTime'
import { mapState, mapActions } from 'vuex'
import Debug from '@/debug'

export default {
    name: 'Content',
    components: {
        'i-btn' :  Button,
        'i-input' : Input,
        'i-datetime': DateTime,
        // 'i-stock-search': StockSearch,
    },
    data () {
        return {
            stockCode1:"",
            stockCode2:"",
            stockCode3:"",
            stockCode4:"",
            stockCode5:"",
            time:"083000",
            sendTime:null
        }
    },
    created () {
    },
    computed : {
        ...mapState ({
            list :  state => state.preview.items,
            data : state => state.preview.data,
        }),     
    },
    methods: {
        ...mapActions('flash', ['SEND_FLASH']),
        ...mapActions( 'preview', ['NES_RESERVE']),
        ...mapActions({
            showError: 'alert/showError',
            showMessage: 'alert/showMessage'
        }),        
        
        
        write(){
            if (!this.stockCode1) {
                this.showMessage({ vc:this, message:'종목을 입력해주세요.' })
                return
            }
            let newsType = (this.flashType? this.flashType.value:'MARKET_FLASH_EARNING')
            let showFlag = 1
            let stocks = this.stockCode1 + ';' + this.stockCode2 + ';' + this.stockCode3 + ';' + this.stockCode4 + ';' + this.stockCode5       
            let params = {
                newsType:newsType,
                menuType:'MENU_EARNING',
                // title:this.data.title,
                sendTime:today()+this.time,
                showFlag:showFlag,
                stocks:stocks
            }
            if(this.data.gubun==1){
                params.content = this.data.tableNews1;
                params.title = this.data.tableTitle1.replace('[','').replace(']','');
            }   
            else if(this.data.gubun==2){
                params.content = this.data.tableNews2;
                params.title = this.data.tableTitle2.replace('[','').replace(']','');
            } 
            else if(this.data.gubun==3){
                params.content = this.data.tableNews3;
                params.title = this.data.tableTitle3.replace('[','').replace(']','');
            }             
            this.SEND_FLASH(params)
                .then((res) => {
                    Debug.log('data = ', res)
                    this.showMessage({ vc: this, message: '예약되었습니다.' })
                })
                .catch((err) => {
                    Debug.log('error = ', err)
                    this.showError({ vc: this, error: err })
                })   
        }
    }
}
</script>


