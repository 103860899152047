<template>
    <article>
        <div class="flex justify-end q-pa-xs">
            <i-btn v-if="newsType.value == 'BOARD_NOTICE' && isDetailPage" label="신규등록" @click="goRegisterPage()"/>
        </div>
        <q-scroll-area visible class="i-content-wrap">
            <!-- 공지사항 -->
            <article v-if="newsType.value == 'BOARD_NOTICE'">
                <q-card flat bordered>
                    <q-card-section v-if="isListPage || isRegisterPage"
                        class="row">
                        <div class="col-12 col-sm-6 q-pa-xs">
                            <i-select v-model="flag" :options="flagOptions" label="구분" />
                        </div>
                        <div class="col-12 q-pa-xs">
                            <i-input v-model="title" label="제목" />
                        </div>
                        <div class="col-12 q-pa-xs">
                            <i-input v-model="content" label="내용" type="textarea" :rows="rows"/>
                        </div>
                    </q-card-section>
                    <q-card-section v-if="isDetailPage">
                        <div class="row q-gutter-sm">
                            <div class="col-12 col-md-6">
                                <i-select label="구분" v-model="item.flag" :options="flagOptions"
                                    option-label="label" option-value="value"/>
                            </div>
                            <div class="col-12">
                                <i-input v-model="item.title" label="제목" />
                            </div>
                            <div class="col-12">
                                <i-input v-model="item.content" label="내용" type="textarea" :rows="rows"/>
                            </div>
                        </div>
                    </q-card-section>
                </q-card>
            </article>
            <!-- 1:1문의 게시판 -->
            <article v-if="newsType.value == 'BOARD_ONE_ON_ONE'">
                <div v-if="isDetailPage">
                    <q-card flat bordered>
                        <q-card-section>
                                <div class="col-6 q-pa-xs">
                                    <i-field inputLabel="작성일자" :text="items[0].createTime" />
                                </div>
                                <div class="col-6 q-pa-xs">
                                    <i-field inputLabel="변경일자" :text="items[0].lastUpdateTime" />
                                </div>
                                <!-- 220526 meej 작성자 추가 -->
                                <div class="col-6 q-pa-xs">
                                    <i-field inputLabel="작성자" :text="items[0].writer" />
                                </div>
                            <div class="q-pa-xs">
                                <i-field inputLabel="제목" :text="items[0].title" />
                            </div>
                            <div class="q-pa-xs">
                                <i-input inputLabel="내용" v-model="items[0].content" disable type="textarea" />
                            </div>
                        </q-card-section>
                    </q-card>
                    <q-card flat bordered class="q-mt-md">
                        <!-- 
                            220609 meej 답변이 1개이상 등록되었을경우에도 일단은 보이게 수정
                                        items.length == 2 -> items.length >= 2         
                        -->
                        <q-card-section v-if="items.length >= 2">
                            <div class="q-pa-xs">
                                <i-input v-model="items[1].title" label="제목" />
                            </div>
                            <div class="q-pa-xs">
                                <i-input v-model="items[1].content" label="내용" type="textarea" />
                            </div>
                        </q-card-section>
                        <q-card-section v-else>
                            <div class="q-pa-xs">
                                <i-input v-model="answerTitle" label="답변 제목" />
                            </div>
                            <div class="q-pa-xs">
                                <i-input v-model="answerContent" label="답변 내용" type="textarea" />
                            </div>
                        </q-card-section>
                    </q-card>
                </div>
            </article>
            <!-- 무통장 입금 -->
            <article v-if="newsType.value == 'BOARD_DEPOSIT'">
                <div v-if="isDetailPage">
                    <q-card flat bordered>
                        <q-card-section>
                            <div class="col-6 q-pa-xs">
                                <i-field inputLabel="작성일자" :text="item.createTime" />
                            </div>
                            <div class="col-6 q-pa-xs">
                                <i-field inputLabel="변경일자" :text="item.lastUpdateTime" />
                            </div>
                             <div class="col-12 q-pa-xs">
                                <i-field inputLabel="입금자명" :text="item.DEPOSITOR" />
                            </div>
                            <div class="col-6 q-pa-xs">
                                <i-field inputLabel="현금영수증 타입" :text="item.ISSUE_TYPE" />
                            </div>
                            <div class="col-6 q-pa-xs">
                                <i-field inputLabel="현금영수증 번호" :text="item.ISSUE_NUMBER" />
                            </div>
                        </q-card-section>
                    </q-card>
                </div>
            </article>
            <!-- 1:1문의 게시판 -->
            <article v-if="newsType.value == 'BOARD_FAKE_NEWS_CHECK'">
                <div v-if="isDetailPage">
                    <q-card flat bordered>
                        <q-card-section>
                                <div class="col-6 q-pa-xs">
                                    <i-field inputLabel="작성일자" :text="items[0].createTime" />
                                </div>
                                <div class="col-6 q-pa-xs">
                                    <i-field inputLabel="변경일자" :text="items[0].lastUpdateTime" />
                                </div>
                                <!-- 220526 meej 작성자 추가 -->
                                <div class="col-6 q-pa-xs">
                                    <i-field inputLabel="작성자" :text="items[0].writer" />
                                </div>
                            <div class="q-pa-xs">
                                <i-field inputLabel="제목" :text="items[0].title" />
                            </div>
                            <div class="q-pa-xs">
                                <i-input inputLabel="내용" v-model="items[0].content" disable type="textarea" />
                            </div>
                        </q-card-section>
                    </q-card>
                    <q-card flat bordered class="q-mt-md">
                        <!-- 
                            220609 meej 답변이 1개이상 등록되었을경우에도 일단은 보이게 수정
                                        items.length == 2 -> items.length >= 2         
                        -->
                        <q-card-section v-if="items.length >= 2">
                            <div class="q-pa-xs">
                                <i-input v-model="items[1].title" label="제목" />
                            </div>
                            <div class="q-pa-xs">
                                <i-input v-model="items[1].content" label="내용" type="textarea" />
                            </div>
                        </q-card-section>
                        <q-card-section v-else>
                            <div class="q-pa-xs">
                                <i-input v-model="answerTitle" label="답변 제목" />
                            </div>
                            <div class="q-pa-xs">
                                <i-input v-model="answerContent" label="답변 내용" type="textarea" />
                            </div>
                        </q-card-section>
                    </q-card>
                </div>
            </article>
        </q-scroll-area>
        <!-- 하단 버튼 -->
        <div class="q-my-xs">
            <i-btn 
                v-show="!(newsType.value == 'BOARD_DEPOSIT')" 
                class="full-width" 
                :label="isListPage || isRegisterPage ? '등록' : '수정'" 
                @click="write" 
            />
            <i-btn v-show="newsType.value == 'BOARD_DEPOSIT' && item.flag.value == 0" class="full-width" label="입금 확인" @click="write" />
        </div>
    </article>
</template>
<script>
import Select from '@/components/common/Select.vue'
import Input from '@/components/common/Input'
import Button from '@/components/common/Button'
import Field from '@/components/common/Field'
// import { FormatDate} from '@/util';

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Content',
    components: {
        'i-select': Select,
        'i-input': Input,
        'i-btn': Button,
        'i-field': Field,
    },
    props: {
        newsType: {
            type: Object,
            default: null,
        },
        noteVisible: {
            type: Boolean,
        },
    },
    data() {
        return {
            title: '',
            content: '',
            answerTitle: '',
            answerContent: '',
            flag: null,
            flagOptions: [
                { label: '일반', value: 0 },
                { label: '강조', value: 1 },
            ],
            signal: false,
            rows: 30,
        }
    },
    created() {
        this.flag = this.flagOptions[0]
    },
    updated() {},
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            item: (state) => state.manage.item,
            items: (state) => state.manage.items,
        }),
        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isRegisterPage',
            'isFlashPage',
            'isEditPage',
        ]),
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('resource', ['REQUEST_RESOURCE']),
        ...mapMutations('manage', ['INIT_LIST', 'SET_LIST']),
        ...mapActions('manage', ['GET_BOARD_LIST']),

        ...mapActions('alert', ['showError', 'showMessage']),
        ...mapActions('manage', [
            'ASYNC_BOARD_LIST',
            'GET_DETAIL',
            'WRITE_BOARD_ITEM',
        ]),

        goRegisterPage() {
            this.setPageStatus(this.PAGE_STATUS.REGISTER)
        },

        getList() {
            this.INIT_LIST()
            let param = {
                newsType: this.newsType.value,
            }

            this.GET_BOARD_LIST(param)
            .then((data) => {
                this.SET_LIST(data)
            })
            .catch((error) => {
                this.showError({ vc:this, error:error })
            })
        },

        // 등록
        async write() {
            var params = {}
            params.newsType = this.newsType.value
            if (this.newsType.value == 'BOARD_NOTICE') {
                if (this.isDetailPage) {
                    params.id = this.item.id
                    params.title = this.item.title
                    params.content = this.item.content
                    params.flag = this.flag.value
                } else {
                    params.title = this.title
                    params.content = this.content
                    params.flag = this.flag.value
                }
            } else if (this.newsType.value == 'BOARD_ONE_ON_ONE') {
                var items = this.items
                params.pid = items[0].pid

                if (items.length == 1) {
                    params.title = this.answerTitle
                    params.content = this.answerContent
                } else {
                    params.id = items[1].id
                    params.title = items[1].title
                    params.content = items[1].content
                }
            } else if (this.newsType.value == 'BOARD_DEPOSIT') {
                params.id = this.item.id
                params.flag = 1
            } else if (this.newsType.value == 'BOARD_FAKE_NEWS_CHECK') {
                var items1 = this.items
                params.pid = items1[0].pid

                if (items1.length == 1) {
                    params.title = this.answerTitle
                    params.content = this.answerContent
                } else {
                    params.id = items1[1].id
                    params.title = items1[1].title
                    params.content = items1[1].content
                }
            }


            this.WRITE_BOARD_ITEM(params)
            .then(() => {
                this.showMessage({ vc:this, message:'저장 되었습니다.' })
                this.getList()
            })
            .catch((err) => {
                this.showError({ vc:this, error:err })
            })
        }
    },
    watch: {
        item() {
            this.flagOptions.forEach((el) => {
                if (el.value == this.item.flag) {
                    this.flag = el
                }
            })
        },
    },
}
</script>
