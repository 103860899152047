<template>
    <article>
        <!-- 구분 -->
        <div class="i-top-wrap">
            <div class="col-12 row q-pa-xs">
                <i-select class="col-12 col-md-4" v-model="year"
                    :options="yearOptions" :label="yearLabel"
                    option-label="name0" option-value="value0" @input="getScheduleList"/>
                <i-select class="col-12 col-md-4 q-ml-xs" v-model="country"
                    :options="countryOptions" :label="countryLabel"
                    option-label="name0" option-value="value0" @input="getScheduleList" />
                <div class="flex justify-end q-pa-xs">
                 <i-btn label="선택삭제" @click="deleteItems"/>
            </div>
            </div>
            
        </div>
        <!-- 목록 -->
        <i-table :columns="columns" :list="items" :checkNextKey="false"
            @row-click="setItem" @next-click="getScheduleList" @check-event="handleCheckList"/>
    </article>
</template>
<script>
import Debug from '@/debug'
import Table from '@/components/common/Table'
import Select from '@/components/common/Select'
import { FormatDate } from '@/util'
import Button from '@/components/common/Button'
import { mapState,  mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'List',
    components: {
        'i-btn' : Button,
        'i-table' : Table,
        'i-select' : Select
    },
    props: {
        yearOptions: Array,
    },
    data () {
        return {
            // 조건
            year: null,
            country: null,
            yearLabel: '연도',
            countryLabel: '국가',
            columns: [
                { name: 'eventDate', label: '휴장일', align: 'left', field: row => row.endDate ? FormatDate(row.eventDate)+'~'+FormatDate(row.endDate) : FormatDate(row.eventDate) },
                { name: 'country', label: '나라', align: 'left',field: row => this.countryOptions.find(v => v.value0 === row.closedCountry).name0 },
                { name: 'title', label: '제목', align: 'left', field: row => row.title },
                { name: 'closedType', label: '구분', align: 'left', field: row => this.typeOptions.find(v => v.value0 === row.closedType).name0  },
            { name: 'lastUpdateTime', label: '수정일', align: 'left', field: row => FormatDate(row.lastUpdateTime) },
            ],
            selected:[],
        }
    },
    created () {
        this.setPageStatus(this.PAGE_STATUS.LIST)

        this.INIT_LIST()
        this.getScheduleList()
    },
    computed : {
        ...mapState ({
            items :  state => state.schedule.items,
            countries : state => state.schedule.countries,
            typeOptions : state => state.schedule.closedTypes,
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
            nextKey : state => state.schedule.nextKey,
            newsTypes : state => state.resource.EventNewsType,
        }),
        ...mapGetters( 'page', ['isListPage', 'isDetailPage', 'isEditPage', 'isRegisterPage']),
        countryOptions() {
            return [{name0:'전체', value0:null}].concat(this.countries)
        },

    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
            SET_ITEM:'schedule/SET_ITEM',
            INIT_LIST:'schedule/INIT_LIST'
        }),
        ...mapActions('schedule', ['ASYNC_CLOSED_LIST','MULIT_DELETE_ITEMS']),
        ...mapActions( 'alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions( 'resource', ['GET_NEWSTYPE_LIST']),

        getScheduleList() {
            this.INIT_LIST()
            let params = {}
            if(this.year !== null) {
                params.year = this.year.value0
            }
            if(this.country !== null) {
                params.country = this.country.value0
            }

            Debug.log('params = ', params)

            this.ASYNC_CLOSED_LIST(params)
        },
        setItem(item) {
            Debug.log('setItem = ', item)
            this.setPageStatus(this.PAGE_STATUS.DETAIL)
            this.SET_ITEM(item)
        },
        handleCheckList(list) {
            this.selected = list
        },
        deleteItems() {
            if(this.selected.length === 0) {
                this.showMessage({vc: this, message:'삭제할 항목을 선택하세요.'})
                return
            } else {
                this.showAlert({vc:this, message:'선택한 항목을 삭제 하시겠습니까?'})
                    .then(()=>{
                        this.doMultiDelete()
                    })
            }

        },
        doMultiDelete() {
             var params = {}
            params.ids = this.selected.map( x => x.id ).join(',')
            // 주요이슈는 완전 삭제!
            // 이미 속보로 나간거는 속보용이므로 현재 데이터는 delete
            params.force = 1
            this.MULIT_DELETE_ITEMS(params)
            .then(() => {
                this.showMessage({ vc:this, message:'삭제되었습니다.' })

                this.INIT_LIST()
                this.getScheduleList()
            })
            .catch((err) => {
                Debug.log('error = ', err)
                var error = err.message
                this.showError({ vc:this, error:error })
            })
        },

    },
    watch: {
        pageStatus: function(val, old) {
            if (val !== old &&this.isListPage) {
                this.INIT_LIST()
                this.getScheduleList()
            }
        }
    }
}
</script>


