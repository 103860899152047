<template>
    <article>
        <div class="flex justify-end q-pa-xs"><i-btn v-show="item.trnsmisId != null" label="신규등록" @click="getView()"/></div>
        <q-card>
            <q-card-section>
                <div class="col q-gutter-sm">
                    <div class="col-12"><i-input v-model="item.trnsmisNm" label="업체명"/></div>
                    <div class="col-12"><i-input v-model="item.trnsmisCn" label="줄광고 내용"/></div>
                    <div class="col-12"><i-input v-model="item.trnsmisLink" label="링크"/></div>
                    <div class="col-12"><i-select v-model="trnsmisSn" :options="trnsmisSnOptions" option-value="trnsmisSn" option-label="trnsmisSnNm" label="위치"/></div>
                    <div class="col-12">
                        <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : itemList.length == 0 }"
                            :columns="columns" :data="itemList" row-key="code" :rows-per-page-options="[0]" style="height:auto;"
                            hide-pagination no-data-label="조회되는 데이터가 없습니다.">
                            <template v-slot:header="props">
                                <q-tr :props="props">
                                    <q-th v-for="col in props.cols" :key="col.name" :props="props" v-html="col.label"></q-th>
                                </q-tr>
                            </template>
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td v-for="col in props.cols" :key="col.name" :props="props" @click="getCheckbox(props.row, col.name)">
                                        <div v-if="col.name == 'trnsmisChk0'"><q-checkbox v-model="props.row.trnsmisChk0" :value ="props.row.trnsmisChk0" :true-value="props.row.code0" false-value="N" dense/></div>
                                        <div v-else-if="col.name == 'trnsmisChk1' && props.row.code1 != null"><q-checkbox v-model="props.row.trnsmisChk1" :value ="props.row.trnsmisChk1" :true-value="props.row.code1" false-value="N" dense/></div>
                                        <div v-else-if="col.name == 'trnsmisChk2' && props.row.code2 != null"><q-checkbox v-model="props.row.trnsmisChk2" :value ="props.row.trnsmisChk2" :true-value="props.row.code2" false-value="N" dense/></div>
                                        <div v-else>{{ col.value }}</div>
                                    </q-td>
                                </q-tr>
                            </template>
                            <template v-slot:no-data="{ icon, message }"><div class="full-width row flex-center q-gutter-sm"><q-icon size="2em" :name="icon" /> <span>{{message}}</span></div></template>
                        </q-table>
                    </div>
                </div>
            </q-card-section>
        </q-card>
        <!-- 하단 버튼 -->
        <div class="q-my-xs">
            <div class="row">
                <i-btn v-show="item.trnsmisId == null" class="full-width" label="등록" @click="getSave" />
                <div class="col q-pa-xs"><i-btn v-show="item.trnsmisId != null" class="full-width" label="수정" @click="getSave" /></div>
                <div class="col q-pa-xs"><i-btn v-show="item.trnsmisId != null" class="full-width" label="삭제" @click="getDelete" /></div>
            </div>
        </div>
    </article>
</template>
<script>
import Select from '@/components/common/Select.vue';
import Input from '@/components/common/Input'
import Button from '@/components/common/Button'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Content',
    components: {
        'i-select' : Select,
        'i-input': Input,
        'i-btn': Button,
    },
    data() {
        return {
            columns: [
                { headerStyle:'text-align:center;', style:'width:*;', name: 'name0', label: '증권사', align: 'left', field:'name0' },
                { headerStyle:'text-align:center;', style:'width:10%;', name: 'trnsmisChk0', label: '전송여부', align: 'center' },
                { headerStyle:'text-align:center;', style:'width:*;', name: 'name1', label: '증권사', align: 'left', field:'name1' },
                { headerStyle:'text-align:center;', style:'width:10%;', name: 'trnsmisChk1', label: '전송여부', align: 'center' },
                { headerStyle:'text-align:center;', style:'width:*;', name: 'name2', label: '증권사', align: 'left', field:'name2' },
                { headerStyle:'text-align:center;', style:'width:10%;', name: 'trnsmisChk2', label: '전송여부', align: 'center' }
              ],
            trnsmisSn: {'trnsmisSn':'0', 'trnsmisSnNm':'랜덤'},
            trnsmisSnOptions: [{'trnsmisSn':'0', 'trnsmisSnNm':'랜덤'}]
        }
    },
    computed: {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            TRANS_TYPE : state => state.manage.transType,
        }),
        ...mapState('manage', ['list', 'item', 'items', 'itemList'])
    },
    created () {
        this.$parent.getView(null);
    },
    methods: {
        ...mapMutations('manage', ['INIT_ITEM', 'SET_ITEM', 'SET_LIST']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        getView() {
            this.$parent.getView(null);
        },
        //저장
        getSave() {
            if (!this.item.trnsmisNm) {
                this.showMessage({ vc:this, message:'업체명을 입력하세요.' });
                return;
            }
            if (!this.item.trnsmisCn) {
                this.showMessage({ vc:this, message:'줄광고 내용을 입력하세요.' });
                return;
            }
            this.showAlert({ vc:this, message:'<span style="color:#1976D2;">저장</span> 하시겠습니까?' }).then(()=>{
                var ds_list = [];
                this.itemList.forEach(row => {
                    for(var i = 0; i < 3; i++){
                        if(row['trnsmisChk'+i] != null && row['trnsmisChk'+i] != 'N'){
                            ds_list.push({
                                trnsmisId : this.item.trnsmisId,
                                code : row['trnsmisChk'+i]
                            });
                        }
                    }
                });
                let param = {
                    ds_list,
                    'trnsmisId': this.item.trnsmisId,
                    'trnsmisNm': this.item.trnsmisNm,
                    'trnsmisCn': this.item.trnsmisCn,
                    'trnsmisLink': this.item.trnsmisLink,
                    'trnsmisSn': this.trnsmisSn.trnsmisSn,
                    'trnsmisType': this.TRANS_TYPE.LINE_ADVRTS,
                    'CALL_API_URL': '/trnsmis/save'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'저장 되었습니다.' });
                    this.$parent.getView(null);
                    this.$parent.getList();
                }).catch((err)=>{
                    this.showError({ vc:this, error:err });
                });
            }).catch(() => { });
        },
        //삭제
        getDelete() {
            this.showDeleteAlert({ vc:this, message:'<span style="color:red;">삭제</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    'trnsmisId': this.item.trnsmisId,
                    'trnsmisType': this.TRANS_TYPE.LINE_ADVRTS,
                    'CALL_API_URL': '/trnsmis/delete'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'삭제 되었습니다.' });
                    this.$parent.getView(null);
                    this.$parent.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },
        getCheckbox(obj, name){
            if(obj['trnsmisChk'+name.substring(4,5)] == 'N'){
                obj['trnsmisChk'+name.substring(4,5)] = obj['code'+name.substring(4,5)];
            }else{
                obj['trnsmisChk'+name.substring(4,5)] = 'N';
            }
        },
        getTrnsmisSnChk(val){
            var listOption = [];
            var lng = this.list.length + 1;
            for(var i = 0; i <= lng; i++){
                var sTmp = "랜덤";
                if(i != 0){ sTmp = i; }
                listOption.push({
                    trnsmisSn : i,
                    trnsmisSnNm : sTmp
                });
            }
            this.trnsmisSnOptions = listOption;
            this.trnsmisSn = listOption.filter(function (entry){ return entry.trnsmisSn === val; })[0];
        }
    }
}

</script>
