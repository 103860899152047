<template>
    <article>
        <!-- 구분 -->
        <div class="row q-my-xs q-pr-md">
            <div class="col-12 col-md-4 q-pa-xs">
                <i-select v-model="condition.corpCls" :options="condition.corpClsOptions"
                    label="회사구분" @input="getSearch()"
                />
            </div>
            <div class="col-12 col-md-5 q-pa-xs">
                <div class="flex justify-between">
                    <i-stock-search style="width:80%" @get="getStock"/>
                    <i-btn style="width:20%" icon="search" @click="getSearch()"/>
                </div>
            </div>
        </div>
         <!-- 목록 -->
        <i-table :columns="columns" :list="list" :nextKey="nextKey" :needCheckBox="false"
            @row-click="getItem" @next-click="getList()" :dense="true"/>
    </article>
</template>
<script>
import CONSTANTS from '@/constants'
import { FormatDate, FormatTime } from '@/util'

import StockSearch from '@/components/common/StockSearch'
import Button from '@/components/common/Button'
import Select from '@/components/common/Select'
import Table from '@/components/common/Table'

import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    components: {
        'i-stock-search': StockSearch,
        'i-btn': Button,
        'i-select': Select,
        'i-table': Table
    },
    data () {
        return {
            condition: {
                corpCls: null,
                corpClsOptions: [{ label: '전체', value: null }],
                stock: ''
            },
            columns: [
                { name: 'rceptDt', label: '접수일시', align: 'left', field: row => `${FormatDate(row.rceptDt)} | ${FormatTime(row.rceptTm)}`  },
                { name: 'corpCls', label: '회사구분', align: 'left',
                    field: row => {
                        var corpcls = CONSTANTS.CORPCLS_TYPE.find(v => v.value == row.corpCls)
                        return corpcls ? corpcls.label : row.corpCls
                    }
                },
                { name: 'corpName', label: '회사명', align: 'left', field: row => row.corpName },
                { name: 'stockCode', label: '종목코드', align: 'left', field: row => row.stockCode },
                { name: 'reportNm', label: '보고서명', align: 'left', field: row => row.reportNm },
            ]
        }
    },
    created () {
        this.condition.corpClsOptions.push(...CONSTANTS.CORPCLS_TYPE)
        this.condition.corpCls = this.condition.corpClsOptions[0]

        this.INIT_LIST()
        this.getList()
    },
    computed : {
         ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
        }),
        ...mapState( 'stock_discl', ['list', 'nextKey'])
    },
    methods: {
        ...mapMutations( 'stock_discl', ['INIT_LIST', 'SET_ITEM']),
        ...mapActions( 'stock_discl', ['GET_LIST','DELETE_ITEMS']),

        goNext() {
            this.pagination.nextCount++
            this.pagination.page = this.pagination.nextCount * 5 + 1
            this.getList()
        },
        // 회사구분에 따른 목록 또는 종목 없이 목록 조회하기
        getSearch() {
            this.condition.stock = ''

            this.INIT_LIST()
            this.getList()
        },
        // 목록 조회하기
        getList() {
            var params = {}
            params.corpCls = this.condition.corpCls.value

            if (this.condition.stock) {
                params.stockCode = this.condition.stock.code
            }

            this.GET_LIST(params)
        },
        // 목록 조회하기 - 종목코드
        getStock(stock) {
            this.condition.stock = stock

            this.INIT_LIST()
            this.getList()
        },
        // 사이트 이동하기
        getItem(item) {
            window.open(`http://dart.fss.or.kr/dsaf001/main.do?rcpNo=${item.rceptNo}`)
        }
    }
}
</script>