var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('q-table',{staticClass:"i-table",class:{ 'no-data': _vm.list.length == 0 },style:(_vm.heightStyle),attrs:{"flat":"","bordered":"","dense":"","columns":_vm.columns,"data":_vm.list,"row-key":"index","virtual-scroll":_vm.verticalScroll,"pagination":_vm.pagination,"hide-pagination":"","no-data-label":"조회되는 데이터가 없습니다.","selected-rows-label":_vm.getSelectedString,"selection":"multiple","selected":_vm.selected},on:{"update:pagination":function($event){_vm.pagination=$event},"update:selected":function($event){_vm.selected=$event}},scopedSlots:_vm._u([{key:"header",fn:function(props){return [_c('q-tr',{attrs:{"props":props}},[(_vm.needCheckBox)?_c('q-th',{staticClass:"text-left"},[_c('q-checkbox',{attrs:{"size":"xs"},model:{value:(_vm.allCheck),callback:function ($$v) {_vm.allCheck=$$v},expression:"allCheck"}})],1):_vm._e(),_vm._l((props.cols),function(col){return _c('q-th',{key:col.name,attrs:{"props":props}},[_vm._v(" "+_vm._s(col.label)+" ")])})],2)]}},{key:"body",fn:function(props){return [_c('q-tr',{class:[(_vm.test_row == props.row.index && _vm.test_row != '' && _vm.test_row != undefined ? 'active_row' : '')],attrs:{"props":props},on:{"click":function($event){_vm.haveSubItem
                        ? (props.expand = !props.expand)
                        : _vm.getItem(props.row)}}},[(_vm.needCheckBox)?_c('q-td',{staticClass:"text-left"},[_c('q-checkbox',{attrs:{"size":"xs","disable":props.row.crud == 'D'},on:{"input":function($event){return _vm.handleCheckBox(props.row)}},model:{value:(props.row.checked),callback:function ($$v) {_vm.$set(props.row, "checked", $$v)},expression:"props.row.checked"}})],1):_vm._e(),_vm._l((props.cols),function(col){return _c('q-td',{key:col.name,class:col.class,attrs:{"props":props}},[_vm._v(" "+_vm._s(col.value)+" ")])})],2),_c('q-tr',{directives:[{name:"show",rawName:"v-show",value:(props.expand),expression:"props.expand"}],attrs:{"props":props}},[_c('q-td',{attrs:{"colspan":"100%"}},[_c('div',{staticClass:"text-left",staticStyle:{"word-break":"break-all","min-width":"500px","white-space":"normal"}},[_vm._v(" "+_vm._s(props.row.content)+" ")])])],1),_c('q-tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSubContent),expression:"showSubContent"}],attrs:{"props":props},on:{"click":function($event){_vm.haveSubItem
                            ? (props.expand = !props.expand)
                            : _vm.getItem(props.row)}}},[_c('q-td',{staticClass:"bg-grey-5"},[_vm._v(" 내용 ")]),_c('q-td',{staticClass:"bg-grey-3",attrs:{"colspan":"100%"}},[_c('div',{staticClass:"text-left",staticStyle:{"word-break":"break-all","white-space":"normal"}},[_vm._v(" "+_vm._s(props.row.newsType1 === 'STOCK_FEATURED' || props.row.newsType1 === 'STOCK_AM_FEATURED' || props.row.newsType1 === 'STOCK_PM_FEATURED' ? props.row.title : props.row.content)+" ")])])],1)]}},{key:"no-data",fn:function(ref){
                            var icon = ref.icon;
                            var message = ref.message;
return [_c('div',{staticClass:"full-width row flex-center q-gutter-sm"},[_c('q-icon',{attrs:{"size":"2em","name":icon}}),_c('span',[_vm._v(_vm._s(message))])],1)]}}])}),(_vm.showPagenation)?_c('div',{staticClass:"row justify-center q-mt-md"},[_c('q-pagination',{attrs:{"max":_vm.pagesNumber,"max-pages":_vm.pagination.maxPages},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}),_c('div',[(_vm.checkNextKey && _vm.nextKey)?_c('q-btn',{attrs:{"flat":"","icon":"navigate_next","color":"dark"},on:{"click":function($event){return _vm.goNext()}}}):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }