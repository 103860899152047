import api from '../../../services/InfostockService'
import Debug  from '../../../debug'

const state = () => ({
    data:[],
})

const mutations = {
    SET_CONSEN(state, {data} ) {
        state.data = data
    },    
}

const actions = {

    async SETTING_LIST({ commit }, params) {
        const {data} = await api.earningPreview(params)
        commit('SET_CONSEN', {data} )
    },  
    async DELETE_PAPER({ commit }, params) {
        const {data} = await api.earningPaperDelete(params)
        commit('SET_CONSEN', {data} )
    },   
    async DELETE_EARNING({ commit }, params) {
        const {data} = await api.earningDelete(params)
        commit('SET_CONSEN', {data} )
    },         
    async NES_RESERVE(_, params) {
        Debug.log('NES_RESERVE params', params)
        const { data } = await api.earningReserve(params)
        Debug.log('NES_RESERVE data', data)
    
    }
}

const getters = {

}

export const preview = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
