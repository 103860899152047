<template>
    <section class="row">
        <i-list class="col-6 q-pa-md" />
        <i-content class="col-6 q-pa-md" />
        <i-resource v-if="showResource" class="col-6 q-pa-md"  />
    </section>
</template>
<script>
import List from '@/components/flash/List.vue'
import Content from '@/components/flash/news-flash/Content.vue'
import Resource from '@/components/flash/news-flash/Resource.vue'
export default {
    name: 'NewsFlash',
    components: {
        'i-list' : List,
        'i-content' : Content,
        'i-resource' : Resource,
    },
    data() {
        return {
            showContent: false,
            showResource: false,
            showPreview: false
        }
    },
    created () {
        var name = this.$router.currentRoute.name
        switch(name) {
            case 'Default':
                this.showResource = true
            break
            case 'News':
                this.showContent = true
            break
            // case 'Send':
            //     this.showPreview = true
            // break
        }
    },
}
</script>

<style>
    /* common */

    /* xs */
    @media only screen and (max-width: 599px) {
    }

    /* sm */
    @media only screen and (max-width: 1023px) {
    }

    /* md */
    @media only screen and (max-width: 1439px) {
    }

    /* lg */
    @media only screen and (max-width: 1919px) {
    }

    /* xl */
    @media only screen and (min-width: 1920px) {
    }

</style>


