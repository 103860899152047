import api from '../../../services/InfostockService'
import Debug  from '../../../debug'
import { FormatDate, isEmpty  } from '../../../util'
const state = () => ({
    items: [],
    item: null,
    nextKey: null,
    closedTypes: [],
    countries : []
})

const mutations = {
    // setList(state,  data) {
    //     state.nextKey = data.nextKey
    //     state.items = data.items
    // },
    /* 
        220412 meej 스케줄 : paging 오류 수정
    */
    SET_LIST(state, { data }) {
        var arr = state.items
        arr.push(...data.items)

        arr.forEach((row, index) => {
            row.index = index
        })

        state.items = arr
        state.nextKey = data.nextKey
    },
    SET_ITEM(state,  data) {
        state.item = data
    },
    INIT_LIST(state) {
        state.items = []
        state.nextKey = null
    },
    setOptions(state,  data) {
        state.closedTypes = data.types
        state.countries = data.countries
    },
}

const actions = {
    async ASYNC_LIST({ rootState, commit}, params) {
        /* 
            220412 meej nextKey 추후 수정 필요 : state의 nextKey초기화 오류
        */
        // params.nextKey = state.nextKey
        const { data } = await api.scheduleList(params)
        // 데이터 가공
        data.items.forEach((row) => {
            
            row.category = rootState.resource.EventNewsType.find(v => v.value === row.newsType1).name

            /* 
                220422 meej category : 메모 일때 formatDate 안씀
            */
            if(row.category == '메모'){
                var yyyy = row.eventDate.substring(0, 4)
                var mm = row.eventDate.substring(4, 6)
                row.date = yyyy+'-'+mm
                row.memoYear = yyyy
                row.memoMonth = mm
            }else{
                row.date = FormatDate(row.eventDate)
                // if(!isEmpty(row.endDate)) {
                    if(row.endDate) {
                    row.date += '~'+ FormatDate(row.endDate)
                }
            }
        })
        // state.nextKey = data.nextKey
        commit('SET_LIST', { data })
    },
    GET_LIST({ rootState}, params) {
        return new Promise((resolve, reject) => {
            api.scheduleList(params)
                .then((response) => {
                let list = response.data.items;
                list.forEach((row) => {
                    row.category = rootState.resource.EventNewsType.find(v => v.value === row.newsType1).name

                    /* 
                        220422 meej category : 메모 일때 formatDate 안씀
                    */
                    if(row.category == '메모'){
                        var yyyy = row.eventDate.substring(0, 4)
                        var mm = row.eventDate.substring(4, 6)
                        row.date = yyyy+'-'+mm
                        row.memoYear = yyyy
                        row.memoMonth = mm
                    }else{
                        row.date = FormatDate(row.eventDate)
                        if(!isEmpty(row.endDate)) {
                            row.date += '~'+ FormatDate(row.endDate)
                        }
                    }
                })
                resolve(list)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    WRITE_ITEMS(_,params) {
        Debug.log('WRITE_ITEMS params', params)
        return new Promise((resolve, reject) => {
            api.scheduleWrite(params)
                .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    DELETE_ITEMS(_,params) {
        Debug.log('DELETE_ITEMS params', params)
        return new Promise((resolve, reject) => {
            api.scheduleDelete(params)
                .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    MULIT_DELETE_ITEMS(_,params) {
        Debug.log('DELETE_ITEMS params', params)
        return new Promise((resolve, reject) => {
            api.scheduleMultiDelete(params)
                .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    ORDER(_,params) {
        return new Promise((resolve, reject) => {
            api.scheduleOrder(params)
                .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    WRITE_CLOSED(_,params) {
        Debug.log('WRITE_CLOSED params', params)
        return new Promise((resolve, reject) => {
            api.closedWrite(params)
                .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    async ASYNC_CLOSED_OPTIONS({commit}) {
        const { data } = await api.closedOptions()
       
        commit('setOptions', data)
    },
    async ASYNC_CLOSED_LIST({commit}, params) {
        const { data } = await api.closedList(params)
        // 데이터 가공

        data.items.forEach((row) => {
            row.date = FormatDate(row.eventDate)
            if(row.endDate) {
                row.date += '~'+ FormatDate(row.endDate)
            } 
        })
        commit('SET_LIST', { data })
    },
    GET_CLOSED_LIST(_, params) {
        return new Promise((resolve, reject) => {
            api.closedList(params)
                .then((response) => {
                let list = response.data.items;
                list.forEach((row) => {
                row.date = FormatDate(row.eventDate)
                    if(!isEmpty(row.endDate)) {
                        row.date += '~'+ FormatDate(row.endDate)
                    }
                })
                resolve(list)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    GET_LAST_ANNOUNCE(_,params) {
        return new Promise((resolve, reject) => {
            api.lastAnnounce(params)
                .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
        })
    }
}

const getters = {}

export const schedule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
