<template>
    <div>
        <q-table
            flat
            bordered
            dense
            class="i-table"
            :class="{ 'no-data': list.length == 0 }"
            :columns="columns"
            :data="list"
            row-key="index"
            :virtual-scroll="verticalScroll"
            :style="heightStyle"
            :pagination.sync="pagination"
            hide-pagination
            no-data-label="조회되는 데이터가 없습니다."
            :selected-rows-label="getSelectedString"
            selection="multiple"
            :selected.sync="selected"
        >
            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th v-if="needCheckBox" class="text-left">
                        <q-checkbox v-model="allCheck" size="xs" />
                    </q-th>
                    <q-th
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                    >
                        {{ col.label }}
                    </q-th>
                </q-tr>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props" @click="getItem(props.row)">
                    <q-td v-if="needCheckBox" class="text-left">
                        <q-checkbox
                            v-model="props.row.checked"
                            @input="handleCheckBox(props.row)"
                            size="xs"
                            :disable="props.row.crud == 'D'"
                        />
                    </q-td>
                    <q-td
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                        :class="col.class"
                    >
                        {{ col.value }}
                    </q-td>
                </q-tr>
                <!-- 20210913 shb 추가  컨센서스 변경시 [투자의견 변경 : 보유/500000 -> 적극매수/550000] 히스토리가 남는데 왼쪽 목록에도 바로바로 나와야 입력시 확인이 편함 | 수정사항 반영-->
                <q-tr v-show="props.row.content" :props="props">
                    <q-td colspan="100%">
                        <div
                            class="text-left"
                            style="word-break:break-all; min-width:500px;white-space: normal;"
                            :style="
                                `color: ${TextColor(
                                    props.row.OPINION_UPDOWN0,
                                    props.row.content
                                )}`
                            "
                        >
                            {{ props.row.content }}
                        </div>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:no-data="{ icon, message }"
                ><div class="full-width row flex-center q-gutter-sm">
                    <q-icon size="2em" :name="icon" />
                    <span>{{ message }}</span>
                </div></template
            >
        </q-table>
        <div class="row justify-center q-mt-md" v-if="showPagenation">
            <q-pagination
                v-model="pagination.page"
                :max="pagesNumber"
                :max-pages="pagination.maxPages"
            />
            <div>
                <q-btn
                    v-if="checkNextKey && nextKey"
                    flat
                    icon="navigate_next"
                    color="dark"
                    @click="goNext()"
                />
            </div>
        </div>
    </div>
</template>
<script>
import CONSTANTS from '@/constants'
import debug from '@/debug'
export default {
    name: 'Table',
    props: {
        columns: {
            type: Array,
        },
        list: {
            type: Array,
        },
        nextKey: {
            type: String,
        },
        checkNextKey: {
            type: Boolean,
            default: true,
        },
        haveSubItem: {
            type: Boolean,
            default: false,
        },
        needCheckBox: {
            type: Boolean,
            default: true,
        },
        dense: {
            type: Boolean,
            default: true,
        },
        verticalScroll: {
            type: Boolean,
            default: false,
        },
        heightStyle: {
            type: Object,
        },
        showPagenation: {
            type: Boolean,
            default: true,
        },
        rows: {
            default: 1,
        },
    },
    data() {
        return {
            pagination: {
                page: 1,
                rowsPerPage: CONSTANTS.LIST_COUNT_PER_PAGE,
                nextCount: 0,
                maxPages: CONSTANTS.DEFAULT_PAGE_NUM
            },
            allCheck: false,
            selected: [],
            checkBuySell: // js 파일로 따로 빼면 좋을 증권사별 의견 데이터
            [
                {key:'Strong BUY', val: 1}, 
                {key:'STRONG BUY', val: 1}, 
                {key:'BUY***', val: 1},
                {key:'BUY', val: 2}, 
                {key:'Buy', val: 2},
                {key:'매수', val: 2},
                {key:'Trading BUY', val: 3},
                {key:'Trading Buy', val: 3},
                {key:'HOLD', val: 4}, 
                {key:'Hold', val: 4},
                {key:'중립', val: 4},   
                {key:'보유', val: 4}, 
                {key:'Neutral', val: 4},  
                {key:'Outperform', val: 5},
                {key:'비중축소', val: 5}, 
                {key:'Reduce', val: 5}, 
                {key:'REDUCE', val: 5},
                {key:'Marketperform', val: 6}, 
                {key:'MARKETPERFORM', val: 6},
                {key:'Underperform', val: 7},
                {key:'매도', val: 10}, 
                {key:'Sell', val: 10}, 
                {key:'SELL', val: 10}, 
                {key:'SELL***', val: 10}, 
                {key:'NotRated', val: 100},
            ],
        }
    },
    created() {
        if (this.rows > CONSTANTS.LIST_COUNT_PER_PAGE) {
            this.pagination.rowsPerPage = this.rows
        }
    },
    computed: {
        pagesNumber() {
            return ((this.list.length / this.pagination.rowsPerPage) < (this.pagination.rowsPerPage * this.pagination.maxPages)?Math.ceil(this.list.length / this.pagination.rowsPerPage):(this.list.length / this.pagination.rowsPerPage))
            //return Math.ceil(this.list.length / this.pagination.rowsPerPage)
        },
    },
    methods: {
        getSelectedString() {
            debug.log('getSelectedString = ', this.selected.length)
            return this.selected.length === 0
                ? ''
                : `${this.selected.length}개 선택`
        },
        getItem(item) {
            //            debug.log('getItem item = ', item)
            this.$emit('row-click', item)
        },
        goNext() {
            this.pagination.nextCount++
            this.pagination.page = this.pagination.nextCount * 5 + 1
            this.$emit('next-click')
        },
        handleCheckBox(item) {
            if (item.checked) {
                this.selected.push(item)
            } else {
                var index = this.selected.indexOf(item)
                if (index !== -1) {
                    this.selected.splice(index, 1)
                }
            }
            this.$emit('check-event', this.selected)
        },
        parentClearCheck() {
            this.selected = []
            this.list.forEach((i) => {
                i.checked = false
            })
            //this.allCheck = false
        },
        /*
         * 20210722 shb
         * 히스토리 텍스트 상향,하향 구분하여 파란색,빨간색 넣기
         * 20211202 suki : 투자의견으로 색상 결정되게 변경
         *   newsOption 에 OPINION_UPDOWN0 로 판단 (0 보다 크면 up, 0보다 작으면 down, 0 이면 동일)
         */
        // 22.02.04 한수예 수정 
        TextColor(upDown, text) {
            var firstNum = 0
            var secondNum = 0
            console.log('[text] ', text)
            console.log('[upDown] ', upDown)
            const re = new RegExp('[^\\d]', 'g')
            if (text !== undefined && text !== null && text !== '') {
                var text2 = text.split(':')
                var text3 = text2[1].split('/')
                var firstTxt = text3[0].trim();
                var text4 = text3[1].split('->')
                var secondTxt = text4[1].trim();
                this.checkBuySell.forEach(check => {
                    firstNum = ( firstTxt == check.key ) ? check.val : firstNum
                    secondNum = ( secondTxt == check.key ) ? check.val : secondNum
                });
                if (firstNum != 0 && secondNum != 0 && firstNum != secondNum) { 
                    console.log('firstNum: ', firstNum, 'secondNum: ', secondNum);
                    if ((firstNum - secondNum) > 0) {
                        return 'red'
                    } else if ((firstNum - secondNum) < 0) {
                        return 'blue'
                    } else if (firstNum == secondNum) {
                           if (upDown !== undefined && upDown !== null) {
                            let check = parseInt(upDown)
                            if (check > 0) return 'red'
                            else if (check < 0) return 'blue'
                            else {
                                return ''
                            }
                        }
                    }
                }
                const arr = text.split('->')
                console.log('arrrr : ', arr)
                if (arr.length > 1) {
                    const be = parseInt(arr[0].replace(re, ''))
                    console.log('be: ', be)
                    const af = parseInt(arr[1].replace(re, ''))
                    console.log('af: ', af)
                    if (be - af <= -1) {
                        // 증가
                        return 'red'
                    } else if (be - af > 0) {
                        // 감소
                        return 'blue'
                    } else {
                        return ''
                    }
                }
            } else {
                if (upDown !== undefined && upDown !== null) {
                            let check = parseInt(upDown)
                            if (check > 0) return 'red'
                            else if (check < 0) return 'blue'
                            else {
                                return ''
                            }
                        }
                }   
        },
    },
    watch: {
        allCheck: function(val) {
            this.selected = []
            if (val) {
                const start =
                    this.pagination.rowsPerPage * (this.pagination.page - 1)
                const last = Math.min(
                    this.pagination.rowsPerPage * this.pagination.page,
                    this.list.length
                )
                debug.log('start!!!', start, ', last = ', last)
                for (let i = start; i < last; i++) {
                    this.list[i].checked = val
                    this.selected.push(this.list[i])
                }
            } else {
                this.list.forEach((i) => {
                    i.checked = false
                })
            }
            this.$emit('check-event', this.selected)
        },
        list: function() {
            debug.log('table watch list!!!!!!!!!')
            this.allCheck = false
            this.selected = []
            this.list.forEach((i) => {
                i.checked = false
            })
        },
    },
}
</script>
