<template>
    <article>
        <div id="preview">
            <!-- 221012 meej 금일 전송된 속보 목록 추가 -->
            <div v-if="(flash.sendData.stockNewsList != undefined && flash.sendData.stockNewsList != null && flash.sendData.stockNewsList.length > 0)
                    || (flash.sendData.themeNewsList != undefined && flash.sendData.themeNewsList != null && flash.sendData.themeNewsList.length > 0)"
                 class="q-ma-xs" style="border: 1px solid #dee2e6;border-radius: 0.25rem;">
                <div class="q-ma-xs bg-grey-4 " style="font-weight:bold">
                    <span class="q-ml-xs"> 금일 전송된 속보 목록</span>
                </div>
                <div class="q-ma-xs" v-for="(item, index) in flash.sendData.themeNewsList" :key="index">
                    [{{item.sendTime}}] {{item.title}}
                </div>
                <div class="q-ma-xs" v-for="(item, index) in flash.sendData.stockNewsList" :key="index">
                    [{{item.sendTime}}] {{item.title}}
                </div>
            </div>

            <div class="q-ma-xs text-center">
                <h5>{{ title }}</h5>
            </div>

            <div class="flex justify-start">
                <p style="font-size:11pt"><b>관련종목 : {{ $route.query.stockname }}({{ $route.query.stockcode }})</b></p>
            </div>
            <div class="row q-gutter-xs q-ma-sm justify-end">
                <i-btn label="취소" @click="cancelFunc()" />
                <i-btn label="보내기" @click="send()" />
            </div>
            <p
                class="q-pa-xs q-ma-xs"
                style="overflow-y:scroll; width:600px; height:100%; background-color:white; border:1px solid; "
                v-html="content"
            />
        </div>
    </article>
</template>
<script>
import Debug from '@/debug'

import Button from '@/components/common/Button'
//import Input from '@/components/common/Input'
import { FormatDateTime, FormatTimeSeconds } from '@/util'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
// import router from '../../router'

// 뉴스속보 isPopup = true
// 증시요약 isPopup = false
export default {
    name: 'AutoFlashPopup',

    components: {
        'i-btn': Button,
        //        'i-input' :  Input,
    },
    props: {
        flashData: null,
        isPopup: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            progress: 0,
            stockList: [],
            // title:'',
            // content:'',
            // sendTime: '',
        }
    },
    created() {
        // 전송시간 오늘날짜로 설정
        this.setStatus(0)
        // Debug.log("Preview Popup created!! preview =", this.item.preview)
        // this.content = this.item.preview
        // this.title = this.item.title
        // this.sendTime = FormatDateTime(this.item.sendTime)
        let params = {}
        params.newsType = 'MARKET_FLASH_STOCK'
        params.title = ' '+this.$route.query.title
        params.stocks = this.$route.query.stockcode
        this.WRITE_FLASH(params)
            .then((response) => {
                Debug.log('success', response)
                Debug.log('Preview Popup created!! preview =', this.flash)
                // 221012 meej 종목 속보 중복기사 체크, 시간 형식 표기 추가
                if(params.newsType == 'MARKET_FLASH_STOCK'){
                    if(response.sendData.stockNewsList != undefined 
                        && response.sendData.stockNewsList.length > 0){
                        response.sendData.stockNewsList.forEach((el)=>{
                            el.sendTime = FormatTimeSeconds(el.sendTime)
                        })
                        this.showMessageAlert(response, params.newsType)
                    }
                }
            })
            .catch((error) => {
                this.refreshList = true
                Debug.log('error', error)
                this.showAlert({ vc: this, message: error })

            })
        
        // this.content = this.item.content
        // this.title = this.item.title
        // this.sendTime = FormatDateTime(this.item.sendTime)
    },
    mounted() {
        this.progress = 100
        //this.initStocks()
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            flash: (state) => state.flash.preview,
            stockItems: (state) => state.resource.stockItems,
        }),
        ...mapGetters('calendar', ['today']),
        content() {
            return this.flash.content
        },
        title() {
            return this.flash.title
        },
        // stocks() {
        //     return this.flash.sendData.stock_codes
        // },
        sendTime() {
            return FormatDateTime(this.flash.sendTime)
        },
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
            setStatus: 'flash/SET_STATUS',
            initPreview: 'flash/INIT_PREVIEW',
        }),
        ...mapActions('flash', ['WRITE_FLASH','SEND_FLASH']),
        ...mapActions('alert', ['showError', 'showAlert']),

        // 221012 meej 종목 속보 중복기사 체크
        showMessageAlert(response, type) {
            const stockNews = response.sendData.stockNewsList
            const stockMsg = '<span style="color:blue;font-weight:bold">금일 종목속보 전송됨.</span> <br/><br/>다시 전송하시겠습니까?'
            if(type == 'MARKET_FLASH_STOCK'){
                // 종목속보
                if(stockNews != undefined && stockNews.length > 0){
                    this.showAlert({ vc: this, message: stockMsg })
                    .then(() => { 
                        return
                    })
                    .catch(()=>{
                        // 221012 meej 현재창 닫기
                        this.close();
                    })
                } 
            }
        },
        // 221012 meej 현재창 닫기
        close(){
            window.close();
            return false;
        },
        initStocks() {
            if (
                this.flash &&
                this.flash.sendData &&
                this.flash.sendData.stock_codes
            ) {
                Debug.log('this.flash.sendData.stock_codes', this.flash.sendData.stock_codes)
                // let count = this.flash.sendData.stock_codes.split(';').length - 1;
                let codeList = null;
                if (this.flash.sendData.stock_codes.substr(-1,this.flash.sendData.stock_codes.length)==';'){
                    codeList = this.flash.sendData.stock_codes.substr(0,this.flash.sendData.stock_codes.length-1).split(';')
                }
                else{
                    codeList = this.flash.sendData.stock_codes.split(';')
                }
                Debug.log('codeList = ', codeList)
                // }
                // else{
                //     codeList = this.flash.sendData.stock_codes.split(';')
                // }
                this.stockList = []
                if (codeList) {
                    for (let index in codeList) {
                        Debug.log('code = ', codeList[index])
                        if(index > 4) break
                        const stock = this.stockItems.filter(function(entry) {
                            return entry.code === codeList[index]
                        })
                        Debug.log('stock = ', stock)
                        this.stockList.push(stock[0])
                    }
                    Debug.log('stockList = ', this.stockList)
                }
            }
        },
        send() {
            let params = { ...this.flash }

            if (!this.isPopup) {
                if (this.flash.stocks && this.flash.stocks.length > 0) {
                    var stocks = this.flash.stocks.map((obj) => obj.code)
                    params.stocks = stocks.join(',')
                }
            }

            console.log(params)
            this.showAlert({
                vc: this,
                message: '속보를 전송하시겠습니까?',
            }).then(() => {
                // this.setStatus(1)
                this.SEND_FLASH(params)
                    .then(() => {
                        this.clearPreview()

                        if (this.isPopup) {
                            this.setStatus(1)
                            window.close()
                        } else {
                            this.$parent.$emit('initCheckItem', null)
                        }
                    })
                    .catch((err) => {
                        this.showError({ vc: this, error: err })
                    })
            })
        },
        cancelFunc() {
            this.clearPreview()
            if (this.isPopup) {
                this.setStatus(0)
                window.close()
            } else {
                this.$parent.$emit('initCheckItem', null)
            }
        },
        clearPreview() {
            this.initPreview()
        },
    },
    watch: {
        stockItems(val) {
            if (val) {
                this.initStocks()
            }
        },
    },
}
</script>
<style>
#preview {
    text-align: left;
}
</style>
