<template>
    <div class="row">
        <div v-if="date" class="col-12" :class="{ 'col-lg-6': time }">
            <q-input
                :outlined="!disable"
                :standout="disable ? 'bg-teal text-white' : false"
                type="date"
                dense
                v-model="dateModel"
                :disable="disable"
                @input="inputDateHandler"
            >
                <template v-slot:before>
                    <label v-if="dateLabel" class="i-label">
                        {{ dateLabel }}
                    </label>
                </template>
            </q-input>
        </div>
        <div v-if="time" class="col-12" :class="{ 'col-lg-6': date }">
            <q-input
                :outlined="!disable"
                :standout="disable ? 'bg-teal text-white' : false"
                type="time"
                dense
                v-model="timeModel"
                :disable="disable"
                @input="inputTimeHandler"
            >
                <template v-slot:before>
                    <label v-if="timeLabel" class="i-label">
                        {{ timeLabel }}
                    </label>
                </template>
            </q-input>
        </div>
    </div>
</template>
<script>
/*
    v-model - full: yyyyMMddHHmmss, date: yyyyMMdd, time - HHmm(ss)
    - 기본 사용: <i-datetime v-model="modelName" label="labelName" />
    1) 날짜만 원할 경우, date 속성 추가
        ex. <i-datetime date />
    2) 시간만 원할 경우, time 속성 추가
        ex. <i-datetime time />
    3) 날짜 시간 둘다 사용하고 싶은 경우, date, time 속성 추가
        ex. <i-datetime date time />
    4) disable 속성이 필요한 경우
        ex. <i-datetime :disable="value" />
    5) disable 속성이 무조건 true인 경우
        ex. <i-datetime disable />

*/
import debug from '@/debug'
import {
    today,
    nowTime,
    isEmpty,
    FormatDate,
    FormatTime,
    rawDateTime,
} from '@/util'
export default {
    name: 'DateTime',
    props: {
        value: {
            type: String,
        },
        dateLabel: String,
        timeLabel: String,
        disable: {
            type: Boolean,
            default: false,
        },
        date: {
            type: Boolean,
            default: false,
        },
        time: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dateModel: '',
            timeModel: '',
            tempDateModel: '', // 22.09.21 suye dateModel 임시저장값
            timeForFlash: '',
        }
    },
    created() {
        //TODO :현재날짜 시간 설정
        //debug.log('created value (', this.value,')')
        this.setDateTimeModel()
        this.timeForFlash = ''
    },
    methods: {
        setDateTimeModel() {
            //            debug.log('setDateTimeModel this.value (', this.value,')')
            let now = this.value
            if (isEmpty(now)) {
                now = ''
                if (this.date) now += today()
                if (this.time) now += nowTime()
                //                debug.log('setDateTimeModel now (', now,')')
                this.$emit('input', now)
                return
            }
            let val = rawDateTime(now)
            if (this.date) {
                this.dateModel = FormatDate(val.substring(0, 8))
                // console.log('dateModel = ', this.dateModel)
                this.tempDateModel = this.dateModel // 22.09.21 suye dateModel 임시저장값
            }
            if (this.date && this.time) {
                this.timeModel = FormatTime(val.substring(8))

                // 22.09.23 suye 속보 전송시간 < 현재시간일 경우 현재시간으로 변경 
                // 초단위 제거 
                let valSub = val.substr(8, 4) 
                let nowTimeSub = nowTime().substring(0, 4)
                // console.warn('this.timeModel 현재시간:', nowTimeSub, '전송시간:', valSub, '현재시간<=전송시간 ?', nowTimeSub <= valSub) // 110616 20220923145500 14:55
                // false일 때 현재시간으로 변경
                if(!(nowTimeSub <= valSub)){ 
                    this.timeForFlash = today() + nowTime().substring(0, 4) + '00'  
                    this.$emit('setCurrentTime', this.timeForFlash)
                } else {
                    this.timeForFlash = ''
                }

            } else if (this.time) {
                this.timeModel = FormatTime(val)
            }
            //            debug.log('setDateTimeModel dateModel (', this.dateModel,')')
            //            debug.log('created timeModel (', this.timeModel,')')
        },
        inputDateHandler(val) {
            //            debug.log("inputDateHandler (", val, ")");
            let newDate = rawDateTime(val)
            // 22.09.21 suye 시작일자 eventDate 입력란 월,일에 0입력시 값 인식불가로 초기화되는 현상 수정 
            let tempDate = this.tempDateModel
            if(val == '' || val.length == 0 || val == undefined){ 
                // console.log('tempDateModel = ', this.tempDateModel)
                newDate = tempDate.replace(/-/g, '') 
            } 
            if (this.time) {
                newDate += rawDateTime(this.timeModel) + '00'
            }
            debug.log('inputDateHandler input (', newDate, ')')
            this.$emit('input', newDate)
        },
        inputTimeHandler(val) {
            //            debug.log("inputTimeHandler (", val, ")");
            let newDate = ''
            if (this.date) {
                newDate = rawDateTime(this.dateModel)
            }
            newDate += rawDateTime(val) + '00'
            debug.log('inputTimeHandler input (', newDate, ')')
            this.$emit('input', newDate)
        },
    },
    watch: {
        value: function(val, old) {
            if (val !== old) {
                this.setDateTimeModel()
            }
        },
    },
}
</script>
