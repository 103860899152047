<template>
    <article>
        <q-dialog v-model="reportPopup" persistent>
            <q-card style="min-width: 70%;min-height:50%;" >
                <q-card-section >
                    <div class="row q-my-sm">
                        <div class="col-12 col-sm-4">
                            <div style="padding: 10px 0px 20px 30px;"><label class="i-label" style="color:gray; font-size: 15px;">담당자 {{ work_person }}</label></div>
                        </div>

                        <div class="col-12 col-sm-4">
                            <div style="padding: 10px 0px 20px 30px;"><label class="i-label" style="color:gray; font-size: 15px">업무일자 {{ result_date }}</label></div>
                        </div>
                    </div>

                  
                    <body class="wrap">
                        <div>
                            <div class="row q-my-sm" style="padding:5px 20px 10px 10px; font-size: 15px; font-family:Tahoma, Arial;"> <div style="color:gray; font-size: 15px; padding-right: 10px; ">난이도</div> {{level}}</div>
                            <div class="row q-my-sm" style="padding:5px 20px 10px 10px; font-size: 15px; font-family:Tahoma, Arial;"> <div style="color:gray; font-size: 15px; padding-right: 10px; ">상태</div> {{state}}</div>
                            <div class="row q-my-sm" style="padding:5px 20px 10px 10px; font-size: 15px; font-family:Tahoma, Arial;"> <div style="color:gray; font-size: 15px; padding-right: 10px; ">결과</div> <div v-html="content2"></div></div>

                        </div>
                    </body>
                </q-card-section>
                <q-card-actions align="center" class="text-primary">
                    <!-- <div class="q-pa-xs"><q-btn label="등록" @click="getUpdatePopupSave()"/></div> -->
                    <q-btn color="white" text-color="black" label="닫기" v-close-popup/>
                </q-card-actions>
            </q-card>
        </q-dialog>
    </article>
</template>
<script>
// import Select from "@/components/common/Select";
import { mapState, mapActions } from 'vuex'
import { FormatDate} from '@/util'

// import DateTime from '@/components/common/DateTime'

export default {
    name: 'Workmanager-List',
    props: {
        hide_selected: {
            type: Boolean,
            default: true
        }
    },
    components: {
        // 'i-select' : Select,
        // 'i-datetime': DateTime,
        // "i-input": Input,
    },
    data () {
        return {
            reportPopup: false,
            level: '',
            result_date: '',
            work_person: '',
            result: '',
            state: '',
            progress_id: '',
            // state: '',
            // file: {'value':'', 'name':'파일'},
        }
    },
    computed : {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
        }),
        content2() {return this.result.replace("\n", "<br />")}
    },
    // created () {
    //     this.getInit();
    // },
    methods: {

        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),

        initList() {

        },
        // initData() {
        //     this.level = '';
        //     this.result_date ='';
        //     this.work_person ='';
        //     this.result = '';
        //     this.state = '';
        //     this.progress_id = '';
        // },

        //수정팝업
        getReportPopup(item) {
            let param = {
                'progress_id': item.progress_id,
                'CALL_API_URL': '/request/result/detail'
            }
            
            this.GET_CALL(param).then((data) => {
                this.level = data.level,
                this.result_date = FormatDate(data.result_date),
                this.work_person = data.work_person,
                this.result = data.result,
                this.state = data.state,
                this.reportPopup = true;
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },

    //    projectHandler(e) {
    //             console.log(e)
    //         },

        //수정팝업
        // getUpdatePopupSave() {
        //     console.log("저장클릭됨")
        // },
    }

}

</script>
<style>

.wrap {
    border: 1px solid #C3C3C3;
    padding: 0px 10px 10px 10px;
}
</style>