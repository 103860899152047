import Router from '../../../router'
import Debug  from '../../../debug'

const state = () => {
    return {
        params: null
    }
}

const actions = {
    push ({ commit }, location) {
        commit('clear')
        Debug.log('history push = ', location)
        Router.push({ path: "/" + location }).catch(err => {
            if (err.name != "NavigationDuplicated") {
                throw err;
            }
         })
    },
    push_params ({ commit }, { location, params}) {
        commit('push', params)
        Debug.log('history push params= ', location+' '+ params)
        Router.push({ path: "/" + location }).catch(err => {
            if (err.name != "NavigationDuplicated") {
                throw err;
            }
        })
    },
    login_page ({ commit }) {
        commit('clear')
        Router.replace({ path: '/login.htm' }).catch(err => {
            if (err.name != "NavigationDuplicated") {
                throw err;
            }
        })
    },
    main_page () {
        Router.replace({ path: '/main.htm' }).catch(err => {
            if (err.name != "NavigationDuplicated") {
                throw err;
            }
        })
    },
    replace ({ commit }, location) {
        commit('clear')
        Debug.log('history replace = ', location)
        Router.replace({ path: '/' + location }).catch(err => {
            if (err.name != "NavigationDuplicated") {
                throw err;
            }
        })
    },
    replace_params ({ commit }, { location, params }) {
        commit('push',params)
        Debug.log('history replace, params = ', location)
        Router.replace({ path: "/" + location, params: params }).catch(err => {
            if (err.name != "NavigationDuplicated") {
                throw err;
            }
        })
    }
}
const mutations = {
    push (state, params) {
        state.params = params
    },
    clear (state) {
        state.params = null
    },
}
export const history = {
    namespaced: true,
    state,
    actions,
    mutations
}