<template>
    <article>
        <!-- 구분 -->
        <div v-show="newsType.value == 'BOARD_SCHEDULE_FORM'"
            class="flex justify-start q-pa-xs q-gutter-sm">
            <i-btn label="삭제" @click="deleteItems()" />
        </div>
        <!-- 목록 -->
        <i-table
            :columns="columns"
            :list="list"
            :nextKey="nextKey"
            @row-click="getItem"
            @next-click="getList()"
            @check-event="handleCheckList"
            :needCheckBox="newsType.value == 'BOARD_SCHEDULE_FORM' ?  true : false"
        />
    </article>
</template>
<script>
import Debug from '@/debug'
import { FormatDate } from '@/util'
import CONSTANTS from "@/constants";
import Button from '@/components/common/Button'
import Table from '@/components/common/Table'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    components: {
        'i-btn': Button,
        'i-table': Table,
    },
    props: {
        newsType: {
            type: Object,
            default: null,
        },

    },
    data() {
        return {
            columns: [
                {
                    name: 'createTime',
                    label: '등록일자',
                    align: 'left',
                    field: (row) => FormatDate(row.createTime),
                },
                {
                    name: 'flag',
                    label: '카테고리',
                    align: 'left',
                    field: (row) => { const category = CONSTANTS.SCHEDULE_INDEX.find(e => e.value == row.flag)
                        return category.label
                    }
                },
                {
                    name: 'options',
                    label: '형식제목',
                    align: 'left',
                    field: (row) => row.title.split(this.cutPoint)[0],
                },
                {
                    name: 'title',
                    label: '제목',
                    align: 'left',
                    field: (row) => row.title.split(this.cutPoint)[1],
                },
                {
                    name: 'writer',
                    label: '작성자',
                    align: 'left',
                    field: (row) => {
                        return row.writer
                    }
                },
            ],
            category: null,
            selected: [],
            note: Boolean,
        }
    },
    created() {},
    mounted() {
        Debug.log('mounted newsType= ', this.newsType)
        this.INIT_LIST()
        this.getList()
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            cutPoint: (state) => state.manage.cutPoint,
        }),
        ...mapState('manage', ['list', 'nextKey']),

        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isEditPage',
            'isRegisterPage',
        ]),
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('manage', ['INIT_LIST', 'SET_ITEM', 'SET_ITEMS', 'SET_LIST']),

        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions('manage', ['GET_SCHEDULE_FORM_LIST', 'DELETE_ITEMS', 'GET_DETAIL']),

        // 목록 조회하기
        getList() {
            this.setPageStatus(this.PAGE_STATUS.LIST)
            let param = {
                newsType: this.newsType.value,
                nextKey : this.nextKey
            }
            if(this.nextKey == null){
                this.INIT_LIST()
            }
            this.GET_SCHEDULE_FORM_LIST(param)
            .then((data) => {
                if(data.items.length > 0){
                    this.SET_LIST(data)
                    this.getItem(data.items[0])
                }
            })
            .catch((error) => {
                this.showError({ vc:this, error:error })
            })
        },
        // 상세 조회
        getItem(item) {
            this.setPageStatus(this.PAGE_STATUS.DETAIL)
            this.SET_ITEM(item)
        },
        handleCheckList(list) {
            this.selected = list
        },
        // 삭제하기
        deleteItems() {
            if (this.selected.length == 0) {
                this.showMessage({
                    vc: this,
                    message: '삭제할 항목을 선택해주세요.',
                })
                return
            } else {
                this.showAlert({
                    vc: this,
                    message: '선택한 항목을 삭제 하시겠습니까?',
                }).then(() => {
                    this.doDelete()
                })
            }
        },
        doDelete() {
            var params = {}
            params.ids = this.selected.map( x => x.id ).join(',')
            params.force = 1
            this.DELETE_ITEMS(params)
            .then(() => {
                this.showMessage({ vc:this, message:'삭제되었습니다.' })
                this.INIT_LIST()
                this.getList()
            })
            .catch((err) => {
                Debug.log('error = ', err)
                var error = err.message
                this.showError({ vc:this, error:error })
            })
        },
        goRegisterPage() {
            this.setPageStatus(this.PAGE_STATUS.REGISTER)
        },
    },
    watch: {
        newsType(val, old) {
            if (val != old) {
                this.INIT_LIST()
                this.getList()
            }
        },
    },
}
</script>
