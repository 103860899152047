<template>
    <section class="row q-pa-md">
        <q-toolbar class="col-12 flex justify-center">
        <i-select
            label="구분"
            v-model="type"
            :options="optionMaker()"
            class="full-width"
        />
        </q-toolbar>
        <i-list
            class="col-6 q-pl-sm q-pr-sm"
            :newsType="type"
            @opennote="onAoff"
            :noteVisible="noteVisible"
        />
        <i-content
            class="col-6 q-pl-sm q-pr-sm"
            :newsType="type"
            :noteVisible="noteVisible"
        />
    </section>
</template>

<script>
import CONSTANTS from "@/constants";

import Select from "@/components/common/Select.vue";
import List from "@/components/manage/List.vue";
import Content from "@/components/manage/Content.vue";

import { mapState, mapGetters, mapMutations } from "vuex";

export default {
    name: "Notice",
    components: {
        "i-select": Select,
        "i-list": List,
        "i-content": Content,
    },
    data() {
        return {
            type: CONSTANTS.BOARD_TYPE[0],
            typeOptions: CONSTANTS.BOARD_TYPE,
            noteVisible: false,
        };
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
        }),
        ...mapGetters("page", [
            "isListPage",
            "isDetailPage",
            "isEditPage",
            "isRegisterPage",
            "isConfirmPage",
        ]),       

    },
    created(){
    },
    methods: {
        ...mapMutations("page", ["setPageStatus"]),
        onAoff(flag) {
            this.noteVisible = flag;
        },

        // 22.12.23 SUYE 공지사항 구분 스케줄형식 제거 
        optionMaker(){
            const newTypeOption = []
            if(this.typeOptions){
                this.typeOptions.forEach(e => {
                    if(e.value !== 'BOARD_SCHEDULE_FORM') {
                        newTypeOption.push(e)
                    }
                });
            }
            return newTypeOption
        },
    },
};
</script>
