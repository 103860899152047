<template>
    <article>
        <!-- 구분 -->
        <div v-if="isListPage" class="i-top-wrap wrapChangeBox">
            <div class="row">
                <div class="col-12 col-sm-3 q-pa-xs">
                    <i-select
                        v-model="conditionType"
                        :options="condition.options"
                        label="선택"
                        @input="initCondition()"
                    />
                </div>
                <div class="col-12 col-sm-4">
                    <div v-if="isText" class="q-pa-xs" @keyup.enter="getSearchList()">
                        <i-input v-model="condition.keyword" />
                    </div>
                    <div v-else class="q-pa-xs">
                        <i-datetime v-model="condition.keyword" date/>
                    </div>
                </div>
                <div class="col-12 col-sm-1 q-pa-xs flex">
                    <i-btn style="width:100%" icon="search" @click="getSearchList()" />
                </div>
                <div class="col-12 col-sm-2 q-pa-xs flex">
                    <div class="flex">
                        <i-btn label="선택삭제" @click="deleteItems" />
                    </div>
                </div>
                <!-- 
                    220425 meej type : 이벤트일자 일때 순서변경 버튼 보여줌 
                -->
                <div v-if="this.conditionType.value == 'eventDate'" class="col-12 col-sm-2 q-pt-xs q-pb-xs flex justify-end">
                    <div class="flex">
                        <i-btn label="순서변경" @click="orderChange" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 목록 -->
        <div v-if="isListPage">
            <!-- 
                220425 meej type : 이벤트일자 일때 페이징 없는 리스트로 보여줌
             -->
            <i-simple-table v-if="this.conditionType.value == 'eventDate'"
                :columns="columns"
                :list="items"
                @row-click="setItem"
                @check-event="handleCheckList"
            />
            <i-table v-else
                :columns="columns"
                :list="items"
                :nextKey="nextKey"
                @row-click="setItem"
                @next-click="getScheduleList"
                @check-event="handleCheckList"
            />
        </div>
        <div v-else>
            <i-otable
                :isScheduleOrder="isScheduleOrder"
                :columns="orderColumns"
                :list="orders"
                @row-event="setItem"
                @order-event="handleOrderList"
            />
        </div>
    </article>
</template>
<script>
import Debug from "@/debug";
import { FormatDate, isEmpty, CutDownText } from "@/util";

import Table from "@/components/common/Table";
import SimpleTable from '@/components/common/SimpleTable'
import OrderTable from "@/components/common/OrderTable";
import Datetime from "@/components/common/DateTime";
import Input from "@/components/common/Input";
import Select from "@/components/common/Select";
import Button from "@/components/common/Button";
import moment from "moment";

import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "List",
    components: {
        "i-btn": Button,
        "i-table": Table,
        "i-datetime": Datetime,
        "i-input": Input,
        "i-otable": OrderTable,
        "i-select": Select,
        'i-simple-table': SimpleTable,
    },
    props: {
        searchDate: String,
    },
    data() {
        return {
            // 조건
            conditionType: { label: "제목", value: "title" },
            condition: {
                options: [
                    { label: "제목", value: "title" },
                    { label: "입력일자", value: "createDate" }, // 등록일자->입력일자
                    { label: "수정일자", value: "lastUpdateDate" }, // 22.07.07 suye 수정일자 추가
                    { label: "시작일자", value: "startDate" },
                    /*  
                        220425 meej 이벤트일자 검색 추가
                     */
                    { label: "이벤트일자", value: "eventDate" }, 
                    { label: "내용", value: "content" },
                    { label: "작성자", value: "writer" },
                ],
                keyword: null,
            },
            columns: [
                {
                    name: "eventDate",
                    label: "이벤트일자",
                    align: "left",
                    field: (row) => row.date,
                    style: (row) => this.getStyle(row)
                },
                {
                    name: "newsType",
                    label: "카테고리",
                    align: "left",
                    field: (row) => row.category,
                    style: (row) => this.getStyle(row)
                },
                {
                    name: "crud",
                    label: "상태",
                    align: "left",
                    field: (row) => row.crud,
                },
                {
                    name: "title",
                    label: "제목",
                    align: "left",
                    // 220901 meej 제목 글자수 조정
                    // field: (row) => row.title,
                    field: (row) => CutDownText(row.title, 18),
                    style: (row) => this.getStyle(row)
                },
                {
                    name: "createTime",
                    label: "입력일자",
                    align: "left",
                    field: (row) =>
                        `${FormatDate(row.createTime)} ${row.CREATE_WRITER}`,
                    style: (row) => this.getStyle(row)
                },
                {
                    name: "lastUpdateTime",
                    label: "수정일자",
                    align: "left",
                    field: (row) =>
                        `${FormatDate(row.lastUpdateTime)} ${row.writer}`,
                    style: (row) => this.getStyle(row)
                },
            ],
            orderColumns: [
                {
                    name: "eventDate",
                    label: "이벤트일자",
                    align: "left",
                    /* 
                        220422 meej category : 메모 일때 formatDate 안씀
                    */
                    field: (row) => row.category == '메모' ? 
                            row.eventDate.substring(0, 4)+"-"+row.eventDate.substring(4, 6)
                            : FormatDate(row.eventDate),
                    style: (row) => this.getStyle(row)
                },
                {
                    name: "newsType",
                    label: "카테고리",
                    align: "left",
                    field: (row) => row.category,
                    style: (row) => this.getStyle(row)
                },
                {
                    name: "title",
                    label: "제목",
                    align: "left",
                    field: (row) => row.title, 
                    style: (row) => this.getStyle(row)
                },
            ],
            selected: [],
            orders: [],
            isScheduleOrder: true, // 스케줄 오더테이블 구분값
        };
    },
    created() {
        this.setPageStatus(this.PAGE_STATUS.LIST);

        this.INIT_LIST();
        this.getScheduleList();
    },
    computed: {
        ...mapState({
            items: (state) => state.schedule.items,
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            nextKey: (state) => state.schedule.nextKey,
            newsTypes: (state) => state.resource.EventNewsType,
        }),
        ...mapGetters("page", [
            "isListPage",
            "isDetailPage",
            "isEditPage",
            "isRegisterPage",
        ]),
        isText() {
            Debug.log("isText = ");
            if (
                this.conditionType !== null &&
                this.conditionType !== undefined
            ) {
                if (
                    this.conditionType.value === "createDate" ||
                    this.conditionType.value === "lastUpdateDate" || 
                    this.conditionType.value === "startDate"  ||
                    this.conditionType.value === "eventDate"
                ) {
                    return false;
                }
            }
            return true;
        },
    },
    methods: {
        ...mapMutations({
            setPageStatus: "page/setPageStatus",
            SET_ITEM: "schedule/SET_ITEM",
            INIT_LIST: "schedule/INIT_LIST",
        }),
        ...mapActions("schedule", [
            "ASYNC_LIST",
            "ORDER",
            "GET_LIST",
            "MULIT_DELETE_ITEMS",
        ]),
        ...mapActions("alert", ["showError", "showMessage", "showAlert"]),
        ...mapActions("resource", ["GET_NEWSTYPE_LIST"]),

        initCondition() {
            this.condition.keyword = "";
        },
        getScheduleList() {
            if (this.newsTypes.length === 0) {
                //default all!!
                this.GET_NEWSTYPE_LIST({ newsType: "EventNewsType" }).then(
                    () => {
                        this.getScheduleList();
                    }
                );
                return;
            }
            /* 
                220425 meej type : 이벤트일자 일 경우 flag = "1", 날짜 입력이 없을 경우 오늘 날짜로 검색
            */
            if (this.conditionType.value == "eventDate"){
                let params = {};
                params.flag = "1"
        
                if(isEmpty(this.condition.keyword)){ 
                    params.eventDate = moment(new Date()).format("YYYYMMDD").toString();
                }else{
                    params.eventDate = this.condition.keyword;
                }

                Debug.log("params = ", params);
                
                this.ASYNC_LIST(params);
            }else{ 
                // 이벤트일자 외 검색시
                let params = { newsType: this.newsTypes[0].value };
                if (
                    this.conditionType !== null &&
                    this.conditionType !== undefined &&
                    !isEmpty(this.condition.keyword)
                ) {
                    params[this.conditionType.value] = this.condition.keyword;
                } 
            
                /* 
                    220412 meej nextKey 추후 수정 필요 : state의 nextKey초기화 오류
                */
                params.nextKey = this.nextKey

                Debug.log("params = ", params);

                this.ASYNC_LIST(params);
            }
        },
        /* 
            220412 meej 검색 오류 수정
        */
        getSearchList(){
            this.INIT_LIST()
            this.getScheduleList()
        },
        setItem(item) {
            Debug.log("setItem = ", item);
            this.setPageStatus(this.PAGE_STATUS.DETAIL);
            this.SET_ITEM(item);
            var date;
            /* 
                220425 meej type : 이벤트일자 일 경우 condition.keyword로 넣어줌
            */
            if(this.conditionType.value == "eventDate"){
                date = this.condition.keyword
            }else{
                date = item.eventDate
            }
            this.getScheduleListByDate(date);
        },
        handleCheckList(list) {
            this.selected = list;
        },
        deleteItems() {
            if (this.selected.length === 0) {
                this.showMessage({
                    vc: this,
                    message: "삭제할 항목을 선택하세요.",
                });
                return;
            } else {
                this.showAlert({
                    vc: this,
                    message: "선택한 항목을 삭제 하시겠습니까?",
                }).then(() => {
                    this.doMultiDelete();
                });
            }
        },
        doMultiDelete() {
            var params = {};
                /* 
                    220425 meej 이벤트일자 검색 추가로 인한 수정 x.id -> x.pid
                 */
            params.ids = this.selected.map((x) => x.pid).join(",");
            // 주요이슈는 완전 삭제!
            // 이미 속보로 나간거는 속보용이므로 현재 데이터는 delete
            params.force = 1;
            this.MULIT_DELETE_ITEMS(params)
                .then(() => {
                    this.showMessage({ vc: this, message: "삭제되었습니다." });

                    this.INIT_LIST();
                    this.getScheduleList();
                })
                .catch((err) => {
                    Debug.log("error = ", err);
                    var error = err.message;
                    this.showError({ vc: this, error: error });
                });
        },
        handleOrderList(list) {
            //순서 바뀐 리스트가 넘어옴
            let orderList = [];
            for (let i = 0; i < list.length; i++) {
                let item = list[i];
                let order = {
                    newsType: item.newsType1,
                    id: item.id,
                    index: i,
                };
                orderList.push(order);
            }
            this.showAlert({
                vc: this,
                message: "순서를 변경하시겠습니까?",
            }).then(() => {
                this.doOrder(orderList);
                // 221107 meej 순서 변경후 리스트로 돌아감
                 this.setPageStatus(this.PAGE_STATUS.LIST);
                // 22.09.05 순서변경 충돌 미작동 주석처리 
                // this.getScheduleListByDate(list[0].eventDate);
            });
        },
        /* 
            220425 meej 스케줄 - (type : 이벤트 날짜) 검색 시 (flag=1) eventDate와 endDate는 parent의 날짜를 보여줌
        */
        getScheduleListByDate(date) {
            Debug.log("getScheduleListByDate = ", date);
            var params = {}
            params.eventDate = date
            this.GET_LIST(params)
                .then((list) => {
                    this.orders = list;
                    console.log("LIST=", list);
                    this.orders.forEach((row, index) => {
                        row.index = index;
                        row.CHECK = false;
                    });
                })
                .catch((err) => {
                    this.showError({ vc: this, error: err });
                });
        },
        doOrder(list) {
            this.ORDER({ items: list })
                .then(() => {
                    this.showMessage({ vc: this, message: "변경되었습니다." });
                    // this.INIT_LIST()
                    // this.getScheduleList()
                })
                .catch((err) => {
                    this.showError({ vc: this, error: err });
                });
        },
        /* 
            220425 meej type : 이벤트 날짜 일때 순서변경 버튼 보여줌
        */
        orderChange(){
            this.setPageStatus(this.PAGE_STATUS.DETAIL);
            this.getScheduleListByDate(this.condition.keyword);
        },
        getStyle(row) {
            if (row.checkPoint) {
                return {
                    fontWeight: 'bold !important',
                    color: 'red !important'
                };
            }
            return {
                fontWeight: 'normal',
                color: 'black'
            };
        }
    },
    watch: {
        pageStatus: function(val, old) {
            if (val !== old && this.isListPage) {
                this.INIT_LIST();
                this.getScheduleList();
            }
        },
        searchDate(val, old) {
            Debug.log("searchDate = ", val, ",", old);
            if (val !== "" && val !== old) {
                this.INIT_LIST()
                this.getScheduleListByDate(val);
            }
        },
         /* 
            220425 meej type : 이벤트일자 일 경우 타입선택 시 바로 오늘 날짜로 검색
        */
        conditionType: function(val){
            if(val.value == "eventDate"){
                this.getSearchList()
            }
        }
    },
};
</script>
<style>
/* .wrapChangeBox {
    flex-wrap: wrap-reverse !important;
    font-size: smaller !important;
} */

/* .tableBoxChange {
    overflow-y:scroll !important;
    overflow-x:hidden !important;
} */

.bold-red {
    font-weight: bold !important;
    color: red !important;
}

</style>
