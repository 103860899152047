<template>
    <article>
        <!-- 신문상단표 -->
        <i-select class="selectChangeSize col-12 col-md-5" inputLabel="선택" v-model="paperType" :options="paperOptions" :bgColor="bgColor"/>

        <div :class="{ 'changePageAlign' : html && html.length > 0 }"> 
        <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : list.length == 0 }"
            :columns="columns" :data="testArr" row-key="RNUM" :rows-per-page-options="[0]" style="height:auto;"
            hide-pagination no-data-label="조회되는 데이터가 없습니다.">
            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th v-for="col in props.cols" :key="col.name" :props="props">{{ col.label }}</q-th>
                </q-tr>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td v-for="col in props.cols" :key="col.name" :props="props">
                        <div v-if="col.name == 'closePrice'" class="number"><q-input v-model="props.row[1]" filled dense input-class="text-right" :class="{'colTextRed' : props.row[2].includes('▲'),'colTextBlue' : props.row[2].includes('▼') }" /></div>
                        <div v-else-if="col.name == 'compareDay'" class="number"><q-input v-model="props.row[2]" filled dense input-class="text-right" :class="{'colTextRed' : props.row[2].includes('▲'),'colTextBlue' : props.row[2].includes('▼') }" /></div>
                        <div v-else-if="col.name == 'changeRate'" class="number"><q-input v-model="props.row[3]" filled dense input-class="text-right" :class="{'colTextRed' : props.row[3].includes('+'),'colTextBlue' : props.row[3].includes('-') }" /></div>
                        <div v-else>{{ col.value }}</div>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:no-data="{ icon, message }"><div class="full-width row flex-center q-gutter-sm"><q-icon size="2em" :name="icon"/> <span>{{message}}</span></div></template>
        </q-table>
        <br/>
        <div>
            <q-btn size="l" label="저장" @click="getSave()"/></div><br>
            <span style="color:grey;">*저장은 변경사항 미리보기를 위한 버튼입니다. 신문 미리보기로 저장하셔야 주식신문에 반영됩니다. </span>
        </div>

        <div v-if="(html && html.length > 0)">
            <br/>
            <p style="font-size: small; font-weight: bold; text-align: left;">변경사항 미리보기 <br> </p>
            <div style="color:grey; text-align: left;">*휴장의 경우 실제 신문에서는 한 칸으로 표시됩니다.</div>
            <div v-html="html"></div>
        </div>

    </article>
</template>
<script>
import Select from '@/components/common/Select.vue'
import constants from '@/constants'
import { today } from '@/util'
import { mapState, mapMutations, mapActions } from 'vuex'
import { closingCheck } from '@/util'

export default {
    name: 'List',
    components: {
        'i-select' : Select,
    },
    data () {
        return {
            timeChk: null,    // AM/PM
            html: "",         // 상단표 html
            // tableCols: [   // 표 컬럼
            //     {rowspan: 6, col: '국내'},
            //     {rowspan: 6, col: '아시아'},
            //     {rowspan: 6, col: '미국'},
            //     {rowspan: 4, col: '유럽'},
            //     {rowspan: 5, col: '환율'},
            //     {rowspan: 4, col: '원자재'},
            //     {rowspan: 4, col: '채권'},
            //     {rowspan: 2, col: '비트코인(USD/BTC)'},
            // ], 
            tableRowAM: [      // 상단표 조간 왼쪽행 
                'KOSPI 종합',
                'KOSDAQ 종합',
                'KOSPI200',
                '선물(최근월)',
                'KOSPI200 야간선물',
                'MSCI KOREA ETF (EWY)',
                '일본 NIKKEI',
                '중국 상해종합',
                '중국 CSI',
                '홍콩 항셍',
                '홍콩 H',
                '대만 가권',
                'DOW JONES',
                'S&P500',
                'NASDAQ',
                'NASDAQ 100',
                '필라델피아반도체',
                'VIX',
                '유로스탁스 50',
                '영국 FTSE 100',
                '독일 DAX30',
                '프랑스 CAC40',
                '달러인덱스',
                '원/달러',
                '원/유로',
                '원/엔',
                '원/위안',
                'WTI(USD/배럴)',
                '구리(USD/파운드)',
                '금(USD/온스)',
                '은(USD/온스)',
                '韓 국고채 3년',
                '韓 국고채 10년',
                '美 국채 2년물',
                '美 국채 10년물',
                '비트코인(USD/BTC)',
            ],
            tableRowPM: [      // 상단표 석간 왼쪽행 
                'KOSPI 종합',
                'KOSDAQ 종합',
                'KOSPI200',
                '선물(최근월)',
                '원/달러',
                '일본 NIKKEI',
                '중국 상해종합',
                '중국 CSI 300',
                '홍콩 항셍',
                '홍콩 H',
                '대만 가권',
            ],
            newsKey: '',       // s3키값
            tableData: [],     // 표 데이터 
            testArr: [],       // 화면 상단표 데이터
            columns: [
                    { headerStyle:'text-align:center;', name: 'stock',  label: '지수/종목', align: 'center', field: (row) => row[0] == null ? 'n/a' : row[0] },
                    { headerStyle:'text-align:center;', name: 'closePrice',  label: '종가', align: 'center', field: (row) => row[1] == null ? 'n/a' : row[1] },
                    { headerStyle:'text-align:center;', name: 'compareDay', label: '전일대비', align: 'center', field: (row) => row[2] == null ? 'n/a' : row[2] },
                    { headerStyle:'text-align:center;', name: 'changeRate',  label: '등락률(%)', align: 'center', field: (row) => row[3] == null ? 'n/a' : row[3] },
                ], 
            paperType: null,    // 조간/석간 
            paperOptions : constants.PAPER_TYPE,
            bgColor: "red-11", //조간신문 : red, 석간신문 : blue
        }
    },
    computed : {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            stockItems : state => state.resource.stockItems
        }),
        ...mapState('manage', ['list', 'totcount']),
    },
    watch:{
        paperType(val){
            this.resetValues()
            if(val.value == "MARKET_MOBILENEWS_AM"){ 
                // 조간신문
                this.bgColor = "red-11"
            }else{
                //석간신문
                this.bgColor = "blue-11"
            }
            this.getList(val.value) 
        },

    },
    created() {
        this.resetValues()
        this.paperType = this.paperOptions[closingCheck()]
        // console.log('paperType?', this.paperType, 'opt?', this.paperOptions)
    },
    mounted() {
        // 22.12.01 SUYE 주식신문 조간/석간 시간 설정
        const today = new Date()
        const hours = today.getHours()// 시간
        // const mins =  today.getMinutes() 
        const mins = today.getMinutes().toString().padStart(2, '0'); // 분을 2자리 수로 맞춤
        const timeHM = hours + "" + mins
        console.log('timeHM?', timeHM)
        const timeLimit = 1530 
        if(this.timeChk == null){
            if(timeHM < timeLimit) this.timeChk = 'AM' // 조간
            else this.timeChk = 'PM' // 석간
            console.warn('timeHM?', timeHM, 'timeChk?', this.timeChk)
        } 
    },
    methods: {
        ...mapActions('newspaper', ['GET_CLOSE_INDEX_LIST', 'SAVE_CLOSE_INDEX',]),
        ...mapMutations('manage', ['SET_DATA', 'INIT_DATA_VIEW', 'SET_DATA_VIEW']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),

        resetValues(){
            this.html = ''
            this.newsKey = ''
            this.tableData = []
            this.testArr = []
        }, 

        getList(type){ // MARKET_MOBILENEWS_AM or PM
            if(type == null) {
                type = 'MARKET_MOBILENEWS_'+this.timeChk
            }
            let param = {
                type: type,
            }
            this.GET_CLOSE_INDEX_LIST(param)
            .then((data) => {
                // console.log('data?', data)
                const newspaperHtml = data 
                let newspaperKey = '' // yyyyMMDD/MARKET_MOBILENEWS_AM/PAPER_CLOSE_INDEX
                if(this.timeChk && this.timeChk !== null){
                    newspaperKey = today()+'/MARKET_MOBILENEWS_'+this.timeChk+'/PAPER_CLOSE_INDEX'
                    // test 임시
                    // newspaperKey = "20221205"+'/MARKET_MOBILENEWS_PM'+'/PAPER_CLOSE_INDEX'
                    this.newsKey = newspaperKey
                } 
                console.warn('newsKey=', this.newsKey)

                Object.entries(newspaperHtml).forEach(([key, value]) => { // 신문Obj key, html
                    console.log('key', key,)
                    console.log('newspaperKey', newspaperKey,)
                    if(key && newspaperKey && key == newspaperKey) {
                        this.html = value
                        
                    } else {
                        console.log('key불일치')
                    }
                })

                let testSplit = this.html 
                // console.log('testSplit', testSplit)
                // 23.01.27 SUYE 휴장처리 
                if(testSplit.includes('휴장')){
                    const regExHoliday = new RegExp('(<td colspan=3>(?!<b>))(휴장)(?=</td>)', 'g') // TEST �쑕�옣
                    const resultHoliday = testSplit.match(regExHoliday)
                    if(resultHoliday !== null){
                        // console.log('resultHoliday', resultHoliday)
                        resultHoliday.forEach(e => {
                            testSplit = testSplit.replace(e, '<td class=hol>휴장</td><td class=hol>휴장</td><td class=hol>휴장')
                        });
                    }
                    this.html = testSplit
                    // console.log('testSplit', testSplit)
                }
                const regEx = new RegExp('(?<=</td><td class=pls>|<td class=mns>|<td class=hol>|<td class=>|<td >|<td>(?!<b>))(.*?)(?=</td>)', 'g')
                const result = testSplit.match(regEx)
                if (result !== null) {
                    // console.log('result', result) // total: 108
                    this.tableData = result
                }

                // 3개씩 list로 묶기
                const size = 3
                for (let i = 0; i < this.tableData.length; i += size) {
                    this.testArr.push(this.tableData.slice(i, i + size)) 
                }

                // 왼쪽 컬럼행 추가 
                this.testArr.forEach((e, i) => {
                    // TEST 임시 
                    // e.unshift(this.tableRowPM[i])
                    if(this.timeChk == 'AM') e.unshift(this.tableRowAM[i])
                    else if(this.timeChk == 'PM') e.unshift(this.tableRowPM[i])
                });
                // console.log('testArr', this.testArr)

                })
                .catch((err) => {
                    this.showError({ vc: this, error: err })
                })
            },

            getSave(){
                let finalHtml = ''
                let testSplit = this.html 
                // console.log('testSplit', testSplit)
                const regEx = new RegExp('(?<=</td><td class=pls>|<td class=mns>|<td class=hol>|<td class=>|<td >|<td>(?!<b>))(.*?)(?=</td>)', 'g')
                let result = testSplit.replace(regEx, '[val]')
                // console.log('result=>', result)
                
                let tempList = []
                this.testArr.forEach(e => {
                    tempList.push(e[1], e[2], e[3])
                });
                // console.log('tempList', tempList)

                // replace()로 앞 값을 바꿔가면서 모두 바꿀 때까지 계속 치환 진행 
                tempList.forEach(e => {
                    result = result.replace('[val]', e)
                });
                // console.log('replaced result', result)
                let result3 = []
                const regEx2 = new RegExp('(<tr>(?!<th))(.*?)(</tr>)', 'g') 
                const result2 = result.match(regEx2)
                if (result2 !== null) {
                    // console.log('result2', result2)
                    result2.forEach(e => {
                        console.log("e : ", e)
                        if(e.includes('▲')){
                            // console.log('▲ included', e)
                            e = e.replaceAll('mns', 'pls').replaceAll('hol', 'pls').replaceAll('-', '+').replaceAll('hol', 'pls').replaceAll('class=>','class=pls>')
                            // console.log('replaced?', e)
                        } 
                        else if(e.includes('▼') || e.includes('-')){
                            // console.log('▼ included', e)
                            e = e.replaceAll('pls', 'mns').replaceAll('hol', 'mns').replaceAll('+', '-').replaceAll('class=>','class=mns>')
                            // console.log('replaced?', e)
                        } else if(e.includes('휴장')){ // TEST �쑕�옣
                            const regEx3 = new RegExp('(</td><td )(.*?)(</tr>)', 'g') 
                            const result4 = e.match(regEx3)
                            // console.log('result3?', result4[0])
                            e = e.replaceAll(result4[0], '</td><td colspan=3>[hol]</td></tr>')
                        }
                        e = e.replaceAll('[hol]', '휴장')
                        result3.push(e)
                    });
                    // console.log('result3', result3)

                    finalHtml = `<style type=text/css>.tbll {border-collapse:collapse;FONT-SIZE:9pt;}.tbll td {border:1px solid #dcdcdc;FONT-SIZE:9pt;padding:3px;text-align:center;}
                    td.pls {color:red;FONT-SIZE:9pt;}td.mns {color:blue;FONT-SIZE:9pt;}.tbll th{text-align:center;height:23pt; border:1pt solid #D8D8D8; background:#153e76;color:white;padding:0;}.w_10{width:10%}.w_20{width:20%}.w_30{width:30%}</style>
                    <table class=tbll cellspacing=0 style="background:#EEF3F8;"><tr><th class=w_10>구분</th><th class=w_30>지수/종목</th><th class=w_20>종가</th><th class=w_20>전일대비</th><th class=w_20>등락률(%)</th></tr>`
                    + result3.join('') + `</table>`
                    
                    // n/a일 경우 클래스 제거 
                    const regEx3 = new RegExp('(class=pls>|class=mns>)(n/a)', 'gi')  
                    finalHtml = finalHtml.replace(regEx3, '>n/a')

                    console.log('finalHtml', finalHtml)
                }

                if(finalHtml && finalHtml.length > 0){
                  
                    let param = {
                        key: this.newsKey, // TEST 임시
                        // key: '20221205/MARKET_MOBILENEWS_PM/PAPER_CLOSE_INDEX',
                        html: finalHtml,
                    }

                    this.SAVE_CLOSE_INDEX(param)
                    .then((res) => {
                        console.log('res', res)
                        this.showMessage({ vc: this, message: '저장되었습니다.' })

                        this.resetValues()
                        this.getList()
                    })
                    .catch((err) => {
                        this.showError({ vc: this, error: err })
                    })
                } else {
                    console.log('result값 없음')
                }
 

            },
    }
}

</script>
<style>
.selectChangeSize {
    width: fit-content;
}
.colTextRed {
    background: #fce9e7;
}
.colTextBlue {
    background: #d9e6fa;
}
.changePageAlign {
    float:right; 
    width:fit-content;
}
</style>