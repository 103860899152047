<template>
    <section class="row q-pl-xs">
        <q-toolbar class="row q-mt-md text-left">
            <div class="q-pa-xs">
                <q-select v-model="stockCode" :options="stockOptions" option-label="name" @filter="filterFn" input-debounce="0" @input="getList" dense outlined :hide-selected="hide_selected" :fill-input="hide_selected" use-input hide-dropdown-icon options-dense style="width:200px;">
                    <q-tooltip anchor="top middle" self="bottom middle" :offset="[5, 5]">한글로 검색 시, enter를 눌러주세요.</q-tooltip>
                    <template v-slot:no-option><q-item><q-item-section class="text-grey">검색된 데이터가 없습니다.</q-item-section></q-item></template>
                </q-select>
            </div>
            <div class="q-pa-xs"><i-select v-model="year" :options="yearOptions" option-value="value" option-label="name" @input="getList" style="width:90px;"/></div>
            <div class="q-pa-xs"><i-select v-model="quarter" :options="quarterOptions" option-value="value" option-label="name" @input="getList" style="width:100px;"/></div>
            <div class="q-pa-xs"><i-select v-model="companyCode" :options="companyCodeOptions" option-value="code" option-label="name" @input="getList" style="width:140px;"/></div>
            <div class="q-pa-xs"><i-select v-model="ifrs" :options="ifrsOptions" option-value="value" option-label="name" @input="getList" style="width:120px;"/></div>
            <div class="q-pa-xs"><i-btn icon="search" @click="getList()"/></div>
            <div class="q-pa-xs"><i-btn label="선택삭제" @click="getDeleteChk()"/></div>
            <div class="col q-pa-xs text-right">
                <i-btn label="년도등록" @click="getUpdatePopup()"/>
                <!--요청에 의해 분기등록 주석처리 : 211006 이민수-->
                <!--<i-btn label="분기등록" @click="getUpdateQuarterPopup()" disabled/>-->
            </div>
        </q-toolbar>
        <i-list class="col-12 q-pa-md" ref="consensusList"/>
        <i-content ref="consensusContent"/>
        <i-contentQuarter ref="consensusContentQuarter"/>
    </section>
</template>
<script>
import CONSTANTS from '@/constants'
import Select from '@/components/common/Select.vue';
import Button from '@/components/common/Button'
import List from '@/components/earning/consensus/List.vue';
import Content from "@/components/earning/consensus/Content.vue";
import ContentQuarter from "@/components/earning/consensus/ContentQuarter.vue";
import { mapState, mapActions } from 'vuex'

export default {
    name: "Earning",
    props: {
        hide_selected: {
            type: Boolean,
            default: true
        }
    },
    components: {
        'i-select' : Select,
        'i-btn': Button,
        "i-list": List,
        "i-content": Content,
        "i-contentQuarter": ContentQuarter
    },
    data() {
        return {
            stockList: [],
            stockCode: '',
            stockOptions: [],
            year: {'value':'', 'name':'연도'},
            yearOptions: [{'value':'', 'name':'연도'}],
            quarter:{'value':'', 'name':'분기'},
            quarterOptions: [{'value':'', 'name':'분기'}].concat(CONSTANTS.COM_QUARTER),
            companyCode: {'code':'', 'name':'증권사'},
            companyCodeOptions: [{'code':'', 'name':'증권사'}],
            ifrs: {'value':'', 'name':'연결/별도'},
            ifrsOptions: [{'value':'', 'name':'연결/별도'}].concat(CONSTANTS.COM_IFRS)
        }
    },
    computed: {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            stockItems : state => state.resource.stockItems
        })
    },
    created () {
        this.getPage();
    },
    methods: {
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        getPage() {
            // 221011 meej 추정컨센서스 연도 설정 변경
            let iYear = Number(new Date().getFullYear())+4;
            for(let i=iYear; i >= iYear-7; i--){
                this.yearOptions.push({
                    value: i+'',
                    name: i+''
                });
            }
            var list = [];
            try {
                list = JSON.parse(this.stockItems);
            } catch (e) {
                list = this.stockItems;
            }
            var ds_list = [];
            list.forEach(row => {
                ds_list.push({
                    code : row['code'],
                    name : row['name'] + ' (' + row['code'] + ')'
                });
            });
            this.stockList = ds_list;
            let param = {
                newsType: 'STOCK_CONSENSUS_COMPANY',
                CALL_API_URL: '/stock/company'
            }
            this.GET_CALL(param).then((data) => {
                this.companyCodeOptions = [{'code':'', 'name':'증권사'}].concat(data.items);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        getList() {
            this.$refs.consensusList.getList();
        },
        getUpdatePopup() {
            this.$refs.consensusContent.getUpdatePopup();
        },
        getUpdateQuarterPopup() {
            this.$refs.consensusContentQuarter.getUpdateQuarterPopup();
        },
        getDeleteChk() {
            this.$refs.consensusList.getDeleteChk();
        },
        filterFn (val, update, abort) {
            // 종목검색 시, 1자리 이하일 경우 검색되지 않음.
            if (val.length < 2) {
                if (val.length == 0) {
                    this.stockCode = '';
                    this.getList();
                }
                abort();
                return;
            }
            update(() => {
                const needle = val.toLowerCase();
                this.stockOptions = this.stockList.filter(
                    v => v.name.toLowerCase().indexOf(needle) > -1 || v.code.indexOf(val) > -1
                )
            })
        }
    }
};

</script>
