import debug  from '../../../debug'
const state = () => {
    return {
        status: {
            LIST: 'List',
            DETAIL: 'Detail',
            EDIT: 'Edit',
            REGISTER: 'Register',
            FLASH: 'Flash',
            HISTORY: 'History',
            CONFIRM: 'Confirm',
            POPUP: 'Popup',
        },
        pageStatus: 'List',
        isMobile: null,
    }
}

const getters = {
    isListPage: state => (state.pageStatus === state.status.LIST),
    isDetailPage: state => (state.pageStatus === state.status.DETAIL),
    isEditPage: state => (state.pageStatus === state.status.EDIT),
    isRegisterPage: state => (state.pageStatus === state.status.REGISTER),
    isFlashPage: state => (state.pageStatus === state.status.FLASH),
    isHistoryPage: state => (state.pageStatus === state.status.HISTORY),
    isPopupPage: state => (state.pageStatus === state.status.POPUP),
    isConfirmPage: state => (state.pageStatus === state.status.CONFIRM),
}

// const actions = {

// }

const mutations = {
    setListPage(state) {
        debug.log('setListPage >>>>>>>>>>>>>>>>')
        state.pageStatus = state.status.LIST
    },
    setPopupPage(state) {
        debug.log('setPopupPage >>>>>>>>>>>>>>>>')
        state.pageStatus = state.status.POPUP
    },
    setPageStatus(state, status) {
        debug.log('setPageStatus >>>>>>>>>>>>>>>> ', status)
        state.pageStatus = status
    },
    setIsMobile(state, isMobile) {
        state.isMobile = isMobile
    }
}

export const page = {
    namespaced: true,
    state,
    getters,
    // actions,
    mutations
}