<template>
    <article>
        <q-toolbar>
            <q-space/>
            <i-btn v-show="isDetailPage" label="취소" @click="goListPage()"/>
        </q-toolbar>
        <q-scroll-area class="i-content-wrap">
            <q-card flat bordered>
                <q-card-section>
                    <div v-if="isListPage" class="row">
                        <div class="col-6 q-pa-xs">
                            <i-stock-search @get="getStock" label="종목검색"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 q-pa-xs">
                            <i-input v-model="strongBuy.stockCode" inputLabel="종목코드" disable />
                        </div>
                        <div class="col-6 q-pa-xs">
                            <i-input v-model="strongBuy.stockName" inputLabel="종목명" disable />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 q-pa-xs">
                            <i-select v-model="strongBuy.STOCK_COMPANY0"
                                :options="stockCompanyOptions" inputLabel="증권사"
                                option-label="name" option-value="value"/>
                        </div>
                        <div class="col-12 col-md-6">
                            <i-datetime date v-model="strongBuy.showDate" dateLabel="제시일" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 q-pa-xs">
                            <i-input v-model="strongBuy.TARGET_PRICE1" inputLabel="목표가" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 q-pa-xs">
                            <i-input v-model="strongBuy.content" inputLabel="사유" type="textarea"  :rows="10" />
                        </div>
                    </div>
                </q-card-section>
            </q-card>
        </q-scroll-area>
        <!-- 하단 버튼 -->
        <div class="q-my-md">
            <i-btn class="full-width" :label="btnLabel" @click="write()"/>
        </div>
    </article>
</template>
<script>
import Debug from '@/debug'

import Button from '@/components/common/Button'
import Input from '@/components/common/Input'
import StockSearch from '@/components/common/StockSearch'
import Select from '@/components/common/Select'
import DateTime from '@/components/common/DateTime'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { objToString } from '@/util'

export default {
    name: 'Content',
    components: {
        'i-input': Input,
        'i-stock-search': StockSearch,
        'i-btn' :  Button,
        'i-select' :  Select,
        'i-datetime': DateTime,
    },
    created () {
        if (this.stockCompanyOptions.length == 0) {
            // var params = {}
            // params.newsType = this.newsType
            this.GET_STOCK_COMPANY()
        }
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
            strongBuy : state => state.stock_strong_buy.item,
            newsType : state => state.stock_strong_buy.newsType,
            stockCompanyOptions : state => state.stock_strong_buy.stockCompany,
        }),
        ...mapGetters( 'page', ['isListPage', 'isDetailPage', 'isEditPage', 'isRegisterPage']),

        btnLabel: function() {
            if (this.isListPage) {
                return '등록'
            } else if (this.isDetailPage) {
                return '수정'
            } else {
                return null
            }
        }
    },
    methods: {
        ...mapMutations( 'page', ['setPageStatus']),
        ...mapMutations( 'stock_strong_buy', ['SET_DETAIL', 'INIT_DETAIL', 'INIT_LIST']),

        ...mapActions( 'alert', ['showError', 'showMessage']),
        ...mapActions( 'stock_strong_buy', ['GET_STOCK_COMPANY', 'GET_LIST', 'GET_DETAIL', 'WRITE_ITEM']),

        getStock(stock) {
            this.strongBuy.stockCode = stock.code
            this.strongBuy.stockName = stock.name
        },
        goListPage() {
            this.INIT_DETAIL()
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        write() {

            var message

            // 빈값 체크
            if (!this.strongBuy.stockCode
                || !this.strongBuy.TARGET_PRICE1
                || !this.strongBuy.content) {
                    message = '내용을 입력해주세요.'
                    this.showMessage({ vc:this, message:message })
                    return
                }

            // 증권사 선택 체크
            if (!this.strongBuy.STOCK_COMPANY0) {
                message = '증권사 선택해주세요.'
                this.showMessage({ vc:this, message:message })
                return
            }

            // 목표가 숫자인지 체크
            if (isNaN(this.strongBuy.TARGET_PRICE1)) {
                message = '목표가에 숫자만 입력해주세요.'
                this.showMessage({ vc:this, message:message })
                return
            }

            var params = {
                stockCode : this.strongBuy.stockCode,
                content : this.strongBuy.content
            }

            var options = {}
            options.STOCK_COMPANY0 = this.strongBuy.STOCK_COMPANY0.code
            options.TARGET_PRICE1 = this.strongBuy.TARGET_PRICE1

            params.options = objToString(options)
            //제시일 추가
            params.showDate = this.strongBuy.showDate

            if (this.isDetailPage) {
                params.id = this.strongBuy.id
            }

            this.WRITE_ITEM(params)
            .then(() => {
                message = this.btnLabel + '되었습니다.'
                this.showMessage({ vc:this, message:message })

                this.INIT_LIST()
                this.GET_LIST({})

                if (this.isListPage) {
                    this.INIT_DETAIL()
                }
            })
            .catch((err) => {
                Debug.log('error = ', err)
                var error = err.message
                this.showError({ vc:this, error:error })
            })
        }
    }
}
</script>


