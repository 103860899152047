<template>
  <q-table
    ref="table"
    dense
    :columns="columns"
    :data="items"
    row-key="name"
    selection="single"
    :selected.sync="selected"
    hide-bottom
    hide-header
    :rows-per-page-options="[0]"
    virtual-scroll
    style="height: 660px"
  >
    <template v-slot:top>
      <span style="font-weight: 600">{{ title }} [{{ list.length }}개] </span>
      <q-space />
      <q-btn
        class="q-ml-xs"
        size="sm"
        @click="addItem"
        icon="add"
        round
        dense
        color="dark"
      >
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          추가
        </q-tooltip>
      </q-btn>

      <q-btn
        class="q-ml-xs"
        size="sm"
        icon="save"
        @click="doneOrder"
        round
        dense
        color="dark"
      >
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          저장
        </q-tooltip>
      </q-btn>
      <q-btn
        class="q-ml-xs"
        size="sm"
        icon="cancel"
        @click="cancelOrder"
        round
        dense
        color="dark"
      >
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          취소
        </q-tooltip>
      </q-btn>
    </template>
    <template v-slot:header="props">
      <q-tr :props="props">
        <q-th v-for="col in props.cols" :key="col.name" :props="props">
          {{ col.label }}
        </q-th>
        <q-th auto-width />
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td auto-width style="padding-left: 5px; padding-right: 5px">
          <q-btn
            size="xs"
            color="dark"
            round
            flat
            @click="delClick(props)"
            icon="remove"
          />
        </q-td>
        <q-td auto-width style="padding-left: 5px; padding-right: 10px">
          {{ props.row.index + 1 }}
        </q-td>
        <q-td
          key="name"
          :props="props"
          style="padding: 0px; margin: 0px; height: 20px"
        >
          <q-select
            v-model="props.row.item"
            :options="stockOptions"
            option-label="name"
            @filter="filterFn"
            input-debounce="0"
            dense
            borderless
            :hide-selected="hide_selected"
            :fill-input="hide_selected"
            use-input
            hide-dropdown-icon
            options-dense
          >
            <q-tooltip anchor="top middle" self="bottom middle" :offset="[5, 5]"
              >한글로 검색 시, enter를 눌러주세요.</q-tooltip
            >
            <template v-slot:no-option
              ><q-item
                ><q-item-section class="text-grey"
                  >검색된 데이터가 없습니다.</q-item-section
                ></q-item
              ></template
            >
          </q-select>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>
<script>
import { clone, isEmpty } from "@/util";
import debug from "@/debug";
import { mapState } from "vuex";
export default {
  name: "Table",
  components: {},
  props: {
    columns: {
      type: Array,
    },
    list: {
      type: Array,
    },
    title: null,
    hide_selected: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      items: [],
      selected: [],
      stockOptions: [],
      stockList: [],
    };
  },
  computed: {
    ...mapState({
      stockItems: (state) => state.resource.stockItems,
    }),
  },
  created() {
    this.items = clone(this.list);
    this.items.forEach((row, index) => {
      row.index = index;
      row.item = row;
    });

    var list = [];
    try {
      list = JSON.parse(this.stockItems);
    } catch (e) {
      list = this.stockItems;
    }
    var ds_list = [];
    list.forEach((row) => {
      ds_list.push({
        name: row["name"] + " (" + row["code"] + ")",
        code: row["code"],
      });
    });
    this.stockList = ds_list;
  },
  methods: {
    // 행삭제 버튼
    delClick(val) {
      debug.log("delClick = ", val.row.index);
      if (val.row.index !== undefined) {
        this.items.splice(val.row.index, 1);
      }
    },
    // 취소
    cancelOrder() {
      // 기존 순서로 번경
      debug.log("cancel = ", this.list);
      this.items = clone(this.list);
      this.items.forEach((row, index) => {
        row.index = index;
        row.item = row;
      });
    },
    // 저장
    doneOrder() {
      let change = "";
      this.items.forEach((row) => {
        debug.log("row =", row.item.code);
        if (!isEmpty(row.item.code)) change += row.item.code + ",";
      });

      this.$emit("order-event", change.slice(0, -1));
    },
    // 행추가
    addItem() {
      this.items.push({ index: this.items.length });
      // 스크롤 맨 아래로 이동
      this.$nextTick(function () {
        this.$refs.table.scrollTo(this.items.length);
      });
    },
    filterFn(val, update, abort) {
      // 종목검색 시, 1자리 이하일 경우 검색되지 않음.
      if (val.length < 2) {
        abort();
        return;
      }
      update(() => {
        const needle = val.toLowerCase();
        this.stockOptions = this.stockList.filter(
          (v) =>
            v.name.toLowerCase().indexOf(needle) > -1 ||
            v.code.indexOf(val) > -1
        );
      });
    },
  },
  watch: {
    list() {
      this.items = clone(this.list);
      this.items.forEach((row, index) => {
        row.index = index;
        row.item = row;
      });
    },
  },
};
</script>

