<template>
    <router-view></router-view>
</template>

<script>
import Debug from '@/debug'
export default {
    name:'Test',
    created () {
        Debug.log('response = ', this.$router)
    },
}
</script>
<style scoped>

</style>