<template>
    <article>
        <q-toolbar class="i-top-wrap">
            <div class="q-pa-xs">
                <i-select v-model="type" :options="options" inputLabel="시간구분" />
            </div>
            
            <q-space/>
            <i-btn v-show="!isListPage" label="취소" @click="goListPage()"/>
        </q-toolbar>
        <q-scroll-area class="i-content-wrap">
            <q-card class="q-pa-md text-left" flat bordered>
                <q-card-section class="row col-12 q-pt-none">
                    <div class="col-12 q-pa-xs">
                        <i-input outlined v-model="featured.themeName" inputLabel="테마명" @focus="goEditPage"/>
                    </div>
                    <div class="col-12 q-pa-xs">
                        <i-input v-model="featured.title" inputLabel="이슈요약" @focus="goEditPage" @input="inputSummary"/>
                    </div>
                    <div class="col-12 q-pa-xs">
                        <i-input v-model="featured.content" type="textarea" inputLabel="이슈내용" :rows="15" @focus="goEditPage"/>
                    </div>
                    <!-- <div class="row q-my-xs">
                        <i-stock-search @get="getStock" label="관련종목"/>
                        
                        <div class="col-md-8 text-left">
                            <q-chip
                                v-for="(stock, i) in featured.stocks"
                                :key="'stock' + i"
                                color="secondary"
                                text-color="white"
                                removable
                                @remove="removeFeaturedStock(i)"
                            >
                                {{ stock.name }}({{ stock.code }})
                            </q-chip>
                        </div>
                    </div> -->
                </q-card-section>
                
                <!-- 220623 meej 테마 추가 버튼 -->
                <q-card-section>
                    <q-btn v-if="(isDetailPage || isEditPage) &&historyList.length > 0 && (featured.crud != 'D')" class="text-left" flat rounded color="primary" @click="goEditPage();requestResource(null, 'Theme', null)" label="테마 추가"/>
                </q-card-section>
                <q-card-section v-for="(item, index) in historyList" :key="'setting-'+index" class="q-pb-none">
                    
                    <div class="row">
                        <div>
                            <q-chip v-if="item.theme"  outline square color="black" text-color="white" removable :disable="isDetailPage"
                                    @remove="removeTheme(index)" >
                                {{ item.theme.name }}
                            </q-chip>
                        </div>
                        <div class="q-pl-md">
                            <!-- 220623 meej 히스토리 추가, 삭제 -->
                            <!-- 221019 meej 테마히스토리 팝업 추가 -->
                            <q-btn flat rounded v-show="item.theme && isDetailPage && (item.showFlag!==1 || item.crud=='D') && (featured.crud != 'D')" label="히스토리 추가" color="primary" @click="openHistory(index)"/>
                            <q-btn rounded v-show="item.theme && isDetailPage && item.showFlag==1 && item.crud!='D' && (featured.crud != 'D')" label="입력완료 | 수정" color="primary" @click="openHistory(index)"/>
                            <!-- 221019 meej 기존에 있던 테마히스토리 추가 사용 안함_주석처리 -->
                            <!-- <i-field v-show="item.theme &&(isDetailPage || isEditPage) && item.showFlag!==1" :disable="item.showFlag===1" class="q-ml-md" v-model="item.history" check inputLabel="히스토리 추가" @input="checkHistory(index)"/> -->
                            <!-- <i-field v-show="item.history" class="q-ml-md" :disable="item.historyDel" v-model="item.historyChart" check inputLabel="차트 추가"/>
                            <i-field v-show="item.theme &&(isDetailPage || isEditPage) && item.showFlag===1" class="q-ml-md" :disable="item.crud =='D'" v-model="item.historyDel" check :ckbColor="ckbColor" inputLabel="히스토리 삭제"/>
                            <i-input v-if="item.historyDel" class="col-12 q-pa-xs" v-model="item.comments" inputLabel="삭제 사유" @focus="isDetailPage" :disable="item.crud =='D'"/> -->
                        </div>
                    </div>

                    <!-- <div class="row" v-if="item.history" > -->
                        <!-- 220624 meej label 추가 -->
                        <!-- <i-input class="col-12 q-pa-xs" v-model="item.content" inputLabel="히스토리 내용" @focus="isDetailPage" :disable="item.historyDel"/>
                    </div> -->
                    <!-- 220624 meej 히스토리 추가할때 보여주는 것으로 변경 
                                     requestResource -> createTime도 같이 보내줌-->
                    <!-- <div class="row" v-show="item.history">
                        <div class="col-12 col-md-8">
                            <q-btn class="text-left" :disable="!item.theme||item.historyDel" flat rounded color="primary" @click="goEditPage(); requestResource(index, 'Stock', featured.createTime)" label="관련종목"/>
                            <q-chip v-for="(stock,i) in item.stocks" :key="'stocks'+i"
                                    color="secondary" removable :disable="isDetailPage||item.historyDel"
                                    @remove="removeStock(index, i)">
                                    {{ stock.stockName }}
                            </q-chip>
                        </div>
                        <div class="col-12 col-md-4">
                            <q-btn :disable="!item.theme||item.historyDel" class="col-md-6" flat rounded color="primary" @click="goEditPage(); requestResource(index, 'Stock' , featured.createTime)" label="주도주"/>
                            <q-chip v-for="(main,i) in item.leads" :key="'leads'+i"
                                    color="secondary" removable :disable="isDetailPage||item.historyDel"
                                    @remove="removeMainStock(index, i)">
                                    {{ main.stockName }}
                            </q-chip>
                        </div>
                    </div> -->
                </q-card-section>
            </q-card>
        </q-scroll-area>
        <!-- 하단 버튼 -->
        <div class="q-my-xs">
            <div v-show="isDetailPage || isEditPage" class="row">
                <!-- 221103 meej 조건식에 삭제 여부 추가 -->
                <div v-show="isDetailPage && featured.crud !='D'" class="col q-pa-xs">
                    <i-btn class="full-width" label="삭제" @click="deleteItem()"/>
                </div>
                <!-- 220705 meej 히스토리저장, 수정 합침 -->
                <!-- <div v-show="isEditPage" class="col q-pa-xs">
                    <i-btn class="full-width" label="히스토리 저장" @click="updateHistory()"/>
                </div> -->
                <div v-show="isEditPage" class="col q-pa-xs">
                    <i-btn class="full-width" label="수정" @click="write()" />
                </div>
            </div>
            <div v-show="isRegisterPage" class="row">
                <div class="col-12 q-pa-xs">
                    <i-btn class="full-width" label="등록" @click="write()" />
                </div>
            </div>
        </div>
    </article>
</template>
<script>
import Debug from '@/debug'
import CONSTANTS from '@/constants'
import { isEmpty, morning, clone,optionsValue, objToString,stringToArray } from '@/util'

import Button from '@/components/common/Button'
// import Field from '@/components/common/Field'
import Input from '@/components/common/Input'
import Select from '@/components/common/Select'
// import StockSearch from '@/components/common/StockSearch'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Content',
    components: {
        'i-btn' :  Button,
        // 'i-field': Field,
        'i-input': Input,
        'i-select': Select,
        //'i-stock-search': StockSearch,

    },
    data () {
        return {
            featured: {
                title:'',
                content:'',
                themeName:'',
                stocks: [],
                crud:'',
            },
            historyList:[],
            type: null,
            options:CONSTANTS.THEME_FEATURED_TIME_TYPE,
            createTime: '',
            writer: '',
            detailResult: null,
            file: null,
            ckbColor : "red", // 220624 meej 히스토리 삭제시 체크박스 red로 변경
        }
    },
    created () {
        
        // const params = { type : 'PUBLIC,INTERNAL_PUBLIC'}
        // this.ASYNC_THEME_LIST(params)
        if(this.options.length == 3) {
            this.options = clone(CONSTANTS.THEME_FEATURED_TIME_TYPE)
            this.options.shift()
        }
        this.initContent()
        this.type = this.options[morning()]
        Debug.log('Themefeatured content ', morning(), ', ', this.type)
        this.$parent.$on('sendResource', this.addResource)

        this.$parent.$on('sendCheckList', this.handleCheckList)
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
            selectedItem: state => state.theme_featured.item,
            themeList: state => state.theme.items,
        }),
        ...mapGetters( 'page', ['isListPage', 'isEditPage', 'isRegisterPage', 'isDetailPage'])
    },
    methods: {
        ...mapMutations( 'page', ['setPageStatus']),
        ...mapMutations( 'resource', ['REQUEST_RESOURCE']),
        ...mapMutations( 'theme_featured', ['INIT_ITEMS','INIT_LIST']),
        ...mapMutations('theme_stock', ['REMOVE_CHECKLIST_ITEM','INIT_CHECKLIST', 'SET_CHECKLIST']),

        ...mapActions( 'alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions( 'theme', ['ASYNC_THEME_LIST']),
        ...mapActions( 'theme_featured', ['ASYNC_FEATURED_THEME_LIST', 'GET_DETAIL', 'WRITE_ITEMS', 'DELETE_ITEM', 'SET_FEATURED_HISTORY']),

        initContent() {
            Debug.log('initContent')
            this.featured.title=''
            this.featured.themeName=''
            this.featured.content=''
            this.detailResult = null
            this.historyList=[]
        },
        createThemeCard(){
            const card = {
                id: null,
                theme: null,
                stocks: [],
                leads: [],
                history: false,
                content: '',
            }
            return card
        },
        removeFeaturedStock(index) {
            this.featured.stocks.splice(index,1)
        },
        removeTheme(index) {
            if(this.historyList[index].history){
                let msg = ""
                if(this.historyList[index].showFlag == 1){
                    msg = '등록된 테마히스토리가 있습니다. <br>테마와 테마히스토리를 삭제하시겠습니까?' 
                    msg += '<br><br>※테마 삭제 할 경우 테마히스토리 내역이 남지않고 <br>완전히 삭제됩니다. '
                    msg += '<br>※수정버튼을 눌러야 삭제가 완료됩니다. '
                    this.showAlert({vc:this, message: msg})
                    .then(() => {
                        this.historyList.splice(index,1)
                    })
                }else{
                    msg = '작성된 테마히스토리도 지워집니다.<br>삭제하시겠습니까?'
                    this.showAlert({vc:this, message: msg})
                    .then(() => {
                        this.historyList.splice(index,1)
                    })
                }
            }else{
                this.historyList.splice(index,1)
            }
        },
        removeStock(index, s_index) {
            let card = this.historyList[index]
            card.stocks.splice(s_index,1)
            this.$set(this.historyList, index, card)
            this.SET_CHECKLIST({stocks:card.stocks, leads:card.leads})
        },
        removeMainStock(index, s_index) {
            let card = this.historyList[index]
            
            card.leads.splice(s_index, 1)
            this.$set(this.historyList, index, card)
            this.SET_CHECKLIST({stocks:card.stocks, leads:card.leads})
        },
        handleCheckList(data) {
            //Debug.log('handleCheckList =', data, this.historyList)
            this.historyList[data.index].stocks = data.stocks
            this.historyList[data.index].leads = data.leads
        }, 
        checkHistory(index) {
            this.setPageStatus(this.PAGE_STATUS.EDIT)
            let card = this.historyList[index]
            if(card.history) {
                if(isEmpty(card.content)) {
                    card.content = this.featured.title
                }
            }
        },
        // 221019 meej 테마히스토리 팝업
        openHistory(index) {
            let card = this.historyList[index]

            // 파라미터 전송 : id, 테마 코드
            const {href} = this.$router.resolve({
                path: `/history/ThemePopup.htm`,
                query: {
                    id: card.id,
                    code: card.theme.code,
                }
            });
        
            this.windowRef = window.open(
                href,
                '테마히스토리',
                'width=1530,height=866'
            )
            // 팝업 종료 후 재조회 
            if (this.windowRef) {
                this.windowRef.addEventListener(
                    'beforeunload',
                    this.getThemeNewsDetail
                )
            } 
        },
        getStock(stock) {
            Debug.log('getStock = ',stock)
            this.featured.stocks.push(stock)
        },
        getList() {
            this.INIT_ITEMS()
            let params = {
                type: 'ALL',
                newsType: 'THEME_FEATURED_ALL'
            }

            this.ASYNC_FEATURED_THEME_LIST(params)
        },
        // 상세 조회
        getThemeNewsDetail() {
            this.initContent()
            const params = {
                id: this.selectedItem.id,
                newsType:this.selectedItem.newsType1
            }
            this.GET_DETAIL(params)
                .then((data) => {
                    this.detailResult = data
                    this.featured.themeName = data.news.themeName.replace(/&/gi, ",")
                    this.featured.content = data.news.content
                    this.featured.title = data.news.title 
                    /* 220701 meej createTime 추가 */
                    this.featured.createTime = data.news.createTime
                    // 221103 meej crud 추가
                    this.featured.crud = data.news.crud
                    this.type = this.options.find(v => v.value === data.news.newsType1)
                    
                    data.items.forEach((row)=> {
                        let item = clone(row)
                        item.theme = this.themeList.find(v => v.code === row.themeCode)

                        item.history = (row.showFlag === 1)
                        // 220624 meej 히스토리 삭제 기능 추가
                        item.historyDel = row.crud == 'D'? true : false
                        // 220704 meej 차트 추가 기능 추가
                        item.historyChart = row.CHART == 1 ? true : false
                        item.crud = row.crud
                        item.comments = row.COMMENT
                        item.content = row.content
                        item.stocks = stringToArray(
                            row.STOCKS,
                            'stockName',
                            'stockCode'
                        )
                        item.leads = stringToArray(
                            row.LEAD_STOCK,
                            'stockName',
                            'stockCode'
                        )
                        this.SET_CHECKLIST({stocks:(item.stocks ? item.stocks : []), leads:(item.leads? item.leads: [])})
                        this.historyList.push(item)
                    })

                })
                .catch((err) => {
                    Debug.log('this.GET_DETAIL catch = ', err)
                    this.showError({vc:this, error:err} )
                    this.goListPage()
                })
        },

        write() {
            // 입력값 체크
            if(this.type === null) {
                this.showError({vc:this, error:'타입을 선택하세요.'} )
                return
            }
            const hasTheme = (this.historyList.length > 0 && this.historyList[0].theme != null)
            
            // 220902 meej 테마시황으로 입력시 입력값 체크 조건 변경
            const isMarketNews = this.featured.themeName.trim().includes('테마시황')

            if (!isMarketNews && this.featured.title == ''){
                this.showError({vc:this, error: '이슈요약을 입력해주세요.'} )
                return
            }
            if (this.featured.themeName == ''){
                this.showError({vc:this, error: '테마명을 입력해주세요.'} )
                return
            }
            if(this.featured.content == '') {
                this.showError({vc:this, error: '내용을 입력해주세요.'} )
                return
            }
            /* 220622 meej 테마 추가 alert */
            /* 221125 meej 주석처리 */
            // if(!isMarketNews && this.historyList.length == 0) {
            //     this.showError({vc:this, error: '테마를 추가해주세요.'} )
            //     return
            // } else if(isMarketNews && this.historyList.length > 0){
            //     // 220902 meej 테마시황으로 입력시 입력값 체크 조건 변경
            //     this.showError({vc:this, error: '[테마 시황] 등록 할 경우 테마를 전부 삭제해주세요.'} )
            //     return
            // }

            //종목
            let options = {}
            options.themeName = this.featured.themeName.replace(/,/gi, "&")
            if (this.featured.stocks && this.featured.stocks.length > 0) {
                var obj = {}
                for (var stock of this.featured.stocks) {
                    obj[stock.code] = stock.name
                }
                options.STOCKS = optionsValue(obj)
            }

            let items = []
            let isHistory = false // 220711 meej 히스토리 유무에 따른 멘트 수정
            // 관련 종목, 주도주 추가
            if (hasTheme) {
                for(let i = 0; i < this.historyList.length; i++) {
                    let card = this.historyList[i]
                    Debug.log('theme history')
                    let historyOptions = {}
                    if (card.theme === null) {
                        continue
                    }
                    
                    // 220704 meej 테마히스토리 추가시 관련종목 필수 입력
                    if (card.history){
                        if(!isHistory) isHistory = true
                        if(card.stocks.length == 0 || !card.stocks){
                            this.showError({vc:this, error: '테마의 관련종목을 추가해주세요.'} )
                            return
                        }
                    }
                    if (card.stocks && card.stocks.length > 0) {
                        var objStock = {}
                        for (var themeStock of card.stocks) {
                            objStock[themeStock.stockCode] = themeStock.stockName
                        }
                        historyOptions.STOCKS = optionsValue(objStock)
                    }
                    if (card.leads && card.leads.length > 0) {
                        var objLead = {}
                        for (var lead of card.leads) {
                            objLead[lead.stockCode] = lead.stockName
                        }
                        historyOptions.LEAD_STOCK = optionsValue(objLead)
                    }
                    // 220705 meej 차트 여부 추가
                    historyOptions.CHART = card.historyChart ? 1 : 0

                    let history = (isEmpty(card.content)? this.featured.title : card.content)

                    // 220705 meej 삭제시 코멘트 추가
                    if(card.historyDel){
                        historyOptions.COMMENT = card.comments
                    }

                    let item = {
                        themeCode: card.theme.code,
                        themeName: card.theme.name,
                        // 221025 meej 테마 히스토리 팝업 추가로 인한 수정
                        // content: history,
                        // showFlag: (card.history?1:0),
                        content: card.showFlag == 1 ? card.content : history,
                        showFlag: card.showFlag == undefined ? 0 : card.showFlag,
                        options: objToString(historyOptions),
                        // 220705 meej 삭제여부 추가
                        delete : card.historyDel+"",
                    }

                    if (!isEmpty(card.id)) {
                        item.id = card.id
                    }

                    items.push(item)
                }
            }

            // 파라미터
            let params = {
                newsType: this.type.value,
                title: this.featured.title,
                content: this.featured.content
            }

            if (hasTheme) {
                params.themeCode = this.historyList[0].theme.code
                params.items = items
            } else {
                params.themeCode = -999
            }

            if(this.detailResult !== null) {
                params.id = this.detailResult.news.id
                if(!isEmpty(this.detailResult.news.ZINDEX)) {
                    options.ZINDEX = this.detailResult.news.ZINDEX
                }
            }
            params.options = objToString(options)

            // 220624 meej alert 멘트 수정, this.isEditPage 일때도 추가
            // 220711 meej 히스토리 유무에 따른 멘트 수정
            // 221025 meej 테마 히스토리 팝업 추가로 인한 멘트 주석처리
            let msg = ""
            // if(isHistory){
            //     msg = "특징테마와 테마 히스토리 모두 수정됩니다.<br>"
            // }
            this.showAlert({vc:this, message:(this.isDetailPage || this.isEditPage ? msg+'수정하시겠습니까?':'작성하시겠습니까?')})
                .then(() => {
                    this.doWrite(params)
                })

        },
        doWrite(params) {
            this.WRITE_ITEMS(params)
            .then(() => {
                // 220624 meej this.isEditPage 일때도 추가
                this.showMessage({ vc:this, message:(this.isDetailPage || this.isEditPage ?'수정되었습니다.':'등록되었습니다.') })
                this.initContent()
                this.goListPage()
                this.$emit('event-update-list')
            })
            .catch((error) => {
                this.showError({ vc:this, error:error })
                Debug.log('error = ', error)
            })

        },
        updateHistory() {
            let items = []
            // 관련 종목, 주도주 추가
            for(let i = 0; i < this.historyList.length; i++) {
                let card = this.historyList[i]
                if(card.history) {
                    Debug.log('theme history')
                    let historyOptions = {}
                    if (card.theme === null) {
                        continue
                    }
                    // 220704 meej 테마히스토리 추가시 관련종목 필수 입력
                    if (card.history){
                        if(card.stocks.length == 0 || !card.stocks){
                            this.showError({vc:this, error: '테마의 관련종목을 추가해주세요.'} )
                            return
                        }
                    }
                    if (card.stocks && card.stocks.length > 0) {
                        var objStock = {}
                        for (var themeStock of card.stocks) {
                            objStock[themeStock.stockCode] = themeStock.stockName
                        }
                        historyOptions.STOCKS = optionsValue(objStock)
                    }
                    if (card.leads && card.leads.length > 0) {
                        var objLead = {}
                        for (var lead of card.leads) {
                            objLead[lead.stockCode] = lead.stockName
                        }
                        historyOptions.LEAD_STOCK = optionsValue(objLead)
                    }
                    // 220705 meej 차트 여부 추가
                    historyOptions.CHART = card.historyChart ? 1 : 0
                    // 220705 meej 삭제시 코멘트 추가
                    if(card.historyDel){
                        historyOptions.COMMENT = card.comments
                    }
                    let item = {
                        themeCode: card.theme.code,
                        themeName: card.theme.name,
                        content: card.content,
                        showFlag: (card.history?1:0),
                        options: objToString(historyOptions),
                        // 220705 meej 삭제여부 추가
                        delete : card.historyDel+"",
                    }
                    if (!isEmpty(card.id)) {
                        item.id = card.id
                    }

                    items.push(item)
                }
                
            }
            // 파라미터
            let params = {
                items: items,
                id: this.detailResult.news.id
            }
            if(items.length > 0) {
                this.showAlert({vc:this, message:'테마 히스토리만 수정됩니다.<br>테마히스토리를 저장하시겠습니까?'})
                .then(() => {
                    this.doUpdateHistory(params)
                })
            } else {
                // 220624 meej 멘트 수정
                this.showError({vc:this, error:'선택한 테마히스토리가 없습니다.'})
            }
        },
        doUpdateHistory(params) {

            this.SET_FEATURED_HISTORY(params)
            .then(() => {
                this.showMessage({ vc:this, message:'히스토리가 적용되었습니다.' })
                this.INIT_LIST()
                this.getList()
                this.goListPage()
            })
            .catch((error) => {
                Debug.log('error = ', error)
                this.showError({ vc:this, error:error })
            })
        },
        deleteItem() {
            if(this.detailResult === null) {
                this.showError({vc:this, error:'정확하지 않은 정보입니다.'})
                this.goListPage()
                return
            }
            this.showAlert({vc:this, message:'삭제하시겠습니까?'})
                .then(() => {
                    this.doDelete()
                })
        },
        doDelete(){

            const params = {}
            params.newsType = this.detailResult.news.newsType1
            params.id = this.detailResult.news.id

            this.DELETE_ITEM(params)
            .then(() => {
                this.showMessage({ vc:this, message:"삭제되었습니다." })
                this.goListPage()
                this.$emit('event-update-list')
            })
            .catch((error) => {
                this.showError({ vc:this, error:error })
                Debug.log('error = ', error)
            })
        },
        // 목록화면으로 이동하기
        goListPage() {
            //this.REQUEST_RESOURCE(null)
            this.INIT_CHECKLIST()
            this.initContent()
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        // 수정화면으로 이동하기
        goEditPage() {
            // 221103 meej 삭제시 수정안되도록 return
            if(this.featured.crud == "D") return
            Debug.log('goEditPage = ', this.pageStatus)
            if(this.isDetailPage) {
                this.setPageStatus(this.PAGE_STATUS.EDIT)
            } else if(this.isListPage) {
                this.setPageStatus(this.PAGE_STATUS.REGISTER)
            }
        },
        requestResource(index, type, date) {
            Debug.log('requestResource index = ', index, ', type = ', type, ', date = ', date)
            if(this.isListPage){
                this.setPageStatus(this.PAGE_STATUS.REGISTER)
            }
            if(type === 'Theme') {
                let req = {menu:'Theme', index:index, type: type}
                this.$emit('event-request-themestock', {code:-1, index:-1})
                this.$nextTick(function () {
                    this.REQUEST_RESOURCE(req)
                })
                Debug.log('requestResource req = ', req)
            } else {
                this.SET_CHECKLIST({stocks:(this.historyList[index].stocks ? this.historyList[index].stocks : []), leads:(this.historyList[index].leads? this.historyList[index].leads: [])})
                /* 220701 meej stockList조회시 newsDate 추가  */
                this.$emit('event-request-themestock', {code:this.historyList[index].theme.code, index:index, newsDate:date})
            }
        },
        addResource(res) {
            Debug.log('addResource res = ', res)
            let tempTitle = this.featured.themeName
            const count = res.items.length
            if(this.historyList.length === 1 && this.historyList[0].theme === null) {
                this.historyList = []
            }
            for(let i = 0; i < count; i++) {
                let flag = true
                /* 220622 meej 테마 중복 입력 방지 */
                for(let j =0;j<this.historyList.length;j++){
                    if(this.historyList[j].theme.code == res.items[i].code){
                        this.showError({ vc:this, error: '['+res.items[i].name+'] 는 이미 추가된 테마 입니다.' })
                        flag = false
                    }
                }
                if(flag){
                    if(tempTitle.length > 0) tempTitle += '/ '
                    let card = this.createThemeCard()
                    card.theme = res.items[i]
                    this.historyList.push(card)
                    tempTitle += card.theme.name
                }
            }
            this.featured.themeName = tempTitle
        },
        inputSummary(val) {
            Debug.log('inputSummary val = ',val)
            for(let i = 0; i < this.historyList.length; i++) {
                let card = this.historyList[i]
                if(card.history) {
                    card.content = val
                }
            }
        },
        inputFile(val) {
            this.file = val[0]
        },
    },
    watch: {
    },
    beforeCreate() {
        this.$parent.$off('sendResource')
    }

}
</script>