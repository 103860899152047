<template>
    <article>
        <q-toolbar>
            <q-space />
            <i-btn v-show="isDetailPage" label="취소" @click="goListPage()" />
        </q-toolbar>
        <q-scroll-area class="i-content-wrap">
            <q-card flat bordered>
                <q-card-section>
                    <!-- 등록 / 상세 수정 화면 -->
                    <div class="flex justify-between">
                        <q-radio
                            v-model="opinion.REPORT_TYPE0"
                            val="NOT_NEW"
                            label="신규아님"
                            :disable="opinion.crud == 'D'"
                        />
                        <q-radio
                            v-model="opinion.REPORT_TYPE0"
                            val="NEW"
                            label="신규"
                            :disable="opinion.crud == 'D'"
                        />
                        <q-radio
                            v-model="opinion.REPORT_TYPE0"
                            val="CHANGE_PIC"
                            label="담당자 변경"
                            :disable="opinion.crud == 'D'"
                        />
                        <q-radio
                            v-model="opinion.REPORT_TYPE0"
                            val="RESUME"
                            label="재개"
                            :disable="opinion.crud == 'D'"
                        />
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <i-datetime
                                date
                                dateLabel="제시일"
                                v-model="opinion.showDate"
                                :disable="opinion.crud == 'D'"
                            />
                        </div>
                        <div v-if="isDetailPage" class="col-6 q-pa-xs">
                            <i-field
                                inputLabel="입력일"
                                :text="FormatDate(opinion.createTime)"
                            />
                        </div>
                        <div v-else-if="isListPage" class="col-6 q-pa-xs">
                            <i-stock-search @get="getStock" label="종목검색" />
                        </div>
                    </div>

                    <div v-show="isListPage || isDetailPage">
                        <div class="row">
                            <div class="col-6 q-pa-xs">
                                <i-input
                                    v-model="reportType"
                                    inputLabel="타입"
                                    disable
                                />
                            </div>
                            <div class="col-6 q-pa-xs">
                                <i-input
                                    v-model="stockName"
                                    inputLabel="종목"
                                    disable
                                />
                                <!-- <i-input
                                    v-model="opinion.stockName"
                                    inputLabel="종목명"
                                    disable
                                /> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 q-pa-xs">
                                <i-select
                                    v-model="opinion.STOCK_COMPANY0"
                                    :options="stockCompanyOptions"
                                    inputLabel="증권사"
                                    option-label="name"
                                    option-value="code"
                                    :disable="opinion.crud == 'D'"
                                    @input="selectCompany()"
                                />
                            </div>
                            <div class="col-6 q-pa-xs">
                                <i-select
                                    v-model="opinion.OPINION0"
                                    :options="investOpinionOptions"
                                    inputLabel="투자의견"
                                    :disable="opinion.crud == 'D'"
                                />
                            </div>
                        </div>
                        <div class="q-pa-xs">
                            <i-input
                                v-model="opinion.PRICE"
                                inputLabel="금액"
                                :disable="opinion.crud == 'D'"
                                type="String"
                            />
                        </div>
                    </div>
                    <div v-show="isDetailPage">
                        <div class="q-pa-xs">
                            <i-field
                                :text="opinion.EXCLUSION0.label"
                                inputLabel="상태"
                            />
                        </div>
                        <div class="row flex q-pa-xs  justify-between">
                            <i-field
                                :text="opinion.content"
                                :color="TextColor(opinion.content)"
                                inputLabel="히스토리"
                                style="width:80%;"
                            />
                            <!-- 20220126 meej
                                글이 삭제상태가 아닐때, 히스토리가 있을때에만 히스토리 삭제 활성화 
                            -->
                            <div class="justify-end">
                                <i-btn 
                                    dense
                                    label="삭제"
                                    @click="delHistory()"
                                    :disabled="checkEmpty(opinion.content, opinion.EXCLUSION0)"
                                />
                            </div>
                        </div>
                        <i-datetime
                            class="q-pa-xs"
                            date
                            dateLabel="제외일"
                            v-model="opinion.EXCLUDED_DATE0"
                            :disable="opinion.crud == 'D'"
                        />
                    </div>
                </q-card-section>
            </q-card>
        </q-scroll-area>
        <div class="i-bottom-wrap q-my-md">
            <div v-show="isListPage" class="q-my-md">
                <i-btn class="full-width" :label="btnLabel" @click="validation_Write()" />
            </div>
            <div v-show="isDetailPage && opinion.crud != 'D'" class="row">
                <div class="col-12 col-sm-6 q-pa-xs">
                    <!-- <i-btn
                        
                        class="full-width"
                        label="재개"
                        @click="updateExclusion()"
                        style="width:30%"
                    /> -->
                    <i-btn
                        v-if="opinion.EXCLUSION0.value !== 1"
                        label="제외"
                        class="full-width"
                        @click="updateExclusion()"
                    />
                </div>
                <div class="col-12 col-sm-6 q-pa-xs">
                    <i-btn
                        class="full-width"
                        label="삭제"
                        @click="deleteNews()"
                    />
                </div>
            </div>
        </div>
    </article>
</template>
<script>
import Debug from '@/debug'
import { objToString, FormatDate, numberFormat, today } from '@/util'

import Button from '@/components/common/Button'
import StockSearch from '@/components/common/StockSearch'
import Input from '@/components/common/Input'
import Select from '@/components/common/Select'
import Field from '@/components/common/Field'
import DateTime from '@/components/common/DateTime'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Content',
    components: {
        'i-input': Input,
        'i-field': Field,
        'i-stock-search': StockSearch,
        'i-select': Select,
        'i-btn': Button,
        'i-datetime': DateTime,
    },
    props: {
        condition: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            newsType: 'STOCK_COMPANY_OPINION',
            investOpinionOptions: [],
            timeOptions: ['오전장', '오후장'],
            cardList: [],
            reportTypes: [
                { name: '신규아님', value: 'NOT_NEW' },
                { name: '신규', value: 'NEW' },
                { name: '담당자 변경', value: 'CHANGE_PIC' },
                { name: '재개', value: 'RESUME' },
            ],
            reportType: '',
        }
    },
    created() {
        // TODO: 증권사 목록 가져오기
        var params = {}
        params.newsType = this.newsType
        this.GET_STOCK_COMPANY(params)
        // 230314 meej created일때는 내용 완전 초기화
        // this.initData()
        this.INIT_DETAIL_NEW()

        // 221117 meej showDate 현재날짜로 들어오도록 추가
        this.opinion.showDate = today()
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            newsTypeList: (state) => state.resource.newsType,
            stockCompanyOptions: (state) => state.resource.stockCompany,
            opinion: (state) => state.stock_invest_opinion.item,
            list: (state) => state.stock_invest_opinion.list,
        }),
        ...mapGetters('page', ['isListPage', 'isDetailPage']),
        ...mapGetters('stock_invest_opinion', ['getFilterCompany']),

        btnLabel: function() {
            if (this.isListPage) {
                return '등록'
            } else if (this.isDetailPage) {
                return '수정'
            } else {
                return null
            }
        },
        stockName() {
            return this.opinion.stockName
                ? this.opinion.stockName + '(' + this.opinion.stockCode + ')'
                : ''
        },
        
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('stock_invest_opinion', [
            'SET_DETAIL',
            'INIT_DETAIL',
            'INIT_LIST',
            'INIT_DETAIL_NEW'
        ]),

        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions('resource', ['GET_STOCK_COMPANY']),
        ...mapActions('stock_invest_opinion', [
            'GET_FILTER_LIST',
            'WRITE_ITEM',
            'DELETE_ITEM',
            'DELETE_HISTORY',
        ]),
        initData() {
            this.INIT_DETAIL()
        },
        getStock(stock) {
            this.opinion.stockCode = stock.code
            this.opinion.stockName = stock.name
        },
        delHistory() {
            var params = {}
            params.id = this.opinion.id
            params.newsType = this.newsType
            var options = {}
            options.STOCK_COMPANY0 = this.opinion.STOCK_COMPANY0.code
            options.OPINION0 = this.opinion.OPINION0
            options.REPORT_TYPE0 = this.opinion.REPORT_TYPE0   
            var price = this.opinion.PRICE.replace(/,/gi, '').replace(/\s/g, '')
            options.PRICE = this.opinion.OPINION0 == 'NotRated' ? '-' : price            
            params.options = objToString(options)         
            this.showAlert({
                vc: this,
                message: '히스토리 내용을 삭제하시겠습니까?',
            }).then(() => {
            this.DELETE_HISTORY(params)
                .then(() => {
                    this.showMessage({ vc: this, message: '삭제 되었습니다.' })
                })
                .catch((err) => {
                    Debug.log('error = ', err)
                    this.showError({ vc: this, error: err })
                })                
                // this.opinion.content = ''
                this.getList()
            })
        },
        // flagToKR(value) {
        //     return
        // },
        // 증권사 선택에 따라 투자의견 옵션 변경
        selectCompany() {
            if (this.opinion.STOCK_COMPANY0) {
                var opinionString = this.stockCompanyOptions.find(
                    (v) => v.code == this.opinion.STOCK_COMPANY0.code
                ).opinion
                this.investOpinionOptions = opinionString.split(',')
               
                /* 
                    220310 meej 신규 입력시 증권사 투자의견 : BUY, 매수 기본 선택으로 설정
                */
                if (!this.isDetailPage) {
                    this.setInitInvest()
                }
            }
        },
        /* 
            220310 meej 증권사 투자의견 : BUY, 매수 기본 선택으로 설정
        */
        setInitInvest(){
            for(var i =0;i<this.investOpinionOptions.length;i++){
                if(this.investOpinionOptions[i].toUpperCase() == 'BUY' || this.investOpinionOptions[i] == '매수'){
                    this.opinion.OPINION0 = this.investOpinionOptions[i]
                }
            }
        },
        goListPage() {
            this.initData()
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        // 목록 조회
        getList() {
            this.INIT_LIST()

            /**
             * 20210721 shb
             * 등록,수정 완료뒤 등록한 증권사,종목 을 리스트에 나오도록 추가
             */
            this.GET_FILTER_LIST({
                code: this.opinion.stockCode,
            })
            this.INIT_DETAIL()
            this.goListPage()
        },

        updateExclusion() {
            if (this.opinion.EXCLUDED_DATE0 == today()) {
                this.showAlert({
                    vc: this,
                    message:
                        '제외일을 설정하지 않으면 당일로 제외일이 설정 됩니다.',
                }).then(() => {
                    this.doUpdateExclusion()
                })
            } else {
                this.doUpdateExclusion()
            }
        },

        // 제외
        doUpdateExclusion() {
            var message
            var params = {}
            params.id = this.opinion.id
            params.newsType = this.newsType
            params.stockCode = this.opinion.stockCode

            var options = {}
            options.EXCLUSION0 = this.opinion.EXCLUSION0.value == 1 ? 0 : 1
            options.EXCLUDED_DATE0 = this.opinion.EXCLUDED_DATE0

            params.options = objToString(options)

            this.WRITE_ITEM(params)
                .then(() => {
                    message = '상태가 변경되었습니다.'
                    this.showMessage({ vc: this, message: message })
                    this.getList()
                })
                .catch((err) => {
                    Debug.log('error = ', err)
                    var error = err.message
                    this.showError({ vc: this, error: error })
                })
        },
        deleteNews() {
            this.showAlert({
                vc: this,
                message: '투자의견을 삭제 하시겠습니까?',
            }).then(() => {
                this.doDelete()
            })
        },
        doDelete() {
            let params = {}
            params.id = this.opinion.id
            params.newsType = this.newsType

            /* 
                220311 meej 투자의견 삭제시 해당되는 히스토리가 있다면 같이 삭제처리 
             */
            if(this.opinion.content === undefined || this.opinion.content == null ){
                // 1. 히스토리가 없을 경우
                this.DELETE_ITEM(params)
                    .then(() => {
                        this.showMessage({ vc: this, message: '삭제 되었습니다.' })
                        this.getList()
                    })
                    .catch((err) => {
                        Debug.log('error = ', err)
                        this.showError({ vc: this, error: err })
                    })

            }else {
                // 2. 히스토리가 있을 경우
                var options = {}
                options.STOCK_COMPANY0 = this.opinion.STOCK_COMPANY0.code
                options.OPINION0 = this.opinion.OPINION0
                options.REPORT_TYPE0 = this.opinion.REPORT_TYPE0   
                var price = this.opinion.PRICE.replace(/,/gi, '').replace(/\s/g, '')
                options.PRICE = this.opinion.OPINION0 == 'NotRated' ? '-' : price            
                params.options = objToString(options)  

                this.DELETE_ITEM(params)
                    .then(() => {
                        this.DELETE_HISTORY(params)
                        this.showMessage({ vc: this, message: '삭제 되었습니다.' })
                        this.getList()
                        })
                    .catch((err) => {
                        Debug.log('error = ', err)
                        this.showError({ vc: this, error: err })
                    })       
            }
        },
        // 220902 meej 투자의견이 NotRated 일경우 신규로 선택되도록 처리_유효성 체크 분리
        validation_Write(){
            var message
            // TODO: 입력값 체크
            if (!this.opinion.stockCode || !this.opinion.STOCK_COMPANY0) {
                message = '내용을 입력해주세요.'
                this.showMessage({ vc: this, message: message })
                return
            }

            if (this.opinion.OPINION0 == "NotRated" && this.opinion.REPORT_TYPE0 != "NEW") {
                this.showAlert({
                    vc: this,
                    message: '투자의견을 "NotRated"로 선택했으나 "신규"가 아닙니다. <br/> 신규로 변경 후 등록하시겠습니까? <br/><br/> [OK] : 신규로 변경 후 등록 <br/> [CANCEL] : 그대로 등록 ',
                }).then(() => {
                    this.opinion.REPORT_TYPE0 = "NEW"
                }).finally(()=>{
                    this.write()
                })
            } else {
                this.write()
            }
        },
        write() {
            // 파라미터
            var params = {}
            params.newsType = this.newsType
            params.stockCode = this.opinion.stockCode

            var options = {}
            options.STOCK_COMPANY0 = this.opinion.STOCK_COMPANY0.code
            options.OPINION0 = this.opinion.OPINION0
            var price = this.opinion.PRICE.replace(/,/gi, '').replace(/\s/g, '')
            options.PRICE = this.opinion.OPINION0 == 'NotRated' ? '-' : price
            options.REPORT_TYPE0 = this.opinion.REPORT_TYPE0

            if (this.isDetailPage) {
                params.id = this.opinion.id
                params.content = this.opinion.content
                    ? this.opinion.content
                    : ''
            }

            params.options = objToString(options)
            //제시일 추가
            params.showDate = this.opinion.showDate

            /* 
                220420 meej 증권사 투자의견 입력시 제시일, 종목, 증권사 모두 동일한 데이터가 존재할 경우 alert
            */
            this.INIT_LIST() 
            this.GET_FILTER_LIST({ 
                code: this.opinion.stockCode,
            }).then(()=>{
                let flag = false
                // 동일 데이터 존재하는지 확인
                for(var i=0;i<this.list.length;i++){
                    // 정렬이 showDate로 되어 있으므로 첫 데이터의 showDate가 다를 경우 break
                    if(this.list[i].showDate !== params.showDate) break

                    if(this.list[i].showDate === params.showDate
                        && this.list[i].stockCode === params.stockCode
                        && this.list[i].STOCK_COMPANY0 === this.opinion.STOCK_COMPANY0.code){
                        flag = true // 존재 할 경우 true
                        break
                    }
                }
                if(flag){ // 동일 데이터 존재할 경우 확인 받은 후 등록 처리
                    this.showAlert({ vc: this, message: "[제시일, 종목, 증권사] 모두 동일한 데이터가 존재합니다. \n등록하시겠습니까?" })
                        .then(()=>{
                            this.register(params)
                        })
                }else{ // 존재 안 할 경우 바로 등록 처리
                    this.register(params)
                } 
            })
        },
        register(params){
            var message
            this.WRITE_ITEM(params)
                .then(() => {
                    message = this.btnLabel + '되었습니다.'
                    this.showMessage({ vc: this, message: message })
                    this.getList()
                })
                .catch((err) => {
                    Debug.log('error = ', err)
                    var error = err.message
                    this.showError({ vc: this, error: error })
                })
        },
        FormatDate(datetime) {
            return datetime ? FormatDate(datetime) : null
        },
        /*
         * 20210722 shb
         * 히스토리 텍스트 상향,하향 구분하여 파란색,빨간색 넣기
         */
        TextColor(text) {
            const re = new RegExp('[^\\d]', 'g')
            if (text !== undefined && text !== null) {
                const arr = text.split('->')
                if (arr.length > 1) {
                    const be = parseInt(arr[0].replace(re, ''))
                    const af = parseInt(arr[1].replace(re, ''))
                    if (be - af <= -1) {
                        // 증가
                        return 'red'
                    } else if (be - af > 0) {
                        // 감소
                        return 'blue'
                    } else {
                        return ''
                    }
                }
            }
        },
        /* 
            20220310 meej 글이 삭제상태가 아닐때, 히스토리가 있을때에만 히스토리 삭제 활성화, 조건 수정
        */
        checkEmpty(content, state){
            if( content == null || content == '' || state.label == '삭제'){
                return true;
            }
            return false;
        }
    },
    watch: {
        opinion(val, old) {
            Debug.log('opinion = ', val)
            /* 
                220428 meej 증권사가 다를 경우만 목록 새로 가져오는 조건 추가
            */
            if (val !== old) {
                if(val.STOCK_COMPANY0 != old.STOCK_COMPANY0){
                    this.selectCompany()
                }
                if (val.stockCode) {
                    let type = this.reportTypes.find(
                        (v) => v.value === val.REPORT_TYPE0
                    )
                    this.reportType = type.name
                } else {
                    this.reportType = ''
                }
            }
        },
        'opinion.PRICE': function() {
            this.opinion.PRICE = numberFormat(this.opinion.PRICE, '')
        },
        /* 220428 meej 상세내용에서 증권사 수정할경우 있는 투자의견이면 그대로 두고 없는 투자의견일때만 기본값으로 설정해줌 */
        'opinion.STOCK_COMPANY0': function(val, old){
            if(this.isDetailPage){
                if(val != old){
                    var flag = true
                    this.investOpinionOptions.forEach((el)=>{
                        if (el == this.opinion.OPINION0){
                            flag = false
                        }
                    })
                    if(flag){
                        this.setInitInvest()
                    }
                }
            }
            
            // 221115 meej 증권사, 종목 선택 시 리스트 조회
            if(!this.isDetailPage &&this.opinion.STOCK_COMPANY0 != null && this.opinion.STOCK_COMPANY0 != ""
                && this.opinion.stockCode != null && this.opinion.stockCode != ""){
                this.$emit('event-get-list', this.opinion)
            }
        },
        // 220902 meej 투자의견이 NotRated 일경우 신규로 선택되도록 처리
        'opinion.OPINION0': function(val){
            if(val == "NotRated" && this.opinion.REPORT_TYPE0 != "NEW"){
                this.opinion.REPORT_TYPE0 = "NEW"
            }
        },
        // 221115 meej 증권사, 종목 선택시 리스트 조회
        'opinion.stockCode': function(){
            if(!this.isDetailPage && this.opinion.STOCK_COMPANY0 != null && this.opinion.STOCK_COMPANY0 != ""
                && this.opinion.stockCode != null && this.opinion.stockCode != ""){
                this.$emit('event-get-list', this.opinion)
            }
        }
    },
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='number'] {
    -moz-appearance: textfield;
}
</style>
