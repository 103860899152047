<template>
    <section class="row">

        <div class="changeRow q-ma-md" style="width:750px">
        <p class="q-ma-md">미국등락률 볼드표시 기준(%)</p> 
            <i-input v-model="usRate" style="width: 50px;margin-left:320px" />
            <p class="changeRow2" style="margin-left: 60px; margin-top: -38px"><i-btn class="q-ml-md" label="수정" @click="onClick()"/></p>   
        <hr class="hrStyle">
        <span class="changeRow q-ma-md">테마등락률 </span>
        <p class="changeRow2"><i-btn class="q-ml-md" label="업데이트" @click="onClick2()"/></p> 
        <hr class="hrStyle">
        <p class="changeRow2"><q-checkbox label="조간상단표 멘트삽입" v-model="mentCheckAm" size="xs" /><i-btn class="q-ml-md" label="수정" @click="onClick3('am')"/></p> 
        <textarea
            v-model="mentTextAm"
            cols="100"
            rows="2"
        ></textarea>          
        <hr class="hrStyle">
        <p class="changeRow2"><q-checkbox label="석간상단표 멘트삽입" v-model="mentCheckPm" size="xs" /><i-btn class="q-ml-md" label="수정" @click="onClick3('pm')"/></p>         
        <textarea
            v-model="mentTextPm"
            cols="100"
            rows="2"
        ></textarea>        
        </div>
    </section>
</template>
<script>
import Input from '@/components/common/Input.vue'
import Button from '@/components/common/Button.vue'

import {  mapState, mapActions } from 'vuex'
//market_news
export default {
    name: 'USRate',
    components: {
        'i-input' : Input,
        'i-btn' : Button,
    },
    data () {
        return {
            mentCheckAm: false,
            mentCheckPm: false,
            usRate:'',
            mentTextAm:'',
            mentTextPm:''
        }
    },
    created(){
        this.GET_SETTINGS()
        .then((res)=> {
            this.usRate = (res===undefined? '': res.US_RATE);
            this.mentTextAm = (res===undefined? '': res.MENTAM);
            this.mentTextPm = (res===undefined? '': res.MENTPM);
            this.mentCheckAm = (res===undefined? false: res.MENTCHECKAM=='0'?false:true);
            this.mentCheckPm = (res===undefined? false: res.MENTCHECKPM=='0'?false:true);
        })

    },
    computed : {
        ...mapState ({
            settings: state => state.resource.settings
        }),

    },
    methods: {
        ...mapActions( 'alert', ['showError', 'showMessage']),
        ...mapActions( 'resource', ['SET_SETTINGS', 'GET_SETTINGS']),
        ...mapActions('theme', ['INSER_THEME_RATE']),

        onClick() {
            let params = {
                items:[{
                    US_RATE:this.usRate
                }]
            }
            this.SET_SETTINGS(params)
            .then(()=>{
                this.showMessage({vc:this, message:'업데이트 완료'})
            })
            .catch((err) => {
                this.showError({ vc:this, error:err})
            })
        },
        onClick2() {
            let params = {
            }            
            this.INSER_THEME_RATE(params)
            .then(()=>{
                this.showMessage({vc:this, message:'업데이트 완료'})
            })
            .catch((err) => {
                this.showError({ vc:this, error:err})
            })
        },
        onClick3(apm) {
            console.log(apm)
            let params;
            if(apm==='am'){
                params = {
                    items:[{
                        MENTAM:this.mentTextAm.replace('\n','<br>')                    
                    },{
                        MENTCHECKAM:this.mentCheckAm==true?'1':'0'
                    }
                    ]
                }
            }
            else{
                params = {
                    items:[{
                        MENTPM:this.mentTextPm.replace('\n','<br>')                    
                    },{
                        MENTCHECKPM:this.mentCheckPm==true?'1':'0'
                    }
                    ]
                }                
            }
            this.SET_SETTINGS(params)
            .then(()=>{
                this.showMessage({vc:this, message:'업데이트 완료'})
            })
            .catch((err) => {
                this.showError({ vc:this, error:err})
            })
        },            
    },
    watch: {
        settings(val, old) {
            if(val !== old) {
                this.usRate = (this.settings===undefined? '': this.settings.US_RATE);
            }
        }
    }

}
</script>
<style>
.changeRow {
    text-align: center;
    margin: auto;
}

.changeRow2 {
    margin-top: 10px;
    margin-left: -5%;
}

.hrStyle {
    margin-top: 50px;
    margin-bottom: 40px;
    size: 10px;
    color: gray;
    width: 100%;
}
</style>
