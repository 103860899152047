<template>
    <article>
        <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : list.length == 0 }"
            :columns="columns" :data="list" row-key="RNUM" @row-click="getView" :rows-per-page-options="[0]" style="height:auto;"
            hide-pagination no-data-label="조회되는 데이터가 없습니다.">
            <template v-slot:no-data="{ icon, message }"><div class="full-width row flex-center q-gutter-sm"><q-icon size="2em" :name="icon" /> <span>{{message}}</span></div></template>
        </q-table>
        <div v-show="list.length != 0" class="flex flex-center q-mt-md">
            <q-pagination
            v-model="pagination.page"
            :max="pagesNumber"
            :max-pages="pagination.maxPages"
            :direction-links="true"
            :boundary-numbers="false"
            :boundary-links="true"
            :ellipses="false"
            @click="getList()"
        />
        </div>
    </article>
</template>
<script>
import CONSTANTS from '@/constants'
import { FormatDateTime } from '@/util'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    data () {
        return {
            columns: [
                { headerStyle:'text-align:center;', style:'width:100px;', name: 'writerName', label: '작성자', align: 'center', field:'writerName' },
                { headerStyle:'text-align:center;', style:'width:145px;', name: 'createTime', label: '등록일자', align: 'center', field: (row) => FormatDateTime(row.createTime) },
                { headerStyle:'text-align:center;', style:'width:60px;', name: 'hits', label: '조회수', align: 'center', field:'hits' },
                { headerStyle:'text-align:center;', style:'width:*;', name: 'title', label: '제목', align: 'left', field:'title' }
              ],
            pagination: {
                page: 1,
                rowsPerPage: CONSTANTS.LIST_COUNT_PER_PAGE,
                maxPages: 5
            }
        }
    },
    computed : {
        ...mapState('manage', ['item', 'list', 'totcount']),
        pagesNumber () {
            return Math.ceil(Number(this.totcount) / Number(this.pagination.rowsPerPage));
        }
    },
    created () {
        this.SET_ITEM(null)
        this.getList();
    },
    methods: {
        ...mapMutations('manage', ['INIT_DATA', 'INIT_ITEM', 'SET_ITEM', 'SET_DATA_INBOARD']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions('manage', ['GET_CALL']),
        //리스트
        getList() {
            let param = {
                keyword: this.$parent.keyword,
                pageStr: (Number(this.pagination.page) - 1) * Number(this.pagination.rowsPerPage),
                pageSize: this.pagination.rowsPerPage,
                CALL_API_URL: '/inboard/list'
            }
            this.GET_CALL(param).then((data) => {
                this.SET_DATA_INBOARD(data);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        //상세
        getView(event, item) {
            if(item == null || item.id == null || item.id == undefined){
                this.INIT_ITEM();
                this.SET_ITEM(item);
                return;
            }
            let param = {
                'id': item.id,
                'CALL_API_URL': '/inboard/view'
            }
            this.GET_CALL(param).then((data) => {
                this.SET_ITEM(data.item);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
    }
}
</script>
