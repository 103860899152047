import api from '@/services/InfostockService'
import Debug  from '@/debug'


const state = () => ({
    newsType: 'STOCK_COMPANY_OPINION',
    list: [],
    nextKey: null,
    item: {
        id: '',
        showTime: '',
        createTime: '',
        stockCode: '',
        stockName: '',
        STOCK_COMPANY0: '',
        OPINION0: '',
        PRICE: '',
        EXCLUSION0: '',
        HISTORY: '',
        REPORT_TYPE0: ''
    },
    options: null
})

const getters = {
}

const mutations = {
    SET_LIST(state, { data }) {
        var arr = state.list
        arr.push(...data.items)

        // 인덱스 추가
        arr.forEach((row, index) => {
            row.index = index
        })

        state.list = arr
        state.nextKey = data.nextKey
    },
    /*
    * 20210721 shb
    * 등록,수정 완료뒤 등록한 증권사,종목 을 리스트에 나오도록 추가
    */
    SET_FILTER_LIST(state, { data }) {
        var arr = state.list
        arr.push(...data.items)
        arr = arr.filter((item) => {
            return item.STOCK_COMPANY0 === state.item.STOCK_COMPANY0.code
        })

        // 인덱스 추가
        arr.forEach((row, index) => {
            row.index = index
        })

        state.list = arr
        state.nextKey = data.nextKey
    },
    SET_DETAIL(state, item) {

        state.item = item
    },
    SET_OPTIONS(state, options) {
        state.options = options
    },
    INIT_LIST(state) {
        state.list = []
        state.nextKey = null
    },
    INIT_DETAIL(state) {
        /* 
            220425 meej 등록 후에도 이전 입력했던 (제시일, 증권사, 투자의견) 다음 입력시 남아있게 처리
            220902 meej 투자의견이 NotRated 일경우 신규로 선택되도록 처리
        */
        state.item = {
            id: '',
            showDate: state.item.showDate,
            showTime: '',
            createTime: '',
            stockCode: '',
            stockName: '',
            STOCK_COMPANY0: state.item.STOCK_COMPANY0,
            OPINION0: state.item.OPINION0,
            PRICE: '',
            EXCLUSION0: '',
            HISTORY: '',
            REPORT_TYPE0: state.item.OPINION0 == 'NotRated' ? 'NEW' : 'NOT_NEW'
        }
    },
    // 230314 meej 내용 초기화
    INIT_DETAIL_NEW(state) {
        state.item = {
            id: '',
            showDate: '',
            showTime: '',
            createTime: '',
            stockCode: '',
            stockName: '',
            STOCK_COMPANY0: '',
            OPINION0: '',
            PRICE: '',
            EXCLUSION0: '',
            HISTORY: '',
            REPORT_TYPE0: 'NOT_NEW'
        }
    },
}

const actions = {
    // 목록 조회
    async GET_LIST({ state, commit }, params) {
        params.newsType = state.newsType
        params.nextKey = state.nextKey
        /* 
            220506 meej 투자의견 검색 시 삭제된 내용도 나오게 처리
        */
        params.delete = true
        Debug.log('GET_LIST params', params)
        const { data } = await api.stockNewsList(params)
        Debug.log('GET_LIST data', data)

        commit('SET_LIST', { data })
    },
    async GET_FILTER_LIST({ state, commit }, params) {
        params.newsType = state.newsType
        params.nextKey = state.nextKey
        /* 
            220506 meej 투자의견 검색 시 삭제된 내용도 나오게 처리
        */
        params.delete = true
        Debug.log('GET_LIST params', params)
        const { data } = await api.stockNewsList(params)
        Debug.log('GET_LIST data', data)

    commit('SET_FILTER_LIST', { data })
    },
    // 상세 조회
    GET_DETAIL({ state }, params) {
        params.newsType = state.newsType

        Debug.log('GET_DETAIL params', params)
        return new Promise((resolve, reject) => {
            api.stockNewsDetail(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 작성 및 수정
    async WRITE_ITEM({ state }, params) {
        params.newsType = state.newsType

        Debug.log('WRITE_ITEM params', params)
        const { data } = await api.stockNewsWrite(params)
        Debug.log('WRITE_ITEM data', { data })
    },
    //  삭제
    DELETE_ITEMS(_, params) {
        Debug.log('DELETE_ITEMS params', params)

        return new Promise((resolve, reject) => {
            api.stockNewsMultiDelete(params)
                .then((response) => {
                    resolve(response.data)
                    Debug.log('DELETE_ITEMS data', response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    //  삭제 -1개
    DELETE_ITEM(_, params) {
        Debug.log('DELETE_ITEMS params', params)

        return new Promise((resolve, reject) => {
            api.stockNewsDelete(params)
                .then((response) => {
                    resolve(response.data)
                    Debug.log('DELETE_ITEMS data', response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    DELETE_HISTORY(_, params) {
        Debug.log('DELETE_HISTORY params', params)

        return new Promise((resolve, reject) => {
            api.stockNewsOpinionHistoryDelete(params)
                .then((response) => {
                    resolve(response.data)
                    Debug.log('DELETE_HISTORY data', response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },    
}

export const stock_invest_opinion = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
