<template>
    <article>
        <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : list.length == 0 }"
            :columns="columns" :data="list" row-key="RNUM" :rows-per-page-options="[0]" style="height:100%;"
             hide-pagination no-data-label="조회되는 데이터가 없습니다.">
            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th v-for="col in props.cols" :key="col.name" :props="props">
                        <div v-if="col.name == 'checked'"><q-checkbox v-model="allCheck" dense @input="getAllCheck()"/></div>
                        <div v-else>{{ col.label }}</div>
                    </q-th>
                </q-tr>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                <!-- :style="(role == 'superuser' || role == 'poweruser') && props.row.status != 'cnfirm' ? 'background-color:rgb(220 240 230);' : ''"> -->
                    <q-td v-for="col in props.cols" :key="col.name" :props="props">
                        <div v-if="col.name == 'sendTime'" class="userCss" style="width:60px;">
                            {{ col.value }}
                            <q-input v-model="props.row[col.name]" mask="time" @input="getTime(props.row)" outlined dense input-class="text-center"/>
                        </div>
                        <div v-else-if="col.name == 'stocks1' || col.name == 'stocks2' || col.name == 'stocks3' || col.name == 'stocks4' || col.name == 'stocks5'" class="userCss" style="width:130px;">
                            {{ col.value }}
                            <q-select v-model="props.row[col.name]" :options="stockOptions" option-label="name" @input="getSelectChk(props.row, col.name)" @filter="filterFn" input-debounce="0" dense outlined :hide-selected="hide_selected" :fill-input="hide_selected" use-input hide-dropdown-icon options-dense>
                                <q-tooltip anchor="top middle" self="bottom middle" :offset="[5, 5]">한글로 검색 시, enter를 눌러주세요.</q-tooltip>
                                <template v-slot:no-option><q-item><q-item-section class="text-grey">검색된 데이터가 없습니다.</q-item-section></q-item></template>
                            </q-select>
                        </div>
                        <div v-else-if="col.name == 'title'"><span @click="getViewPopup(props.row)" style="color:#551A8B;text-decoration:underline;cursor:pointer;">{{ col.value }}</span></div>
                        <div v-else-if="col.name == 'copyButton'">
                            <span class="q-pa-xs"><q-btn size="xs" label="간편저장" @click="getSave(props.row)"/></span>
                            <span class="q-pa-xs"><q-btn size="xs" label="복사" @click="getCopy(props.row)"/></span>
                        </div>
                        <div v-else-if="col.name == 'button'"><q-btn size="xs" outline color="primary"  label="수정" @click="getView(props.row)"/> <q-btn size="xs" label="삭제" color="red" @click="getDelete(props.row)"/></div>
                        <div v-else-if="col.name == 'checked'"><q-checkbox v-model="props.row.checked" dense/></div>
                        <div v-else>{{ col.value }}</div>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:no-data="{ icon, message }"><div class="full-width row flex-center q-gutter-sm"><q-icon size="2em" :name="icon" /> <span>{{message}}</span></div></template>
        </q-table>
        <div v-show="list.length != 0" class="flex flex-center q-mt-md">
            <q-pagination
            v-model="pagination.page"
            :max="pagesNumber"
            :max-pages="pagination.maxPages"
            :direction-links="true"
            :boundary-numbers="false"
            :boundary-links="true"
            :ellipses="false"
            @click="getList()"
        />
        </div>
        <q-dialog v-model="viewPopup" persistent>
            <q-card style="min-width: 80%;overflow-y:hidden">
                <q-card-actions align="right" class="text-primary" style="height:50px;">
                    <q-btn color="white" text-color="black" label="닫기" v-close-popup/>
                </q-card-actions>
                <q-card-section v-html="viewContent" style="height:700px;overflow-y:scroll;"></q-card-section>
            </q-card>
        </q-dialog>
    </article>
</template>
<script>
import { FormatDate, FormatTime, stringToMapValue, stringToMapStockValue } from '@/util'
import { mapState, mapMutations, mapActions } from 'vuex'
import CONSTANTS from '@/constants'
import debug from '@/debug'
export default {
    name: 'List',
    props: {
        hide_selected: {
            type: Boolean,
            default: true
        },
        trnsmis: null,
        sendDate: null
    },
    data () {
        return {
            viewPopup: false,
            viewContent: '',
            trClass: '',
            allCheck: false,
            selected: [],
            stock: '',
            stockOptions: [],
            stockList: [],
            columns: [
                { headerStyle:'text-align:center;', name: 'sendDate', label: '날짜', align: 'center', field: (row) => FormatDate(row.sendDate) },
                { headerStyle:'text-align:center;', name: 'sendTime', label: '시간', align: 'center', field: (row) => FormatTime(row.sendTime) },
                { headerStyle:'text-align:center;', name: 'stocks1',  label: '종목1', align: 'center', field: (row) => stringToMapStockValue('stocks1', row.stocks, this.stockList) },
                { headerStyle:'text-align:center;', name: 'stocks2',  label: '종목2', align: 'center', field: (row) => stringToMapStockValue('stocks2', row.stocks, this.stockList) },
                { headerStyle:'text-align:center;', name: 'stocks3',  label: '종목3', align: 'center', field: (row) => stringToMapStockValue('stocks3', row.stocks, this.stockList) },
                { headerStyle:'text-align:center;', name: 'stocks4',  label: '종목4', align: 'center', field: (row) => stringToMapStockValue('stocks4', row.stocks, this.stockList) },
                { headerStyle:'text-align:center;', name: 'stocks5',  label: '종목5', align: 'center', field: (row) => stringToMapStockValue('stocks5', row.stocks, this.stockList) },
                { headerStyle:'text-align:center;', name: 'title',  label: '제목', align: 'left', field:'title'},
                { headerStyle:'text-align:center;', name: 'copyButton',  label: '간편저장/복사', align: 'center'},
                { headerStyle:'text-align:center;', name: 'button',  label: '수정/삭제', align: 'center'},
                { headerStyle:'text-align:center;', name: 'checked',  label: 'checked', align: 'center'}
              ],
            page:1,
            pagination: {
                page: 1,
                rowsPerPage: CONSTANTS.LIST_COUNT_PER_PAGE,
                maxPages: 1
            }
        }
    },
    computed: {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            stockItems : state => state.resource.stockItems
        }),
        ...mapState('manage', ['list', 'totcount']),
        pagesNumber () {
            return Math.ceil(Number(this.totcount) / Number(this.pagination.rowsPerPage));
        }
    },
    created () {
        this.getSelectInit();
    },
    methods: {
        ...mapMutations('manage', ['SET_DATA', 'INIT_DATA_VIEW', 'SET_DATA_VIEW']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        //리스트
        getList(trnsmis) {
            let nextKey = null
            if(this.page < this.pagination.page) {
                nextKey = this.list[this.list.length-1].lastUpdateTime
            } else if(this.page > this.pagination.page) {
                nextKey = this.list[0].lastUpdateTime
            }
            this.page = this.pagination.page
            if(this.page == 1) nextKey = null
        
            debug.log('getList = ', trnsmis)
            var sTrnsmisId = "";
            if(trnsmis != null ){
                sTrnsmisId = trnsmis.trnsmisId;
            }       
            else{
                sTrnsmisId = this.trnsmis.trnsmisId;
            }
            let param = {
                client: sTrnsmisId,
                sendDate: this.$parent.sendDate.replaceAll("-",""),
                pageStr: (Number(this.pagination.page) - 1) * Number(this.pagination.rowsPerPage),
                pageSize: this.pagination.rowsPerPage,
                nextKey:nextKey,
                CALL_API_URL: '/irflash/list'
            }         
            this.GET_CALL(param).then((data) => {
                data.items.forEach(row => {
                    row.checked = this.allCheck;
                });
                this.SET_DATA(data);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        //상세
        getView(item) {
            this.INIT_DATA_VIEW();
            var sId = "";
            if(item != null && item.id != null){
                sId = item.id;
            }
            let param = {
                'id': sId,
                'CALL_API_URL': '/irflash/view'
            }
            this.GET_CALL(param).then((data) => {
                data.itemList = [{'trnsmisId':'', 'trnsmisNm':'선택'}].concat(data.itemList);
                this.SET_DATA_VIEW(data);
                this.$parent.$refs.iRflashContent.getSelectInit(data.item);
                this.$parent.getUpdatePopup(item);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        //간편저장
        getSave(obj) {
            if (obj.sendTime == "") {
                this.showMessage({ vc:this, message:'전송시간을 입력하세요.' });
                return;
            }
            this.showAlert({ vc:this, message:'<span style="color:#1976D2;">간편저장</span> 하시겠습니까?' }).then(()=>{
                var sStocks = "";
                var iCnt = 1;
                for(var i = 1; i < 6; i++){
                    var sTmp = "";
                    if(obj['stocks'+i] == null){
                        sTmp = stringToMapValue('stocks'+ i, obj.stocks);
                    }else{
                        sTmp = obj['stocks'+i].code;
                    }
                    if(sTmp != ""){
                        sStocks += "stocks" + (iCnt++) + ":" + sTmp + ",";
                    }
                }
                let param = {
                    'id': obj.id,
                    'sendTime': obj.sendTime,
                    'stocks': sStocks,
                    'CALL_API_URL': '/irflash/save'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'간편저장 되었습니다.' });
                    this.getList();
                }).catch((err)=>{
                    this.showError({ vc:this, error:err });
                });
            }).catch(() => { });
        },
        //복사
        getCopy(obj) {
            if (obj.sendTime == "") {
                this.showMessage({ vc:this, message:'전송시간을 입력하세요.' });
                return;
            }
            this.showAlert({ vc:this, message:'<span style="color:#1976D2;">복사</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    'title': obj.title,
                    'content': obj.content,
                    'client': obj.client,
                    'sendDate': obj.sendDate,
                    'sendTime': obj.sendTime,
                    'stocks': obj.stocks,
                    'CALL_API_URL': '/irflash/save'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'복사 되었습니다.' });
                    this.getList();
                }).catch((err)=>{
                    this.showError({ vc:this, error:err });
                });
            }).catch(() => { });
        },
        //삭제
        getDelete(obj) {
            this.showDeleteAlert({ vc:this, message:'<span style="color:red;">삭제</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    'id': obj.id,
                    'CALL_API_URL': '/irflash/delete'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'삭제 되었습니다.' });
                    this.$parent.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },
        //선택삭제
        getDeleteChk() {
            var iCnt = 0;
            var ds_list = [];
            this.list.forEach(row => {
                if(row.checked){
                    iCnt++;
                    ds_list.push({
                        id : row['id']
                    });
                }
            });
            if(iCnt == 0){
                this.showMessage({ vc:this, message:'체크항목을 선택하세요.' });
                return;
            }
            this.showDeleteAlert({ vc:this, message: iCnt+'건 <span style="color:red;">선택삭제</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    ds_list,
                    'CALL_API_URL': '/irflash/deleteChk'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'선택삭제 되었습니다.' });
                    this.$parent.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },
        filterFn (val, update, abort) {
            // 종목검색 시, 1자리 이하일 경우 검색되지 않음.
            if (val.length < 2) {
                abort();
                return;
            }
            update(() => {
                const needle = val.toLowerCase();
                this.stockOptions = this.stockList.filter(
                    v => v.name.toLowerCase().indexOf(needle) > -1 || v.code.indexOf(val) > -1
                );
            })
        },
        //종목체크
        getSelectChk(obj, name){
            for(var i = 1; i < 6; i++){
                if(obj["stocks"+i] != null && ("stocks"+i) != name && obj["stocks"+i].code == obj[name].code){
                    this.showError({ vc: this, error: '같은 종목이 포함되어 있습니다.' });
                    obj[name] = null;
                }
            }
        },
        getSelectInit(){
            var list = [];
            try {
                list = JSON.parse(this.stockItems);
            } catch (e) {
                list = this.stockItems;
            }
            var ds_list = [];
            list.forEach(row => {
                ds_list.push({
                    code : row['code'],
                    name : row['name'] + ' (' + row['code'] + ')'
                });
            });
            this.stockList = ds_list;
        },
        //전체선택
        getAllCheck(){
            this.list.forEach(row => {
                row.checked = this.allCheck;
            });
        },
        getTime(obj){
            obj["sendTime"] = obj.sendTime.replace(/:/gi,'');
        },
        //팝업
        getViewPopup(obj) {
            this.viewContent = obj.content;
            this.viewPopup = true;
        },
    },
    watch: {
        trnsmis: function(val, old) {
            if (val != old) {
                this.getList()
            }
        }
    }
}

</script>
<style type="text/css">
    .userCss .q-field__control{ padding:0 3px 0 3px !important; height: 25px !important; min-height: 25px !important; }
    .userCss .q-field__native{ padding:0 3px 0 3px !important; height: 25px !important; min-height: 25px !important; }
    .userCss .q-field{ padding:0 3px 0 3px !important; height: 25px !important; min-height: 25px !important; }
</style>
