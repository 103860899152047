<!-- 20230920 광고매크로 -->
<template>
    <article>
        <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : list.length == 0 }"
             :columns="columns" :data="list" row-key="RNUM" :rows-per-page-options="[0]" style="height:auto;"
             hide-pagination no-data-label="조회되는 데이터가 없습니다.">
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td v-for="col in props.cols" :key="col.name" :props="props">
                        <q-btn size="xs" label="재전송" @click="reSend(selectedItem)" color="grey" style="width:2800px; height: 1100px;"/>
                    </q-td>
                </q-tr>
            </template>
        </q-table>
  </article>
</template>
<script>
import {  isEmpty, nowTime, today } from '@/util'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import debug from '@/debug'
import CONSTANTS from '@/constants'

export default {
  name: 'MacroList',
  props: {
      hide_selected: {
          type: Boolean,
          default: true
      },
      trnsmis: null,
      sendDate: null
  },
  data () {
      return {
          nowTime:nowTime("HHmm"),
          today: today("YYYYMMDD"),
          viewPopup: false,
          viewContent: '',
          trClass: '',
          allCheck: false,
          selected: [],
          stock: '',
          stockOptions: [],
          stockList: [],
          columns: [
            //   { headerStyle:'text-align:center;', name: 'sendDate', label: '전송 날짜', align: 'center', style:'width: 40px', field: (row) => FormatDate(row.sendDate) },
            //   { headerStyle:'text-align:center;', name: 'sendTime', label: '시간', align: 'center', style:'width: 60px', field: (row) => FormatTime(row.sendTime) },
            //   { headerStyle:'text-align:center;', name: 'title',  label: '제목', align: 'left', field:'title'},
              { headerStyle:'text-align:center;', name: 'button',  label: '재전송', align: 'center', style:'width: 80px'},
            ],
            page: 1,
          pagination: {
              page: 1,
              rowsPerPage: CONSTANTS.LIST_COUNT_PER_PAGE,
              maxPages: 1
          },
          selectedItem: null
      }
  },
  computed: {
      ...mapState ({
        //   role : state => state.session.role,
        //   userId : state => state.session.userId,
          stockItems : state => state.resource.stockItems
      }),
      ...mapState('manage', ['list', 'totcount']),
  },
  methods: {
      ...mapMutations('manage', ['SET_DATA', 'INIT_DATA_VIEW', 'SET_DATA_VIEW']),
      ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
      ...mapActions('manage', ['GET_CALL']),
      ...mapGetters ({
          today : 'calendar/today'
      }),
      notSend(val) {
          return isEmpty(val)
      },
      //리스트
      getList() {
          debug.log('getList = ', this.pagination.page, this.page)
          var sTrnsmisId = "";
          if(this.trnsmis != null && this.trnsmis.trnsmisId != ''){
              sTrnsmisId = this.trnsmis.trnsmisId;
          }
          let sendDate = null
          if(this.sendDate && this.sendDate.includes("-")) {
              sendDate = this.sendDate.replaceAll("-", "")
              // console.log('sendDate', this.sendDate)
          }
          let param = {
              client: sTrnsmisId,
              sendDate: sendDate, // this.sendDate,
              pageStr: (Number(this.pagination.page) - 1) * Number(this.pagination.rowsPerPage),
              pageSize: this.pagination.rowsPerPage,
              // nextKey:nextKey,
              CALL_API_URL: '/adnews/adList'
          }
          this.GET_CALL(param).then((data) => {
              data.items.forEach(row => {
                  row.checked = this.allCheck;
                  // 220711 meej 광고번호 newsType2 -> adNum 수정 options 값 받아옴, replaceAll 처리 추후 보완해야함
                  if(row.options != null){
                      // row.adNum = row.options.replaceAll("AD_NUM:","")
                      const adNum = row.options.replaceAll("AD_NUM:","")
                      if(adNum != "null"){
                          row.adNum = adNum
                      }
                  }
              });
              this.SET_DATA(data);
            //   console.log("데이타 : ", data)
              if (data.items && data.items.length > 0) {
                this.selectedItem = data.items;
            }
          }).catch((error) => {
              this.showError({ vc:this, error:error })
          });
      },
      //팝업
      getViewPopup(obj) {
          debug.log('getViewPopup = ', obj)
          this.viewContent = obj.content;
          this.viewPopup = true;
      },
      reSend(item){
        console.log("item 리스트들 : ", item);
        let param = {
              item: item,
              CALL_API_URL: '/adnews/reSend',
        }
        this.GET_CALL(param).then(() => {
            console.log("param 리스트들 : ", param);
            this.selectedItem = null
            this.getList();
            }).catch((err)=>{
                this.showError({ vc:this, error:err });
        });
      }
  },
  watch: {
      trnsmis: function(val, old) {
          if (val != old) {
              this.getList()
          }
      }
  }
}

</script>
<style type="text/css">
  .userCss .q-field__control{ padding:0 6px 0 6px !important; height: 25px !important; min-height: 25px !important; }
  .userCss .q-field__native{ padding:0 6px 0 6px !important; height: 25px !important; min-height: 25px !important; }
  .userCss .q-field{ padding:0 6px 0 6px !important; height: 25px !important; min-height: 25px !important; }
</style>
