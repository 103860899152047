import api from '../../../services/InfostockService'
import debug from '../../../debug'

const state = () => ({
  initEvents: {},
})

const mutations = {
  setList(state, data) {
    state.initEvents = data.dataArray
  },
}

const actions = {
  GET_CALL(_, params) {
    debug.log('GET_CALL params', params);
    return new Promise((resolve, reject) => {
      api.call(params)
        .then((response) => {
          resolve(response.data);
          debug.log('GET_CALL data', response.data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  },

  async GET_LIST(_,params) {
    return new Promise((resolve, reject) => {
      api.adminSchedule(params)
        .then((response) => {
          // 22.03.08 한수예
          /* startTime, endTime 시간 형태 변경 0900 -> 09:00 */
          // [2], [4] startTime, endTime
          // var getstartTime = ''
          // var getEndTime = ''
          response.data.items.forEach((el) => {
            // getstartTime = e[Object.keys(e)[2]].substr(0, 2) + ':' + e[Object.keys(e)[2]].substr(2, 2)
            // getEndTime = e[Object.keys(e)[4]].substr(0, 2) + ':' + e[Object.keys(e)[4]].substr(2, 2)
            // e[Object.keys(e)[2]] = getstartTime
            // e[Object.keys(e)[4]] = getEndTime
            // 220801 meej substring 수정
            el.startTime = el.startTime.substring(0,2)+ ':' +el.startTime.substring(2,4)
            el.endTime = el.endTime.substring(0,2)+ ':' +el.endTime.substring(2,4)
          });
          // console.log('changed data chk: ', JSON.stringify(response.data.items))
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
          console.log('error = ', error)
        })
    })

  },
  // 220824 meej 인포봇 업무 조회
  async GET_INFOBOT_LIST(_,params) {
    return new Promise((resolve, reject) => {
      api.infobotSchedule(params)
        .then((response) => {
          response.data.items.forEach((el) => {
            if(el.endTime.length == 4){
              el.endTime = el.endTime.substring(0,2)+ ':' +el.endTime.substring(2,4)
            }
            el.manager = el.manager.slice(-1)
          });
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
          console.log('error = ', error)
        })
    })

  },
  async ASYNC_EVENT_LIST({ commit }, params) {
    debug.log("adminSchedulgetList >>> ")
    let { data } = await api.adminScheduleCalendarList(params)

    let dataArray = []

    for (var i = 0; i < data.items.length; i++) {
      let dataFormat =
      {
        id: '',
        title: '',
        start: '',
        end: '',
        allDay: true,
        backgroundColor: '',
        borderColor: '',
        extendedProps: {
          description: ''
        }
      };
      // status 에 따라 color 설정
      switch (data.items[i].status) {
        case 2:
          data.items[i].status = 'teal'
          break;
        case 3:
          data.items[i].status = 'orange'
          break;
        case 4:
          data.items[i].status = 'red'
          break;
        case 5:
          data.items[i].status = 'cyan'
          break;
      }
      // data set
      dataFormat.id = data.items[i].id
      dataFormat.title = data.items[i].title
      dataFormat.start = data.items[i].startTime.substr(0, 8)
      dataFormat.end = data.items[i].endTime.substr(0, 8)
      dataFormat.backgroundColor = data.items[i].status
      dataFormat.borderColor = data.items[i].status
      dataFormat.extendedProps.description = data.items[i].content
      
      dataArray.push(dataFormat)
    }

    commit('setList', { dataArray })
  },
  WRITE_EVENTS(_, params) {
    // color에 따라 status 설정
    switch (params.color) {
      case "teal":
        params.color = "2"
        break;
      case "orange":
        params.color = "3"
        break;
      case "red":
        params.color = "4"
        break;
      case "cyan":
        params.color = "5"
        break;
    }
    // startTime, endTime format : yyyyMMddhhmmss 
    params.start = params.start + '000000';
    params.end = params.end + '000000';
    debug.log("WRITE_EVENTS params", params)

    return new Promise((resolve, reject) => {
      api.adminScheduleWrite(params)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 220826 meej 인포봇 실행 요청
  EXE_INFOBOT(_, params) {
    return new Promise((resolve, reject) => {
        api.infobotExe(params)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
  },
}

const getters = {
  initEvents: state => state.initEvents,
}

export const adminSchedule = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
