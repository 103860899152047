<template>
    <section class="row">
        <!-- 221028 meej 테마 등락률 목록 별도 추가 -->
        <!-- <i-resource class="col-4 q-pa-md" :initMenu="resType"/> -->
        <i-ThemeRateTable class="col-4 q-pa-md"/>
        <i-content class="col-8 q-pa-md" @event-order-featured="saveOrder"/>
    </section>
</template>
<script>
import ThemeContent from '@/components/featured/trend/Content.vue'
// import Resource from '@/components/common/Resource.vue'
import ThemeRateTable from '@/components/common/ThemeRateTable.vue'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Theme',
    components: {
        'i-content' : ThemeContent,
        // 'i-resource' : Resource
        'i-ThemeRateTable' : ThemeRateTable
    },
    data () {
        return {
            resType: 'Theme',
        }
    },
    created () {
        this.setPageStatus(this.PAGE_STATUS.REGISTER)
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
        }),
        ...mapGetters ({
            isEditPage : 'page/isEditPage',
            isRegisterPage : 'page/isRegisterPage'
        })
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
        }),
        ...mapActions('theme_featured', ['FEAURED_ORDER']),
        ...mapActions( 'alert', ['showError', 'showMessage']),

        /* 
            220504 meej 테마동향 순서 변경 시 오류 수정
         */
        saveOrder(params) {
            this.FEAURED_ORDER(params)
                .then(()=> {
                    this.showMessage({vc:this, message:'완료되었습니다'})
                })
                .catch((err)=>{
                    this.showError({vc:this, error:err})
                })
        },
    }
}
</script>

<style>
    /* common */

    /* xs */
    @media only screen and (max-width: 599px) {
    }

    /* sm */
    @media only screen and (max-width: 1023px) {
    }

    /* md */
    @media only screen and (max-width: 1439px) {
    }

    /* lg */
    @media only screen and (max-width: 1919px) {
    }

    /* xl */
    @media only screen and (min-width: 1920px) {
    }

</style>


