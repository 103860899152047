<template>
    <section class="row q-pl-xs">
        <q-toolbar class="row q-mt-md text-left">
            <div class="q-pa-xs">
                <q-input
                    v-model="sendDate"
                    bg-teal
                    text-white
                    outlined
                    dense
                    type="date"
                />
            </div>
            <div class="q-pa-xs"><i-select v-model="trnsmis" :options="trnsmisIdOptions" option-value="trnsmisId" option-label="trnsmisNm" @input="getList" style="width:200px;"/></div>
            <div class="q-pa-xs">
                <i-btn icon="search" @click="getList()" />
            </div>
            <div class="q-pa-xs">
                <i-btn label="선택삭제" @click="getDeleteChk()" />
            </div>
            <div class="col q-pa-xs text-right">
                <i-btn label="신규등록" @click="getView(null)" />
            </div>
        </q-toolbar>
        <i-list ref="iRflashList" :trnsmis="trnsmis" :sendDate="sendDate" class="col-12 q-pa-xs" />
        <i-content ref="iRflashContent" :trnsmis="trnsmis" />
    </section>
</template>
<script>
import debug from '@/debug'
import Select from '@/components/common/Select.vue';
import Button from '@/components/common/Button'
import List from '@/components/marketing/IRflash/List.vue'
import Content from '@/components/marketing/IRflash/Content.vue'
import { mapState, mapActions } from 'vuex'

export default {
    name: 'IRflash',  
    components: {
        'i-btn': Button,
        'i-list': List,
        'i-content': Content,
        'i-select' : Select,
    },
    data() {
        return {
            trnsmis: null,
            trnsmisIdOptions:[],
            sendDate: '',
        }
    },
    computed: {
        ...mapState({
            role: (state) => state.session.role,
            userId: (state) => state.session.userId,
        }),
    },
    created() {
        this.getPage()
    },
    methods: {
        ...mapActions('alert', [
            'showError',
            'showMessage',
            'showAlert',
            'showDeleteAlert',
        ]),
        ...mapActions('manage', ['GET_CALL']),
        //페이지 검색
        getPage() {
            let param = {
                CALL_API_URL: '/irflash/page',
            }
            this.GET_CALL(param)
                .then((data) => {
                    // 20210914 shb 페이지 뜨지않아 임시처리
                    // if (data.selectList.length !== 0) {
                    //     this.trnsmisId = data.selectList.filter(function(
                    //         entry
                    //     ) {
                    //         return entry.trnsmisNm === '인포스탁데일리'
                    //     })[0].trnsmisId
                    // }
                    // 221116 meej 건설경제 => 대한경제 변경
                    data.selectList.forEach((el)=>{
                        if(el.trnsmisNm == "건설경제") {
                            el.trnsmisNm = "대한경제"
                        }
                    })
                    this.trnsmisIdOptions = data.selectList
                    this.trnsmisIdOptions.unshift({trnsmisId:'', trnsmisNm:'전체'});
                    // debug.log('getPage', this.trnsmisIdOptions)
                    this.trnsmis = this.trnsmisIdOptions[0];                 
                    this.getList()
                })
                .catch((error) => {
                    this.showError({ vc: this, error: error })
                })
        },
        getList() {
            debug.log('ad getlist', this.trnsmis)
            this.$refs.iRflashList.getList(this.trnsmis)
        },
        getView(item) {
            this.$refs.iRflashList.getView(item)
        },
        getUpdatePopup(item) {
            this.$refs.iRflashContent.getUpdatePopup(item)
        },
        getDeleteChk() {
            this.$refs.iRflashList.getDeleteChk()
        },
    },
}
</script>
