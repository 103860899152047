<template>
    <article>
        <q-dialog v-model="feedbackPopup" persistent>
            <q-card style="min-width: 70%;min-height:40%;" >
                <q-card-section >
                    <div class="row q-my-sm">
                        
                        <div class="col-12 col-sm-6">
                        <div style="padding: 10px 0px 0px 30px;"><label class="i-label" style="color:gray;">작성자 {{userId}}</label></div>
                        
                        </div>
                        <div class="col-12 col-sm-6">
                            <i-datetime date v-model="feedback_date" dateLabel="작성일" style="width:300px; "/> 
                        </div>
                    </div>

                  
                    <body class="wrap">
                        <div>
                            <div class="row q-my-sm">
                                <label class="i-label" style="padding: 30px 0px 0px 50px; color:gray; width:100px;">피드백</label>
                               <div><i-select v-model="feedback_state" :options="['확인','재요청']" option-value="value" option-label="name" style="padding: 20px 0px 0px 6px; text-align : center;"/></div>
                            </div>

                            <div class="row q-my-sm">
                                <q-input class="full-width" dense outlined v-model="feedback_content" type="textarea" :input-style="{width:'100%'}"><template v-slot:before><label class="i-label" style="padding: 70px 0px 0px 30px; color:gray; width:100px; text-align : center;">내용</label></template></q-input>
                            </div>
                        </div>
                    </body>
                        
                    
                        

                </q-card-section>
                <q-card-actions align="center" class="text-primary">
                    <div class="q-pa-xs"><q-btn label="등록" @click="getUpdatePopupSave()"/></div>
                    <div class="q-pa-xs"><q-btn color="white" text-color="black" label="닫기" v-close-popup/></div>
                </q-card-actions>
            </q-card>
        </q-dialog>
    </article>
</template>
<script>
import Select from "@/components/common/Select";
import { mapState, mapActions } from 'vuex'

import DateTime from '@/components/common/DateTime'

export default {
    name: 'Workmanager-List',
    props: {
        hide_selected: {
            type: Boolean,
            default: true
        }
    },
    components: {
        'i-select' : Select,
        'i-datetime': DateTime,
        // "i-input": Input,
    },
    data () {
        return {
            feedback_state: '',
            feedbackPopup: false,
            feedback_content:'',
            feedback_date: '',
            progress_id: '',
            // file: {'value':'', 'name':'파일'},
        }
    },
    computed : {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
        })
    },
    // created () {
    //     this.getInit();
    // },
    methods: {

        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),

        initList() {

        },
        initData() {
            this.feedback_state = '';
            this.feedback_content = '';
            this.feedback_date = '';
            this.progress_id = '';
        },

        // 팝업
        getFeedbackPopup(item) {
            this.initData();
            this.progress_id = item.progress_id;
            this.feedbackPopup = true;
        },

        // 저장 팝업
        getUpdatePopupSave() {

            if (this.feedback_state == null || this.feedback_state === "") {
                this.showMessage({ vc:this, message:'피드백을 선택하세요.' });
                return;
            }

            if (this.feedback_content == null || this.feedback_content === "") {
                this.showMessage({ vc:this, message:'내용을 입력하세요.' });
                return;
            }

            if (this.feedback_content.length > 255){
                this.showMessage({ vc:this, message:'피드백을 255자 이내로 작성해주세요.' });
                return;
            }


            this.showAlert({ vc:this, message:'<span style="color:#1976D2;">저장</span> 하시겠습니까?' }).then(()=>{
            this.updatePopup = false;
                let param = {
                    'feedback_state': this.feedback_state,
                    'feedback_content': this.feedback_content,
                    'feedback_date': this.feedback_date,
                    'progress_id': this.progress_id,
                    'CALL_API_URL': '/request/feedback',
                }
                
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'저장되었습니다.' });
                    this.initData()
                    this.$parent.getList();
                    this.feedbackPopup = false;
                }).catch((err)=>{
                    this.showError({ vc:this, error:err });
                });
            }).catch(() => { });
        },
    }
}

</script>
<style>

.wrap {
    border: 1px solid #C3C3C3;
    padding: 0px 10px 10px 10px;
}
</style>