<template>
    <article>
        <!-- 구분 -->
        <div class="row q-my-xs i-top-wrap">
            <div class="col-12 col-lg-6">
                <div class="flex justify-between">
                    <i-stock-search style="width:80%" @get="getStock"/>
                </div>
            </div>
            <div class="col-12 offset-md-2 col-md-4">
                <div class="flex justify-end">
                    <div class="q-pa-xs">
                        <i-btn label="삭제" @click="deleteItems()"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- 목록 -->
        <i-table :columns="columns" :list="list" :nextKey="nextKey"
            @row-click="getItem" @next-click="getList()" @check-event="handleCheckList" />
    </article>
</template>
<script>
import Debug from '@/debug'
import { FormatDate } from '@/util'

import Button from '@/components/common/Button'
import StockSearch from '@/components/common/StockSearch'
import Table from '@/components/common/Table'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    components: {
        'i-btn' : Button,
        'i-stock-search': StockSearch,
        'i-table' : Table
    },
    data () {
        return {
            condition: {
                stock: ''
            },
            selected: [],
            columns: [
                { name: 'stockName', label: '종목', align: 'left', field: row => row.stockName+'('+row.stockCode+')' },
                { name: 'date', label: '날짜', align: 'left',field: row => FormatDate(row.showDate) },
            ]
        }
    },
    created () {
        this.INIT_LIST()
        this.getList()
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus
        }),
        ...mapState( 'earning', ['list', 'nextKey', 'stockCompany']),
        ...mapGetters( 'page', ['isListPage', 'isDetailPage', 'isEditPage', 'isRegisterPage']),
    },
    methods: {
        ...mapMutations( 'page', ['setPageStatus']),
        ...mapMutations( 'earning', ['SET_DETAIL', 'INIT_LIST', 'INIT_DETAIL']),

        ...mapActions( 'alert', ['showError', 'showMessage']),
        ...mapActions( 'earning', ['GET_DATE_LIST','GET_DETAIL','DELETE_ITEMS']),

        handleCheckList(list) {
            this.selected = list
        },
        getStock(stock) {
            this.condition.stock = stock

            this.INIT_LIST()
            this.getList()
        },
        // 목록 조회하기
        getList() {
            var params = {}
            if (this.condition.stock != null) {
                params.code = this.condition.stock.code
            }
            this.GET_DATE_LIST(params)
        },
        // 상세 조회하기
        getItem(item) {
            this.setPageStatus(this.PAGE_STATUS.DETAIL)

            var params = {}
            params.id = item.id

            this.GET_DETAIL(params)
            .then((data) => {
                Debug.log('GET_DETAIL data', data)
                var item = data.item
                this.SET_DETAIL(item)
            })
            .catch((err) => {
                var error = err.message
                this.showError({ vc:this, error:error })
                Debug.log('error = ', error)
            })
        },
        // 삭제하기
        deleteItems() {
            var message

            if (this.selected.length == 0) {
                message = '삭제할 항목을 선택해주세요.'
                this.showMessage({ vc:this, message:message })
                return
            }

            var params = {}
            params.ids = this.selected.map( x => x.id ).join(',')
            // 실적은 Delete?
            params.force = 0
            // 종목뉴스 삭제 API
            this.DELETE_ITEMS(params)
            .then(() => {
                message = '삭제되었습니다.'
                this.showMessage({ vc:this, message:message })

                this.INIT_DETAIL()

                this.INIT_LIST()
                this.getList()
            })
            .catch((err) => {
                var error = err.message
                this.showError({ vc:this, error:error })
                Debug.log('error = ', error)
            })
        }
    }
}
</script>


