<template>

    <q-select
        v-model="stock"
        :value="stock"
        :options="stockOptions"
        option-label="name"
        @filter="filterFnAutoselect"
        input-debounce="0"
        @input="isWatched ? sendStock() : abortFilterFn()"
        @keyup.enter="sendStock()"
        hide_selected
        :hide-selected="hide_selected"
        use-input
        clearable 
        fill-input
        ref="click"
        :placeholder="innerLabel"
        hide-dropdown-icon
        options-dense
        dense
        outlined
    > 
        <template v-if="label" v-slot:before>  
            <label class="i-label"> {{ label }} </label>
        </template>
        <template v-slot:no-option>
            <q-item>
                <q-item-section class="text-grey">
                    No results
                </q-item-section>
            </q-item>
        </template>
        <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps" v-on="scope.itemEvents" @mousedown="mouseEvent($event, scope)">
                <q-item-section>
                    <q-item-label v-html="scope.opt.name" />
                </q-item-section>
                <q-item-section>
                    <q-item-label v-html="scope.opt.code" />
                </q-item-section>
            </q-item>
        </template>
    </q-select>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import debug from '../../debug'
export default {
    name: 'StockSearchOri',
    props: {
        extra: {
            type: Object,
            default: null,
        },
        label: String,
        hide_selected: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            stock: '',
            stockOptions: [],
            innerLabel: '종목검색',
            isWatched: false, 
        }
    },
    computed: {
        ...mapState({
            stockItems: (state) => state.resource.stockItems,
        }),
    },
    created() {
        
        if (this.label) {
            this.innerLabel = ''
        }
        if (this.stockItems === undefined || this.stockItems.length === 0) {
            this.GET_INIT()
            this.isWatched = false 
        }
    },
    methods: {
        ...mapActions('resource', ['GET_INIT']),
        sendStock() {
            debug.log(
                'sendStock ',
                this.label,
                ',',
                this.innerLabel,
                ',',
                this.stock
            )
            console.log('this.stock', this.stock, this.extra)
            if (this.stock) {
                if (this.extra) {
                    this.$emit('get', this.stock, this.extra)
                } else {
                    this.$emit('get', this.stock)
                }
            } 
            if (this.hide_selected) {
                debug.log('hide_selected!!!! ', this.hide_selected)
                this.stock = null
            }
            this.isWatched = false 
        },
        clear() {
            debug.log('stock search clear !!!!!!!!!!!!!!!')
            this.stock = null
            // this.sendStock()
        },

        abortFilterFn () { 
            console.log('delayed filter aborted')
            // console.log('stock? ', this.stock)
        },

        mouseEvent(event, scope){ 
            // console.log('mouseEvent', event, scope.opt)
            if(event) {
                this.isWatched = true
                this.stock = scope.opt
                this.sendStock()
            }
        },

        // 종목검색 필터링 
        filterFnAutoselect (val, update, abort) { 
            // 종목코드 검색시 value 길이 5 이상, 종목명 검색시 2자 이상 
            let check = /^[0-9]+$/
            if (check.test(val)) {    
                // console.log('check.test(val)', check.test(val))
                if (val.length < 5) {
                    abort()
                    return
                } 
            } else {
                if (val.length < 2) {
                    abort()
                    return
                }
            }
    
            let objstockItems = []
            update(
                () => {
                    if (val === '') {
                        this.stockOptions = objstockItems
                    }
                    else {
                        objstockItems = this.stockItems
                        const needle = val.toLowerCase()
                        debug.log('needle = ', needle)
                        this.stockOptions = objstockItems.filter(v => v.name.toLowerCase().indexOf(needle) > -1 || v.code.indexOf(needle) > -1)
                    }
                },

                ref => {
                // console.warn('val', val, 'ref length', ref.options.length, 'ref.optionIndex', ref.optionIndex) 
                    if (val !== '' && ref.options.length > 0 && ref.optionIndex === -1) {
                        // console.log('came to the ref') 
                        ref.moveOptionSelection(1, true) 
                        ref.toggleOption(ref.options[ref.optionIndex], true) 
                    } 
                }
            ) 
        },
    },

    watch: {
        stock(val, old){
            // console.warn('this.stock val=', val, 'old=', old)
            this.stock = val
            if(val && old){
                if(val !== old) {
                    // console.log('watch val !== old')
                    this.isWatched = true
                } 
            } else if(!old) {
                // console.warn('watch 2 val = ', val)
                this.isWatched = true
            }
            
        },
    },
}
</script>
<style></style>