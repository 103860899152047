<template>
    <section class="q-pa-md">
        <div class="row i-top-wrap q-mb-xs">
            <i-select class="col-12 col-md-4 q-pa-xs"
                v-model="type" inputLabel="구분" :options="typeOptions"
                option-label="name" option-value="value"
            />
            <div class="col-12 col-md-7">
                <div class="row">
                    <div class="col-4 flex justify-end q-pa-xs q-pr-md">
                        <i-field check v-model="update" inputLabel="멘트삽입" />
                    </div>
                    <div class="col-8 q-pa-xs">
                        <i-input v-model="comment" class="full-width"/>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-1">
                <div class="flex justify-end q-pa-xs">
                    <i-btn label="수정" @click="write()"/>
                </div>
            </div>
        </div>
        <i-content :macroType="type" @click-event="uploadMacro"/>
    </section>
</template>
<script>
import Debug from '@/debug'
import { morning } from '@/util'

import Select from '@/components/common/Select.vue'
import Input from '@/components/common/Input.vue'
import Field from '@/components/common/Field.vue'
import Button from '@/components/common/Button.vue'
import Content from '@/components/macro/Content.vue'

import { mapActions } from 'vuex'

export default {
    name: 'Index',
    components: {
        'i-content' : Content,
        'i-select' : Select,
        'i-input' : Input,
        'i-field' : Field,
        'i-btn' : Button,
    },
    data () {
        return {
            type: null,
            typeOptions:[
                { name: '조간 지수 입력', value: '0630INDEX_W' },
                { name: '석간 지수 입력', value: '1600INDEX_W' }
            ],
            comment: null,
            update: false
        }
    },
    created() {
        this.type = ( morning() ? this.typeOptions[1] :this.typeOptions[0] )

        this.GET_SETTINGS()
        .then((res)=> {
            this.comment = ( res === undefined ? '' : res.INDEX_COMMENT )
        })
    },
    computed : {},
    methods: {
        ...mapActions( 'alert', ['showError', 'showMessage']),
        ...mapActions( 'resource', ['MACRO_UPLOAD', 'SET_SETTINGS', 'GET_SETTINGS']),

        // 수정하기
        write() {
            let params = {
                items: [{
                    INDEX_COMMENT: this.comment
                }]
            }
            this.SET_SETTINGS(params)
            .then(()=>{
                this.showMessage({vc:this, message:'업데이트 완료'})
            })
            .catch((err) => {
                this.showError({ vc:this, error:err})
            })
        },

        // 등록하기
        uploadMacro(params) {
            this.MACRO_UPLOAD(params)
            .then((data)=> {
                var message
                if (data.success) {
                    message = '등록되었습니다.'
                } else {
                    message = '등록 실패하였습니다. 관리자에게 문의해주세요.'
                }
                this.showMessage({ vc:this, message:message })
            })
            .catch((error) => {
                Debug.log('error = ', error)
                this.showError({ vc:this, error:error })
            })
        }
    }
}
</script>


