<template>
    <q-btn
        :size="size"
        :label="label"
        :disable="disable"
        @click="click()"
        color="dark"
        unelevated
    >
        <q-icon v-if="icon" :name="icon" />
    </q-btn>
</template>
<script>
export default {
    name: 'Button',
    props: {
        size: {
            type: String,
            default: null,
        },
        label: {
            type: [String, Function],
            default: null,
        },
        icon: {
            type: String,
            default: null,
        },
        disable: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        click() {
            this.$emit('click')
        },
    },
}
</script>
