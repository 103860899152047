<template>
    <article>
        <!-- 목록 -->
        <i-table
            :columns="columns"
            :list="list"
            :nextKey="nextKey"
            @row-click="getItem"
            @next-click="getList()"
            @check-event="handleCheckList"
        />
    </article>
</template>
<script>
import { FormatDate, CRUDLabel, CutDownText, isEmpty } from '@/util'
import Table from '@/components/common/Table'
import Debug from '@/debug'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    components: {
        'i-table': Table,
    },
    props: {
        newsType: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            columns: [],
            dailyColumns:[
                {
                    name: 'sendFlag',
                    label: '게시',
                    align: 'left',
                    field: (row) => (isEmpty(row.sendDate) ? 'X' : 'O'),
                },

                {
                    name: 'lastUpdateTime',
                    label: '작성/변경날짜',
                    align: 'left',
                    field: (row) => FormatDate(row.lastUpdateTime),
                },
                {
                    name: 'crud',
                    label: '상태',
                    align: 'left',
                    field: (row) => CRUDLabel(row.crud),
                },
                {
                    name: 'title',
                    label: '제목 ',
                    align: 'left',
                    field: (row) => row.title,
                },
            ],
            marketColumns: [
                {
                    name: 'lastUpdateTime',
                    label: '작성/변경날짜',
                    align: 'left',
                    field: (row) => FormatDate(row.lastUpdateTime),
                },
                {
                    name: 'createwriter',
                    label: '작성/수정자',
                    align: 'left',
                    field: (row) => row.CREATE_WRITER +(row.crud == 'U' ? '/'+row.writer : ''),
                },
                {
                    name: 'crud',
                    label: '상태',
                    align: 'left',
                    field: (row) => CRUDLabel(row.crud),
                },
                {
                    name: 'content',
                    label: '내용 ',
                    align: 'left',
                    field: (row) => CutDownText(row.content, 30),
                },
            ],
            selected:[]
        }
    },
    created() {
        this.INIT_LIST()
        Debug.log('cerate newstye = ', this.newsType)
        if (this.newsType !== null && this.newsType !== undefined) {
            this.getList()
            this.columns = (this.newsType === 'MARKET_THEME_NEWS'?this.marketColumns:this.dailyColumns)
        }
        
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
        }),
        ...mapState({
            nextKey: (state) => state.theme_featured.nextKey,
            list:(state)=>state.theme_featured.list
        }),
        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isEditPage',
            'isRegisterPage',
        ]),
        
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('theme_featured', ['SET_ITEM','SET_LIST', 'INIT_LIST']),

        ...mapActions('alert', ['showError', 'showMessage','showAlert']),

        ...mapActions('flash', [
            'GET_NEWS_LIST',
            'DETAIL_FLASH',
            'DELETE_LIST',
        ]),
        ...mapActions('market_news', [
            'GET_LIST',
            'GET_DETAIL_ITEM',
            'DELETE_ITEMS'
        ]),
        //  목록 조회하기
        getList() {
            this.setPageStatus(this.PAGE_STATUS.LIST)

            var params = {
                newsType: this.newsType,
                nextKey:this.nextKey,
                // 220726 meej 페이징 오류 수정 flag : 1 추가
                flag : 1,
            }
            if(this.newsType === 'MARKET_THEME_DAILY') {
                
                this.GET_NEWS_LIST(params)
                    .then((data)=> {
                        // 220726 meej 페이징 오류 수정 조건 추가
                        if(this.nextKey == null){
                            this.INIT_LIST()
                        }
                        this.SET_LIST(data)

                    })
                    .catch((err) => {
                        this.showError({ vc: this, error: err })
                    })
            } else {
                this.GET_LIST(params)
                    .then((data)=> {
                        // 220726 meej 페이징 오류 수정
                        if(this.nextKey == null){
                            this.INIT_LIST()
                        }
                        this.SET_LIST(data)

                    })
                    .catch((err) => {
                        this.showError({ vc: this, error: err })
                    })
            }
            
        },
        //  가져오기
        getItem(item) {
            Debug.log('getItem = ', this.newsType)
            if(item.newsType1 === 'MARKET_THEME_NEWS') {
                this.GET_DETAIL_ITEM({
                    newsType: item.newsType1,
                    id: item.pid,
                })
                .then((data) => {
                    Debug.log('getItem = ', data)

                    this.SET_ITEM(data.items[0])
                    this.setPageStatus(this.PAGE_STATUS.DETAIL)
                })
                .catch((err) => {
                    var error = err.message
                    this.showError({ vc: this, error: error })
                })
            } else {
                this.SET_ITEM(item)
            }
            
            
            //
        },
        handleCheckList(list) {
            //this.$emit('event-check', list)
            this.selected = list
        },
        // 삭제하기
        deleteItems() {
            if (this.selected.length == 0) {
                this.showMessage({
                    vc: this,
                    message: '삭제할 항목을 선택해주세요.',
                })
                return
            } else {
                this.showAlert({
                    vc: this,
                    message: '선택한 항목을 삭제 하시겠습니까?',
                }).then(() => {
                    this.doDelete()
                })
            }
        },
        doDelete() {
            var params = {}
            params.ids = this.selected.map((x) => x.id).join(',')
            params.force = 1
            params.notSend = 1
           
            if(this.newsType === 'MARKET_THEME_NEWS') {
                this.DELETE_ITEMS(params)
                .then(() => {
                    this.INIT_LIST()
                    this.getList()
                    this.setPageStatus(this.pageStatus.LIST)
                })
                .catch((err) => {
                    var error = err.message
                    this.showError({ vc: this, error: error })
                })
            } else {
                 this.DELETE_LIST(params)
                .then(() => {
                    this.showMessage({ vc: this, message: '삭제되었습니다.' })

                    this.INIT_LIST()
                    this.getList()
                    this.setPageStatus(this.pageStatus.LIST)
                })
                .catch((err) => {
                    Debug.log('error = ', err)
                    var error = err.message
                    this.showError({ vc: this, error: error })
                })
            }
        },
    },
    watch: {
        newsType(val, old) {
            if(val !== old) {
                this.INIT_LIST()
                this.getList()
                this.columns = (val === 'MARKET_THEME_NEWS'?this.marketColumns:this.dailyColumns)
            }
        }
    },
}
</script>
