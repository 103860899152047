import api from '@/services/InfostockService'
import Debug  from '@/debug'

const state = () => ({
    newsType:'STOCK_CONSENSUS_COMPANY',

    stockCompany: [],
    list: [],
    nextKey: null,
    item: {
        id:'',
        stockCode: '',
        stockName: '',
        gubun:'',
        ifrs:'',
        year:'',
        quarter:'',
        time:'',
        sales:'',
        operatingIncome:'',
        netIncome:'',
        unit:'',
        memo:'',
        pretaxIncome:'',
        ciNetIncome:'',
        showDate:''
    },
})

const getters = {}

const mutations = {
    SET_STOCK_COMPANY(state, list) {
        state.stockCompany = list
    },
    SET_LIST(state, { data }) {
        var arr = state.list
        arr.push(...data.items)

        // 인덱스 추가
        arr.forEach((row, index) => {
            row.index = index
        })

        state.list = arr
        state.nextKey = data.nextKey
    },
    SET_DETAIL(state, item) {
        state.item = item
    },
    INIT_LIST(state) {
        state.list = []
        state.nextKey = null
    },
    INIT_ITEM(state) {
        state.item = {
            id:'',
            stockCode: '',
            stockName: '',
            type:'',
            ifrs:'',
            year:'',
            quarter:'',
            time:'',
            sales:'',
            operatingIncome:'',
            netIncome:'',
            unit:'',
            memo:'',
            pretaxIncome:'',
            ciNetIncome:'',
            showDate:''
        }
    }
}

const actions = {
    // 증권사 조회
    async GET_STOCK_COMPANY({ commit }) {
        var params = {}
        params.newsType = 'STOCK_CONSENSUS_COMPANY'

        Debug.log('GET_STOCK_COMPANY params', params)
        const { data } = await api.stockCompanyList(params)
        Debug.log('GET_STOCK_COMPANY data', data)

        commit('SET_STOCK_COMPANY', data.items)
    },
    // 목록 조회
    async GET_LIST({ commit }, params) {
//        params.nextKey = state.nextKey

        Debug.log('GET_LIST params', params)
        const { data } = await api.earningList(params)
        Debug.log('GET_LIST data', data)

        commit('SET_LIST', { data })
    },
    // 목록 조회
    async GET_DATE_LIST({ state, commit }, params) {
        params.newsType = 'STOCK_EARNING_DATE'
        params.nextKey = state.nextKey

        const { data } = await api.stockNewsList(params)

        commit('SET_LIST', { data })
    },
    // 상세 조회
    GET_DETAIL(_, params) {
        params.newsType = 'STOCK_EARNING_DATE'
        Debug.log('GET_DETAIL params', params)
        return new Promise((resolve, reject) => {
            api.stockNewsDetail(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    async WRITE_DATE_ITEM(_, params) {
        params.newsType = 'STOCK_EARNING_DATE'
        Debug.log('WRITE_ITEM params', params)
        const { data } = await api.stockNewsWrite(params)
        Debug.log('WRITE_ITEM data', { data })
    },
    // 여러개 삭제
    async DELETE_ITEMS(_, params) {
        Debug.log('DELETE_ITEM params', params)
        params.newsType = 'STOCK_EARNING_DATE'
        const { data } = await api.stockNewsMultiDelete(params)
        Debug.log('DELETE_ITEM data', { data })
    },
}

export const earning = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
