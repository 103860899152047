<template>
    <article>
        <q-dialog v-model="checkFeedbackPopup" persistent>
            <q-card style="min-width: 70%;min-height:50%;" >
                <q-card-section >
                    <div class="row q-my-sm">
                        <div class="col-12 col-sm-3">
                            <div style="padding: 10px 0px 20px 30px;"><label class="i-label" style="color:gray; font-size: 15px">피드백 작성자 {{feedback_writer }}</label></div>
                        </div>

                        <div class="col-12 col-sm-3">
                            <div style="padding: 10px 0px 20px 30px;"><label class="i-label" style="color:gray; font-size: 15px">피드백 등록일자 {{ feedback_date }}</label></div>
                        </div>

                        
                    </div>

                  
                    <body class="wrap">
                        <div>
                            <div class="row q-my-sm" style="padding:5px 20px 10px 10px; font-size: 15px; font-family:Tahoma, Arial;"> <div style="color:gray; font-size: 15px; padding-right: 10px; ">피드백 상태</div> <div style="color: red;">{{feedback_state}}</div></div>
                            <div class="row q-my-sm" style="padding:5px 20px 10px 10px; font-size: 15px; font-family:Tahoma, Arial;"> <div style="color:gray; font-size: 15px; padding-right: 10px; ">피드백 내용</div> {{feedback_content}}</div>

                        </div>
                    </body>
                </q-card-section>
                <q-card-actions align="center" class="text-primary">
                    <!-- <div class="q-pa-xs"><q-btn label="등록" @click="getUpdatePopupSave()"/></div> -->
                    <div class="q-pa-xs"><q-btn color="white" text-color="black" label="닫기" v-close-popup/></div>
                </q-card-actions>
            </q-card>
        </q-dialog>
    </article>
</template>
<script>
// import Select from "@/components/common/Select";
import { mapState, mapActions } from 'vuex'
import { FormatDate} from '@/util'
// import DateTime from '@/components/common/DateTime'

export default {
    name: 'Workmanager-List',
    props: {
        hide_selected: {
            type: Boolean,
            default: true
        }
    },
    components: {
        // 'i-select' : Select,
        // 'i-datetime': DateTime,
        // "i-input": Input,
    },
    data () {
        return {
            checkFeedbackPopup: false,
            feedback_state: {'value':'', 'name':'상태'},
            feedback_content:'',
            feedback_date: '',
            feedback_writer: '',
            progress_id: '',
        }
    },
    computed : {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
        })
    },
    // created () {
    //     this.getInit(); 
    // },
    methods: {

        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),

        initList() {

        },
        // initData() {
        //     this.feedback_state='';
        //     this.feedback_content='';
        //     this.feedback_date='';
        //     this.feedback_writer='';
        //     this.progress_id='';
        // },

        //수정팝업
        getCheckFeedbackPopup(item) {
            let param = {
                'progress_id': item.progress_id,
                'CALL_API_URL': '/request/feedback/detail'
            }
            
            this.GET_CALL(param).then((data) => {
                this.feedback_state = data.feedback_state,
                this.feedback_content = data.feedback_content,
                this.feedback_date = FormatDate(data.feedback_date),
                this.feedback_writer = data.feedback_writer,
                this.checkFeedbackPopup = true;
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
    }

}

</script>
<style>

.wrap {
    border: 1px solid #C3C3C3;
    padding: 0px 10px 10px 10px;
}
</style>