<template>
    <article>
        <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : list.length == 0 }"
            :columns="columns" :data="list" row-key="RNUM" :rows-per-page-options="[0]" style="height:auto;"
            hide-pagination no-data-label="조회되는 데이터가 없습니다.">
            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th v-for="col in props.cols" :key="col.name" :props="props">{{ col.label }}</q-th>
                </q-tr>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td v-for="col in props.cols" :key="col.name" :props="props">
                        <div v-if="col.name == 'button'">
                            <q-btn size="xs" label="수정" @click="getUpdatePopup(props.row)" v-show="props.row.type != 'WITHDRAWAL'"/> <q-btn size="xs" label="삭제" color="red" @click="getDelete(props.row)"/>
                        </div>
                        <div v-else>{{ col.value }}</div>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:no-data="{ icon, message }"><div class="full-width row flex-center q-gutter-sm"><q-icon size="2em" :name="icon"/> <span>{{message}}</span></div></template>
        </q-table>
        <div v-show="list.length != 0" class="flex flex-center q-mt-md">
            <q-pagination
            v-model="pagination.page"
            :max="pagesNumber"
            :max-pages="pagination.maxPages"
            :direction-links="true"
            :boundary-numbers="false"
            :boundary-links="true"
            :ellipses="false"
            @click="getList()"
        />
        </div>
        <q-dialog v-model="updatePopup" persistent>
          <q-card style="min-width: 370px">
            <q-card-section class="column">
              <q-checkbox v-model="isPeriodSet">기간으로 설정</q-checkbox>
              <q-card-section v-if="isPeriodSet === false" class="row">
                <div class="q-my-auto q-pr-xl"><label class="i-label">{{U_OldType}}</label></div>
                <div class="q-my-auto q-pr-xl"> ▶▶▶ </div>
                <div class="q-pa-xs"><i-select v-model="U_type" :options="U_typeOptions" option-value="type" option-label="typeNm" style="width:160px;"/></div>
              </q-card-section>
              <!-- 220808 meej 타입으로 변경시 날짜 미리보기 및 버튼 추가 -->
              <q-card-section v-if="isPeriodSet === false && showDateChange" class="row">
               <span class="q-pt-md">변경 전 : </span><div class="q-pa-xs"><q-input v-model="U_startDate" dense filled type="date" readonly/></div>
                <div class="q-my-auto"> ~ </div>
                <div class="q-pa-xs"><q-input v-model="U_endDate" dense filled type="date" readonly/></div>
              </q-card-section>
              <q-card-section v-if="isPeriodSet === false && showDateChange" class="row">
                <span class="q-pt-md">변경 후 : </span> <div class="q-pa-xs"><q-input v-model="T_startDate" dense filled type="date" /></div>
                <div class="q-my-auto"> ~ </div>
                <div class="q-pa-xs"><q-input v-model="T_endDate" dense filled type="date" /></div>
              </q-card-section>
              <q-card-section v-if="isPeriodSet === false && showDateChange" class="row">
              <q-btn color="white" text-color="black" icon="refresh" @click="setEndDate('re')"/>
              <q-btn color="white" text-color="black" label="+7일" @click="setEndDate('7W')"/>
              <q-btn color="white" text-color="black" label="+1개월" @click="setEndDate('1M')"/>
              <q-btn color="white" text-color="black" label="+3개월" @click="setEndDate('3M')"/>
              <q-btn color="white" text-color="black" label="+6개월" @click="setEndDate('6M')"/>
              <q-btn color="white" text-color="black" label="+12개월" @click="setEndDate('12M')"/>
              </q-card-section>
              <q-card-section v-if="isPeriodSet" class="row">
                  <div class="q-my-auto"><label class="i-label">유료회원 기간</label></div>
                  <div class="q-pa-xs"><q-input v-model="U_startDate" dense filled type="date"/></div>
                  <div class="q-my-auto"> ~ </div>
                  <div class="q-pa-xs"><q-input v-model="U_endDate" dense filled type="date"/></div>
              </q-card-section>
            </q-card-section>
            <q-card-actions align="right" class="text-primary">
                <q-btn color="white" text-color="black" label="닫기" v-close-popup/>
                <q-btn label="수정" @click="getSave()"/>
            </q-card-actions>
          </q-card>
        </q-dialog>
    </article>
</template>
<script>
import { date } from 'quasar'
import CONSTANTS from '@/constants'
import Select from '@/components/common/Select.vue';
import { today, FormatDate, FormatDateTime, isEmpty } from '@/util'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    components: {
        'i-select' : Select
    },
    data () {
        return {
            typeCode : CONSTANTS.MEMBER_STATUS_TYPE2,
            paymentCode : CONSTANTS.MEMBER_PAYMENT,
            columns: [
                { headerStyle:'text-align:center;', style:'width:3%;', name: 'RNUM', label: '순번', align: 'center', field: (row) => Number(this.totcount) - Number(row.RNUM) + 1 },
                { headerStyle:'text-align:center;', style:'width:9%;', name: 'createTime', label: '회원가입일', align: 'center', field: (row) => FormatDateTime(row.createTime) },
                { headerStyle:'text-align:center;', style:'width:10%;', name: 'id', label: 'ID', align: 'center', field:'maskId' },
                { headerStyle:'text-align:center;', style:'width:10%;', name: 'name', label: '닉네임', align: 'center', field:'name' },
                { headerStyle:'text-align:center;', style:'width:10%;', name: 'phone', label: '연락처', align: 'center', field:'PHONE' }, // 220713 meej 연락처 추가
                { headerStyle:'text-align:center;', style:'width:10%;', name: 'type', label: '회원구분', align: 'center', field: (row) => row.type == "WITHDRAWAL" ? this.typeCode[row.type+'_'] : this.typeCode[row.type+'_'+row.status] }, // 221102 탈퇴회원 조건 추가
                { headerStyle:'text-align:center;', style:'width:*;', name: 'startDate', label: '유료 이용기간', align: 'center', field: (row) => row.startDate == null ? '' : FormatDate(row.startDate) + ' ~ ' + FormatDate(row.endDate) },
                // 220902 meej 결제 방법 표시 안되는 오류 수정_유료회원만 보이게 설정
                // { headerStyle:'text-align:center;', style:'width:10%;', name: 'PAYMENT', label: '결제방법', align: 'center', field: (row) => this.paymentCode[stringToMapValue('PAYMENT', row.options)] },
                { headerStyle:'text-align:center;', style:'width:10%;', name: 'PAYMENT', label: '결제방법', align: 'center', field: (row) => row.type == "REGULAR" ? this.paymentCode[row.PAYMENT] : "" },
                { headerStyle:'text-align:center;', style:'width:10%;', name: 'loginTime', label: '최근로그인', align: 'center', field: (row) => FormatDateTime(row.loginTime) },
                { headerStyle:'text-align:center;', style:'width:10%;', name: 'loginIp', label: 'IP', align: 'center', field:'loginIp' },
                { headerStyle:'text-align:center;', style:'width:8%;', name: 'button', label: '수정/삭제', align: 'center' }
              ],
            pagination: {
                page: 1,
                rowsPerPage: CONSTANTS.LIST_COUNT_PER_PAGE,
                nextCount: 0,
                maxPages: CONSTANTS.DEFAULT_PAGE_NUM
            },
            updatePopup: false,
            isPeriodSet: false,
            U_OldType: '',
            U_type: null,
            U_typeOptions: CONSTANTS.MEMBER_STATUS_TYPE,
            U_startDate: '',
            U_endDate: '',
            U_id: '',
            // 220808 meej 기간 타입으로 변경시 날짜 미리보기 추가
            T_startDate: '',
            T_endDate: '',
            showDateChange: false,
        }
    },
    computed : {
        ...mapState('manage', ['list', 'totcount']),
        ...mapState({
            userId: (state) => state.session.userId,
        }),
        pagesNumber () {
            return Math.ceil(Number(this.totcount) / Number(this.pagination.rowsPerPage));
        }
    },
    created () {
        this.getList();
    },
    methods: {
        ...mapMutations('manage', ['SET_DATA']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),

        //리스트
        getList() {
            var sType = "";
            var sStatus = "";
            if(this.$parent.type != null){
                var arr = this.$parent.type.type.split("_");
                sType = arr[0];
                sStatus = arr[1];
            }
            let param = {
                type: sType,
                status: sStatus == "null" ? "" : sStatus,
                dateGb: this.$parent.dateGb.dateGb,
                dateStr: this.$parent.dateStr,
                dateEnd: this.$parent.dateEnd,
                keywordGb: this.$parent.keywordGb.keywordGb,
                keyword: this.$parent.keyword,
                pageStr: (Number(this.pagination.page) - 1) * Number(this.pagination.rowsPerPage),
                pageSize: this.pagination.rowsPerPage,
                CALL_API_URL: '/member/list'
            }
            this.GET_CALL(param).then((data) => {
                data.items.forEach((row)=>{
                    // 220908 meej 일부 관리자만 핸드폰번호, id 다 보이게 설정
                    if (this.userId != "ggyom_" && this.userId != "mdragon" && this.userId != "minseok" && this.userId != "kangwook" ){
                        row.maskId = this.maskingId(row.id)
                        row.PHONE = '-'
                    } else {
                        row.maskId = row.id
                    }
                })
                this.SET_DATA(data);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        // 220908 meej id 마스킹
        maskingId (strName) {
            if (strName.length > 2) {
                var originName = strName.split('');
                originName.forEach(function(name, i) {
                if (i === 0 || i === originName.length - 1) return;
                    originName[i] = '*';
                });
                var joinName = originName.join();
                return joinName.replace(/,/g, '');
            } else {
                var pattern = /.$/; 
                return strName.replace(pattern, '*');
            }
        },
        //수정팝업
        getUpdatePopup(obj) {
            // 220808 meej U_type watch 오류 없애기 위해 this.U_type = null 추가
            this.U_type = null
            this.U_type = this.U_typeOptions.filter(function (entry){ return entry.type === obj.type+'_'+obj.status; })[0];
            this.U_id = obj.id;
            this.U_OldType = this.typeCode[obj.type+'_'+obj.status];
            this.U_startDate = FormatDate(obj.startDate);
            this.U_endDate = FormatDate(obj.endDate);
            this.updatePopup = true;
            this.T_startDate = this.U_startDate
            this.T_endDate = this.U_endDate
        },
        //저장
        getSave() {
            if (!this.U_type) {
                this.showMessage({ vc:this, message:'회원구분을 선택해 주세요.' });
                return;
            }
            this.showAlert({ vc:this, message:'데이터를 <span style="color:#1976D2;">저장</span> 하시겠습니까?' }).then(()=>{
                const getAddMonth = this.U_type.addMonth
                console.log("getAddMonth=", getAddMonth)
                var arr = this.U_type.type.split("_");
                this.updatePopup = false;

                let param = {
                    'id': this.U_id,
                    'type': arr[0],
                    'status': arr[1],
                    // 'startDate': this.U_startDate,
                    // 'endDate': this.U_endDate,
                    'CALL_API_URL': '/member/save'
                }

                if (this.isPeriodSet === false) {
                  // 회원구분으로 설정할 때,

                  // 220808 meej 기간 타입으로 변경시 날짜 미리보기 추가
                  if(isEmpty(getAddMonth) === false ) {
                    // 유료회원인지 아닌지 확인
                    // param.startDate = today()
                    // param.endDate = addMonth("", getAddMonth)
                    param.startDate = this.T_startDate
                    param.endDate = this.T_endDate
                  } else if(isEmpty(this.U_type.addDay) === false){ 
                    /* 
                        220420 meej 유료회원 1주일 추가
                    */
                    // param.startDate = today()
                    // param.endDate = addDay("", this.U_type.addDay)
                    param.startDate = this.T_startDate
                    param.endDate = this.T_endDate

                  }else {
                    param.startDate = null
                    param.endDate = null
                  }
 
                } else {
                  // 기간으로 설정할 때,
                  param.startDate = this.U_startDate
                  param.endDate = this.U_endDate
                }

                this.GET_CALL(param).then(() => { 
                    this.showMessage({ vc:this, message:'저장 되었습니다.' });
                    this.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },
        //삭제
        getDelete(obj) {
            this.showDeleteAlert({ vc:this, message:'정말로 <span style="color:red;">삭제</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    'id': obj.id,
                    'CALL_API_URL': '/member/delete'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'삭제 되었습니다.' });
                    this.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },
        // 220808 meej 기간 타입으로 변경 날짜 더하기 버튼 추가
        setEndDate(val) {
            let now = new Date(this.T_endDate)
            if(val == 're'){
                this.T_endDate = this.U_endDate
            }else if(val == '7W'){
                const newDate = new Date(now.setDate(now.getDate()+7))
                this.T_endDate = date.formatDate(newDate, 'YYYY-MM-DD')
            }else if(val == '1M'){
                const newDate = new Date(now.setMonth(now.getMonth()+1))
                this.T_endDate = date.formatDate(newDate, 'YYYY-MM-DD')
            }else if(val == '3M'){
                const newDate = new Date(now.setMonth(now.getMonth()+3))
                this.T_endDate = date.formatDate(newDate, 'YYYY-MM-DD')
            }else if(val == '6M'){
                const newDate = new Date(now.setMonth(now.getMonth()+6))
                this.T_endDate = date.formatDate(newDate, 'YYYY-MM-DD')
            }else if(val == '12M'){
                const newDate = new Date(now.setMonth(now.getMonth()+12))
                this.T_endDate = date.formatDate(newDate, 'YYYY-MM-DD')
            }
        }
    },
    watch : {
        // 220808 meej 타입 변경 시 기간 추가
        U_type(val){
            if(val != null){
                if(isEmpty(val.addDay) && isEmpty(val.addMonth)){
                    this.showDateChange = false
                }else{
                    this.showDateChange = true
                    // 준회원, 환불, 영업, 블랙 회원일경우 날짜 기본 set
                    // 220817 meej 이용기간이 이미 지났을 경우 현재일부터 설정
                    if(this.U_startDate == null || isEmpty(this.U_startDate) || this.U_endDate < today('YYYY-MM-DD')){
                        this.T_startDate = today('YYYY-MM-DD')
                    }
                }
                if(val.type == 'REGULAR_w1'){
                    let now = new Date(this.T_startDate)
                    const newDate = new Date(now.setDate(now.getDate()+val.addDay))
                    this.T_endDate = date.formatDate(newDate, 'YYYY-MM-DD')
                }else{
                    let now = new Date(this.T_startDate)
                    const newDate = new Date(now.setMonth(now.getMonth()+val.addMonth))
                    this.T_endDate = date.formatDate(newDate, 'YYYY-MM-DD')
                }
            }
        }
    }
}
</script>