<template>
    <article>
        <q-card>
            <q-card-section>
                <div class="col q-gutter-sm">
                    <div class="col-12">
                        <q-select v-model="stockCode" :options="stockOptions" option-label="name" @filter="filterFn" input-debounce="0" dense outlined :hide-selected="hide_selected" :fill-input="hide_selected" use-input hide-dropdown-icon options-dense>
                            <q-tooltip anchor="top middle" self="bottom middle" :offset="[5, 5]">한글로 검색 시, enter를 눌러주세요.</q-tooltip>
                            <template v-slot:no-option><q-item><q-item-section class="text-grey">검색된 데이터가 없습니다.</q-item-section></q-item></template>
                            <template v-slot:before><label class="i-label">종목검색</label></template>
                        </q-select>
                    </div>
                    <!-- 220707 meej 금융주 목록 추가됨으로 인한 레이아웃 변경 -->
                    <div class="row">
                        <div class="col-12"><i-select v-model="financialGb" :options="financialGbOptions" option-value="value" option-label="name" inputLabel="구분"/></div>
                    </div>
                    <div class="row">
                        <div class="col-12"><i-select v-model="ifrs" :options="ifrsOptions" option-value="value" option-label="name" inputLabel="연결/별도"/></div>
                    </div>
                    <div class="row">
                        <div class="col-12 row">
                            <div><i-select v-model="closingDate" :options="closingDateOptions" option-value="value" option-label="name" inputLabel="결산일" style="width:180px;"/></div>
                            <div class="q-pl-xs"><i-select v-model="closingMonth" :options="closingMonthOptions" option-value="value" option-label="name" style="width:70px;"/></div>
                        </div>
                        </div>
                    <div class="row">
                        <div class="col-12"><i-select v-model="reportType" :options="reportTypeOptions" option-value="value" option-label="name" inputLabel="보고서종류"/></div>
                    </div>
                    <div class="col-12">
                        <div><i-input v-model="item.fieldCode" inputLabel="항목코드" /></div>
                        <div class="q-pa-xs text-left" style="padding-left:20px;">
                            <div>※ 항목코드</div>
                            <div style="padding-left:15px;">매출액 : ifrs_Revenue</div>
                            <div style="padding-left:15px;">영업이익 : dart_OperatingIncomeLoss</div>
                            <div style="padding-left:15px;">순이익 : ifrs_ProfitLoss</div>
                            <div style="padding-left:15px;">지배기업의 소유주지분 : ifrs_ProfitLossAttributableToOwnersOfParent</div>
                        </div>
                    </div>
                    <div class="col-12"><i-input v-model="item.fieldName" inputLabel="항목" /></div>
                    <div class="col-12"><q-input v-model="item.financialValue" type="number" outlined  dense input-class="text-right" style="width:250px;"><template v-slot:before><label class="i-label">금액(원)</label></template></q-input></div>
                </div>
            </q-card-section>
        </q-card>
        <!-- 하단 버튼 -->
        <div class="q-my-xs">
            <i-btn v-show="item.financialId == null" class="full-width" label="등록" @click="getSave" />
            <i-btn v-show="item.financialId != null" class="full-width" label="수정" @click="getSave" />
        </div>
        <!-- 비고 -->
        <q-toolbar class="row q-mt-md">
            <div class="col q-pa-xs">
            <i-input type="textarea" v-model="note" 
                :rows="10"
                inputLabel="비고"
                class="col-6"/>
            </div>
            <i-btn label="저장" @click="updateNote" dense />
        </q-toolbar>
    </article>
</template>
<script>
import CONSTANTS from '@/constants'
import Select from "@/components/common/Select";
import Input from "@/components/common/Input";
import Button from '@/components/common/Button'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Content',
    props: {
        hide_selected: {
            type: Boolean,
            default: true
        }
    },
    components: {
        "i-input": Input,
        'i-btn': Button,
        'i-select' : Select,
    },
    data() {
        return {
            stockList: [],
            stockCode: null,
            stockOptions: [],
            financialGb:{'value':'', 'name':'구분'},
            financialGbOptions: [{'value':'', 'name':'구분'}].concat(CONSTANTS.FINANCIAL_FINANCIAL_GB),
            ifrs: {'value':'', 'name':'연결/별도'},
            ifrsOptions: [{'value':'', 'name':'연결/별도'}].concat(CONSTANTS.COM_IFRS),
            closingDate: {'value':'', 'name':'연도'},
            closingDateOptions: [{'value':'', 'name':'연도'}],
            closingMonth: {'value':'', 'name':'월'},
            closingMonthOptions: [{'value':'', 'name':'월'}],
            reportType: {'value':'', 'name':'리포트'},
            reportTypeOptions: [{'value':'', 'name':'리포트'}].concat(CONSTANTS.FINANCIAL_REPORT_TYPE),
            note: '' // 비고 FINANCIAL_NOTE 
        }
    },
    computed: {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            stockItems : state => state.resource.stockItems
        }),
        ...mapState('manage', ['item', 'items', 'itemList']),
    },
    created () {
        this.$parent.getView(null);
        this.getInit();
        this.getSetting()
    },
    methods: {
        ...mapMutations('manage', ['INIT_ITEM', 'SET_ITEM', 'SET_LIST']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        ...mapActions('resource', ['GET_SETTINGS','SET_SETTINGS']),
        //저장
        getSave() {
            if (this.stockCode == null || this.stockCode == "") {
                this.showMessage({ vc:this, message:'종목을 선택하세요.' });
                return;
            }
            if (this.financialGb.value == "") {
                this.showMessage({ vc:this, message:'구분을 선택하세요.' });
                return;
            }
            if (this.ifrs.value == "") {
                this.showMessage({ vc:this, message:'연결/별도을 선택하세요.' });
                return;
            }
            if (this.closingDate.value == "") {
                this.showMessage({ vc:this, message:'결산일을 선택하세요.' });
                return;
            }
            if (this.closingMonth.value == "") {
                this.showMessage({ vc:this, message:'결산일을 선택하세요.' });
                return;
            }
            if (this.reportType.value == "") {
                this.showMessage({ vc:this, message:'보고서종류를 선택하세요.' });
                return;
            }
            if (!this.item.fieldCode) {
                this.showMessage({ vc:this, message:'항목코드를 입력하세요.' });
                return;
            }
            if (!this.item.fieldName) {
                this.showMessage({ vc:this, message:'항목을 입력하세요.' });
                return;
            }
            if (!this.item.financialValue) {
                this.showMessage({ vc:this, message:'금액(원)을 입력하세요.' });
                return;
            }
            this.showAlert({ vc:this, message:'<span style="color:#1976D2;">저장</span> 하시겠습니까?' }).then(()=>{
                var stockCode = this.stockCode.code;
                let param = {
                    'financialId': this.item.financialId,
                    'stockCode': stockCode,
                    'stockName': this.stockItems.filter(function (entry){ return entry.code === stockCode; })[0].name,
                    'financialGb': this.financialGb.value,
                    'ifrs': this.ifrs.value,
                    'closingDate': this.closingDate.value + this.closingMonth.value + '01',
                    'closingMonth': this.closingMonth.value,
                    'reportType': this.reportType.value,
                    'fieldCode': this.item.fieldCode,
                    'fieldName': this.item.fieldName,
                    'financialValue': this.item.financialValue,
                    'CALL_API_URL': '/financial/save'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'저장 되었습니다.' });
                    this.$parent.getView(null);
                    this.$parent.getList();
                }).catch((err)=>{
                    this.showError({ vc:this, error:err });
                });
            }).catch(() => { });
        },
        filterFn (val, update, abort) {
            // 종목검색 시, 1자리 이하일 경우 검색되지 않음.
            if (val.length < 2) {
                abort();
                return;
            }
            update(() => {
                const needle = val.toLowerCase();
                this.stockOptions = this.stockList.filter(
                    v => v.name.toLowerCase().indexOf(needle) > -1 || v.code.indexOf(val) > -1
                )
            })
        },
        getInit() {
            var iYear = Number(new Date().getFullYear())+2;
            for(var i=iYear; i >= 2016; i--){
                this.closingDateOptions.push({
                    value: i+'',
                    name: i+''
                });
            }
            for(var k=1; k <= 12; k++){
                var sMm = k < 10 ? '0'+k : ''+k;
                this.closingMonthOptions.push({
                    value: sMm,
                    name: sMm
                });
            }
            var list = [];
            try {
                list = JSON.parse(this.stockItems);
            } catch (e) {
                list = this.stockItems;
            }
            var ds_list = [];
            list.forEach(row => {
                ds_list.push({
                    code : row['code'],
                    name : row['name'] + ' (' + row['code'] + ')'
                });
            });
            this.stockList = ds_list;
        },
        getViewItem(obj){
            if(obj != null){
                this.stockCode = this.stockList.filter(function (entry){ return entry.code === obj.stockCode; })[0];
                this.financialGb = this.financialGbOptions.filter(function (entry){ return entry.value === obj.financialGb; })[0];
                this.ifrs = this.ifrsOptions.filter(function (entry){ return entry.value === obj.ifrs; })[0];
                this.closingMonth = this.closingMonthOptions.filter(function (entry){ return entry.value === obj.closingMonth; })[0];
                this.closingDate = this.closingDateOptions.filter(function (entry){ return entry.value === obj.closingDate.substring(0, 4); })[0];
                this.reportType = this.reportTypeOptions.filter(function (entry){ return entry.value === obj.reportType; })[0];
            }
            // else{
                // 220503 meej 재무제표 등록 후 아래의 항목은 그대로 유지 처리
                // 220705 meej 모든 항목 유지 처리 
                // this.stockCode = this.stockList.filter(function (entry){ return entry.code === ''; })[0];
                // this.financialGb = this.financialGbOptions.filter(function (entry){ return entry.value === ''; })[0];
                // this.ifrs = this.ifrsOptions.filter(function (entry){ return entry.value === ''; })[0];
                // this.closingMonth = this.closingMonthOptions.filter(function (entry){ return entry.value === ''; })[0];
                // this.closingDate = this.closingDateOptions.filter(function (entry){ return entry.value === ''; })[0];
                // this.reportType = this.reportTypeOptions.filter(function (entry){ return entry.value === ''; })[0];
            // }
        },
        // 비고 업데이트 
        updateNote() {
            let params = {
                items: [
                {
                    FINANCIAL_NOTE: this.note,
                },
                ],
            };
            this.SET_SETTINGS(params)
                .then(() => {
                this.showMessage({ vc: this, message: "업데이트 완료" });
                })
                .catch((err) => {
                this.showError({ vc: this, error: err });
                });
        },
        // 비고 불러오기 
        getSetting() {
            this.GET_SETTINGS()
                .then((res) => {
                console.log('FINANCIAL_NOTE', res.FINANCIAL_NOTE)
                    if (res.FINANCIAL_NOTE !== undefined) {
                        this.note = res.FINANCIAL_NOTE
                    }
                })
                .catch((e) => {
                    console.log('getSetting error = ', e)
                })
        },
    }
}

</script>
