<template>
    <q-select
        v-model="theme"
        :options="themeOptions"
        option-label="name"
        option-value="code"
        options-dense
        use-input
        @filter="filterFn"
        dense
        outlined
        :hide-selected="hide_selected"
        input-debounce="0"
        @input="sendTheme()"
        :placeholder="innerLabel"
    >
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[5, 5]">
            한글로 검색 시, enter를 눌러주세요.
        </q-tooltip>
        <template v-if="label" v-slot:before>
            <label class="i-label"> {{ label }}</label>
        </template>
        <template v-slot:no-option>
            <q-item>
                <q-item-section class="text-grey">
                    No results
                </q-item-section>
            </q-item>
        </template>
        <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                <!-- <q-item-section>
                    <q-item-label v-html="scope.opt.type" />
                </q-item-section> -->
                <q-item-section>
                    <q-item-label v-html="scope.opt.name" />
                </q-item-section>
            </q-item>
        </template>
        <template v-if="theme" v-slot:append>
            <q-icon
                name="cancel"
                @click.stop="clear()"
                class="cursor-pointer"
            />
        </template>
    </q-select>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import debug from '@/debug'
export default {
    name: 'ThemeSearchOri',
    props: {
        extra: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            default: '테마',
        },
        hide_selected: {
            type: Boolean,
            default: false,
        },
        inputData: {
            type: Object,
            default: null,
        },
        // 20120802 shb 삭제 테마 셀렉트 박스 목록에서 필터링
        filterD: {
            type: Boolean,
            default: false,
        },
        status: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            theme: '',
            themeOptions: [],
        }
    },
    computed: {
        ...mapState({
            themeItems: (state) => state.theme.items,
        }),
    },
    created() {
        if (this.label) {
            this.innerLabel = ''
        }
        this.GET_THEME_LIST({ type: this.status }).then(() => {
            this.themeOptions = this.themeItems
        })

        if (this.inputData) {
            this.theme = this.inputData
        }
    },
    methods: {
        ...mapActions({
            GET_THEME_LIST: 'theme/GET_THEME_LIST',
        }),

        sendTheme() {
            debug.log(`sendTheme ${this.label}, ${this.theme}`)
            if (this.theme) {
                if (this.extra) {
                    this.$emit('get', this.theme, this.extra)
                } else {
                    this.$emit('get', this.theme)
                }
            }
            if (this.hide_selected) {
                debug.log('hide_selected!!!! ')
                this.theme = null
            }
        },
        clear() {
            debug.log('theme search clear !!!!!!!!!!!!!!!')
            this.theme = null
            this.sendTheme()
        },
        filterFn(val, update) {
            const needle = val.toLowerCase()

            update(() => {
                this.themeOptions = this.themeItems.filter(
                    (v) => v.name.toLowerCase().indexOf(needle) > -1
                )
            })
            if (this.filterD) {
                this.themeOptions = this.themeOptions.filter(
                    (item) => item.type !== 'DELETE'
                )
            }
        },
        // filterTheme(val, update){
        //     if(this.themeList === undefined || this.themeList === null || this.themeList.length === 0) {
        //         let params = {  }
        //         this.GET_THEME_LIST(params)
        //             .then(()=>{
        //                 this.themeOptions = this.themeList
        //                 update()
        //             })
        //     } else {
        //         this.themeOptions = this.themeList.filter(v => v.name.indexOf(val) > -1)
        //         update()
        //     }
        // },
    },
    watch: {
        theme(val, old) {
            console.log('watch')
            console.log(val, old)
        },
    },
}
</script>
<style></style>
