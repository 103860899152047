<template>
    <!-- 스케줄형식 -->
    <section class="row q-pa-md">
        <i-list
            class="col-6 q-pl-sm q-pr-sm"
            :newsType="type"
        />
        <i-content
            class="col-6 q-pl-sm q-pr-sm"
            :newsType="type"
        />
    </section>
</template>
<script>
import CONSTANTS from "@/constants";
import List from "@/components/schedule/form/List.vue";
import Content from "@/components/schedule/form/Content.vue";

import { mapState, mapGetters, mapMutations } from "vuex";

export default {
    name: "ScheduleForm",
    components: {
        "i-list": List,
        "i-content": Content,
    },
    data() {
        return {
            type: CONSTANTS.BOARD_TYPE[3], // BOARD_SCHEDULE_FORM
        };
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
        }),
        ...mapGetters("page", [
            "isListPage",
            "isDetailPage",
            "isEditPage",
            "isRegisterPage",
            "isConfirmPage",
        ]),
    },
    created() {},
    methods: {
        ...mapMutations("page", ["setPageStatus"]),

    },
};
</script>
