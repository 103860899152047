import { date } from 'quasar'
import CONSTANTS from '@/constants'
import debug from '@/debug'

export default Object.freeze({
    statusToObj: function (status) {
        if (status === CONSTANTS.STATUS.standby) {
            return { id: CONSTANTS.STATUS.standby, desc: '대기' }
        }
        for (var i = 0; i < CONSTANTS.STATUSLIST.length; i++) {
            if (CONSTANTS.STATUSLIST[i].id === status) {
                return CONSTANTS.STATUSLIST[i];
            }
        }
    }
})

export function objToString(obj) {
    const DELIMITER = ":"
    const SEPARATOR = ","

    let arr = []
    for (let p in obj) {
        arr.push(p + DELIMITER + obj[p])
    }

    return arr.join(SEPARATOR)
}

export function optionsValue(obj) {
    const DELIMITER = "-"
    const SEPARATOR = "|"

    let arr = []
    for (let p in obj) {
        arr.push(p + DELIMITER + obj[p])
    }

    return arr.join(SEPARATOR)
}

export function mapOptionsValue(map) {
    const DELIMITER = "-";
    const SEPARATOR = "|";

    let arr = [];
    for (let [key, value] of map) {
        arr.push(key + DELIMITER + value);
    }
    return arr.join(SEPARATOR);
}

export function stringToArray(str, nameLabel, valueLabel) {
    if(isEmpty(str)) return []

    if (nameLabel == null) {
        nameLabel = 'name0'
    }
    if (valueLabel == null) {
        valueLabel = 'value0'
    }

    const DELIMITER = "-"
    const SEPARATOR = "|"
    let sep = str.split(SEPARATOR)
    let arr = []
    for(let i = 0; i < sep.length; i++) {
        let a = sep[i].split(DELIMITER)
        debug.log(' stringtoarray a = ', a)
        var obj = {}
        obj[valueLabel] = a[0]
        /* 
            220518 meej 종목명칭에 '-' 들어가는 경우 이름이 제대로 안나오는 오류 수정
        */
        if(a.length > 2){
            var str2 = ''
            for(let j=1;j<a.length;j++){
                 str2 += a[j]
                 if(j!=a.length-1){
                     str2 += '-'
                 }
            }
            obj[nameLabel] = str2
        }else{
            obj[nameLabel] = a[1]
        }
        arr.push(obj)
    }
    return arr
}

export function addMonth(format, addMonthValue) {
    if(isEmpty(format) || format === '') {
        format = 'YYYYMMDD'
    }
    let now = new Date()
    const newDate = new Date(now.setMonth(now.getMonth()+addMonthValue))
 
    return date.formatDate(newDate, format)
}

/* 
    220420 meej 유료회원 1주일 추가
*/
export function addDay(format, addDayValue) {
    if(isEmpty(format) || format === '') {
        format = 'YYYYMMDD'
    }
    let now = new Date()
    const newDate = new Date(now.setDate(now.getDate()+addDayValue))
 
    return date.formatDate(newDate, format)
}

export function morning() {
    let now = new Date()
    return (date.formatDate(now, 'A') === 'AM'?0:1)
}
 
// 220127_meej 
// 시간 구분 : 전체, 오전장, 오후장
// 오후장 끝나면 전체로 바꿔줌 추가 _ 시간 값 변수로 추가여부 확인필요
export function morningCheck() {
    let now = new Date()
    let nowTime = parseInt(date.formatDate(now, 'hhmm'))
    // 221117 meej 구분 시간 설정 변경 12시 -> 12시30분
    // return (date.formatDate(now, 'A') === 'AM'?1:(nowTime < 330 || nowTime >= 1200 ? 2:0))
    return (date.formatDate(now, 'A') === 'AM' || (nowTime>= 1200 && nowTime <= 1230) ? 1: (nowTime > 1230 || nowTime < 330) ? 2:0)
}

// 220714 meej 장마감 체크
export function closingCheck() {
    let now = new Date()
    let nowTime = parseInt(date.formatDate(now, 'HHmm'))
    return (nowTime < 1530 ? 0:1)
}

export function today(format) {
    if(isEmpty(format)) {
        format = 'YYYYMMDD'
    }
    let now = new Date()
    return date.formatDate(now, format)
}
export function thisYear() {
    let now = new Date()
    return date.formatDate(now, 'YYYY')
}
export function week() {
    const { addToDate } = date
    let newDate = new Date()
    let week
    do {
        newDate = addToDate(newDate, { days: -1 })
        week = date.formatDate('d')
        debug.log(' week ', week)
    } while(week === '0' || week === '6')
    return date.formatDate(newDate, 'YYYYMMDD')
}

// 분기 (param:0 현재 분기, param:-1 전 분기)
export function quarter(param) {
    let quarters = Math.ceil(today('MM')/3)+param
    if (quarters==0) {quarters=4}
    return quarters
}

// 220722 meej 실적 미리보기에서 사용_오후4시이후 다음날로 설정
export function setQuarter(gubun, param) {
    let quarters
    let now = new Date()
    let nowTime = parseInt(date.formatDate(now, 'hhmm'))
    if(gubun == 1 && date.formatDate(now, 'A') === 'PM' && 400 <= nowTime && nowTime < 1200){
        quarters = Math.ceil(addWorkDay(1,'MM')/3)+param
    } else{
        quarters = Math.ceil(date.formatDate(now, 'MM')/3)+param
    }
    if (quarters==0) {quarters=4}
    return quarters
}

// 연도 (param:0 현재 연도, param:-1 전 연도)
export function year(gubun,param) {
    let now = new Date()
    let years,cha
    if (quarter(0)==1) {cha=param+1} else {cha=param}
    years = now.setFullYear(now.getFullYear() - cha);
    if (gubun==1){years = date.formatDate(years, 'YYYY')}
    else if (gubun==2){years = date.formatDate(years, 'YY')}
    else if (gubun==3){
        // 220722 meej 실적 미리보기에서 사용_오후4시이후 다음날로 설정
        let nowTime = parseInt(date.formatDate(now, 'hhmm'))
        if(date.formatDate(now, 'A') === 'PM' && 400 <= nowTime && nowTime < 1200){
            years = addWorkDay(1, 'YYYY')
        }else{
            years = date.formatDate(years, 'YYYY')
        }
    }
    return years
}

// 220722 meej 실적 미리보기에서 사용_오후4시이후 다음날로 설정
export function setDay(format) {
    if(isEmpty(format)) {
        format = 'YYYYMMDD'
    }
    let now = new Date()
    let nowTime = parseInt(date.formatDate(now, 'hhmm'))
    let day 
    if(date.formatDate(now, 'A') === 'PM' && 400 <= nowTime && nowTime < 1200){
        day = addWorkDay(1, format)
    }else{
        day = date.formatDate(now, format)
    }
    return day
}

export function nowTime(format) {
    if(isEmpty(format)) {
        format = 'HHmmss'
    }

    let now = new Date()
    return date.formatDate(now, format)
}


export function now(format) {
    if(isEmpty(format)) {
        format = 'YYYYMMDDHHmmss'
    }
    let now = new Date()
    return date.formatDate(now, format)
}

export function addWorkDay(num,format) {
    // 금요일의 경우 다음주 월요일로 처리 
    if(isEmpty(format)) {
        format = 'YYYYMMDD'
    }
    let now = new Date()
    now.setDate(now.getDate() + num)
    let day = now.getDay()
    if(day > 5) now.setDate(now.getDate() + 2)
    return date.formatDate(now, format)
}

export function FormatDateTime(datetime) {
    if (datetime) {
        var YYYY, MM, DD, HH, mm, ss

        YYYY = datetime.substring(0, 4)
        MM = datetime.substring(4, 6)
        DD = datetime.substring(6, 8)
        HH = datetime.substring(8, 10)
        mm = datetime.substring(10, 12)
        ss = datetime.substring(12, 14)

        return YYYY + '-' + MM + '-' + DD + ' ' + HH + ':' + mm + ':' + ss
    } else {
        return null
    }
}

export function FormatDate(datetime) {
    if (datetime) {
        var YYYY, MM, DD

        YYYY = datetime.substring(0, 4)
        MM = datetime.substring(4, 6)
        DD = datetime.substring(6, 8)

        return YYYY + '-' + MM + '-' + DD
    } else {
        return null
    }
}

export function FormatTime(time) {
    if (time) {
        var HH, mm

        HH = time.substring(0, 2)
        mm = time.substring(2, 4)
        return HH + ':' + mm
    } else {
        return ''
    }
}

export function FormatTimeSeconds(time) {
    if (time) {
        var HH, mm, ss

        HH = time.substring(0, 2)
        mm = time.substring(2, 4)
        ss = time.substring(4, 6)
        return HH + ':' + mm + ':' + ss
    } else {
        return ''
    }
}
export function compareTime(datetime) {
    let now = new Date()

    // now > datetime => true면, 과거 | false면, 미래    
    // now < datetime => false면, 과거 | true면 미래

    return (date.formatDate(now, 'YYYYMMDDHHmmss') < datetime) 
}
export function rawDateTime(datetime) {
    let temp
    temp = datetime.replace(/-/g, '')
    temp = temp.replace(/:/g, '')
    return temp.trim()
}

export function getByteSize(text) {
    if(isEmpty(text)) return 0
    let byteSize = 0

    for (var i = 0; i < text.length; i++) {
        if (escape(text.charAt(i)).length > 4) {
            byteSize += 2
        } else {
            byteSize++
        }
    }
    return byteSize
}

export function getMaxBytesText(text, max_bytes) {
    if(isEmpty(text)) return 0
    let index, byteSize = 0

    for (index = 0; index < text.length; index++) {
        if (escape(text.charAt(index)).length > 4) {
            byteSize += 2
        } else {
            byteSize++
        }
        // 입력한 문자 길이 보다 넘치면 잘라내기 위해 저장
        if (byteSize > max_bytes) {
            break;
        }
    }
    return text.substr(0, ++index)
}


export function CutDownText(text, len) {
    if (text && text.length > len) {
        text = text.substr(0,len) + '...'
    }
    return text
}

export function isEmpty(value){
    /**
     * value == "" 
     * value 파라미터에 0이 들어 왔을때..
     * Number 유형의 피연산자가 들어오면 다른 피연산자는 Number로 강제 변환 된다.
     * 0 == Number("") 
     * 0 == 0
     * 
     * value === "" 수정
     * shb 20210806 shb
     */
    if( value === "" || value == null || value == undefined
            || ( value != null && typeof value == "object" && !Object.keys(value).length ) ) {
        return true
    } else {
        return false
    }
}

export function clone(obj) {
    if(obj === null || typeof obj !== 'object') {
        return obj;
    }

    const result = Array.isArray(obj) ? [] : {};

    for(let key of Object.keys(obj)) {
        result[key] = clone(obj[key])
    }

    return result;
}

export function handleResize(vc) {
    var windowWidth = window.innerWidth
    // 조회 사이즈 가져오기
    debug.log(vc.$refs.topWrap.clientHeight)
    debug.log(windowWidth)
    debug.log(CONSTANTS.SIZE_md)

    var heightValue

    if (windowWidth < CONSTANTS.SIZE_md) {
        heightValue = 'initial'
    } else {
        heightValue = vc.$refs.topWrap.clientHeight + 'px'
    }
    vc.$parent.$emit('sendHeight', heightValue)
}

export function isNumeric(value) {
    return /^\d+$/.test(value);
}

// 220811 meej 데일리 테마 :등락률
export function numberFormatDecimal(num, defaultNum) {
    if(isEmpty(num)){
        return (defaultNum? defaultNum:'0.00')
    }
    num = parseFloat(num/100).toFixed(2) 
    return num
}

export function numberFormat(num, defaultNum) {
    if(isEmpty(num)){
        return (defaultNum? defaultNum:'0')
    }
    num = num + '';
    num = num.replace(/,/g, '');
    var obj = num.split(".");
    var regexp = /\B(?=(\d{3})+(?!\d))/g
    return obj[0].toString().replace(regexp, ',') + (obj[1] != null ? '.'+obj[1]: '')
}

export function CRUDLabel(crud) {
    if (crud == 'C') {
        return '신규'
    } else if (crud == 'U') {
        return '수정'
    } else if (crud == 'D') {
        return '삭제'
    } else if (crud == 'E') {
        return '제외'
    }
}

export function stringToMapValue(key, val) {
    var rtn = "";
    if(val != null && val != ""){
        var obj = val.split(",");
        for(var i = 0; i < obj.length; i++){
            var arr = obj[i].split(":");
            if(arr[0] == key){
                rtn = arr[1];
                break;
            }
        }
    }
    return rtn;
}

export function stringToMapStockValue(key, val, items) {
    var sCode = "";
    if(val != null && val != ""){
        var obj = val.split(",");
        for(var i = 0; i < obj.length; i++){
            var arr = obj[i].split(":");
            if(arr[0] == key){
                sCode = arr[1];
                break;
            }
        }
    }
    var rtn = sCode;
    
    var list = [];
    try {
        list = JSON.parse(items);
    } catch (e) {
        list = items;
    }
    if(list != null && list.length != 0){

        var itm = list.filter(function (entry){ return entry.code === sCode; })[0];
        if(itm != null){
            rtn = itm.name;
        }
    }
    return rtn;
}

export function stringToStockValue(key, items) {
    var rtn = key;
    var list = [];
    try {
        list = JSON.parse(items);
    } catch (e) {
        list = items;
    }
    if(list != null && list.length != 0){
    var itm = list.filter(function (entry){ return entry.code === key; })[0];
        if(itm != null){
            rtn = itm.name;
        }
    }
    return rtn;
}

export function nvl(value){
    if( value == "" || value == null || value == undefined
            || ( value != null && typeof value == "object" && !Object.keys(value).length ) ) {
        return ''
    } else {
        return value
    }
}
