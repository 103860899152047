<template>
    <section class="row">
        <q-toolbar class="row q-ma-xs justify-center">
            <q-space />
            <div class="q-pr-xl"><i-select v-model="type" :options="typeOptions" option-value="value" option-label="label" style="width:160px;"/></div>
            <div class="q-pl-xs"><i-datetime  date v-model="dateEnd" label="입력일"/></div>
            <div class="q-pl-sm q-pr-xl">이전 10건 </div>
            <div class="q-pr-lg"><i-btn label="검색" @click="getList()"/></div>
            <q-space />
        </q-toolbar>
          <i-list class="col-12 q-pa-xs" ref="vieCountList"/>
    </section>
</template>
<script>
import CONSTANTS from '@/constants'
import Select from '@/components/common/Select.vue';
import DateTime from '@/components/common/DateTime';
import Button from '@/components/common/Button'
import List from "@/components/newspaper/viewCount/List.vue"
import { mapActions } from 'vuex'

export default {
    name: 'Member',
    components: {
        'i-select' : Select,
        'i-datetime': DateTime,
        'i-btn': Button,
        "i-list": List
    },
    data () {
        var now = new Date();
        var dEnd = now.getFullYear() + '' + ((now.getMonth()+1)>9 ? ''+(now.getMonth()+1) : '0'+(now.getMonth()+1)) + '' + (now.getDate()>9 ? ''+now.getDate() : '0'+now.getDate());
        return {
            type: {'value':'', 'label':'전체'},
            typeOptions: [{'value':'', 'label':'전체'}].concat(CONSTANTS.PAPER_TYPE),
            dateEnd: dEnd,
        }
    },
    methods: {
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions('manage', ['GET_CALL']),

        getList() {
            this.$refs.vieCountList.getList();
        },
    }
}
</script>
