<template>
    <section class="row">
        <i-list class="col-6 q-pa-md" />
        <i-content class="col-6 q-pa-md" />
    </section>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import List from '@/components/newspaper/new-recommend/List.vue'
import Content from '@/components/newspaper/new-recommend/Content.vue'

export default {
    name: 'NewRecommend',
    components: {
        'i-list' : List,
        'i-content' : Content
    },
    data () {
        return {
        }
    },
    created () {
        this.setPageStatus(this.PAGE_STATUS.LIST)
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
        }),
        ...mapGetters( 'page', ['isListPage', 'isEditPage', 'isRegisterPage'])
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus'
        })
    }
}
</script>

<style>
    /* common */

    /* xs */
    @media only screen and (max-width: 599px) {
    }

    /* sm */
    @media only screen and (max-width: 1023px) {
    }

    /* md */
    @media only screen and (max-width: 1439px) {
    }

    /* lg */
    @media only screen and (max-width: 1919px) {
    }

    /* xl */
    @media only screen and (min-width: 1920px) {
    }

</style>


