<template>
    <article>
        <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : list.length == 0 }"
            :columns="columns" :data="list" row-key="RNUM" :rows-per-page-options="[0]" style="height:100%;"
             hide-pagination no-data-label="조회되는 데이터가 없습니다.">
            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th v-for="col in props.cols" :key="col.name" :props="props" v-html="col.label"></q-th>
                </q-tr>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td v-for="col in props.cols" :key="col.name" :props="props">
                        <!-- <div v-if="col.name == 'button'"> -->
                            <!-- <span class="q-pa-xs"><q-btn size="xs" label="삭제" color="red" @click="getDelete(props.row)" v-show="role == 'superuser' || role == 'poweruser'"/></span> -->
                            <!-- <span class="q-pa-xs"><q-btn v-show="props.row.status != 'cnfirm'" size="xs" label="전송" color="secondary" @click="getCnfirm(props.row)"/></span> -->
                        <!-- </div> -->
                        <!-- 220707 meej 팍스경제 TV 비밀번호 확인받음 -->
                        <div v-if="col.name == 'button' && isColumnCheck">
                            <div v-if="props.row.status != 'confirm'">
                                <span class="q-pa-xs"><q-btn size="xs" outline color="primary"  label="수정" @click="getView(props.row)"/></span>
                                <span class="q-pa-xs"><q-btn size="xs" label="삭제" color="red" @click="getDelete(props.row)" v-show="role == 'superuser' || role == 'poweruser'"/></span>
                                <span class="q-pa-xs"><q-btn size="xs" label="전송" color="secondary"/>
                                    <q-popup-edit v-model="checkPwd" v-slot="scope">
                                        <q-input v-model="checkPwd" dense autofocus outlined hint="전송 비밀번호를 입력하세요"/>
                                        <div style="text-align:center;margin-top:5px"><q-btn v-show="props.row.status != 'cnfirm'" size="sm" label="전송" color="primary" @click="getCnfirm(props.row)" @click.stop.prevent="scope.cancel"/></div>
                                    </q-popup-edit>
                                </span>
                            </div>
                            <div v-else >
                                전송완료
                            </div>
                        </div>
                        <div v-else>{{ col.value }}</div>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:no-data="{ icon, message }"><div class="full-width row flex-center q-gutter-sm"><q-icon size="2em" :name="icon" /> <span>{{message}}</span></div></template>
        </q-table>
        <div v-show="list.length != 0" class="flex flex-center q-mt-md">
            <q-pagination
            v-model="pagination.page"
            :max="pagesNumber"
            :max-pages="pagination.maxPages"
            :direction-links="true"
            :boundary-numbers="false"
            :boundary-links="true"
            :ellipses="false"
            @click="getList()"
        />
        </div>
    </article>
</template>
<script>
import CONSTANTS from '@/constants'
import { FormatDate, FormatTime, stringToMapStockValue } from '@/util'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    data () {
        return {
            stockList: [],
            columns: [
                { headerStyle:'text-align:center;', style:'width:8%;', name: 'trnsmisNm',  label: '언론사', align: 'center', field:'trnsmisNm'},
                { headerStyle:'text-align:center;', style:'width:8%;', name: 'sendDate', label: '날짜', align: 'center', field: (row) => FormatDate(row.sendDate) + ' ' + FormatTime(row.sendTime) },
                { headerStyle:'text-align:center;', style:'width:28%;', name: 'title',  label: '제목', align: 'left', field:'title'},
                { headerStyle:'text-align:center;', style:'width:8%;', name: 'stocks1',  label: '종목1', align: 'center', field: (row) => stringToMapStockValue('stocks1', row.stocks, this.stockList) },
                { headerStyle:'text-align:center;', style:'width:8%;', name: 'stocks2',  label: '종목2', align: 'center', field: (row) => stringToMapStockValue('stocks2', row.stocks, this.stockList) },
                { headerStyle:'text-align:center;', style:'width:8%;', name: 'stocks3',  label: '종목3', align: 'center', field: (row) => stringToMapStockValue('stocks3', row.stocks, this.stockList) },
                { headerStyle:'text-align:center;', style:'width:8%;', name: 'stocks4',  label: '종목4', align: 'center', field: (row) => stringToMapStockValue('stocks4', row.stocks, this.stockList) },
                { headerStyle:'text-align:center;', style:'width:8%;', name: 'stocks5',  label: '종목5', align: 'center', field: (row) => stringToMapStockValue('stocks5', row.stocks, this.stockList) },
                { headerStyle:'text-align:center;', style:'width:5%;', name: 'hits',  label: '조회수', align: 'center', field:'hits'},
                // 220707 meej 팍스경제 TV 비밀번호 확인받음
                { headerStyle:'text-align:center;', style:'width:13%', name: 'button',  label: '', align: 'center'}
              ],
            page:1,
            pagination: {
                page: 1,
                rowsPerPage: CONSTANTS.LIST_COUNT_PER_PAGE,
                maxPages: 1
            },
            isColumnCheck : false, // 220707 meej 전송확인버튼 여부
            checkPwd : "", // 220707 meej 전송 비밀번호
        }
    },
    computed: {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            stockItems : state => state.resource.stockItems
        }),
        ...mapState('manage', ['list', 'totcount']),
        pagesNumber () {
            return Math.ceil(Number(this.totcount) / Number(this.pagination.rowsPerPage));
        }
    },
    created () {
        this.getSelectInit();
    },
    methods: {
        ...mapMutations('manage', ['SET_DATA', 'INIT_DATA_VIEW', 'SET_DATA_VIEW']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        //리스트
        getList() {
            //  전체로 조회시 시간이 오래걸려서 startdate 를 넣어줌 
            let nextKey = null
            if(this.page < this.pagination.page) {
                nextKey = this.list[this.list.length-1].lastUpdateTime
            } else if(this.page > this.pagination.page) {
                nextKey = this.list[0].lastUpdateTime
            }
            this.page = this.pagination.page
            if(this.page == 1) nextKey = null
            
            let param = {
                client: this.$parent.trnsmisId.trnsmisId,
                // 220708 meej 날짜로 검색시 오류 수정
                sendDate: this.$parent.sendDate.replaceAll("-",""),
                nextKey: nextKey,
                pageStr: (Number(this.pagination.page) - 1) * Number(this.pagination.rowsPerPage),
                pageSize: this.pagination.rowsPerPage,
                CALL_API_URL: '/ir/manage/list'
            }
            this.GET_CALL(param).then((data) => {
                // 220707 meej 팍스경제TV 비밀번호 확인받음 isColumnCheck : true, 에러로 인한 조건 추가
                if(data.items[0] != null && data.items[0].trnsmisNm == "팍스경제TV"){
                    this.isColumnCheck = true
                }else{
                    this.isColumnCheck = false
                }
                // 220810 meej 제목길이 조정
                data.items.forEach((el)=>{
                    // 221116 meej 건설경제 => 대한경제 변경
                    if(el.trnsmisNm == "건설경제"){
                        el.trnsmisNm = "대한경제"
                    }
                    if(el.title.length > 28){
                        const replaceTitle = el.title.substring(0, 28).trim() + '...'
                        el.title = replaceTitle
                    }
                })
                this.SET_DATA(data);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        //상세
        getView(item) {
            this.INIT_DATA_VIEW();
            var sId = "";
            if(item != null && item.id != null){
                sId = item.id;
            }
            let param = {
                'id': sId,
                'CALL_API_URL': '/ir/manage/view'
            }
            this.GET_CALL(param).then((data) => {
                data.itemList = [{'trnsmisId':'', 'trnsmisNm':'선택'}].concat(data.itemList);
                this.SET_DATA_VIEW(data);
                if(data.item != null){
                    this.$parent.$refs.IRflashManageContent.getTrnsmisNmChk(data.item);
                }else{
                    this.$parent.$refs.IRflashManageContent.getTrnsmisNmChk(null);
                }
                this.$parent.getContentClose('Y');
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        //전송
        getCnfirm(obj) {
            // 220707 meej 팍스경제 TV 비밀번호 확인 받음, 비밀번호 고정 (1493)
            var check = this.checkPwd+""
            if(this.isColumnCheck && check != "1493"){
                this.showError({ vc:this, error:"비밀번호가 틀렸습니다." })
                this.checkPwd = ""
                return
            }else {
                this.checkPwd = ""
            }
            this.showAlert({ vc:this, message:'<span style="color:#1976D2;">전송</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    'id': obj.id,
                    'CALL_API_URL': '/ir/manage/saveCnfirm'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'전송 되었습니다.' });
                    this.$parent.getContentClose('');
                    this.$parent.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },
        //삭제
        getDelete(obj) {
            this.showDeleteAlert({ vc:this, message:'<span style="color:red;">삭제</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    'id': obj.id,
                    'CALL_API_URL': '/ir/manage/delete'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'삭제 되었습니다.' });
                    this.$parent.getContentClose('');
                    this.$parent.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },
        getSelectInit(){
            var list = [];
            try {
                list = JSON.parse(this.stockItems);
            } catch (e) {
                list = this.stockItems;
            }
            var ds_list = [];
            list.forEach(row => {
                ds_list.push({
                    code : row['code'],
                    name : row['name'] + ' (' + row['code'] + ')'
                });
            });
            this.stockList = ds_list;
        }
    }
}

</script>
