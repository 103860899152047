<template>
    <div>
        <div class="title q-pb-md">
            <h5 class="text-left">{{ title }}</h5>
        </div>
        <q-separator color="grey-6"/>
        <section>
            <article class="q-my-lg">
                <q-card flat bordered class="q-mx-auto" style="max-width:400px;">
                    <q-card-section>
                        <q-form class="q-gutter-md">
                            <q-input autofocus square filled v-model="oldPasswd" type="password" placeholder="기존 비밀번호" />
                            <q-input square filled v-model="newPasswd" type="password" placeholder="새 비밀번호" />
                            <q-input square filled v-model="repeat" type="password" placeholder="새 비밀번호 확인" />
                            <div class="text-negative">
                                <p class="validation">
                                    {{ validation.firstError('oldPasswd') }}
                                </p>
                                <p class="validation" v-if="!validation.firstError('oldPasswd')" >
                                    {{ validation.firstError('newPasswd') }}
                                </p>
                                <p class="validation"
                                    v-if="!validation.firstError('oldPasswd') && !validation.firstError('newPasswd')">
                                    {{ validation.firstError('newPasswd') }}
                                </p>
                                <p class="validation"
                                    v-if="!validation.firstError('oldPasswd') && !validation.firstError('newPasswd')">
                                    {{ validation.firstError('repeat') }}
                                </p>
                            </div>
                            <div>
                                <q-btn unelevated class="full-width" label="변경" color="dark" @click="submit"/>
                            </div>
                        </q-form>
                    </q-card-section>
                </q-card>
            </article>
        </section>
    </div>
</template>
<script>
import Vue from 'vue';
import InfostockService from '../services/InfostockService'
import { mapActions } from 'vuex'
import CONSTANTS from '../constants'
import Debug from '../debug'
import SimpleVueValidation from 'simple-vue-validator'

const Validator = SimpleVueValidation.Validator

Vue.use(SimpleVueValidation);

export default {
    name: 'MyInfo',
    data () {
        return {
            title: CONSTANTS.MENU.MyInfo,
            oldPasswd: '',
            newPasswd: '',
            repeat: '',
            submitted: false
        }
    },
    validators: {
        oldPasswd: function (value) {
            return Validator.value(value).required('현재 비밀번호를 입력하세요');
        },
        newPasswd: function(newPasswd) {
            Debug.log('validator , newPasswd submit ?', this.submitted)
            return Validator.value(newPasswd).required('새로운 비밀번호를 입력하세요.').minLength(5, '비밀번호는 최소 5자리 이상');
        },
        'repeat, newPasswd': function (repeat, newPasswd) {
            if (this.submitted || this.validation.isTouched('repeat')) {
                Debug.log('validator , repeat submit ?', this.submitted, this.validation.isTouched('repeat'))
                return Validator.value(repeat).required('비밀번호를 입력해주세요.').match(newPasswd, '비밀번호가 동일하지 않습니다.');
            }
        }
    },
    methods : {
        ...mapActions({
            showAlert: 'alert/showAlert',
            showError: 'alert/showError'
        }),
        submit() {

            this.submitted = true;
            this.$validate()
            .then((success) => {
                if (success) {
                    Debug.log('validate success')
                    this.changePassword()
                }  else {
                    Debug.log('validation fail = ', this.validation.errors)
                    var error = this.validation.errors[0].message
                    this.showError({ vc:this, error:error })
                    this.submitted = false
                }
            })
            .catch((error) => {
                Debug.log('validation error = ', error)
                this.showError({ vc:this, error:error })
            })
        },
        changePassword() {

            let oldPasswd = btoa(this.$CryptoJS.SHA256(this.password).toString())
            let newPasswd = btoa(this.$CryptoJS.SHA256(this.password).toString())

            let data = {
                oldPasswd: oldPasswd,
                newPasswd:  newPasswd
            }

            InfostockService.passwd(data)
            .then((response) => {
                Debug.log('passwd --------------')
                Debug.log('response = ', response)
                if(response.success) {
                    this.oldPasswd = ''
                    this.newPasswd = ''
                    this.repeat = ''
                    this.validation.reset()
                    var message = '비밀번호가 변경되었습니다.'
                    this.showAlert({ vc:this, message:message })
                }
            })
            .catch((error) => {
                Debug.log('error = ', error)
                this.showError({ vc:this, error:error })
            })
        }
    }
}
</script>
