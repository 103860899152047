<template>
    <section>
        <div class="row q-px-md q-pt-md">
            <div class="col-12 col-md-5 col-xl-4 q-pa-xs">
                <i-theme-search :inputData="theme" @get="selectTheme" filterD />
            </div>
            <div class="col-6 col-xl-7 q-pa-xs">
                <i-field
                    class="full-width"
                    type="textarea"
                    :text="themeOutline"
                    inputLabel="테마개요"
                />
            </div>
            <div class="col-12 col-md-1 col-xl-1 q-pa-xs text-right">
                <i-btn
                    v-show="isListPage"
                    label="신규"
                    @click="goRegisterPage()"
                />
                <i-btn
                    v-show="!isListPage"
                    label="취소"
                    @click="goListPage()"
                />
            </div>
        </div>
        <div class="row">
            <i-list :theme="theme" class="col-6 q-pa-md" />
            <!-- v-if="isListPage || isDetailPage" -->
            <!-- :class="[isListPage ? 'col-md-12' : 'col-md-6']" -->
            <i-content
                :theme="theme"
                class="col-6 q-pa-md"
            />
            <!-- <i-content
                :theme="theme"
                class="col-6 q-pa-md"
                @writeComplete="getThemeStock()"
            /> -->
            <!-- v-if="isListPage || isDetailPage" -->
            <!-- :class="[isDetailPage ? 'col-md-6' : 'col-md-7']" -->
        </div>
    </section>
</template>
<script>
// import Debug from '@/debug'

import ThemeSearch from '@/components/common/ThemeSearch.vue'
import Button from '@/components/common/Button.vue'
import Field from '@/components/common/Field.vue'
import ThemeList from '@/components/theme/theme-stock/List.vue'
import ThemeContent from '@/components/theme/theme-stock/Content.vue'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'ThemeStock',
    components: {
        'i-list': ThemeList,
        'i-content': ThemeContent,
        'i-btn': Button,
        'i-theme-search': ThemeSearch,
        'i-field': Field,
    },
    data() {
        return {
            theme: null,
            themeOptions: null,
            themeStock: null,
            themeOutline: null,
            isOrder: false,
            grow: true,
        }
    },
    created() {
        this.setPageStatus(this.PAGE_STATUS.LIST)
        this.theme = this.selectedTheme
        if (this.theme !== null) {
            this.themeOutline = this.theme.outline
        } else {
            let req = { menu: 'Theme', index: 0 }
            this.$nextTick(function() {
                this.REQUEST_RESOURCE(req)
            })
        }
        // this.$parent.$on('sendResource', this.addResource)
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            themeStockType: (state) => state.resource.themeStockType,
            themeList: (state) => state.theme.items,
            selectedTheme: (state) => state.theme.theme,
        }),
        ...mapGetters({
            isListPage: 'page/isListPage',
            isEditPage: 'page/isEditPage',
            isRegisterPage: 'page/isRegisterPage',
            isDetailPage: 'page/isDetailPage',
        }),
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('resource', ['REQUEST_RESOURCE']),
        ...mapMutations('theme_stock', ['INIT_LIST']),

        ...mapActions('theme', ['GET_THEME_LIST']),
        ...mapActions('theme_stock', ['ORDER', 'ASYNC_THEMESTOCK_LIST']),
        ...mapActions('alert', ['showAlert', 'showError', 'showMessage']),

        // 목록화면으로 이동하기
        goListPage() {
            const mesg = '입력한 내용을 취소하시겠습니까?'

            if (this.isEditPage || this.isRegisterPage) {
                this.showAlert({ vc: this, message: mesg })
                    .then(() => {
                        this.setPageStatus(this.PAGE_STATUS.LIST)
                    })
                    .catch(() => {})
            } else {
                this.setPageStatus(this.PAGE_STATUS.LIST)
            }
        },
        // 등록화면으로 이동하기
        goRegisterPage() {
            const mesg = '테마를 선택하세요'
            if (this.theme === null || this.theme === undefined) {
                this.showError({ vc: this, error: mesg })
            } else {
                this.setPageStatus(this.PAGE_STATUS.REGISTER)
            }
        },

        selectTheme(val) {
            console.log(val)
            this.theme = val
            this.themeOutline = val.outline

            /* 
                220502 meej 이중으로 getThemeStock 되어 주석처리
             */
            // this.getThemeStock()
        },
        /* 
            220502 meej 이중으로 getThemeStock 되어 주석처리
         */
        // 테마 종목 조회
        // getThemeStock(params) {
        //     Debug.log('getThemeStock params =', params)

        //     if (params === undefined) {
        //         if (this.theme === null || this.theme === undefined) {
        //             let mesg = '테마를 선택하세요'
        //             Debug.log('getThemeStock mesg= ', mesg)
        //             this.showMessage({ vc: this, message: mesg })
        //             return
        //         }
        //         params = {
        //             themeCode: this.theme.code,
        //         }
        //     }

        //     this.INIT_LIST()
        //     this.ASYNC_THEMESTOCK_LIST(params)
        // },
    },
    watch: {},
}
</script>

<style></style>
