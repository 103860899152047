<template>
    <article>
        <!-- 구분 -->
        <!-- <div class="col-12 col-md-4"> -->
        <div class="row i-top-wrap q-my-xs">
            <div class="col-2 flex justify-start q-gutter-xs">
                <i-btn label="삭제" @click="deleteItems()" />
                <!-- <i-btn v-if="is52NewsType" label="속보" @click="writeFlash()" /> -->
            </div>
            <!-- </div> -->
            <!-- <q-space /> -->
            <!-- 
                220408 meej keyword 검색 추가, css 수정
             -->
            <!-- <div class="col-12 col-md-4 q-my-xs"> -->
            <div class="col-2">
                <i-select v-model="titleReq.type" :options="titleReq.options" label="선택" />
            </div> 
            <div class="col-7 q-pl-xs" v-if="titleReq.type == this.titleReq.options[0]">
                <i-stock-search @get="getStock" />
            </div> 
            <div class="col-6 q-pl-xs" v-if="titleReq.type == this.titleReq.options[1]" @keyup.enter="INIT_LIST(); getList()">
                <!-- <i-input v-model="keyword"/> -->
                <i-input :value="keyword" @input="updateKeyword"/>
            </div> 
            <div class="col-1 flex q-pl-xs" v-if="titleReq.type == this.titleReq.options[1]" >
                <i-btn icon="search" @click="INIT_LIST(); getList()" dense/>
            </div> 
            <!-- 220914 meej 표 전체 보기 기능 추가 -->
            <div class="col-1 flex justify-end">
                <q-btn
                    v-if="!listWide"
                    label=">"
                    color="dark"
                    @click="changeListWide(true)"
                >
                    <q-tooltip anchor="center right" self="center left" :offset="[10, 10]">
                        <strong>표 전체 보기</strong>
                    </q-tooltip>
                </q-btn>
                <q-btn
                    v-if="listWide"
                    label="<"
                    color="dark"
                    @click="changeListWide(false)"
                >
                    <q-tooltip anchor="center left" self="center right" :offset="[10, 10]">
                        <strong>표 크기 되돌리기</strong>
                    </q-tooltip>
                </q-btn>
            </div>
        </div>

        <!-- 20230605 날짜별 검색기능 -->
        <div class="col-12 flex justify-end">
            <q-btn label="1주일" @click="getPastData('1w')" :color="activeButton === '1w' ? 'grey' : 'dark'"/>
            <q-btn label="1달" @click="getPastData('1m')"  :color="activeButton === '1m' ? 'grey' : 'dark'"/>
            <q-btn label="3달" @click="getPastData('3m')" :color="activeButton === '3m' ? 'grey' : 'dark'"/>
            <q-btn label="6달" @click="getPastData('6m')" :color="activeButton === '6m' ? 'grey' : 'dark'"/>
            <q-btn label="1년" @click="getPastData('1y')" :color="activeButton === '1y' ? 'grey' : 'dark'"/>
            <q-btn label="전체" @click="getPastData('all')" :color="activeButton === 'all' ? 'grey' : 'dark'"/>
        </div> 


        <!-- 목록 -->
        <i-table
            :columns="dynaColumns"
            :list="list"
            :nextKey="nextKey"
            :showSubContent="true"
            @row-click="getItem"
            @next-click="getList"
            @check-event="handleCheckList"
            
            :init-start-date = "startDate"
            :init-end-date = "endDate"
        />
    </article>
</template>
<script>
import Debug from '@/debug'
import { FormatDate } from '@/util'

import Button from '@/components/common/Button'
import StockSearch from '@/components/common/StockSearch'
import Table from '@/components/common/Table'
import Select from '@/components/common/Select'
import Input from '@/components/common/Input'
import dayjs from 'dayjs'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    components: {
        'i-btn': Button,
        'i-stock-search': StockSearch,
        'i-table': Table,
        'i-select' :  Select,
        'i-input' :  Input,
    },
    props: {
        newsType: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            // 조건
            stock: '',
            selected: [],
            // columns: [
            //     { name: 'createTime', label: '입력일자', align: 'left', field: row => FormatDate(row.createTime) },
            //     { name: 'newsType', label: '기사구분', align: 'left',
            //         field: row => (this.stockNewsType.find(v => v.value == row.newsType1) !== undefined?this.stockNewsType.find(v => v.value == row.newsType1).name:'??') },
            //     { name: 'stockCode', label: '종목', align: 'left', field: row => `${row.stockName} ( ${row.stockCode} )` },
            //     { name: 'content', label: '내용', align: 'left', field: row => CutDownText(row.content, 30) },
            // ],
            flashNewsType: 'MARKET_FLASH_52_WEEKS_NEW_HIGH',
            titleReq: {
                type: null,
                options:[
                    {label: '종목', value: 'stock'},
                    {label: '내용', value: 'content'},
                ],
            },
            // keyword : '',
            listWide : false, // 220624 meej 리스트 전체보기 기능 추가
            activeButton: 'all',
        }
    },
    created() {
        this.titleReq.type = this.titleReq.options[0]
        
        // 20230605 날짜별 검색기능 추가
        this.startDate = dayjs(new Date()).format('YYYYMMDD');
        this.endDate = dayjs(new Date()).format('YYYYMMDD');
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            stockNewsType: (state) => state.resource.StockNewsType,
            searchedStockName: (state) => state.stock.searchedStockName,
        }),
        ...mapState('stock_news', ['list', 'nextKey', 'keyword']),
        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isEditPage',
            'isRegisterPage',
            'isFlashPage',
        ]),

        is52NewsType: function() {
            if (this.newsType) {
                return this.newsType.value == 'STOCK_52_WEEKS_NEW_HIGH'
            } else {
                return false
            }
        },
        /* 
            220511 meej SPOT입력 구분에 상관없이 삭제, 수정 내역 출력, 주석처리
        */
        // isStockAll() {
        //     return (
        //         (this.newsType && this.newsType.value) ==  'STOCK_FEATURED_ALL' ||
        //         (this.newsType && this.newsType.value) == 'STOCK_ALL' ||
        //         /* 
        //             220412 meej 특징주(장마감) : 삭제된 내용 표시
        //         */
        //         (this.newsType && this.newsType.value) =='STOCK_FEATURED'
        //     )
        // },
        // 20210709 shb 상한가, 하한가 선택시 테이블에 필드 추가 "일수 " 요구사항 추가
        dynaColumns() {
            const columns = [
                {
                    name: 'showDate',
                    label: '입력날짜',
                    align: 'left',
                    field: (row) => FormatDate(row.showDate),
                },
                {
                    name: 'lastUpdateTime',
                    label: '수정날짜',
                    align: 'left',
                    field: (row) => FormatDate(row.lastUpdateTime),
                },
                {
                    name: 'newsType',
                    label: '기사구분',
                    align: 'left',
                    field: (row) =>
                        this.stockNewsType.find(
                            (v) => v.value == row.newsType1
                        ) !== undefined
                            ? this.stockNewsType.find(
                                  (v) => v.value == row.newsType1
                              ).name
                            : row.newsType1 == 'STOCK_PM_FEATURED'
                            ? '오후장'
                            : row.newsType1 == 'STOCK_AM_FEATURED'
                            ? '오전장'
                            : '??',
                },
                {
                    name: 'stockCode',
                    label: '종목',
                    align: 'left',
                    field: (row) => this.stockOrGroupField(row),
                },
                // 230206 meej SPOT 입력 내용 별도의 한줄로 생성
                // {
                //     name: 'content',
                //     label: '내용',
                //     align: 'left',
                //     field: (row) =>
                //         row.newsType1 === 'STOCK_FEATURED' ||
                //         row.newsType1 === 'STOCK_AM_FEATURED' ||
                //         row.newsType1 === 'STOCK_PM_FEATURED'
                //             ? row.title
                //             : row.content,
                //             // : CutDownText(row.content, 30), // 220914 meej 주석처리
                // },
            ]
            if (
                this.newsType === null ||
                this.newsType.value === 'STOCK_JUMP' ||
                this.newsType.value === 'STOCK_SLUMP' ||
                this.newsType.value === 'STOCK_ALL'
            ) {
                const option = {
                    name: 'option',
                    label: '일수',
                    align: 'left',
                    field: (row) => (row.DAYS === undefined ? '' : row.DAYS),
                }
                columns.splice(3, 0, option)
            }
            /* 
                220511 meej SPOT입력 구분에 상관없이 삭제, 수정 내역 출력, 주석처리
            */
            // if (this.isStockAll) {
                columns.unshift({
                    name: 'crud',
                    label: '상태',
                    align: 'left',
                    // class: 'text-red',
                    style: (row) => (row.crud === 'D' ? 'color:#dc3545' : row.crud === 'U' ? 'color:#007bff' : 'color:#000'),
                    field: (row) => (row.crud === 'D' ? '삭제' : row.crud === 'U' ? '수정' : ''),
                })
            // }

            return columns
        },
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('stock_news', ['INIT_LIST', 'SET_DETAIL', 'SET_KEYWORD']),
        ...mapMutations('flash', ['SET_PREVIEW']),
        ...mapMutations('stock', ['SET_SEARCHED_STOCK_NAME']),

        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions('flash', ['PREVIEW_ITEM', 'WRITE_FLASH']),
        ...mapActions('stock_news', [
            'ASYNC_DETAIL',
            'DELETE_ITEMS',
            // 230215 meej 리스트 조회 api 변경
            // 'ASYNC_LIST',
            'ASYNC_SPOT_LIST',
        ]),

        updateKeyword (e) {
            this.SET_KEYWORD(e)
        },

        // 220914 meej 리스트 전체보기 기능 추가
        changeListWide(val){
            this.listWide = val
            this.$emit('event-list-wide', val)
        },
        stockOrGroupField(row) {
            if (
                (row.newsType1 == 'STOCK_FEATURED' ||
                    row.newsType1 == 'STOCK_PM_FEATURED' ||
                    row.newsType1 == 'STOCK_AM_FEATURED') &&
                row.GROUP_NAME.length > 0
            ) {
                return row.GROUP_NAME
            } else {
                return `${row.stockName} ( ${row.stockCode} )`
            }
        },
        goFlashPage() {
            // TODO: 속보
            //

            // var params = {}
            // params.newsType = 'MARKET_FLASH_52_WEEKS_NEW_HIGH'

            // this.PREVIEW_ITEM(params)
            // .then((data) => {
            //     this.setPageStatus(this.PAGE_STATUS.FLASH)
            //     console.log(data)
            // })
            // .catch((error) =>{
            //     this.showError({ vc:this, error:error })
            //     Debug.log('error = ', error)
            // })
            this.setPageStatus(this.PAGE_STATUS.FLASH)
        },
        handleCheckList(list) {
            this.selected = list
        },
        // 종목 가져오기
        getStock(stock) {
            this.stock = stock

            this.INIT_LIST()
            this.getList()
        },
        
        // 20230605 날짜별 검색기능 추가
        getPastData(period) {
            const today = new Date();
            let startDate, endDate;

            switch (period) {
                case '1w':
                    startDate = dayjs(today).subtract(1, 'week').format('YYYYMMDD');
                    endDate = dayjs(today).format('YYYYMMDD');
                    this.activeButton = '1w';
                    break;
                case '1m':
                    startDate = dayjs(today).subtract(1, 'month').format('YYYYMMDD');
                    endDate = dayjs(today).format('YYYYMMDD');
                    this.activeButton = '1m';
                    break;
                case '3m':
                    startDate = dayjs(today).subtract(3, 'month').format('YYYYMMDD');
                    endDate = dayjs(today).format('YYYYMMDD');
                    this.activeButton = '3m';
                    break;
                case '6m':
                    startDate = dayjs(today).subtract(6, 'month').format('YYYYMMDD');
                    endDate = dayjs(today).format('YYYYMMDD');
                    this.activeButton = '6m';
                    break;
                case '1y':
                    startDate = dayjs(today).subtract(1, 'year').format('YYYYMMDD');
                    endDate = dayjs(today).format('YYYYMMDD');
                    this.activeButton = '1y';
                    break;
                case 'all':
                    startDate = dayjs(today).subtract(10, 'year').format('YYYYMMDD');
                    endDate = dayjs(today).format('YYYYMMDD');
                    this.activeButton = 'all';
                    break;
                default:
                    startDate = dayjs(today).subtract(10, 'year').format('YYYYMMDD');
                    endDate = dayjs(today).format('YYYYMMDD');
                    this.activeButton = 'all';
                    console.log("기본");
                    break;
            }
            this.startDate = startDate;
            this.endDate = endDate;
            this.period = period
        },

        // 20230605 날짜별 데이터 조회
        getList(period, val) {

            period = this.period
            
            if( !period){
                this.getPastData("default")
            }

            var params = {
                newsType: this.newsType.value,
                startDate: this.startDate,
                endDate: this.endDate
            }

            if(this.titleReq && this.titleReq.type){
                if(this.titleReq.type == this.titleReq.options[1]){
                    if(this.keyword != '' && this.keyword != null){
                        params.keyword = this.keyword
                        // 230215 meej SPOT입력 리스트 조회시 오류로 null 처리 추가
                    }
                    this.stock = null
                    params.searchedStockName = null
                    this.SET_SEARCHED_STOCK_NAME(null)
                } else {
                    if (this.stock != '' && this.stock != null) {
                        // 230215 meej SPOT입력 리스트 조회시 오류로 조건 및 설정 추가
                        if(this.searchedStockName) {
                            params.searchedStockName = this.searchedStockName
                            params.code = this.stock.code
                        }
                    } 
                    this.SET_KEYWORD(null)
                }
            }
        
            /* 
                220408 meej keyword 검색 추가
            *
            /* 
                220511 meej SPOT입력 구분에 상관없이 삭제, 수정 내역 출력, 주석처리
            */
            //2021.11.08 suki - spot 에 특징주 (장중)은 삭제도 조회
            // if (this.newsType.value === 'STOCK_FEATURED_ALL') {
                params.delete = true
            // }
            /* 220527 meej SPOT입력 조회여부 추가*/
            params.isSpotList = true
            
            // 230215 meej SPOT입력 리스트 조회시 오류로 주석처리
            // if(this.searchedStockName) params.searchedStockName = this.searchedStockName
            
            // 230215 meej SPOT입력 페이징처리 방식 변경으로 인해 pageNo 추가
            if(val != undefined && val != null){
                params.pageNo = parseInt(val/5)
            }

            // 230215 meej SPOT 입력 리스트 조회 API 변경
            // this.ASYNC_LIST(params)
            this.ASYNC_SPOT_LIST(params)
            
            // this.keyword = null
            // this.stock = null
        },
        // 상세 조회하기
        getItem(item) {
            Debug.log('getItem item = ', item)
            this.SET_DETAIL(item)
            this.setPageStatus(this.PAGE_STATUS.DETAIL)
        },
        // 삭제하기
        deleteItems() {
            if (this.selected.length == 0) {
                this.showMessage({
                    vc: this,
                    message: '삭제할 항목을 선택해주세요.',
                })
            } else {
                this.showAlert({
                    vc: this,
                    message: '선택한 항목을 삭제하시겠습니까?',
                }).then(() => {
                    this.doDelete()
                })
            }
        },
        doDelete() {
            var params = {}
            params.ids = this.selected.map((x) => x.id).join(',')
            //공시, 이슈, 상한가, 하한가, 급등락, 52주, 시간외 단일가는 Update
            params.force = 0
            this.DELETE_ITEMS(params)
                .then(() => {
                    this.showMessage({ vc: this, message: '삭제되었습니다.' })

                    this.INIT_LIST()
                    this.getList()
                })
                .catch((err) => {
                    var error = err.message
                    this.showError({ vc: this, error: error })
                    Debug.log('error = ', error)
                })
        },
        writeFlash() {
            if (!this.is52NewsType) {
                this.showAlert({
                    vc: this,
                    message: '속보 뉴스타입을 선택해주세요.',
                })
                return
            }

            var params = {}
            params.newsType = this.flashNewsType

            this.WRITE_FLASH(params)
                .then((res) => {
                    Debug.log('data = ', res)
                    this.SET_PREVIEW(res)
                    this.goFlashPage()
                })
                .catch((err) => {
                    Debug.log('error = ', err)
                    this.showError({ vc: this, error: err })
                })
        },
    },
}
</script>
