<template>
    <article>
        <!-- 구분 -->
        <div class="i-top-wrap row">
            <!-- 220624 meej 순서변경 상태 일때는 전체 검색 안보이게 처리 -->
            <div class="justify-start row " :class="moveButton? 'col-5':'col-6'">
                <i-field class="col-1" v-show="!ordering" check v-model="allSearch" label="전체" />
                <!-- 221102 meej 삭제포함여부 추가 특징테마에서만 보이도록 설정 -->
                <!-- 221111 meej 전체목록에 삭제 포함으로 변경_주석처리 -->
                <!-- <i-field class="col-2" v-show="!ordering&&!allSearch&!moveButton" check v-model="containDel" label="삭제포함" /> -->
                <!-- 220622 meej 전체 체크 되어있을 땐 날짜 검색 안되게 처리 -->
                <i-datetime
                    class="col-5"
                    date
                    v-model="dateSearch"
                    :disabled = "allSearch"
                />
                <i-select
                    class="col-4 q-pl-sm"
                    v-model="type"
                    :options="typeOption"
                    label="시간구분"
                    @input="getList()"
                />
            </div>
            <div class="flex justify-end q-pa-xs q-col-gutter-xs" :class="moveButton? 'col-7':'col-6'">
                <!-- 22.04.05 suye 순서변경 클릭시 확인&삭제 버튼 미표시 -->
                <div class="flex" v-show="ordering == false">
                    <i-btn label="확인" @click="confirmList()" />
                </div>
                <div class="flex" v-show="ordering == false">
                    <i-btn label="삭제" @click="deleteList()" />
                </div>
                
                <!-- 220511 meej 등락률 업데이트 기능추가 -->
                <div class="flex" v-show="ordering && updateRate">
                    <i-btn
                        :disabled = "allSearch"
                        label="등락률 업데이트"
                        @click="updateList()"
                    />
                </div>
                <div class="flex">
                    <!-- 220624 meej 전체일경우엔 순서변경 기능 사용 못하게 처리 -->
                    <!-- 220712 meej 특징주 - 순서변경목록의 정렬과, 목록의 정렬을 다르게 처리함 ordering 여부전달 -->
                    <i-btn
                        v-if="orderButton"
                        :disabled = "allSearch"
                        :label="orderLabel"
                        @click="ordering = !ordering, getList(ordering? 1:0)"
                    />
                </div>
                <div class="flex">
                    <!-- 220627 meej 순서변경 리스트 일때는 안보이게 처리 -->
                    <i-btn
                        v-show="!ordering"
                        label="이동"
                        v-if="moveButton"
                        @click="changeNewsType()"
                    />
                </div>
                <div class="flex">
                    <!-- 220905 meej 이슈 이동 기능 추가 -->
                    <i-btn
                        v-show="!ordering"
                        label="이슈이동"
                        v-if="moveButton"
                        @click="moveIssue()"
                    />
                </div>
                <!-- 220624 meej 표 전체 보기 기능 추가 -->
                <div class="flex">
                    <q-btn
                        v-if="!listWide"
                        label=">"
                        color="dark"
                        @click="changeListWide(true)"
                    >
                        <q-tooltip anchor="center right" self="center left" :offset="[10, 10]">
                            <strong>표 전체 보기</strong>
                        </q-tooltip>
                    </q-btn>
                    <q-btn
                        v-if="listWide"
                        label="<"
                        color="dark"
                        @click="changeListWide(false)"
                    >
                        <q-tooltip anchor="center left" self="center right" :offset="[10, 10]">
                            <strong>표 크기 되돌리기</strong>
                        </q-tooltip>
                    </q-btn>
                </div>
            </div>
        </div>
        <!-- 220624 meej 등락률 업데이트 실행 시간 추가 -->
        <div v-show="ordering && updateRate" class="row justify-end" style="font-size:13px">
            마지막 등락률 업데이트 실행 시간 : {{rateUpdateTime !== null ? rateUpdateTime:"--:--:--" }}
        </div>
        <!-- 
            220426 meej 특징주 요일 검색 시 보이는 table, 페이징 없앰
         -->
        <div v-if="!ordering && !allSearch">
            <q-table
                flat
                bordered
                dense
                class="i-table"
                :class="{ 'no-data': list.length == 0 }"
                :columns="columns"
                :data="list"
                row-key="index"
                :selected-rows-label="getSelectedString"
                selection="multiple"
                :selected.sync="selected"
                separator="cell"
                no-data-label="조회되는 데이터가 없습니다."
                :rows-per-page-options="[0]"
            >
                <template v-slot:header="props">
                    <q-tr :props="props">
                        <q-th class="text-left">
                            <q-checkbox v-model="allCheck" size="xs" />
                        </q-th>
                        <q-th auto-width />
                        <q-th
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props"
                        >
                            {{ col.label }}
                        </q-th>
                    </q-tr>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props" :class="trColor(props.row)">
                        <q-td class="text-left">
                            <q-checkbox
                                v-model="props.row.checked"
                                v-show="props.row.crud != 'D'"
                                @input="check(props.row)"
                                size="xs"
                            />
                        </q-td>
                        <q-td v-if="props.row.crud != 'D' && props.row.showFlag === 1" class="text-left">
                            <q-icon
                                name="check"
                                class="cursor-pointer"
                                @click="confirm(props.row)"
                            />
                        </q-td>
                        <q-td v-else auto-width>
                            <!-- 221102 meej 삭제내용 표시추가 -->
                            <q-btn
                                v-if="props.row.crud != 'D'"
                                size="sm"
                                color="accent"
                                outline
                                label="확인"
                                dense
                                @click="confirm(props.row)"
                            />
                            <span v-if="props.row.crud == 'D'" style="color:red;font-weight:bold">삭제</span>
                        </q-td>

                        <q-td
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props"
                            :class="textColor(col.name, col.value)"
                            @click="getItem(props.row)"
                        >
                            {{ col.value }}
                        </q-td>
                    </q-tr>
                    <q-tr :props="props">
                        <q-td colspan="2" style="background:lightgray">
                            <div class="text-left">
                                이슈요약
                            </div>
                        </q-td>
                        <q-td colspan="5">
                            <div
                                class="text-left"
                                style="word-break:break-all; min-width:500px;white-space: pre-line;"
                            >
                                {{ props.row.title }}
                            </div>
                        </q-td>
                    </q-tr>
                    <q-tr :props="props">
                        <q-td colspan="100%">
                            <div
                                class="text-left"
                                style="word-break:break-all; min-width:500px;white-space: pre-line;"
                            >
                                {{ props.row.content }}
                            </div>
                        </q-td>
                    </q-tr>
                    <!-- 220805 meej 종목별(테마별)로 구분 칸 추가 -->
                    <q-tr :props="props" style="height:14px">
                        <q-td colspan="100%" style="background:lightgrey;height:100%"></q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
        <!-- 
            220426 meej 특징주 전체 체크시 보이는 table
         -->
        <div v-if="!ordering && allSearch">
            <q-table
                flat
                bordered
                dense
                class="i-table"
                :class="{ 'no-data': list.length == 0 }"
                :columns="columns"
                :data="list"
                row-key="index"
                :selected-rows-label="getSelectedString"
                selection="multiple"
                :selected.sync="selected"
                separator="cell"
                :pagination.sync="pagination"
                hide-pagination
                no-data-label="조회되는 데이터가 없습니다."
            >
                <template v-slot:header="props">
                    <q-tr :props="props">
                        <q-th class="text-left">
                            <q-checkbox v-show="!allSearch" v-model="allCheck" size="xs" />
                        </q-th>
                        <q-th auto-width />
                        <q-th
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props"
                        >
                            {{ col.label }}
                        </q-th>
                    </q-tr>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props" :class="trColor(props.row)">
                        <q-td class="text-left">
                            <q-checkbox
                                v-if="props.row.crud != 'D'"
                                v-model="props.row.checked"
                                @input="check(props.row)"
                                size="xs"
                            />
                        </q-td>
                        <q-td v-if="props.row.crud != 'D' && props.row.showFlag === 1" class="text-left">
                            <q-icon
                                name="check"
                                class="cursor-pointer"
                                @click="confirm(props.row)"
                            />
                        </q-td>
                        <q-td v-else auto-width>
                            <q-btn
                                v-if="props.row.crud != 'D'"
                                size="sm"
                                color="accent"
                                outline
                                label="확인"
                                dense
                                @click="confirm(props.row)"
                            />
                            <span v-if="props.row.crud == 'D'" style="color:red;font-weight:bold">삭제</span>
                        </q-td>

                        <q-td
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props"
                            :class="textColor(col.name, col.value)"
                            @click="getItem(props.row)"
                        >
                            {{ col.value }}
                        </q-td>
                    </q-tr>
                    <q-tr :props="props">
                        <q-td colspan="2" style="background:lightgray">
                            <div class="text-left">
                                이슈요약
                            </div>
                        </q-td>
                        <q-td colspan="5">
                            <div
                                class="text-left"
                                style="word-break:break-all; min-width:500px;white-space: pre-line;"
                            >
                                {{ props.row.title }}
                            </div>
                        </q-td>
                    </q-tr>
                    <q-tr :props="props">
                        <q-td colspan="100%">
                            <div
                                class="text-left"
                                style="word-break:break-all; min-width:500px;white-space: pre-line;"
                            >
                                {{ props.row.content }}
                            </div>
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
            <div class="row justify-center q-mt-md">
                <q-pagination
                    v-model="pagination.page"
                    :max="pagesNumber"
                    :max-pages="pagination.maxPages"
                />
                <div>
                    <q-btn
                        v-if="nextKey"
                        flat
                        icon="navigate_next"
                        color="dark"
                        @click="goNext()"
                    />
                </div>
            </div>
        </div>
        <!-- 순서 변경 table -->
        <!-- 220428 meej 특징주, 특징테마에서는 순서변경시 단일 체크만 가능 -->
        <div v-if="ordering">
            <i-otable
                :columns="orderColumns"
                :list="list"
                :onlyOrder="true"
                @order-event="handleOrderList"
                @row-event="getItem"
                :singleCheck="true"
            />
        </div>
    </article>
</template>
<script>
import debug from '@/debug'
import CONSTANTS from '@/constants'
import Button from '@/components/common/Button'
//import Table from '@/components/common/Table'
import Select from '@/components/common/Select'
import Field from '@/components/common/Field'
import OrderTable from '@/components/common/OrderTable'
import DateTime from '@/components/common/DateTime'
// 220127_meej morning -> morningCheck로 변경처리 
// import { today, morning } from '@/util'
import { today, morningCheck } from '@/util'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
// 리스트 요청 : event-get-list({today (true, false), newsType})
// 리스트 click : event-click-row(item)
// 순서 변경 : event-order-featured (list)
// confirm : event-confirm(array)
// 삭제  : event-delete(array)
// check  : event-check(array)
// 이동 : event-change(array)
export default {
    name: 'List',
    components: {
        //        'i-table' : Table,
        'i-select': Select,
        'i-otable': OrderTable,
        'i-btn': Button,
        'i-datetime': DateTime,
        'i-field': Field,
    },
    props: {
        columns: {
            type: Array,
        },
        list: {
            type: Array,
        },
        nextKey: {
            type: String,
        },
        typeOption: Array,
        orderColumns: Array,
        moveButton: {
            type: Boolean,
            default: false,
        },
        orderButton: {
            type: Boolean,
            default: false,
        },

        // 220511 meej 등락률 업데이트 기능추가
        updateRate:{
            type: Boolean,
            default: false,
        },
        // 220624 meej 등락률 업데이트 시간 설정
        rateUpdateTime:{
            type: String,
            default: null,
        },
    },
    data() {
        return {
            type: null,
            // 조건
            dateSearch: null,
            // 조건
            allSearch: false,
            selected: [],
            pagination: {
                page: 1,
                rowsPerPage: CONSTANTS.LIST_COUNT_PER_PAGE,
                nextCount: 0,
                maxPages: CONSTANTS.DEFAULT_PAGE_NUM
            },
            ordering: false,
            allCheck: false,
            deleteOrderList: [],
            // 220624 meej 리스트 전체보기 기능 추가
            listWide : false,
            // containDel: false, // 221111 meej 전체목록에 삭제 포함으로 변경_주석처리
        }
    },
    created() {
        //newsType 첫번째
        this.type = this.typeOption[0]

        //default 당일
        this.dateSearch = today()
        debug.log('datetime = ', this.dateSearch)
        if (this.rows > CONSTANTS.LIST_COUNT_PER_PAGE) {
            this.pagination.rowsPerPage = this.rows
        }
 
        // 220127_meej morning -> morningCheck로 변경처리 
        // if(morning() === 1) {
        //     this.type = this.typeOption[(this.typeOption.length-1)]
        // }
        this.type = this.typeOption[morningCheck()]
        //this.time = (morning() === 0?this.timeOptions[0]:this.timeOptions[1])
        //this.getList()
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
        }),
        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isEditPage',
            'isRegisterPage',
        ]),
        pagesNumber() {
            return ((this.list.length / this.pagination.rowsPerPage) < (this.pagination.rowsPerPage * this.pagination.maxPages)?Math.ceil(this.list.length / this.pagination.rowsPerPage):(this.list.length / this.pagination.rowsPerPage))
            // return Math.ceil(this.list.length / this.pagination.rowsPerPage)
        },
        orderLabel() {
            return this.ordering ? '취소' : '순서변경'
        },
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('stock_news', [
            'INIT_DETAIL',
            'SET_FLASH_TYPE',
            'SET_DETAIL',
        ]),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),

        // 220624 meej 리스트 전체보기 기능 추가
        changeListWide(val){
            this.listWide = val
            this.$emit('event-list-wide', val)
        },

        // table handler
        trColor(row) {
            if (row.ISSUE !== undefined && row.ISSUE === '1')
                return 'bg-amber-5'
        },
        textColor(name, value) {
            if (name === 'changeRate') {
                if (value.startsWith('-') && value.length > 1)
                    return 'text-blue'
                else if (value === '0.00%' || value === '-') return 'text-black'
                return 'text-red'
            } else if (name === 'tradingMarket') {
                return value === '코스피' ? 'text-blue' : 'text-red'
            } else if (name === 'type') {
                return value === '오전장' ? 'text-blue' : 'text-red'
            }
            return 'text-black'
        },
        getSelectedString() {
            debug.log('getSelectedString = ', this.selected.length)
            return this.selected.length === 0
                ? ''
                : `${this.selected.length}개 선택`
        },
        getItem(item) {
            // debug.log('getItem item = ', item)
            this.$emit('event-click-row', item)
        },
        goNext() {
            this.pagination.nextCount++
            this.pagination.page = this.pagination.nextCount * 5 + 1
            //get-list
            let condition = {
                all: this.allSearch,
                date: this.dateSearch,
                newsType: this.type.value,
                init: false,
            }
            this.$emit('event-get-list', condition)
        },
        check(item) {
            if (item.checked) {
                this.selected.push(item)
            } else {
                var index = this.selected.indexOf(item)
                if (index !== -1) {
                    this.selected.splice(index, 1)
                }
            }
            this.$emit('event-check-row', this.selected)
        },
        confirm(item) {
            this.$emit('event-confirm', [item])
        },
        confirmList() {
            if (this.selected.length == '0') {
                this.showMessage({ vc: this, message: '항목을 선택해주세요.' })
            } else {
                this.showAlert({
                    vc: this,
                    message: '선택한 항목의 상태로 변경하시겠습니까?',
                }).then(() => {
                    this.$emit('event-confirm', this.selected)
                    // 220610 meej 변경후 selected 초기화 추가
                    this.selected = []
                })
            }
        },
        deleteList() {
            console.log('삭제하러옴 ', this.selected.length)
            console.log('this.deleteOrderList: ', this.deleteOrderList)
            if (this.selected.length == 0) {
                this.showMessage({
                    vc: this,
                    message: '삭제할 항목을 선택해주세요.',
                })
            } else {
                this.showAlert({
                    vc: this,
                    message: '선택한 항목을 삭제하시겠습니까?',
                }).then(() => {
                    this.$emit('event-delete', this.selected)
                    /* 220622 meej 삭제후 체크내역 초기화 */
                    this.selected = []
                })
            }
        },
        /* 
            220511 meej 등락률 업데이트 기능 추가
        */
        updateList() {
            console.log('등락률 업데이트 처리 1 ', this.list.length)
            console.log('등락률 업데이트 처리 2 ', this.type)
            if (this.list.length == 0) {
                this.showMessage({
                    vc: this,
                    message: '등락률을 업데이트할 항목이 없습니다.',
                })
            } else {
                let msg = this.list.length+'개 항목의 등락률을 업데이트 하시겠습니까?'
                // 특징주 시간구분 전체일때도 순서 필요해서 주석처리 나중에 searchAllTime 관련 지워야함
                // if(this.type.value != "STOCK_FEATURED_ALL"){
                    msg += '<br><br>등락률 업데이트 후에는 '
                    msg += '<br>※ 등락률순으로 재정렬됩니다.'
                    msg += '<br>※ 등락률에 따라 이슈요약 문구가 변경됩니다.'
                // }
                this.showAlert({
                    vc: this,
                    message: msg,
                }).then(() => {
                     this.$emit('event-updateRate', this.list, this.type)
                })
            }
        },
        changeNewsType() {
            if (this.selected.length == 0) {
                this.showMessage({
                    vc: this,
                    message: '특징주(장마감)로 등록할 항목을 선택해주세요.',
                })
            } else {
                this.$emit('event-change', this.selected)
                // 220905 meej 확인 메시지 이중으로 나옴 _ 주석처리
                // this.showAlert({
                //     vc: this,
                //     message:
                //         '선택한 항목을 특징주에서 이슈로 이동하시겠습니까?(해당 특징주는 삭제상태가 됩니다.)',
                // }).then(() => {
                    // this.$emit('event-change', this.selected)
                // })
            }
        },
        handleOrderList(list) {
            let orderList = []
            for (let i = 0; i < list.length; i++) {
                let item = list[i]
                let order = {
                    newsType: item.newsType1,
                    id: item.id,
                    index: '' + i,
                }
                orderList.push(order)
            }
            this.deleteOrderList = orderList // added

            this.showAlert({ vc: this, message: '순서를 변경하시겠습니까?' })
                .then(() => {
                    this.$emit('event-order-featured', orderList)
                    this.ordering = !this.ordering
                })
                .catch(() => {
                    this.ordering = !this.ordering
                })
            this.deleteOrderList = orderList // added
        },
        // select handler
        // 220712 meej 특징주 - 순서변경목록의 정렬과, 목록의 정렬을 다르게 처리함 ordering 여부전달
        getList(val) {
            let sortZ = false
            if(val!=null && val==1){
                sortZ = true
            }
            let condition = {
                date: this.dateSearch,
                all: this.allSearch,
                newsType: this.type.value,
                init: true,
                sortZ: sortZ, // ordering 여부전달
                // delete : this.containDel // 삭제 포함 여부 전달 // 221111 meej 전체목록에 삭제 포함으로 변경_주석처리
            }
            debug.log('getList condition = ', condition)
            this.$nextTick(function() {
                this.$emit('event-get-list', condition)
            })
        },
        // parent 호출
        clearCheck() {
            this.selected = []
            this.list.forEach((i) => {
                i.checked = false
            })
        },
        // 220905 meej 이슈 이동 추가
        moveIssue(){
            if (this.selected.length == 0) {
                this.showMessage({
                    vc: this,
                    message: '[Spot 입력 > 이슈]로 등록할 항목을 선택해주세요.',
                })
            } else {
                /* 220905 meej 이슈이동
                    1. SPOT입력의 특징주(장마감, 장중) 삭제상태로 남음
                    2. SPOT입력의 이슈에 새로 등록됨 
                */
                this.$emit('event-change-Issue', this.selected)
            }
        },
    },
    watch: {
        allCheck: function(val) {
            this.selected = []
            if (val) {
                const start =
                    this.pagination.rowsPerPage * (this.pagination.page - 1)
                // const last = Math.min(
                //     this.pagination.rowsPerPage * this.pagination.page,
                //     this.list.length
                // )
                /* 
                    220502 meej 특징주 요일 검색시 페이징삭제로 인해 전체체크 갯수 수정
                */
                const last = !this.ordering && this.allSearch ? 
                    Math.min(
                        this.pagination.rowsPerPage * this.pagination.page,
                        this.list.length
                    ) 
                    : this.list.length
                
                debug.log('start!!!', start, ', last = ', last)
                for (let i = start; i < last; i++) {
                    this.list[i].checked = val
                    this.selected.push(this.list[i])
                }
            } else {
                this.list.forEach((i) => {
                    i.checked = false
                })
            }
            this.$emit('event-check-row', this.selected)
        },
        allSearch: function() {
            debug.log('allSearch')
            // 221103 meej 전체 선택시 삭제 포함 여부 false로
            // 221111 meej 전체목록에 삭제 포함으로 변경_주석처리
            // this.containDel = false
            this.getList()
        },
        // 221103 meej 삭제 포함 여부 선택시
        // 221111 meej 전체목록에 삭제 포함으로 변경_주석처리
        // containDel: function() {
        //     debug.log('containDel')
        //     this.selected = []
        //     this.allCheck = false
        //     this.getList()
        // },
        dateSearch: function(val, old) {
            if (!this.allSearch && val !== old) {
                this.getList()
            }
        },
    },
}
</script>
