import api from '@/services/InfostockService'
import Debug  from '@/debug'
import CONSTANTS from '@/constants'
import { FormatDate } from '@/util'

const state = () => ({
    nextKey:null,
    list: [],
    code: null,
    item: {
        code: '',
        name: '',
        corpCls: '',
        part: '',
        listingDate: '',
        listing: false,
        ir: false,
        corpCode: '',
        corpName: '',
        content: '',
        c_item: {},
        c_items: [],
        t_items: []
    },
    themeStock: {
        themeCode: '',
        createTime: '',
        outline: '',
        isDelete: false,
        DELETE_DATE: '',
        CAUSE: '',
        history: []
    },
    history: [],
    tempCode: null, // 종목코드 임시저장 
    searchedStockName: '', // 종목명 검색값
})


const mutations = {

    SET_SEARCHED_STOCK_NAME(state, name){
        state.searchedStockName = name
    },

    SET_TEMP_CODE(state, code){
        state.tempCode = code
    },

    SET_LIST(state, list) {
        // 데이터 가공
        list.forEach((row, index) => {
            row.index = index
        })

        state.list = list
    },
    SET_DETAIL(state, item) {
        item.corpCls = CONSTANTS.CORPCLS_TYPE.find(v => v.value == item.corpCls)
        item.ir = item.ir == 0 ? false : true

        // TODO: 서버에 가공한 데이터로 받기
        item.c_items = item.c_items.reverse()
        item.c_item = item.c_items[0]
        item.part = {
            code: item.partCode,
            name: item.partName
        }
        let p = [],r = [],d = [], result = [];
        if(item.length !== 0){
            item.t_items.forEach((el) => {
                if(el.type == 'PUBLIC'){
                    p.push(el)
                }else if(el.type == 'DELETE'){
                    d.push(el)
                }else{
                    r.push(el)
                }
                }
            )
            result = p.concat(r,d)
        }
        item.t_items = result

        state.item = item
    },
    SET_CODE(state, code) {
        state.code = code
    },
    setHistory(state, { data }) {
        var arr = state.history
        arr.push(...data.items)

        // 데이터 가공
        arr.forEach((row, index) => {
            row.index = index
            row.expand = false
        })

        state.history = arr
        state.nextKey = data.nextKey
    },
    SET_THEME_STOCK(state, themeStock) {
        themeStock.createTime = FormatDate(themeStock.createTime)
        state.themeStock = themeStock
    },
    INIT_LIST(state) {
        state.list = []
    },
    INIT_HISTORY(state) {
        state.history = []
        state.nextKey = null
    },
    INIT_DETAIL(state) {
        state.item = {
            code: '',
            name: '',
            corpCls: '',
            part: '',
            listingDate: '',
            listing: false,
            ir: false,
            corpCode: '',
            corpName: '',
            content: '',
            c_item: {},
            c_items: [],
            t_items: []
        }
    },
    INIT_THEME_STOCK(state) {
        state.themeStock = {
            themeCode: '',
            createTime: '',
            outline: '',
            isDelete: false,
            DELETE_DATE: '',
            CAUSE: '',
            history: []
        }
    }
}

const actions = {
    // 종목 목록 조회
    // async GET_LIST({ commit }, params) {
    //     Debug.log('GET_LIST params', params)
    //     const { data } = await api.stockList(params)
    //     Debug.log('GET_LIST data', data)

    //     commit('SET_LIST', { data })
    // },
    GET_LIST(_, params) {
        Debug.log('GET_LIST params', params)

        return new Promise((resolve, reject) => {
            api.stockList(params)
                .then((response) => {
                    resolve(response.data)
                    Debug.log('GET_LIST data', response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 종목 조회
    // async GET_DETAIL({ commit }, params) {

    //     Debug.log('GET_DETAIL params', params)
    //     const { data } = await api.stockDetail(params)
    //     Debug.log('GET_DETAIL data', data)

    //     commit('SET_DETAIL', data)
    // },
    GET_DETAIL(_, params) {
        Debug.log('GET_DETAIL params', params)

        return new Promise((resolve, reject) => {
            api.stockDetail(params)
            .then((response) => {
                resolve(response.data)
                Debug.log('GET_DETAIL data', response.data)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    // 종목 작성 및 수정
    async WRITE_ITEM(_, params) {
        Debug.log('WRITE_ITEM params', params)
        const { data } = await api.stockWrite(params)
        Debug.log('WRITE_ITEM data', data)
    },
    // 종목 삭제
    async DELETE_ITEM(_, params) {
        Debug.log('DELETE_ITEM params', params)
        const { data } = await api.stockDelete(params)
        Debug.log('DELETE_ITEM data', data)
    },

    // 테마기업요약 순서변경
    async ORDER_ITEMS(_, params) {
        Debug.log('ORDER_ITEMS params', params)
        const { data } = await api.stockThemeOrder(params)
        Debug.log('ORDER_ITEMS data', data)
    },
    // 히스토리
    async GET_HISTORY({ commit, state }, params) {
        Debug.log('GET_HISTORY params', params)
        /* 
            220422 meej  if(state.nextKey !== null) 삭제, nextKey는 항상 받는다.
        */
        params.nextKey = state.nextKey
        const { data } = await api.stockHistory(params)
        /* 
            220422 meej 종목 히스토리 검색 : STOCK_ISSUE와 STOCK_FEATURED는 같은 이슈로 보여지게 한다.
        */
        data.items.forEach((el) =>{
            if(el.newsType1 == 'STOCK_FEATURED'){
                el.newsType1 = 'STOCK_ISSUE'
            }
        })
        Debug.log('GET_HISTORY data', data)

        commit('setHistory', {data})
    },
    // 테마기업요약 조회
    GET_THEME_STOCK(_, params) {
        Debug.log('GET_THEME_STOCK params', params)

        return new Promise((resolve, reject) => {
            api.themeStockDetail(params)
                .then((response) => {
                    resolve(response.data)
                    Debug.log('GET_THEME_STOCK data', response.data)
                })
                .catch((error) => {
                    reject(error)
                    Debug.log('error = ', error)
                })
        })
    },
    // 테마기업요약 수정
    async WRITE_THEME_STOCK(_, params) {
        Debug.log('WRITE_THEME_STOCK params', params)
        const { data } = await api.themeStockWrite(params)
        Debug.log('WRITE_THEME_STOCK data', data)
    }
}

export const stock = {
    namespaced: true,
    state,
    mutations,
    actions,
}
