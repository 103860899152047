import api from '../../../services/InfostockService'
import Debug  from '../../../debug'


const state = () => ({
    items: [],
    item: null,
    themeOutline:'',
    stockCount : {}, // 220630 meej 전체, 공개, 준비, 편출 종목수 추가 
    checkList: {
        stocks:[],
        leads: []
    },
    selectedDate : null,
})

const mutations = {
    setList(state, data) {
        Debug.log('SET_LIST data', data)
        // 20210930 shb 공개,준비,편출 정렬 
        let p = [],r = [],d = [], result = [];
        if(data.items.length !== 0){
            data.items.forEach((el, index, arr) => {
                /* 
                    220526 meej 종목명이 없는경우 리스트에 안보이게 처리
                 */
                if(el.stockName == null){
                    arr.splice(index, 1)
                }else{
                    if(el.type == 'PUBLIC'){
                        p.push(el)
                    }else if(el.type == 'DELETE'){
                        d.push(el)
                    }else{
                        r.push(el)
                    }
                }
            })
            result = p.concat(r,d)
        }
        state.items = result
        state.themeOutline = data.themeOutline
        // 220630 meej 전체, 공개, 준비, 편출 종목수 추가 
        state.stockCount = data.count
    },
    SET_ITEM(state, data) {
        state.item = data
    },
    SET_ITEMS(state, data) {
        state.items = data
    },
    SET_CHECKLIST(state, data) {
        
        state.checkList.stocks = data.stocks
        state.checkList.leads = data.leads
        Debug.log('stock', data.stocks)
            Debug.log('lead', data.leads)
    },

    /* 
        220523 meej stocklist의 date set
     */
    SET_SELETED_DATE(state, data){
        state.selectedDate = data
    },
     // 20210728 shb 추가
    // ADD_CHECKLIST(state,data){
    //     // console.log(data)
    //     const stock = data.stock
    //     const LEAD = data.LEAD
    //     // console.log(stock)
    //     if(stock !== undefined){
    //         state.checkList.stock.push(stock) 
    //     }else if(LEAD !== undefined){
    //         state.checkList.LEAD.push(LEAD) 
    //     }

    // },
    REMOVE_CHECKLIST_ITEM(state,data){
        const stockI = data.stock
        const LEADI = data.LEAD
        if(stockI !== undefined){
            state.checkList.stocks.splice(stockI,1)
        }else if(LEADI !== undefined){
            state.checkList.leads.splice(LEADI,1)
        }
        
    },
    INIT_CHECKLIST(state) {
        state.checkList.stocks = []
        state.checkList.leads = []
    },
    // ===================
    INIT_LIST(state) {
        state.themeOutline = ''
        state.items = []
        // 220630 meej 전체, 공개, 준비, 편출 종목수 추가 
        state.stockCount = {}
    },
}

const actions = {
    GET_THEMESTOCK_LIST(_, params) {
        return new Promise((resolve, reject) => {
            api.themeStock(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })

    },

    GET_THEMESTOCK_RATE_LIST(_, params) {
        return new Promise((resolve, reject) => {
            api.themeStock(params)
            .then((response) => {
                const { data } = response
                data.items.forEach((row) => {
                    row.stock = false
                    row.LEAD = false
                })
                resolve(data.items)
            })
            .catch((error) => {
                reject(error)
            })
        })

    },
    async ASYNC_THEMESTOCK_LIST({ commit,rootState }, params) {
        Debug.log('ASYNC_THEMESTOCK_LIST params', params)
        const { data } = await api.themeStock(params)
        Debug.log('ASYNC_THEMESTOCK_LIST data', data)
        
        // 220502 meej 테마종목 - 종목 오름차순 정렬 오류 수정
        // data.items.sort(function(a, b) { // 오름차순
        //     // return a.theme_idx < b.theme_idx ? -1 : a.theme_idx > b.theme_idx ? 1 : 0;
        //     return a.theme_idx - b.theme_idx 
        // });
        // 221102 meej 테마종목 - 신규 추가 된 종목은 무조건 마지막으로 
        data.items.sort(function(a, b) { // 오름차순
            if(a.theme_idx !== '' && b.theme_idx === '') return -1;
            return a.theme_idx - b.theme_idx 
        });
        data.items.forEach((row, index) => {
            row.index = index
            row.label = rootState.resource.themeStockType.find(v => v.value0 === row.type).name0
            row.CHECK = false
        })
        commit('setList', data )
    },
    GET_THEMESTOCK_DETAIL(_, params) {
        return new Promise((resolve, reject) => {
            api.themeStockDetail(params)
            .then((response) => {
                const { data } = response
                resolve(data)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    async WRITE_ITEMS(_, params) {
        Debug.log('WRITE_ITEMS params', params)
        await api.themeStockWrite(params)

    },
    ORDER(_, params) {
        return new Promise((resolve, reject) => {
            api.themeStockOrder(params)
            .then((response) => {
                const { data } = response
                resolve(data)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
}

const getters = {
    /* 
    getCheckList(state){
        return state.checkList.stock.filter(item => item.checked)
    }
    */
}

export const theme_stock = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
