<template>
    <div>
        <div class="title q-py-md">
            <h3 class="text-center"> {{ title }} </h3>
        </div>
        <section>
            <article class="q-pa-md q-mx-auto" style="max-width: 400px">
                <q-form class="q-gutter-md login" v-show="!logined">
                    <q-input autofocus v-model="uid" type="text" filled square placeholder="아이디"/>
                    <q-input v-on:keyup.enter="submit" v-model="password" type="password" filled square placeholder="비밀번호"/>
                    <div class="text-negative">
                        {{ validation.firstError('uid') }}
                        <p v-if="!validation.firstError('uid')"> {{ validation.firstError('password') }} </p>
                    </div>
                    <div class="row justify-end">
                        <q-btn @click.prevent="submit" label="로그인" color="dark" class="full-width q-mb-sm" unelevated/>
                        <q-btn @click.prevent="signup" label="가입하기" color="dark" outline class="full-width" />
                    </div>
                </q-form>
                <!-- <Login2Fa v-show="logined" v-bind:uid="uid"/> -->
            </article>
        </section>
    </div>
</template>

<script>
import CONSTANTS from '@/constants'
import { mapActions } from 'vuex'
import SimpleVueValidation from 'simple-vue-validator'
import Debug from '@/debug'

const Validator = SimpleVueValidation.Validator

export default {
    name: 'Login',
    data () {
        return {
            title: CONSTANTS.MENU.Login,
            uid : '',
            password : '',
            logined : false
        }
    },
    validators: {
        uid: function(value) {
            return Validator.value(value).required('ID를 입력하세요.');
        },
        password: function(value) {
            return Validator.value(value).required('비밀번호를 입력하세요.').minLength(5, '비밀번호 5자리 이상 입력하세요.')
        }
    },
    methods: {
        ...mapActions({
            login: 'session/login_action',
            logout: 'session/logout_action',
            replace: 'history/replace',
            push: 'history/push',
            showError: 'alert/showError',
        }),
        submit: function () {
            const vc = this
            this.$validate()
            .then(function(success) {
                if (success) {
                    console.log(this)
                    vc.doLogin()
                } else {
                    Debug.log('validation fail = ', this.validation.errors)
                    var error = this.validation.errors[0].message
                    this.showError({ vc:this, error:error })
                }
            })
            .catch((error) => {
                Debug.log('validation error = ', error)
                this.showError({ vc:this, error:error })
            })
        },
        doLogin: function () {
            let uid = this.uid
            let password = btoa(this.$CryptoJS.SHA256(this.password).toString())
            this.login({uid, password})
            .then((response) => {
                Debug.log('doLogin response '+ response)
                // if(response === CONSTANTS.REQUIRE_2FA) {
                //     this.logined = true
                // } else {
                    
                    this.replace('main.htm')
                //}
            })
            .catch((error) => {
                Debug.log('error = ', error)
                this.showError({ vc:this, error:error })
            })
        },
        signup: function () {
            this.push('signup.htm')
        }
    }
}
</script>
<style>
</style>