<template>
    <article class="q-pa-md">
        <q-scroll-area visible class="i-content-wrap">
            <!-- 등록 화면 -->
            <q-card flat bordered>
                <q-card-section>
                    <div class="row">
                        <div class="col-10 col-md-10 q-pa-xs">
                            <div
                                v-if="isRegisterPage"
                                class="flex justify-between"
                            >
                                <i-stock-search
                                    style="width:20%"
                                    @get="getStock"
                                />
                                <i-input
                                    style="width:40%"
                                    v-model="themeStock.stockCode"
                                    inputLabel="종목코드"
                                    disable
                                />
                                <i-input
                                    style="width:40%"
                                    v-model="themeStock.stockName"
                                    inputLabel="종목명"
                                    disable
                                />
                            </div>
                            <i-field
                                v-else
                                class="full-width"
                                inputLabel="종목"
                                :text="displayThemeStock()"
                            />
                        </div>
                    </div>
                    <div v-show="!isRegisterPage" class="row">
                        <div class="col-3 q-pa-xs">
                            <i-field
                                v-if="themeStock"
                                inputLabel="작성일자"
                                :text="themeStock.createTime"
                            />
                        </div>
                        <div class="col-3 q-pa-xs">
                            <i-field
                                v-if="themeStock"
                                inputLabel="작성자"
                                :text="themeStock.writer"
                            />
                        </div>
                        <div class="col-3 q-pa-xs">
                            <i-field
                                v-if="themeStock"
                                inputLabel="수정일자"
                                :text="themeStock.lastUpdateTime"
                            />
                        </div>
                    </div>
                    <div class="q-pa-xs">
                        <i-input
                            v-if="themeStock"
                            v-model="themeStock.outline"
                            type="textarea"
                            inputLabel="테마기업요약"
                            :rows="10"
                            @focus="goEditPage"
                        />
                    </div>
                    <div class="row">
                        <div class="col-6 q-pa-xs">
                            <i-select
                                v-if="themeStock"
                                inputLabel="상태"
                                v-model="themeStock.type"
                                :options="themeStockType"
                                option-label="name0"
                                option-value="value0"
                                @input="goEditPage"
                            />
                        </div>
                        <div
                            v-if="showDelete(themeStock)"
                            class="col-12 col-md-6"
                        >
                            <i-datetime
                                v-model="themeStock.DELETE_DATE"
                                date
                                dateLabel="편출일자"
                            />
                        </div>
                    </div>

                    <div v-if="!isRegisterPage">
                        <div v-if="showDelete(themeStock)" class="q-pa-xs">
                            <i-input
                                v-if="themeStock"
                                inputLabel="편출 사유"
                                v-model="themeStock.cause"
                                type="textarea"
                                :rows="5"
                                @focus="goEditPage()"
                            />
                        </div>
                        <div class="q-pa-xs">
                            <i-input
                                v-if="themeStock"
                                inputLabel="메모"
                                class="col-12"
                                v-model="themeStock.memo"
                                type="textarea"
                                :rows="5"
                                @focus="goEditPage()"
                            />
                        </div>
                        <div v-if="themeStock">
                            <div
                                v-for="(t_item, i) in themeStock.items"
                                :key="'t_item-' + i"
                                class="q-my-sm"
                            >
                                <q-field filled>
                                    <template v-slot:before>
                                        <label class="i-label">
                                            <span v-show="i == 0"
                                                >수정내역</span
                                            >
                                        </label>
                                    </template>
                                    <template v-slot:control>
                                        <div
                                            class="self-center full-width no-outline"
                                            tabindex="0"
                                        >
                                            <p>
                                                {{ t_item.title }}
                                            </p>
                                            <div v-html="t_item.content" />
                                        </div>
                                    </template>
                                </q-field>
                            </div>
                        </div>
                    </div>
                </q-card-section>
            </q-card>
        </q-scroll-area>
        <!-- 버튼 -->
        <div>
            <i-btn
                v-show="isRegisterPage"
                class="full-width"
                label="등록"
                @click="write()"
            />
            <i-btn
                v-show="isEditPage"
                class="full-width"
                label="수정"
                @click="edit()"
            />
        </div>
    </article>
</template>
<script>
import Debug from '@/debug'

import Button from '@/components/common/Button'
import Input from '@/components/common/Input'
import Select from '@/components/common/Select'
import Field from '@/components/common/Field'
import DateTime from '@/components/common/DateTime'
import StockSearch from '@/components/common/StockSearch'
import { FormatDate, FormatDateTime, isEmpty } from '@/util'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Content',
    components: {
        'i-field': Field,
        'i-datetime': DateTime,
        'i-btn': Button,
        'i-input': Input,
        'i-select': Select,
        'i-stock-search': StockSearch,
    },
    props: {
        theme: Object,
    },
    data() {
        return {
            themeStock: null,
            //            cardList: [],
            detailThemeStock: null,
        }
    },
    created() {
        Debug.log('Content Crated!!!!!!!!!!!!!')
        if (!this.isRegisterPage) {
            Debug.log('Content Crated!!!!!!!!!!!!!', this.selectedThemeStock)
            if (
                this.selectedThemeStock === null ||
                this.selectedThemeStock === undefined
            ) {
                this.goListPage()
            } else {
                this.getThemeStockDetail()
            }
        } else {
            this.requestResource('Stock')
            this.themeStock = this.createThemeStock()
            //this.cardList.push(this.createThemeStock())
        }
        // this.$parent.$on('sendResource', this.addResource)
    },
    computed: {
        ...mapState({
            selectedThemeStock: (state) => state.theme_stock.item,
            themeStockType: (state) => state.resource.themeStockType,
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
        }),
        ...mapGetters({
            isEditPage: 'page/isEditPage',
            isRegisterPage: 'page/isRegisterPage',
            isDetailPage: 'page/isDetailPage',
        }),
    },
    methods: {
        ...mapMutations({
            SET_ITEM: 'theme_stock/SET_ITEM',
            setPageStatus: 'page/setPageStatus',
        }),
        ...mapMutations('resource', ['REQUEST_RESOURCE']),
        ...mapActions({
            WRITE_ITEMS: 'theme_stock/WRITE_ITEMS',
            GET_THEMESTOCK_DETAIL: 'theme_stock/GET_THEMESTOCK_DETAIL',
        }),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),
        // 카드 삭제하기
        deleteCard(index) {
            this.cardList.splice(index, 1)
            if (this.cardList.length === 0) {
                this.cardList.push(this.createThemeStock())
            }
        },
        getStock(stock) {
            Debug.log('getStock = ', stock)
            this.themeStock.stockCode = stock.code
            this.themeStock.stockName = stock.name
        },
        displayStock(stock) {
            return stock === null ? '' : stock.name + '(' + stock.code + ')'
        },
        displayThemeStock() {
            this.goEditPage()
            Debug.log('displayThemeStock = ', this.detailThemeStock)

            if (this.detailThemeStock) {
                var stockName = this.detailThemeStock.stockName
                var stockCode = this.detailThemeStock.stockCode
                return isEmpty(stockName)
                    ? stockCode
                    : `${stockName}(${stockCode})`
            } else {
                return ''
            }
        },
        createThemeStock() {
            let themeStock = {
                theme: this.theme,
                stockCode: null,
                stockName: null,
                type: this.themeStockType[1],
                outline: '',
            }
            return themeStock
        },
        showDelete(item) {
            if (item != null) {
                Debug.log('showDelete item.type = ', item.type)
                return (
                    item.type !== null &&
                    item.type !== undefined &&
                    item.type.value0 === 'DELETE'
                )
            } else {
                return false
            }
        },
        getThemeStockDetail() {
            const themeStock = this.selectedThemeStock
            const params = {
                themeCode: themeStock.themeCode,
                stockCode: themeStock.stockCode,
            }
            this.getDetail(params)
        },
        getDetail(params) {
            this.GET_THEMESTOCK_DETAIL(params)
                .then((data) => {
                    this.detailThemeStock = data
                    data.createTime = FormatDate(data.createTime)
                    data.lastUpdateTime = FormatDate(data.lastUpdateTime)
                    data.type = this.themeStockType.find(
                        (v) => v.value0 === data.type
                    )
                    data.items.forEach(function(item) {
                        item.content.replace('/(?:\r\n|\r|\n)/g', '<br />')
                    })
                    if (data.memo === undefined) {
                        data.memo = ''
                    }
                    if (data.cause === undefined) {
                        data.cause = ''
                    }
                    this.themeStock = data
                    //this.cardList.push(data)
                })
                .catch((error) => {
                    this.showError({ vc: this, error: error })
                })
        },
        edit() {
            let params = {
                themeCode: this.detailThemeStock.themeCode,
                stockCode: this.detailThemeStock.stockCode,
            }
            const data = this.themeStock
            params.outline = data.outline
            params.type = data.type.value0
            params.memo = data.memo
            params.deleteDate = data.DELETE_DATE
            params.cause = data.cause
            this.doWrite([params], '수정이 ')
        },
        write() {
            const mesg = '종목코드, 타입을 확인해주세요!'
            let list = []
            //for(let i = 0; i < this.cardList.length; i++) {
            let params = { themeCode: this.theme.code }
            let themeStock = this.themeStock //this.cardList[i]
            if (themeStock.stock === null || themeStock.type === null) {
                this.showMessage({ vc: this, message: mesg })
                return
            }

            params.stockCode = themeStock.stockCode
            params.stockName = themeStock.stockName
            params.outline = themeStock.outline
            params.type = themeStock.type.value0

            Debug.log('write params =', params)
            list.push(params)
            //}
            if (list.length > 0) {
                this.doWrite(list, '등록이 ')
            } else {
                this.showError({ vc: this, error: mesg })
            }
        },
        doWrite(paramList, title) {
            const mesg = title + '완료되었습니다.'
            let succ = 0,
                fail = 0,
                total = paramList.length
            console.log(mesg, fail, total, succ)
            for (let i = 0; i < paramList.length; i++) {
                let params = paramList[i]

                this.WRITE_ITEMS(params)
                    .then(() => {
                        succ++
                        if (succ + fail === total) {
                            this.showMessage({ vc: this, message: mesg })
                            this.goListPage()
                            this.$emit('writeComplete')
                            this.getDetail(params)
                        }
                    })
                    .catch((err) => {
                        fail++
                        if (succ + fail === total) {
                            /* 
                                220523 meej 테마종목 오류메시지 오류 수정
                             */
                            // this.showMessage({ vc: this, message: mesg })
                            this.showError({ vc: this, error: err })
                            this.goListPage()
                        }
                    })
            }
        },
        // 목록화면으로 이동하기
        goListPage() {
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        // 수정화면으로 이동하기
        goEditPage() {
            if (this.isDetailPage) {
                this.setPageStatus(this.PAGE_STATUS.EDIT)
            }
        },
        FormatDateTime(datetime) {
            return FormatDateTime(datetime)
        },
        /**
        resource handler
        */
        requestResource(menu, code) {
            let req = {
                menu: menu,
            }
            if (code !== undefined) {
                req.themeCode = code
            }
            this.$nextTick(function() {
                this.REQUEST_RESOURCE(req)
            })
        },
        // addResource(res) {
        //     Debug.log('addResource res = ', res)
        //     if(this.isRegisterPage) {
        //         if(this.cardList.length === 1 && this.cardList[0].stock === null) {
        //             //아무것도 없을때 삭제하고 무조건 추가
        //             this.cardList.splice(0,1)
        //         }
        //         if(res.menu === 'Stock') {
        //             const count = res.items.length
        //             for(let i = 0; i < count; i++) {
        //                 let newCard = this.createThemeStock()
        //                 newCard.stock = res.items[i]
        //                 this.cardList.push(newCard)
        //             }
        //         }
        //     }
        // },
    },
    watch: {
        pageStatus(status, old) {
            if (status !== old) {
                if (status === this.PAGE_STATUS.REGISTER) {
                    //this.cardList = []
                    //this.cardList.push(this.createThemeStock())
                    this.themeStock = this.createThemeStock()
                }
            }
        },
        selectedThemeStock(item, old) {
            if (item !== old) {
                //this.cardList = []
                this.getThemeStockDetail()
            }
        },
    },
}
</script>
