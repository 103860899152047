<template>
    <article>
        <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : list.length == 0 }"
            :columns="columns" :data="list" row-key="RNUM" :rows-per-page-options="[0]" style="height:auto;"
            hide-pagination no-data-label="검색조건을 입력해 주세요.">
            <template v-slot:no-data="{ icon, message }"><div class="full-width row flex-center q-gutter-sm"><q-icon size="2em" :name="icon" /> <span>{{message}}</span></div></template>
        </q-table>
    </article>
</template>
<script>
import CONSTANTS from '@/constants'
import { numberFormat } from '@/util'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    data () {
        return {
            ifrsOptions : CONSTANTS.COM_IFRS2,
            columns: []
        }
    },
    computed : {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId
        }),
        ...mapState('manage', ['list'])
    },
    created () {
        this.INIT_DATA();
    },
    methods: {
        ...mapMutations('manage', ['SET_DATA', 'INIT_DATA']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        //리스트
        getList() {
            if (this.$parent.stockCode == '') {
                this.showMessage({ vc:this, message:'종목을 선택하세요.' });
                return;
            }
            if (this.$parent.closingDate.value == '') {
                this.showMessage({ vc:this, message:'년도를 선택하세요.' });
                return;
            }
            if (this.$parent.ifrs.value == '') {
                this.showMessage({ vc:this, message:'연겨/별도를 선택하세요.' });
                return;
            }
            let param = {
                financialGb: '1',
                stockCode: this.$parent.stockCode.code,
                ifrs: this.$parent.ifrs.value,
                closingDate: this.$parent.closingDate.value,
                CALL_API_URL: '/financialHistory/list'
            }
            this.GET_CALL(param).then((data) => {
                this.columns = [
                        { headerStyle:'text-align:center;', name: 'stockCode', label: '종목', align: 'center', field: (row) => row.stockName + " (" + row.stockCode + ")" },
                        { headerStyle:'text-align:center;', name: 'closingDate',  label: '연도', align: 'center', field: (row) => row.closingDate == null ? '':row.closingDate.substring(0, 4) },
                        { headerStyle:'text-align:center;', name: 'ifrs', label: '연결/별도', align: 'center', field: (row) => this.ifrsOptions[row.ifrs] }
                    ]
                var iCnt = 0;
                var ds_list = [];
                var ds_obj = {};
                data.items.forEach(row => {
                    if(iCnt == 0){
                        ds_obj['stockCode'] = row.stockCode;
                        ds_obj['stockName'] = row.stockName;
                        ds_obj['closingDate'] = row.closingDate;
                        ds_obj['ifrs'] = row.ifrs;
                    }
                    var sId = 'financialValue'+ iCnt;
                    ds_obj[sId] = numberFormat(row.financialValue);
                    this.columns.push({ headerStyle:'text-align:center;', name:sId, label:row.fieldName, align:'right', field:sId });
                    iCnt++;
                });
                if(data.items.length != 0){
                    ds_list.push(ds_obj);
                }
                data.items = ds_list;
                this.SET_DATA(data);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        }
    }
}

</script>
