<template>
    <section class="row q-pa-lg">
        <div class="col-12 col-sm-8" style="width:100%;text-align:left;">
            <div class="flex">
                <div class="q-pa-xs" style="width:50%"><i-input v-model="keyword" ref="keyword"/></div>
                <div class="q-pa-xs"><i-btn icon="search" @click="getList()"/></div>
            </div>
        </div>
        <i-list class="col-6 q-pa-md" ref="coprtnOxprList"/>
        <i-content class="col-6 q-pa-md" ref="coprtnOxprContent"/>
    </section>
</template>
<script>
import Button from '@/components/common/Button';
import Input from '@/components/common/Input';
import List from "@/components/trnsmis/coprtnOxpr/List.vue";
import Content from "@/components/trnsmis/coprtnOxpr/Content.vue";

export default {
    name: "coprtnOxpr",
    components: {
        "i-btn" :  Button,
        "i-input" :  Input,
        "i-list": List,
        "i-content": Content
    },
    data() {
        return {
            keyword:''
        }
    },
    methods: {
        getList() {
            this.$refs.coprtnOxprList.getList();
        },
        getView(item) {
            this.$refs.coprtnOxprList.getView(null, item);
        }
    }
};

</script>
