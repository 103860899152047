<template>
    <article>
        <q-scroll-area visible class="i-content-wrap">
            <q-card flat bordered>
                <q-card-section>
                    <i-input v-model="content" type="textarea" :label="macroType.name" :rows="40"/>
                </q-card-section>
            </q-card>
        </q-scroll-area>
        <!-- 하단 버튼 -->
        <div class="q-my-md">
            <i-btn class="full-width" label="등록" @click="write()" />
        </div>
    </article>
</template>
<script>
import Debug from '@/debug'

import Button from '@/components/common/Button'
import Input from '@/components/common/Input'

import { mapActions } from 'vuex'

export default {
    name: 'Content',
    components: {
        'i-btn':  Button,
        'i-input': Input,
    },
    props: {
        macroType: {
            type: Object,
            default: null
        },
    },
    data () {
        return {
            content: ''
        }
    },
    created () {
        this.initItem()
    },
    computed : {
    },
    methods: {
        ...mapActions( 'alert', ['showError', 'showMessage']),

        // 입력칸 초기화
        initItem() {
            Debug.log('initItem')
            this.content = ''
        },

        // 등록하기
        write() {
            const params ={
                service: this.macroType.value,
                content: this.content
            }
            this.$emit('click-event', params)
        }
    },
    watch: {
        macroType (val, old) {
            if(val != old) {
                this.initItem()
            }
        },
    }
}
</script>