<template>
    <section class="row col-12 ">
        <div class="row col-12 q-pa-xs ">
            <div class="row col-6 ">
                <i-select
                    class="col-3 q-ml-xs"
                    v-model="tradingMarket"
                    :options="tradingMarketOptions"
                    label="거래시장"
                    @input="selectTradingMarket()"
                />

                <div class="row col-6 q-ml-xs">
                    <q-select
                        v-model="flashType"
                        :options="flashOptions"
                        option-label="name"
                        option-value="value"
                        label="속보타입"
                        @filter="filterFlash"
                        dense
                        outlined
                        style="width:80%"
                    />
                </div>
                <i-btn
                    label="속보"
                    class="row col-2 q-ml-xs"
                    @click="makeFlash()"
                    :disable="flashType === null"
                />
                <div class="flex justify-end"></div>
            </div>
        </div>
        <div class="row col-12">
            <!-- 220624 meej 표 전체보기 기능 추가 -->
            <article :class="[listWide? 'col-12':'col-6']">
                <!-- <i-list
                    class="full-width"
                    :columns="columns"
                    :list="list"
                    :nextKey="nextKey"
                    :typeOption="newsTypeOption"
                    :orderColumns="orderColumns"
                    :orderButton="true"
                    :moveButton="true"
                    @event-click-row="handleGetItem"
                    @event-get-list="handleGetList"
                    @event-order-featured="doOrder"
                    @event-confirm="handleConfirm"
                    @event-delete="handleDelete"
                    @event-change="handleChange"
                    @event-list-wide = "handleListWide"
                /> -->
                <!-- 220511 meej 등락률 업데이트 기능 추가 :updateRate="true"  -->
                <i-list
                    class="full-width q-pa-xs"
                    :columns="columns"
                    :list="list"
                    :nextKey="nextKey"
                    :typeOption="newsTypeOption"
                    :orderColumns="orderColumns"
                    :orderButton="true"
                    :moveButton="true"
                    :updateRate="true" 
                    :rateUpdateTime = "rateUpdateTime"
                    @event-click-row="handleGetItem"
                    @event-get-list="handleGetList"
                    @event-order-featured="doOrder"
                    @event-confirm="handleConfirm"
                    @event-delete="handleDelete"
                    @event-change="handleChange"
                    @event-change-Issue="handleChangeIssue"
                    @event-updateRate="handleUpdateRate"
                    @event-list-wide = "handleListWide"
                />
            </article>
            <!-- 220624 meej 표 전체보기 기능 추가 -->
            <div v-show="!listWide" class="col-6">
                <i-content
                    class="q-pa-xs"
                    @event-update-list="handleUpdateList"
                />
            </div>
        </div>
    </section>
</template>
<script>
import Debug from '@/debug'
import CONSTANTS from '@/constants'
import { FormatDate, objToString, isEmpty } from '@/util'

import List from '@/components/featured/List.vue'
import Content from '@/components/featured/stock/Content.vue'
import Select from '@/components/common/Select'
import Button from '@/components/common/Button'

import { mapState, mapMutations, mapActions } from 'vuex'
export default {
    name: 'StockFeatured',
    components: {
        'i-list': List,
        'i-content': Content,
        'i-select': Select,
        'i-btn': Button,
    },
    data() {
        return {
            columns: [
                {
                    name: 'createTime',
                    label: '입력일자',
                    align: 'left',
                    field: (row) =>
                        FormatDate(row.createTime) +
                        '\n' +
                        (row.newsType1 === 'STOCK_AM_FEATURED'
                            ? '오전장'
                            : '오후장'),
                    sortable: true,
                },
                {
                    name: 'tradingMarket',
                    label: '거래소',
                    align: 'left',
                    field: (row) => {
                        return row.tradingMarketName
                    },
                    sortable: true,
                },
                {
                    name: 'writer',
                    label: '작성/수정',
                    align: 'left',
                    field: (row) => {
                        if (row.crud === 'U')
                            return row.CREATE_WRITER + '/' + row.writer
                        else return row.writer
                    },
                },
                {
                    name: 'stock',
                    label: '그룹명/종목',
                    align: 'left',
                    // 220712 meej 등락률 업데이트 시 음수<>양수로 등락률 반전될경우 배경색(#FFE69A) 설정
                    // 221110 meej 너무 긴 경우 줄바꿈 추가
                    style: (row) => isEmpty(row.RATE_CHANGE) ? "max-width:300px;word-break:break-all;white-space: normal" : "max-width:300px;word-break:break-all;white-space: normal;background:#FFE69A",
                    // 220901 meej 종목명, 종목 코드 사이의 띄어쓰기 제거
                    field: (row) =>
                        isEmpty(row.GROUP_NAME)
                            ? `${row.stockName}(${row.stockCode})`
                            : row.GROUP_NAME,
                    sortable: true,
                },
                {
                    name: 'changeRate',
                    label: '등락률',
                    align: 'left',
                    field: (row) =>
                        isEmpty(row.changeRate)
                            ? '-'
                            : row.changeRate === 0
                            ? '0.00%'
                            : Number(row.changeRate / 100).toFixed(2) + '%',
                    sortable: true,
                },
            ],
            tradingMarket: null,
            tradingMarketOptions: [
                { label: '전체', value: null },
                { label: '코스피', value: 'Y', flash: 'KOSPI' },
                { label: '코스닥', value: 'K', flash: 'KOSDAQ' },
            ],
            orderColumns: [
                {
                    name: 'stock',
                    label: '종목',
                    align: 'left',
                    field: (row) =>
                        isEmpty(row.GROUP_NAME)
                            ? `${row.stockName} ( ${row.stockCode} )`
                            : row.GROUP_NAME,
                },
                {
                    name: 'tradingMarket',
                    label: '거래소',
                    align: 'left',
                    field: (row) => {
                        return row.tradingMarketName
                    },
                },
                {
                    name: 'changeRate',
                    label: '등락률',
                    align: 'left',
                    field: (row) =>
                        isEmpty(row.changeRate)
                            ? '-'
                            : row.changeRate === 0
                            ? '0.00%'
                            : Number(row.changeRate / 100).toFixed(2) + '%',
                },
                {
                    name: 'title',
                    label: '요약',
                    align: 'left',
                    field: (row) => row.title,
                },
            ],
            newsTypeOption: CONSTANTS.STOCK_FEATURED_TIME_TYPE,
            condition: {},
            flashType: null,
            flashOptions: [],
            rateUpdateTime : null, // 220624 meej 등락률 업데이트 시간
            listWide : false, // 220624 meej 표 전체보기
            list:[] // 220714 meej 스크롤 이동 문제로 인해 store -> data의 list로 변경
        }
    },
    created() {
        this.tradingMarket = this.tradingMarketOptions[0]
        this.setPageStatus(this.PAGE_STATUS.LIST)
        //전체 조회
        this.condition.newsType = this.newsTypeOption[0]
        //당일
        this.condition.today = true
        Debug.log('FeturedStockFlashType = ', this.FeturedStockFlashType)
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
        }),
        ...mapState('stock_news', ['nextKey']),
        // 220714 meej 스크롤 이동 문제로 인해 data의 list로 변경
        // ...mapState('stock_news', ['list', 'nextKey']),
        flashStatus: (state) => state.flash.status,
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('stock_news', [
            'SET_DETAIL',
            'INIT_LIST',
            // 'SET_SORTED_LIST', // 220714 meej 스크롤 이동 문제로 인해 data의 list로 변경
            'SET_NEXTKEY',
        ]),
        ...mapMutations('flash', ['SET_PREVIEW']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showConfirm']),
        ...mapActions('flash', ['WRITE_FLASH']),
        ...mapActions('stock_news', [
            'GET_FLASH_NEWSTYPE',
            'GET_DATE_LIST',
            'GET_LIST',
            'GET_FEATURED_DETAIL',
            'DELETE_ITEMS',
            'ORDER_ITEMS',
            'CHANGE_NEWSTYPE_ITEMS',
            'CONFIRM_FEATURED',
            'REGIST_ISSUE',
            'UPDATE_RATE', // 220511 meej 등락률 업데이트 기능 추가
            'CHANGE_ISSUE',
        ]),
        makeFlash() {
            let params = {}
            params.newsType = this.flashType.value
            params.title = this.flashType.label
            this.WRITE_FLASH(params)
                .then((res) => {
                    Debug.log('data = ', res)
                    this.showPreview()
                })
                .catch((err) => {
                    Debug.log('error = ', err)
                    this.showError({ vc: this, error: err })
                })
        },
        showPreview() {
            this.windowRef = window.open(
                `/preview/popup.htm`,
                '시황속보 미리보기',
                'width=620,height=820'
            )
            if(this.windowRef) {
                this.windowRef.addEventListener('beforeunload', this.closePreview)
            }
        },
        closePreview() {
            this.windowRef = null
            if (this.flashStatus === 1) {
                this.showMessage({ vc: this, message: '전송되었습니다.' })
            }
        },
        goListPage() {
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        goDetailPage() {
            this.setPageStatus(this.PAGE_STATUS.DETAIL)
        },
        selectTradingMarket() {
            Debug.log('selectTradingMarket ', this.tradingMarket)
            // if(this.condition.newsType === CONSTANTS.STOCK_FEATURED_TIME_TYPE[0].value) {
            //     this.showMessage({vc:this, message:'오전/오후를 선택해주세요.'})
            //     return
            // }
            this.INIT_LIST()
            this.getList()
        },
        getList() {
            let params = {
                newsType: this.condition.newsType,
                /* 220623 meej 페이징 오류 수정 nextKey 추가 */
                nextKey : this.nextKey
            }
            if (this.tradingMarket.value !== null) {
                let options = {
                    TRADING_MARKET0: this.tradingMarket.value,
                }
                params.options = objToString(options)
            }
            if (this.condition.all) {
                params.delete = true
                this.GET_LIST(params).then((res) => {
                    this.sortingItems(res.items)
                    this.SET_NEXTKEY(res.nextKey)
                })
            } else {
                /* 220624 meej 특징주 날짜 조회시 date -> showDate로 변경 */
                params.showDate = this.condition.date
                params.delete = false
                this.GET_DATE_LIST(params).then((res) => {
                    this.sortingItems(res.items)
                })
            }
        },
        sortingItems(items) {
            if (items !== null) {
                /* 220624 meej 등락률 업데이트 일자, 시간 가져옴 */
                let flag = false
                let rateUpdateTime = null
                for(let i=0;i<items.length;i++){
                    if(items[i].RATE_UPDATE0 !== undefined && items[i].RATE_UPDATE0 !== null){
                        flag = true
                        // 제일 먼저 업데이트된 날짜로 넣음
                        if(rateUpdateTime == null || Number(rateUpdateTime) > Number(items[i].RATE_UPDATE0)){
                            rateUpdateTime = items[i].RATE_UPDATE0
                        }
                    }else {
                        flag = false
                        break
                    }
                }
                if(flag){
                    rateUpdateTime = rateUpdateTime.substring(0,4)+"-"+rateUpdateTime.substring(4,6)+"-"+rateUpdateTime.substring(6,8)+" "
                        +rateUpdateTime.substring(8,10)+":"+rateUpdateTime.substring(10,12)+":"+rateUpdateTime.substring(12,14)
                }else{
                    rateUpdateTime = null
                }
                this.rateUpdateTime = rateUpdateTime
                /* 
                    220506 meej 시간구분이 전체일 경우 오전오후 상관없이 등락률 내림차순 정렬
                    220624 meej 등락률 업데이트 될때 시간구분 상관없이 Z.INDEX 입력됨 -> Z.INDEX 정렬로 수정
                                전체 체크되어있을경우, lastUpdateTime순으로 나오게 처리
                */
                if(!this.condition.all){
                    // if(this.condition.newsType == "STOCK_FEATURED_ALL"){ //전체
                    //     items.sort(function(a, b) {
                    //         return b.changeRate - a.changeRate
                    //     })
                    // }else{
                        // items.sort(function(a, b) {
                        //     //코스피 코스닥으로 sorting 20210805 - 피드백 요청
                        //     // 순서 변경했으면 그거 위주로
                        //     Debug.log(' zindex = ', a.ZINDEX, a.ZINDEX)
                        //     if (a.ZINDEX !== undefined || b.ZINDEX !== undefined) {
                        //         return a.ZINDEX - b.ZINDEX
                        //     } else {
                        //         const aDate = a.createTime.substr(0, 8)
                        //         const bDate = b.createTime.substr(0, 8)
                        //         if (aDate === bDate) {
                        //             let i =
                        //                 a.TRADING_MARKET0 > b.TRADING_MARKET0
                        //                     ? -1
                        //                     : a.TRADING_MARKET0 < b.TRADING_MARKET0
                        //                     ? 1
                        //                     : 0
                                    // if (i === 0) {
                                    //     return a.changeRate > b.changeRate
                                    //         ? -1
                                    //         : a.changeRate < b.changeRate
                                    //         ? 1
                                    //         : 0
                                    // }
                        //             return i
                        //         } else {
                        //             return aDate < bDate ? 1 : -1
                        //         }
                        //     }
                        // })
                    // }
                    
                    // 220712 meej 특징주 정렬 수정
                    // 1. 초기목록창에서는 저장된 순서와 상관없이 거래소, 등락률 순으로 정렬
                    // 2. 순서변경창에서는 저장된 순서로 정렬
                    if(this.condition.sortZ){
                        this.sortingByZINDEX(items)
                    }else{
                        this.sortingByRate(items)
                    }
                }
                // 220714 meej 스크롤 이동 문제로 인해 data의 list로 변경, 페이징 오류 수정
                // this.SET_SORTED_LIST(items)
                if(this.nextKey != null){
                    var arr = this.list
                    arr.push(...items)
                    items.forEach((row, index) => {
                        row.index = index
                        row.checked = false
                    })
                    this.list = arr
                }else{
                    items.forEach((row, index) => {
                        row.index = index
                        row.checked = false
                    })
                    this.list = items
                }
            }
        },
        // 저장된 순서로 정렬
        sortingByZINDEX(items){
            items.sort(function(a, b) {
                Debug.log(' zindex = ', a.ZINDEX, a.ZINDEX)
                if (a.ZINDEX !== undefined || b.ZINDEX !== undefined) {
                    return a.ZINDEX - b.ZINDEX
                } 
            })
        },
        // 거래소, 등락률 순으로 정렬
        sortingByRate(items){
            items.sort(function(a, b) {
                const aDate = a.createTime.substr(0, 8)
                const bDate = b.createTime.substr(0, 8)
                if (aDate === bDate) {
                    let i =
                        a.TRADING_MARKET0 > b.TRADING_MARKET0
                            ? -1
                            : a.TRADING_MARKET0 < b.TRADING_MARKET0
                            ? 1
                            : 0
                    if (i === 0) {
                        return a.changeRate > b.changeRate
                            ? -1
                            : a.changeRate < b.changeRate
                            ? 1
                            : 0
                    }
                    return i
                } else {
                    return aDate < bDate ? 1 : -1
                }
            })
        },
        //private
        doOrder(list) {
            // 220712 meej 정렬방식 설정 추가
            this.condition.sortZ = true
            this.ORDER_ITEMS({ items: list, newsType: list[0].newsType })
                .then(() => {
                    this.showMessage({ vc: this, message: '변경되었습니다.' })
                    this.INIT_LIST()
                    this.getList()
                    this.condition.sortZ = false
                })
                .catch((err) => {
                    this.showError({ vc: this, error: err })
                })
        },
        filterFlash(val, update) {
            if (this.flashOptions.length === 0) {
                this.GET_FLASH_NEWSTYPE('FeturedStockFlashType')
                    .then((data) => {
                        this.flashOptions = data.newsTypes
                        update()
                    })
                    .catch((err) => {
                        if (err === undefined) {
                            err = '오류가 발생하였습니다.'
                        }
                        this.showError({ vc: this, error: err })
                    })
            } else {
                update()
            }
        },

        // getListByDate(item) {
        //     let params = {
        //         newsType:item.newsType1,
        //         date:item.createTime.substring(0,8)
        //     }
        //     this.GET_DATE_LIST(params)
        //         .then((data) => {
        //             this.orders = data.items
        //             this.orders.forEach((row, index) => {
        //                 row.index = index
        //             })
        //         })
        //         .catch((err)=> {
        //             this.showError({vc:this, error:err})
        //         })
        // },
        // handle Content component
        handleUpdateList() {
            this.INIT_LIST()
            this.getList()
        },
        // handle List component
        handleGetList(condition) {
            Debug.log('handleGetList')
            this.condition.all = condition.all
            this.condition.date = condition.date
            this.condition.newsType = condition.newsType
            this.condition.sortZ = condition.sortZ // 순서변경 목록 여부에 따라 정렬기준이 달라짐
            if (condition.init) { 
                this.INIT_LIST()
            }
            this.getList()
        },
        // 상세 조회
        handleGetItem(item) {
            this.goDetailPage()
            this.SET_DETAIL(item)
        },
        // 확인
        handleConfirm(array) {
            Debug.log('handleConfirm', array)
            let params = {}
            params.ids = array.map((x) => x.id).join(',')
            params.status = array
                .map((x) => (x.showFlag === 1 ? 0 : 1))
                .join(',')
            params.force = 1
            this.CONFIRM_FEATURED(params)
                .then(() => {
                    this.showMessage({ vc: this, message: '적용되었습니다.' })
                    this.INIT_LIST()
                    this.getList()
                    this.goListPage()
                })
                .catch((error) => {
                    this.showError({ vc: this, error: error })
                })
        },
        // 삭제하기
        // handleDelete(array) {
        //     let params = {}
        //     params.ids = array.map((x) => x.id).join(',')
        //     // 특징주는 delete!!!, 특징주 아닌것들은 이슈로 넘어가므놀
        //     // 2021.08.08 손숙희 :  박광춘 과장님과 연락후 변경 (특징주 삭제는 나중에도 보여야함 )
        //     //params.force = 1
        //     this.DELETE_ITEMS(params)
        //         .then(() => {
        //             this.showMessage({ vc: this, message: '삭제되었습니다.' })
        //             this.INIT_LIST()
        //             this.getList()
        //             this.goListPage()
        //         })
        //         .catch((error) => {
        //             this.showError({ vc: this, error: error })
        //         })
        // },
        //20211019 shb 삭제(상태를 D로 변경 )
        handleDelete(array) {
            let params = {}
            params.ids = array.map((x) => x.id).join(',')
            this.REGIST_ISSUE(params)
                .then(() => {
                    this.showMessage({ vc: this, message: '삭제되었습니다.' })
                    this.INIT_LIST()
                    this.getList()
                    this.goListPage()
                })
                .catch((error) => {
                    this.showError({ vc: this, error: error })
                })
        },
        // 장마감 이동
        handleChange(array) {
            let params = {}
            params.ids = array.map((x) => x.id).join(',')
            this.showAlert({
                vc: this,
                message:
                    '해당 내용은 [Spot 입력 > 특징주(장마감)]에서 확인가능합니다. <br/>이동하시겠습니까?',
            })
                .then(() => {
                    params.newsType = 'STOCK_FEATURED'
                    this.CHANGE_NEWSTYPE_ITEMS(params)
                        .then(() => {
                            this.showMessage({
                                vc: this,
                                message: '변경 되었습니다.',
                            })
                            this.INIT_LIST()
                            this.getList()
                            this.goListPage()
                        })
                        .catch((error) => {
                            this.showError({ vc: this, error: error })
                        })
                })
                .catch(() => {})
        },
        // 220905 meej 특징주 - 이슈이동
        handleChangeIssue(array) {
            let params = {}
            params.ids = array.map((x) => x.id).join(',')
            this.showAlert({
                vc: this,
                message:
                    '해당 내용은 [Spot 입력 > 이슈]에서 확인가능합니다. <br/>이동하시겠습니까?<br/>(특징주(장마감)는 삭제상태로 입력 됩니다.)',
            })
                .then(() => {
                    this.CHANGE_ISSUE(params)
                        .then(() => {
                            this.showMessage({
                                vc: this,
                                message: '변경 되었습니다.',
                            })
                            this.INIT_LIST()
                            this.getList()
                            this.goListPage()
                        })
                        .catch((error) => {
                            this.showError({ vc: this, error: error })
                        })
                })
                .catch(() => {})
        },
        /* 220511 meej 등락률 업데이트 기능 추가 */
        handleUpdateRate(array, type) {
            let params = {}
            params.ids = array.map((x) => x.id).join(',')
            params.newsType = type.value
            this.UPDATE_RATE(params)
                .then((el) => {
                    // 220712 meej 등락률 업데이트 멘트 추가(등락률반전종목) 및 showMessage -> showConfirm 으로 변경
                    var msg = "[ "
                    msg += el.lastUpdate.substring(0,4)+"-"+el.lastUpdate.substring(4,6)+"-"+el.lastUpdate.substring(6,8)+" "
                    msg += el.lastUpdate.substring(8,10)+":"+el.lastUpdate.substring(10,12)+":"+el.lastUpdate.substring(12,14)
                    msg += " ] 기준 등락률로 업데이트되었습니다."
                    if(el.stateList != null && el.stateList.length > 0){
                        msg += "<br><br>"
                        msg += "※ 등락률 반전(양수<->음수)된 종목<br>"
                        msg += el.stateList
                    }
                    this.showConfirm({ vc: this, message: msg })
                    this.INIT_LIST()
                    this.getList()
                    this.goListPage()
                })
                .catch((error) => {
                    this.showError({ vc: this, error: error })
                })
        },
        /* 220624 meej 표 전체보기 기능 추가 */
        handleListWide(val){
            this.listWide = val   
        }
    },
}
</script>

<style>
/* common */

/* xs */
@media only screen and (max-width: 599px) {
}

/* sm */
@media only screen and (max-width: 1023px) {
}

/* md */
@media only screen and (max-width: 1439px) {
}

/* lg */
@media only screen and (max-width: 1919px) {
}

/* xl */
@media only screen and (min-width: 1920px) {
}
</style>
