<template>
  <div class="q-pa-md">
    <!-- <div id="media-Box-PC" class="row"> -->
    <div class="row">
      <div class="col-5 q-pa-md">
        <!-- 220628 meej 회원수 현황 차트 추가 -->
        <MemberChart />
        <MainTable />
        <!-- <section>
            <q-tableS
                flat bordered dense class="i-table" hide-bottom
                row-key="name"
                :columns="columns" :data="list"
                virtual-scroll
                :rows-per-page-options="[0]"
                hide-pagination
                >
                <template v-slot:header="props">
                    <q-tr :props="props">
                        <q-th
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props"
                        >
                            {{ col.label }}
                        </q-th>
                    </q-tr>
                </template>

                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props"
                            @click="getItem(props.row)"
                        >
                            {{ col.value }}
                        </q-td>
                    </q-tr>
                </template>

            </q-table>
        </section> -->
      </div>
      <div class="col-7 q-pa-md">
        <Calendar class="calendarBorder"></Calendar>
      </div>
    </div>
    <!-- 220628 meej media-Box-mobile 추가-->
    <!-- <div id="media-Box-mobile">
        <MemberChart />
        <MainTable />
        <div class="q-pt-md">
            <Calendar class="calendarBorder"></Calendar>
        </div>
    </div> -->
  </div>
</template>
 
<script>
import Calendar from "@/components/common/Calendar";
import MainTable from "../components/common/MainTable.vue";
import MemberChart from "../components/common/MemberChart.vue";

// import { mapActions, mapMutations } from "vuex";
// import Debug from "@/debug";
export default {
  name: "Main",
  components: {
    Calendar: Calendar,
    MainTable,
    MemberChart, // 220628 meej 회원수 현황 차트 추가
  },
  data() {
    return {
      // 220824 meej 사용 X 주석처리
      // columns: [
      //   {
      //     name: "time",
      //     label: "시간",
      //     align: "left",
      //     field: (row) => row.sendTime,
      //   },
      //   {
      //     name: "name",
      //     label: "속보명",
      //     align: "left",
      //     field: (row) => row.name,
      //   },
      //   {
      //     name: "label",
      //     label: "상태",
      //     align: "left",
      //     field: (row) => (row.sent == 1 ? "전송" : ""),
      //   },
      // ],
      // title: "속보전송상황",
      // list: [],
    };
  },
  created() {
    // 220824 meej 사용 X 주석처리
    // this.getFlashList();
  },
  computed: {},
  //FlashType
  methods: {
    // 220824 meej 사용 X 주석처리
    // ...mapMutations("flash", ["SET_PREVIEW"]),
    // ...mapActions({
    //   GET_FLASH_LIST: "flash/GET_FLASH_LIST",
    //   // GET_SENT_LIST: "flash/GET_SENT_LIST",
    //   DETAIL_FLASH: "flash/DETAIL_FLASH",
    //   showError: "alert/showError",
    // }),
    // getFlashList() {
    //   this.GET_SENT_LIST("FlashType")
    //     .then((res) => {
    //       this.list = res.newsTypes;
    //     })
    //     .catch((err) => {
    //       if (err === undefined) {
    //         err = "오류가 발생하였습니다.";
    //       }
    //       this.showError({ vc: this, error: err });
    //     });
    // },
    // getItem(row) {
    //   Debug.log("row = ", row);
    //   let params = {
    //     newsType: row.value,
    //     id: row.id,
    //   };
    //   this.DETAIL_FLASH(params).then((res) => {
    //     Debug.log("data = ", res);
    //     this.SET_PREVIEW(res);
    //   });
    // },
  },
};
</script>

<style>
.calendarBorder {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%),
    0 3px 1px -2px rgb(0 0 0 / 12%);
  padding: 10px;
}

/* 220824 meej 사용 X 주석처리 */
/* @media (min-width: 1200px) {
    #media-Box-mobile {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    #media-Box-PC {
        display: none;
    }
} */
</style>