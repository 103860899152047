<template>
    <article>
        <div class="q-pa-sm">
            <q-select 
                dense
                v-model="target"
                :options="options"
                option-value="value"
                option-label="name"
            />
        </div>
        <div id="preview">
            <div class="row col-12 q-my-xs">
                {{ previewType.name }}
            </div>
            <q-card class="q-pa-sm text-left" flat bordered>
                <div v-html="htmlMessage[target.value]"></div>
            </q-card>
            <div class="flex justify-end q-gutter-xs">
                <i-btn label="취소" @click="cancelFunc()"/>
                <i-btn label="저장" @click="confirm()"/>
            </div>
        </div>
    </article>
</template>
<script>
import debug from '@/debug'
import Button from '@/components/common/Button'
import CONSTANTS from '@/constants'
import { mapState,mapMutations, mapActions, mapGetters } from 'vuex'

export default {
    name: 'PreviewPaper',

    components: {
        'i-btn' :  Button,
    },
    data () {
        return {
            htmlMessage: {},
            prevData:{},
            target: {'value' : 'REGULAR', 'name' : '홈페이지/유료회원' },
            options: CONSTANTS.NEWSPAPER_TARGET,
        }
    },

    watch: {
        target() {
            this.addJavascript()
        }
    },

    created () {
        debug.log('papernewstype = ',this.previewType)

        for (const opt of this.options) {
            this.htmlMessage[opt.value] = '<div>주식뉴스 생성중입니다...</div>'
        }
        setTimeout(() => {
            this.getPreview()
        }, 25000);
        
    },

    mounted() {
        this.$q.iconSet.arrow.dropdown = ""
    },

    computed: {
        ...mapState ({
            PAGE_STATUS: state => state.page.status,
            previewType: state => state.newspaper.paperType,
        }),
        ...mapGetters( 'calendar', ['today']),
        
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',

        }),
        ...mapActions( 'newspaper', ['PAPER_PREVIEW', 'PAPER_CONFIRM']),
        ...mapActions( 'alert', ['showError', 'showMessage']),
        getPreview() {
            
            let params = {
                newsType:this.previewType.value
            }

            this.PAPER_PREVIEW(params)
            .then((res) => {
                debug.log('res = ',res)
                this.prevData = res
                this.htmlMessage = res

                this.addJavascript()

            })
            .catch((err) => {
                this.showError({vc:this, error:err} )
            })
        },
        
        cancelFunc() {
            window.close()
        },
        confirm() {
            debug.log(this.previewType)

            if(this.htmlMessage[this.options[0].value] === '<div>주식뉴스 생성중입니다...</div>') {
                this.showMessage({vc:this, message:"주식뉴스 생성중입니다!"})
                return
            }

            this.PAPER_CONFIRM({newsType: this.previewType.value, content: this.prevData})
            .then(() => {
                this.showMessage({vc:this, message:"주식신문을 저장하였습니다."})
            })
            .catch((err) => {
                this.showError({vc:this, error:err} )
            })
        },
        
        addJavascript() {
            const newsPaperScript = document.createElement('script')
            newsPaperScript.setAttribute('src', 'https://news.infostock.co.kr/staticserving/js/newspaper.js')
            document.head.appendChild(newsPaperScript)
        }

    }
}
</script>
<style>
#preview {
    text-align: left;
}
</style>