<template>
    <section class="row q-pl-xs">
        <q-toolbar class="row q-mt-md text-left">
            <div style="padding: 5px 5px 0px 10px; color: grey">요청일</div>
            <div class="q-pa-xs"><i-datetime date v-model="startDate" /></div>
            <div>~</div>
            <div class="q-pa-xs" style="padding: 0px 20px 0px 0px;"><i-datetime date v-model="endDate"/></div>
            <div class="q-pa-xs"><i-select v-model="department" :options="departmentOptions" option-value="value" option-label="name" style="width:100px;"/></div>
            <div class="q-pa-xs"><i-select v-model="requester" :options="requesterOptions" option-value="value" option-label="name" style="width:100px;"/></div>
            <div class="q-pa-xs"><i-select v-model="respondent" :options="respondentOptions" option-value="value" option-label="name" style="width:100px;"/></div>
            <div class="q-pa-xs"><i-select v-model="work_person" :options="work_personOptions" option-value="value" option-label="name" style="width:100px;"/></div>
            <div class="q-pa-xs"><i-select v-model="state" :options="stateOptions" option-value="value" option-label="name" style="width:120px;"/></div>
            <div class="q-pa-xs"><i-input v-model="keyword" ref="keyword" style="width:200px;" label="프로젝트명 입력"/></div>
            
            <div class="q-pa-xs"><i-btn icon="search" @click="getListByCondition()" /></div>
            
            <div class="q-pa-xs"><i-btn label="선택삭제" style="width:100px;" @click="getDeleteChk()"/></div>
            <div class="q-pa-xs"><i-btn label="결과등록" style="width:100px" @click="getResultPopup()"/></div>
            
            <div class="q-pa-xs"><i-btn label="작성" style="width:100px" @click="getUpdatePopup()"/></div>      
        </q-toolbar>
        
        <i-list class="col-12 q-pa-md" ref="workmanagerList"/>
        <i-content ref="workmanagerContent"/>
        <i-result ref="resultContent"/>
    </section>
</template>
<script>


import Input from '@/components/common/Input';
import CONSTANTS from '@/constants'
import Select from '@/components/common/Select.vue';
import Button from '@/components/common/Button'
import List from '@/components/manage/work/work.vue';
import Content from '@/components/manage/member/Content.vue'
import Result from '@/components/manage/member/Result.vue'
import DateTime from '@/components/common/DateTime'
import { mapState, mapActions } from 'vuex'
export default {
    name: 'Workmanager',  
    components: {
        "i-input" : Input,
        'i-select' : Select,
        'i-btn': Button,
        "i-list": List,
        "i-content": Content,
        "i-result": Result,
        'i-datetime': DateTime,
    },

    data() {
        return {
            department: {'value':'', 'name':'부서'},
            departmentOptions: [{'value':'', 'name':'부서'}].concat(CONSTANTS.DEPARTMENT_TYPE),

            requester: {'value':'', 'name':'요청자'},
            requesterOptions: [{'value':'', 'name':'요청자'}].concat(CONSTANTS.PERSON_NAME),

            respondent: {'value':'', 'name':'확인자'},
            respondentOptions: [{'value':'', 'name':'확인자'}].concat(CONSTANTS.PERSON_NAME),

            work_person: {'value':'', 'name':'담당자'},
            work_personOptions: [{'value':'', 'name':'담당자'}].concat(CONSTANTS.PERSON_NAME),

            state: {'value':'', 'name':'업무결과'},
            stateOptions: [{'value':'', 'name':'업무결과'}].concat(CONSTANTS.STATE),

            keyword: '',
            startDate: '',
            endDate: '',
        }
    },

    computed: {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
        }),
    },

    methods: {
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),

        getList() {
            this.$refs.workmanagerList.getList();
        },

        getListByCondition(){
            this.$refs.workmanagerList.getListByCondition();
        },

        getUpdatePopup() {
            this.$refs.workmanagerContent.getUpdatePopup();
        },
        getResultPopup() {
            const ds_list = this.$refs.workmanagerList.getChkId();
            if(ds_list.length > 1){
                this.showError({ vc:this, error:'한 가지만 체크하세요.' });
                return;
            }

            if(ds_list.length < 1){
                this.showError({ vc:this, error:'한 가지를 체크하세요.' });
                return;
            }
            this.$refs.resultContent.getResultPopup(ds_list[0]);
        },

        getDeleteChk() {
            this.$refs.workmanagerList.getDeleteChk();
        },

    },
};
</script>