<template>
  <div class="calendar">
    <!-- ----- event popup start ----- -->
    <div>
      <q-dialog v-model="dialog" persistent>
        <q-card>
          <q-card-section>
            <q-input
              v-model="editEvent.title"
              autofocus
              @keyup.enter.stop
              placeholder="제목"
              :rules="[(val) => !!val || '제목을 입력하세요']"
            />

            <div class="q-gutter-sm q-mb-sm">
              <q-radio
                keep-color
                v-model="editEvent.color"
                val="teal"
                label="Teal"
                color="teal"
              />
              <q-radio
                keep-color
                v-model="editEvent.color"
                val="orange"
                label="Orange"
                color="orange"
              />
              <q-radio
                keep-color
                v-model="editEvent.color"
                val="red"
                label="Red"
                color="red"
              />
              <q-radio
                keep-color
                v-model="editEvent.color"
                val="cyan"
                label="Cyan"
                color="cyan"
              />
            </div>

            <q-input
              class="q-gutter-sm q-mt-sm q-mb-sm"
              v-model="editEvent.start"
              filled
              type="date"
              hint="시작일"
              :rules="[(val) => !!val || '시작일을 확인하세요']"
            />

            <q-input
              class="q-gutter-sm q-mb-sm"
              v-model="endDate"
              filled
              type="date"
              hint="종료일"
              :rules="[
                (val) => editEvent.start <= val || '종료일 을 확인하세요',
              ]"
            />

            <q-editor
              v-model="editEvent.description"
              min-height="5rem"
              @keyup.enter.stop
            />
          </q-card-section>

          <q-card-actions align="center">
            <!-- button Delete ; 수정 시에만 보임 -->
            <q-btn
              v-if="editMode"
              flat
              color="primary"
              label="DELETE"
              @click="handleDeleteSelect(currentEvents)"
            />
            <!-- button Save -->
            <q-btn
              flat
              color="primary"
              label="SAVE"
              @click="handleAddEvent(currentEvents)"
            />
            <!-- button Close -->
            <q-btn flat color="primary" label="CANCEL" @click="popupClose" />
          </q-card-actions>
        </q-card>
      </q-dialog>
    </div>
    <!-- ----- event popup end ----- -->

    <!-- ----- calendar 1 start ----- -->
    <div>
      <FullCalendar ref="calendar1" :options="config1">
        <template v-slot:eventContent="arg">
          <div>
            <b>{{ arg.timeText }}</b>
            {{ arg.event.title }}
          </div>

          <!-- mouseOver 시 보이는 tooltip start -->
          <q-tooltip anchor="top right" self="top left" :offset="[10, 10]">
            <div class="customTt">
              <strong>{{ arg.event.title }}</strong>
              <br />
              <div v-html="arg.event.extendedProps.description">
                {{ arg.event.extendedProps.description }}
              </div>
            </div>
          </q-tooltip>
          <!-- mouseOver 시 보이는 tooltip end -->
        </template>
      </FullCalendar>
      <!-- ----- calendar 1 end ----- -->

      <!-- ----- calendar 2 start ----- -->
      <FullCalendar ref="calendar2" :options="config2">
        <template v-slot:eventContent="arg">
          <div>
            <b>{{ arg.timeText }}</b>
            {{ arg.event.title }}
          </div>

          <!-- mouseOver 시 보이는 tooltip start -->
          <q-tooltip anchor="top right" self="top left" :offset="[10, 10]">
            <div class="customTt">
              <strong>{{ arg.event.title }}</strong>
              <br />
              <div v-html="arg.event.extendedProps.description">
                {{ arg.event.extendedProps.description }}
              </div>
            </div>
          </q-tooltip>
          <!-- mouseOver 시 보이는 tooltip end -->
        </template>
      </FullCalendar>
      <!-- ----- calendar 2 end ----- -->
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "calendar",
  components: {
    FullCalendar,
  },
  computed: {
    ...mapGetters("adminSchedule", ["initEvents"]),

    // calendar1 options
    config1() {
      return {
        ...this.configOptions,
        ...this.oneOptions,
      };
    },
    // calendar2 options
    config2() {
      return {
        ...this.configOptions,
        ...this.twoOptions,
      };
    },
    // calendar defualt options
    configOptions() {
      return {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        events: this.initEvents,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventDrop: this.handelEventDrop,
        height: "auto", // 220713 meej 일정 수 에 맞게 cell 높이 조정
      };
    },
    // calendar1 options
    oneOptions() {
      return {
        customButtons: {
          registerButton: {
            text: "register",
            click: () => {
              // 1. 기본 값 set
              this.editEvent.title = "";
              this.editEvent.description = "";
              let today = moment(new Date()).format("YYYY-MM-DD").toString();
              this.editEvent.start = today;
              this.endDate = today;
              this.editEvent.color = this.color;

              // 2. popup open
              this.editMode = false;
              this.dialog = true;
            },
          },
          nextButton: {
            text: ">",
            click: () => {
              this.calendar1.next();
              this.calendar2.next();
            },
          },
          nextYearButton: {
            text: ">>",
            click: () => {
              this.calendar1.nextYear();
              this.calendar2.nextYear();
            },
          },
          prevButton: {
            text: "<",
            click: () => {
              this.calendar1.prev();
              this.calendar2.prev();
            },
          },
          prevYearButton: {
            text: "<<",
            click: () => {
              this.calendar1.prevYear();
              this.calendar2.prevYear();
            },
          },
          todayButton: {
            text: "today",
            click: () => {
              this.calendar1.gotoDate(moment().format("YYYY-MM-DD"));
              this.calendar2.gotoDate(
                moment().add(1, "months").format("YYYY-MM-DD")
              );
            },
          },
        },
        initialDate: moment().format("YYYY-MM-DD"),
        headerToolbar: {
          left: "todayButton prevYearButton,prevButton",
          center: "title",
          right: "nextButton,nextYearButton registerButton",
        },
      };
    },
    // calendar2 options
    twoOptions() {
      return {
        initialDate: moment().add(1, "months").format("YYYY-MM-DD"),
        headerToolbar: {
          left: "",
          center: "title",
          right: "",
        },
      };
    },
  },
  created() {
    this.getList();
  },
  mounted() {
    // calendar get Api
    this.calendar1 = this.$refs.calendar1.getApi();
    this.calendar2 = this.$refs.calendar2.getApi();
  },
  data: function () {
    return {
      calendar1: null,
      calendar2: null,
      dialog: false, // 팝업창 사용여부
      editMode: false, // 팝업창 수정 모드 설정
      cancelEnabled: false,
      color: "teal", // 기본 색상 설정
      date: moment(new Date()).format("YYYY-MM-DD").toString(), // 오늘 날짜
      endDate: null, // 종료날짜
      currentEvents: [], // 현재 선택된 이벤트
      editEvent: { // 수정한 이벤트
        title: "",
        color: "",
        start: "",
        end: "",
        description: "",
      },
    };
  },
  methods: {
    ...mapActions("adminSchedule", [
      "ASYNC_EVENT_LIST",
      "WRITE_EVENTS",
      "DELETE_EVENTS",
      "GET_CALL",
    ]),
    ...mapActions("alert", ["showError", "showMessage"]),

    /* event 목록 가져오기 */
    getList() {
      const params = {};
      this.ASYNC_EVENT_LIST(params);
    },

    /* 팝업 close */
    popupClose() {
      this.editMode = false;
      this.dialog = false;
    },

    /* Date select 
      1. event value set
      2. 팝업창 open */
    handleDateSelect(selectInfo) {
      // 1. event value set
      this.editEvent.title = "";
      this.editEvent.description = "";
      this.editEvent.start = selectInfo.startStr;
      this.editEvent.color = this.color;

      // 1-1. endDate -1로 보이게 설정
      let endDate = new Date(selectInfo.endStr);
      endDate.setDate(endDate.getDate() - 1);
      endDate = moment(endDate).format("YYYY-MM-DD").toString();
      this.endDate = endDate;

      // 1-3. 현재 이벤트 설정
      this.currentEvents = selectInfo;

      // 2. 팝업창 open
      this.editMode = false;
      this.dialog = true;
    },

    /* event 추가 */
    handleAddEvent(eventInfo) {
      // 1. endDate -1로 보이게 설정
      let endDate = new Date(this.endDate);
      endDate.setDate(endDate.getDate() + 1);
      this.editEvent.end = moment(endDate).format("YYYY-MM-DD").toString();

      // 2. set params
      if (
        this.editEvent.title.length > 0 &&
        new Date(this.editEvent.start) < new Date(this.editEvent.end)
      ) {
        let params = {
          id: "",
          title: this.editEvent.title,
          color: this.editEvent.color,
          start: this.editEvent.start.replaceAll("-", ""),
          end: this.editEvent.end.replaceAll("-", ""),
          allDay: true,
          description: this.editEvent.description,
        };

        // 2-1. 수정 일 경우 id set
        if (this.editMode) {
          params.id = eventInfo.event.id;
        }

        // 3. call api
        this.WRITE_EVENTS(params)
          .then(() => {
            this.getList();
          })
          .catch((error) => {
            this.showError({ vc: this, error: error });
          });

        // 4. 팝업창 close
        this.editMode = false;
        this.dialog = false;
      }
    },

    /* 이벤트 클릭 */
    handleEventClick(clickInfo) {
      // 1. 현재 이벤트 설정
      this.currentEvents = clickInfo;

      // 2. event value set
      this.editEvent.title = clickInfo.event.title;
      this.editEvent.description = clickInfo.event.extendedProps.description;
      this.editEvent.start = clickInfo.event.startStr;
      this.editEvent.color = clickInfo.event.backgroundColor;
     
      // 3. endDate -1로 보이게 설정
      let endDate = new Date(clickInfo.event.endStr);
      endDate.setDate(endDate.getDate() - 1);
      endDate = moment(endDate).format("YYYY-MM-DD").toString();
      this.endDate = endDate;

      // 4. popup Open
      this.editMode = true;
      this.dialog = true;
    },

    /* 이벤트 삭제 */
    handleDeleteSelect(delInfo) {
      // 1. remove
      this.calendar1.getEventById(delInfo.event.id).remove();
      this.calendar2.getEventById(delInfo.event.id).remove();

      // 2. param set & call api
      let param = {
        id: delInfo.event.id,
        CALL_API_URL: "/adminSchedule/delete",
      };
      this.GET_CALL(param)
        .then(() => {
          this.showMessage({ vc: this, message: "삭제 되었습니다." });
        })
        .catch((error) => {
          this.showError({ vc: this, error: error });
        });

      // 3. popup close
      this.editMode = false;
      this.dialog = false;
    },

    /* event 설정 */
    handleEvents(events) {
      this.currentEvents = events;
    },
  },
};
</script>

<style lang='css'>
h2 {
  margin: 0;
  font-size: 16px;
}
ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}
li {
  margin: 1.5em 0;
  padding: 0;
}
b {
  margin-right: 3px;
}
.fc {
  max-width: 1100px;
  margin: 0 auto;
}
.customTt {
  font-size: 12px;
}
.customTt strong {
  font-size: 15px;
}
</style>