<template>
    <article class="q-pa-md">
        <div class="q-my-xs">
            <i-flash-type
                ref="flashTypeRef"
                class="full-width"
                @event-select-type="handleSelectType"
                @event-select-stock="handleSelectStock"
            />
        </div>
        <div class="q-my-xs q-pa-xs">
            <i-input v-model="title" inputLabel="제목" />
        </div>
        <div class="q-my-xs q-pa-xs">
            <!-- <i-input
                //20210916 uploadMacro 없이 write 에서 newsType 구분해서 처리하게 변경 
                v-show="isWrite"
                v-model="content"
                inputLabel="내용"
                type="textarea"
                :rows="30"
            /> -->
            <i-input
                v-model="content"
                inputLabel="내용"
                type="textarea"
                :rows="25"
            />
        </div>
        <div class="q-my-xs">
            <i-datetime
                class="col-sm-6"
                v-model="sendTime"
                date
                dateLabel="전송일자"
                time
                timeLabel="전송시간"
            />
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 q-pa-xs">
                <i-btn label="다시쓰기" @click="initInputs()" class="full-width" />
            </div>
            <div class="col-12 col-sm-6 q-pa-xs">
                <i-btn
                    label="속보전송"
                    @click="register()"
                    class="full-width"
                />
            </div>
        </div>
    </article>
</template>
<script>
import DateTime from '@/components/common/DateTime'
import Button from '@/components/common/Button'
import Input from '@/components/common/Input'
import FlashType from '@/components/flash/news-flash/FlashType'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import Debug from '@/debug'
import {compareTime, nowTime} from '@/util'

export default {
    name: 'Content',
    components: {
        'i-datetime': DateTime,
        'i-btn': Button,
        'i-input': Input,
        'i-flash-type': FlashType,
    },
    data() {
        return {
            flashType: null,
            sendTime: null,
            newsFlashData: {},
            title: '',
            content: '',
            newsStocks: [],
        }
    },
    created() {},
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            flashTypeOptions: (state) => state.flash.flashAdminType,
            flashStatus: (state) => state.flash.status
        }),
        ...mapGetters({
            today: 'calendar/fullToday',
            isSuccess: 'flash/isSuccess'
        }),

    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
            SET_PREVIEW: 'flash/SET_PREVIEW',
            SET_STATUS: 'flash/SET_STATUS',
            INIT_LIST: 'flash/INIT_LIST'
            
        }),
        ...mapActions('flash', ['WRITE_FLASH', 'SEND_FLASH', 'GET_INDEX','ASYNC_LIST']),
        ...mapActions({
            showError: 'alert/showError',
            showMessage: 'alert/showMessage'
        }),

        handleSelectType(type) {
            Debug.log('handleSelectType!!!!!!! ---> ', type)
            this.flashType = type
            if(this.flashType.value !== 'MARKET_FLASH_DAILY_REPORT') {
                this.title = type.name
            }
            
        },

        register() {
            //뉴스 속보--> 20210915 회의에서 뉴스타입없는 일반 속보도 있다고 나와서 들어가게 수정 
            // if (this.isWrite) {
            //     return
            // }
            // 딱히 뉴스타입 선택없이 나가는 일반 텍스트 
            
            if(this.flashType !== null &&
                (this.flashType.value === 'MARKET_FLASH_STOCKLOAN_SELL' ||
                    this.flashType.value === 'MARKET_FLASH_STOCKLOAN_BUY' ||
                    this.flashType.value === 'MARKET_FLASH_STOCK_CHANGE_DISC'))
            {
                //2021.11.12 suki - parsing이 필요한 속보 
                if (this.$refs.flashTypeRef !== undefined) {
                    this.$refs.flashTypeRef.uploadMacro(
                        this.flashType,
                        this.content,
                        this.sendTime
                    )
                }
                return
            
            }
            let newsType = (this.flashType? this.flashType.value:'MARKET_FLASH_TEXT')
            // if (!this.newsFlash) {
            //     message = '내용을 입력하세요.'
            //     this.showError({ vc:this, error:message})
            //     return
            // }
            let showFlag = 1
            let params = {}
            let stocks = ''
            params.newsType = newsType
            params.showFlag = showFlag
            params.title = this.title
            params.content = this.content
            if(compareTime(this.sendTime)) {
                params.sendTime = this.sendTime
            } else {
                params.sendTime = this.sendTime.substring(0,8)+ nowTime()
            }
            for (let i in this.newsStocks) {
                stocks +=
                    this.newsStocks[i].code +
                    ':' +
                    this.newsStocks[i].name +
                    ','
            }
            stocks.slice(0, -1)
            params.stocks = stocks

            this.WRITE_FLASH(params)
                .then((res) => {
                    Debug.log('data = ', res)
                    //this.initInputs();
                    this.showPreview()
                })
                .catch((err) => {
                    Debug.log('error = ', err)
                    this.showError({ vc: this, error: err })
                })
        },
        initInputs: function() {
            this.content = ''
            this.title = ''
            this.flashType = null
            this.setPageStatus(this.PAGE_STATUS.REGISTER)
            if (this.$refs.flashTypeRef !== undefined) {
                this.$refs.flashTypeRef.parentClear()
            }
        },
        showPreview() {
            this.windowRef = window.open(
                `/preview/popup.htm`,
                '시황속보 미리보기',
                'width=620,height=820'
            )
            if(this.windowRef) {
                this.windowRef.addEventListener('beforeunload', this.closePreview)
            }
            
        },
        handleSelectStock(list) {
            this.newsStocks = list
        },
        closePreview() {
            Debug.log('closePrevice!!!!!!! ---> ', this.flashStatus)
            this.windowRef = null
            if (this.isSuccess) {
                this.showMessage({ vc: this, message: '전송되었습니다.' })
                this.initInputs()
                this.SET_STATUS(0)
                Debug.log('closePrevice success')
                this.INIT_LIST()
                this.ASYNC_LIST({})
            } 

        },
        finishSend() {
            Debug.log('finishSend!!!!!!! ---> ')
        }
    },
    
    beforeDestroy() {
        if (this.windowRef) this.windowRef = null
        //this.$store.dispatch('flash/ASYNC_NEWSTYPE', 'NewsFlashAdminType')
    },
}
</script>
