<template>
    <article>
        <q-dialog v-model="updateQuarterPopup" persistent>
            <q-card style="min-width:550px;min-height:500px;">
                <q-card-section>
                    <div class="col q-gutter-sm">
                        <div class="row">
                            <div class="q-pa-xs">
                                <q-select v-model="stockCode" :options="stockOptions" option-label="name" @filter="filterFn" input-debounce="0" dense outlined :hide-selected="hide_selected" :fill-input="hide_selected" use-input hide-dropdown-icon options-dense>
                                    <q-tooltip anchor="top middle" self="bottom middle" :offset="[5, 5]">한글로 검색 시, enter를 눌러주세요.</q-tooltip>
                                    <template v-slot:no-option><q-item><q-item-section class="text-grey">검색된 데이터가 없습니다.</q-item-section></q-item></template>
                                    <template v-slot:before><label class="i-label">종목검색</label></template>
                                </q-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6"><i-select v-model="stockDivision" :options="stockDivisionOptions" option-value="value" option-label="name" inputLabel="구분" style="width:220px;"/></div>
                            <div class="col-6"><i-select v-model="companyCode" :options="companyCodeOptions" option-value="code" option-label="name" inputLabel="증권사" style="width:240px;"/></div>
                        </div>
                        <div class="row">
                            <div class="col-6"><i-select v-model="ifrs" :options="ifrsOptions" option-value="value" option-label="name" inputLabel="연결/별도" style="width:220px;"/></div>
                            <div class="col-6"><i-select v-model="unit" :options="unitOptions" option-value="value" option-label="name" inputLabel="단위" style="width:200px;"/></div>
                        </div>
                        <div class="q-ma-xs"><q-input class="full-width" dense outlined v-model="textLine" @keyup="textLineHandler" type="textarea" :input-style="{height:'70px'}" placeholder="Excel Header를 제외한 Data를 복사한 후 붙여넣기 하세요." /></div>
                        <div class="q-pa-xs">
                            <q-table flat bordered dense separator="cell" class="i-table" :columns="columns" :data="list" row-key="index" :rows-per-page-options="[0]" hide-pagination style="height:100%;">
                                <template v-slot:header="props">
                                    <q-tr :props="props">
                                        <q-th v-for="col in props.cols" :key="col.name" :props="props">
                                            <div v-if="col.name == 'name'">{{ col.label }}</div>
                                            <div v-else class="q-mx-auto select" style="display:table;">
                                                <div class="row">
                                                    <div class="q-pa-xs"><i-select v-model="year" :options="yearOptions" option-value="value" option-label="name" style="width:80px;"/></div>
                                                    <div class="q-pa-xs"><i-select v-model="quarter" :options="quarterOptions" option-value="value" option-label="name" style="width:90px;"/></div>
                                                </div>
                                            </div>
                                        </q-th>
                                    </q-tr>
                                </template>
                                <template v-slot:body="props">
                                    <q-tr :props="props">
                                        <q-td v-for="col in props.cols" :key="col.name" :props="props">
                                            <div v-if="col.name == 'name'">{{ props.row[col.name] }}</div>
                                            <div v-else class="q-mx-auto number" style="display:table;"><q-input v-model="props.row[col.name]" type="number" dense outlined input-class="text-right"/></div>
                                        </q-td>
                                    </q-tr>
                                </template>
                            </q-table>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right" class="text-primary">
                    <div class="q-pa-xs"><q-btn color="white" text-color="black" label="닫기" v-close-popup/></div>
                    <div class="q-pa-xs"><q-btn label="등록" @click="getUpdateQuarterPopupSave()"/></div>
                </q-card-actions>
            </q-card>
        </q-dialog>
    </article>
</template>
<script>
import CONSTANTS from '@/constants';
import { nvl, isEmpty } from '@/util';
import Select from "@/components/common/Select";
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    props: {
        hide_selected: {
            type: Boolean,
            default: true
        }
    },
    components: {
        'i-select' : Select
    },
    data () {
        return {
            updateQuarterPopup: false,
            stockList: [],
            stockCode: '',
            stockOptions: [],
            year: {'value':'', 'name':'연도'},
            yearOptions: [{'value':'', 'name':'연도'}],
            quarter:{'value':'', 'name':'분기'},
            quarterOptions: [{'value':'', 'name':'분기'}].concat(CONSTANTS.COM_QUARTER),
            companyCode: {'code':'', 'name':'증권사'},
            companyCodeOptions: [{'code':'', 'name':'증권사'}],
            ifrs: {'value':'', 'name':'연결/별도'},
            ifrsOptions: [{'value':'', 'name':'연결/별도'}].concat(CONSTANTS.COM_IFRS),
            unit: {'value':'', 'name':'단위'},
            unitOptions: [{'value':'', 'name':'단위'}].concat(CONSTANTS.COM_UNIT),
            stockDivision: {'value':'', 'name':'구분'},
            stockDivisionOptions: [{'value':'', 'name':'구분'}].concat(CONSTANTS.COM_STOCK_DIV),
            colList: [
                    { name:'매출액', code:'sales'},
                    { name:'영업이익', code:'operatingIncome'},
                    { name:'세전이익', code:'pretaxIncome'},
                    { name:'당기순이익', code:'netIncome'},
                    { name:'지배주주 순이익', code:'ciNetIncome'}
            ],
            columns: [
                    { headerStyle:'text-align:center;', style:'width:*;', name:'name',  label: '구분', align: 'center', field:'name'},
                    { headerStyle:'text-align:center;', style:'width:80%;', name:'value1', label: '년도', align: 'center', field:'value1'}
                ],
            list: [],
            textLine: '',
        }
    },
    computed : {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            stockItems : state => state.resource.stockItems
        })
    },
    created () {
        this.getInit();
    },
    methods: {
        ...mapMutations('manage', ['SET_DATA', 'INIT_DATA_VIEW', 'SET_DATA_VIEW']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        //수정팝업
        getUpdateQuarterPopup() {
            this.stockCode = '';
            this.textLine = '';
            this.list = [];
            this.colList.forEach(row => {
                var arr = {};
                arr['name'] = row['name'];
                arr['code'] = row['code'];
                arr['value1'] = '0';
                this.list.push(arr);
            });
            this.year = {'value':'', 'name':'연도'};
            this.quarter = {'value':'', 'name':'분기'};
            this.companyCode = {'code':'', 'name':'증권사'};
            this.ifrs = {'value':'', 'name':'연결/별도'};
            this.unit = {'value':'', 'name':'단위'};
            this.stockDivision = {'value':'', 'name':'구분'};
            this.updateQuarterPopup = true;
        },
        //수정팝업
        getUpdateQuarterPopupSave() {
            if (this.stockCode == null || this.stockCode == "") {
                this.showMessage({ vc:this, message:'종목을 선택하세요.' });
                return;
            }
            if (this.stockDivision.value == "") {
                this.showMessage({ vc:this, message:'구분을 선택하세요.' });
                return;
            }
            if (this.companyCode.code == "") {
                this.showMessage({ vc:this, message:'증권사를 선택하세요.' });
                return;
            }
            if (this.ifrs.value == "") {
                this.showMessage({ vc:this, message:'연결/별도를 선택하세요.' });
                return;
            }
            if (this.unit.value == "") {
                this.showMessage({ vc:this, message:'단위를 선택하세요.' });
                return;
            }
            if (this.year.value == "") {
                this.showMessage({ vc:this, message:'년도를 선택하세요.' });
                return;
            }
            if (this.quarter.value == "") {
                this.showMessage({ vc:this, message:'분기를 선택하세요.' });
                return;
            }
            this.showAlert({ vc:this, message:'<span style="color:#1976D2;">저장</span> 하시겠습니까?' }).then(()=>{
                this.updateQuarterPopup = false;
                var sStockCode = this.stockCode.code;
                var sOptions = 'stockName:' + this.stockItems.filter(function (entry){ return entry.code === sStockCode; })[0].name;
                var ds_list = [];

                if(this.year.value != ''){
                    var arr = {};
                    arr['type'] = 'ESTIMATED';
                    arr['year'] = this.year.value;
                    arr['updateTime'] = '';
                    arr['quarter'] = this.quarter.value;
                    arr['stockCode'] = sStockCode;
                    arr['stockDivision'] = this.stockDivision.value;
                    arr['companyCode'] = this.companyCode.code;
                    arr['ifrs'] = this.ifrs.value;
                    arr['unit'] = this.unit.value;
                    arr['options'] = sOptions;
                    arr['totalAsset'] = '0';
                    arr['totalLiabilities'] = '0';
                    arr['totalSTEquity'] = '0';
                    arr['ciTotalSTEquity'] = '0';
                    arr['operatingCashFlow'] = '0';
                    arr['investCashFlow'] = '0';
                    arr['finacialCashFlow'] = '0';
                    arr['totalCashFlow'] = '0';
                    arr['netDebt'] = '0';
                    arr['ebitda'] = '0';
                    arr['assetAmort'] = '0';
                    arr['dps'] = '0';
                    this.list.forEach(row => {
                        arr[row['code']] = row['value1'];
                    });
                    ds_list.push(arr);
                }

                let param = {
                    ds_list,
                    'CALL_API_URL': '/consensus/save'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'저장 되었습니다.' });
                    this.$parent.getList();
                }).catch((err)=>{
                    this.showError({ vc:this, error:err });
                });
            }).catch(() => { });
        },
        getInit(){
            var iYear = Number(new Date().getFullYear())+2;
            for(var i=iYear; i >= 2016; i--){
                this.yearOptions.push({
                    value: i+'',
                    name: i+''
                });
            }
            var list = [];
            try {
                list = JSON.parse(this.stockItems);
            } catch (e) {
                list = this.stockItems;
            }
            var ds_list = [];
            list.forEach(row => {
                ds_list.push({
                    code : row['code'],
                    name : row['name'] + ' (' + row['code'] + ')'
                });
            });
            this.stockList = ds_list;
            let param = {
                newsType: 'STOCK_CONSENSUS_COMPANY',
                CALL_API_URL: '/stock/company'
            }
            this.GET_CALL(param).then((data) => {
                this.companyCodeOptions = [{'code':'', 'name':'증권사'}].concat(data.items);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        filterFn (val, update, abort) {
            // 종목검색 시, 1자리 이하일 경우 검색되지 않음.
            if (val.length < 2) {
                if (val.length == 0) {
                    this.stockCode = '';
                }
                abort();
                return;
            }
            update(() => {
                const needle = val.toLowerCase();
                this.stockOptions = this.stockList.filter(
                    v => v.name.toLowerCase().indexOf(needle) > -1 || v.code.indexOf(val) > -1
                )
            })
        },
        textLineHandler(e) {
            if (e.code == 'KeyV' || e.code == 'MetaLeft') {
                var iRow = 0;
                var bStrRow = false;
                var row = this.textLine.split('\n');
                for(var i=0; i < row.length; i++) {
                    if (row[i] == null || row[i].length == 0){ continue; }
                    var cols = row[i].split('\t');
                    for(var k=0; k < cols.length; k++) {
                        var val = nvl(cols[k]).replace(/,/gi,"").trim();
                        if(!isNaN(val) && !isEmpty(val)){
                            if(this.list[iRow] != null){
                                this.list[iRow]['value1'] = val;
                            }
                            bStrRow = true;
                        }
                    }
                    if(bStrRow){
                        iRow++;
                    }
                }
            }
        }
    }
}

</script>
<style type="text/css">
    .number .q-field__control{ padding:0 !important; height: 25px !important; }
    .select .q-field{ height: 25px !important; min-height: 25px !important; }
    .select .q-field__control{ min-height: 25px !important; background-color:#ffffff;}
    .select .q-field__append{ height: 25px !important; }
    .select .q-field__native{ padding:3px 0 0 0 !important; min-height: 25px !important; }
</style>
