<template>
    <section>
        <article class="title q-py-md">
            <h5 class="text-left">{{ title }}</h5>
        </article>
        <q-separator color="grey-6" />
        <article class="row">
            <article class="q-mt-lg col-12">
                <p><img :src="image" /></p>
                <h1>작업중</h1>
                작업중입니다..
                <br />
                <p>현재 메뉴 : {{ message }}</p>
            </article>
        </article>
    </section>
</template>

<script>
import CONSTANTS from "@/constants";
import Debug from "@/debug";
export default {
    name: "Error",
    data() {
        return {
            title: CONSTANTS.MENU.Error,
            message: null,
            image: require("../assets/working.png"),
        };
    },
    created() {
        this.message = this.$route.params.message;
        Debug.log(" message = ", this.message);
    },
};
</script>
