import api from '../../../services/InfostockService'
import Debug  from '../../../debug'
import { FormatDate } from '@/util'

const state = () => ({
    items: [],
    totalCount: 0,
    editList: [],
    theme: null,
    themeRateList: [],
})

const mutations = {
    setList(state, { data }) {

        data.items.forEach((row) => {
            switch(row.type) {
                case 'PUBLIC':
                    row.label = '전체공개'
                break;
                case 'READY':
                    row.label = '준비'
                break;
                case 'DELETE':
                    row.label = '삭제'
                break;
                case 'INTERNAL_PUBLIC':
                    row.label = '내부공개'
                break;
            }
            data.themeName = row.name
        })

        // data.items.forEach((row) => {
        //     switch(row.themeType) {
        //         case 'MAIN_TYPE':
        //             row.label = '대테마'
        //         break;
        //         case 'SUB_TYPE':
        //             row.label = '세부테마'
        //         break;
        //         case null:
        //             row.label = '미분류'
        //         break;
        //     }
        //     data.themeName = row.name
        // })
        state.items = data.items
        state.totalCount = data.totalCount
    },
    // setDetail(state, { data }) {
    //     state.themeDetail = data
    //     state.editList = data.items
    // },
    SET_THEME(state, theme) {
        state.theme = theme
    },
    // 221028 meej 테마 등락률 목록
    setRateList(state, { data }) {
        state.themeRateList = data.items
    },
}


const actions = {

    async ASYNC_THEME_LIST({ commit }, params) {
        const { data } = await api.themeList(params)
        //위에 문법은 themeList의  결과 값이 {data:{items:...,totalCount:100}) 이렇게 들어오는걸  앞에 key(data)로 뽑아서 data에 결과만 넣어준거라 parameter 넘길때 data 만 넘기면 됨

        commit('setList', { data })
    },
    GET_THEME_LIST({commit}, params) {
        return new Promise((resolve, reject) => {
            api.themeList(params)
                .then((response) => {
                    var data = response.data;
                    data.items.forEach((row) => {
                    data.themeName = row.name
                    row.createTime = row.createTime == null ? null : FormatDate(row.createTime.substring(0,8))
                    row.lastUpdateTime = row.lastUpdateTime == null ? null : FormatDate(row.lastUpdateTime.substring(0,8))
                    })
                    
                    // 22.10.27 SUYE 삭제 테마 정렬 아래로 
                    data.items.sort((a, b) => {

                        // PUBLIC, DELETE
                        if(a.type !== 'DELETE' && b.type == 'DELETE') { return -1 }
                        if(a.type == 'DELETE' && b.type !== 'DELETE') { return 1 }

                        if(a.name > b.name) { return 1 } 
                        if(a.name < b.name) { return -1 }
                                                
                        if(a.name.length > b.name.length) { return 1 } 
                        if(a.name.length < b.name.length) { return -1 }

                        if(a.name.localeCompare(b.name) > b.name.localeCompare(a.name)) { return -1 }
                        if(a.name.localeCompare(b.name) < b.name.localeCompare(a.name)) { return 1 }

                    }) 

                    commit('setList', { data })
                    resolve()
                })
                .catch((error) =>{
                    reject(error)
                })
        })

    },
    GET_DETAIL({ rootState }, params) {
        Debug.log('GET_DETAIL params', params)
        return new Promise((resolve, reject) => {
            api.themeDetail(params)
                .then((response) => {
                    let detail = response.data
                    detail.label = rootState.resource.themeType.find(v => v.value0 === detail.type).label0
                    detail.popular = (detail.popular === 1)
                    resolve(detail)
                })
                .catch((error)=>{
                    reject(error)
                })
        })

    },
    async WRITE_ITEMS(_,params) {
        //store 에 parameter 첫번째가 vuex 에서 사용하는 parameter(commit, dispatch, state..등등) 라서 필요한 parameter는 두번째에 넣음
        //여러가지 parameter받는 방법 WRITE_ITEMS(_, { parameter1, paramter2}}) - 두번째 parameter를 dictionary 로 받음
        if (params.type == '전체공개'){
            params.type = 'PUBLIC'
        }
        else if(params.type == '준비'){
            params.type = 'READY'
        }
        else if(params.type == '삭제'){
            params.type = 'DELETE'
        }
        else if(params.type == '내부공개'){
            params.type = 'INTERNAL_PUBLIC'
        }
        if (params.popular == true){
            params.popular = '1'
        }
        else{
            params.popular = '0'
        }
        Debug.log('WRITE_ITEMS params', params)

        await api.themeWrite(params)
    },
    async INSER_THEME_RATE(params) {
        await api.themeRate(params)
    },   
    // 221028 meej 테마 등락률 목록 조회
    GET_THEME_RATE_LIST({commit}, params) {
        return new Promise((resolve, reject) => {
            api.themeRateList(params)
                .then((response) => {
                    var data = response.data;
                    commit('setRateList', { data })
                    resolve()
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },
    // 221028 meej 종목시세 업데이트 후 테마 등락률 조회
    GET_THEME_UPDATE_RATE_LIST({commit}, params) {
        return new Promise((resolve, reject) => {
            api.themeUpdateRateList(params)
                .then((response) => {
                    var data = response.data;
                    commit('setRateList', { data })
                    resolve()
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },
}

const getters = {
    pageMax: state => parseInt(state.totalCount / 20) + 1,
}

export const theme = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
