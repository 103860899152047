<template>
    <article>
        <!-- 구분 -->
        <div class="row q-my-xs">
            <!-- TODO: 뉴스타입 설정 필요 -->
            <div class="col-12 col-sm-4 q-pa-xs">
                <i-select
                    v-model="condition.newsType"
                    :options="stockNewsType"
                    label="분류"
                    @input="
                        INIT_HISTORY(),
                        getSearchByType()
                    "
                    option-label="name"
                    option-value="value"
                />
            </div>
            <div class="col-12 col-sm-4 q-pa-xs">
                <i-stock-search @get="getStock" />
            </div>
            <div class="col-12 col-sm-4 q-pa-xs">
                <div class="flex justify-end">
                    <i-btn label="취소" @click="goListPage()" />
                </div>
            </div>
        </div>
        <!-- 목록 -->
        <i-table
            :columns="dynaColumns"
            :list="list"
            :nextKey="nextKey"
            :needCheckBox="false"
            :haveSubItem="true"
            @next-click="getList()"
        />

        <!-- <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : list.length == 0 }"
                :columns="columns" :data="list" row-key="index"
                :pagination.sync="pagination" hide-pagination no-data-label="조회되는 데이터가 없습니다."
            />
            <div v-show="list.length != 0" class="flex flex-center q-mt-md">
                <q-pagination
                    v-model="pagination.page"
                    :max="pagesNumber"
                    :max-pages="pagination.maxPages"
                    :direction-links="true"
                    :boundary-numbers="false"
                    :boundary-links="true"
                    :ellipses="false"
                />
            </div> -->
    </article>
</template>
<script>
// import Debug from '@/debug'
//import CONSTANTS from '@/constants'

import Select from '@/components/common/Select'
import StockSearch from '@/components/common/StockSearch'
import Button from '@/components/common/Button'
import Table from '@/components/common/Table'

import { FormatDate, CutDownText } from '@/util'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'History',
    components: {
        'i-stock-search': StockSearch,
        'i-btn': Button,
        'i-table': Table,
        'i-select': Select,
    },
    data() {
        return {
            // 조건
            condition: {
                newsType: '',
                stock: {},
            },
            params: { code: '', newsType: '' },
            columns: [
                {
                    name: 'stock',
                    label: '종목',
                    align: 'left',
                    field: (row) => `${row.stockName} ( ${row.stockCode} )`,
                },
                {
                    name: 'corpCls',
                    label: '구분',
                    align: 'left',
                    field: (row) => {
                        /* 
                            220422 meej this.stockNewsType -> this.stockHistoryType 변경 
                        */
                        var temp = this.stockHistoryType.find(
                            (v) => v.value == row.newsType1
                        )
                        return temp ? temp.name : row.newsType1
                    },
                },
                {
                    name: 'type',
                    label: '히스토리',
                    align: 'left',
                    // 220901 meej 글자수 조정 30 -> 70
                    field: (row) =>
                        row.REPORT_TYPE0 === undefined
                            ? CutDownText(row.content, 70)
                            : row.REPORT_TYPE0 == 'NEW'
                            ? `${row.OPINION0}/${row.PRICE} -> ${row.OPINION0}/${row.PRICE}`
                            : CutDownText(row.content, 70),
                },
                // 220506 meej 제시일자 추가
                {
                    name: 'showDate',
                    label: '제시일자',
                    align: 'left',
                    field: (row) => FormatDate(row.showDate),
                },
                {
                    name: 'modifyDate',
                    label: '수정일자',
                    align: 'left',
                    field: (row) => FormatDate(row.lastUpdateTime),
                },
            ],
        }
    },
    created() {
        this.INIT_HISTORY()
        // 220422 meej type가져올때 오류로 인한 수정
        if (this.stockNewsType == undefined) {
            this.getNewsTypeList()  
        }else {
            this.condition.newsType = this.stockNewsType[0]
            this.getList()
        }
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            nextKey: (state) => state.stock.nextKey,
            list: (state) => state.stock.history,
            code: (state) => state.stock.code,
            stockHistoryType: (state) => state.resource.StockHistoryType,
        }),
        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isEditPage',
            'isRegisterPage',
        ]),
        // 20211130 shb
        dynaColumns() {
            const colums = []
            colums.push(
                {
                    name: 'stock',
                    label: '종목',
                    align: 'left',
                    field: (row) => `${row.stockName} ( ${row.stockCode} )`,
                },
                {
                    name: 'corpCls',
                    label: '구분',
                    align: 'left',
                    field: (row) => {
                        // 220422 meej this.stockNewsType -> this.stockHistoryType 변경 
                        var temp = this.stockHistoryType.find(
                            (v) => v.value == row.newsType1
                        )
                        return temp ? temp.name : row.newsType1
                    },
                }
            )
            if (this.condition.newsType.value == 'STOCK_COMPANY_OPINION') {
                colums.push({
                    name: 'companyNm',
                    label: '증권사',
                    align: 'left',
                    field: (row) => row.companyNm,
                })
            }
            colums.push(
                {
                    name: 'type',
                    label: '히스토리',
                    align: 'left',
                    // 220901 meej 글자수 조정 30 -> 70
                    field: (row) =>
                        row.REPORT_TYPE0 === undefined
                            ? CutDownText(row.content, 70)
                            : row.REPORT_TYPE0 == 'NEW'
                            ? `${row.OPINION0}/${row.PRICE} -> ${row.OPINION0}/${row.PRICE}`
                            : CutDownText(row.content, 70),
                },
                // 220506 meej 제시일자 추가
                {
                    name: 'showDate',
                    label: '제시일자',
                    align: 'left',
                    field: (row) => FormatDate(row.showDate),
                },
                {
                    name: 'modifyDate',
                    label: '수정일자',
                    align: 'left',
                    field: (row) => FormatDate(row.lastUpdateTime),
                }
            )
            return colums
        },

        pagesNumber() {
            return ((this.list.length / this.pagination.rowsPerPage) < (this.pagination.rowsPerPage * this.pagination.maxPages)?Math.ceil(this.list.length / this.pagination.rowsPerPage):(this.list.length / this.pagination.rowsPerPage))
            //return Math.ceil(this.list.length / this.pagination.rowsPerPage)
        },
        // 220422 meej Type 오류로 인한 주석처리
        // stockNewsType() {
        //     return [{ name: '전체', value: '' }].concat(this.stockHistoryType)
        // },
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('stock', ['INIT_HISTORY']),
        ...mapActions('stock', ['GET_HISTORY']),
        // 220422 meej ASYNC_NEWSTYPE_LIST -> GET_NEWSTYPE_LIST 변경
        ...mapActions('resource', ['GET_NEWSTYPE_LIST']), 

        // 220422 meej GET_NEWSTYPE_LIST를 created 오류로 인해 메소드로 따로 뺌
        getNewsTypeList(){
            var params = {}
            params.newsType = 'StockHistoryType'

            this.GET_NEWSTYPE_LIST(params)
                .then(()=>{
                    this.stockNewsType = [{ name: '전체', value: null }]
                    this.stockNewsType.push(...this.stockHistoryType)
                    this.condition.newsType = this.stockNewsType[0]
                    this.getList()
                })
                .catch((err) => { 
                    this.showError({ vc: this, error: err })
                })
        },
        /* 
            종목코드로 히스토리 검색 (타입 유지)
            220422 meej 검색시 조건 따로 뺌  
        */
        getStock(stock) {
            this.condition.stock = { ...stock }
            
            // 221028 meej 종목 검색시 무조건 초기화 되도록 주석처리
            // if (this.params.code !== this.condition.stock.code) {
                this.INIT_HISTORY()
            // }
            this.getList()
        },
        goListPage() {
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        /* 
            타입으로 히스토리 검색 (종목코드 유지X)
            220422 meej 검색시 조건 따로 뺌  
        */
        getSearchByType(){
            // 종목코드는 무조건 null 처리
            this.condition.stock = null
            if (this.params.newsType !== this.condition.newsType.value) {
                this.INIT_HISTORY()
            }
            this.getList()
        },
        // 종목 히스토리 목록 조회하기
        getList() {
            //  220422 meej 검색 오류로 인한 주석처리 
            // 20211012 shb 추가
            // if (this.params.code !== this.condition.stock.code) {
            //     this.INIT_HISTORY()
            //     this.params.code = this.condition.stock.code
            // }
            // if (this.params.newsType !== this.condition.newsType.value) {
            //     // this.params.code = ''
            //     delete this.params.code
            //     this.condition.stock.code = ''
            // }
            // if (!isEmpty(this.condition.newsType.value)) {
            //     this.params.newsType = this.condition.newsType.value
            // } else {
            //     this.params.newsType = ''

            // TODO: type 조회조건 있으면 params에 추가하기. 
            // 종목 히스토리 목록 API
            this.params.newsType = this.condition.newsType.value
            if(this.condition.stock != null){
                this.params.code = this.condition.stock.code
            }else{
                this.params.code = null
            }

            this.GET_HISTORY(this.params)
        },
        getHistory() {
            var params = {
                code: this.code,
            }
            this.GET_HISTORY(params)
        },
    },
}
</script>
