<template>
    <article>
        <div class="row">
            <q-card class="col-12 col-lg-6 q-pa-md text-left" flat bordered>
                <q-tabs
                    v-model="tab"
                    dense
                    class="text-grey"
                    active-color="primary"
                    indicator-color="primary"
                    align="justify"
                    narrow-indicator
                    @input="selectTab"
                >
                    <q-tab name="stock" label="속보 입력" />
                    <q-tab name="theme" label="테마 속보 입력" />
                    <q-tab name="news" label="뉴스 속보 입력" />
                </q-tabs>
                <q-separator />
                <q-tab-panels v-model="tab" animated class="row">
                    <q-tab-panel name="theme">
                        <div class="row q-pa-xs">
                            <div class="col-md-6 col-12 q-pa-xs">
                                <q-select
                                    v-model="theme"
                                    :options="themeOptions"
                                    option-label="name"
                                    option-value="code"
                                    use-input
                                    @filter="filterTheme"
                                    clearable
                                    dense
                                    outlined
                                >
                                    <template v-slot:before>
                                        <label class="i-label">테마선택</label>
                                    </template>
                                </q-select>
                            </div>
                            <div class="col-md-6 col-12 q-pa-xs">
                                <!-- 20231213 -->
                                <q-select
                                    ref="themeStockSelect"
                                    v-model="themeStock"
                                    :options="themeStockFilters"
                                    option-label="stockLabel"
                                    option-value="stockCode"
                                    @filter="filterThemeStock"
                                    hide-selected="true"
                                    input-debounce="0"
                                    multiple
                                    use-input
                                    use-chips
                                    dense
                                    outlined
                                >
                                    <q-tooltip
                                        anchor="top middle"
                                        self="bottom middle"
                                        :offset="[5, 5]"
                                    >
                                        한글로 검색 시, enter를 눌러주세요.
                                    </q-tooltip>
                                    <template v-slot:before>
                                        <label class="i-label">테마종목</label>
                                    </template>
                                </q-select>
                            </div>
                        </div>
                    </q-tab-panel>
                    <q-tab-panel name="stock">
                        <!-- <div class="row"> -->
                        <div class="row col-12 q-pa-xs">
                            <div class="col-4">
                                <i-stock-search
                                    @get="getStock"
                                    label="종목코드"
                                    dense
                                    ref="toCode"
                                />
                            </div>
                            <div class="flex justify-start">
                                <q-chip
                                    v-for="(stock, i) in selectedStocks"
                                    :key="'stocks' + i"
                                    outline
                                    square
                                    color="black"
                                    text-color="white"
                                    removable
                                    @remove="remove(i)"
                                    :v-bind="tempCode = stock.code"
                                >
                                    {{ stock.name }}( {{ stock.code }} )
                                </q-chip>
                                <!-- <q-chip
                                    v-for="(item, index) in selectedStocks"
                                    :key="'stock' + index"
                                    outline
                                    square
                                    color="black"
                                    text-color="white"
                                    size="xs"
                                    removable
                                    @remove="remove(index)"
                                >
                                    {{ item.name }} ( {{ item.code }} )
                                </q-chip> -->
                            </div>
                        </div>
                        <!-- <div class="col-12 col-sm-6 q-pa-xs">
                        <div class="q-pa-xs">
                            <q-select
                                v-model="theme"
                                :options="themeOptions"
                                option-label="name"
                                option-value="code"
                                label="테마선택"
                                use-input
                                @filter="filterTheme"
                                clearable
                                dense filled />
                        </div>
                        <div class="q-pa-xs">
                            <q-select
                                v-model="themeStock"
                                :options="themeStockFilters"
                                label="테마종목"
                                option-label="stockLabel"
                                option-value="stockCode"
                                @filter="filterThemeStock"
                                multiple
                                use-input
                                use-chips
                                dense filled />
                        </div>
                    </div> -->
                        <!-- </div> -->
                        <q-separator />
                        <div>
                            <div class="row">
                                <div class="col-12 col-sm-2 q-pa-xs">
                                    자주쓰는 문구
                                </div>
                                <div class="col-12 col-sm-10 q-pa-xs">
                                    <!-- 221026 meej 지정색으로 보여지도록 수정 -->
                                    <q-chip
                                        v-for="(item, index) in freqUsedTitle"
                                        :key="'title' + index"
                                        :color="item.color"
                                        text-color="white"
                                        clickable
                                        @click="setTitle(item.name)"
                                        size="11.5px"
                                    >
                                        {{ item.name }}
                                    </q-chip>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-2 q-pa-xs">
                                    옵션
                                </div>
                                <div class="col-12 col-sm-10 q-pa-xs">
                                    <!-- 221026 meej 지정색으로 보여지도록 수정 -->
                                    <q-chip
                                        v-for="(item, index) in optionTitle"
                                        :key="'title' + index"
                                        :color="item.color"
                                        text-color="white"
                                        clickable
                                        @click="add(item.name)"
                                        size="11.5px"
                                    >
                                        {{ item.name }}
                                    </q-chip>
                                </div>
                            </div>
                            <q-separator />
                            <div class="row">
                                <div class="col-12 col-sm-2 q-pa-xs">
                                    제목 선택
                                </div>
                                <div class="col-12 col-sm-10 q-pa-xs">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 q-pa-xs">
                                            <q-select
                                                v-model="upTitle"
                                                :options="upTitleOptions"
                                                label="상승"
                                                @input="title = upTitle"
                                                dense
                                            />
                                        </div>
                                        <div class="col-12 col-sm-6 q-pa-xs">
                                            <q-select
                                                v-model="downTitle"
                                                :options="downTitleOptions"
                                                label="하락"
                                                @input="title = downTitle"
                                                dense
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </q-tab-panel>
                    <q-tab-panel name="news">
                        <i-flash-type
                            ref="flashTypeRef"
                            @event-select-type="handleSelectType"
                            @event-select-stock="handleSelectStock"
                        />
                    </q-tab-panel>
                </q-tab-panels>

                <q-separator inset />
                <q-card-section>
                    <div class="row">
                        <i-input
                            inputLabel="제목"
                            v-model="title"
                            dense
                            class="full-width"
                            ref="toTitle"
                        />
                    </div>
                    <div class="row q-pt-xs">
                        <i-input
                            inputLabel="내용"
                            v-model="content"
                            dense
                            class="full-width"
                        />
                    </div>
                    <!-- //20210916 uploadMacro 없이 write 에서 newsType 구분해서 처리하게 변경 
                    <div class="row q-pt-xs" v-if="inputExcel">
                        <i-input
                            inputLabel="내용"
                            v-model="content"
                            dense
                            class="full-width"
                        />
                    </div> -->
                </q-card-section>

                <q-card-section class="row">
                    <!-- 221101 meej 테마 속보 목록 팝업 버튼 추가 -->
                    <div v-if="tab=='theme'" class="col-12 col-sm-2 q-pa-xs">
                        <q-btn
                            label="목록"
                            @click="getFlashList()"
                            color="grey-6"
                            unelevated
                            class="full-width"
                        />
                    </div>
                    <div :class="tab=='theme'? 'col-12 col-sm-5 q-pa-xs' :'col-12 col-sm-6 q-pa-xs'">
                        <i-btn
                            label="다시쓰기"
                            @click="reWrite()"
                            class="full-width"
                        />
                    </div>
                    <div :class="tab=='theme'? 'col-12 col-sm-5 q-pa-xs' :'col-12 col-sm-6 q-pa-xs'">
                        <i-btn
                            label="속보전송"
                            @click="writeFlash()"
                            class="full-width"
                        />
                    </div>
                </q-card-section>
            </q-card>
            <i-list class="col-12 col-lg-6 q-pa-md" :refreshList="refreshList"/>
        </div>
    </article>
</template>
<script>
import Button from '@/components/common/Button'
import StockSearch from '@/components/common/StockSearch'
// import Preview from '@/components/common/Preview'
import Input from '@/components/common/Input'
import List from '@/components/flash/List'
import FlashType from '@/components/flash/news-flash/FlashType'
import { mapState, mapMutations, mapActions } from 'vuex'
import debug from '../../../debug'
import { isEmpty } from '@/util'

export default {
    name: 'FullPopup',
    components: {
        'i-stock-search': StockSearch,
        // 'i-preview': Preview,
        'i-btn': Button,
        'i-list': List,
        'i-input': Input,
        'i-flash-type': FlashType,
    },
    data() {
        return {
            tempCode: '',
            tab: 'stock',
            selectedStocks: [],
            freqUsedTitle: [
                // 220207 meej 주석처리
                /*'소폭 상승세 ', '상승폭 확대 '*/
            ],
            optionTitle: [
                /* '52주 신고가',
                '신고가 경신',
                '외국계 매수 유입',
                'VI 발동',
                '나흘만에 반등',
                '닷새째 상승',
                '나흘째 상승', */
            ],
            upTitle: '',
            upTitleOptions: [
              /*  '강보합세 전환 ',
                '강세출발 ',
                '거래증가하며 강세 ',
                '거래증가하며 급등 ',
                '거래증가하며 상승세 ',
                '건조한 상승흐름 유지중 ', */
            ],
            downTitle: '',
            downTitleOptions: [
               /* '급락세 기록중 ',
                '상승폭 축소 ',
                '상한가 이탈 ',
                '약보합세 전화 ',*/
            ],
            title: '',
            content: '',
            //showPreview: false,
            theme: null,
            themeStock: [],
            themeStockList: null,
            themeStockFilters: null,
            themeOptions: [],
            newsFlashData: {},
            newsType: null,
            newsStocks: [],
            windowRef: null,
            refreshList:true
        }
    },
    created() {
        //sett 에서 문구 가져오기
        this.getSetting()
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            themeList: (state) => state.theme.items,
            stockList: (state) => state.resource.stockItems,
            sendStatus: (state) => state.flash.status,
        }),
        inputExcel() {
            return (
                this.tab === 'news'
                // 20210915 회의 중 : 뉴스타입 없는 일반 속보도 있어서 항상 content 가 보이게 수정
                //  &&
                // this.newsType !== null &&
                //     (this.newsType.value === 'MARKET_FLASH_STOCK_CHANGE_DISC' ||
                //         this.newsType.value === 'MARKET_FLASH_STOCKLOAN_SELL' ||
                //         this.newsType.value === 'MARKET_FLASH_STOCKLOAN_BUY')
            )
        },
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('flash', ['SET_STATUS', 'SET_PREVIEW']),

        ...mapActions('flash', ['WRITE_FLASH', 'SEND_FLASH', 'GET_INDEX']),
        ...mapActions('theme_stock', ['GET_THEMESTOCK_LIST']),
        ...mapActions('theme', ['GET_THEME_LIST']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions('resource', ['GET_SETTINGS']),
        getStock(stock) {
            debug.log('getStock = ', stock)
            //this.reWrite()
            if (stock.code === 'YYYYYY' || stock.code === 'KKKKKK') {
                this.title = stock.name + '지수 : '
            }
            // this.GET_INDEX({
            //         code:stock.value0
            //     })
            //     .then((data)=> {
            //         if(stock.value0 === 'YYYYYY' || stock.value0 === 'KKKKKK') {
            //         let change = Math.abs(data.change / 100);
            //         this.content = (data.change > 0?"▲":" ▼")+change + '('+(data.changeRate > 0?"+":"")+(data.changeRate / 100)+'%)'+', '+(data.currPrice / 100)+"P"
            //         } else {
            //             this.changeRate = (data.changeRate > 0?"+":"")+(data.changeRate / 100)+'%'
            //             this.title = this.changeRate
            //         }
            //     })
            this.selectedStocks.push(stock)
        },
        getSetting() {
            this.GET_SETTINGS()
                .then((res) => {
                    debug.log(res.F_FAVORITE)
                    debug.log(res.F_OPTION)
                    debug.log(res.F_TITLE_UP)
                    debug.log(res.F_TITLE_DOWN)
                    // F_FAVORITE("F_FAVORITE"),
                    // F_OPTION("F_OPTION"),
                    // F_TITLE_UP("F_TITLE_UP"),
                    // F_TITLE_DOWN("F_TITLE_DOWN");
                    if (res.F_FAVORITE !== undefined) {
                        // 221026 meej 지정색으로 보여지도록 수정 
                        this.freqUsedTitle = []
                        // this.freqUsedTitle = fav
                        res.F_FAVORITE.split(',').forEach(e => {
                            let arrO = e.split('|')
                            let objO = {
                                name: arrO[0],
                                color : arrO[1] != undefined? arrO[1] : 'teal'
                            }
                            this.freqUsedTitle.push(objO)
                        })
                    }

                    if (res.F_OPTION !== undefined)
                        // 221026 meej 지정색으로 보여지도록 수정 
                        // this.optionTitle = res.F_OPTION.split(',')
                        this.optionTitle = []
                        res.F_OPTION.split(',').forEach(e => {
                            let arrO = e.split('|')
                            let objO = {
                                name: arrO[0],
                                color : arrO[1] != undefined? arrO[1] : 'teal'
                            }
                            this.optionTitle.push(objO)
                        })
                    if (res.F_TITLE_UP !== undefined)
                        this.upTitleOptions = res.F_TITLE_UP.split(',')
                    if (res.F_TITLE_DOWN !== undefined)
                        this.downTitleOptions = res.F_TITLE_DOWN.split(',')
                })
                .catch((e) => {
                    debug.log('getSetting error = ', e)
                })
        },
        setTitle(item) {
            debug.log('setTitle = ', this.selectedStocks)
            if (this.selectedStocks.length == 0) {
                this.showMessage({
                    vc: this,
                    message: '종목코드를 먼저 입력해주세요.',
                })
                this.$refs.toCode.$el.focus() 
            } else {
                this.title = `${item} `
                this.$refs.toTitle.$el.focus() 

            }
        },
        remove(index) {
            this.selectedStocks.splice(index, 1)
        },
        reWrite() {
            this.title = ''
            this.content = ''
            this.selectedStocks = []
            this.theme = null
            this.themeStock = []
            this.newsType = null
            this.newsStocks = []
            if (this.$refs.flashTypeRef !== undefined) {
                this.$refs.flashTypeRef.parentClear()
            }
            //this.showPreview = false
        },

        writeFlash() {
            //20210916 uploadMacro 없이 write 에서 newsType 구분해서 처리하게 변경
            //(news-flash/content.vue 도 동일하게 처리)
            // if (this.inputExcel) {
            //     if (this.$refs.flashTypeRef !== undefined) {
            //         this.$refs.flashTypeRef.uploadMacro(
            //             this.newsType,
            //             this.content
            //         )
            //     }
            //     return
            // }
            
            let type = null
            let params = {}
            let stocks = ''
            if (this.tab === 'stock') {
                // 종목 속보쪽
                // 종목속보, 코스피지수, 코스닥지수 속보, 테마속보, 환율속보
                // MARKET_FLASH_KOSDAQ_INDEX=코스피 지수 속보
                // MARKET_FLASH_KOSPI_INDEX=코스닥 지수
                // MARKET_FLASH_FOREX=환율속보
                // MARKET_FLASH_STOCK=종목속보

                // 그외는 뉴스 속보
                if (isEmpty(this.title)) {
                    this.showMessage({
                        vc: this,
                        message: '제목을 입력해주세요.',
                    })
                    return
                }
                if (this.selectedStocks.length > 0) {
                    //const stock = this.selectedStocks[0]
                    
                    type = 'MARKET_FLASH_STOCK'
                    for (let i in this.selectedStocks) {
                        stocks +=
                            this.selectedStocks[i].code +
                            ':' +
                            this.selectedStocks[i].name +
                            ','
                    }
                    stocks.slice(0, -1)
                    
                } 
            } else if (this.tab === 'theme') {
                // MARKET_FLASH_THEME=테마 속보
                type = 'MARKET_FLASH_THEME'
                if (this.theme === null) {
                    this.showMessage({
                        vc: this,
                        message: '테마를 선택하세요.',
                    })
                    return
                }
                params.themeCode = this.theme.code
                for (let i in this.themeStock) {
                    stocks +=
                        this.themeStock[i].stockCode +
                        ':' +
                        this.themeStock[i].stockName +
                        ','
                }
                stocks.slice(0, -1)
            } else {
                
                if (this.title.startsWith('코스피지수') || this.title.startsWith('코스닥지수') ) {
                    type = 'MARKET_FLASH_KOSDAQ_INDEX'
                }  else if (this.title.startsWith('원/달러 환율')){
                    type = 'MARKET_FLASH_FOREX'
                }
                else {
                    //뉴스 속보--> 20210915 회의에서 뉴스타입없는 일반 속보도 있다고 나와서 들어가게 수정                
                    type = this.newsType ? this.newsType.value : 'MARKET_FLASH_TEXT'
                }
                // if (this.newsType === null) {
                //     // this.showMessage({
                //     //     vc: this,
                //     //     message: '속보를 선택하세요.',
                //     // })
                //     // return
                // }

                //type = this.newsType.value
                for (let i in this.newsStocks) {
                    stocks +=
                        this.newsStocks[i].code +
                        ':' +
                        this.newsStocks[i].name +
                        ','
                }
                stocks.slice(0, -1)

                if(type !== null &&
                (type === 'MARKET_FLASH_STOCKLOAN_SELL' ||
                    type === 'MARKET_FLASH_STOCKLOAN_BUY' ||
                    type === 'MARKET_FLASH_STOCK_CHANGE_DISC'))
                {
                    //2021.11.12 suki - parsing이 필요한 속보 
                    if (this.$refs.flashTypeRef !== undefined) {
                        this.$refs.flashTypeRef.uploadMacro(
                            this.newsType,
                            this.content,
                        )
                    }
                    return
                
                }
                
            }
            params.newsType = type
            params.title = ' ' + this.title
            params.content = this.content
            params.stocks = stocks
            this.refreshList = false
            this.WRITE_FLASH(params)
                .then((response) => {
                    debug.log('success', response)
                    // 220930 meej 종목 속보, 테마 속보 중복기사 체크
                    if(type == 'MARKET_FLASH_STOCK' || type == 'MARKET_FLASH_THEME'){
                        this.showMessageAlert(response, type)
                    }else{
                        this.showPreview()
                    }
                    this.refreshList = true
                })
                .catch((error) => {
                    this.refreshList = true
                    debug.log('error', error)
                    this.showAlert({ vc: this, message: error })

                })
        },
        // 220930 meej 종목 속보, 테마 속보 중복기사 체크
        showMessageAlert(response, type) {
            const stockNews = response.sendData.stockNewsList
            const stockMsg = '<span style="color:blue;font-weight:bold">금일 종목속보 전송됨.</span> <br/><br/>다시 전송하시겠습니까?'
            if(type == 'MARKET_FLASH_STOCK'){
                // 종목속보
                if(stockNews != undefined && stockNews.length > 0){
                    this.showAlert({ vc: this, message: stockMsg }).then(() => { this.showPreview() })
                }else{
                    this.showPreview()
                }
            } else if(type == 'MARKET_FLASH_THEME'){
                // 테마속보 : 종목 중복 되는 경우, 테마 중복 되는 경우에따라 메시지창 다르게 보여줌. 둘다 중복될경우 메시지창 두번띄움
                const themeNews = response.sendData.themeNewsList
                const themeMsg = '<span style="color:blue;font-weight:bold">금일 ['+this.theme.name+'] 테마속보 전송됨.</span> <br/><br/>다시 전송하시겠습니까?'
                if(stockNews != undefined && stockNews.length > 0){
                    this.showAlert({ vc: this, message: stockMsg })
                    .then(() => {
                        if(themeNews != undefined && themeNews.length > 0){
                            this.showAlert({ vc: this, message: themeMsg }).then(() => { this.showPreview() })
                        }else{
                            this.showPreview()
                        }
                    })
                } else {
                    if(themeNews != undefined && themeNews.length > 0){
                        this.showAlert({ vc: this, message: themeMsg }).then(() => { this.showPreview() })
                    } else { 
                        this.showPreview()
                    }
                }
            }
        },
        showPreview() {
            this.windowRef = window.open(
                `/preview/popup.htm`,
                '시황속보 미리보기',
                'width=620,height=820'
            )
            if (this.windowRef) {
                this.windowRef.addEventListener(
                    'beforeunload',
                    this.closePreview
                )
            }
            // this.reWrite() 
        },
        closePreview() {
            this.windowRef = null
            // 20211110 shb 속보 보내기 완료후 입력 값 초기화 요청 / 수정 및 추가
            if (JSON.parse(localStorage.getItem('flashStatus')) === 1) {
                this.SET_PREVIEW(null)
                this.SET_STATUS(0)
                this.title = ''
                this.content = ''
                // 220614 meej 전송완료후 테마 초기화 추가
                this.theme = ''
                this.selectedStocks = []
                this.showMessage({ vc: this, message: '전송되었습니다.' })
                this.reWrite() // 22.09.28 suye 속보전송후 입력창 리셋
            }
        },
        add(item) {
            this.title += ', ' + item
        },
        filterTheme(val, update) {
            const needle = val.toLowerCase()
            debug.log('filtertheme needle = ')
            if (
                this.themeList === undefined ||
                this.themeList === null ||
                this.themeList.length === 0
            ) {
                const params = { type: 'PUBLIC,INTERNAL_PUBLIC' }

                this.GET_THEME_LIST(params).then(() => {
                    this.themeOptions = this.themeList.filter(
                        (v) =>
                            v.name.toLowerCase().indexOf(needle) > -1 ||
                            v.code == val
                    )
                    update()
                })
            } else {
                this.themeOptions = this.themeList.filter(
                    (v) =>
                        v.name.toLowerCase().indexOf(needle) > -1 ||
                        v.code == val
                )
                update()
            }
        },
        filterThemeStock(val, update) {
            debug.log(
                'filterThemeStock ',
                this.theme.code,
                ',',
                this.themeStockList
            )
            let hasThemeStock =
                this.themeStockList !== null && this.themeStockList.length > 0
            debug.log(
                'filterThemeStock11111111 hasThemeStock = ',
                hasThemeStock
            )
            // 20231213
            const codes = val.replace(/\s+/g, '').match(/.{1,6}/g) || [];
            const invalidCodes = [];
            codes.forEach(code => {
                if (code.length === 6) {
                    const foundStock = this.themeStockList.find(stock => stock.stockCode === code);
                    if (foundStock && !this.themeStock.some(stock => stock.stockCode === code)) {
                        // 새로운 종목 추가
                        this.themeStock = [...this.themeStock, { 
                            stockCode: foundStock.stockCode,
                            stockName: foundStock.stockName,
                            stockLabel: foundStock.stockLabel // 선택된 종목 업데이트 (stockCode, stockName, stockLabel 모두 포함)
                        }];
                        this.clearInput();
                    }   
                    else {
                        invalidCodes.push(code); // 유효하지 않은 코드 추가
                    }   
                }
            });
            // 20231213
            if (invalidCodes.length > 0) {
                // 유효하지 않은 코드에 대한 경고 메시지 표시
                const invalidCodesString = invalidCodes.join(', ');
                this.showMessage({
                    vc: this,
                    message: `${invalidCodesString} 종목코드는 오타가 있습니다.`
                });
            }
            if (hasThemeStock) {
                debug.log(
                    'selectTheme ',
                    this.theme.code,
                    ',',
                    this.themeStockList[0].themeCode
                )
                hasThemeStock =
                    this.theme.code === this.themeStockList[0].themeCode
            }
            debug.log('filterThemeStock hasThemeStock = ', hasThemeStock)
            if (hasThemeStock) {
                debug.log('filterThemeStock ', val)
                debug.log('filterThemeStock ', this.themeStockList)
                this.themeStockFilters = this.themeStockList.filter(
                    (v) =>
                        v.stockCode.indexOf(val) > -1 ||
                        v.stockName.indexOf(val) > -1
                )

                update()
            } else {
                const params = {
                    themeCode: this.theme.code,
                    type: 'PUBLIC',
                }

                this.GET_THEMESTOCK_LIST(params).then((response) => {
                    this.themeStockList = response.items
                    this.themeStockList.forEach((row) => {
                        if (
                            row.stockName === undefined ||
                            row.stockName === null
                        ) {
                            row.stockName =
                                this.stockList.find(
                                    (v) => v.code === row.stockCode
                                ) == undefined
                                    ? ''
                                    : this.stockList.find(
                                          (v) => v.code === row.stockCode
                                      ).name
                        }
                        row.stockLabel =
                            row.stockName + '(' + row.stockCode + ')'
                    })
                    // 2200729 meej 테마 종목 이름으로 정렬 추가 
                    this.themeStockList.sort(function(a, b) {
                        return a.stockName < b.stockName ? -1 : a.stockName > b.stockName ? 1 : 0;
                    })
                    this.themeStockFilters = this.themeStockList
                    debug.log('this.themeStockList ', this.themeStockList)
                    update()
                })
            }
        },
        // 20231213
        clearInput() {
            this.$refs.themeStockSelect.updateInputValue(''); // 'themeStockSelect'는 q-select의 ref 이름
        },
        clearThemeStock() {
            this.themeStock = []
        },
        handleSelectType(newsType) {
            this.newsType = newsType
            if (this.newsType.value !== 'MARKET_FLASH_DAILY_REPORT') {
                this.title = this.newsType.name
            }
        },
        handleSelectStock(list) {
            this.newsStocks = list
        },
        selectTab(tab) {
            debug.log('selectTab = ', tab)
            this.reWrite()
        },
        // 221101 meej 테마 속보 목록 팝업
        getFlashList() {
            // let query = {}
            // if(this.theme != undefined && this.theme.name != null){
            //     query.themeName = this.theme.name
            // }
            const {href} = this.$router.resolve({
                path: `/newsflash/FlashListPopup.htm`,
                // query: query
            });
            this.windowRef = window.open(
                href,
                '속보 목록',
                'width=950,height=600'
            )
        }
    },
    beforeCreate() {
        const params = { type: 'PUBLIC,INTERNAL_PUBLIC' }
        this.$store.dispatch('theme/ASYNC_THEME_LIST', params)
    },
    beforeDestroy() {
        if (this.windowRef) {
            this.windowRef = null
        }
    },
    watch: {
        theme: function(val, old) {
            if (val !== old) {
                this.clearThemeStock()
            }
        },
    },
}
</script>
<style></style>
