<template>
    <section class="row q-pl-xs">
        <q-toolbar class="row q-mt-md text-left">
            <div class="q-pa-xs"><i-select v-model="type" :options="typeOptions" option-value="value" option-label="name" @input="getList" style="width:160px;"/></div>
            <div class="q-pa-xs"><i-input v-model="keyword" ref="keyword" style="width:300px;"/></div>
            <div class="q-pa-xs"><i-btn icon="search" @click="getList()"/></div>
            <div class="q-pa-xs"><i-btn label="선택삭제" @click="getDeleteChk()"/></div>
            <div class="col q-pa-xs text-right">
                <i-btn label="신규등록" @click="getView(null)"/>
            </div>
        </q-toolbar>
        <i-list class="col-6 q-pa-md" ref="issueList"/>
        <i-content class="col-6 q-pa-md" ref="issueContent"/>
    </section>
</template>
<script>
import Input from '@/components/common/Input';
import Select from '@/components/common/Select.vue';
import Button from '@/components/common/Button';
import List from '@/components/newspaper/issue/List.vue';
import Content from '@/components/newspaper/issue/Content.vue';
import { mapState, mapMutations } from 'vuex'
export default {
    name: "Issue",
    components: {
        "i-input" : Input,
        'i-select' : Select,
        'i-btn': Button,
        "i-list": List,
        'i-content': Content
    },
    data() {
        return {
            type: {name: '개장전★주요이슈 점검', value:'MARKET_BEFORE_ISSUE', flash:'MARKET_FLASH_BEFORE_ISSUE'},
            typeOptions:[
                    {name: '개장전★주요이슈 점검', value:'MARKET_BEFORE_ISSUE', flash:'MARKET_FLASH_BEFORE_ISSUE'},
                    {name: '장마감 주요이슈', value:'MARKET_AFTER_ISSUE', flash:'MARKET_FLASH_AFTER_ISSUE'}
                ],
            keyword:''
        }
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
        }),
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        getList() {
            this.$refs.issueList.getList();
        },
        getView(item) {
            this.setPageStatus(this.PAGE_STATUS.LIST)
            this.$refs.issueList.getView(item);
        },
        getDeleteChk() {
            this.$refs.issueList.getDeleteChk();
        }
    }
};

</script>
