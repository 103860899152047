<template>
 <!-- 221019 meej 테마 히스토리 등록 팝업 content : 등록만 가능 -->
    <section>
        <div class="row i-top-wrap q-pa-md">
        </div>
        <div class="row">
            <i-stock-list
                class="col-12 col-md-4 q-pa-md"
                :themeCode="isQuery? Number(query.code):theme.code"
                :upDown="upDown"
                :propsContent="propsContent"
            />
            <i-content
                class="col-12 q-pa-md col-md-8"
                :theme="theme"
                :propsContent="propsContent"
            />
        </div>

        <!-- 221025 meej 이미 입력된 내용 있을 경우 조회하는 팝업 -->
        <!-- ----- popup start ----- -->
        <div>
        <q-dialog v-model="dialog" persistent>
            <q-card>
                <q-card-section >
                    <div class="bg-grey-3 text-center" style="padding:7px">
                        <q-icon name="error_outline" color="red" size="1.7em"></q-icon>
                        <span> 금일 입력된 히스토리가 존재합니다. 불러오시겠습니까?</span>
                    </div>
                    <div class="text-center q-pa-sm">
                        <span>사용할 내용을 선택해주세요.</span>
                    </div>
                    <q-markup-table class="table-align" style="width:100%">
                        <thead>
                        <tr style="height:40px" class="bg-grey-4">
                            <th class="text-center" style="font-weight: bold; font-size: 13px;width:62px">선택</th>
                            <th class="text-left" style="font-weight: bold; font-size: 13px;width:84px">등록시간</th>
                            <th class="text-left" style="font-weight: bold; font-size: 13px;width:382px">내용</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(a, i) in checkList" :key="i">
                            <td class="text-center" >
                                <q-checkbox v-model="a.checked" size="xs" @input="handleCheckBox(a)" />
                            </td>
                            <td class="text-center">{{a.createTime}}</td>
                            <td class="text-left"><p style="margin:0px;white-space:normal;word-break:break-all;" v-html="a.content"/></td>
                        </tr>
                        </tbody>
                    </q-markup-table>
                </q-card-section>
                <q-card-actions align="center">
                    <q-btn flat color="primary" label="불러오기" @click="handleSave()" />
                    <q-btn flat color="primary" label="취소" @click="handleClose()" />
                </q-card-actions>
            </q-card>
        </q-dialog>
        </div>
        <!-- ----- popup end ----- -->
    </section>
</template>
<script>
import StockList from '@/components/theme/theme-history/StockList.vue'
import ThemeContent from '@/components/featured/theme/PopupContent.vue'

import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    name: 'ThemeHistory',
    components: {
        'i-content': ThemeContent,
        'i-stock-list': StockList,
    },
    data() {
        return {
            theme: null,
            query : {
                code : null,
                id : null
            },
            isQuery : false,
            propsContent : null,
            upDown : null,
            dialog : false, // 팝업 상태
            checkList : [],
        }
    },
    created() {
        if(this.$route.query.id != undefined){
           this.setPageStatus(this.PAGE_STATUS.POPUP)
            this.query = this.$route.query
            this.isQuery = true
            this.theme = this.query.code
        } else{
            this.isQuery = false
        }
    },
    mounted() {
        if(this.isQuery){
            this.getThemeNewsDetail(this.query.id)
        }  
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
        }),
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
        }),
        ...mapActions({
            showError: 'alert/showError',
            showMessage: 'alert/showMessage',
            showAlert: 'alert/showAlert'
        }),
        ...mapActions( 'theme_featured', ['GET_DETAIL']),
        ...mapActions( 'theme_news', ['GET_THEMENEWS_HISTORY_LIST']),

        getThemeNewsDetail() {
            let param = {}
            param.id = this.query.id

            this.GET_DETAIL(param)
            .then((el)=>{
                if(el.news.crud == "D"){
                    let param = {}
                    param.id = el.news.pid

                    this.GET_DETAIL(param)
                    .then((res)=>{
                        el.news.content = res.news.title
                        // 자동 선택 : 급등 강세 상승 -> UP | 급락 약세 하락 -> DOWN
                        var keyword = ["급등", "강세", "상승", "급락", "약세", "하락"]
                        var kwIndex = [0, 0, 0, 0, 0, 0]
                        var maxIndex = -1
                        for(var msg in keyword){
                            kwIndex[msg] = el.news.content.lastIndexOf(keyword[msg])
                            if(kwIndex[msg] == -1) continue
                            if(maxIndex == -1) {
                                maxIndex = msg
                                continue
                            } 
                            if(msg >=1 && kwIndex[maxIndex]<=kwIndex[msg]) maxIndex = msg
                        }
                        if(maxIndex == -1) this.upDown = null
                        else if(maxIndex < 3) this.upDown = "UP"
                        else if (maxIndex >= 3) this.upDown = "DOWN"
                        else this.upDown = null
                        this.propsContent = el.news
                        let params = {}
                        params.newsType = 'THEME_HISTORY'
                        params.themeCode = this.query.code
                        this.GET_THEMENEWS_HISTORY_LIST(params)
                        .then((data)=>{
                            if(data.items != null && data.items.length > 0){
                                this.checkList = []
                                data.items.forEach((row)=>{
                                    row.createTime = row.createTime.substring(8,10)+":"+row.createTime.substring(10,12)+":"+row.createTime.substring(12)
                                    row.checked = false
                                    this.checkList.push(row)
                                })
                                this.dialog = true
                            }
                        })
                    })
                }
                else if(el.news.showFlag == 0){
                    // 자동 선택 : 급등 강세 상승 -> UP | 급락 약세 하락 -> DOWN
                    var keyword = ["급등", "강세", "상승", "급락", "약세", "하락"]
                    var kwIndex = [0, 0, 0, 0, 0, 0]
                    var maxIndex = -1
                    for(var msg in keyword){
                        kwIndex[msg] = el.news.content.lastIndexOf(keyword[msg])
                        if(kwIndex[msg] == -1) continue
                        if(maxIndex == -1) {
                            maxIndex = msg
                            continue
                        } 
                        if(msg >=1 && kwIndex[maxIndex]<=kwIndex[msg]) maxIndex = msg
                    }
                    if(maxIndex == -1) this.upDown = null
                    else if(maxIndex < 3) this.upDown = "UP"
                    else if (maxIndex >= 3) this.upDown = "DOWN"
                    else this.upDown = null
                    this.propsContent = el.news
                    let params = {}
                    params.newsType = 'THEME_HISTORY'
                    params.themeCode = this.query.code
                    this.GET_THEMENEWS_HISTORY_LIST(params)
                    .then((data)=>{
                        if(data.items != null && data.items.length > 0){
                            this.checkList = []
                            data.items.forEach((row)=>{
                                row.createTime = row.createTime.substring(8,10)+":"+row.createTime.substring(10,12)+":"+row.createTime.substring(12)
                                row.checked = false
                                this.checkList.push(row)
                            })
                            this.dialog = true
                        }
                    })
                } else {
                    this.upDown = null
                    this.propsContent = el.news
                }
                
            })
        },
        // 220923 meej 팝업 close 
        handleClose() {
            this.dialog = false;
        },
        // 220923 meej 팝업 체크리스트
        handleCheckBox(item){
            if(item.checked){ 
                this.checkList.forEach((row)=>{
                    if(row.pid != item.pid) row.checked = false
                })
            }
        },
        handleSave() {
            this.checkList.forEach((row)=>{
                if(row.checked) this.propsContent = row
            })
            this.dialog = false;
        }
    },
    watch: {},
}
</script>

<style>
</style>
