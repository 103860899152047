<template>
    <article>
        <q-table flat bordered dense separator="cell" class="table" :class="{ 'no-data' : list.length == 0 }"
            :columns="columns" :data="list" row-key="RNUM" :rows-per-page-options="[0]" style="height:auto; width: 100%;"
            :v-model="pagination" hide-pagination no-data-label="조회되는 데이터가 없습니다.">
            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th v-for="col in props.cols" :key="col.name" :props="props" :style="col.style">
                        <div v-if="col.name == 'checked'"><q-checkbox v-model="allCheck" dense @input="getAllCheck()"/></div>
                        <div v-else>{{ col.label }}</div>
                    </q-th>
                </q-tr>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td v-for="col in props.cols" :key="col.name" :props="props" style="font-size: 12px;">
                        <div v-if="col.name == 'checked'"><q-checkbox v-model="props.row.checked" dense/></div>
                        <div v-else-if="col.name == 'project'">{{ props.row.project_show }} {{ col.value }}<q-btn round size="8px" color="grey" icon="assignment" style="float:right" @click="getDetailsPopup(props.row)"/></div> 
                        <div v-else-if="col.name == 'respondent'"> 
                            <div v-if="col.value ==''"><q-btn color="grey" size="8px" label="등록"   @click="getCheckPopup(props.row)"/></div>
                            <div v-else >{{ col.value }}</div>
                        </div>

                        <!-- 업무결과-->
                        <div v-else-if="col.name == 'state'"> 
                            <div v-if="props.row.state!=''">{{ col.value }}<q-btn round size="8px" color="grey" icon="assignment" style="float:right" @click="getReportPopup(props.row)"/></div>
                        </div>

                        <!-- 피드백 -->
                        <div v-else-if="col.name == 'feedback_state'">
                            <div v-if="props.row.state !=''">
                                <div v-if="col.value==''"><q-btn color="primary" size="10px" label="등록"  @click="getFeedbackPopup(props.row)"/></div>
                                <div v-else> {{ col.value }}<q-btn round size="8px" color="grey" icon="assignment" style="float:right" @click="getCheckFeedbackPopup(props.row)"/></div>
                            </div>
                        </div>
                        
                        <div v-else>{{ col.value }}</div>

                    </q-td>
                </q-tr>
            </template>
            <template v-slot:no-data="{ icon, message }"><div class="full-width row flex-center q-gutter-sm"><q-icon size="2em" :name="icon" /> <span>{{message}}</span></div></template>
        </q-table>
        <div v-show="list.length != 0" class="flex flex-center q-mt-md">
            <q-pagination
            v-model="pagination.page"
            :max="pagesNumber"
            :max-pages="pagination.maxPages"
            :direction-links="true"
            :boundary-numbers="false"
            :boundary-links="true"
            :ellipses="false"
            @click="getList()"
        />
        </div>
        <i-details ref="detailsContent"/>
        <i-report ref="reportContent"/>
        <i-feedback ref="feedbackContent"/>
        <i-chkfeedback ref="checkFeedbackContent"/>
    </article>
</template>

<script>
import CONSTANTS from '@/constants'
import { mapState, mapMutations, mapActions } from 'vuex'
import Details from '@/components/manage/member/Details.vue'
import Report from '@/components/manage/member/Report.vue'
import CheckFeedback from '@/components/manage/member/CheckFeedback.vue'
import Feedback from '@/components/manage/member/Feedback.vue'
import { FormatDate} from '@/util'
// import { ref } from 'vue';


export default {

    name: 'Work',
    components: {
        "i-details": Details,
        "i-report": Report,
        "i-chkfeedback": CheckFeedback,
        "i-feedback": Feedback,
    },

    data () {
        return {
            allCheck: false,
            department: '',
            requester: '',
            respondent: '',
            work_person: '',
            state: '',
            columns: [
                { headerStyle:'text-align:center;', style:'width:10px;', name: 'checked',  label: 'checked', align: 'center'},
                { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:100px;', name: 'urgency', label: '긴급도', align: 'center', field:(row) => row.urgency, sortable: true },
                { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:50px;', name: 'importance', label: '중요도', align: 'center', field: (row) => row.importance, sortable: true },
                { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:50px;', name: 'department', label: '부서', align: 'center', field:(row) => row.department, sortable: true },
                { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:100px;', name: 'request_date', label: '요청일', align: 'left', field:(row) =>FormatDate(row.request_date), sortable: true },
                { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:200px;', name: 'project', label: '프로젝트명', align: 'center', field:(row) => row.project, sortable: true },
                { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:100px;', name: 'requester', label: '요청자', align: 'center', field:(row) => row.requester, sortable: true },
                { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:100px;', name: 'confirm_req', label: '확인요청자', align: 'center', field:(row) => row.confirm_req, sortable: true },
                { headerStyle:'text-align:center; background-color: #F5EFE3; font-weight: 550; border:none;', style:'width:100px;', name: 'respondent', label: '확인자', align: 'center', field:(row) => row.respondent, sortable: true },
                { headerStyle:'text-align:center; background-color: #F5EFE3; font-weight: 550; border:none;', style:'width:100px;', name: 'check_date', label: '확인일자', align: 'center', field:(row) => FormatDate(row.check_date), sortable: true},
                { headerStyle:'text-align:center; background-color: #E6E7E5; font-weight: 550; border:none;', style:'width:100px;', name: 'state', label: '업무결과', align: 'center', field:(row) => row.state, sortable: true },
                { headerStyle:'text-align:center; background-color: #E6E7E5; font-weight: 550; border:none;', style:'width:100px;', name: 'result_date', label: '업무일자', align: 'center', field: (row) => FormatDate(row.result_date), sortable: true },
                { headerStyle:'text-align:center; background-color: #E6E7E5; font-weight: 550; border:none;', style:'width:100px;', name: 'work_person', label: '담당자', align: 'center', field: (row) => row.work_person, sortable: true },
                { headerStyle:'text-align:center; background-color: #A6AA9C; font-weight: 550; border:none;', style:'width:100px;', name: 'feedback_state', label: '피드백', align: 'center', field:(row) => row.feedback_state, sortable: true },
              ],
            pagination: {
                page: 1,
                rowsPerPage: CONSTANTS.LIST_COUNT_PER_PAGE,
                sortBy: 'urgency',
                nextCount: 0,
                maxPages: CONSTANTS.DEFAULT_PAGE_NUM
            }
        }
    },
    
    computed : {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
        }),
        ...mapState('manage', ['list', 'totcount']),
        pagesNumber () {
            return Math.ceil(Number(this.totcount) / Number(this.pagination.rowsPerPage));
        }
    },
    created () {
        // this.SET_REQUEST_LIST(null)
        this.getList();
    },
    methods: {
        ...mapMutations('manage', ['SET_DATA', 'INIT_DATA_VIEW', 'SET_DATA_VIEW']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        
        //리스트
        getList() {
            let param = {
                pageStr: (Number(this.pagination.page) - 1) * Number(this.pagination.rowsPerPage),
                pageSize: this.pagination.rowsPerPage,
                CALL_API_URL: '/request/list',
            }
            this.GET_CALL(param).then((data) => { 
                this.SET_DATA(data);

                const baseColumns = [
                    { headerStyle:'text-align:center;', style:'width:10px;', name: 'checked',  label: 'checked', align: 'center'},
                    { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:50px;', name: 'urgency', label: '긴급도', align: 'center', field:(row) => row.urgency, sortable: true },
                    { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:50px;', name: 'importance', label: '중요도', align: 'center', field: (row) => row.importance, sortable: true },
                    { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:50px;', name: 'department', label: '부서', align: 'center', field:(row) => row.department, sortable: true },
                    { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:100px;', name: 'request_date', label: '요청일', align: 'center', field:(row) => FormatDate(row.request_date), sortable: true},
                    { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:200px;', name: 'project', label: '프로젝트명', align: 'center', field:(row) => row.project, sortable: true },
                    { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:100px;', name: 'requester', label: '요청자', align: 'center', field:(row) => row.requester, sortable: true },
                    { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:100px;', name: 'confirm_req', label: '확인요청자', align: 'center', field:(row) => row.confirm_req, sortable: true },
                    { headerStyle:'text-align:center; background-color: #F5EFE3; font-weight: 550; border:none;', style:'width:100px;', name: 'respondent', label: '확인자', align: 'center', field:(row) => row.respondent, sortable: true },
                    { headerStyle:'text-align:center; background-color: #F5EFE3; font-weight: 550; border:none;', style:'width:100px;', name: 'check_date', label: '확인일자', align: 'center', field:(row) => FormatDate(row.check_date), sortable: true},
                    { headerStyle:'text-align:center; background-color: #E6E7E5; font-weight: 550; border:none;', style:'width:100px;', name: 'state', label: '업무결과', align: 'center', field:(row) => row.state, sortable: true },
                    { headerStyle:'text-align:center; background-color: #E6E7E5; font-weight: 550; border:none;', style:'width:100px;', name: 'result_date', label: '업무일자', align: 'center', field: (row) => FormatDate(row.result_date), sortable: true },
                    { headerStyle:'text-align:center; background-color: #E6E7E5; font-weight: 550; border:none;', style:'width:100px;', name: 'work_person', label: '담당자', align: 'center', field: (row) => row.work_person, sortable: true },
                    { headerStyle:'text-align:center; background-color: #A6AA9C; font-weight: 550; border:none;', style:'width:100px;', name: 'feedback_state', label: '피드백', align: 'center', field:(row) => row.feedback_state, sortable: true },
                ];
                this.columns = baseColumns;

            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
                
        },


        getListByCondition() {
            let param = {
                startDate:  this.$parent.startDate + "000000",
                endDate: this.$parent.endDate +"999999",
                department: this.$parent.department.value+("%"),
                requester: ("%")+this.$parent.requester.value+("%"),
                respondent: this.$parent.respondent.value+("%"),
                work_person: ("%") + this.$parent.work_person.value+("%"),
                state: this.$parent.state.value+("%"),
                keyword: ("%") + this.$parent.keyword + ("%"),
                pageStr: (Number(this.pagination.page) - 1) * Number(this.pagination.rowsPerPage),
                pageSize: this.pagination.rowsPerPage,
                CALL_API_URL: '/request/list/condition',
            }
            this.GET_CALL(param).then((data) => {
                console.log(param[0])
                this.SET_DATA(data);
                const baseColumns = [
                    { headerStyle:'text-align:center;', style:'width:10px;', name: 'checked',  label: 'checked', align: 'center'},
                    { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:50px;', name: 'urgency', label: '긴급도', align: 'center', field:(row) => row.urgency, sortable: true },
                    { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:50px;', name: 'importance', label: '중요도', align: 'center', field: (row) => row.importance, sortable: true },
                    { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:50px;', name: 'department', label: '부서', align: 'center', field:(row) => row.department, sortable: true },
                    { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:100px;', name: 'request_date', label: '요청일', align: 'center', field:(row) => FormatDate(row.request_date), sortable: true },
                    { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:200px;', name: 'project', label: '프로젝트명', align: 'center', field:(row) => row.project, sortable: true },
                    { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:100px;', name: 'requester', label: '요청자', align: 'center', field:(row) => row.requester, sortable: true },
                    { headerStyle:'text-align:center; background-color: #F7D3BA; font-weight: 550; border:none;', style:'width:100px;', name: 'confirm_req', label: '확인요청자', align: 'center', field:(row) => row.confirm_req, sortable: true },
                    { headerStyle:'text-align:center; background-color: #F5EFE3; font-weight: 550; border:none;', style:'width:100px;', name: 'respondent', label: '확인자', align: 'center', field:(row) => row.respondent, sortable: true },
                    { headerStyle:'text-align:center; background-color: #F5EFE3; font-weight: 550; border:none;', style:'width:100px;', name: 'check_date', label: '확인일자', align: 'center', field:(row) => FormatDate(row.check_date), sortable: true},
                    { headerStyle:'text-align:center; background-color: #E6E7E5; font-weight: 550; border:none;', style:'width:100px;', name: 'state', label: '업무결과', align: 'center', field:(row) => row.state, sortable: true },
                    { headerStyle:'text-align:center; background-color: #E6E7E5; font-weight: 550; border:none;', style:'width:100px;', name: 'result_date', label: '업무일자', align: 'center', field: (row) => FormatDate(row.result_date), sortable: true },
                    { headerStyle:'text-align:center; background-color: #E6E7E5; font-weight: 550; border:none;', style:'width:100px;', name: 'work_person', label: '담당자', align: 'center', field: (row) => row.work_person, sortable: true },
                    { headerStyle:'text-align:center; background-color: #A6AA9C; font-weight: 550; border:none;', style:'width:100px;', name: 'feedback_state', label: '피드백', align: 'center', field:(row) => row.feedback_state, sortable: true },
                ];
                this.columns = baseColumns;
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },

        getReportPopup(item) {
            this.$refs.reportContent.getReportPopup(item);
        },

           //선택삭제
           getDeleteChk() {
            var iCnt = 0;
            var ds_list = [];
            var req_list = [];
            this.list.forEach(row => {
                if(row.checked){
                    iCnt++;
                    ds_list.push(
                        row['progress_id']);
                    req_list.push(
                    row['request_id']);
                }
            });
            if(iCnt == 0){
                this.showMessage({ vc:this, message:'체크항목을 선택하세요.' });
                return;
            }
            this.showDeleteAlert({ vc:this, message: iCnt+'건 <span style="color:red;">선택삭제</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    ds_list,
                    req_list,
                    'CALL_API_URL': '/request/delete' 
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'선택삭제 되었습니다.' });
                    this.allCheck = false;
                    this.$parent.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },

        getChkId() {
            // var iCnt = 0;
            var ds_list = [];
            this.list.forEach(row => {
                if(row.checked){
                    // iCnt++;
                    ds_list.push(
                        row['request_id']);
                }
            });
            return ds_list;
        },


        //전체선택
        getAllCheck(){
            this.list.forEach(row => {
                row.checked = this.allCheck;
            });
       },

        getDetailsPopup(item) {  
            this.$refs.detailsContent.getDetailsPopup(item)
        },

        getFeedbackPopup(item) {
            this.$refs.feedbackContent.getFeedbackPopup(item);
        },
        

        getCheckPopup(item) {
                this.showAlert({ vc:this, message:'확인자를 <span style="color:#1976D2;">등록</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    'CALL_API_URL': '/request/check',
                    'request_id': item.request_id
                }

                this.GET_CALL(param).then(() => { 
                    this.showMessage({ vc:this, message:'등록되었습니다.' });
                    this.$parent.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });

        },
        

        getCheckFeedbackPopup(item) {
            this.$refs.checkFeedbackContent.getCheckFeedbackPopup(item);
        },

     
    }
}
</script>

<style>
</style>