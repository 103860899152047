<template>
    <section>
        <div class="row i-top-wrap q-pa-md">
            <div class="col-12 col-md-2">
                <!-- 불필요한 분류 주석처리 -->
                <!-- <i-select
                    v-model="type"
                    :options="typeOptions"
                    label="분류"
                    option-label="name0"
                    option-value="value0"
                    @input="selectType"
                /> -->
                <div class="q-pa-xs">
                    <q-input v-model="searchDate" bg-teal text-white outlined dense type="date" @input="getDateTheme"/>
                </div>
            </div>
            <div class="col-12 col-md-1">
                <i-field check v-model="searchAll" label="테마 전체" @input="getAllTheme"/>
            </div>
            <div class="col-12 col-md-7">
                <!-- 
                    220428 meej 테마히스토리에서는 삭제된 테마도 보여야함
                    220825 meej 삭제된 테마 안보이게 처리
                 -->
                <i-theme-search
                    :inputData="theme"
                    @get="selectTheme"
                    inputLabel="테마"
                    :filterD="true"
                    ref="themeSearch"
                />
            </div>
            
            <div class="col-12 col-md-2 text-right">
                <i-btn
                    v-show="isListPage"
                    label="신규"
                    @click="goRegisterPage()"
                />
                <i-btn
                    v-show="!isListPage"
                    label="취소"
                    @click="goListPage()"
                />
            </div>
        </div>
        <div class="row" style="height: 800px;">
            <i-list
                v-if="isListPage || isDetailPage"
                class="col-12 q-pa-md"
                :class="[isListPage ? 'col-md-12' : 'col-md-6']"
                :type="type"
                :initTheme="theme"
                @select-theme="selectTheme"
            />
            <i-stock-list
                v-if="isRegisterPage || isEditPage"
                class="col-12 col-md-4 q-pa-md"
                :themeCode="contentThemeCode != null? contentThemeCode : theme.code"
            />
            <i-content
                v-if="!isListPage"
                class="col-12 q-pa-md"
                :type="type"
                :theme="theme"
                :class="[isDetailPage ? 'col-md-6' : 'col-md-8']"
                @set-content-theme="setContentTheme"
                @get-history-list="getThemeHistory"
                @get-themenews-list="getThemeNewsList"
            />
        </div>
    </section>
</template>
<script>
import Debug from '@/debug'

import ThemeSearch from '@/components/common/ThemeSearch.vue'
import Button from '@/components/common/Button'
// import Select from '@/components/common/Select'
import StockList from '@/components/theme/theme-history/StockList.vue'
import ThemeList from '@/components/theme/theme-history/List.vue'
import ThemeContent from '@/components/theme/theme-history/Content.vue'
import Field from '@/components/common/Field'
import { today } from '@/util'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'ThemeHistory',
    components: {
        'i-list': ThemeList,
        'i-content': ThemeContent,
        'i-stock-list': StockList,
        'i-theme-search': ThemeSearch,
        'i-btn': Button,
        // 'i-select': Select,
        'i-field': Field
    },
    data() {
        return {
            theme: null,
            type: { name0: '전체', value0: '' },
            searchDate : today("YYYY-MM-DD"),
            searchAll : true,
            contentThemeCode : null,
        }
    },
    created() {
        Debug.log('ThemeHistroy created page status = ', this.pageStatus)
        // this.theme = this.selectedTheme
        this.INIT_LIST()
        this.getThemeHistory()
    },
    mounted() {
        // Debug.log('ThemeHistroy mounted page status = ', this.pageStatus)
        // if (!this.theme) {
        //     const message = '테마를 선택하세요'

        //     this.showMessage({ vc: this, message: message })
        //     this.setPageStatus(this.PAGE_STATUS.LIST)
        // }
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            selectedTheme: (state) => state.theme.theme,
            themeType: (state) => state.resource.themeType,
            themeNews: (state) => state.theme_news.item,
        }),
        ...mapGetters({
            isListPage: 'page/isListPage',
            isEditPage: 'page/isEditPage',
            isRegisterPage: 'page/isRegisterPage',
            isDetailPage: 'page/isDetailPage',
        }),

        typeOptions() {
            return [{ name0: '전체', value0: '' }].concat(this.themeType)
        },
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
        }),
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('theme_news', ['INIT_LIST', 'SET_ITEM']),

        ...mapActions({
            showError: 'alert/showError',
            showMessage: 'alert/showMessage',
        }),
        ...mapActions('theme', ['GET_THEME_LIST']),
        ...mapActions('theme_news', ['ASYNC_THEMENEWS_LIST', 'GET_THEMENEWS_LIST']),

        goListPage() {
            this.setPageStatus(this.PAGE_STATUS.LIST)
            this.INIT_LIST()
            this.getThemeHistory()
        },
        goRegisterPage() {
            console.log()
            if (!this.theme) {
                const message = '테마를 선택하세요'
                this.showError({ vc: this, error: message })
            } else {
                this.SET_ITEM(null)
                this.setPageStatus(this.PAGE_STATUS.REGISTER)
            }
        },
        selectTheme(val) {
            Debug.log('ThemeHistory selectTheme theme = ', val)
            if (!this.isRegisterPage) {
                this.setPageStatus(this.PAGE_STATUS.LIST)
            }

            this.INIT_LIST()

            if (val != null) {
                this.searchAll = false
                this.searchDate = null
                this.getThemeHistory(val.code)
            }

            this.$emit('select-theme', val)
            this.theme = val
        },
        selectType() {
            this.theme = null
            let params = { type: this.type.value0 }
            this.GET_THEME_LIST(params).then(() => {
                this.themeOptions = this.themeList
            })
        },
        // 테마히스토리 조회
        getThemeHistory(code) {
            // if (code === undefined && this.theme === null) {
            //     return
            // } else if (code === undefined && this.theme !== null) {
            //     code = this.theme.code
            // }

            if (code === 'all' || this.searchAll) {
                code = -1
            } else if (code === undefined && this.theme !== null) {
                code = this.theme.code
            }
            Debug.log('code = ', code, ', theme = ', this.theme, ', date = ', this.searchDate)

            let params = {}
            params.newsType = 'THEME_HISTORY'
            params.themeCode = code
            if(this.searchDate != "" && this.searchDate != null){
                params.searchDate = this.searchDate.replaceAll("-","")
            }
            Debug.log('params = ', params)

            this.ASYNC_THEMENEWS_LIST(params)
        },
        getAllTheme(val) {
            if(val){
                if(this.searchDate == "" || this.searchDate == null){
                    this.searchDate = today("YYYY-MM-DD")
                }
                this.theme = null
                this.$refs.themeSearch.removeSearched();
            } else {
                if(this.theme == null) {
                    const message = '테마를 선택하세요'
                    this.showError({ vc: this, error: message })
                    this.searchAll = true
                    return
                }
            }
            Debug.log('getAllTheme')
            Debug.log('theme = ', this.theme, ', date = ', this.searchDate)
            this.INIT_LIST()
            this.getThemeHistory('all')
        },
        getDateTheme() {
            Debug.log('getDateTheme')
            Debug.log('theme = ', this.theme, ', date = ', this.searchDate)
            if((this.searchDate == "" || this.searchDate == null) && this.searchAll) {
                const message = '테마를 선택하거나 날짜를 선택해주세요.'
                this.showError({ vc: this, error: message })
                return
            }
            this.INIT_LIST()
            this.getThemeHistory()
        },
        setContentTheme(item) {
            // let theme = {
            //     code : item.themeCode,
            //     name : item.themeName
            // }
            // this.theme = theme
            this.contentThemeCode = item.themeCode
            this.setPageStatus(this.PAGE_STATUS.EDIT)
        },
        getThemeNewsList(item){
            let code = item.themeCode
            if (code === 'all' || this.searchAll) {
                code = -1
            } else if (code === undefined && this.theme !== null) {
                code = this.theme.code
            }
            Debug.log('code = ', code, ', theme = ', this.theme, ', date = ', this.searchDate)

            let param = {}
            param.newsType = 'THEME_HISTORY'
            param.themeCode = code
            if(this.searchDate != "" && this.searchDate != null){
                param.searchDate = this.searchDate.replaceAll("-","")
            }
            Debug.log('param = ', param)
            this.GET_THEMENEWS_LIST(param).then((el)=>{
                if(item.id != undefined && item.id != null){
                    el.items.forEach((row)=>{
                        if(item.id == row.id){
                            row.CHART = row.CHART == 1 ? true : false
                            this.SET_ITEM(row)
                        }
                    })
                }  else{
                    el.items[0].CHART = el.items[0].CHART == 1 ? true : false
                    this.SET_ITEM(el.items[0])
                }
                this.INIT_LIST()
                this.getThemeHistory()
                this.setPageStatus(this.PAGE_STATUS.DETAIL)
            })
        }
    },
    watch: {},
}
</script>

<style>
/* common */

/* xs */
@media only screen and (max-width: 599px) {
}

/* sm */
@media only screen and (max-width: 1023px) {
}

/* md */
@media only screen and (max-width: 1439px) {
}

/* lg */
@media only screen and (max-width: 1919px) {
}

/* xl */
@media only screen and (min-width: 1920px) {
}
</style>
