<template>
    <div id="home" class="full">
        <q-layout view="hHh Lpr lff" container class="shadow-2 bg-grey-2">
            <!-- Header -->
            <q-header elevated class="bg-dark shadow-2">
                <q-toolbar>
                    <q-btn
                        v-if="isLoggedIn"
                        flat
                        @click="menuDrawer = !menuDrawer"
                        dense
                        icon="menu"
                    />
                    <q-btn
                        v-if="isLoggedIn"
                        flat
                        @click="goMain()"
                        dense
                        icon="home"
                        label="인포스탁"
                        class="q-ml-sm"
                    />
                    <!-- 메뉴 path -->
                    <q-breadcrumbs
                        v-if="isLoggedIn && menuLabel && subMenuLabel"
                        class="text-white q-px-sm"
                        active-color="white"
                    >
                        <q-breadcrumbs-el :label="menuLabel" />
                        <q-breadcrumbs-el :label="subMenuLabel" />
                    </q-breadcrumbs>
                    <q-space />
                    <div>{{ test_version }}</div>
                    <div v-if="isLoggedIn" class="flex">
                        <div id="user-name">
                            <q-chip
                                color="blue"
                                class="text-white"
                                style="font-size: 1rem;"
                            >
                                {{ team }} / {{ role }} / {{ username }}
                            </q-chip>
                        </div>
                        <q-btn flat class="text-white" @click="goMyInfo">
                            <q-icon name="portrait" />
                        </q-btn>
                        <q-btn
                            flat
                            label="로그아웃"
                            class="text-white"
                            @click="logout"
                        />
                    </div>
                    <div v-else>
                        <q-btn
                            flat
                            label="로그인"
                            class="text-white"
                            @click="goLogin"
                        />
                    </div>
                </q-toolbar>
            </q-header>
            <!-- 메뉴 -->
            <q-drawer
                v-model="menuDrawer"
                :width="240"
                :breakpoint="500"
                content-class="bg-grey-1"
            >
                <q-scroll-area class="fit">
                    <q-list bordered>
                        <q-expansion-item
                            dense
                            v-for="(menu, index) in menuList"
                            :key="index"
                            themeSummary
                            :icon="menu.icon"
                            :label="menu.label"
                            :default-opened="menu.open"
                            expand-icon-class="text-black"
                            header-class="bg-grey-4 text-black"
                        >
                            <q-item
                                dense
                                v-for="(item, subIndex) in menu.sub"
                                :key="subIndex"
                                :active="
                                    $route.path ===
                                        getPath(menu.path, item.route)
                                "
                                active-class="bg-primary text-white"
                                clickable
                                @click="goNext(menu, item)"
                            >
                                <q-item-section>{{
                                    item.label
                                }}</q-item-section>
                            </q-item>
                        </q-expansion-item>
                    </q-list>
                </q-scroll-area>
            </q-drawer>
            <!-- 컨텐츠 -->
            <q-page-container>
                <!-- <q-page> -->
                    <router-view :key="$route.fullPath" />
                <!-- </q-page> -->
            </q-page-container>
        </q-layout>
    </div>
</template>

<script>
import Debug from '@/debug'
import CONSTANTS from '@/constants'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Home',
    data() {
        return {
            menuDrawer: true,
            menuList: CONSTANTS.MENU,
            menuLabel: '',
            subMenuLabel: '',
            test_version: '1.0.5',
        }
    },
    beforeCreate() {
        Debug.log('Home beforeCreate!!!')        
    },
    created() {
        this.setIsMobile(this.isMobile)

        // 화면 resize 이벤트 등록
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        console.log(this.isLoggedIn)
        if (!this.isLoggedIn) {
            this.menuDrawer = false
        } else {
            this.initMenu()
            // 220811 meej IR, 외부 일경우 처음에 메뉴 안보이게 설정
            if(this.team == CONSTANTS.TEAM.IR && this.role == CONSTANTS.ROLE.CU){
                this.menuDrawer = false
            }else{
                this.menuDrawer = true
            }
        }
    },
    beforeDestroy() {
        // 화면 resize 이벤트 해제
        window.removeEventListener('resize', this.handleResize)
    },
    computed: {
        ...mapState({
            username: (state) => state.session.username,
            team: (state) => state.session.team,
            role: (state) => state.session.role,
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
        }),
        ...mapGetters({
            isPopupPage: 'page/isPopupPage',
            isLoggedIn: 'session/isLoggedIn',
        }),

        isMobile() {
            return this.$q.screen.xs || this.$q.screen.sm
        },
    },
    methods: {
        ...mapMutations('page', ['setListPage', 'setIsMobile','setPopupPage']),
        ...mapActions({
            logout: 'session/logout_action',
        }),

        doLogout: function() {
            this.logout(true)
        },
        getPath(path, route) {
            return path + '/' + route
        },
        goNext(menu, item) {
            this.menuLabel = menu.label
            this.subMenuLabel = item.label
            if (item.popup) {
                //시황속보 팝업 !
                window.open(
                    `/newsflash/popup.htm`,
                    '시황속보 입력창',
                    'width=620,height=820'
                )
            }

            var path = { path: menu.path + '/' + item.route }
            Debug.log('goNews =', path)
            this.$router.push(path).catch((err) => {
                //                    Debug.log(err);
                this.$router.push({ path: '/nonePage.htm' }).then(() => {   //빈페이지 호출 후 갱신
                    this.$router.push(path);
                });
                this.setListPage()
                if (
                    err.name !== 'NavigationDuplicated' &&
                    !err.message.includes(
                        'Avoided redundant navigation to current location'
                    )
                ) {
                    Debug.log(err)
                }
            })
        },
        goPath(menu) {
            const path = menu.path
            this.$router.push(menu.path).catch((err) => {
                //                    Debug.log(err);
                this.$router.push({ path: '/nonePage.htm' }).then(() => {   //빈페이지 호출 후 갱신
                    this.$router.push(path);
                });
                this.setListPage()
                if (
                    err.name !== 'NavigationDuplicated' &&
                    !err.message.includes(
                        'Avoided redundant navigation to current location'
                    )
                ) {
                    Debug.log(err)
                }
            })
        },
        handleResize() {
            this.setIsMobile(this.isMobile)
        },

        goMyInfo() {
            this.$router.push({ name: 'MyInfo' }).catch(() => {})
        },
        goLogin() {
            this.$router.push({ name: 'Login' }).catch(() => {})
        },
        goMain() {
            /* 220708 meej MARKETING, IR 에 맞는 path 추가 */
            if(this.role === CONSTANTS.ROLE.CU) {
                if(this.team === CONSTANTS.TEAM.MARKETING) {
                    const adHome = CONSTANTS.AD_MENU
                    this.goPath(adHome[0])
                } else if(this.team === CONSTANTS.TEAM.IR) {
                    const prHome = CONSTANTS.PR_MENU
                    this.goPath(prHome[0])
                } 
            } else {
                this.$router.push({ name: 'Main' }).catch(() => {})
            }
        },
        initMenu(){
            Debug.log('Home initMenu!!!')        
            if(this.role === CONSTANTS.ROLE.CU) {
                if(this.team === CONSTANTS.TEAM.MARKETING) {
                    this.menuList = CONSTANTS.AD_MENU
                } else if(this.team === CONSTANTS.TEAM.IR) {
                    this.menuList = CONSTANTS.PR_MENU
                }
                this.goPath(this.menuList[0])
            } else if(this.role === CONSTANTS.ROLE.OP){
                // 220708 meej '마케팅팀', '직원' 일 경우 일부 카테고리만 노출
                if(this.team === CONSTANTS.TEAM.MARKETING) {
                    this.menuList = CONSTANTS.MK_MENU
                }
            } else {
                this.menuList = CONSTANTS.MENU
            }
        }
    },
    watch: {
        role () {
            Debug.log('watch!!!!!!!', this.isLoggedIn)
            if(this.isLoggedIn) {
                this.initMenu()
                // 220811 meej IR, 외부 일경우 처음에 메뉴 안보이게 설정
                if(this.team == CONSTANTS.TEAM.IR && this.role == CONSTANTS.ROLE.CU){
                    this.menuDrawer = false
                }else{
                    this.menuDrawer = true
                }
            } else {
                this.menuDrawer = false
            }
             
        },
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100vh;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0;
}
.full {
    height: 100% !important;
    width: 100% !important;
}
p {
    word-wrap: break-word;
}
.q-page-container {
    min-width: 1440px;
}
.i-text-small {
    font-size: 12px;
}
.i-label {
    width: 80px;
    font-size: 12px;
}
.i-wrap {
    height: calc(100vh - 50px) !important;
}

.i-content-wrap {
    height: 75vh !important;
}

/* 20210723 shb 시간외 단일가 특이종목 좌측 content 관련 추가  */
article.twincon .i-content-wrap.q-scrollarea {
    height: 30vh !important;
}
div.sn-date .q-field__before {
    padding-right: 0;
}

div.sn-date .q-field {
    padding: 4px 4px 4px 0px;
}
/* ================================================ */

/* q-table 관련 css */
.i-table {
    height: 75vh;
}
.i-table.no-data {
    height: auto;
}
.i-table .q-table__top,
.i-table .q-table__bottom,
.i-table thead tr:first-child th {
    background-color: grey;
    color: white;
}
.i-table thead tr th {
    position: sticky;
    z-index: 1;
}
.i-table thead tr:first-child th {
    top: 0;
}
.i-table.q-table--loading thead tr:last-child th {
    top: 48px;
}

.i-small-table .q-table__top,
.i-small-table .q-table__bottom,
.i-small-table thead tr:first-child th {
    background-color: grey;
}
</style>
