<template>
    <section>
        <q-toolbar class="i-top-wrap q-pt-md q-px-md">
            
            <div class="row col-12">
                <div class="col q-px-md row flex">
                    <i-select inputLabel="구분" v-model="type" :options="typeOptions"
                    option-label="name" option-value="value"/>
                    <div class="on-right">
                        <p> 속보는 당일속보만 발송됩니다. 보낼 속보를 선택하신 후 속보전송하세요.</p>
                    </div>
                </div>
                
                <!-- 속보전송으로 수정  -->
                <div class="q-px-md row col flex justify-end " > 
                    <div class="col">
                        <i-datetime
                            v-model="sendTime"
                            date
                            dateLabel="전송일자"
                            time
                            timeLabel="전송시간"
                            @setCurrentTime="currentTime = $event"
                        />
                        <div class="row">
                            <i-stock-search @get="getStock" label="종목코드" />
                            <div class="flex justify-start">
                                <q-chip v-for="(item,index) in selectedStocks" :key="'stock'+index"
                                    outline square color="black" text-color="white" size="sm" removable @remove="remove(index)">
                                    {{ item.name }} ( {{ item.code }} )
                                </q-chip>
                            </div>
                        </div>
                            
                        
                    </div>
                    <div class="q-px-md">
                        <i-btn
                        label="속보전송"
                        @click="makeFlash()"
                        />
                    </div>
                </div>
                
            </div>
        </q-toolbar>
        <div class="row">
             <i-list v-if="isListPage || isDetailPage"
                class="col-6 q-px-md" :newsType="type" />
            <i-resource v-if="isRegisterPage || isEditPage" class="col-4 q-pa-md"  />
              <!-- 220527 meej 주요종목공시 버튼 label 변경 -->
            <i-content class="q-px-md"
                        :class="[isRegisterPage || isEditPage? 'col-8':'col-6']" :newsType="type"  :disclosure="true"/>
        </div>
    </section>
</template>
<script>
import {morning} from '@/util'

import Select from '@/components/common/Select.vue'
import List from '@/components/newspaper/List.vue'
import Content from '@/components/newspaper/Content.vue'
import Resource from '@/components/common/Resource.vue'
import DateTime from '@/components/common/DateTime'
import StockSearch from '@/components/common/StockSearch'
import Button from '@/components/common/Button'
import Debug from '@/debug'

import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'

//market_news
export default {
    name: 'MainDiscl',
    components: {
        'i-datetime': DateTime,
        'i-list' : List,
        'i-content' : Content,
        'i-resource' : Resource,
        'i-select' : Select,
        'i-stock-search': StockSearch,
        'i-btn': Button,
    },
    data () {
        return {
            type: null,
            // typeOptions:[ {name: '장중 주요 종목 공시', value:'MARKET_STOCK_DISC_MIDDLE', flashType:'MARKET_FLASH_STOCK_DISC_MIDDLE'},
            //             {name: '전일 장마감 후 주요 종목 공시', value:'MARKET_STOCK_DISC_CLOSED', flashType:'MARKET_FLASH_STOCK_DISC_CLOSED'},
            // ],
            typeOptions:[
                {name: '장중 주요 종목 공시', value:'MARKET_STOCK_DISC_MIDDLE', flashType:'MARKET_FLASH_STOCK_DISC_MIDDLE'},
                {name: '전일 장마감 후 주요 종목 공시', value:'MARKET_STOCK_DISC_CLOSED', flashType:'MARKET_FLASH_STOCK_DISC_CLOSED'}
            ],
            newsFlashData: {},
            sendTime: null,
            selectedStocks: [],
            currentTime: '', // 현재시간 받아옴
        }
    },
    created() {
        this.type = (morning()?this.typeOptions[1]:this.typeOptions[0])
        console.log('this.type: ', this.type);
        this.setPageStatus(this.PAGE_STATUS.LIST)
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
            flashStatus: (state) => state.flash.status,
        }),
        ...mapGetters( 'page', ['isListPage', 'isDetailPage', 'isEditPage', 'isRegisterPage']),
    },
    methods: {
        ...mapMutations( 'page', ['setPageStatus']),
        ...mapActions('flash', ['WRITE_FLASH','SET_PREVIEW']),
        ...mapActions('alert', ['showAlert']),

        getStock(stock) {
            if(stock.code0 === 'YYYYYY' || stock.code0 === 'KKKKKK') {
                this.title = stock.name + '지수'
            }
            this.selectedStocks.push(stock)
            this.$emit('event-select-stock', this.selectedStocks)
        },
        remove(index) {
            this.selectedStocks.splice(index,1)
            this.$emit('event-select-stock', this.selectedStocks)
        },
        makeFlash() {

            
            let params = {}
            params.newsType = this.type.flashType

            console.log('params.newsType: ', params.newsType);
            let stocks = ''
            Debug.log('makeFlash stock = ', this.selectedStocks)
            for (let i in this.selectedStocks) {
                stocks +=
                    this.selectedStocks[i].code +
                    ':' +
                    this.selectedStocks[i].name +
                    ','
            }
            stocks.slice(0, -1)
            params.stocks = stocks
            params.sendTime = this.sendTime


            // 22.09.23 suye 속보 전송시간 < 현재시간일 경우 현재시간으로 변경 
            if(this.currentTime && this.currentTime.length > 13 && this.currentTime > this.sendTime) {
                // console.log('currentTime? ', this.currentTime, 'this.sendTime? ', this.sendTime)  
                const text = '전송시간이 과거입니다. 현재시간 ' + this.currentTime.substr(8,2) + ':' + this.currentTime.substr(10,2) + '으로 설정합니다.' 
                this.showAlert({ vc: this, message: text }).then(()=>{
                    // console.log('confirmed');
                    this.sendTime = this.currentTime 
                    params.sendTime = this.sendTime

                    this.WRITE_FLASH(params)
                    .then((res) => {
                        Debug.log('data = ',res)
                        this.goFlashPage();
                    })
                    .catch((err) => {
                        Debug.log('error = ', err)
                        this.showError({vc:this, error:err})
                    })

                })      

            } else {

                this.WRITE_FLASH(params)
                .then((res) => {
                    Debug.log('data = ',res)
                    this.goFlashPage();
                })
                .catch((err) => {
                    Debug.log('error = ', err)
                    this.showError({vc:this, error:err})
                })

            }

            // let params = {}
            // params.newsType = this.type.flashType

            // console.log('params.newsType: ', params.newsType);
            // let stocks = ''
            // Debug.log('makeFlash stock = ', this.selectedStocks)
            // for (let i in this.selectedStocks) {
            //     stocks +=
            //         this.selectedStocks[i].code +
            //         ':' +
            //         this.selectedStocks[i].name +
            //         ','
            // }
            // stocks.slice(0, -1)
            // params.stocks = stocks
            // params.sendTime = this.sendTime
            // //params.content = this.item.content

            // this.WRITE_FLASH(params)
            // .then((res) => {
            //     Debug.log('data = ',res)
            //     this.goFlashPage();
            // })
            // .catch((err) => {
            //     Debug.log('error = ', err)
            //     this.showError({vc:this, error:err})
            // })
        },
        goFlashPage() {
            this.windowRef = window.open(
                `/preview/popup.htm`,
                '시황속보 미리보기',
                'width=620,height=820'
            )
            if(this.windowRef) {
                this.windowRef.addEventListener('beforeunload', this.closePreview)
            }
        },
        closePreview() {
            this.windowRef = null
            if (this.isSuccess) {
                this.SET_STATUS(0)
                this.showMessage({ vc: this, message: '전송되었습니다.' })
                
                this.doUpdateWeb()
                Debug.log('closePrevice success')
            }
        },

    }

}
</script>


