<template>
    <ckeditor
        :value="value"
        :config="editorConfig"
        :height="height"
        @input="inputHtml"
    >
    </ckeditor>
</template>
<script>
export default {
    name: 'Editor',
    props: {
        value: {
            required: true,
        },
        height: {
            type: Number,
            default: 250,
        },
    },
    data() {
        return {
            editorData: '',
            editorConfig: {
                height: this.height,
                enterMode: 2,
                forceEnterMode: false,
                fillEmptyBlocks: false,
                toolbarGroups: [
                    { name: 'tools', groups: ['tools'] },
                    {
                        name: 'document',
                        groups: ['mode', 'document', 'doctools'],
                    },
                    { name: 'clipboard', groups: ['clipboard', 'undo'] },
                    {
                        name: 'editing',
                        groups: [
                            'find',
                            'selection',
                            'spellchecker',
                            'editing',
                        ],
                    },
                    { name: 'forms', groups: ['forms'] },
                    {
                        name: 'paragraph',
                        groups: [
                            'list',
                            'indent',
                            'blocks',
                            'align',
                            'bidi',
                            'paragraph',
                        ],
                    },
                    '/',
                    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                    { name: 'colors', groups: ['colors'] },
                    { name: 'styles', groups: ['styles'] },
                    { name: 'links', groups: ['links'] },
                    { name: 'insert', groups: ['insert'] },
                    { name: 'others', groups: ['others'] },
                    { name: 'about', groups: ['about'] },
                ],
                removeButtons:
                    'Save,NewPage,Scayt,SelectAll,Form,Radio,Checkbox,TextField,Textarea,Select,Button,ImageButton,HiddenField,BidiLtr,BidiRtl,Language,Anchor,Flash,Smiley,SpecialChar,Iframe,About,Styles',

                extraPlugins:
                    'balloontoolbar, balloonpanel, font, preview, print, removeformat, showblocks, find, toolbar, templates, justify, colorbutton, copyformatting, pagebreak, div',
            },
        }
    },

    methods: {
        inputHtml(val) {
            this.$emit('input-html', val)
        },
    },
}
</script>
