<template>
    <article>
        <q-table
            flat
            bordered
            dense
            separator="cell"
            class="i-table"
            :class="{ 'no-data': list.length == 0 }"
            :columns="columns"
            :data="list"
            row-key="RNUM"
            :rows-per-page-options="[0]"
            style="height:auto;"
            hide-pagination
            no-data-label="조회되는 데이터가 없습니다."
        >
            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                        :style="col.style"
                    >
                        <div v-if="col.name == 'checked'">
                            <q-checkbox
                                v-model="allCheck"
                                dense
                                @input="getAllCheck()"
                            />
                        </div>
                        <div v-else>{{ col.label }}</div>
                    </q-th>
                </q-tr>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                        @click="getView(props.row)"
                        style="cursor:pointer;"
                    >
                        <div v-if="col.name == 'checked'">
                            <q-checkbox v-model="props.row.checked" dense />
                        </div>
                        <div
                            v-else-if="col.name == 'title'"
                            v-html="col.value"
                        ></div>
                        <div v-else>{{ col.value }}</div>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:no-data="{ icon, message }"
                ><div class="full-width row flex-center q-gutter-sm">
                    <q-icon size="2em" :name="icon" />
                    <span>{{ message }}</span>
                </div></template
            >
        </q-table>
        <div v-show="list.length != 0" class="flex flex-center q-mt-md">
            <q-pagination
                v-model="pagination.page"
                :max="pagesNumber"
                :max-pages="pagination.maxPages"
                :direction-links="true"
                :boundary-numbers="false"
                :boundary-links="true"
                :ellipses="false"
                @click="getList()"
            />
        </div>
    </article>
</template>
<script>
import CONSTANTS from '@/constants'
import { FormatDate } from '@/util'
// CutDownText
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    data() {
        return {
            allCheck: false,
            columns: [
                {
                    headerStyle: 'text-align:center;',
                    name: 'createTime',
                    label: '등록일자',
                    align: 'center',
                    field: (row) => FormatDate(row.createTime),
                },
                {
                    headerStyle: 'text-align:center;',
                    name: 'writerNm',
                    label: '최초작성자',
                    align: 'center',
                    field: 'writerNm',
                },
                {
                    headerStyle: 'text-align:center;',
                    name: 'lastUpdateTime',
                    label: '변경일자',
                    align: 'center',
                    field: (row) => FormatDate(row.lastUpdateTime),
                },
                {
                    headerStyle: 'text-align:center;',
                    name: 'showDate',
                    label: '게시일자',
                    align: 'center',
                    field: (row) => FormatDate(row.showDate),
                },
                {
                    headerStyle: 'text-align:center;',
                    name: 'crud',
                    label: '확인',
                    align: 'center',
                    field: (row) => (row.crud == 'F' ? '확인' : ''),
                },
                {
                    headerStyle: 'text-align:center;',
                    name: 'title',
                    label: '내용',
                    align: 'left',
                    field: 'title',
                },
                {
                    headerStyle: 'text-align:center;',
                    name: 'stockCode',
                    label: '종목명',
                    align: 'left',
                    field: (row) => (row.stockCode ? row.stockName + '(' + row.stockCode + ')' : '') // 22.09.19 종목추가 
                },
                {
                    headerStyle: 'text-align:center;',
                    name: 'checked',
                    label: 'checked',
                    align: 'center',
                },
            ],
            page:1,
            pagination: {
                page: 1,
                rowsPerPage: CONSTANTS.LIST_COUNT_PER_PAGE,
                maxPages: 1,
            },
        }
    },
    computed: {
        ...mapState({
            role: (state) => state.session.role,
            userId: (state) => state.session.userId,
        }),
        ...mapState('manage', ['list', 'totcount']),
        pagesNumber() {
            return Math.ceil(
                Number(this.totcount) / Number(this.pagination.rowsPerPage)
            )
        },
    },
    created() {
        this.getList()
    },
    methods: {
        ...mapMutations('manage', [
            'SET_DATA',
            'INIT_DATA_VIEW',
            'SET_DATA_VIEW',
        ]),
        ...mapActions('alert', [
            'showError',
            'showMessage',
            'showAlert',
            'showDeleteAlert',
        ]),
        ...mapActions('manage', ['GET_CALL']),

        //리스트
        getList() {
            let nextKey = null
            if(this.page < this.pagination.page) {
                nextKey = this.list[this.list.length-1].lastUpdateTime
            } else if(this.page > this.pagination.page) {
                nextKey = this.list[0].lastUpdateTime
            }
            this.page = this.pagination.page

            if(this.page == 1) nextKey = null
            let param = {
                keyword: this.$parent.keyword,
                nextKey:nextKey,
                pageStr: (Number(this.pagination.page) - 1) * Number(this.pagination.rowsPerPage),
                pageSize: this.pagination.rowsPerPage,
                CALL_API_URL: '/dividend/list',
            }
            this.GET_CALL(param)
                .then((data) => {
                    data.items.forEach((row) => {
                        row.checked = this.allCheck
                    })
                    this.SET_DATA(data)
                })
                .catch((error) => {
                    this.showError({ vc: this, error: error })
                })
        },
        //상세
        getView(item) {
            this.INIT_DATA_VIEW()
            var sId = ''
            if (item != null && item.id != null) {
                sId = item.id
            }
            let param = {
                id: sId,
                CALL_API_URL: '/dividend/view',
            }
            this.GET_CALL(param)
                .then((data) => {
                    this.SET_DATA_VIEW(data)
                    this.$parent.$refs.dividendContent.getViewItem(data.item)
                })
                .catch((error) => {
                    this.showError({ vc: this, error: error })
                })
                
        },
        //선택삭제
        getDeleteChk() {
            var iCnt = 0
            var ds_list = []
            this.list.forEach((row) => {
                if (row.checked) {
                    iCnt++
                    ds_list.push({
                        id: row['id'],
                    })
                }
            })
            if (iCnt == 0) {
                this.showMessage({
                    vc: this,
                    message: '체크항목을 선택하세요.',
                })
                return
            }
            this.showDeleteAlert({
                vc: this,
                message:
                    iCnt +
                    '건 <span style="color:red;">선택삭제</span> 하시겠습니까?',
            })
                .then(() => {
                    let param = {
                        ds_list,
                        CALL_API_URL: '/dividend/deleteChk',
                    }
                    this.GET_CALL(param)
                        .then(() => {
                            this.showMessage({
                                vc: this,
                                message: '선택삭제 되었습니다.',
                            })
                            this.allCheck = false
                            this.$parent.getList()
                            // this.$parent.getView(null)
                            this.INIT_DATA_VIEW 
                        })
                        .catch((error) => {
                            this.showError({ vc: this, error: error })
                        })
                })
                .catch(() => {})
        },
        //전체선택
        getAllCheck() {
            this.list.forEach((row) => {
                row.checked = this.allCheck
            })
        },
    },
}
</script>
