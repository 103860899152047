<template>
  <article>
    <div class="q-pa-xl" v-if="list.length == 0">
      조회된 내용이 없습니다.
    </div>
    <div v-if="list.length > 0">
      <q-markup-table class="markUpTb">
        <thead>
            <q-tr>
                <q-th></q-th>
                <q-th v-for="(a,i) in list" :key="i" style="font-weight: bold;font-size:10pt">
                  {{ a.showDate }} [{{a.typeNm}}]
                </q-th>
            </q-tr>
        </thead>
        <tbody>
            <!-- <q-tr style="font-weight: bold;">
                <q-td>조회수</q-td>
                <q-td v-for="(a,i) in list" :key="i">
                    <div>{{list[i].totalCount}}</div>
                </q-td>
            </q-tr> -->
            <q-tr style="background:lightGrey">
                <q-td>순위</q-td>
                <q-td v-for="(a,i) in list" :key="i">
                  <div> 아이디 (조회수) </div>
                </q-td>
            </q-tr>
            <q-tr v-for="i in mLen" :key="i">
                <q-td>{{i}}</q-td>
                <q-td v-for="(b,j) in list" :key="j">
                  <div v-if="list[j].memberList.items[i-1] != undefined">{{list[j].memberList.items[i-1].memberId}} ({{list[j].memberList.items[i-1].viewCount}})</div>
                  <div v-else>-</div>
                </q-td>
            </q-tr>
        </tbody>
      </q-markup-table>
    </div>
  </article>
</template>
<script>
import { mapActions } from "vuex";
import { FormatDate } from '@/util'

export default {
  name: "List",
  components: {
  },
  data() {
    return {
        list : [],
        mLen: 20,
    };
  },
  computed: {
  },
  created() {
    this.getList()
  },
  methods: {
    ...mapActions('newspaper', ['GET_VIEWCOUNT_LIST']),
    ...mapActions('alert', ['showError']),

    // 220927 meej 주식신문 조회수 목록 (상위20)
    getList(){
      let param = {
          newsType: this.$parent.type.value,
          showDate: this.$parent.dateEnd,
      }
      this.GET_VIEWCOUNT_LIST(param)
      .then((data) => {
        data.items.forEach((row)=>{
            row.showDate = FormatDate(row.showDate)
            if(row.newsType1 == "MARKET_MOBILENEWS_AM") {
              row.typeNm = "조간"
            } else {
              row.typeNm = "석간"
            }
          })
          this.list = data.items
        })
        .catch((err) => {
            this.showError({ vc: this, error: err })
        })
    }
  },
  watch: {},
};
</script>
<style scoped>
.markUpTb .q-table thead{
    background-color: grey;
    color: white;
}
.markUpTb .q-table thead tr, .q-table tbody td {
    height: 30px;
    border-left: 1px solid rgba(0,0,0,0.12);
}
</style>