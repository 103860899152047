<template>
   <div class="q-pt-md" >
    <q-list bordered>
    <!-- 인포스탁 업무관리표 start -->
    <q-expansion-item
        group="somegroup"
        icon="perm_identity"
        label="인포스탁 업무관리표"
        default-opened
        header-class="text-bold text-dark bg-grey-4"
      >
    <!-- 220819 meej 실적 업무 데이터 있으면 나오게, 없으면 안나오게 처리 -->
    <!-- 220628 meej style="width:100%" 추가 -->
    <!-- <div class="row justify-end bg-white">
      <span class="q-pt-sm">실적 업무 확인하기</span> 
      <q-checkbox v-model="checked" color="primary" size="sm"
        @input="handleCheckBox()"
      />
    </div> -->
    <q-markup-table class="table-align" style="width:100%">
      <!-- <caption class="tab-caption-style" style="text-align:right;"> -->
        <!-- 인포스탁 업무관리표  -->
        <!-- 220802 meej 리스트 재조회 버튼 추가 -->
        <!-- <span class="q-pl-sm q-pr-sm" ><q-btn round size="sm" icon="refresh" color="primary" @click="getListAll()"/></span> -->
      <!-- </caption> -->
      <thead>
        <tr style="height:40px">
          <th class="text-center" style="font-weight: bold; font-size: 13px;width:20%">시간</th>
          <!-- <th class="text-center" style="font-weight: bold; font-size: 13px;">담당자</th> -->
          <!-- <th class="text-center">구분</th> -->
          <th class="text-center" style="font-weight: bold; font-size: 13px;width:60%">
            <span class="q-pl-md" >컨텐츠/업무관리표</span>
            <span class="q-pl-sm" ><q-btn round size="sm" icon="refresh" color="primary" @click="getListAll()"/></span>
          </th>
          <th class="text-center" style="font-weight: bold; font-size:13px;width:20%">처리
          </th>
          <!-- <th class="text-center" style="font-weight: bold; font-size: 13px;width:15%"> -->
            <!-- 실행 -->
          <!-- </th> -->
        </tr>
      </thead>
      <tbody>
        <!-- for문 start -->
        <tr v-for="(a, i) in list" :key="i">
          <td class="text-right" style="height:40px" > 
              <span v-if="a.startTime != '00:00' && a.endTime != '00:00'">{{a.startTime}} ~ {{a.endTime}}</span>
              <span v-else-if="a.startTime != '00:00'">{{a.startTime}}</span>
              <span v-else-if="a.endTime != '00:00'">~{{a.endTime}}</span>
          </td>
          <!-- <td class="text-center">{{a.manager}}</td> -->
          <!-- <td class="text-center font-size-change">{{a.sort}}</td> -->
          <td class="text-center" style="height:40px">
            <span v-if="a.title.length < 33">{{a.title}}</span>
            <span v-else class="font-size-change">{{a.title}}</span>
            </td>
          <!-- 처리상태 start -->
          <td v-if="a.status == 1" class="text-center done_text_red checked_align" style="height:40px;padding:0px">
           <q-checkbox v-model="a.checked" color="red" size="sm"></q-checkbox>
          </td>
          <!-- 221123 meej 테마 히스토리 입력 추가 -->
          <td v-else-if="a.status == 0 && a.title == '테마히스토리 입력'" class="text-center checked_align" style="height:40px">
            <span v-if="total == 0&&insertCnt == 0">-</span>
            <span v-else>{{insertCnt}} / {{total}}</span>
          </td>
          <td v-else-if="a.status == 0" class="text-center checked_align" style="height:40px">-</td>
          <td v-else class="text-center checked_align" style="height:40px"></td>
          <!-- 처리상태 end -->
          <!-- <td v-if="a.manager == 'infobot1' || a.manager == 'infobot2'" class="text-center" style="height:40px">
            <q-btn @click="doInfobot" class="btn-align-left" push color="white" text-color="primary" size="sm" label="실행" />  
          </td>
          <td v-else class="text-center" style="height:40px">
            -
          </td>  -->
            <!-- <q-btn class="btn-align-left" disable push color="grey-4" text-color="black" label="실행" /> -->
        </tr>
        <!-- for문 end -->
      </tbody>
      </q-markup-table>
      </q-expansion-item>
      <!-- 인포스탁 업무관리표 end -->
      <q-separator></q-separator>
      <!-- 인포봇 업무목록 start -->
      <q-expansion-item
        group="somegroup"
        icon="schedule"
        label="인포봇 업무 목록"
        header-class="text-bold text-dark bg-grey-4"
      >
    <q-markup-table class="table-align" style="width:100%">
      <thead>
        <tr style="height:40px">
          <th class="text-center" style="font-weight: bold; font-size: 13px;width:5%">시간</th>
          <th class="text-center" style="font-weight: bold; font-size: 13px;width:3%">인포봇</th>
          <th class="text-center" style="font-weight: bold; font-size: 13px;width:86%">
            <span class="q-pl-md" >컨텐츠/업무관리표</span>
            <span class="q-pl-sm" ><q-btn round size="sm" icon="refresh" color="primary" @click="getInfobotList()"/></span>
          </th>
          <th class="text-center" style="font-weight: bold; font-size: 13px;width:5%">처리
          </th>
          <th class="text-center" style="font-weight: bold; font-size: 13px;width:5%">
            실행
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(a, i) in infoBotList" :key="i">
          <td class="text-center" style="height:40px;padding:0 0 0 5px" > 
              <span v-if="a.endTime != '00:00'">{{a.endTime}}</span>
          </td>
          <td class="text-center" style="height:40px;padding:0px">{{a.manager}}</td>
          <td class="text-center" style="height:40px;padding:0px">
            <span v-if="a.manager=='1'" :class="a.title.length < 30 ? '': 'font-size-change'" style="color:#007BFF">{{a.title}}</span>
            <span v-else :class="a.title.length < 30 ? '': 'font-size-change'">{{a.title}}</span>
            </td>
          <td v-if="a.status == 1" class="text-center done_text_red checked_align" style="height:40px;padding:0px">
           <q-checkbox v-model="a.checked" color="red" size="sm"></q-checkbox>
          </td>
          <td v-else-if="a.id == '5063502'" class="text-center checked_align" style="height:40px;padding:0px">
            직접확인
          </td>
          <td v-else class="text-center checked_align" style="height:40px;padding:0px">-</td>
          <td v-if="a.content != null" class="text-center" style="height:40px;padding:0px">
            <q-btn style="width:40px" @click="doInfobot(a)" push color="white" text-color="dark" size="11px" label="실행" />  
          </td> 
          <td v-else class="text-center checked_align" style="height:40px;padding:0px">-</td>
        </tr>
      </tbody>
      </q-markup-table>
      </q-expansion-item>
      <!-- 인포봇 업무목록 end -->
      </q-list>
    </div> 
</template>

<script>
// import mainTableData from '@/store/modules/common/mainTable.js'  
import { mapActions } from 'vuex'

export default {
  name: "MainTable",
  data() {
    return {
      list: [],
      infoBotList : [],
      // data: mainTableData, // 133
      // timeGroup: ['06:50', '07:40', '09:00', '09:30', '15:30', '15:40', '15:50', '16:00', '16:30', '16:50',],
      checked: false,
      // params: {},
      total : 0, // 221123 meej 테마히스토리 입력 
      insertCnt : 0, // 221123 meej 테마히스토리 입력 
    };
  }, // data end
  mounted() {
    // 220819 meej 실적 업무 데이터 있으면 나오게, 없으면 안나오게 처리
    // 220817 meej 실적확인하기_localStorage로 넣어놓음(임시)_사용안함
    // const earning = localStorage.getItem("earn")
    // if(earning == undefined){
    //   this.params = {}
    //   this.checked = false
    // }else {
    //   this.params = {"earning" : earning}
    //   this.checked = earning == "1"? true : false
    // }
    this.getListAll()
    this.getInfobotList()
  },
  methods: {
    ...mapActions( 'adminSchedule', ['GET_LIST', 'GET_INFOBOT_LIST', 'EXE_INFOBOT']),
    ...mapActions('alert', ['showError', 'showAlert', 'showMessage']),

    // 220823 meej 인포봇 속보 실행 함수
    doInfobot(val){ 
      let msg = ''
      if(val.checked){
        msg += "처리 완료된 내용입니다. "
      }
      msg += '실행하시겠습니까?'
      this.showAlert({
                vc: this,
                message: msg,
            }).then(() => {
              const params = { id : val.id }
              this.EXE_INFOBOT(params)
              .then(()=>{
                this.showMessage({ vc: this, message: "인포봇 실행 요청 하였습니다." })
                this.getInfobotList()
              }).catch((err) => {
                  this.showError({ vc: this, error: err })
              })
            })
    },
    // 리스트 재조회
    getListAll(){
      const params = {}
      this.GET_LIST(params).then((result) => {
        this.list = result.items
        this.total = result.themeHistoryTotal
        this.insertCnt = result.themeHistoryInsert
        result.items.forEach((row)=>{
        if(row.status == 1) row.checked = true
        else row.checked = false
      })
      }).catch((err) => {
          console.log('err', err);
      })
    },
    // 220823 meej 인포봇 업무 리스트 조회
    getInfobotList(){
      this.GET_INFOBOT_LIST({}).then((result) => {
        this.infoBotList = result.items
        result.items.forEach((row)=>{
        if(row.status == 1) row.checked = true
        else row.checked = false
      })
      }).catch((err) => {
          console.log('err', err);
      })
    },
    // 220817 meej 실적확인하기_localStorage로 넣어놓음(임시)_사용안함
    // handleCheckBox(){
    //   if(this.checked){
    //     localStorage.setItem("earn", "1")
    //     this.params = {"earning" : "1"}
    //   }else{
    //     localStorage.removeItem("earn")
    //     this.params = {}
    //   }
    //   this.getListAll()
    // }
  }, // methods end
}
</script>

<style>

/* 테이블 캡션 */
.tab-caption-style {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 15px;
}

/* 테이블 상단 컬럼 */
.font-weight-change {
  font-weight: bold;
  font-size: 60%;
}

.font-size-change {
  font-size: 10px;
}

/* 버튼 위치 */
.btn-align-left {
  margin-left: 5%;
}

.table-align {
  width: fit-content; 
  table-layout: fixed;
  word-break: break-all;
}

.done_text_red {
  color: red;
}

/* 체크박스 위치 */
/* .checked_align {
  left: 5px;
} */

</style>