<template>
    <article>
        <q-dialog v-model="updatePopup" persistent>
            <q-card style="min-width: 70%;min-height:50%;" >
                <q-card-section >
                    <div class="row q-my-sm">
                        
                        <div class="col-12 col-sm-4">
                        <!-- <div style="padding: 10px 0px 0px 30px;"><label class="i-label" style="color:gray; font-size: 15px">요청자 {{userId}}</label></div> -->
                        <i-input v-model="requester" inputLabel="요청자" style="width:200px; margin: 0px 0px 0px 15px; text-align : center;"/>
                        </div>
                        <!-- <div class="col-12 col-sm-6">
                            <i-datetime date v-model="request_date" dateLabel="요청일" style="width:300px; "/> 
                        </div> -->
                    </div>

                  
                    <body class="wrap">
                        <div>
                            <div class="row q-my-sm">
                                <label class="i-label" style="padding: 30px 0px 0px 50px; color:gray; width:100px;">부서</label>
                               <!-- <div style="padding: 30px 30px 0px 40px; text-align:right;">부서 </div> -->
                               <div><i-select v-model="department" :options="departmentOptions" option-value="value" option-label="name" style="padding: 20px 0px 0px 6px; text-align : center;"/></div>
                            
                               <!-- <label class="i-label" style="margin: 30px 0px 0px 150px; color:gray; width:150px;">확인요청자</label> -->
                               <i-input v-model="confirm_req" inputLabel="확인요청자" style="width:250px; margin: 17px 0px 0px 50px; text-align : center;"/>
                            </div>

                            <div class="row q-my-sm">
                                <i-input v-model="project" inputLabel="프로젝트명" style="width:50%; padding: 0px 0px 0px 20px; text-align : center;"/>
                            </div>

                            <div class="row q-my-sm">
                                <q-input class="full-width" dense outlined v-model="content" type="textarea" :input-style="{width:'100%'}"><template v-slot:before><label class="i-label" style="padding: 70px 0px 0px 20px; color:gray; width:100px; text-align : center;">내용</label></template></q-input>
                                <!-- <div class="q-ma-xs"><input v-model="content" @keyup="projectHandler" type="textarea"/></div> -->
                            </div>
                        </div>

                        <div class="row q-my-sm">
                            <label class="i-label" style="padding: 30px 0px 0px 50px; color:gray; width:100px;">중요도</label>
                            <div><i-select v-model="importance" :options="['1','2','3','4','5']" option-value="value" option-label="name" style="padding: 20px 0px 0px 6px; text-align : center;"/></div>
                            
                            <label class="i-label" style="padding: 30px 0px 0px 50px; color:gray; width:100px;">긴급도</label>
                            <div><i-select v-model="urgency" :options="['1','2','3','4','5']" option-value="value" option-label="name" style="padding: 20px 0px 0px 6px; text-align : center;"/></div>
                        </div>
                    </body>
                      
                </q-card-section>
                <q-card-actions align="center" class="text-primary">
                    <div class="q-pa-xs"><q-btn label="등록" @click="getUpdatePopupSave()"/></div>
                    <div class="q-pa-xs"><q-btn color="white" text-color="black" label="닫기" v-close-popup/></div>
                </q-card-actions>
            </q-card>
        </q-dialog>
    </article>
</template>
<script>
import Select from "@/components/common/Select";
import { mapState, mapActions } from 'vuex'
import Input from "@/components/common/Input";
import CONSTANTS from '@/constants'

export default {
    name: 'Workmanager-List',
    props: {
        hide_selected: {
            type: Boolean,
            default: true
        }
    },
    components: {
        'i-select' : Select,
        // 'i-datetime': DateTime,
        "i-input": Input,
    },
    data () {
        return {
            requester: '',
            importance: '',
            urgency: '',
            // request_date: '',
            updatePopup: false,
            confirm_req: '',
            departmentOptions: [{'value':'', 'name':'부서'}].concat(CONSTANTS.DEPARTMENT_TYPE),
            department: '',
            // file: {'value':'', 'name':'파일'},
            project: '',
            content: '',
            // project: {'value':'', 'name':'프로젝트'},
            // content: {'value':'', 'name':'내용'},
        

        }
    },
    computed : {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            
        })
    },
    // created () {
    //     this.getInit();
    // },
    methods: {

        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),

        initList() {

        },
        initData() {
            // this.showDate = '';
            // this.request_date = '';
            this.requester ='';
            this.department ='';
            this.confirm_req = '';
            this.project = '';
            this.content = '';
            this.importance = '';
            this.urgency = '';
        },

        //수정팝업
        getUpdatePopup() {
            this.initData()
            this.updatePopup = true;
        },

        // 작성
        getUpdatePopupSave() {

            if (this.requester == null || this.requester === "") {
                this.showMessage({ vc:this, message:'요청자를 입력하세요.' });
                return;
            }

            if (this.department == null || this.department === "") {
                this.showMessage({ vc:this, message:'부서를 선택하세요.' });
                return;
            }

            if (this.confirm_req == null || this.confirm_req === "") {
                this.showMessage({ vc:this, message:'확입요청자를 입력하세요.' });
                return;
            }

            if (this.project == null || this.project === "") {
                this.showMessage({ vc:this, message:'프로젝트명을 입력하세요.' });
                return;
            }

            if (this.project.length > 32){
                this.showMessage({ vc:this, message:'프로젝트명을 32자 이내로 작성해주세요.' });
                return;
            }

            // if (this.content == null || this.content === "") {
            //     this.showMessage({ vc:this, message:'내용을 입력하세요.' });
            //     this.roleeturn;
            // }

            if (this.content.length > 512){
                this.showMessage({ vc:this, message:'내용을 512자 이내로 작성해주세요.' });
                return;
            }

            if (this.importance == null || this.importance === "") {
                this.showMessage({ vc:this, message:'중요도를 선택하세요.' });
                return;
            }
            if (this.urgency == null || this.urgency === "") {
                this.showMessage({ vc:this, message:'긴급도를 선택하세요.' });
                return;
            }

            this.showAlert({ vc:this, message:'<span style="color:#1976D2;">저장</span> 하시겠습니까?' }).then(()=>{
            this.updatePopup = false;
                
                let param = {
                    'requester': this.requester,
                    'department': this.department.name,
                    'confirm_req': this.confirm_req,
                    'project': this.project,
                    'content': this.content,
                    'importance': this.importance,
                    'urgency': this.urgency,
                    'CALL_API_URL': '/request/add'
                }

                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'저장되었습니다.' });
                    this.initData()
                    this.$parent.getList();
                }).catch((err)=>{
                    this.showError({ vc:this, error:err });
                });
            }).catch(() => { });

           
        }
    }   
}
</script>
<style>

.wrap {
    border-style: solid;
    border-width: 5px;
    border-color: #C3C3C3;
}
</style>