<template>
    <section class="row">
        <!-- 여기서 view들 통제해야함 -->
        <i-list v-show="isListPage" class="col-6 q-pa-md" :newsType="type"/>
        <i-content v-if="isListPage" class="col-6 q-pa-md" :newsType="type"/>
    </section>
</template>
<script>
import List from "@/components/marketing/pdf/List.vue";
import Content from "@/components/marketing/pdf/Content.vue";
import { mapState, mapGetters } from "vuex";

export default {
    name: "IRinsight",
    components: {
        "i-list": List,
        "i-content": Content,
    },
    data() {
        return {
            type:{
                name:'IR 인사이트',
                value:'MARKET_INFOSTCOK_INSIGHT'
            }
        }
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
        }),
        ...mapGetters({
            isListPage: "page/isListPage",
            isEditPage: "page/isEditPage",
            isRegisterPage: "page/isRegisterPage",
            isHistoryPage: "page/isHistoryPage",
        }),
    },
};
</script>
<style>
/* common */

/* xs */
@media only screen and (max-width: 599px) {
}

/* sm */
@media only screen and (max-width: 1023px) {
}

/* md */
@media only screen and (max-width: 1439px) {
}

/* lg */
@media only screen and (max-width: 1919px) {
}

/* xl */
@media only screen and (min-width: 1920px) {
}
</style>
