<template>
    <article>
        <!-- 목록 -->
        <i-table
            :columns="columns"
            :list="list"
            :nextKey="nextKey"
            :needCheckBox="!usTheme"
            @row-click="getItem"
            @next-click="getList()"
            @check-event="handleCheckList"
        />
    </article>
</template>
<script>
import CONSTANTS from '@/constants'
import { FormatDate, CutDownText, CRUDLabel, isEmpty, nowTime, addWorkDay, today } from '@/util'
import Table from '@/components/common/Table'
import Debug from '@/debug'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    components: {
        'i-table': Table,
    },
    props: {
        newsType: {
            type: Object,
            default: null,
        },
        showFlag: {
            type: Object,
            default: CONSTANTS.MARKETNEWS_TYPE[0],
        },
    },
    data() {
        return {
            columns: [
                {
                    name: 'showFlag',
                    label: '게시',
                    align: 'left',
                    field: (row) => (isEmpty(row.showDate) ? 'X' : 'O'),
                },
                // 20210812 shb 구분 숫자 제거
                {
                    name: 'type',
                    label: '구분',
                    align: 'left',
                    field: (row) =>
                        isEmpty(row.DIVISION)
                            ? '-'
                            : row.DIVISION.slice(0, row.DIVISION.length - 1),
                },
                // 220819 meej 미국테마시황_MarketNews에서 조회하므로 showDate로 나오게 수정
                {
                    name: 'sendDate',
                    label: '전송날짜',
                    align: 'left',
                    field: (row) => isEmpty(row.sendDate)? FormatDate(row.showDate) : FormatDate(row.sendDate),
                },
                {
                    name: 'lastUpdateTime',
                    label: '작성/변경날짜',
                    align: 'left',
                    field: (row) => FormatDate(row.lastUpdateTime),
                },
                {
                    name: 'createwriter',
                    label: '최초작성자',
                    align: 'left',
                    field: (row) => row.CREATE_WRITER,
                },
                {
                    name: 'writer',
                    label: '수정자',
                    align: 'left',
                    field: (row) => (row.crud == 'U' ? row.writer : ''),
                },
                {
                    name: 'crud',
                    label: '상태',
                    align: 'left',
                    field: (row) => CRUDLabel(row.crud),
                },
                {
                    name: 'content',
                    label: '내용',
                    align: 'left',
                    field: (row) => CutDownText(row.content, 50),
                },
            ],
        }
    },
    created() {
        this.INIT_LIST()
        Debug.log('cerate newstye = ', this.newsType)
        if (this.newsType !== null && this.newsType !== undefined) {
            if(this.newsType.value === 'MARKET_FLASH_HTS_US_THEME' ) {
                this.columns.shift()
            }
            this.getList()
        }
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            marketNewsType: (state) => state.resource.MarketNewsType,
        }),
        ...mapState('market_news', ['list', 'nextKey']),
        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isEditPage',
            'isRegisterPage',
        ]),
        usTheme() {
            if (this.newsType !== null && this.newsType !== undefined) {
                return this.newsType.value === 'MARKET_FLASH_HTS_US_THEME'
            }
            return false
        },
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('market_news', ['INIT_LIST', 'SET_ITEM']),

        ...mapActions('alert', ['showError', 'showMessage']),
        ...mapActions('resource', ['GET_NEWSTYPE_LIST']),
        ...mapActions('market_news', [
            'ASYNC_LIST',
            'GET_DETAIL',
            'DELETE_ITEMS',
        ]),

        // 시황 목록 조회하기
        getList() {
            //this.setPageStatus(this.PAGE_STATUS.LIST)

            var params = {}
            params.newsType = this.newsType.value
            params.showFlag = this.showFlag.value

            // 220802 meej 시황 전체(당일만조회)시 전송일자 기준으로 조회
            if(this.newsType.value == 'MARKET_CLOSED_ALL'){
                let sendDate
                if (Number(nowTime('HH')) > 13) sendDate = addWorkDay(1)
                else sendDate = today()
                params.date = sendDate
            }

            this.ASYNC_LIST(params)
        },
        // 시황 가져오기
        getItem(item) {
            if (this.usTheme) {
                this.setPageStatus(this.PAGE_STATUS.DETAIL)
                this.SET_ITEM({ ...item })
                console.log(item)
            } else {
                this.$emit('event-click', item)
                this.GET_DETAIL({
                    newsType: item.newsType1,
                    id: item.pid,
                })
                    .then(() => {
                        this.setPageStatus(this.PAGE_STATUS.DETAIL)
                    })
                    .catch((err) => {
                        var error = err.message
                        this.showError({ vc: this, error: error })
                    })
            }
            //
        },
        handleCheckList(list) {
            this.$emit('event-check', list)
        },
    },
    watch: {
        newsType() {
            if (this.isListPage) {
                this.INIT_LIST()
                this.getList()
            }
        },
        showFlag() {
            this.INIT_LIST()
            this.getList()
        },
    },
}
</script>
