<template>
    <article>
        <!-- 220705 meej 페이징 오류 수정 : :pagination.sync="pagination" 삭제 -->
        <!-- <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : list.length == 0 }"
            :columns="columns" :data="list" row-key="RNUM" :rows-per-page-options="[0]" style="height:auto;"
            :pagination.sync="pagination" hide-pagination no-data-label="조회되는 데이터가 없습니다."> -->
        <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : list.length == 0 }"
            :columns="columns" :data="list" row-key="RNUM" :rows-per-page-options="[0]" style="height:auto;"
            hide-pagination no-data-label="조회되는 데이터가 없습니다.">
            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th v-for="col in props.cols" :key="col.name" :props="props" :style="col.style">
                        <div v-if="col.name == 'checked'"><q-checkbox v-model="allCheck" dense @input="getAllCheck()"/></div>
                        <div v-else>{{ col.label }}</div>
                    </q-th>
                </q-tr>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td v-for="col in props.cols" :key="col.name" :props="props" @click="getView(props.row)" style="cursor:pointer;">
                        <div v-if="col.name == 'checked'"><q-checkbox v-model="props.row.checked" dense/></div>
                        <div v-else>{{ col.value }}</div>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:no-data="{ icon, message }"><div class="full-width row flex-center q-gutter-sm"><q-icon size="2em" :name="icon" /> <span>{{message}}</span></div></template>
        </q-table>
        <div v-show="list.length != 0" class="flex flex-center q-mt-md">
            <q-pagination
            v-model="pagination.page"
            :max="pagesNumber"
            :max-pages="pagination.maxPages"
            :direction-links="true"
            :boundary-numbers="false"
            :boundary-links="true"
            :ellipses="false"
            @click="getList()"
        />
        </div>
        <q-dialog v-model="updatePopup" persistent>
            <q-card style="min-width: 80%;">
                <q-card-section>
                    <q-input class="full-width" dense outlined v-model="textLine" type="textarea" :input-style="{height:'70px'}" placeholder="Excel Header를 제외한 Data를 복사한 후 붙여넣기 하세요." />
                </q-card-section>
                <q-card-actions align="right" class="text-primary">
                    <q-btn color="white" text-color="black" label="닫기" v-close-popup/>
                    <q-btn label="저장" @click="getUpdatePopupSave()"/>
                </q-card-actions>
            </q-card>
        </q-dialog>
    </article>
</template>
<script>
import CONSTANTS from '@/constants'
import { FormatDate, numberFormat } from '@/util'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    data () {
        return {
            updatePopup: false,
            textLine: '',
            allCheck: false,
            ifrsOptions : CONSTANTS.COM_IFRS2,
            reportTypeOptions: CONSTANTS.FINANCIAL_REPORT_TYPE2,
            columns: [
                    { headerStyle:'text-align:center;', style:'width:100px;', name: 'stockCode', label: '종목', align: 'center', field: (row) => row.stockName + " (" + row.stockCode + ")" },
                    { headerStyle:'text-align:center;', style:'width:100px;', name: 'ifrs', label: '연결/별도', align: 'center', field: (row) => this.ifrsOptions[row.ifrs] },
                    { headerStyle:'text-align:center;', style:'width:80px;', name: 'closingDate',  label: '결산일', align: 'center', field: (row) => FormatDate(row.closingDate) },
                    { headerStyle:'text-align:center;', style:'width:100px;', name: 'reportType',  label: '보고서종류', align: 'center', field: (row) => this.reportTypeOptions[row.reportType] },
                    { headerStyle:'text-align:center;', style:'width:*;', name: 'fieldName',  label: '항목', align: 'left', field:'fieldName' },
                    { headerStyle:'text-align:center;', style:'width:80px;', name: 'financialValue',  label: '금액(원)', align: 'right', field: (row) => numberFormat(row.financialValue) },
                    { headerStyle:'text-align:center;', style:'width:40px;', name: 'checked',  label: 'checked', align: 'center'}
                ],
            pagination: {
                page: 1,
                rowsPerPage: CONSTANTS.LIST_COUNT_PER_PAGE,
                maxPages: CONSTANTS.DEFAULT_PAGE_NUM
            }
        }
    },
    computed : {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId
        }),
        ...mapState('manage', ['list', 'totcount']),
        pagesNumber () {
            return Math.ceil(Number(this.totcount) / Number(this.pagination.rowsPerPage));
        }
    },
    created () {
        this.getList();
    },
    methods: {
        ...mapMutations('manage', ['SET_DATA', 'INIT_DATA_VIEW', 'SET_DATA_VIEW']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        //리스트
        getList() {
            let param = {
                financialGb: this.$parent.financialGb.value,
                stockCode: this.$parent.stockCode.code,
                ifrs: this.$parent.ifrs.value,
                closingDate: this.$parent.closingDate.value,
                reportType: this.$parent.reportType.value,
                pageStr: (Number(this.pagination.page) - 1) * Number(this.pagination.rowsPerPage),
                pageSize: this.pagination.rowsPerPage,
                CALL_API_URL: '/financial/list'
            }
            this.GET_CALL(param).then((data) => {
                data.items.forEach(row => {
                    row.checked = this.allCheck;
                });
                this.SET_DATA(data);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        //상세
        getView(item) {
            this.INIT_DATA_VIEW();
            var sId = "";
            if(item != null && item.financialId != null){
                sId = item.financialId;
            }
            let param = {
                'financialId': sId,
                'CALL_API_URL': '/financial/view'
            }
            this.GET_CALL(param).then((data) => {
                this.SET_DATA_VIEW(data);
                this.$parent.$refs.financialContent.getViewItem(data.item);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        //선택삭제
        getDeleteChk() {
            var iCnt = 0;
            var ds_list = [];
            this.list.forEach(row => {
                if(row.checked){
                    iCnt++;
                    ds_list.push({
                        financialId : row['financialId']
                    });
                }
            });
            if(iCnt == 0){
                this.showMessage({ vc:this, message:'체크항목을 선택하세요.' });
                return;
            }
            this.showDeleteAlert({ vc:this, message: iCnt+'건 <span style="color:red;">선택삭제</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    ds_list,
                    'CALL_API_URL': '/financial/deleteChk'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'선택삭제 되었습니다.' });
                    this.allCheck = false;
                    this.$parent.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },
        //전체선택
        getAllCheck(){
            this.list.forEach(row => {
                row.checked = this.allCheck;
            });
        },
        //수정팝업
        getUpdatePopup() {
            this.textLine = '';
            this.updatePopup = true;
        },
        //수정팝업
        getUpdatePopupSave() {
            if (!this.textLine) {
                this.showMessage({ vc:this, message:'내용을 입력하세요.' });
                return;
            }
            this.showAlert({ vc:this, message:'<span style="color:#1976D2;">저장</span> 하시겠습니까?' }).then(()=>{
                this.updatePopup = false;
                let param = {
                    'textLine': this.textLine,
                    'CALL_API_URL': '/financial/saveLine'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'저장 되었습니다.' });
                    this.$parent.getView(null);
                    this.$parent.getList();
                }).catch((err)=>{
                    this.showError({ vc:this, error:err });
                });
            }).catch(() => { });
        }
    }
}

</script>
