<template>
    <q-input
        dense
        :outlined="!disable"
        :standout="disable ? 'bg-teal text-white' : false"
        :value="value"
        @input="myChange"
        :label="label"
        :disable="disable"
        :type="type"
        :rows="rows"
        @focus="focus()"
        :autogrow="grow"
        :readonly="readonly"
    >
        <template v-if="inputLabel" v-slot:before>
            <label class="i-label">{{ inputLabel }}</label>
        </template>
    </q-input>
</template>
<script>
/*
    - 기본 사용: <i-input v-model="modelName" label="labelName" />
    1) disable 속성이 필요한 경우
        ex. <i-input :disable="value" />
    2) disable 속성이 무조건 true인 경우
        ex. <i-input disable />
    3) type 속성이 textarea인 경우
        ex. <i-input type="textarea" />
    4) type이 textarea일 때, rows 조정이 필요한 경우
        ex. <i-input type="textarea" :rows="rows"/>
    5) label vs inputLabel 차이
        - label: input 안에 label이 써져있음.
        - inputLabel: input밖 왼쪽에 label이 써져있음.
*/

export default {
    name: 'Input',
    props: {
        value: {
            required: true,
        },
        label: String,
        disable: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        rows: {
            type: Number,
            default: 0,
        },
        inputLabel: String,
    },
    data() {
        return {
            pvalue: this.value,
        }
    },
    computed: {
        grow() {
            return (
                this.type == 'textarea' &&
                this.rows == 0 &&
                this.value !== null &&
                    this.value !== undefined &&
                    this.value.length > 0
            )
        },
    },
    methods: {
        myChange(val) {
            this.$emit('input', val)
        },
        focus() {
            this.$emit('focus')
        },
    },
}
</script>
