<template>
    <section class="row">
        <i-list
            v-show="isListPage || isDetailPage"
            class="q-pa-md"
            :class="[isListPage ? 'col-12' : 'col-6']"
            @goThemeStock="goPage('ThemeStock')"
            @goThemeHistory="goPage('History')"
        />
        <i-resource
            v-show="isRegisterPage || isEditPage"
            :initMenu="resType"
            class="col-4 q-pa-md"
        />
        <i-content
            v-show="!isListPage"
            class="q-pa-md"
            @goThemeStock="goPage('ThemeStock')"
            @goThemeHistory="goPage('History')"
            :class="[isDetailPage ? 'col-6' : 'col-8']"
        />
    </section>
</template>
<script>
//import Debug from '@/debug'
import { mapState, mapGetters, mapMutations } from 'vuex'
import ThemeList from '@/components/theme/theme/List.vue'
import ThemeContent from '@/components/theme/theme/Content.vue'
import Resource from '@/components/common/Resource.vue'

export default {
    name: 'Theme',
    components: {
        'i-list': ThemeList,
        'i-content': ThemeContent,
        'i-resource': Resource,
    },
    data() {
        return {
            resType: 'Theme',
        }
    },
    created() {
        this.setPageStatus(this.PAGE_STATUS.LIST)
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
        }),
        ...mapGetters({
            isListPage: 'page/isListPage',
            isEditPage: 'page/isEditPage',
            isDetailPage: 'page/isDetailPage',
            isRegisterPage: 'page/isRegisterPage',
        }),
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
        }),
        goPage(name) {
            this.$router.push({ name: name })
        },
    },
    watch: {},
}
</script>

<style>
/* common */

/* xs */
@media only screen and (max-width: 599px) {
}

/* sm */
@media only screen and (max-width: 1023px) {
}

/* md */
@media only screen and (max-width: 1439px) {
}

/* lg */
@media only screen and (max-width: 1919px) {
}

/* xl */
@media only screen and (min-width: 1920px) {
}
</style>
