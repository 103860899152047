import api from '../../../services/InfostockService'
import Debug  from '../../../debug'

const state = () => ({
    items: [],
    item: {},
    nextKey : null
})

const mutations = {
    setList(state, { data }) {
        Debug.log('setList state.items', state.items)
        var arr = state.items
        arr.push(...data.items)
        // arr.sort(function(a, b) {
        //     return a.showDate > b.showDate ? -1 : a.showDate > b.showDate ? 1 : 0;
        // })

        state.items = arr
        state.nextKey = data.nextKey
        Debug.log('setList arr', arr)
    },
    SET_ITEM(state, data) {
        
        state.item = data
    },
    INIT_LIST(state) {
        state.nextKey = null
        state.items = []
    }
}

const actions = {
    async ASYNC_THEMENEWS_LIST({ commit }, params) {
        const { data } = await api.themeNews(params)
        commit('setList', { data })
    },
    /* 
        220523 meej THEMENEWS_LIST 동기방식 추가
     */
    GET_THEMENEWS_LIST(_, params) {
        Debug.log('GET_THEMENEWS_LIST params', params)
        return new Promise((resolve, reject) => {
            api.themeNews(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 작성 및 수정
    WRITE_ITEM(_, params) {
        Debug.log('WRITE_ITEM params', params)
        return new Promise((resolve, reject) => {
            api.themeNewsWrite(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    DELETE_ITEM(_, params) {
        return new Promise((resolve, reject) => {
            api.themeNewsDelete(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 221025 meej themeNews 완전삭제
    DELETE_HARD_ITEM(_, params) {
        return new Promise((resolve, reject) => {
            api.themeNewsDeleteHard(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 221025 meej 테마 히스토리 조회
    GET_THEMENEWS_HISTORY_LIST(_, params) {
        Debug.log('GET_THEMENEWS_HISTORY_LIST params', params)
        return new Promise((resolve, reject) => {
            api.themeNewsHistoryList(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}

const getters = {}

export const theme_news = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
