<template>
    <section class="row q-pl-xs" style="width:800px;">
        <q-toolbar class="row q-mt-md justify-center">
            <div class="q-pa-xs"><i-select v-model="indexId" :options="indexIdOptions" option-value="indexId" option-label="indexNm" @input="getList" style="width:200px;"/></div>
            <div class="q-pa-xs"><i-datetime date v-model="openDate"/></div>
            <div class="q-pa-xs"><i-input v-model="inputText" style="width:200px;"/></div>
            <div class="q-pa-xs"><i-btn label="등록" @click="getInsert()"/></div>
        </q-toolbar>
        <i-list class="col-12" ref="indexPriceList"/>
    </section>
</template>
<script>
import Select from '@/components/common/Select.vue';
import DateTime from '@/components/common/DateTime';
import Input from '@/components/common/Input';
import Button from '@/components/common/Button'
import List from "@/components/newspaper/indexPrice/List.vue";
import { FormatDate } from '@/util'
import { mapActions } from 'vuex'

export default {
    name: "IndexPrice",
    components: {
        'i-select' : Select,
        'i-datetime': DateTime,
        'i-input': Input,
        'i-btn': Button,
        "i-list": List
    },
    data() {
        return {
            indexId: null,
            indexIdOptions:[],
            openDate: '',
            inputText: ''
        }
    },
    created () {
        this.getPage();
    },

    methods: {
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        //페이지 검색
        // getPage() {
        //     let param = {
        //         CALL_API_URL: '/indexPrice/page'
        //     }
        //     this.GET_CALL(param).then((data) => {
        //         console.log("data : ", data)
        //         this.indexIdOptions = data.selectList;
        //         this.indexId = this.indexIdOptions[0];
        //         this.getList();
        //     }).catch((error) => {
        //         this.showError({ vc:this, error:error })
        //     });
        // },
        getPage() {
            let param = {
                CALL_API_URL: '/indexPrice/page'
            }
            this.GET_CALL(param).then((data) => {
                console.log("data : ", data)
                // 원하는 indexId만 필터링
                const desiredIndexIds = ["FED_ASSETS", "BITCOIN", "DOLLAR_IDX", "VIX"];
                this.indexIdOptions = data.selectList.filter(item => desiredIndexIds.includes(item.indexId));
                if (this.indexIdOptions.length > 0) {
                    this.indexId = this.indexIdOptions[0];
                    this.getList();
                }
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        getList() {
            this.$refs.indexPriceList.getList();
        },
        //등록
        getInsert() {
            this.showAlert({ vc:this, message:' '+FormatDate(this.openDate)+ ' 일자 데이터를 <span style="color:#1976D2;">등록</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    'indexId': this.indexId.indexId,
                    'openDate': this.openDate,
                    'inputText': this.inputText,
                    'CALL_API_URL': '/indexPrice/insert'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'등록 되었습니다.' });
                    this.inputText = '';
                    this.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        }
    }
};

</script>
