import api from '@/services/InfostockService'
import Debug  from '@/debug'
import { compareTime } from '@/util'

const state = () => ({
    list: [],
    item: {},
    nextKey: null,
    NewsFlashType:[],
    NewsFlashMenualType:[],
    NewsFlashAdminType:[],
    // flashType:[],
    // flashMenualType:[],
    // flashAdminType:[],
    BeforeNewsFlashType:[],
    MiddleAFlashType:[],
    MiddleBFlashType:[],
    AfterFlashType:[],
    status: JSON.parse(localStorage.getItem('flashStatus')) || 0,      //완료 1
    preview: JSON.parse(localStorage.getItem('preview')) || {},
    stockFilteringList: []
})

const getters = {
    isSuccess: state => (state.status === 1)
}

const mutations = {
    setList(state, data) {
        Debug.log('setList data', data)
        var arr = state.list
        arr.push(...data.items)

        // 인덱스 추가
        arr.forEach((row, index) => {
            row.index =  index
            if(row.sendFlag === -1) {
                row.label = (compareTime(row.sendDate+row.sendTime) ? '예약':'-')
            } else if(row.sendFlag === 0) {
                row.label = '완료'
            } else if(row.sendFlag === 1) {
                row.label = '실패'
            }
            row.checked = false
        })

        state.list = arr
        state.nextKey = data.nextKey
        Debug.log('setList arr', arr)
    },
    setFlashList(state, { data }) {
        Debug.log('setFlashList data', data)
        state[data.newsType] = data.newsTypes
    },
    // setFlashType(state, items) {
    //     state.NewsFlashType = items
    // },
    // setFlashMenualType(state, items) {
    //     state.flashMenualType = items
    // },
    // setFlashAdminType(state, items) {
    //     state.flashAdminType = items
    // },
    INIT_LIST(state) {
        state.list = []
        state.nextKey = null
    },
    INIT_PREVIEW(state) {
        state.preview = {}
        localStorage.removeItem('preview')
    },
    SET_PREVIEW(state,  data ) {
        Debug.log('SET_PREVIEW data', data)
        state.preview = data
        localStorage.setItem('preview', JSON.stringify(data))
    },
    SET_STATUS(state, status) {
        Debug.log('SET_STATUS data', status)
        localStorage.setItem('flashStatus', status)
        state.status = status
    },
    SET_STOCK_FILTERING_LIST(state, { data }) {
        var arr = state.stockFilteringList
        arr.push(...data.items)

        // 인덱스 추가
        arr.forEach((row, index) => {
            row.index = index
        })

        state.stockFilteringList = arr
    },
    SET_ITEM(item) {
        state.item = item
    },
    INIT_STOCK_FILTERING_LIST(state) {
        state.stockFilteringList = []
    },
}

const actions = {
    // 목록 조회
    async ASYNC_LIST({ commit, state, dispatch }, params ) {
        if(state.NewsFlashType.length == 0 || params.newsType === undefined) {
            await dispatch('ASYNC_NEWSTYPE', 'NewsFlashType')
            // 220708 meej 뉴스 속보 조회시 오류 수정
            // params = {newsType : state.NewsFlashType[0].value}
            params.newsType = state.NewsFlashType[0].value
        }
        params.nextKey = state.nextKey

        const { data } = await api.flashList(params)
        commit('setList', data)

    },
    async GET_NEWS_LIST(_, params ) {
        
        return new Promise((resolve, reject) => {
            api.flashNewsList(params)
                .then((response) => {
                    let data = response.data
                    resolve(data)

                })
                .catch((error) =>{
                    reject(error)
                })
        })

    },
    WRITE_FLASH({ commit }, params) {
        Debug.log('WRITE_FLASH params', params)
        return new Promise((resolve, reject) => {
            api.flashWrite(params)
                .then((response) => {
                    commit('SET_PREVIEW', response.data)
                    commit('SET_STATUS', 0)
                    resolve(response.data)

                })
                .catch((error) =>{
                    reject(error)
                })
        })

    },
    
    DETAIL_FLASH(_, params) {
        Debug.log('DETAIL_FLASH params', params)
        return new Promise((resolve, reject) => {
            api.sentNewsFlashDetail(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },

    PREVIEW_SUMMARY(_, params) {
        Debug.log('PREVIEW_SUMMARY params', params)
        return new Promise((resolve, reject) => {
            api.summaryPreview(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    RESEND_FLASH(_, params) {
        Debug.log('SEND_FLASH params', params)
        return new Promise((resolve, reject) => {
            api.flashReSend(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },
    SEND_FLASH(_, params) {
        Debug.log('SEND_FLASH params', params)
        return new Promise((resolve, reject) => {
            api.flashSend(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
            })
        // },window.close())
        
    },
    async ASYNC_NEWSTYPE({ commit }, newsType) {
        // NewsFlashType : 속보 뉴스 타입
        // NewsFlashMenualType : 속보보내기 타입
        // NewsFlashAdminType : 뉴스속보 타입

        const params = {newsType : newsType}
        const { data } = await api.newsType(params)
        data.newsType = newsType
        commit('setFlashList', { data })
        // if(newsType === 'NewsFlashType') {
        //     commit('setFlashType', data.newsTypes)
        // } else if(newsType === 'NewsFlashMenualType') {
        //     commit('setFlashMenualType', data.newsTypes)
        // } else if(newsType === 'NewsFlashAdminType') {
        //     commit('setFlashAdminType', data.newsTypes)
        // }
    },
    GET_FLASH_LIST({ commit }, newsType) {
        Debug.log('GET_FLASH_LIST newsType', newsType)
        const params = {newsType : newsType}
        return new Promise((resolve, reject) => {
            api.newsType(params)
            .then((response) => {
                const { data } = response

                data.newsType = newsType
                commit('setFlashList', { data })
                Debug.log('GET_FLASH_LIST data', data)
                resolve(data.newsTypes)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    DELETE_LIST(_, params) {
        return new Promise((resolve, reject) => {
            api.deleteNewsFlash(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })

    },
    // 증시요약 조회
    GET_SUMMARY_ITEMS({ commit }) {
        return new Promise((resolve, reject) => {
            api.marketNewsSummaryRefer()
                .then((response) => {
                    commit('INIT_LIST')
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },
    GET_INDEX(_, params) {
        return new Promise((resolve, reject) => {
            api.stockPrice(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },
    GET_SENT_LIST() {
        return new Promise((resolve, reject) => {
            api.sentNewsFlashList()
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },

    // 종목필터링 조회
    async ASYNC_STOCK_FILTERING_LIST({ commit }, params) {
        Debug.log('ASYNC_STOCK_FILTERING_LIST params', params)
        const { data } = await api.stockFilteringList(params)

        commit('SET_STOCK_FILTERING_LIST', { data })
    },
    // 221101 meej 속보 목록 조회
    async GET_NEWS_LIST_DAY(_, params ) {
        return new Promise((resolve, reject) => {
            api.flashNewsListByDay(params)
                .then((response) => {
                    let data = response.data
                    resolve(data)
                })
                .catch((error) =>{
                    reject(error)
                })
        })
    },
}

export const flash = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
