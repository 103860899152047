<template>
    <article class="bg-grey-4 i-wrap">
        <div class="i-top-wrap">
            <div class="row q-my-xs">
                <!-- v-model="flashData.sendDate" -->
                <i-datetime
                    class="full-width"
                    date
                    dateLabel="일자"
                    v-model="date"
                    @input="getList()"
                />
            </div>
        </div>
        <q-table
            flat
            bordered
            dense
            class="i-table"
            :class="{ 'no-data': items.length == 0 }"
            :columns="columns"
            :data="items"
            :rows-per-page-options="[0]"
            hide-bottom
            virtual-scroll
            row-key="code"
            no-data-label="조회되는 데이터가 없습니다."
        >
            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                    >
                        {{ col.label }}
                    </q-th>
                    <q-th>
                        <p class="check-text">상승주</p>
                        <q-checkbox v-model="allUpCheck" size="xs" />
                    </q-th>
                    <q-th>
                        <p class="check-text">하락주</p>
                        <q-checkbox v-model="allDownCheck" size="xs" />
                    </q-th>
                    <q-th>
                        <p class="check-text">주도주</p>
                        <q-checkbox v-model="allLEAD" size="xs" />
                    </q-th>
                </q-tr>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                        :class="textColor(col.name, col.value)"
                    >
                        {{ col.value }}
                    </q-td>
                    <q-td class="text-center" colspan="2">
                        <q-checkbox
                            v-model="props.row.stock"
                            @input="handleCheckBox()"
                            size="xs"
                            :disable="props.row.crud == 'D'"
                        />
                    </q-td>
                    <q-td class="text-left">
                        <q-checkbox
                            v-model="props.row.LEAD"
                            @input="handleCheckBox()"
                            size="xs"
                            :disable="props.row.crud == 'D'"
                        />
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:no-data="{ icon, message }"
                ><div class="full-width row flex-center q-gutter-sm">
                    <q-icon size="2em" :name="icon" />
                    <span>{{ message }}</span>
                </div></template
            >
        </q-table>
        <!-- </div> -->
    </article>
</template>

<script>
import DateTime from '@/components/common/DateTime'
import debug from '@/debug'
import { isEmpty, today, stringToArray } from '@/util'

import { mapActions, mapState, mapMutations } from 'vuex'

export default {
    name: 'StockList',
    props: {
        themeCode: {
            type: Number,
            default: -1,
        },
        newsDate: {
            type: String,
            default: null,
        },
        index: {
            type: Number,
            default: -1,
        },
        // 221019 meej 상승 하락 자동 체크 추가
        upDown : {
            type: String,
            default: null,
        },
        // 221025 meej 특징테마에서 테마히스토리 입력시 필요
        propsContent : {
            type: Object,
            default : null,
        }
    },
    components: {
        'i-datetime': DateTime,
    },
    data() {
        return {
            columns: [
                {
                    name: 'code',
                    label: '종목코드',
                    align: 'left',
                    field: (row) => row.stockCode,
                },
                {
                    name: 'name',
                    label: '종목명',
                    align: 'left',
                    field: (row) => row.stockName,
                },
                {
                    name: 'changeRate',
                    label: '등락률',
                    align: 'left',
                    field: (row) =>
                        isEmpty(row.changeRate)
                            ? '-'
                            : row.changeRate === 0
                            ? '0.00%'
                            : Number(row.changeRate / 100).toFixed(2) + '%',
                    sortable: true,
                    // 221025 meej 등락률 정렬 오류 수정
                    sort: (a, b) => parseFloat(a, 10) - parseFloat(b, 10),
                },
            ],
            items: [],
            date: today(),
            allUpCheck: false,
            allDownCheck: false,
            allLEAD: false,
            selected: [],
            selectedLead: [],
        }
    },
    // mounted() {
    //     this.getList()
    // },
    created() {
        /* 
            220523 meej 입력일에 맞춰 stockList 자동 조회
            220701 meej 특징테마, 테마히스토리 각 내용에 맞게 조회 가능하게 수정
         */
        // this.date = this.newsDate !== null ? this.newsDate : today()
        // if(this.selectedDate != null){
        //     this.date = this.selectedDate
        // }else{
            // this.date = this.newsDate !== null ? this.newsDate : today()
        // }
        if(this.newsDate != null){
            this.date = this.newsDate
        }else{
            this.date = this.selectedDate !== null ? this.selectedDate : today()
        }

        this.getList()
    },
    computed: {
        ...mapState({
            checkList: (state) => state.theme_stock.checkList,
            selectedDate: (state) => state.theme_stock.selectedDate,
        }),
    },
    methods: {
        ...mapActions('theme_stock', ['GET_THEMESTOCK_RATE_LIST']),
        ...mapMutations('theme_stock', ['SET_SELETED_DATE']),

        // 221019 meej 상승 하락 자동 체크 추가
        setUpDown(){
            if(this.upDown == null) return
            if(this.upDown == "UP"){
                this.allUpCheck = true
                this.allDownCheck = false
                this.allLEAD = false
                this.items.forEach((i) => {
                    this.allDownCheck = false
                    i.stock = false
                    if (i.changeRate >= 100) i.stock = this.allUpCheck
                })
                this.items = this.items.sort((f, r) => {
                    return r.changeRate - f.changeRate
                })
                debug.log('allUpCheck')
                this.handleCheckBox()
            } else if(this.upDown == "DOWN") {
                this.allUpCheck = false
                this.allDownCheck = true
                this.allLEAD = false
                this.items.forEach((i) => {
                    this.allUpCheck = false
                    i.stock = false
                    if (i.changeRate <= -100) i.stock = this.allDownCheck
                })
                debug.log('allDownCheck')
                this.items = this.items.sort((f, r) => {
                    return f.changeRate - r.changeRate
                })
                this.handleCheckBox()
            }
        },
        getList() {
            this.allUpCheck = false
            this.allDownCheck = false
            this.allLEAD = false
            this.selected = []
            this.selectedLead = []
            const param = {
                date: this.date,
                includeRate: 1,
                themeCode: this.themeCode,
            }

            this.GET_THEMESTOCK_RATE_LIST(param).then((res) => {
                this.items = res.sort((f, r) => {
                    return r.changeRate - f.changeRate
                })
                // 221019 meej 상승 하락 자동 체크 추가
                this.setCheckBox()
                this.setUpDown()
            })
        },
        ...mapMutations('theme_stock', ['SET_CHECKLIST']),
        textColor(name, value) {
            if (name === 'changeRate') {
                if(value.startsWith('-') && value.length > 1) return 'text-blue'
                else if(value === '0.00%' || value === '-') return 'text-black'
                return 'text-red'
            }
            return 'text-black'
        },

        goNext() {
            this.pagination.nextCount++
            this.pagination.page = this.pagination.nextCount * 5 + 1
            this.$emit('next-click')
        },
        handleCheckBox() {
            console.log('this.selected', this.selectedLead, this.selected)
            this.selectedLead = []
            this.selected = []
            this.items.forEach((i) => {
                if (i.LEAD) {
                    this.selectedLead.push(i)
                }
                if (i.stock) {
                    this.selected.push(i)
                }
            })

            if (this.index < 0) {
                debug.log('handleCheckBox setcheck lsit')
                this.SET_CHECKLIST({
                    stocks: this.selected,
                    leads: this.selectedLead,
                })
            } else {
                debug.log('handleCheckBox event')
                //event로도 올려주기
                this.$parent.$emit('sendCheckList', {
                    index: this.index,
                    stocks: this.selected,
                    leads: this.selectedLead,
                })
            }
        },
        // 221025 meej 특징테마 수정 내용 일 경우 설정
        setCheckBox(val){
            if (this.propsContent == null || this.propsContent.showFlag != 1
                || this.propsContent.crud == "D") return
            let stocks = stringToArray(
                    this.propsContent.STOCKS,
                    'stockName',
                    'stockCode'
                )
            let leads = stringToArray(
                this.propsContent.LEAD_STOCK,
                'stockName',
                'stockCode'
            )

            //기존 뉴스에 있는 내용이면 해당 사항을 check해줌
            this.items.forEach((i) => {
                i.stock = false
                i.LEAD = false
            })
            this.items.forEach((i) => {
                if (stocks.some((v) => v.stockCode == i.stockCode)) {
                    i.stock = true
                }
                if (leads.some((v) => v.stockCode == i.stockCode)) {
                    i.LEAD = true
                }
            })
            // 상승하락 정렬 바꿔주기
            if(val == "UP"){
                this.items = this.items.sort((f, r) => {
                    return r.changeRate - f.changeRate
                })
            }else if(val == "DOWN"){
                this.items = this.items.sort((f, r) => {
                    return f.changeRate - r.changeRate
                })
            }
            this.handleCheckBox()
        }
    },
    watch: {
        /* 220523 meej content의 입력일이 바뀌면 자동으로 date변경 설정 */
        selectedDate(val){
            this.date = val
        },
        date(val, old) {
            debug.log('watch date', val, ',', old)
            if (val !== old) {
                /* 220603 meej date 바꾸면 content의 입력일도 바뀌게 설정 */
                this.SET_SELETED_DATE(val)
                this.getList()
            }
        },
        themeCode(val, old) {
            if (val !== -1 && val !== old) {
                this.getList()
            }
        },
        newsDate(val, old) {
            if (val !== old) {
                this.date = val
                this.getList()
            }
        },
        allUpCheck: function(val) {
            this.items.forEach((i) => {
                this.allDownCheck = false
                i.stock = false
                if (i.changeRate >= 100) i.stock = val
            })
            debug.log('allUpCheck')
            this.handleCheckBox()
        },
        allDownCheck: function(val) {
            this.items.forEach((i) => {
                this.allUpCheck = false
                i.stock = false
                if (i.changeRate <= -100) i.stock = val
            })
            debug.log('allDownCheck')
            this.handleCheckBox()
        },
        allLEAD(val) {
            this.items.forEach((i) => {
                i.LEAD = val
            })
            debug.log('allLEAD')
            this.handleCheckBox()
        },
        items: function() {
            debug.log(
                'table watch list!!!!!!!!! items size = ',
                this.items.length
            )
            debug.log(
                'table watch list!!!!!!!!! checkList size = ',
                this.checkList
            )

            //기존 뉴스에 있는 내용이면 해당 사항을 check해줌
            this.items.forEach((i) => {
                i.stock = false
                i.LEAD = false
            })
            this.items.forEach((i) => {
                if (
                    this.checkList.stocks.some(
                        (v) => v.stockCode == i.stockCode
                    )
                ) {
                    i.stock = true
                }

                if (
                    this.checkList.leads.some((v) => v.stockCode == i.stockCode)
                ) {
                    i.LEAD = true
                }
            })
            this.handleCheckBox()
            debug.log('table watch list!!!!!!!!! after items  = ', this.items)
        },
        upDown(){
            // 221019 meej 특징테마에서 히스토리 입력시 상승 하락 자동 정렬 및 체크 추가
            this.setUpDown()
        },
        propsContent(){
            // 221019 meej 특징테마에서 히스토리 입력시 상승 하락 정렬 및 체크 추가
            if(this.propsContent != null && this.propsContent.crud != "D"){
                // 자동 선택 : 급등 강세 상승 -> UP | 급락 약세 하락 -> DOWN
                var keyword = ["급등", "강세", "상승", "급락", "약세", "하락"]
                var kwIndex = [0, 0, 0, 0, 0, 0]
                var maxIndex = -1
                let trend
                for(var msg in keyword){
                    kwIndex[msg] = this.propsContent.content.lastIndexOf(keyword[msg])
                    if(kwIndex[msg] == -1) continue
                    if(maxIndex == -1) {
                        maxIndex = msg
                        continue
                    } 
                    if(msg >=1 && kwIndex[maxIndex]<=kwIndex[msg]) maxIndex = msg
                }
                if(maxIndex == -1)  trend = null
                else if(maxIndex < 3) trend = "UP"
                else if (maxIndex >= 3) trend = "DOWN"
                else trend = null
                this.setCheckBox(trend)
            }
        },
        checkList(){
            console.log("#### watch checkList = ",this.checkList)
        }
    },
}
</script>

<style>
.check-text {
    margin: 0px;
}
</style>
