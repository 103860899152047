<template>
    <article>
        <div class="flex justify-end q-pa-xs" style="height:43.99px">
            <i-btn v-show="isDetailPage" label="취소" @click="goListPage()"/>
        </div>
        <q-scroll-area visible class="i-content-wrap">
            <q-card flat bordered>
                <q-card-section>
                    <div class="row col-12">
                        <i-input class="q-ma-xs col-5" v-model="item.code" inputLabel="증권사코드"/>
                        <i-input class="q-ma-xs col-5" v-model="item.name" inputLabel="증권사명"/>
                    </div>
                    <i-input class="q-ma-xs" v-model="item.opinion" inputLabel="투자의견"/>
                    <div class="flex justify-between q-ma-xs">
                        <i-field v-model="item.newRecommend" inputLabel="신규추천" check />
                        <i-field v-model="item.opi" inputLabel="투자의견" check />
                        <i-field v-model="item.strong" inputLabel="강력매수" check />
                        <i-field v-model="item.consensus" inputLabel="컨센서스" check />
                    </div>
                    <i-input class="q-ma-xs" v-model="item.memo" inputLabel="메모"/>
                    <i-input class="q-ma-xs" v-model="item.url" inputLabel="url"/>
                    <i-input class="q-ma-xs" v-model="item.port" inputLabel="전송 PORT(Default)"/>
                </q-card-section>
            </q-card>
        </q-scroll-area>
        <!-- 하단 버튼 -->
        <div class="q-my-md">
            <i-btn class="full-width" :label="btnLabel" @click="write"/>
        </div>
    </article>
</template>
<script>
import Input from '@/components/common/Input'
import Button from '@/components/common/Button'
import Field from '@/components/common/Field'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Content',
    components: {
        'i-input' : Input,
        'i-btn' :  Button,
        'i-field': Field
    },
    data () {
        return {
        }
    },
    created () {},
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
        }),
        ...mapState( 'manage', ['item']),

        ...mapGetters( 'page', ['isListPage', 'isDetailPage', 'isRegisterPage', 'isFlashPage','isEditPage']),

        btnLabel() {
            return this.isListPage || this.isRegisterPage ? '등록' : '수정'
        }
    },
    methods: {
        ...mapMutations( 'page', ['setPageStatus']),
        ...mapMutations( 'manage', ['INIT_LIST', 'INIT_ITEM']),

        ...mapActions( 'alert', ['showError', 'showMessage']),
        ...mapActions( 'manage', ['ASYNC_COMPANY_LIST', 'WRITE_STOCK_COMPANY']),

        goListPage() {
            this.INIT_ITEM()
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        getList() {
            this.INIT_LIST()
            // TODO vuex.esm.js?2f62:770 [vuex] unknown local action type: manage/ASYNC_COMPANY_LIST, global type: manage/manage/ASYNC_COMPANY_LIST
            this.ASYNC_COMPANY_LIST()
        },

        // 등록 수정 하기
        write() {
            let params = {
                code:this.item.code,
                name:this.item.name,
                comment:this.item.comment,
                opinionOption:this.item.opinion,
                memo:this.item.memo,
                url:this.item.url,
                port:this.item.port
            }
            params.newRecommend = (this.item.newRecommend?1:0)
            params.opinion = (this.item.opi?1:0)
            params.strong = (this.item.strong?1:0)
            params.consensus = (this.item.consensus?1:0)

            params.ad_infostock = (this.item.ad_infostock?1:0)
            params.ad_line = (this.item.ad_line?1:0)

            this.WRITE_STOCK_COMPANY(params)
                .then(() => {
                    this.showMessage({vc:this, message:'업데이트가 완료되었습니다'})
                    this.goListPage()
                    this.getList()
                })
                .catch((e) => {
                    this.showError({vc:this, error:e})
                })
        },

    }
}
</script>