<template>
    <section class="row q-pl-xs">
        <div class="q-pl-md" style="font-size: medium; font-weight: bold;"><br/>신문 상단표
        <span class="todayChangeSize" v-if="today">⏲️{{ today }}</span></div>
        <i-list class="col-12 q-pa-md" ref="closeIndexList"/>
        <Comment />
    </section>
</template>
<script>
import List from '@/components/newspaper/closeIndex/List.vue';
import Comment from '@/components/newspaper/closeIndex/Comment.vue';
import { mapState, mapActions } from 'vuex'

export default {
    name: "CloseIndex",
    props: {
        hide_selected: {
            type: Boolean,
            default: true
        }
    },
    components: {
        "i-list": List,
        Comment,
    },
    data() {
        return {
            today: null,
        }
    },
    computed: {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            stockItems : state => state.resource.stockItems
        })
    },
    created () {
        const today = new Date()
        this.today = (today.getMonth()+1) + "/" + today.getDate()
    },
    methods: {
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
    }
};

</script>
<style>
.todayChangeSize{
    font-size: small;
    font-weight: 600;
    color: #1976d2;
}
</style>
