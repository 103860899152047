<template>
  <div>
    <q-markup-table>
      <caption class="tab-caption-style">
        현황
      </caption>
      <tbody>
        <tr>
          <th>유료회원 :</th>
          <th>{{memberStatus.memberCnt}} 명</th>
          <th>금일 신규 유료회원 :</th>
          <th>{{memberStatus.newCnt}} 명</th>
          <th>금일 종료예정 :</th>
          <th>{{memberStatus.endUseCnt}} 명</th>
          <th>금일 조회수 :</th>
          <th>{{memberStatus.hitMainCnt}} 명</th>
        </tr>
        <tr>
          <th colspan="6">
            <!-- 220628 meej 추후 차트 추가 예정 -->
            <!-- <LineChart
              style="width:100%;height:250px"
              :fCount="memberData"
              :date="dateData"
              :countLabel="labelData"
            /> -->
          </th>
        </tr>
      </tbody>
    </q-markup-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
// import LineChart from "./LineChart.vue"; // 220628 meej 추후 차트 추가 예정

export default {
  name: "MemberChart",
  components: {
    // LineChart, // 220628 meej 추후 차트 추가 예정
  },
  data() {
    return {
      // 220628 meej 추후 차트 추가 예정
      // memberData: [
      //   { x: "2016-12-25", y: 20 },
      //   { x: "2016-12-26", y: 10 },
      //   { x: "2016-12-27", y: 15 },
      //   { x: "2016-12-28", y: 18 },
      //   { x: "2016-12-29", y: 35 },
      //   { x: "2016-12-30", y: 24 },
      // ],
      // labelData: ["유료회원수"],
      // dateData: [
      //             "2016-12-25", 
      //             "2016-12-26",
      //             "2016-12-27",
      //             "2016-12-28",
      //             "2016-12-29",
      //             "2016-12-30",
      // ],
    };
  }, // data end
  created() {
    const params = {};
    this.GET_MEMBER_STAT(params)
  },
  computed: {
    ...mapState('member', ['memberStatus'])

  },
  methods: {
    ...mapActions('member', ['GET_MEMBER_STAT']),

  },
};
</script>

<style>
</style>