<template>
    <article>
        <div class="row ">
            <div class="col-6 q-pa-xs">
                <q-select v-model="beforeNewsFlashType" :options="beforeNewsFlashOptions"
                    @input="selectBeforeType" @filter="filterBefore"
                    dense outlined use-input option-label="name" option-value="value" >
                    <template v-slot:before>
                    <label class="i-label">개장전 속보</label>
                </template>
                </q-select>
            </div>
            <div class="col-6 q-pa-xs">
                <q-select v-if="beforeNewsFlashOptions" v-model="afterFlashType" :options="afterFlashOptions"
                    @input="selectAfterType" @filter="filterAfter"
                    dense outlined use-input option-label="name" option-value="value" >
                    <template v-slot:before>
                        <label class="i-label">장마감 속보</label>
                    </template>
                </q-select>
            </div>
        </div>
        <div class="row q-my-xs">
            <div class="col-6 q-pa-xs">
                <q-select v-model="middleAFlashType" :options="middleAFlashOptions"
                    @input="selectMiddleAType" @filter="filterMiddleA"
                    dense outlined use-input option-label="name" option-value="value" >
                    <template v-slot:before>
                        <label class="i-label">장중 속보A</label>
                    </template>
                </q-select>
            </div>
            <div class="col-6 q-pa-xs">
                <q-select v-model="middleBFlashType" :options="middleBFlashOptions"
                    @input="selectMiddleBType" @filter="filterMiddleB"
                    dense outlined use-input option-label="name" option-value="value" >
                    <template v-slot:before>
                        <label class="i-label">장중 속보B</label>
                    </template>
                </q-select>
            </div>
        </div>
        <div class="row q-pa-xs">
            <div >
                <i-stock-search @get="getStock" label="종목코드"/>
            </div>
            <div class="flex justify-start">
                <q-chip v-for="(item,index) in selectedStocks" :key="'stock'+index"
                    outline square color="black" text-color="white" size="sm" removable @remove="remove(index)">
                    {{ item.name }} ( {{ item.code }} )
                </q-chip>
            </div>
        </div>
    </article>
</template>
<script>
import StockSearch from '@/components/common/StockSearch'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import Debug from '@/debug'

export default {
    name: 'FlashType',
    components: {
        'i-stock-search': StockSearch,
    },
    data () {
        return {
            beforeNewsFlashType:null,
            middleAFlashType:null,
            middleBFlashType :null,
            afterFlashType : null,
            selectedStocks: []
        }
    },
    created () {

    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
            flashTypeOptions : state => state.flash.flashAdminType,
            beforeNewsFlashOptions : state => state.flash.BeforeNewsFlashType,
            middleAFlashOptions : state => state.flash.MiddleAFlashType,
            middleBFlashOptions : state => state.flash.MiddleBFlashType,
            afterFlashOptions : state => state.flash.AfterFlashType,

        }),
        ...mapGetters ({
            isFlashPage : 'page/isFlashPage',
            
        }),
        ...mapGetters('flash', ['isSuccess']),
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
        }),
        ...mapMutations('flash', ['SET_STATUS', 'SET_PREVIEW']),
        ...mapActions({
            GET_FLASH_LIST: 'flash/GET_FLASH_LIST',
            MACRO_UPLOAD: 'resource/MACRO_UPLOAD',
            showError: 'alert/showError',
        }),

        initInputs: function() {
            this.newsFlash = ''
            this.flashType = null
            this.setPageStatus(this.PAGE_STATUS.REGISTER)
        },
        selectAfterType(val) {
            this.$emit('event-select-type', val)
            this.middleBFlashType = null
            this.beforeNewsFlashType = null
            this.middleAFlashType = null
        },
        selectBeforeType(val) {
            this.$emit('event-select-type', val)
            this.middleBFlashType = null
            this.afterFlashType = null
            this.middleAFlashType = null
        },
        selectMiddleAType(val) {
            this.$emit('event-select-type', val)
            this.beforeNewsFlashType = null
            this.afterFlashType = null
            this.middleBFlashType = null
        },
        selectMiddleBType(val) {
            this.$emit('event-select-type', val)
            this.beforeNewsFlashType = null
            this.afterFlashType = null
            this.middleAFlashType = null
        },
        filterBefore(val, update) {
            Debug.log('filterBefore')
            if(this.beforeNewsFlashOptions.length === 0) {
                this.GET_FLASH_LIST('BeforeNewsFlashType')
                    .then(()=> {
                        update()
                    })
                    .catch((err) => {
                        if(err === undefined) {
                            err = '오류가 발생하였습니다.'
                        }
                        this.showError({vc:this, error:err})
                    })
            } else {
                update()
            }
        },
        filterMiddleA(val, update) {
            if(this.middleAFlashOptions.length === 0) {
                this.GET_FLASH_LIST('MiddleAFlashType')
                    .then(()=> {
                        update()
                    })
                    .catch((err) => {
                        if(err === undefined) {
                            err = '오류가 발생하였습니다.'
                        }
                        this.showError({vc:this, error:err})
                    })
            } else {
                update()
            }

        },
        filterMiddleB(val, update) {
            if(this.middleBFlashOptions.length === 0) {
                this.GET_FLASH_LIST('MiddleBFlashType')
                    .then(()=> {
                        update()
                    })
                    .catch((err) => {
                        if(err === undefined) {
                            err = '오류가 발생하였습니다.'
                        }
                        this.showError({vc:this, error:err})
                    })
            } else {
                update()
            }
        },
        filterAfter(val, update) {
            if(this.afterFlashOptions.length === 0) {
                this.GET_FLASH_LIST('AfterFlashType')
                    .then(()=> {
                        update()
                    })
                    .catch((err) => {
                        if(err === undefined) {
                            err = '오류가 발생하였습니다.'
                        }
                        this.showError({vc:this, error:err})
                    })
            } else {
                update()
            }
        },
        getStock(stock) {
            if(stock.code0 === 'YYYYYY' || stock.code0 === 'KKKKKK') {
                this.title = stock.name + '지수'
            }
            this.selectedStocks.push(stock)
            this.$emit('event-select-stock', this.selectedStocks)
        },
        remove(index) {
            this.selectedStocks.splice(index,1)
            this.$emit('event-select-stock', this.selectedStocks)
        },
        parentClear() {
            Debug.log('parentClear!!!!')
            this.selectedStocks = []
            this.middleBFlashType = null
            this.afterFlashType = null
            this.middleAFlashType = null
            this.beforeFlashType = null
        },
        uploadMacro(newsType, content, sendTime) {
            let params ={
                service:newsType.value,
                content:content,
                sendTime:sendTime
                }
            this.MACRO_UPLOAD(params)
                .then((res) => {
                Debug.log('data = ',res)
                // let flashData = {
                //     sendTime:res.data.sendTime,
                //     preview:res.data.content,
                //     title: res.data.title,
                //     newsType: res.data.newsType,
                //     newsType3: res.data.newsType3,
                //     origin: res.data.origin
                // }
                this.SET_PREVIEW(res.data)

                //this.reWrite();
                this.showPreview()
            })
            .catch((err) => {
                Debug.log('error = ', err)
                this.showError({ vc:this, error:err})
            })
        },
        showPreview() {
            this.windowRef = window.open(
                    `/preview/popup.htm`,
                    '시황속보 미리보기',
                    'width=620,height=820'
                )
            if(this.windowRef) {
                this.windowRef.addEventListener('beforeunload', this.closePreview)
            }
        },
        // closePreview() {
        //     this.windowRef = null
        //     if (this.isSuccess) {
        //         this.showMessage({ vc: this, message: '전송되었습니다.' })
        //         this.SET_STATUS(0)
        //     }

        // },
    },
    beforeCreate (){
    }
}
</script>
