<template>
 <div>
    <div class="inputBox">
        <!-- 검색값 표시 -->
        <span v-if="searched" style="color: #2196F3; font-weight: 600;"> {{ searched }} </span>
        <!-- 테마검색창 -->
        <input
            ref="headersearch"
            class="header-searchbar"
            type="text"
            placeholder="테마검색"
            @input="inputText = $event.target.value"
            @keyup.enter="hitEnter"
            @keyup.esc="keyEscape"
            @keydown.down="keyDown"
            @keydown.up="keyUp"
            @focus="getFocusListAll"
        />
    </div>
    <ul v-if="toggle" class="autobox" style="z-index: 9999; position: absolute; height: 700px;">
        <!-- 자동완성 리스트 -->
        <li
            v-for="item in themeOptions"
            :key="item.code"
            ref="li_refs"
            @click="onBoxClick(item.code)"
            :style="item.type && item.type == 'DELETE' ? 'color: #BBBBBB;' : ''"
        >
            <span class="autoliItem">
                <p>{{ item.name }}</p>
            </span>
        </li>
    </ul>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
    name: 'ThemeSearch',
    props: { 
    // 22.10.28 SUYE 쿼사q-input버리고 새로 만들어서 props 대부분 사용하지 않음 
        extra: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            default: '테마',
        },
        inputData: {
            type: Object,
            default: null,
        },
        filterD: {
            type: Boolean,
            default: false,
        },
        status: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            theme: '',          // 선택된 테마 
            inputText: '',      // 사용자 입력값 
            currentFocus: -1,   // 키보드 선택시 포커스 제어 
            toggle: false,      // select박스토글
            themeOptions: [],   // 테마 리스트 
            searched: '',       // 검색값 표시
        }
    },
    computed: {
        ...mapState({
            themeItems: (state) => state.theme.items,
        }),
    },
    created() {
        if (this.label) {
            this.innerLabel = ''
        }
        this.GET_THEME_LIST({ type: this.status }).then(() => {
            this.themeOptions = this.themeItems
            console.log('themeItems? ', this.themeItems)
        })
    },

    watch: {
        // 두글자 이상 입력해야 자동완성 랜더링 되도록 만들어주는 조건
        inputText() {
            console.log('inputText?', this.inputText)
            const parsedobj = this.themeItems && JSON.parse(JSON.stringify(this.themeItems))
            // console.log('parsedobj= ', parsedobj)

            if (this.inputText.length > 1) {
                this.toggle = true

                const result =
                    parsedobj &&
                    parsedobj.filter(
                        (item) =>
                            item.name
                                .toLowerCase()
                                .includes(this.inputText.toLowerCase().trim())
                    )
                // console.log('result ', result)
                
                if (result.length === 0) {
                    return [{ name: '해당되는 테마가 없습니다.' }]
                } else {
                    // 22.11.09 theme.js에서만 처리 
                    // 22.10.31 SUYE 검색시 추천박스 정렬
                    // result.sort((a, b) => {

                    //     if(a.type !== 'DELETE' && b.type == 'DELETE') { return -1 }
                    //     if(a.type == 'DELETE' && b.type !== 'DELETE') { return 1 }

                    //     if(a.name > b.name) { return -1 } 
                    //     if(a.name < b.name) { return 1 }

                    //     if(a.name.length > b.name.length) { return 1 } 
                    //     if(a.name.length < b.name.length) { return -1 }

                    //     if(a.name.localeCompare(b.name) > b.name.localeCompare(a.name)) { return -1 }
                    //     if(a.name.localeCompare(b.name) < b.name.localeCompare(a.name)) { return 1 }

                    // }) 
                    // console.log('result SORTED ', result)
                    this.themeOptions = result
                }
            }
            this.currentFocus = -1

            if (this.$refs.li_refs !== undefined) {
                this.removeActive()
            }

        },
        
        currentFocus(){
            if(this.currentFocus) console.log('currentFocus', this.currentFocus)
        },
    },
    beforeMount() {
        window.addEventListener('click', this.closeAllLists)
    },
    beforeDestroy() {
        window.removeEventListener('click', this.closeAllLists)
    },
    methods: {
        ...mapActions('theme', ['GET_THEME_LIST']),
        ...mapActions('theme_news', ['ASYNC_THEMENEWS_LIST']),
        
        getFocusListAll(){
            this.toggle = true      
            if((this.inputText == '' || this.inputText == undefined || this.inputText == null) && this.toggle == true)
                {
                    this.currentFocus = -1
                    this.themeOptions = this.themeItems
                    console.log('themeOptions? ', this.themeOptions)
                }
        },

        hitEnter(event) {
            console.log('hitEnter')
            // 검색값 입력 제어 
            if (this.inputText.trim() === '' && this.toggle == false) {
                alert('테마명을 입력해주세요.')
            } 
            else {
            // 검색값 존재시 
                // console.log('검색값 존재', this.currentFocus)
                // selectBox 선택시
                if (this.currentFocus > -1) {
                    // console.log('selectBox 선택시 currentFocus', this.currentFocus)
                    const ref = this.$refs.li_refs
                    // console.log('ref', ref)
                    const selectedItem = ref.filter(
                        (item) => item.className === 'autocomplete-active'
                )
                // console.log('selectedItem', selectedItem[0].textContent)

                if (selectedItem[0].textContent.trim() !== null 
                    && selectedItem[0].textContent.trim() !== undefined 
                    && selectedItem[0].textContent.trim() !== '') 
                {
                    this.searched = selectedItem[0].textContent
                    console.log('searched=', this.searched)
                    this.theme = this.themeOptions[this.currentFocus]
                    // console.log('currentFocus theme? ', this.theme)
                    this.sendTheme(this.theme)
                }
                // selectBox 미선택시
                } else if (this.currentFocus == -1) {
                    // console.log('selectBox 미선택시 currentFocus', this.currentFocus)
                    const ref = this.$refs.li_refs
                    ref[0].className = 'autocomplete-active'
                    const selectedItem = ref
                    // console.log('selectedItem2 ', selectedItem[0].textContent)
                    if (
                        selectedItem[0].textContent.trim() !== null 
                        && selectedItem[0].textContent.trim() !== undefined 
                        && selectedItem[0].textContent.trim() !== '') 
                    {
                        this.searched = selectedItem[0].textContent
                        console.log('searched=', this.searched)
                        this.theme = this.themeOptions[0]
                        // console.log('currentFocus theme? ', this.theme)
                        this.sendTheme(this.theme) 
                    }
                } 
                this.inputText = ''
                event.target.value = ''
                this.toggle = false
            } 
            // this.$refs.headersearch.value = '' // 검색필드 클리어
        },

        // 부모 @get에 선택된 테마 보냄
        sendTheme(theme) {
            // console.log('ThemeSearch sendTheme', theme)
            this.$emit('get', theme)
            this.currentFocus = -1
        },

        // 자동완성 아이템 클릭 시, queryString으로 이동시키는 메소드
        onBoxClick(code) {
            this.$refs.headersearch.value = '' // 검색필드 클리어
            if (code) {
                this.inputText = ''
                this.toggle = false
                this.currentFocus = -1
                const tempList = this.themeOptions
                const selectedItem = tempList.filter(
                    (item) => item.code === code
                )
                console.log('clicked selectedItem', selectedItem[0].name)
                this.theme = selectedItem[0]
                this.searched = selectedItem[0].name

                this.sendTheme(this.theme)
            }
        },

        // 아래로 키보드 눌렀을 때, 스크롤 포커스도 따라가게 만드는 메소드
        outboundScrolling() {
            const ref = this.$refs.li_refs

            if (
                ref[this.currentFocus].offsetTop <
                ref[this.currentFocus].parentNode.scrollTop
            ) {
                // Hidden on top, move scroll to show item
                // Just to the top of item
                ref[this.currentFocus].parentNode.scrollTop =
                    ref[this.currentFocus].offsetTop
            } else if (
                ref[this.currentFocus].offsetTop >
                ref[this.currentFocus].parentNode.scrollTop +
                    ref[this.currentFocus].parentNode.clientHeight -
                    ref[this.currentFocus].clientHeight
            ) {
                // Hidden on bottom, move scroll to top of item + item height
                ref[this.currentFocus].parentNode.scrollTop =
                    ref[this.currentFocus].offsetTop -
                    (ref[this.currentFocus].parentNode.clientHeight -
                        ref[this.currentFocus].clientHeight)
            }
        },

        // ese 눌렀을 때, 자동완성 hide
        keyEscape() {
            this.toggle = false
            this.currentFocus = -1
        },

        // 자동완성 아래방향 키입력
        keyDown() {
            const ref = this.$refs.li_refs

            if (ref !== undefined && ref.length - 1 > this.currentFocus) {
                this.removeActive()
                this.currentFocus++
                ref[this.currentFocus].classList.add('autocomplete-active')

                this.outboundScrolling()
            }
        },

        // 자동완성 아래방향 키입력
        keyUp() {
            const ref = this.$refs.li_refs

            if (this.currentFocus > 0) {
                this.removeActive()
                this.currentFocus--
                ref[this.currentFocus].classList.add('autocomplete-active')

                this.outboundScrolling()
            }
        },

        // autocomplete-active 클래스를 제거해주는 메소드
        removeActive() {
            for (let i = 0; i < this.$refs.li_refs.length; i++) {
                this.$refs.li_refs[i].classList.remove('autocomplete-active')
            }
        },
        // 자동완성 부분 외의 부분을 클릭할 시, 자동완성 토글창 닫도록 만드는 조건
        closeAllLists(elem) {
            if (
                elem.target.className !== 'autobox' &&
                elem.target.className !== 'header-searchbar'
            ) {
                this.toggle = false
            }
        }, 
        removeSearched() {
            this.searched = false
        }
    }
}
</script>
<style>

.autocomplete-active {
    background-color: #EEEEF3 !important;
    border-radius: 5px;
}

.header-searchbar {
    border-bottom: 2px solid slategrey;
    width: 500PX;
    height: 40px;
    font-size: 15px;
    border: 0;
    border-radius: 4px;
    outline-color: #2196F3;
    padding-left: 10px;
    background-color: rgb(233, 233, 233);
}
.autobox {
    list-style : none;
    border-bottom: 1px solid #E2E2E7;
    padding: 4px 2px;
    width: 400px;
    height: 700px;
    min-height: 700px;
    margin-left: 7%;
    left: auto;
    z-index: 9999;
    border: 1px solid #D0D0D5;
    /* 리스트 크기에 맞춰서 박스도 제한되는 것 때문에 임시 positon: initial */
    position: absolute;
    /* position: initial;  */
    padding: 4px;
    overflow: auto;
    background: white;
    cursor: pointer;
}

</style>
