<template>
    <article>
        <q-toolbar class="i-top-wrap text-right">
            <q-space />
            <i-btn v-if="!isListPage" label="취소" @click="goListPage()"/>
        </q-toolbar>
        <q-scroll-area visible class="i-content-wrap">
            <div class="row q-pt-xs">
                <i-select class="col-6 q-ml-xs" v-model="country"
                    :options="countryOptions" label="나라"
                    option-label="name0" option-value="value0"  />
                <div v-show="country.value0 === 'KOR'" class="col-12 col-md-4 q-ml-xs">
                <i-select  v-model="type"
                    :options="typeOptions" label="구분"
                    option-label="name0" option-value="value0" />
                </div>
            </div>

            <q-card flat bordered class="q-mt-xs">
                <div v-if="isEditPage || isDetailPage" class="row q-pt-md q-pl-md">
                    <div class="col-xs-6 col-lg-4 q-pa-xs">
                        <i-field label="입력일" :text="schedule.createTime" disable />
                    </div>
                    <div class="col-xs-6 col-lg-4 q-pa-xs">
                        <i-field :text="schedule.lastUpdateTime" label="수정일" disable />
                    </div>
                    <!-- <div class="col-sm-4 q-pt-xs">
                        <i-field v-model="schedule.writer" label="입력자" disable />
                    </div> -->
                </div>
                <div class="q-ma-md"  v-for="(event, index) in scheduleList" :key="'new-'+index">
                    <div v-if="index > 0" class="col-12 text-right">
                        <i-btn label="삭제" @click="deleteSchedule(index)"/>
                    </div>
                    <div class="row q-pt-xs">
                        <div class="col-10 col-lg-5">
                            <i-datetime v-model="event.eventDate" date dateLabel="휴무일" />
                        </div>

                        <div v-show="event.sequence" class="col-10 col-lg-5">
                            <i-datetime v-model="event.endDate" date dateLabel="종료일자" />
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <i-field check v-model="event.sequence" label="연속" @input="checkSequence(index)"/>
                        </div>
                    </div>
                    <div class="justify-between" v-if="type.value0 == 'CLOSED'">
                        <i-input class="full-width q-pt-xs" v-model="event.title" label="제목 (미입력시 '나라' 증시 휴장)" />
                    </div>
                    <div class="justify-between">
                        <i-input class="full-width q-pt-xs" v-model="event.content" label="내용" />
                    </div>
                    <q-separator class="q-mt-md"/>
                </div>

            </q-card>
        </q-scroll-area>

        <!-- 버튼 -->
        <div v-if="isDetailPage" class="row q-my-md text-right">
            <div class="col-12 col-sm-6 q-pa-xs">
                <i-btn class="full-width" label="수정" @click="goSave()"/>
            </div>
            <div class="col-12 col-sm-6 q-pa-xs">
                <i-btn class="full-width" label="삭제" @click="deleteItem()"/>
            </div>

        </div>
        <div v-else class="row q-my-md">
            <div class="col-12 col-sm-6 q-pa-xs">
                <i-btn class="full-width" label="추가" @click="addSchedule()"/>
            </div>
            <div class="col-12 col-sm-6 q-pa-xs">
                <i-btn class="full-width" label="등록" @click="goSave()"/>
            </div>
        </div>
    </article>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import Button from '@/components/common/Button'
import DateTime from '@/components/common/DateTime'
import Input from '@/components/common/Input'
import Debug from '@/debug'
import Field from '@/components/common/Field'
import Select from '@/components/common/Select'
import { FormatDate, isEmpty, clone } from '@/util'

export default {
    name: 'Content',
    components: {
        'i-datetime': DateTime,
        'i-field': Field,
        'i-btn' :  Button,
        'i-input': Input,
        'i-select': Select
    },
    props: {
        yearOptions: Array,
    },
    data () {
        return {
            type: {name0:'휴장', value0:'CLOSED'},
            country: {name0:'국내', value0:'KOR'},
            scheduleList:[],
            //schedule 정의
            defaultSchedule:{
                id: null,
                title:null,
                content:'',
                eventDate: null,
                endDate:null,
                sequesce: false,
            },
            schedule:{
            },
            lastIndex : 0,
        }
    },
    created () {
        // 전송시간 오늘날짜로 설정
        this.initSchedule()

        // EventBus를 통한 리소스 카드 추가하기
        this.$parent.$on('sendResource', this.addResource)
//        this.$parent.$on('goDetail', this.getScheduleDetail)
    },
    computed : {
        ...mapState ({
            countryOptions : state => state.schedule.countries,
            typeOptions : state => state.schedule.closedTypes,
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
            selectItem : state => state.schedule.item,
        }),
        ...mapGetters( 'page', ['isListPage', 'isEditPage', 'isRegisterPage', 'isDetailPage']),

    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
            REQUEST_RESOURCE: 'resource/REQUEST_RESOURCE',
            INIT_LIST: 'schedule/INIT_LIST',
        }),
        ...mapActions( 'schedule', ['ASYNC_CLOSED_LIST', 'WRITE_CLOSED', 'DELETE_ITEMS']),
        ...mapActions( 'alert', ['showError', 'showMessage', 'showAlert']),
        initSchedule() {
            this.schedule = clone(this.defaultSchedule)
            this.scheduleList = []
            var s = clone(this.defaultSchedule)
            this.scheduleList.push(s)
        },
        // 목록화면으로 이동하기
        goListPage() {
            this.initSchedule()
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        // 새 카드 추가하기
        addSchedule() {
            var s = clone(this.defaultSchedule)
            this.scheduleList.push(s)
        },
        // 카드 삭제
        deleteSchedule(index) {
            this.scheduleList.splice(index, 1)
        },
        finishUpload(success, fail, total) {
            if((success + fail) === total) {
                let message = (this.isDetailPage?(success > fail?'수정되었습니다.':'수정에 실패하였습니다.'):'등록되었습니다. (총 '+total+'중 '+success+' 성공)')
                if(success > fail) {
                    this.showMessage({vc:this, message:message} )
                } else {
                    this.showError({vc:this, error:message} )
                }
                this.goListPage()
            }
        },
        /**
            API 통신
         */
        // 신규입력 or 수정
        goSave(){
            // 220819 meej 유효성 체크 추가
            for(let i = 0; i < this.scheduleList.length; i++ ) {
                let schedule = this.scheduleList[i]
                if(isEmpty(schedule.title) && isEmpty(schedule.content)) {
                    this.showError({vc:this, error:"내용을 입력해주세요."} )
                    return
                }
            }
            // 220819 meej 수정상태, 등록상태 추가
            if(this.schedule.id !== null) {
                this.setPageStatus(this.PAGE_STATUS.DETAIL)
            }else{
                this.setPageStatus(this.PAGE_STATUS.REGISTER)
            }
            // 리스트 숫자만크
            this.showAlert({vc:this,message:(this.isDetailPage?'수정하시겠습니까':'등록하시겠습니까')})
                .then(()=>{
                    this.doWrite()
                })
        },
        doWrite() {
            let defaultParams = {}
            if(this.schedule.id !== null) {
                defaultParams.id	     = this.schedule.id
            }
            defaultParams.closedType = this.type.value0
            defaultParams.country	 = this.country.value0

            let success = 0, fail = 0, total = this.scheduleList.length

            for(let i = 0; i < total; i++ ) {
                let schedule = this.scheduleList[i]
                // 22.10.19 SUYE 제목 미입력시: XX 증시 휴장
                if(isEmpty(schedule.title)){
                    if(this.type.value0 == 'CLOSED'){
                        schedule.title = (this.country.name0.includes('증시') ? this.country.name0.replace('증시', '').trim() : this.country.name0.trim()) + ' 증시 휴장'
                    }
                } 
                if(isEmpty(schedule.title) && isEmpty(schedule.content)) {
                    continue
                }
                let params = clone(defaultParams)
                params.title	 = schedule.title
                params.content	 = schedule.content
                params.zindex	 = schedule.zindex
                params.startDate = schedule.eventDate
                params.sequence	 = (schedule.sequence?1:0)
                if(schedule.sequence) {
                    params.endDate = schedule.endDate
                } else {
                    params.endDate = schedule.eventDate
                }
                this.WRITE_CLOSED(params)
                    .then((response) => {
                        if(response.success) {
                            success++

                            this.scheduleList.splice(i, 1)
                        } else {
                            fail++
                        }
                        this.finishUpload(success, fail, total)
                    })
                    .catch(()=> {
                        this.finishUpload(success, fail++, total)
                    })
            }
        },
        deleteItem() {
            this.showAlert({vc:this,message:'삭제하시겠습니까?'})
                .then(()=>{
                    this.doDelete()
                })


        },
        doDelete() {
            var params = {}
            params.id = this.schedule.id
            params.newsType = this.schedule.newsType1
            Debug.log('deleteItem = ', this.schedule)
            this.DELETE_ITEMS(params)
            .then(() => {
                this.showMessage({ vc:this, message:'삭제되었습니다.' })
                this.INIT_LIST()
                this.goListPage()
            })
            .catch((err) => {
                var error = err.message
                this.showError({ vc:this, error:error })
            })
        },
        checkSequence(index) {
            if(this.scheduleList[index].sequence) {
                Debug.log('checkSequence = ', this.scheduleList[index].endDate)
            } else {
                // 22.10.19 SUYE 연속 미체크시 현일자x 설정일자로 적용 
                this.scheduleList[index].endDate = this.scheduleList[index].eventDate
            }
        }
    },
    watch: {
        selectItem: function(val) {
            this.schedule = clone(val)
            this.eventDate = this.schedule.eventDate

            this.schedule.createTime = FormatDate(val.createTime)
            this.schedule.lastUpdateTime = FormatDate(val.lastUpdateTime)
            this.schedule.eventDate = val.eventDate
            this.schedule.endDate = (isEmpty(val.endDate)? val.eventDate: val.endDate)

            this.schedule.sequence = (val.sequence === 2? true:false)
            this.type = this.typeOptions.find(v => v.value0 === val.closedType)
            this.country = this.countryOptions.find(v => v.value0 === val.closedCountry)
            this.scheduleList = []
            this.scheduleList.push(this.schedule)
            //this.requestResource(0, 'Event', true)
            Debug.log('watch schedule --------------', this.schedule)
        },

    }
}
</script>