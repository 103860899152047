<template>
    <div></div>
</template>

<script>
export default {
    name: 'NonePage',
}
</script>

<style></style>
