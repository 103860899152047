<template>
    <article>
        <q-toolbar class="i-top-wrap">
            <q-space/>
            <i-btn label="취소" @click="goListPage()"/>
        </q-toolbar>
        <q-scroll-area class="i-content-wrap">
            <q-card flat bordered>
                <q-card-section>
                    <div class="row">
                        <div class="col-12 q-pa-xs">
                            <i-stock-search v-show="isRegisterPage" @get="getStock" label="종목검색"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 q-pa-xs">
                            <i-input v-model="item.stockCode" inputLabel="종목코드" disable/>
                        </div>
                        <div class="col-6 q-pa-xs">
                            <i-input v-model="item.stockName" inputLabel="종목명" disable/>
                        </div>
                    </div>
                    <i-input class="q-my-sm" v-model="item.content" inputLabel="개요 부가설명" type="textarea" :rows="20" />
                </q-card-section>
            </q-card>
        </q-scroll-area>
        <!-- 하단 버튼 -->
        <div class="q-my-xs">
            <i-btn class="full-width" label="저장" @click="write()"/>
        </div>
    </article>
</template>
<script>
import Debug from '@/debug'

import Button from '@/components/common/Button'
import Input from '@/components/common/Input'
import StockSearch from '@/components/common/StockSearch'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Content',
    components: {
        'i-input': Input,
        'i-stock-search': StockSearch,
        'i-btn' :  Button
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
        }),
        ...mapState( 'stock_summary_extra', ['item']),
        ...mapGetters( 'page', ['isListPage', 'isDetailPage', 'isEditPage', 'isRegisterPage']),
    },
    methods: {
        ...mapMutations( 'page', ['setPageStatus']),
        ...mapMutations( 'stock_summary_extra', ['INIT_LIST']),

        ...mapActions( 'alert', ['showError', 'showMessage']),
        ...mapActions( 'stock_summary_extra', ['GET_LIST', 'GET_DETAIL', 'WRITE_ITEM']),

        goListPage() {
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        getStock(stock) {
            this.item.stockCode = stock.code
            this.item.stockName = stock.name
        },
        write() {

            var message

            // 빈값 체크
            if(!this.item.stockCode || !this.item.stockName || !this.item.content) {
                message = '내용을 입력해주세요.'
                this.showMessage({ vc:this, message:message })
            }

            var params = {}
            params.stockCode = this.item.stockCode
            params.content = this.item.content
            // TODO: title 무엇으로 할지

            if (this.isDetailPage) {
                params.id = this.item.id
            }

            this.WRITE_ITEM(params)
            .then(() => {
                message = this.isRegisterPage ? '등록되었습니다.' : '수정되었습니다.'
                this.showMessage({ vc:this, message:message })

                this.INIT_LIST()
                this.GET_LIST({})

                if (this.isRegisterPage) {
                    this.goListPage()
                }
            })
            .catch((err) => {
                Debug.log('error = ', err)
                var error = err.message
                this.showError({ vc:this, error:error })
            })
        }
    }
}
</script>


