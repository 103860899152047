import api from '@/services/InfostockService'
import Debug  from '@/debug'

const state = () => ({
    newsType: 'STOCK_NEW_RECOMMEND',
    list: [],
    nextKey: null,
    item: {
        id: '',
        stockCode: '',
        stockName: '',
        STOCK_COMPANY0: '',
        RECOMMEND_TYPE: '',
        PRICE: '',
        content: '',
        EXCLUSION0: '',
        CAUSE: '',
        sendTime: ''
    }
})

const getters = {}

const mutations = {
    SET_LIST(state, { data }) {
        var arr = state.list
        arr.push(...data.items)

        // 인덱스 추가
        arr.forEach((row, index) => {
            row.index = index
        })

        state.list = arr
        state.nextKey = data.nextKey
    },
    SET_DETAIL(state, item) {
        state.item = item
    },
    INIT_LIST(state) {
        state.list = []
        state.nextKey = null
    },
    INIT_DETAIL(state) {
        state.item = {
            id: '',
            stockCode: '',
            stockName: '',
            STOCK_COMPANY0: '',
            RECOMMEND_TYPE: '',
            PRICE: '',
            content: '',
            EXCLUSION0: '',
            CAUSE: '',
            sendTime: ''
        }
    }
}

const actions = {
    // 목록 조회
    async GET_LIST({ state, commit }, params) {
        params.newsType = state.newsType
        params.nextKey = state.nextKey


        Debug.log('GET_LIST params', params)
        const { data } = await api.stockNewsList(params)
        Debug.log('GET_LIST data', data)

        commit('SET_LIST', { data })
    },
    // 상세 조회
    GET_DETAIL({ state }, params) {
        params.newsType = state.newsType

        Debug.log('GET_DETAIL params', params)
        return new Promise((resolve, reject) => {
            api.stockNewsDetail(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 작성 및 수정
    async WRITE_ITEM({ state }, params) {
        params.newsType = state.newsType

        Debug.log('WRITE_ITEM params', params)
        const { data } = await api.stockNewsWrite(params)
        Debug.log('WRITE_ITEM data', { data })
    },
    //  삭제
    async DELETE_ITEMS(_, params) {
        Debug.log('DELETE_ITEM params', params)
        const { data } = await api.stockNewsMultiDelete(params)
        Debug.log('DELETE_ITEM data', { data })
    }
}

export const stock_new_recommend = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
