import api from '@/services/InfostockService'
import Debug from '@/debug'

const state = () => ({
    list: [],
    nextKey: null,
    item: null,
    // 220628 meej 회원수 현황
    memberStatus : {
        memberCnt: 0,
        newCnt : 0,
        endUseCnt : 0,
        hitMainCnt: 0,
    }
})

const getters = {}

const mutations = {
    SET_LIST(state, items) {
        state.list = items
        // state.nextKey = data.nextKey
    },
    SET_ITEM(state, item) {
        state.item = item
    },
    INIT_LIST(state) {
        state.list = []
        state.nextKey = null
    },
    INIT_ITEM(state) {
        state.item = null
    },
    // 220628 meej 회원수 현황
    SET_MEMBER_STAT(state, data) {
        state.memberStatus = data
    }
}

const actions = {
    // 목록 조회
    GET_LIST(_, params) {
        Debug.log('GET_LIST params', params)

        return new Promise((resolve, reject) => {
            api.memberList(params)
                .then((response) => {
                    resolve(response.data)
                    Debug.log('GET_LIST data', response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 회원 조회
    async GET_DETAIL({ commit }, params) {
        Debug.log('GET_DETAIL params', params)
        const { data } = await api.memberDetail(params)
        Debug.log('GET_DETAIL data', data)

        commit('SET_ITEM', { data })
    },
    // 220628 meej 회원수 현황 조회
    async GET_MEMBER_STAT({commit}, parmas){
        const {data} = await api.memberStatus(parmas)
        commit('SET_MEMBER_STAT', data)
    }
}

export const member = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
