<template>
    <article>
        <!-- 목록 -->
        <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : list.length == 0 }"
            :columns="columns" :data="list" row-key="index" @row-click="getItem"
            :pagination.sync="pagination" hide-pagination no-data-label="조회되는 데이터가 없습니다."
        />
        <div v-show="list.length != 0" class="flex flex-center q-mt-md">
            <q-pagination
                v-model="pagination.page"
                :max="pagesNumber"
                :max-pages="pagination.maxPages"
                :direction-links="true"
                :boundary-numbers="false"
                :boundary-links="true"
                :ellipses="false"
            />
        </div>
        </article>
</template>
<script>
import Debug from '@/debug'
import CONSTANTS from '@/constants'
import { FormatDate } from '@/util'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    props: {
        condition: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            columns: [
                { name: 'stock', label: '종목', align: 'left', field: row => `${row.name} ( ${row.code} )` },
                { name: 'corpCls', label: '회사구분', align: 'left',field: row => {
                        var temp = this.condition.corpClsOptions.find(v => v.value == row.corpCls)
                        return temp ? temp.label : row.corpCls
                    }
                },
                { name: 'type', label: '상태', align: 'left',field: row => {
                        var temp = this.stockTypeOptions.find(v => v.value0 == row.type)
                        return temp ? temp.name0 : row.type
                    }
                },
                { name: 'modifyDate', label: '변경일자', align: 'left', field: row => FormatDate(row.modifyDate) },
            ],
            pagination: {
                page: 1,
                rowsPerPage: 30,
                nextCount: 0,
                maxPages: CONSTANTS.DEFAULT_PAGE_NUM
            }
        }
    },
    created () {
        this.getList()
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
            stockType : state => state.resource.stockType,
            list :  state => state.stock.list,
        }),
        ...mapGetters( 'page', ['isListPage', 'isDetailPage', 'isEditPage', 'isRegisterPage']),

        pagesNumber () {
            return Math.ceil(this.list.length / this.pagination.rowsPerPage)
        },
        stockTypeOptions () {
            var arr = [{name0 : '전체', value0: null}]
            arr.push(...this.stockType)
            return arr
        }
    },
    methods: {
        ...mapMutations( 'page', ['setPageStatus']),
        ...mapMutations( 'stock', ['SET_LIST', 'SET_DETAIL', 'SET_TEMP_CODE']), 

        ...mapActions( 'alert', ['showError', 'showMessage']),
        ...mapActions( 'stock', ['GET_LIST', 'GET_DETAIL']),

        // 목록 조회하기
        getList() {
            var params = {
                type: this.condition.type.value0,
                corpCls: this.condition.corpCls.value
            }

            this.GET_LIST(params)
            .then((data) => {
                var items = data.items
                //최근 변경날짜별로 sorting
                items.sort(function(i1, i2) {
                return i1.lastUpdateDate < i2.lastUpdateDate ? -1 : i1.lastUpdateDate > i2.lastUpdateDate ? 1 : 0
                })
                this.SET_LIST(items)

                if (items[0]) {
                    this.getDetail(items[0].code)
                }
            })
            .catch((err) => {
                Debug.log('error = ', err)
                var error = err.message
                this.showError({ vc:this, error:error })
            })
        },
        // 상세 조회하기
        getItem(_,item) {
            // 이 메뉴에서는 List와 Detail 동일하게 사용됨.
            this.setPageStatus(this.PAGE_STATUS.LIST)

            this.getDetail(item.code)
        },
        // 상세 조회 API
        getDetail(code) {


            var params = {}
            params.code = code
            this.SET_TEMP_CODE(code) // 종목코드 임시저장값 

            this.GET_DETAIL(params)
            .then((data) => {
                data.type = this.stockType.find(v => v.value0 == data.type)
                this.SET_DETAIL(data)
            })
            .catch((error) => {
                Debug.log('error = ', error)
                this.showError({ vc:this, error:error })
            })
        },
    }
}
</script>



