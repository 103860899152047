import Debug from '../../../debug'

const state = () => {
    return {
        isActive: false
    }
}

const actions = {
    // 220712 meej 확인 알림창 추가
    showConfirm ({ state, commit }, { vc, message }) {
        if(!state.isActive) {
            commit('active')
            return new Promise((resolve, reject) => {
                vc.$q.dialog({
                    title: '알림',
                    message: message,
                    cancel: false,
                    html: true,
                }).onOk(() => {
                    Debug.log('OK')
                    commit('disable')
                    resolve()
                }).onCancel(() => {
                    commit('disable')
                    Debug.log('Cancel')
                    reject()
                }).onDismiss(() => {
                    Debug.log('I am triggered on both OK and Cancel')
                })
            })
        }
    },
    showAlert ({ state, commit }, { vc, message }) {
        if(!state.isActive) {
            commit('active')
            return new Promise((resolve, reject) => {
                vc.$q.dialog({
                    title: '알림',
                    message: message,
                    cancel: true,
                    html: true,
                }).onOk(() => {
                    Debug.log('OK')
                    commit('disable')
                    resolve()
                }).onCancel(() => {
                    commit('disable')
                    Debug.log('Cancel')
                    reject()
                }).onDismiss(() => {
                    Debug.log('I am triggered on both OK and Cancel')
                })

            })


        }
    },
    showDeleteAlert ({ state, commit }, { vc, message }) {
        if(!state.isActive) {
            commit('active')
            return new Promise((resolve, reject) => {
                vc.$q.dialog({
                    title: '알림',
                    //color: 'negative',
                    message: message,
                    html: true,
                    cancel: true,
                    ok: {
                        push: true,
                        color: 'negative',
                        label: '삭제'
                    }
                }).onOk(() => {
                    Debug.log('OK')
                    commit('disable')
                    resolve()
                }).onCancel(() => {
                    commit('disable')
                    Debug.log('Cancel')
                    reject()
                }).onDismiss(() => {
                    Debug.log('I am triggered on both OK and Cancel')
                })
            })
        }
    },
    showError (_, {vc, error}) {
        Debug.log('showError error = ', error)
        vc.$q.notify({
            position: 'top',
            color: 'negative',
            icon: 'error_outline',
            timeout: 3000,
            message: error
        })
    },
    showMessage (_, { vc, message }) {
        Debug.log('showMessage vc = ', vc, ',', message)
        vc.$q.notify({
            position: 'top',
            icon: 'notification_important',
            timeout: 3000,
            message: message
        })
  }
}

const mutations = {
    active (state) {
        state.isActive = true
    },
    disable (state) {
        state.isActive = false
    }
}

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations
}
