<template>
    <article>
        <div id="preview">
            <div class="q-ma-xs text-center">
                <h5>{{ title }}</h5>
            </div>
            <div v-if="!isPopup && flash.stocks" class="flex justify-start">
                <q-chip v-for="(item,index) in flash.stocks" :key="'stock'+index"
                    outline square color="black" text-color="white" size="sm" >
                    {{ item.name }} ({{ item.code }})
                </q-chip>
            </div>
            <div v-else-if="stockList" class="flex justify-start">
                <q-chip v-for="(item,index) in stockList" :key="'stock'+index"
                    outline square color="black" text-color="white" size="sm" >
                    {{ item.name }} ({{ item.code }})
                </q-chip>
            </div>
            <p class="q-pa-xs q-ma-xs"
                style="overflow-y:scroll; width:600px; height:100%; background-color:white; border:1px solid; "
                v-html="content"
            />
            
            <div class="flex q-gutter-xs q-ma-lg">
                <i-btn label="닫기" @click="cancelFunc()"/>
            </div>
        </div>
    </article>
</template>
<script>
import Debug from '@/debug'

import Button from '@/components/common/Button'
import {FormatDateTime} from '@/util'
import { mapState,mapMutations, mapActions, mapGetters } from 'vuex'

// 뉴스속보 isPopup = true
// 증시요약 isPopup = false

export default {
    name: 'PreviewPopup',

    components: {
        'i-btn' :  Button,
    },
    props: {
        flashData: null,
        isPopup: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            progress:0,
            stockList: []
            // title:'',
            // content:'',
            // sendTime: '',
        }
    },
    created () {
        // 전송시간 오늘날짜로 설정

        // Debug.log("Preview Popup created!! preview =", this.item.preview)
        // this.content = this.item.preview
        // this.title = this.item.title
        // this.sendTime = FormatDateTime(this.item.sendTime)

        Debug.log("SEnt Popup created!! preview =", this.flash)
        // this.content = this.item.content
        // this.title = this.item.title
        // this.sendTime = FormatDateTime(this.item.sendTime)
        
    },
    mounted() {
        this.progress = 100
    },
    computed: {
        ...mapState ({
            PAGE_STATUS: state => state.page.status,
            flash: state => state.flash.preview
        }),
        ...mapGetters( 'calendar', ['today']),
        content () {
            return this.flash.content;
        },
        title() {
            return this.flash.title;
        },
        sendTime() {
            return FormatDateTime(this.flash.sendTime)
        }
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
            setStatus:'flash/SET_STATUS',
            initPreview:'flash/INIT_PREVIEW'
        }),
        ...mapActions( 'flash', ['SEND_FLASH']),
        ...mapActions( 'alert', ['showError', 'showMessage']),
        initStocks() {
            if(this.flash && this.flash.stock_codes) {
                let codeList = this.flash.sendData.stock_codes.split(';')
                this.stockList = []
                Debug.log('code = ',codeList)
                if(codeList) {
                    for(let index in codeList) {
                        let stock = this.stockItems.find(
                            (element) => element.code == codeList[index]
                        )
                        this.stockList.push(stock)
                    }
                    Debug.log('code = ',this.stockList)
                }
            }
        },
        send() {
            let params = this.flash

            if (!this.isPopup) {

                if (this.flash.stocks && this.flash.stocks.length > 0) {
                    var stocks = this.flash.stocks.map(obj => obj.code)
                    params.stocks = stocks.join(',')
                }
            }

            console.log(params)

            this.SEND_FLASH(params)
            .then(() => {
                this.showMessage({ vc:this, message: '속보 보내기 완료되었습니다.' })
                this.clearPreview()
                if (this.isPopup) {
                    this.setStatus(1)
                    window.close();
                } else {
                    this.$parent.$emit('initCheckItem', null)
                }
            })
            .catch((err) => {
                this.showError({vc:this, error:err} )
            })
        },
        cancelFunc() {
            this.clearPreview()
            if (this.isPopup) {
                this.setStatus(0)
                window.close()
            } else {
                this.$parent.$emit('initCheckItem', null)
            }

        },
        clearPreview() {
            this.initPreview()
        }
    },
    watch : {
        stockItems(val) {
            if(val) {
                this.initStocks()
            }
        } 
    }
}
</script>
<style>
#preview {
    text-align:left;

}

</style>