<template>
    <section class="row q-pl-xs">
        <q-toolbar class="row q-mt-md text-left">
            <div class="q-pa-xs">
                <q-select label="종목" v-model="stockCode" :options="stockOptions" option-label="name" @filter="filterFn" input-debounce="0" dense outlined :hide-selected="hide_selected" :fill-input="hide_selected" use-input hide-dropdown-icon options-dense style="width:200px;">
                    <q-tooltip anchor="top middle" self="bottom middle" :offset="[5, 5]">한글로 검색 시, enter를 눌러주세요.</q-tooltip>
                    <template v-slot:no-option><q-item><q-item-section class="text-grey">검색된 데이터가 없습니다.</q-item-section></q-item></template>
                </q-select>
            </div>
            <div class="q-pa-xs"><i-select v-model="closingDate" :options="closingDateOptions" option-value="value" option-label="name" style="width:90px;"/></div>
            <div class="q-pa-xs"><i-select v-model="ifrs" :options="ifrsOptions" option-value="value" option-label="name" style="width:120px;"/></div>
            <div class="q-pa-xs"><i-btn icon="search" @click="getList()"/></div>
        </q-toolbar>
        <i-list class="col-12 q-pa-md" ref="financialHistoryList"/>
    </section>
</template>
<script>
import CONSTANTS from '@/constants'
import Select from '@/components/common/Select.vue';
import Button from '@/components/common/Button'
import List from "@/components/earning/financialHistory/List.vue";
import { mapState, mapActions } from 'vuex'

export default {
    name: "FinancialHistory",
    props: {
        hide_selected: {
            type: Boolean,
            default: true
        }
    },
    components: {
        'i-select' : Select,
        'i-btn': Button,
        "i-list": List
    },
    data() {
        return {
            stockList: [],
            stockCode: '',
            stockOptions: [],
            ifrs: null,
            ifrsOptions: CONSTANTS.COM_IFRS,
            closingDate: null,
            closingDateOptions: []
        }
    },
    computed: {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            stockItems : state => state.resource.stockItems
        })
    },
    created () {
        this.getPage();
    },
    methods: {
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        getPage() {
            var iYear = Number(new Date().getFullYear())+2;
            for(var i=iYear; i >= 2016; i--){
                this.closingDateOptions.push({
                    value: i+'',
                    name: i+''
                });
            }
            this.ifrs = this.ifrsOptions[0];
            this.closingDate = this.closingDateOptions[0];
            var list = [];
            try {
                list = JSON.parse(this.stockItems);
            } catch (e) {
                list = this.stockItems;
            }
            var ds_list = [];
            list.forEach(row => {
                ds_list.push({
                    code : row['code'],
                    name : row['name'] + ' (' + row['code'] + ')'
                });
            });
            this.stockList = ds_list;
        },
        getList() {
            this.$refs.financialHistoryList.getList();
        },
        filterFn (val, update, abort) {
            // 종목검색 시, 1자리 이하일 경우 검색되지 않음.
            if (val.length < 2) {
                if (val.length == 0) {
                    this.stockCode = '';
                }
                abort();
                return;
            }
            update(() => {
                const needle = val.toLowerCase();
                this.stockOptions = this.stockList.filter(
                    v => v.name.toLowerCase().indexOf(needle) > -1 || v.code.indexOf(val) > -1
                )
            })
        }
    }
};

</script>
