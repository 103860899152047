<template>
    <article>
        <q-toolbar v-show="isRegisterPage" :style="topWrapStyle" ref="topWrap">
            <!-- <div v-show="isRegisterPage" class="col-12 col-md-8">
                <i-select v-model="condition.newsType" :options="marketNewsTypeOptions" label="뉴스타입"
                    @input="selectNewsType()" option-label="name" option-value="value"/>
            </div> -->
            <!-- <div class="col-12 col-md-2">
                <q-checkbox
                    v-model="isShow"
                    label="바로 게시"
                    left-label
                    @input="goRegisterPage()"
                />
            </div> -->
            <!-- <div v-if="isDetailPage" class="col-12 col-md-8 q-pa-xs">
                <i-field label="뉴스타입" :text="detailNewsType" />
            </div> -->
            <q-space />
        </q-toolbar>
        <q-scroll-area visible class="i-content-wrap" ref="scroll">
            <!-- 등록 화면 -->
            <q-card
                v-if="isListPage || isRegisterPage"
                flat
                bordered
                class="newsCard"
            >
                <div class="q-py-sm row">
                <i-datetime
                    date
                    dateLabel="전송 날짜"
                    v-model="sendDate"
                />
                </div>
                <q-card-section
                    class="q-py-sm"
                    v-for="(item, index) in newsCardList"
                    :key="'new-' + index"
                >
                    <div class="row">
                        <div class="col-10">
                            <i-select
                                class="full-width"
                                v-model="item.title"
                                label="제목"
                                @focus="goRegisterPage()"
                                :options="worldIndex()"
                                option-label="label"
                                option-value="value0"
                            />

                            <!-- <i-input class="full-width" v-model="item.title" label="제목" @focus="goRegisterPage()" /> -->
                        </div>
                        <div class="col-1 text-right">
                            <q-btn
                                color="deep-orange"
                                icon="delete"
                                size="xs"
                                @click="deleteCard(index)"
                                round
                                unelevated
                                :disable="isListPage"
                            />
                        </div>
                    </div>
                    <div class="q-py-sm">
                        <i-input
                            v-model="item.content"
                            type="textarea"
                            label="내용"
                            :rows="rows(index)"
                            @input="typingCount('new', index)"
                            @focus="goRegisterPage()"
                        />
                        <div
                            v-show="isRegisterPage"
                            class="text-right text-red i-text-small"
                        >
                            {{ item.textBytes }} / {{ MAX_BYTE }}
                        </div>
                    </div>
                </q-card-section>
            </q-card>
            <!-- 상세 화면 -->
            <q-card v-if="isDetailPage" flat bordered class="newscard">
                <q-card-section class="row q-pb-sm">
                    <div class="col-12 col-sm-4 col-lg-3 q-pa-xs">
                        <i-field label="작성날짜" :text="createTime" />
                    </div>
                    <div class="col-12 col-sm-4 col-lg-3 q-pa-xs">
                        <i-field label="변경날짜" :text="lastUpdateTime" />
                    </div>
                    <div class="col-12 col-sm-4 col-lg-3 q-pa-xs">
                        <i-field label="전송날짜" :text="sendTime" />
                    </div>
                    <!-- <div class="col-12 col-sm-6 col-lg-3 q-pa-xs">
                        <i-field label="작성자" :text="writer" />
                    </div> -->
                </q-card-section>
                <q-card-section
                    class="q-gutter-sm"
                    v-for="(item, index) in news"
                    :key="'detail-' + index"
                >
                    <div class="flex justify-between">
                        <div style="width: 80%;">
                            <i-input
                                v-model="item.title"
                                label="제목"
                                readonly
                            />
                        </div>
                        <div style="width: 20%;">
                            <!-- TODO: id로 삭제하는 API 필요 -->
                            <!-- <div class="text-right">
                                <q-btn v-show="index != 0" color="deep-orange" icon="delete" size="xs"
                                    @click="deleteOriginCard(item.id)" round unelevated />
                            </div> -->
                            <div
                                class="text-right self-end text-red i-text-small"
                            >
                                {{ item.textBytes }} / {{ MAX_BYTE }}
                            </div>
                        </div>
                    </div>
                    <i-input
                        v-model="item.content"
                        type="textarea"
                        label="내용"
                        :rows="rows(index)"
                        @input="typingCount('origin', index)"
                        readonly
                    />
                </q-card-section>
            </q-card>
            <!-- 속보 -->
            <q-card v-if="isFlashPage" flat bordered>
                <q-card-section>
                    <i-preview
                        style="height: 400px;"
                        :flashData="newsFlashData"
                        disableCancel
                        editSendTime
                    />
                </q-card-section>
            </q-card>
        </q-scroll-area>
        <!-- 하단 -->
        <div class="i-bottom-wrap q-my-md">
            <div v-if="isDetailPage && !editMode">
                <div class="row" v-show="editShowTime">
                    <div class="col-6">
                        <i-datetime
                            date
                            v-model="showTime"
                            dateLabel="게시날짜"
                        />
                    </div>
                </div>
                <!-- <div class="col-12 q-pa-xs">
                    <i-field v-model="sendCheck" check inputLabel="속보전송" />
                </div> -->
                 
                
                <div class="row q-my-xs">
                    <!-- TODO: 담당자 확인 API 연결 필요 -->
                    <div class="col-12 col-sm-6 q-pa-xs">
                        <i-btn
                            class="full-width"
                            :label="
                                `홈페이지 게시${
                                    news[0].showDate === null ||
                                    news[0].showDate === ''
                                        ? ''
                                        : ' 취소'
                                }`
                            "
                            @click="confirm()"
                        />
                    </div>
                    <div class="col-12 col-sm-6 q-pa-xs">
                        <i-btn
                            class="full-width"
                            label="수정하기"
                            @click="goEdit()"
                        />
                    </div>
                </div>
            </div>
            <div v-if="isRegisterPage" class="row">
                <div class="col-12 col-sm-6 q-pa-xs">
                    <i-btn class="full-width" label="추가" @click="addCard()" />
                </div>
                <div v-if="!editMode" class="col-12 col-sm-6 q-pa-xs">
                    <i-btn
                        class="full-width"
                        label="등록"
                        @click="register()"
                    />
                </div>
                <div v-if="editMode" class="col-12 col-sm-6 q-pa-xs">
                    <i-btn
                        class="full-width"
                        label="수정"
                        @click="register()"
                    />
                </div>
            </div>
        </div>
    </article>
</template>
<script>
import Debug from '@/debug'
//import CONSTANTS from '@/constants'
import {
    getByteSize,
    // getMaxBytesText,
    today,
    addWorkDay,
    nowTime,
    isEmpty,
    FormatDate,
} from '@/util'

import Button from '@/components/common/Button'
import Field from '@/components/common/Field'
import Input from '@/components/common/Input'
import Select from '@/components/common/Select'
import DateTime from '@/components/common/DateTime'
import Preview from '@/components/common/Preview'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import debug from '../../../debug'

export default {
    name: 'Content',
    components: {
        'i-field': Field,
        'i-datetime': DateTime,
        'i-btn': Button,
        'i-input': Input,
        'i-select': Select,
        'i-preview': Preview,
    },
    props: {
        newsType: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            newsTypeOptions: [],
            // 담당자 확인 관련
            sendCheck: false,
            // sendTime: null,
            // 시황
            isShow: false,
            MAX_BYTE: 10240,
            newsCardList: [],
            updateScroll: false,
            newsFlashData: {},
            topWrapStyle: {
                height: '',
            },
            editMode: false,
            showDate: '',
            // 20210812 shb 전송일자 - 당일 14시 이후에는 다음날로 전송일자가 넘어간다.
            sendDate: '',
        }
    },
    created() {
        // 전송시간 오늘날짜로 설정
        // EventBus를 통한 리소스 카드 추가하기
        this.$parent.$on('sendResource', this.addResourceCard)

        this.initCard()

        this.$parent.$on('sendHeight', this.setHeight)
        this.sendDateChanger()
    },
    updated() {
        if (this.updateScroll) {
            Debug.log('updated pos = ', this.$refs.scroll.$el.clientHeight)
            let pos = this.$refs.scroll.getScrollPosition()
            let height = this.$refs.scroll.$el.clientHeight
            this.$refs.scroll.setScrollPosition(pos + height, 300)
            this.updateScroll = false
        }
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            news: (state) => state.market_news.details,
            worldIndexItem: (state) => state.market_news.worldIndexItem,
            //            marketNewsType :  state => state.resource.MarketNewsType,
        }),
        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isRegisterPage',
            'isFlashPage',
        ]),
        ...mapGetters('calendar', ['today']),

        createTime: function() {
            return this.news[0].createTime
        },
        lastUpdateTime() {
            return isEmpty(this.news[0].lastUpdateTime)
                ? ''
                : this.news[0].lastUpdateTime
        },
        sendTime() {
            return isEmpty(this.news[0].sendDate)
                ? ''
                : FormatDate(this.news[0].sendDate)
        },
        writer: function() {
            return this.news[0].writer
        },
        marketNewsTypeOptions: function() {
            var arr = this.marketNewsType.slice()
            var index = arr.findIndex((v) => v.value == 'MARKET_CLOSED_ALL')
            arr.splice(index, 1)

            return arr
        },
        editShowTime() {
            return (this.news[0].showDate === null || this.news[0].showDate === '')
        }
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('resource', ['REQUEST_RESOURCE']),
        ...mapMutations('market_news', ['INIT_LIST']),

        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions('market_news', [
            'ASYNC_LIST',
            'GET_DETAIL',
            'WRITE_ITEM',
            'DELETE_ITEMS',
            'GET_NEWS_RECENT',
            /* 
                220406 meej 거래소/코스닥 시황(월드) 일 때 전일시황내용 없이 빈칸으로 출력, 주석처리
            */
            // 'GET_MULTI_DETAIL',
            // 'GET_LIST_SENDATE'
        ]),
        // 20210812 shb 작성일자 관련 - 당일 14시 이후 입력 다음날로 변경하는 메서드
        sendDateChanger() {
            // const nextDay = today().setDate(today.getDate() + 19)
            // 금요일경우 다음주로 
            if (Number(nowTime('HH')) > 13) {
                this.sendDate = addWorkDay(1)
            } else {
                this.sendDate = today()
            }
        },

        // setCondition(condition) {
        //     if (condition.newsType.value != 'MARKET_CLOSED_ALL') {
        //         this.condition.newsType = condition.newsType
        //     }
        //     this.condition.showFlag = condition.showFlag
        // },
        rows(index) {
            /* 
                220406 meej
                isRegisterPage일 때 content 칸 높이 고정으로 변경
            */
            Debug.log('index  = ', index)
            if(this.isRegisterPage) {
                // let card = this.newsCardList[index]
                // let row = (card.content ? 0 : 23)
                // return row
                return 23
            } else if(this.isDetailPage) {
                return 0
            } else {
                return 23
            }
        },
        setHeight(height) {
            this.topWrapStyle.height = height
        },
        worldIndex() {
            if (this.newsType === null) return []
            if (
                this.newsType.value === 'MARKET_KOSPI' ||
                this.newsType.value === 'MARKET_KOSDAQ'
            ) {
                //코스피, 코스닥의 경우 world index 가 따로없음
                return [
                    { name0: this.newsType.name, label: this.newsType.name },
                ]
            } else {
                this.newsType.worldIndex.forEach((element) => {
                    element.label = `${element.name0}(${element.value0})`
                })

                return this.newsType.worldIndex
            }
        },
        goListPage() {
            this.editMode = false
            this.INIT_LIST()
            this.setPageStatus(this.PAGE_STATUS.LIST)

            // this.ASYNC_LIST({
            //     newsType: this.newsType.value
            // })
            // 220822 meej 목록 페이지에서도 이전 입력 시황 조회
            if(!(this.newsType.value === 'MARKET_KOSPI' || this.newsType.value === 'MARKET_KOSDAQ'
                    || this.newsType.value === "MARKET_CLOSED_ALL" || this.newsType.value === 'MARKET_FUTURES')) {
                //NewsType 별 전날 내용 가져오기
                this.getNewsBeforeDay()
                this.requestResource()
            }
        },
        goRegisterPage() {
            if (!this.isRegisterPage) {
                if (this.newsType.value === 'MARKET_CLOSED_ALL') {
                    this.showError({
                        vc: this,
                        error: '뉴스타입을 먼저 선택하세요',
                    })
                    return
                }
                // 이전에 작업한 입력 카드 있으면 지우기
                this.updateScroll = false
                this.initCard()
                this.setPageStatus(this.PAGE_STATUS.REGISTER)
                // 220725 meej 거래소/코스닥 시황(월드) 마감시황(KS1), 특징테마/종목/상한가(KS3) 당일 내용 가져오게 수정으로 인한 주석처리
                // 21.11.09 suki : 피드백으로 해당내용 안가져오게 수정 
                //|| this.newsType.value == 'MARKET_FOREX_WORLD') {
                // if(this.newsType.value === 'MARKET_WORLD_INDEX') {

                    /* 
                        220406 meej
                        거래소/코스닥 시황(월드) 일 때 4개 전부 나오도록 설정
                        마감시황(KS1)에 전일시황내용 없이 빈칸으로 출력하도록 수정
                    */
                //    this.newsCardList = []
                //    for(var i=0;i<this.worldIndex().length;i++){
                //         var obj = {}
                //         obj.title = this.worldIndex()[i]
                //         this.newsCardList.push(obj);
                //     }                            

                    /* 
                        220406 meej 거래소/코스닥 시황(월드) 일 때 전일시황내용 없이 빈칸으로 출력, 주석처리
                    */
                    // 월드인덱스 처리
                    // var params = {}
                    // params.newsTypes = 'MARKET_KOSPI,MARKET_KOSDAQ'
                    // params.day = today()
                    // this.newsType.value == 'MARKET_WORLD_INDEX'
                    //     ? 'MARKET_KOSPI,MARKET_KOSDAQ'
                    //     : 'MARKET_FOREX_DOMESTIC,MARKET_FOREX_NEWYORK'
                    //20211108 suki - 월드인덱스 가져오는 기준을 전송날짜 기준으로 변경 (피드백) --> 1109 삭제 
                    // this.GET_LIST_SENDATE(params)
                    //     .then(() => {
                            // this.newsCardList = []
                            // this.newsCardList.push(this.worldIndexItem)                         
                        // })
                        // .catch((err) => {
                        //     Debug.log('error = ', err)
                        // })
                    //20211108 suki - 월드인덱스 가져오는 기준을 전송날짜 기준으로 변경 (피드백)
                    // if(this.newsType.value == 'MARKET_FOREX_WORLD') {
                    //     //월드 환율은 전날 내용까지 NewsType 별 전날 내용 가져오기
                    //     this.getNewsBeforeDay()
                    // }
                // } else if(!(this.newsType.value === 'MARKET_KOSPI' || this.newsType.value === 'MARKET_KOSDAQ')) {
                // 220822 meej 조건 추가
                if(!(this.newsType.value === 'MARKET_KOSPI' || this.newsType.value === 'MARKET_KOSDAQ'
                        || this.newsType.value === "MARKET_CLOSED_ALL" || this.newsType.value === 'MARKET_FUTURES')) {
                    //NewsType 별 전날 내용 가져오기
                    this.getNewsBeforeDay()
                }
                
                this.sendDateChanger()

                if (this.newsType) {
                    this.requestResource()
                    this.newsCardList[0].title = this.worldIndex()[0]
                    
                }
            }
        },

        goFlashPage() {
            this.setPageStatus(this.PAGE_STATUS.FLASH)
        },
        initCard() {
            this.newsCardList = []
            let title = ''
            debug.log('addCar = ', this.newsType)
            if (this.newsType && this.newsType.value !== 'MARKET_CLOSED_ALL') {
                title = this.worldIndex()[0]
            }
            this.addCard(title)
            this.isShow = false
        },
        // 새 카드 추가하기
        addCard(title) {
            debug.log('addCar = ', title)
            // title
            let tit = ''
            if (!isEmpty(title)) {
                tit = title
            }
            var card = {
                title: tit,
                content: '',
                textBytes: 0,
            }
            // if (this.worldIndex().length > 0) {
            //     card.newsType = this.worldIndex()[
            //         this.newsCardList.length
            //     ].value0
            //     card.title = this.worldIndex()[this.newsCardList.length].name0
            // }

            this.updateScroll = true
            this.newsCardList.push(card)
        },
        // 카드 삭제하기
        deleteCard(index) {
            this.newsCardList.splice(index, 1)
        },
        // 담당자 확인
        confirm() {
            if (this.sendCheck) {
                this.goFlashPage()
            } else {
                var message

                // TODO: 게시날짜 체크
                var newsType = this.news[0].newsType1
                var id = this.news[0].id

                var params = {}
                params.newsType = newsType
                params.id = id
                
                if (isEmpty(this.news[0].showDate)) {
                    params.showDate = this.showTime
                } else {
                    params.showDate = ''
                    // 220714 meej 시황 : 홈페이지 게시 취소 오류 수정
                    params.showFlag = 0
                }
                params.content = this.news[0].content

                this.WRITE_ITEM(params)
                    .then(() => {
                        message = '저장되었습니다.'
                        this.showMessage({ vc: this, message: message })

                        this.showTime = null

                        // TODO: 리스트 초기화
                        this.INIT_LIST()
                        // TODO: 상세 초기화

                        var params1 = {
                            newsType: newsType,
                        }

                        this.ASYNC_LIST(params1)

                        var params2 = {
                            newsType: newsType,
                            id: id,
                        }
                        this.GET_DETAIL(params2)

                        this.setPageStatus(this.PAGE_STATUS.DETAIL)
                    })
                    .catch((err) => {
                        var error = err.message
                        this.showError({ vc: this, error: error })
                    })
            }
        },
        // 전날 시황 가져오기
        getNewsBeforeDay() {
            if (!this.newsType) return
            let params = {
                newsType: this.newsType.value,
                date: this.sendDate, // 220725 meej 시황 : 거래소/코스닥 시황 _ 입력할 전송날짜와 일치하는 시황 조회로 수정
            }
            this.GET_NEWS_RECENT(params)
            .then((data) => {
                // 리스트가 1나 인건 pass
                // index 1 부터 넣기
                if (data.closed) {
                    //휴장
                    this.setCloseMarket(data.item)
                } else {
                    //if(data.items.length > 1) {
                    this.setOtherNews(data.items)
                    //}
                }
            })
            // 220822 meej 에러메시지 추가
            .catch((err) => {
                this.showError({ vc: this, error: err })
            })
        },
        setCloseMarket(item) {
            let content =
                this.newsType.name() + '은 ' + item.getTitle() + '로 휴장.'
            let count = this.worldIndex.length
            this.newsCardList[0].content = content
            for (let i = 1; i < count; i++) {
                let card = {
                    title: this.worldIndex()[i],
                    content: content,
                    textBytes: 0,
                }
                this.newsCardList.push(card)
            }
        },

        setOtherNews(items) {
            Debug.log('setOtherNews1 items = ', items)
            items.sort(function(news1, news2) {
                return news1.DIVISION < news2.DIVISION
                    ? -1
                    : news1.DIVISION > news2.DIVISION
                    ? 1
                    : 0
            })

            Debug.log('setOtherNews2 items = ', items)

            // 220722 meej 신규 시황 타입 추가로 인한 수정 MARKET_NEW_RECOMMEND,MARKET_THEME_FEATURED,MARKET_STOCK_FEATURED,MARKET_STOCK_JUMP
            // 220725 meej 거래소/코스닥 시황(월드) 마감시황(KS1), 특징테마/종목/상한가(KS3) 당일 내용 가져오게 수정 MARKET_WORLD_INDEX
            if(this.newsType.value == 'MARKET_NEW_RECOMMEND'
                || this.newsType.value == 'MARKET_THEME_FEATURED'
                || this.newsType.value == 'MARKET_STOCK_FEATURED'
                || this.newsType.value == 'MARKET_STOCK_JUMP'
                || this.newsType.value == 'MARKET_WORLD_INDEX'
            ){
                this.newsCardList = []
            }
            for (let i in items) {
                let item = items[i]
                let card
                this.worldIndex().forEach((el) => {
                    if (el.value0 == item.DIVISION) {
                        let elContent = item.content
                        // 220726 meej 거래소/코스닥 시황(월드) 일때 마감시황의 <b></b> 안나오게 처리
                        if(this.newsType.value == 'MARKET_WORLD_INDEX'){
                            elContent = elContent.replaceAll("<b>", "").replaceAll("</b>", "")
                        }
                        card = {
                            title: el,
                            content: elContent,
                            textBytes: item.content ? elContent.length : 0,
                        }
                    }
                })
                this.newsCardList.push(card)
            }
        },
        // 시황 등록하기
        register() {
            if (this.editMode) {
                this.edit()
            } else {
                if (
                    !this.newsType ||
                    this.newsType.value == 'MARKET_CLOSED_ALL'
                ) {
                    this.showMessage({
                        vc: this,
                        message: '뉴스타입을 선택해주세요.',
                    })
                    return
                }

                if (this.newsCardList.length == 0) {
                    this.showMessage({
                        vc: this,
                        message: '카드를 추가해주세요.',
                    })
                    return
                }
                this.updateScroll = true
                // 제목, 내용 체크
                /* 
                    220330 meej
                    this.newsCardList.length -1 
                        -> this.newsCardList.length 로 수정
                */
                for (var i = 0; i < this.newsCardList.length; i++) {
                    var item = this.newsCardList[i]
                    debug.log('newsCard = ', item)
                    /* 
                        220330 meej
                        제목 선택 안할 경우도 추가
                    */
                    if (!item.title || !item.content || !item.title.label) {
                        this.showMessage({
                            vc: this,
                            message: '제목 또는 내용을 입력해주세요.',
                        })
                        return
                    } else {
                        /* 
                            220330 meej 
                            글자 수 초과시 return 처리
                        */
                        let byteSize = getByteSize(item.content)
                        if (byteSize > this.MAX_BYTE) {
                            let message = "[ "+item.title.label+" ] 의 글자 수는 "+ this.MAX_BYTE + "자를 초과 입력할 수 없습니다."
                            this.showMessage({ 
                                vc: this,
                                message: message })
                            return
                        }
                    }
                }

                let params = {
                    newsType: this.newsType.value,
                    // showFlag: this.isShow ? 1 : 0,
                }

                debug.log(
                    'this.newsCardList[0].title = ',
                    this.newsCardList[0].title
                )
                if (this.newsCardList[0].title.name0) {
                    params.title = this.newsCardList[0].title.name0
                } else {
                    params.title = this.newsCardList[0].title
                }

                params.content = this.newsCardList[0].content

                if (this.sendDate) {
                    params.sendDate = this.sendDate
                    // 220610 meej showDate 주석처리
                    // params.showDate = this.sendDate
                }

                // 221124 meej 일부 시황은 글등록시 게시처리함
                if(this.newsType.value == "MARKET_FOREX_WORLD" || this.newsType.value == "MARKET_NEW_RECOMMEND"
                    || this.newsType.value == "MARKET_STOCK_FEATURED" || this.newsType.value == "MARKET_STOCK_JUMP"
                    || this.newsType.value == "MARKET_THEME_FEATURED" || this.newsType.value == "MARKET_WORLD_INDEX"
                ){
                    params.showDate = this.sendDate
                }

                if (this.newsCardList.length > 1) {
                    let items = []

                    for (let i = 1; i < this.newsCardList.length; i++) {
                        let card = this.newsCardList[i]
                        debug.log(card)
                        debug.log('card [', i, '].newsType = ', card.newsType)
                        items.push({
                            content: card.content,
                            newsType: card.title.value0,
                            title: card.title.name0
                                ? card.title.name0
                                : card.title,
                        })
                    }
                    params.items = items
                    console.log(params)
                }

                // 20221220 meej 갯수 확인
                if (this.worldIndex().length < this.newsCardList.length) {
                    let message = "중복되는 제목이 존재합니다.";
                    this.showMessage({vc: this, message: message});
                    return;
                }

                this.showAlert({ vc: this, message: '등록하시겠습니까' }).then(
                    () => {
                        this.doWrite(params)
                    }
                )
            }
        },
        edit() {
            if (this.newsCardList.length == 0) {
                this.showMessage({ vc: this, message: '카드를 추가해주세요.' })
                return
            }
            // 제목, 내용 체크
            for (var i = 0; i < this.newsCardList.length; i++) {
                var item = this.newsCardList[i]
                debug.log('newsCard = ', item)
                if (!item.title || !item.content || !item.title.label) {
                    this.showMessage({
                        vc: this,
                        message: '제목 또는 내용을 입력해주세요.',
                    })
                    return
                }else {
                    /* 
                        220330 meej 
                        글자 수 초과시 return 처리
                    */
                    let byteSize = getByteSize(item.content)
                    if (byteSize > this.MAX_BYTE) {
                        let message = "[ "+item.title.label+" ] 의 글자 수는 "+ this.MAX_BYTE + "자를 초과 입력할 수 없습니다."
                        this.showMessage({ 
                            vc: this,
                            message: message })
                        return
                    }
                }
            }
            debug.log('edit newsType ',this.newsType)
            let params = {
                id: this.newsCardList[0].id,
                newsType: this.newsType.value,
                title: this.newsCardList[0].title.name0
                    ? this.newsCardList[0].title.name0
                    : this.newsCardList[0].title,
                content: this.newsCardList[0].content,
                sendDate: this.sendDate,
                // 220610 meej showDate 주석처리
                // showDate : this.sendDate
            }

            if (this.newsCardList.length > 1) {
                let items = []

                for (let i = 1; i < this.newsCardList.length; i++) {
                    let card = this.newsCardList[i]
                    debug.log('card [', i, '].id = ', card.id)
                    let child = {
                        id: card.id,
                        content: card.content,
                        newsType: card.title.value0,
                        title: card.title.name0 ? card.title.name0 : card.title,
                    }
                    items.push(child)
                }
                params.items = items
            }

            // 20221220 meej 갯수 확인
            if (this.worldIndex().length < this.newsCardList.length) {
                let message = "중복되는 제목이 존재합니다.";
                this.showMessage({vc: this, message: message});
                return;
            }

            this.showAlert({ vc: this, message: '수정하시겠습니까' }).then(
                () => {
                    this.doWrite(params)
                }
            )
        },
        doWrite(params) {
            this.WRITE_ITEM(params)
                .then(() => {
                    this.editMode = false
                    this.sendDate = ''
                    this.showMessage({ vc: this, message: '완료 되었습니다.' })

                    this.goListPage()
                })
                .catch((err) => {
                    this.showError({ vc: this, error: err })
                })
        },
        // 시황 수정하기
        goEdit() {
            
            this.editMode = true
            this.setPageStatus(this.PAGE_STATUS.REGISTER)
            this.newsCardList = []
            this.sendDate = isEmpty(this.news[0].sendDate)
                ? ''
                : this.news[0].sendDate
            for (let i in this.news) {
                let item = this.news[i]
                //Debug.log('edit item = ', item)
                let card = {
                    id: item.id,
                    content: item.content,
                    textBytes: 0,
                }
                // Debug.log('edit newsType = ', this.newsType)
                // Debug.log('edit card1 = ', this.worldIndex())
                // Debug.log('edit card2 = ', item.DIVISION)
                // Debug.log('edit card3 = ', this.worldIndex().find((v) => v.value0 == item.DIVISION))
                
                card.title = this.worldIndex().find((v) => v.value0 == item.DIVISION)
                this.newsCardList.push(card)
            }
            this.requestResource()
        },
        // 시황 삭제하기
        deleteOriginCard(id) {
            var params = {}
            params.ids = id
            // TODO: 완전삭제인지 남기기인지 확인 필요
            // 시황은 delete!

            // TODO: 서버 API에서 id로 삭제하는 API 필요.(현재 pid로만 삭제하는 API밖에 없음.)
            this.DELETE_ITEMS(params)
                .then(() => {
                    this.showMessage({ vc: this, message: '삭제되었습니다.' })

                    this.setPageStatus(this.PAGE_STATUS.LIST)
                })
                .catch((err) => {
                    var error = err.message
                    this.showError({ vc: this, error: error })
                })
        },
        // 입력 내용 바이트 계산하기
        typingCount(cardwrap, index) {
            let text

            if (cardwrap == 'origin') {
                text = this.news[index].content
            } else if (cardwrap == 'new') {
                text = this.newsCardList[index].content
            }

            let byteSize = getByteSize(text)
            let message

            // 넘어가는 글자는 자른다. ==> max byte 수 만큼 자르기!
            /* 
                220330 meej 
                넘어가는 글자 자르지 않게 수정(주석처리)
            */
            if (byteSize > this.MAX_BYTE) {
                message = this.MAX_BYTE + '자를 초과 입력할 수 없습니다.'
                this.showMessage({ vc: this, message: message })

                // text = getMaxBytesText(text, this.MAX_BYTE)
                // byteSize = getByteSize(text)

                // if (cardwrap == 'origin') {
                //     this.news[index].content = text
                // } else if (cardwrap == 'new') {
                //     this.newsCardList[index].content = text
                // }
            }

            if (cardwrap == 'origin') {
                this.news[index]['textBytes'] = byteSize
            } else if (cardwrap == 'new') {
                this.newsCardList[index]['textBytes'] = byteSize
            }
        },
        /**
            Resource 처리
        */
        selectNewsType() {},
        requestResource() {
            Debug.log('selectNewsType = ', this.newsType)
            // let menu = CONSTANTS.MENU.filter(menu => menu.value == 'Market')[0]
            let req = {
                menu: 'Market',
                newsType: this.newsType,
            }
            this.$nextTick(function() {
                this.REQUEST_RESOURCE(req)
            })
        },
        // 리소스 카드 추가하기
        addResourceCard(resource) {
            for (let i = 0; i < resource.items.length; i++) {
                let item = resource.items[i]
                Debug.log('addResource ', item)
                //  Debug.log(
                //         'addResourceCard item = ',
                //         item
                //     )
                this.updateScroll = true
                if (
                    item !== undefined &&
                    item !== null &&
                    item.newsType1 !== undefined &&
                    item.newsType1 !== null
                ) {
                    // Debug.log(
                    //     'addResourceCard this.newsType.value = ',
                    //     this.newsType
                    // )
                    if (item.newsType1 === this.newsType.value) {
                        if (
                            this.newsCardList.length == 1 &&
                            !this.newsCardList[0].title
                        ) {
                            this.newsCardList = []
                        }
                        var card = {
                            title: this.worldIndex().find((v) => v.value0 == item.DIVISION),
                            content: item.content,
                            textBytes: getByteSize(item.content),
                        }
                        this.newsCardList.push(card)
                    }
                }
            }
        },
    },
    watch: {
        newsType: function() {
            Debug.log('this.newsType = ', this.newsType)

            this.initCard()
            // 220822 meej 목록 페이지에서도 이전 입력 시황 조회 if (this.isRegisterPage) 주석처리
            // if (this.isRegisterPage) {
                // 220725 meej 거래소/코스닥 시황(월드) 마감시황(KS1), 특징테마/종목/상한가(KS3) 당일 내용 가져오게 수정으로 인한 주석처리
                // if (this.newsType.value == 'MARKET_WORLD_INDEX') {
                //     /* 
                //         220406 meej
                //         거래소/코스닥 시황(월드) 일 때 4개 전부 나오도록 설정
                //         마감시황(KS1)에 전일시황내용 없이 빈칸으로 출력하도록 수정
                //     */
                //     this.newsCardList = []
                //     for(var i=0;i<this.worldIndex().length;i++){
                //         var obj = {}
                //         obj.title = this.worldIndex()[i]
                //         this.newsCardList.push(obj);
                //     }  

                //     /* 
                //         220406 meej 거래소/코스닥 시황(월드) 일 때 전일시황내용 없이 빈칸으로 출력, 주석처리
                //     */
                //     // 월드인덱스 처리
                //     // var params = {}
                //     // params.newsTypes = 'MARKET_KOSPI,MARKET_KOSDAQ'
                //     // params.day = today
                //     // this.GET_MULTI_DETAIL(params)
                //     //     .then(() => {
                //             // this.newsCardList = []
                //             // this.newsCardList.push(this.worldIndexItem)
                                          
                //         // })
                //         // .catch((err) => {
                //         //     Debug.log('error = ', err)
                //         // })
                // } else{
                    // this.getNewsBeforeDay()
                // }
            // }
            // this.requestResource()
            // this.newsCardList[0].title = this.newsType.name
            //}
            // 220822 meej 조건 추가
            if(!(this.newsType.value === 'MARKET_KOSPI' || this.newsType.value === 'MARKET_KOSDAQ'
                    || this.newsType.value === "MARKET_CLOSED_ALL" || this.newsType.value === 'MARKET_FUTURES')) {
                //NewsType 별 전날 내용 가져오기
                this.getNewsBeforeDay()
                this.requestResource()
            }
        },
        news: function() {
            this.showTime = (this.news[0].showTime?this.news[0].showTime:today())
        },
        pageStatus: function(val, old) {
            Debug.log('val = ', val, old)
            if (
                old === this.PAGE_STATUS.DETAIL &&
                val === this.PAGE_STATUS.REGISTER
            ) {
                //수정
                this.editMode = true
            }

            if (this.isListPage) {
                this.editMode = false
                this.newsCardList = []
                this.initCard()
                // 220822 meej 목록 페이지에서도 이전 입력 시황 조회
                if(!(this.newsType.value === 'MARKET_KOSPI' || this.newsType.value === 'MARKET_KOSDAQ'
                        || this.newsType.value === "MARKET_CLOSED_ALL" || this.newsType.value === 'MARKET_FUTURES')) {
                    //NewsType 별 전날 내용 가져오기
                    this.getNewsBeforeDay()
                    this.requestResource()
                }
            }
        },
        // 220725 meej 시황 : 거래소/코스닥 시황 _ 입력할 전송날짜와 일치하는 시황 조회로 수정
        sendDate: function() {
            if (this.isRegisterPage && this.newsType.value === 'MARKET_WORLD_INDEX') {
                this.initCard()
                this.getNewsBeforeDay()
                this.requestResource()
            }
        },
    },
}
</script>
