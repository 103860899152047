<template>
    <article>
        <q-toolbar class="i-top-wrap">
            <q-space />
            <i-btn
                v-show="isRegisterPage || isDetailPage || isEditPage"
                label="취소"
                @click="goListPage()"
            />
            <i-btn v-show="isFlashPage" label="취소" @click="goDetailPage()" />
        </q-toolbar>
        <q-scroll-area visible class="i-content-wrap">
            <!-- 등록화면 -->
            <div v-if="isListPage || isRegisterPage">
                <q-card flat bordered>
                    <q-card-section class="q-gutter-sm">
                        <i-input v-model="title" label="제목" />
                        <i-input
                            v-model="content"
                            type="textarea"
                            label="내용"
                            :rows="25"
                        />

                        <div class="row q-my-sm">
                            <div class="col-12 col-lg-6">
                                <i-theme-search
                                    @get="searchTheme"
                                    label="관련테마"
                                    filterD
                                    hide_selected
                                />
                            </div>
                            <div class="col-12 flex justify-start">
                                <q-chip
                                    v-for="(item, index) in themeList"
                                    :key="'theme' + index"
                                    outline
                                    square
                                    color="black"
                                    text-color="white"
                                    size="md"
                                    removable
                                    @remove="themeList.splice(index, 1)"
                                >
                                    {{ item.name }}
                                </q-chip>
                            </div>
                        </div>
                    </q-card-section>
                </q-card>
            </div>
            <!-- 상세화면 -->
            <div v-if="isDetailPage || isEditPage">
                <q-card flat bordered>
                    <q-card-section class="q-gutter-sm">
                        <i-input v-model="item.title" label="제목" />
                        <i-input
                            v-model="item.content"
                            type="textarea"
                            label="내용"
                            :rows="25"
                        />
                        <div class="row q-my-sm">
                            <div class="col-12 col-lg-6">
                                <i-theme-search
                                    @get="searchTheme"
                                    label="관련테마"
                                    filterD
                                    hide_selected
                                />
                            </div>
                            <div class="col-12 flex justify-start">
                                <q-chip
                                    v-for="(tItem, index) in item.THEMES"
                                    :key="'theme' + index"
                                    outline
                                    square
                                    color="black"
                                    text-color="white"
                                    size="md"
                                    removable
                                    @remove="item.THEMES.splice(index, 1)"
                                >
                                    {{ tItem.name }}
                                </q-chip>
                            </div>
                        </div>
                    </q-card-section>
                </q-card>
            </div>
        </q-scroll-area>

        <!-- 하단 버튼 -->
        <div v-show="isDetailPage" class="row q-my-md">
            <div class="col-12 col-sm-6 q-pa-xs">
                <i-btn
                    v-show="canPreview"
                    class="full-width"
                    label="미리보기"
                    @click="preview()"
                />
            </div>
            <div class="col-12 col-sm-6 q-pa-xs">
                <i-btn class="full-width" label="수정하기" @click="edit()" />
            </div>
        </div>
        <div v-show="isListPage || isRegisterPage" class="q-my-md">
            <i-btn class="full-width" label="등록" @click="register()" />
        </div>
    </article>
</template>
<script>
import Debug from '@/debug'
import { optionsValue, objToString, today } from '@/util'

import Button from '@/components/common/Button'
// import DateTime from '@/components/common/DateTime'
import Input from '@/components/common/Input'
//import Field from '@/components/common/Field'
// import Preview from '@/components/common/Preview'
import ThemeSearch from '@/components/common/ThemeSearch'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Content',
    components: {
        'i-btn': Button,
        'i-input': Input,
        // 'i-preview': Preview,
        'i-theme-search': ThemeSearch,
    },
    props: {
        newsType: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            title: '미국 테마시황',
            content: '',
            sendTime: null,
            selected: [],
            themeList: [],
        }
    },
    created() {
        this.$parent.$on('sendResource', this.addResource)
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            item: (state) => state.market_news.item,
            sendStatus: (state) => state.flash.status,
            //THEMES :  state => state.market_us_theme.resourceItems,
        }),
        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isRegisterPage',
            'isFlashPage',
            'isEditPage',
        ]),
        canPreview() {
            return (
                this.item !== null &&
                this.item.lastUpdateTime.startsWith(today())
            )
        },
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('resource', ['REQUEST_RESOURCE']),
        ...mapMutations('market_news', ['INIT_LIST']),
        ...mapMutations('flash', ['SET_PREVIEW']),

        ...mapActions('alert', ['showError', 'showMessage']),
        ...mapActions('market_news', ['ASYNC_LIST', 'WRITE_ITEM']),
        ...mapActions('flash', ['WRITE_FLASH']),

        goRegisterPage() {
            this.setPageStatus(this.PAGE_STATUS.REGISTER)
            // this.$nextTick(function () {
            //    this.requestResource('Theme')
            // })
        },
        goListPage() {
            this.title = '미국 테마시황'
            this.content = ''
            this.themeList = []

            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        goDetailPage() {
            this.setPageStatus(this.PAGE_STATUS.DETAIL)
        },
        goEditPage() {
            this.setPageStatus(this.PAGE_STATUS.EDIT)
        },
        goFlashPage() {
            this.setPageStatus(this.PAGE_STATUS.FLASH)
        },
        // 테마 선택
        searchTheme(theme) {
            if (this.isListPage || this.isRegisterPage) {
                this.themeList.push(theme)
            } else if (this.isDetailPage) {
                if (this.item.THEMES == undefined) {
                    this.item.THEMES = []
                }
                this.item.THEMES.push(theme)
            }
        },
        /*
         * 등록하기
         */
        register() {
            var message

            // 입력값 체크
            if (!this.title || !this.content) {
                message = '제목 또는 내용을 입력해주세요.'
                this.showError({ vc: this, error: message })

                this.newsCardList = []
                return
            }

            // 220819 meej 테마 입력 체크 추가
            if (this.themeList.length == 0) {
                message = '관련테마를 입력해주세요.'
                this.showError({ vc: this, error: message })
                return
            }

            var params = {}
            params.newsType = this.newsType.value
            params.title = this.title
            params.content = this.content

            if (this.themeList.length > 0) {
                var obj = {}
                for (var t of this.themeList) {
                    obj[t.code] = t.name
                }
                var temp = {}
                temp.THEMES = optionsValue(obj)

                params.options = objToString(temp)
            }

            this.WRITE_ITEM(params)
                .then(() => {
                    message = '등록되었습니다.'
                    this.showMessage({ vc: this, message: message })

                    this.content = ''
                    this.INIT_LIST()
                    this.getList()
                    this.goListPage()
                })
                .catch((err) => {
                    Debug.log(err)
                    this.showError({ vc: this, error: err })
                })
        },
        /*
         * 수정하기
         */
        edit() {
            var message

            // 입력값 체크
            if (!this.item.title || !this.item.content) {
                message = '제목 또는 내용을 입력해주세요.'
                this.showError({ vc: this, error: message })

                this.newsCardList = []
                return
            }

            // 220819 meej 테마 입력 체크 추가
            if (this.item.THEMES.length == 0) {
                message = '관련테마를 입력해주세요.'
                this.showError({ vc: this, error: message })
                return
            }

            var params = {}
            params.newsType = this.newsType.value
            params.id = this.item.id
            params.title = this.item.title
            params.content = this.item.content

            if (this.item.THEMES.length > 0) {
                var obj = {}
                for (var t of this.item.THEMES) {
                    obj[t.code] = t.name
                }
                var temp = {}
                temp.THEMES = optionsValue(obj)

                params.options = objToString(temp)
            }

            console.log(params)

            this.WRITE_ITEM(params)
                .then(() => {
                    message = '수정되었습니다.'
                    this.showMessage({ vc: this, message: message })

                    this.INIT_LIST()
                    this.getList()
                    this.goListPage()
                })
                .catch((err) => {
                    Debug.log(err)
                    this.showError({ vc: this, error: err })
                })
        },

        /* 미리보기 */
        preview() {
            console.log(this.item)
            let params = {}
            params.newsType = this.newsType.value
            params.showFlag = 0
            params.id = this.item.id
            // console.log(params)
            //if(this.item.lastUpdateTime )
            this.WRITE_FLASH(params)
                .then((res) => {
                    Debug.log('data = ', res)

                    //this.reWrite();
                    this.showPreview()
                })
                .catch((err) => {
                    Debug.log('error = ', err)
                    this.showError({ vc: this, error: err })
                })
        },

        showPreview() {
            this.windowRef = window.open(
                `/preview/popup.htm`,
                '시황속보 미리보기',
                'width=620,height=820'
            )
            if(this.windowRef) {
                this.windowRef.addEventListener('beforeunload', this.closePreview)
            }
        },
        closePreview() {
            this.windowRef = null
            if (this.sendStatus === 1) {
                this.SET_PREVIEW(null)
                this.SET_STATUS(0)
                this.showMessage({ vc: this, message: '전송되었습니다.' })
            }
        },

        // 시황 목록 조회하기
        getList() {
            this.setPageStatus(this.PAGE_STATUS.LIST)
            console.log(this.newsType.value)
            var params = {
                newsType: this.newsType.value,
            }
            this.ASYNC_LIST(params)
        },
    },
    watch: {
        item(val) {
            Debug.log('val = ', val)
        },
    },
}
</script>
