<template>
    <article>
        <!-- 구분 -->
        <div class="flex justify-between q-my-xs q-pr-md">
            <div class="q-pa-xs">
                <i-btn label="삭제" @click="deleteItems()"/>
            </div>
        </div>
        <!-- 목록 -->
        <i-table :columns="columns" :list="list" :nextKey="nextKey"
            @row-click="getItem" @next-click="getList()" @check-event="handleCheckList" />
    </article>
</template>
<script>
import Button from '@/components/common/Button'
import Table from '@/components/common/Table'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    components: {
        'i-btn' :  Button,
        'i-table' :  Table,
    },
    data () {
        return {
            columns: [
                { name: 'stock', label: '종목명(종목코드)', align: 'left', field: row => `${row.name}(${row.code})` },
                { name: 'cause', label: '필터링 사유', align: 'left', field: row => row.CAUSE},
            ],
            nextKey: null
        }
    },
    created () {
        this.INIT_LIST()
        this.getList()
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
            list : state => state.flash.stockFilteringList
        }),

        ...mapGetters( 'page', ['isListPage', 'isDetailPage', 'isEditPage', 'isRegisterPage']),
    },
    methods: {
        ...mapMutations ({
            INIT_LIST : "flash/INIT_STOCK_FILTERING_LIST"
        }),
        ...mapMutations( 'page', ['setPageStatus']),
        ...mapMutations( 'flash', ['SET_ITEM']),

        ...mapActions( 'alert', ['showError', 'showMessage']),
        ...mapActions( 'flash', ['ASYNC_STOCK_FILTERING_LIST']),

        // 목록 조회
        getList() {
            this.setPageStatus(this.PAGE_STATUS.LIST)

            var params = {
                type: 'F_NEWS'
            }

            this.ASYNC_STOCK_FILTERING_LIST(params)
        },
        // 상세 조회
        getItem(item) {
            console.log(item)
            this.SET_ITEM({...item})
        },
        handleCheckList(list) {
            this.$emit("event-check", list)
        },
        deleteItems(){}
    }
}
</script>