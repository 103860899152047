<template>
    <section class="row">
        <i-list class="col-6 q-pa-md" />
        <i-content class="col-6 q-pa-md" />
    </section>
</template>
<script>
import List from '@/components/flash/stock-filtering/List.vue'
import Content from '@/components/flash/stock-filtering/Content.vue'

export default {
    name: 'StockFiltering',
    components: {
        'i-list' : List,
        'i-content' : Content
    }
}
</script>

<style>
    /* common */

    /* xs */
    @media only screen and (max-width: 599px) {
    }

    /* sm */
    @media only screen and (max-width: 1023px) {
    }

    /* md */
    @media only screen and (max-width: 1439px) {
    }

    /* lg */
    @media only screen and (max-width: 1919px) {
    }

    /* xl */
    @media only screen and (min-width: 1920px) {
    }

</style>


