<template>
    <article>
        <div class="row i-top-wrap">
            <div class="col-12 col-sm-6 q-pa-xs">
                <div class="flex justify-between">
                    <i-stock-search style="width:80%" @get="getStock" />
                    <i-btn
                        style="width:15%"
                        icon="search"
                        dense
                        @click="getSearch()"
                    />
                </div>
            </div>
            <div class="col-12 col-sm-6 q-pa-xs">
                <div class="flex justify-end q-gutter-xs">
                    <i-btn label="삭제" @click="deleteItems()" />
                </div>
            </div>
        </div>
        <!-- 목록 -->
        <!-- 221111 meej 투자의견도 같이 필터링되도록 수정 -->
        <i-table
            :columns="columns"
            :list="
                list.filter((el) =>
                    condition.stockCompany.code == null
                        ? true
                        : (condition.investOpinion == null || condition.investOpinion == '전체')
                        ? el.STOCK_COMPANY0 == condition.stockCompany.code 
                        : el.STOCK_COMPANY0 == condition.stockCompany.code && el.OPINION0 == condition.investOpinion
                )
            "
            :nextKey="nextKey"
            @row-click="getItem"
            @next-click="getList()"
            @check-event="handleCheckList"
        />
    </article>
</template>
<script>
import Debug from '@/debug'
import { FormatDate, numberFormat } from '@/util'
import CONSTANTS from '@/constants'

import Button from '@/components/common/Button'
import StockSearch from '@/components/common/StockSearch'
import Table from '@/components/stock/stock-invest-opinion/Table'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    components: {
        'i-btn': Button,
        'i-stock-search': StockSearch,
        'i-table': Table,
    },
    props: {
        condition: {
            type: Object,
            default: null,
        },
        stockSet: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            newsType: 'STOCK_COMPANY_OPINION',
            newsOption: null,
            // 조건
            investOpinionOptions: ['전체'],
            checkList: [],
            selected: [],
            columns: [
                {
                    name: 'crud',
                    label: '상태',
                    align: 'left',
                    class: 'text-red',
                    field: (row) => {
                        return Number(row.EXCLUSION0) === 1
                            ? '제외'
                            : row.crud == 'D'
                            ? '삭제'
                            : ''
                    },
                },

                {
                    name: 'stock',
                    label: '종목',
                    align: 'left',
                    field: (row) => `${row.stockName} ( ${row.stockCode} )`,
                },
                {
                    name: 'reportType',
                    label: '타입',
                    align: 'left',
                    class: 'text-blue',
                    field: (row) => {
                            let type = this.reportType.find((v) => v.key === row.REPORT_TYPE0)
                            if(type && (type.key === 'NOT_NEW')) return ''
                            else return type? type.value :  '-'
                        }
                },
                {
                    name: 'stockCompany',
                    label: '증권사',
                    align: 'left',
                    field: (row) => {
                        var temp = this.stockCompanyOptions.find(
                            (v) => v.code == row.STOCK_COMPANY0
                        )
                        return temp ? temp.name : row.STOCK_COMPANY0
                    },
                },

                {
                    name: 'opinion',
                    label: '투자의견',
                    align: 'left',
                    field: (row) => row.OPINION0,
                },
                {
                    name: 'PRICE',
                    label: '금액',
                    align: 'left',
                    field: (row) => numberFormat(row.PRICE, '-'),
                },
                {
                    name: 'showDate',
                    label: '제시일',
                    align: 'left',
                    field: (row) => FormatDate(row.showDate),
                },
                {
                    name: 'lastUpdateTime',
                    label: '입력일',
                    align: 'left',
                    field: (row) => FormatDate(row.lastUpdateTime),
                },
            ],
            reportType:[
                    {key:'NEW', value:'신규'},
                    {key:'NOT_NEW', value:'신규아님'},
                    {key:'CHANGE_PIC', value:'담당자 변경'},
                    {key:'RESUME', value:'재개'},
            ]
        }
    },
    created() {
        this.INIT_LIST()
        this.getList()
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            list: (state) => state.stock_invest_opinion.list,
            newsTypeList: (state) => state.resource.newsType,
            stockCompanyItems: (state) => state.resource.stockCompany,
        }),

        ...mapState('stock_invest_opinion', ['list', 'nextKey']),
        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isEditPage',
            'isRegisterPage',
        ]),

        stockCompanyOptions() {
            var arr = [{ name: '전체', code: null }]
            arr.push(...this.stockCompanyItems)
            return arr
        },
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('stock_invest_opinion', [
            'SET_LIST',
            'SET_DETAIL',
            'INIT_LIST',
            'INIT_DETAIL',
            'SET_OPTIONS',
        ]),

        ...mapActions('alert', ['showError', 'showMessage']),
        ...mapActions('stock_invest_opinion', [
            'GET_LIST',
            'GET_DETAIL',
            'DELETE_ITEMS',
        ]),
        getReportTypeKor(type) {
            if(type === 'NOT_NEW') return ''
            let repType = this.reportType.find((v) => v.key === type )
            return repType.value
        },
        handleCheckList(list) {
            this.selected = list
        },
        goRegisterPage() {
            this.setPageStatus(this.PAGE_STATUS.REGISTER)
        },
        getStock(stock) {
            this.condition.stock = stock
            this.INIT_LIST()
            this.getList()
        },
        getSearch() {
            this.condition.stock = ''

            this.INIT_LIST()
            this.getList()
        },
        // 목록 조회하기
        getList() {
            // this.INIT_LIST()

            var params = {}

            if (this.condition.stock != null) {
                params.code = this.condition.stock.code
            }
            params.delete = true
            this.GET_LIST(params)
        },
        // 상세 조회하기
        getItem(item) {
            this.setPageStatus(this.PAGE_STATUS.DETAIL)

            var params = {}
            params.id = item.id

            this.GET_DETAIL(params)
                .then((data) => {
                    Debug.log('GET_DETAIL data', data.item)
                    var item = data.item

                    item.STOCK_COMPANY0 = this.stockCompanyOptions.find(
                        (v) => v.code == item.STOCK_COMPANY0
                    )
                    item.EXCLUSION0 = CONSTANTS.EXCLUSION_TYPE.find((v) => {
                        var temp = item.EXCLUSION0 ? item.EXCLUSION0 : 0
                        if (temp == 0 && item.crud === 'D') {
                            temp = item.crud
                        }
                        return v.value == temp
                    })

                    this.SET_DETAIL(item)
                })
                .catch((err) => {
                    var error = err.message
                    this.showError({ vc: this, error: error })
                    Debug.log('error = ', error)
                })
        },
        // 삭제하기
        deleteItems() {
            var message
            if (this.selected.length == 0) {
                message = '삭제할 항목을 선택해주세요.'
                this.showMessage({ vc: this, message: message })
                return
            }

            var params = {}
            params.ids = this.selected.map((x) => x.id).join(',')
            //투자의견은 Update - 증권사에도 알려줘야함
            params.force = 0
            // 종목뉴스 삭제 API
            this.DELETE_ITEMS(params)
                .then((data) => {
                    var total = data.success + data.fail
                    message =
                        '선택된 ' +
                        total +
                        '개 중 ' +
                        data.success +
                        '개 삭제되었습니다.'
                    this.showMessage({ vc: this, message: message })

                    this.INIT_DETAIL()

                    this.INIT_LIST()
                    this.getList()
                })
                .catch((err) => {
                    var error = err.message
                    this.showError({ vc: this, error: error })
                    Debug.log('error = ', error)
                })
        },
    },
    watch: {
        newsTypeList: function() {
            var temp = this.newsTypeList.find((v) => v.value == this.newsType)
            var options = temp ? temp.options : null
            this.SET_OPTIONS(options)
        },
        // 221115 meej 증권사, 종목 선택시 리스트 조회
        stockSet: function() {
            this.condition.stock = this.stockSet
            this.INIT_LIST()
            this.getList()
        }
    },
}
</script>
