<template>
    <article>
        <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : list.length == 0 }"
            :columns="columns" :data="list" row-key="RNUM" :rows-per-page-options="[0]" style="height:auto;" @row-click="getView" hide-pagination no-data-label="조회되는 데이터가 없습니다.">
            <template v-slot:no-data="{ icon, message }"><div class="full-width row flex-center q-gutter-sm"><q-icon size="2em" :name="icon" /> <span>{{message}}</span></div></template>
        </q-table>
    </article>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    data () {
        return {
            columns: [
                { headerStyle:'text-align:center;', style:'width:*;', name: 'trnsmisNm', label: '업체명', align: 'left', field:'trnsmisNm' },
                { headerStyle:'text-align:center;', style:'width:60px;', name: 'trnsmisCnt', label: '전송수', align: 'center', field:'trnsmisCnt' }
              ]
        }
    },
    computed : {
        ...mapState('manage', ['item', 'list', 'itemList']),
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            TRANS_TYPE : state => state.manage.transType,
        })
    },
    created () {
        this.getList();
    },
    methods: {
        ...mapMutations('manage', ['INIT_DATA', 'INIT_DATA_VIEW', 'SET_DATA_VIEW', 'SET_DATA']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions('manage', ['GET_CALL']),
        //리스트
        getList() {
            let param = {
                keyword: this.$parent.keyword,
                'trnsmisType': this.TRANS_TYPE.ADVRTS_CTMMNY,
                CALL_API_URL: '/trnsmis/list'
            }
            this.GET_CALL(param).then((data) => {
                this.SET_DATA(data);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        //상세
        getView(event, item) {
            this.INIT_DATA_VIEW();
            var trnsmisId = "";
            if(item != null && item.trnsmisId != null){
                trnsmisId = item.trnsmisId;
            }
            let param = {
                'trnsmisId': trnsmisId,
                'trnsmisType': this.TRANS_TYPE.ADVRTS_CTMMNY,
                'CALL_API_URL': '/trnsmis/view'
            }
            this.GET_CALL(param).then((data) => {
                var iCnt = 0;
                var ds_list = [];
                var ds_obj = {};
                data.itemList.forEach(row => {
                    if(iCnt == 3){
                        iCnt = 0;
                        ds_list.push(ds_obj);
                        ds_obj = {};
                    }
                    ds_obj['code'+iCnt] = row.code;
                    ds_obj['name'+iCnt] = row.name;
                    ds_obj['trnsmisChk'+iCnt] = row.trnsmisChk;
                    ds_obj['port'+iCnt] = row.port;
                    iCnt++;
                });
                ds_list.push(ds_obj);
                data.itemList = ds_list;
                this.SET_DATA_VIEW(data);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
    }
}

</script>
