<template>
    <article>
        <q-toolbar class="i-top-wrap text-right">
            <q-space />
            <i-btn v-if="!isListPage" label="취소" @click="goListPage()" />
        </q-toolbar>
        <q-scroll-area visible class="i-content-wrap">
            <!-- 검색/수정 화면 -->
            <!-- 
                220422 meej :disable="isEditPage" 삭제
             -->
            <div class="col-12 q-pt-xs">
                <i-select
                    v-model="type"
                    :options="scheduleOption"
                    inputLabel="카테고리"
                    option-label="name"
                    option-value="value"
                />
            </div>

            <!-- 
                220422 meej memo 선택시 연도, 월 보여주기
             -->
            <div v-if="isMemoType" class="row q-pt-xs">
                <div class="col-sm-10 col-md-5">
                    <i-select
                        v-model="year"
                        :options="years"
                        inputLabel="연도"
                        option-label="name"
                        option-value="value"
                    />
                </div>

                <div class="col-sm-10 col-md-5">
                    <i-select
                        v-model="month"
                        :options="months"
                        inputLabel="월"
                        option-label="name"
                        option-value="value"
                    />
                </div>
            </div>

            <!-- 220901 meej isListPage 조건 추가 -->
            <div  v-if="!isMemoType" :class="isListPage? '' : 'row'">
                <div class="col-sm-10 col-md-5 q-pt-xs">
                    <i-datetime v-model="eventDate" date dateLabel="시작일자" />
                </div>
                <div class="col-sm-10 col-md-5 q-pt-xs">
                    <!-- 220329 meej 스케줄 입력 후 재 입력시 endDate 그대로 유지 -->
                    <i-datetime  v-model="endDate" date dateLabel="종료일자" />
                </div>
                <div class="q-pt-xs q-pl-lg">
                    <i-field check v-model="schedule.sequence" label="연속" />
                </div>
            </div>
            <q-card flat bordered class="q-mt-xs">
                <div
                    v-if="isEditPage || isDetailPage"
                    class="row q-pt-md q-pl-md"
                >
                    <div class="col-xs-6 col-sm-4 q-pa-xs">
                        <i-field
                            label="입력일"
                            :text="schedule.createTime"
                            disable
                        />
                    </div>
                    <div class="col-xs-6 col-sm-4 q-pa-xs">
                        <i-field
                            :text="schedule.lastUpdateTime"
                            label="수정일"
                            disable
                        />
                    </div>
                    <!-- <div class="col-sm-4 q-pt-xs">
                        <i-field v-model="schedule.writer" label="입력자" disable />
                    </div> -->
                </div>
                <q-card-section
                    v-for="(event, index) in scheduleList"
                    :key="'new-' + index"
                >
                    <div class="justify-between">
                        <div v-if="index > 0" class="col-12 q-py-xs text-right">
                            <i-btn
                                label="삭제"
                                @click="deleteSchedule(index)"
                            />
                        </div>
                        <i-input
                            class="full-width q-pt-xs"
                            v-model="event.title"
                            @input="handleTitleChange(index, $event)"
                            label="제목"
                            @focus="requestResource(index, 'Event', true)"
                        />
                        <!-- v-model="event.title"  -->
                        <i-input
                            class="full-width q-pt-xs"
                            v-model="event.content"
                            type="textarea"
                            label="내용"
                            :rows="10"
                            @input="typingCount(index)"
                            @focus="requestResource(index, 'Event', true)"
                        />
                        <div
                            v-show="isRegisterPage"
                            class="full-width text-right text-red i-text-small"
                        >
                            {{ event.textBytes }} / {{ MAX_BYTE }}
                        </div>
                        <div class="row q-pt-md">
                            <div class="col-6 col-sm-4">
                                <i-field
                                    check
                                    v-model="event.yearEvent"
                                    inputLabel="연간스케줄"
                                    @focus_handler="
                                        requestResource(index, 'Event')
                                    "
                                />
                            </div>
                            <div class="col-6 col-sm-4">
                                <i-field
                                    check
                                    v-model="event.notSend"
                                    inputLabel="중요하지않음"
                                    @focus_handler="
                                        requestResource(index, 'Event')
                                    "
                                />
                            </div>
                            <div class="col-6 col-sm-4">
                                <i-input
                                    v-model="event.zindex"
                                    inputLabel="순서"
                                    @focus_handler="
                                        requestResource(index, 'Event')
                                    "
                                />
                            </div>
                        </div>
                        <div class="row q-pt-sm">
                            <div class="col-4">
                                <i-field
                                    check
                                    v-model="event.checkPoint"
                                    inputLabel="체크포인트"
                                    @focus_handler="
                                        requestResource(index, 'Event')
                                    "
                                />
                            </div>
                            <!-- // 20230925 -->
                            <div class="col-8">
                                <i-input
                                    v-model="event.note"
                                    label="메모"
                                    @focus="requestResource(index, 'Event')"
                                />
                            </div>
                            <div class="col-4">
                                <i-input
                                    v-model="event.country"
                                    label="국가코드"
                                    @focus="requestResource(index, 'Event')"
                                />
                            </div>
                        </div>
                        <div class="col-8">
                            <i-input
                                type="textarea"
                                v-model="event.explanation"
                                label="설명"
                                @focus="requestResource(index, 'Event')"
                            />
                        </div>
                        <div class="row q-pt-sm">
                            <!-- <q-btn
                                flat
                                rounded
                                color="primary"
                                @click="requestResource(index, 'Theme')"
                                label="관련테마"
                            /> -->
                            <i-theme-search
                                @get="getTheme"
                                label="관련테마"
                                :extra="{ sindex: index }"
                                :status="themeStatus"
                                hide_selected
                            />
                            <div>
                                <q-chip
                                    v-for="(theme, i) in event.themes"
                                    :key="'theme' + i"
                                    outline
                                    square
                                    color="black"
                                    text-color="white"
                                    removable
                                    @remove="removeTheme(index, i)"
                                >
                                    {{ theme.name }}
                                </q-chip>
                            </div>
                        </div>
                        <div class="row q-pt-sm">
                            <div class="row">
                                <i-stock-search
                                    class="col-12"
                                    @get="getStock"
                                    label="관련종목"
                                    :hide_selected="true"
                                    :filterD="true"
                                    :extra="{ sindex: index }"
                                />
                                <i-btn
                                    class="q-mt-xs"
                                    label="실적가져오기"
                                    @click="getLastAnnounce(index)"
                                    size="sm"
                                />
                                <i-select
                                    class="col-12"
                                    style="margin-top: 1%;"
                                    @input="applyForm(form.id)"
                                    v-model="form"
                                    :options="scheduleForms"
                                    inputLabel="스케줄 형식"
                                    option-label="title"
                                    option-value="id"
                                />
                            </div>
                            <div>
                                <q-chip
                                    v-for="(stock, i) in event.stocks"
                                    :key="'stock' + i"
                                    outline
                                    square
                                    color="black"
                                    text-color="white"
                                    removable
                                    @remove="removeStock(index, i)"
                                >
                                    <span
                                        >{{ stock.name }} (
                                        {{ stock.code }} )</span
                                    >
                                </q-chip>
                            </div>
                        </div>
                    </div>
                </q-card-section>
            </q-card>
        </q-scroll-area>

        <!-- 버튼 -->
        <div v-show="isDetailPage || isEditPage" class="row q-my-md text-right">
            <div class="col-12 col-sm-6 q-pa-xs">
                <i-btn class="full-width" label="수정" @click="goSave()" />
            </div>
            <div class="col-12 col-sm-6 q-pa-xs">
                <i-btn class="full-width" label="삭제" @click="deleteItem()" />
            </div>
        </div>
        <div v-if="isRegisterPage" class="row q-my-md">
            <div class="col-12 col-sm-6 q-pa-xs">
                <i-btn class="full-width" label="추가" @click="addSchedule()" />
            </div>
            <div class="col-12 col-sm-6 q-pa-xs">
                <i-btn class="full-width" label="등록" @click="goSave()" />
            </div>
        </div>
    </article>
</template>
<script>
import Debug from '@/debug'
import {
    FormatDate,
    isEmpty,
    getByteSize,
    clone,
    getMaxBytesText,
} from '@/util'

import CONSTANTS from "@/constants";
import Button from '@/components/common/Button'
import DateTime from '@/components/common/DateTime'
import Input from '@/components/common/Input'
import Field from '@/components/common/Field'
import Select from '@/components/common/Select'
import StockSearch from '@/components/common/StockSearchOri'
import ThemeSearch from '@/components/common/ThemeSearchOri'
import moment from "moment"

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Content',
    components: {
        'i-datetime': DateTime,
        'i-field': Field,
        'i-btn': Button,
        'i-input': Input,
        'i-stock-search': StockSearch,
        'i-theme-search': ThemeSearch,
        'i-select': Select,
    },
    data() {
        return {
            type: null,
            scheduleList: [],
            //schedule 정의
            defaultSchedule: {
                id: null,
                title: null,
                content: '',
                eventDate: null,
                endDate: null,
                newsType: null,
                zindex: 0,
                yearEvent: false,
                notSend: false,
                checkPoint: false,
                note: null,
                sequesce: false,
                themes: [],
                stocks: [],
                // 20230925
                country : null,
                explanation : null,
                subtitle : null,
            },
            MAX_BYTE: 10240,
            schedule: {},
            eventDate: null,
            endDate: null,
            lastIndex: 0,
            themeStatus: 'PUBLIC,INTERNAL_PUBLIC',
            /* 
                220422 meej 카테고리 - 메모 일때 연도, 월까지만 
            */
            isMemoType : false,
            months: ['01','02','03','04','05','06','07','08','09','10','11','12'],
            years: [],
            month : moment(new Date()).format("MM").toString(),
            year : moment(new Date()).format("YYYY").toString(),
            scheduleForms: [],  // 스케줄형식 리스트
            form: [],           // 불러온형식 
            formTitle: '',      // 형식제목 
            scheduleTitle: '',  // 스케줄제목 
        }
    },
    created() {
        // 전송시간 오늘날짜로 설정
        this.initSchedule()
        // EventBus를 통한 리소스 카드 추가하기
        //this.$parent.$on('sendResource', this.addResource)
        //        this.$parent.$on('goDetail', this.getScheduleDetail)
    },
    mounted() {
        // 22.12.12 SUYE 스케줄 형식 불러오기 
        let params = {
            newsType: CONSTANTS.BOARD_TYPE[3].value, // BOARD_SCHEDULE_FORM
        }
        this.SELECT_ALL_SCHEDULE_FORM(params)
        .then((data) => {
            this.scheduleForms = data.items
            if(this.scheduleForms && this.scheduleForms.length > 0){
                this.scheduleForms.forEach(e => {
                    e.title = e.title.split(this.cutPoint)[0]
                });
            }
            // console.log('scheduleForms', this.scheduleForms)
        })
        .catch((error) => {
            this.showError({ vc:this, error:error })
        })
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            selectItem: (state) => state.schedule.item,
            eventNewsOptions: (state) => state.resource.EventNewsType,
            resRequest: (state) => state.resource.request,
            cutPoint: (state) => state.manage.cutPoint,
        }),
        ...mapGetters('page', [
            'isListPage',
            'isEditPage',
            'isRegisterPage',
            'isDetailPage',
        ]),
        scheduleOption() {
            return this.eventNewsOptions.slice(1, this.eventNewsOptions.length)
        },
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
            REQUEST_RESOURCE: 'resource/REQUEST_RESOURCE',
            INIT_LIST: 'schedule/INIT_LIST',
        }),
        ...mapActions('schedule', [
            'WRITE_ITEMS',
            'DELETE_ITEMS',
            'ASYNC_LIST',
            'GET_LAST_ANNOUNCE',
        ]),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions('manage', ['SELECT_ALL_SCHEDULE_FORM',]),

        // 20231006 includes로 수정
        handleTitleChange(index, newTitle) {
            this.scheduleList[index].title = newTitle;



            // 미국
            // 미국 옵션만기일 , 선물/옵션 동시 만기일 추가
            if (newTitle.includes("美)") && newTitle.includes("옵션만기일")) {
                this.scheduleList[index].explanation = "선물과 현물의 가격 차이를 이용한 프로그램 매매(차익거래)가 청산되는 날로 매달 세번째 주 금요일임.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 옵션만기일"
            }

            else if (newTitle.includes("美)") && newTitle.includes("만기 국채 입찰(현지시간)")) {
                this.scheduleList[index].explanation = "미국 재무부의 분기별 국채발행 계획에 따라 진행되는 美 국고채 입찰. 발행 예정인 국채 물량 및 입찰 결과에 따라 미국 국채금리와 달러 가격에 영향을 줌.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 만기 국채 입찰"
            }

            else if (newTitle.includes("美)") && newTitle.includes("선물/옵션 동시 만기일")) {
                this.scheduleList[index].explanation = "선물 만기일과 옵션 만기일이 겹치는 날로 매년 3, 6, 9, 12월(분기마다) 세번째 주 금요일임.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 선물/옵션 동시 만기일"
            }

            else if (newTitle.includes("美)") && newTitle.includes("FOMC 회의 발표(현지시간)")) {
                this.scheduleList[index].explanation = "연방준비제도(Fed)는 이틀간 일정으로 통화정책 결정기구인 연방공개시장위원회(FOMC) 회의를 열고, 회의 결과는 마지막 날에 발표. 연준은 FOMC 회의에서 기준금리를 결정.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 FOMC 회의"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("美)") && newTitle.includes("연준 베이지북 공개(현지시간)")) {
                this.scheduleList[index].explanation = "연방준비제도(Fed) 산하의 12개 지역 연방준비은행이 각 지역경제를 조사 및 분석한 보고서. 현재 미국 내 경제 추세 및 문제 상황을 보여줌.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 연준 베이지북"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("美)") && newTitle.includes("FOMC 의사록 공개(현지시간)")) {
                this.scheduleList[index].explanation = "연방공개시장위원회(FOMC) 의사록은 약 3주 전 열린 위원회 정책결정회의에 대한 상세한 기록. 통화정책에 대한 FOMC 입장을 자세히 살펴볼 수 있으며, 향후 금리 결정에 대한 단서를 얻을 수 있음.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 FOMC 의사록"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("美)") && newTitle.includes("GDP 예비치(현지시간)")) {
                this.scheduleList[index].explanation = "국가 경제가 생산한 모든 상품 및 서비스의 총 가치. 가계, 기업, 정부 등 모든 경제주체가 일정기간 동안 생산한 재화 및 서비스의 부가가치를 시장가격으로 평가하여 합산.GDP는 세 번에 걸쳐 예비치, 수정치, 확정치를 발표.";
                this.scheduleList[index].country = "1";
                this.scheduleList[index].subtitle = "미국 GDP 예비치"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("美)") && newTitle.includes("GDP 수정치(현지시간)")) {
                this.scheduleList[index].explanation = "국가 경제가 생산한 모든 상품 및 서비스의 총 가치. 가계, 기업, 정부 등 모든 경제주체가 일정기간 동안 생산한 재화 및 서비스의 부가가치를 시장가격으로 평가하여 합산.GDP는 세 번에 걸쳐 예비치, 수정치, 확정치를 발표.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 GDP 수정치"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("美)") && newTitle.includes("GDP 확정치(현지시간)")) {
                this.scheduleList[index].explanation = "국가 경제가 생산한 모든 상품 및 서비스의 총 가치. 가계, 기업, 정부 등 모든 경제주체가 일정기간 동안 생산한 재화 및 서비스의 부가가치를 시장가격으로 평가하여 합산.GDP는 세 번에 걸쳐 예비치, 수정치, 확정치를 발표.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 GDP 확정치"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("美)") && newTitle.includes("고용동향보고서(현지시간)")) {
                this.scheduleList[index].explanation = "美 노동부가 발표하는 고용동향보고서(Employment Situation). 고용동향보고서에 담겨 있는 실업률과 비농업부문 고용 등은 미국연방준비은행(FRB)이 이자율을 결정할 때 판단의 기준이 되며, 주식시장에 민감하게 반영되는 중요한 지표 중에 하나임.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 고용동향보고서"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("美)") && newTitle.includes("소비자물가지수(현지시간)")) {
                this.scheduleList[index].explanation = "소비자물가지수(CPI)는 대표적인 인플레이션 지표로 가구에서 일상생활을 영위하기 위해 구입하는 상품과 서비스의 가격변동을 측정하기 위해 작성됨. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 소비자물가지수"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("美)") && newTitle.includes("산업생산(현지시간)")) {
                this.scheduleList[index].explanation = "일정 기간 동안 이뤄진 산업생산활동의 수준을 나타내는 지표. 제조업, 광산업 및 유틸리티 산업이 생산한 총산출액의 인플레이션 조정치 변동을 측정. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 산업생산"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("美)") && newTitle.includes("신규주택매매(현지시간)")) {
                this.scheduleList[index].explanation = "주택시장의 선행지표 역할을 하고 있는 중요한 지표중 하나. 신규주택 판매건수의 증가는 경제의 회복을 나타내는 신호이며 감소는 경기의 침체를 나타내는 신호로 판단할 수 있음.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 신규주택매매"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("美)") && newTitle.includes("ISM 제조업지수(현지시간)")) {
                this.scheduleList[index].explanation = "미국의 공급자관리협회(ISM : Institute for Supply Management)가 미국내 20개 업종 400개 이상 회사를 대상으로 매달 설문조사를 실시해 산출하는 지수로 제조업지수와 비제조업(서비스업)지수 두 가지로 발표됨. 미국 실물경제의 대표적인 선행지표로 주식시장에 민감하게 반영되는 지표이며, 50이상이면 경기확장을, 50이하면 수축을 의미. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 ISM 제조업지수"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("美)") && newTitle.includes("ISM 비제조업지수(현지시간)")) {
                this.scheduleList[index].explanation = "미국의 공급자관리협회(ISM : Institute for Supply Management)가 미국내 20개 업종 400개 이상 회사를 대상으로 매달 설문조사를 실시해 산출하는 지수로 제조업지수와 비제조업(서비스업)지수 두 가지로 발표됨. 미국 실물경제의 대표적인 선행지표로 주식시장에 민감하게 반영되는 지표이며, 50이상이면 경기확장을, 50이하면 수축을 의미. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 ISM 비제조업지수"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("美)") && newTitle.includes("잠정주택판매지수(현지시간)")) {
                this.scheduleList[index].explanation = "주택의 매매계약까지는 성사됐으나 대금지급 등 거래가 완결되지 않은 상태를 지수화한 것으로 '펜딩주택판매지수'로도 불림. 기존주택 판매를 1~2달 먼저 예측할 수 있는 선행지표의 역할을 하며, 100을 기준으로 하여 그 이상이면 주택판매활성화, 100 이하면 판매부진을 나타냄.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 잠정주택판매지수"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("美)") && newTitle.includes("개인소득, 개인소비지출(현지시간)")) {
                this.scheduleList[index].explanation = "개인소득(Personal Income)은 모든 경로로 소비자에게 전달된 소득 총액 변화를 측정하며, 전체 경제활동의 대부분을 차지하는 소비자 지출과 밀접한 상관관계가 있음. 개인소비지출(PCE)은 일정 기간 개인이 물건을 구입하거나 서비스를 이용하는 데 지출한 모든 비용을 합친 금액으로, 매월 미국 상무부 경제분석국에서 발표. 美 연방준비제도(Fed)가 선호하는 물가지표로 금리 인상이나 인하 유무를 판단하는 주요 지표로 활용됨.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 개인소득, 개인소비지출"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("美)") && newTitle.includes("ADP취업자 변동(현지시간)")) {
                this.scheduleList[index].explanation = "美 민간 고용정보업체 ADP가 약 400,000개 미국 사업체 고객의 임금 데이터를 근거로 한 비농업 민간 고용의 월 변화에 대한 척도를 보여주는 지표. 美 정부의 고용동향보고서보다 이틀 먼저 발표되기 때문에 정부의 비농업부문 고용에 대한 사전 예측치로 활용됨.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 ADP취업자 변동"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("美)") && newTitle.includes("기존주택판매(현지시간)")) {
                this.scheduleList[index].explanation = "전미부동산협회(National Association of Realtors)에서 발표하며, 주택시장의 선행지표로 활용. 美 상무부에서 발표되는 신규주택판매와 함께 중요한 지표임. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 기존주택판매"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("美)") && newTitle.includes("소매판매(현지시간)")) {
                this.scheduleList[index].explanation = "美 상무부가 매월 발표하는 지표로 소매 단계 총 판매액의 인플레이션 조정치 변동을 측정하여 발표. 미국 소매경기의 대표적인 지표로 미국 소비경기 및 경기 전반의 상황을 알 수 있음. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 소매판매"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("美)") && newTitle.includes("무역수지(현지시간)")) {
                this.scheduleList[index].explanation = "무역으로 발생하는 국제수지, 즉 일정 기간 수출입 거래에 의해 발생한 해당국과 타국간의 대금 수불액 간의 차이. 美 상무부가 매월 발표하며 0보다 큰 경우 수출된 상품/서비스가 수입보다 많다는 것(무역수지 흑자)을 나타냄.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 무역수지"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("美)") && newTitle.includes("생산자물가지수(현지시간)")) {
                this.scheduleList[index].explanation = "대표적인 인플레이션 지표로 美 노동부가 매월 발표함. 미국 내 생산자가 내수시장에 공급하는 상품 및 서비스의 가격의 변동을 종합한 지수. 연방준비제도(Fed)의 정책금리 결정에 영향을 미치는 중요한 역할을 하고 있음.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 생산자물가지수"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("美)") && newTitle.includes("경상수지(현지시간)")) {
                this.scheduleList[index].explanation = "외국과 물건(재화)이나 서비스(용역)를 팔고 산 결과를 종합한 것으로 국가 간 상품 및 서비스의 수출입, 자본 노동 등 생산요소의 이동에 따른 대가의 수입과 지급을 종합적으로 나타낸 지표. 경상수지는 상품수지, 서비스수지, 소득수지, 경상이전수지 등으로 구분됨.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 경상수지"
            }
            else if (newTitle.includes("美)") && newTitle.includes("비농업부문 노동생산성 잠정치(현지시간)")) {
                this.scheduleList[index].explanation = "농축산업을 제외한 전 부문에서 상품/서비스 생산시 노동 효율 연율치의 변동을 측정하며, 잠정치와 확정치를 발표. 생산성과 노동 관련 인플레이션은 직접적으로 관련되어 있으며, 근로자 생산성의 하락은 임금이 상승하는 것과 같음.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 비농업부문 노동생산성 잠정치"
            }
            else if (newTitle.includes("美)") && newTitle.includes("비농업부문 노동생산성 확정치(현지시간)")) {
                this.scheduleList[index].explanation = "농축산업을 제외한 전 부문에서 상품/서비스 생산시 노동 효율 연율치의 변동을 측정하며, 잠정치와 확정치를 발표. 생산성과 노동 관련 인플레이션은 직접적으로 관련되어 있으며, 근로자 생산성의 하락은 임금이 상승하는 것과 같음.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 비농업부문 노동생산성 확정치"
            }
            else if (newTitle.includes("美)") && newTitle.includes("필라델피아 연준 제조업지수(현지시간)")) {
                this.scheduleList[index].explanation = "필라델피아 지역 기업 경기 전반의 상대적 수준을 평가하며 수치가 0보다 높을 경우 경기의 활황, 0 미만일 경우 불황을 의미. 필라델피아 연준 지역의 약 250개 제조업체 대상 설문조사를 통해 산출.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 필라델피아 연준 제조업지수"
            }
            else if (newTitle.includes("美)") && newTitle.includes("경기선행지수(현지시간)")) {
                this.scheduleList[index].explanation = "경기순환의 중요한 전환점과 가까운 장래에 경제가 어디로 가고 있는지를 조기에 보여주며 전월보다 올라가면 경기상승, 내려가면 경기하강을 의미.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 경기선행지수"
            }
            else if (newTitle.includes("美)") && newTitle.includes("주택착공, 건축허가(현지시간)")) {
                this.scheduleList[index].explanation = "해당월에 착공에 들어간 신규 주거용 건물수의 연율환산치 변동을 측정. 주택 부문 경기에 대한 선행지표.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 주택착공, 건축허가"
            }
            else if (newTitle.includes("美)") && newTitle.includes("뉴욕주 제조업지수(현지시간)")) {
                this.scheduleList[index].explanation = "뉴욕주의 일반적인 기업 경기 수준을 평가. 뉴욕주 소재 약 200개 제조업체를 대상으로 한 설문조사를 통해 산출하며 해당 수치가 0보다 클 경우 경기가 상승세, 0 미만일 경우 하락세를 의미.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 뉴욕주 제조업지수"
            }
            else if (newTitle.includes("美)") && newTitle.includes("NAHB 주택시장지수(현지시간)")) {
                this.scheduleList[index].explanation = "현재 및 미래 단독주택 판매의 상대적 수준을 측정. 약 900개 건설업체를 대상으로 실시한 설문조사를 토대로 산출하며 수치가 50을 넘을 경우 주택 판매 전망이 낙관적, 50 미만일 경우 비관적인 것을 의미.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 NAHB 주택시장지수"
            }
            else if (newTitle.includes("美)") && newTitle.includes("제조업 PMI 예비치(현지시간)")) {
                this.scheduleList[index].explanation = "제조업 부문 구매관리자의 활동 수준을 측정. 수치가 50을 넘는 경우 경기 확장을 나타내며, 50 미만은 경기 위축을 의미. 제조업 PMI는 예비치와 확정치를 발표. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 제조업 PMI 예비치"
            }
            else if (newTitle.includes("美)") && newTitle.includes("제조업 PMI 확정치(현지시간)")) {
                this.scheduleList[index].explanation = "제조업 부문 구매관리자의 활동 수준을 측정. 수치가 50을 넘는 경우 경기 확장을 나타내며, 50 미만은 경기 위축을 의미. 제조업 PMI는 예비치와 확정치를 발표. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 제조업 PMI 확정치"
            }
            else if (newTitle.includes("美)") && newTitle.includes("서비스업 PMI 예비치(현지시간)")) {
                this.scheduleList[index].explanation = "마킷 이코노믹스에서 매월 발간하며, 교통, 통신, 금융 중개, 컴퓨팅 및 IT, 호텔 및 음식점 등 민간 서비스 기업의 400여명의 임원들 설문 조사를 바탕으로 지수가 50이면 변화 없음, 50 초과면 개선, 50 미만이면 악화를 나타냄. 서비스업 PMI는 예비치와 확정치를 발표. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 서비스업 PMI 예비치"
            }
            else if (newTitle.includes("美)") && newTitle.includes("서비스업 PMI 확정치(현지시간)")) {
                this.scheduleList[index].explanation = "마킷 이코노믹스에서 매월 발간하며, 교통, 통신, 금융 중개, 컴퓨팅 및 IT, 호텔 및 음식점 등 민간 서비스 기업의 400여명의 임원들 설문 조사를 바탕으로 지수가 50이면 변화 없음, 50 초과면 개선, 50 미만이면 악화를 나타냄. 서비스업 PMI는 예비치와 확정치를 발표. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 서비스업 PMI 확정치"
            }
            else if (newTitle.includes("美)") && newTitle.includes("합성 PMI 예비치(현지시간)")) {
                this.scheduleList[index].explanation = "합성 PMI는 제조업과 서비스업 PMI를 합한 것으로 예비치와 확정치를 발표. 50을 기준선으로 업황의 확장과 위축을 가늠. 합성 PMI는 예비치와 확정치를 발표. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 합성 PMI 예비치"
            }
            else if (newTitle.includes("美)") && newTitle.includes("합성 PMI 확정치(현지시간)")) {
                this.scheduleList[index].explanation = "합성 PMI는 제조업과 서비스업 PMI를 합한 것으로 예비치와 확정치를 발표. 50을 기준선으로 업황의 확장과 위축을 가늠. 합성 PMI는 예비치와 확정치를 발표. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 합성 PMI 확정치"
            }
            else if (newTitle.includes("美)") && newTitle.includes("주택가격지수(현지시간)")) {
                this.scheduleList[index].explanation = "미국 내 다양한 지역의 단독 주택 가격 변동을 나타내는 지표로 전국 또는 특정 지역의 주택 가격 평균 변화를 반영하기 위한 통계임.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 주택가격지수"
            }
            else if (newTitle.includes("美)") && newTitle.includes("S&P 코어로직/CS 주택가격지수(현지시간)")) {
                this.scheduleList[index].explanation = "칼케이스 교수와 로버트 쉴러 교수가 만든 주택가격지수로 S&P(신용평가사)/코어로직(부동산 정보 분석업체)/CS 주택가격지수로 불림. 미국 내 20개 대도시 지역 단독주택 판매가격의 변동을 측정하는 지표. 주택산업의 상태를 파악할 수 있는 선도적인 지표임. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 S&P 코어로직/CS 주택가격지수"
            }
            else if (newTitle.includes("美)") && newTitle.includes("내구재주문(현지시간)")) {
                this.scheduleList[index].explanation = "내구재 수주(Durable Goods Orders)는 운송업 부문을 제외한 내구성 제조업 제품의 신규 수주 총액 변화를 측정. 한번 구입하면 3년 이상 사용하는 내구재(자동차, 항공기, 기계류 등)에 대한 주문을 집계. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 내구재주문"
            }
            else if (newTitle.includes("美)") && newTitle.includes("미시건대 소비심리 평가지수 잠정치(현지시간)")) {
                this.scheduleList[index].explanation = "약 500명의 소비자 대상 설문조사를 통해 현재 및 향후 경제상황을 상대적 수준으로 평가, 2주 간격으로 잠정치와 수정치로 나뉘어 발표됨. 실제 수치가 예상치보다 높은 경우 향후 전망이 긍정적이라는 뜻이며, 낮은 경우 부정적임을 의미함.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 미시건대 소비심리 평가지수 잠정치"
            }
            else if (newTitle.includes("美)") && newTitle.includes("미시건대 소비심리 평가지수 확정치(현지시간)")) {
                this.scheduleList[index].explanation = "약 500명의 소비자 대상 설문조사를 통해 현재 및 향후 경제상황을 상대적 수준으로 평가, 2주 간격으로 예비치와 수정치로 나뉘어 발표됨. 실제 수치가 예상치보다 높은 경우 향후 전망이 긍정적이라는 뜻이며, 낮은 경우 부정적임을 의미함.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 미시건대 소비심리 평가지수 확정치"
            }
            else if (newTitle.includes("美)") && newTitle.includes("시카고 PMI(현지시간)")) {
                this.scheduleList[index].explanation = "시카고 지역 제조업 부문(주로 자동차와 철강 소재 산업에 대한 업황) 경제의 건전성을 측정하는 지표로 통상 매월 마지막 영업일에 발표. 50을 넘는 경우 제조업 부문의 경기 성장, 50 미만인 경우 경기 위축을 의미.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 시카고 PMI"
            }
            else if (newTitle.includes("美)") && newTitle.includes("달라스 연준 제조업 활동(현지시간)")) {
                this.scheduleList[index].explanation = "댈러스 연준이 주 내 공장 활동에 대한 평가를 확보하기 위해 매월 텍사스 제조업 전망 설문조사를 실시해 산출하는 지표. 증가를 보고한 기업의 비중이 감소를 보고한 기업의 비중을 초과하는 경우 지수는 0보다 크며, 감소를 보고한 기업의 비중이 증가를 보고한 기업의 비중을 초과하는 경우 지수는 0보다 작음.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 달라스 연준 제조업 활동"
            }
            else if (newTitle.includes("美)") && newTitle.includes("소비자 인플레이션 기대치(현지시간)")) {
                this.scheduleList[index].explanation = "美 뉴욕 연방준비은행이 매월 발표하는 전월 미국 가계가 예상하는 장기, 중기, 단기 인플레이션 예상치. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 소비자 인플레이션 기대치"
            }
            else if (newTitle.includes("美)") && newTitle.includes("자동차판매(현지시간)")) {
                this.scheduleList[index].explanation = "자동차 시장조사 업체 Autodata Corp가 매월초 Motor Intelligence 를 통해 발표하는 전월 미국 국내 자동차 판매량 지표";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 자동차판매"
            }
            else if (newTitle.includes("美)") && newTitle.includes("건설지출(현지시간)")) {
                this.scheduleList[index].explanation = "미국의 건설 경기를 보여주는 지표. 美 상무부가 매월초 발표하며, 2개월 전 건설 분야에 사용된 총 지출액의 변동을 나타냄. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 건설지출"
            }
            else if (newTitle.includes("美)") && newTitle.includes("공장주문(현지시간)")) {
                this.scheduleList[index].explanation = "美 상무부가 매월 발표하는 2개월 전의 내구재·비내구재 주문에 대한 통계치. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 공장주문"
            }
            else if (newTitle.includes("美)") && newTitle.includes("소비자신용지수(현지시간)")) {
                this.scheduleList[index].explanation = "美 컨퍼런스 보드가 매월 5,000가구를 대상으로 6개월 후의 경기, 생활형편, 소비자지출계획, 고용 및 가계수입에 대한 전망 등을 조사해 평균치 100을 기준으로 지수화해 발표하는 지수. 소비자 심리를 예측할 수 있는 지표로 사용됨";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 소비자신용지수"
            }
            else if (newTitle.includes("美)") && newTitle.includes("도매재고지수(현지시간)")) {
                this.scheduleList[index].explanation = "美 상무부가 매월 발표하는 2개월 전의 도매업 재고 변화율을 나타내는 지표. 기업의 소비를 예측할 수 있는 선행지표로 사용. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 도매재고지수"
            }
            else if (newTitle.includes("美)") && newTitle.includes("수출입물가지수(현지시간)")) {
                this.scheduleList[index].explanation = "美 노동부가 매월 발표하는 전월치의 수입/수출 상품의 가격변동을 지수화한 지표. 수입/수출품의 가격이 물가에 미치는 영향을 파악하는 지표로 사용. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 수출입물가지수"
            }
            else if (newTitle.includes("美)") && newTitle.includes("기업재고(현지시간)")) {
                this.scheduleList[index].explanation = "美 상무부가 매월 발표하는 2개월 전의 도, 소매업 및 제조업 재고수준";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 기업재고"
            }
            else if (newTitle.includes("美)") && newTitle.includes("구인 및 이직(JOLTs) 보고서(현지시간)")) {
                this.scheduleList[index].explanation = "JOLTs(Job Openings and Labor Turnover Survery)는 美 노동부가 매월 발표. 기업의 구인, 고용, 퇴직에 대한 지표를 확인할 수 있으며, 미국 경제의 일자리 및 고용시장 등의 추이를 나타내는 지표 중 하나. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 구인 및 이직(JOLTs) 보고서"
            }
            else if (newTitle.includes("美)") && newTitle.includes("상품수지(현지시간)")) {
                this.scheduleList[index].explanation = "美 상무부가 매월 발표. 미국 상품의 수출과 수입의 차이를 나타내는 지표이자 경상수지의 한 부분. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 상품수지"
            }
            else if (newTitle.includes("美)") && newTitle.includes("NFIB 소기업 낙관지수(현지시간)")) {
                this.scheduleList[index].explanation = "전미자영업연맹(NFIB)이 소기업들을 대상으로 집계한 경기낙관지수로, 10개의 계절조정치로 구성된 종합지수. 미국 민간부문 고용의 약 50%를 차지하는 미국 소기업의 건전성에 대한 지표를 제공.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 NFIB 소기업 낙관지수"
            }
            else if (newTitle.includes("美)") && newTitle.includes("소비자신뢰지수(현지시간)")) {
                this.scheduleList[index].explanation = "美 소비자신뢰지수는 컨퍼런스보드(CB)가 발표. 미국 경제상태를 나타내는 경기선행지수의 하나로 전체 경제상황과 개인적 재무 상황에 대해 소비자가 낙관적으로 느끼는 정도를 측정하기 위한 경제 지표. 보통 지수가 100을 넘으면 소비자들이 경기를 낙관한다는 뜻임.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 소비자신뢰지수"
            }
            else if (newTitle.includes("美)") && newTitle.includes("리치몬드 연방 제조업지수(현지시간)")) {
                this.scheduleList[index].explanation = "워싱턴DC, 버지니아, 메릴랜드, 사우스캐롤라이나, 노스캐롤라이나 등을 대상으로 기업 활동 여건을 조사한 수치. 약 100개의 제조업체를 대상으로 하는 설문조사를 토대로 산출하며, 출하량(shipments), 신규수주 및 고용을 포함한 기업 경기의 상대적 수준을 평가. 수치가 0보다 클 경우 기업활동이 나아졌음을, 0보다 작을 경우 기업활동이 어려워졌다는 의미.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 리치몬드 연방 제조업지수"
            }
            else if (newTitle.includes("美)") && newTitle.includes("시카고 연은 국가활동지수(현지시간)")) {
                this.scheduleList[index].explanation = "미국의 전반적인 경제상황과 물가동향을 알아보기 위해 85개의 경제지표를 가중평균해 만든 종합적인 경제지수. 0을 기준으로 경제의 성장과 위축을 판단. 경제 성장의 추적 및 잠재적 인플레이션의 확인에 유용.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 시카고 연은 국가활동지수"
            }
            else if (newTitle.includes("美)") && newTitle.includes("캔자스 연준 제조업지수(현지시간)")) {
                this.scheduleList[index].explanation = "캔자스 시티 연준 관할지역의 제조업 활동에 대한 정보를 제공. 생산 활동 지수, 신규 주문, 고용, 납품 및 원자재 주식의 평균 가치 변화를 반영하며, 약 300개 제조 회사를 대상으로 한 월별 조사에 기초하여 계산. 지수가 플러스 값이라면 제조업 활동이 확장, 마이너스 값이라면 이 활동이 축소됐음을 의미.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 캔자스 연준 제조업지수"
            }
            else if (newTitle.includes("美)") && newTitle.includes("주간 원유재고(현지시간)")) {
                this.scheduleList[index].explanation = "미국에너지정보청(EIA)에서 주간 단위로 발표하는 원유재고 변동치.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 주간 원유재고"
            }
            else if (newTitle.includes("美)") && newTitle.includes("주간 MBA 모기지 신청건수(현지시간)")) {
                this.scheduleList[index].explanation = "주택 시장의 동향과 활동을 이해하기 위한 경제 지표로 해당주간에 MBA가 보증한 모기지 신규 신청 건수의 변동을 측정. ";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 주간 MBA 모기지 신청건수"
            }
            else if (newTitle.includes("美)") && newTitle.includes("주간 신규 실업수당 청구건수(현지시간)")) {
                this.scheduleList[index].explanation = "주간 단위로 발표하는 실업수당 청구건수 중 신규로 신청한 숫자를 합산한 수치. 청구건수 증가 시 경기가 나쁘다는 의미로 볼 수 있고, 감소 시 경기가 좋아지고 있다고 볼 수 있음.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 주간 신규 실업수당 청구건수"
            }
            else if (newTitle.includes("美)") && newTitle.includes("주간 원유 채굴장비 수(현지시간)")) {
                this.scheduleList[index].explanation = "원유정보업체 베이커휴즈가 미국과 캐나다의 시추 활동을 매주 집계/발표. 석유 시추 산업의 중요한 비즈니스 지표.";
                this.scheduleList[index].country = "1"
                this.scheduleList[index].subtitle = "미국 주간 원유 채굴장비 수"
            }

            // 중국
            else if (newTitle.includes("中)") && newTitle.includes("소비자물가지수")) {
                this.scheduleList[index].explanation = "소비자가 구매하는 상품 및 서비스 가격의 변동을 측정하는 지수로 대표적 인플레이션 지표임 ";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 소비자물가지수"
            }
            else if (newTitle.includes("中)") && newTitle.includes("차이신 제조업 PMI")) {
                this.scheduleList[index].explanation = "중국 경제 전문매체 차이신이 발표하는 제조업 부문 활동을 전반적으로 조망할 수 있도록 설계된 종합지표이며, 전체 경제에 대한 선행지표의 역할. 수치가 50 미만이면 제조업 경기가 위축, 50이상이면 제조업 경기 확장을 나타냄. ";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 차이신 제조업 PMI"
            }
            else if (newTitle.includes("中)") && newTitle.includes("차이신 종합 PMI")) {
                this.scheduleList[index].explanation = "중국 경제 전문매체 차이신이 발표하는 PMI 지수로 제조업 PMI와 서비스업 PMI를 합산. 수치가 50 미만이면 경기축소, 50이 넘을 경우 경기확장을 의미.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 차이신 종합 PMI"
            }
            else if (newTitle.includes("中)") && newTitle.includes("차이신 서비스업 PMI")) {
                this.scheduleList[index].explanation = "중국 경제 전문매체 차이신이 발표하는 서비스업(비제조업) 부문의 전체 경제 상황의 지표로 계산되는 종합지수. 400개 이상의 민간 서비스 부문 기업 구매 담당 임원들에게 질문지를 발송하여 산출. 수치가 50을 넘을 경우 서비스업 부문 경기가 호황, 50 미만일 경우 서비스업 부문 경기 위축을 의미.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 차이신 서비스업 PMI"
            }
            else if (newTitle.includes("中)") && newTitle.includes("제조업 PMI")) {
                this.scheduleList[index].explanation = "중국물류구매연합회(CFLP)와 HSBC에서 작성되며, 매월 중국 제조업 부문 경제 활동에 대한 조기 지표를 제공. 수치가 50 미만이면 제조업 경기가 하락, 50이 넘을 경우 제조업 경기가 확장중임을 나타냄.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 제조업 PMI"
            }
            else if (newTitle.includes("中)") && newTitle.includes("비제조업 PMI")) {
                this.scheduleList[index].explanation = "중국 비제조업 부문 매월 경제 활동의 조기 지표를 제공. 국가통계국(NBS)에서 수집한 데이터에 기반하여 중국 물류구매연합회(CFLP), 중국 물류정보센터(CLIC)가 산출. 수치가 50을 넘을 경우 비제조업 부문 경기가 확장, 50 미만일 경우 비제조업 부문 경기 위축을 의미.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 비제조업 PMI"
            }
            else if (newTitle.includes("中)") && newTitle.includes("광의통화(M2)")) {
                this.scheduleList[index].explanation = "협의통화(M1)에 정기 예금이나 정기 적금과 같은 저축성 예금을 포함한 통화량을 의미. 중앙은행이 통화정책을 수립하고 시중의 통화량을 측정하는데 주요한 지표로 활용.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 광의통화(M2)"
            }
            else if (newTitle.includes("中)") && newTitle.includes("외국인 직접 투자")) {
                this.scheduleList[index].explanation = "외국인이 기업의 경영에 실질적인 영향력을 행사하며 지속적인 경제관계를 수립할 목적으로 투자하는 것.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 외국인 직접 투자"
            }
            else if (newTitle.includes("中)") && newTitle.includes("무역수지")) {
                this.scheduleList[index].explanation = "무역으로 발생하는 국제수지, 해당월 수출/수입 상품 및 서비스의 가치 차이를 측정. 수치가 0보다 크면 수출 규모가 수입보다 큼을 의미.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 무역수지"
            }
            else if (newTitle.includes("中)") && newTitle.includes("외환보유액")) {
                this.scheduleList[index].explanation = "일정 시점에 보유하고 있는 대외 외환 채권의 총액. ";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 외환보유액"
            }
            else if (newTitle.includes("中)") && newTitle.includes("주택가격지수")) {
                this.scheduleList[index].explanation = "전국 또는 특정 지역의 주택 평균가격 변동을 반영하기 위해 설계된 통계치로 주택 시장에서의 가격 변동을 측정하는 지표.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 주택가격지수"
            }
            else if (newTitle.includes("中)") && newTitle.includes("중기유동성지원창구(MLF) 금리 발표")) {
                this.scheduleList[index].explanation = "MLF는 인민은행이 중국의 은행권에 중기 유동성을 공급하는 창구로 인민은행은 이를 통해 유동성 총량과 금리를 조절.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 중기유동성지원창구(MLF) 금리"
            }
            else if (newTitle.includes("中)") && newTitle.includes("실업률")) {
                this.scheduleList[index].explanation = "경제활동인구 중에서 실업자가 차지하는 비율로 中 국가통계국이 매월 집계하여 발표.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 실업률"
            }
            else if (newTitle.includes("中)") && newTitle.includes("소매판매")) {
                this.scheduleList[index].explanation = "中 국가통계국이 매월 발표하는 지표로 소비동향을 파악하기 위해 작성되며, 전체 경제 활동의 대부분을 차지하는 소비자 지출의 가장 중요한 지표로 활용.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 소매판매"
            }
            else if (newTitle.includes("中)") && newTitle.includes("산업생산")) {
                this.scheduleList[index].explanation = "中 국가통계국이 매월 발표하는 지표로 제조업, 광산업 및 유틸리티 산업이 생산한 총산출액의 인플레이션 조정치 변동을 측정한 지표.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 산업생산"
            }
            else if (newTitle.includes("中)") && newTitle.includes("고정자산투자")) {
                this.scheduleList[index].explanation = "中 국가통계국이 매월 발표하는 지표로 공장, 도로, 전력망, 부동산 등과 같은 비농촌지역 자본투자의 총지출 변동을 측정한 지표.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 고정자산투자"
            }
            else if (newTitle.includes("中)") && newTitle.includes("대출우대금리(LPR) 발표")) {
                this.scheduleList[index].explanation = "사실상 중국의 기준금리 역할을 하는 지표로 中 인민은행이 전국은행간대출센터에서 계산하여 공표할 수 있는 기초적인 대출참고금리임. 1년물과 5년물 LPR을 발표하며 특히, 5년물 LPR의 경우 부동산대출금리에 영향을 미치고 있음. 대출우대금리(LPR) 발표에 앞서 발표되는 중기유동성지원창구(MLF) 대출 금리를 통해 LPR 금리의 방향성을 나타내는 경향이 있음.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 대출우대금리(LPR)"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("中)") && newTitle.includes("생산자물가지수")) {
                this.scheduleList[index].explanation = "생산자물가지수(Producer Price Index)는 중국 내 생산자가 내수시장에 공급하는 상품 및 서비스의 가격의 변동을 종합한 지수로 中 국가통계국이 매월 집계하여 발표.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 생산자물가지수"
            }
            else if (newTitle.includes("中)") && newTitle.includes("차량 판매(연간)")) {
                this.scheduleList[index].explanation = "중국자동차공업협회(CAMM)가 매월 발표하는 차량판매 지표.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 차량 판매"
            }
            else if (newTitle.includes("中)") && newTitle.includes("공업이익")) {
                this.scheduleList[index].explanation = "中 국가통계국이 발표하는 지표로 중국 공업 분야 기업들의 수익성 동향을 나타냄.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 공업이익"
            }
            else if (newTitle.includes("中)") && newTitle.includes("분기 GDP")) {
                this.scheduleList[index].explanation = "국가경제가 생산한 모든 상품 및 서비스 가치의 인플레이션 조정치의 연율 환산 변동을 측정한 지표로 中 국가통계국이 분기마다 집계해 발표.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 분기 GDP"
            }
            else if (newTitle.includes("中)") && newTitle.includes("분기 경상수지")) {
                this.scheduleList[index].explanation = "외국과 물건(재화)이나 서비스(용역)를 팔고 산 결과를 종합한 것으로 국가 간 상품 및 서비스의 수출입, 자본 노동 등 생산요소의 이동에 따른 대가의 수입과 지급을 종합적으로 나타낸 지표. 경상수지는 상품수지, 서비스수지, 소득수지, 경상이전수지 등으로 구분됨.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "중국 분기 경상수지"
            }
            else if (newTitle.includes("홍콩)") && newTitle.includes("중앙은행 기준금리결정")) {
                this.scheduleList[index].explanation = "홍콩은 1983년 이래로 달러페그제(고정환율제)를 채택하고 있어 미국의 금리와 연동되고 있으며, 홍콩금융관리국이 기준금리를 발표.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "홍콩 중앙은행 기준금리"
            }
            else if (newTitle.includes("대만)") && newTitle.includes("중앙은행 기준금리결정")) {
                this.scheduleList[index].explanation = "대만 중앙은행이 매 분기 발표하는 정책 금리.";
                this.scheduleList[index].country = "86"
                this.scheduleList[index].subtitle = "대만 중앙은행 기준금리"
            }
            // 국내
            else if (newTitle.includes("KDI") && newTitle.includes("북한경제리뷰")) {
                this.scheduleList[index].explanation = "북한경제의 실태 및 남북한 경제협력과 관련한 주요 이슈를 분석하고 정리하여 정책 당국자, 학계 및 업계 등의 이해를 높이고 정책방안을 도출하는데 도움을 주고자 발간되는 보고서로 한국개발연구원(KDI)이 매월 발간.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "북한경제리뷰"
            }
            // else if (newTitle.includes("KDI") && newTitle.includes("경제전망 수정")) {
            //     this.scheduleList[index].explanation = "우리나라의 현 경제상황에 대한 인식, 국내경제 전망과 위험요인 등을 종합하여 한국개발연구원(KDI)이 상반기/하반기로 나눠서 발표.";
            //     this.scheduleList[index].country = "82"
            // }
            else if (newTitle.includes("KDI") && newTitle.includes("경제전망")) {
                this.scheduleList[index].explanation = "한국개발연구원이 우리나라 현 경제상황에 대한 인식, 대외여건에 대한 주요 전제, 국내경제 전망, 전망의 위험요인 등을 종합해 발표. 상/하반기로 나눠서 발표하며 각 반기는 3달 뒤 수정치를 발표.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "경제전망"
            }
            else if (newTitle.includes("KDI") && newTitle.includes("경제동향")) {
                this.scheduleList[index].explanation = "최근의 경제동향을 파악할 수 있는 국내외 거시경제 통계를 바탕으로 경제현황을 분석하고 전망을 제시하는 보고서로서 한국개발연구원(KDI)이 매월 발간.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "경제동향"
            }
            else if (newTitle.includes("ICT") && newTitle.includes("수출입 동향")) {
                this.scheduleList[index].explanation = "관세청 통관 기준 수출입 실적 자료를 제공받아 이를 ICT산업분류 기준에 의거하여 분류, 가공, 분석을 통해 산출한 ICT교역 통계. ICT산업의 수출입 위상 파악, 교역 구조변화, 수출경쟁력, 품목별 수출동향 등을 파악할 수 있으며,정부와 업계의 정책수립 및 경영 전략수립의 기초 자료로 활용 가능.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "수출입 동향"
            }
            else if (newTitle.includes("통화 및 유동성")) {
                this.scheduleList[index].explanation = "시중에 통화량이 얼마나 풀려있는지 확인할 수 있는 지표로 한국은행이 매월 집계하여 발표. 시중에 풀린 통화량이 많을 수록 자산가격이 상승할 가능성이 높고, 시중에 통화량이 줄어들 수록 자산의 가격은 줄어들 가능성이 높음.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "통화 및 유동성"
            }
            else if (newTitle.includes("최근 경제동향")) {
                this.scheduleList[index].explanation = "기획재정부에서 매월 발간하는 경제동향 자료. 고용, 물가, 재정, 해외경제, 민간소비, 설비투자, 건설투자, 수출입, 국제수지, 광공업 생산, 서비스업 생산, 전산업생산 및 경기종합지수, 금융/외환 시장, 부동산 시장 등 부문별 동향을 정리하여 제공.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "최근 경제동향"
            }
            else if (newTitle.includes("주요 유통업체 매출 동향")) {
                this.scheduleList[index].explanation = "산업통산자원부에서 매월 발간하는 유통업체 매출 동향 자료로 온/오프라인, 상품군별, 업태별 등 항목별 매출 동향 파악이 용이하도록 제공.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "주요 유통업체 매출 동향"
            }
            else if (newTitle.includes("인구주택총조사 전수결과")) {
                this.scheduleList[index].explanation = "통계청이 매년 발표하는 인구주택총조사 전수결과 자료. 총인구, 시도 인구증감률, 생산연령 인구, 노령화지수 등 인구 통계 자료를 제공.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "인구주택총조사 전수결과"
            }
            else if (newTitle.includes("국제인구이동")) {
                this.scheduleList[index].explanation = "법무부 출입국자료를 기초로 상주지 국가를 떠나 90일을 초과하여 체류한 내국인과 외국인 국제이동자를 집계.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "국제인구이동"
            }
            else if (newTitle.includes("재정증권 발행계획")) {
                this.scheduleList[index].explanation = "재정증권은 세입-세출 간 시기적 불일치 등으로 인해 일시적으로 발생한 국고 부족자금을 충당하기 위해 금융시장에서 발행하는 유가증권으로 매월 발표.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "재정증권 발행계획"
            }
            else if (newTitle.includes("자금순환(잠정)")) {
                this.scheduleList[index].explanation = "국민경제를 구성하는 경제부문간 금융거래와 금융자산·부채 현황을 체계적으로 기록한 통계로, 경제부문별 자금 과부족 현황, 자금 운용 및 조달 행태 뿐만 아니라 생산·소비·투자 등 실물거래와의 관계를 파악하는 데 유용. 금융거래표와 금융자산·부채잔액표로 구성.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "자금순환"
            }
            else if (newTitle.includes("인구동향")) {
                this.scheduleList[index].explanation = "인구규모 및 구조의 변동요인인 출생․사망․혼인․이혼 현황을 파악하여 제공. 통상 익익월말(매 분기에는 분기결과 추가)에 공표.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "인구동향"
            }
            else if (newTitle.includes("월간 재정동향")) {
                this.scheduleList[index].explanation = "국가의 총수입, 총지출을 비롯해 통합재정수지, 관리재정수지, 중앙정부 채무를 월간 단위로 발표.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "월간 재정동향"
            }
            else if (newTitle.includes("외환보유액") && this.type.value === "EVENT_DOMESTIC_INDICATOR") {
                this.scheduleList[index].explanation = "중앙은행이나 정부가 국제수지 불균형을 보전하거나 외환시장 안정을 위해 언제든지 사용할 수 있도록 보유하고 있는 대외 지급준비자산.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "외환보유액"
            }
            else if (newTitle.includes("외국인직접투자")) {
                this.scheduleList[index].explanation = "일반적으로 외국인이 1억원 이상을 투자하면서 국내기업 주식등의 10% 이상을 취득하거나 외국인투자기업이 해외 모기업으로부터 5년 이상의 장기차관을 대부받는 것의 동향 지표로 매 분기 익월 발표.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "외국인직접투자"
            }
            else if (newTitle.includes("옵션만기일") && this.type.value === "EVENT_DOMESTIC_INDICATOR") {
                this.scheduleList[index].explanation = "선물과 현물의 가격 차이를 이용한 프로그램 매매(차익거래)가 청산되는 날로 매달 둘째 주 목요일임.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "옵션만기일"
            }
            else if (newTitle.includes("온라인쇼핑동향")) {
                this.scheduleList[index].explanation = "PC기반 인터넷쇼핑 거래액과 모바일기반 인터넷쇼핑 거래액을 포괄한 온라인쇼핑 거래액의 통계로 매월 발표.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "온라인쇼핑동향"
            }
            else if (newTitle.includes("예금취급기관 가계대출")) {
                this.scheduleList[index].explanation = "금융기관에서 한국은행으로 보내오는 통화금융통계조사표 결과 집계. 매월 발표.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "예금취급기관 가계대출"
            }
            else if (newTitle.includes("여름철 전력수급 전망 및 대책 발표")) {
                this.scheduleList[index].explanation = "안정적 전력수급을 위한 수급 현황 및 전망 파악, 예비자원 확보 등 대책 수립, 공공기관 및 민간기업에 대한 에너지 절약 협조 요청을 목적으로 발표.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "여름철 전력수급 전망 및 대책"
            }
            else if (newTitle.includes("실질 국내총생산(속보)")) {
                this.scheduleList[index].explanation = "소유에 관계없이 한 나라에 있는 노동, 자본 등 모든 생산요소를 결합하여 만들어낸 최종생산물의 합인 생산활동지표. 기준연도(현재 2000년을 적용) 시장가격×최종생산물의 총계.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "실질 국내총생산"
            }
            // else if (newTitle.includes("수출입물가지수") && this.type.value === "EVENT_DOMESTIC_INDICATOR") {
            //     this.scheduleList[index].explanation = "수출 및 수입상품의 가격변동을 측정하는 통계지표. 개별품목의 수출입액이 모집단거래액의 1/2,000이상의 거래비중을 가지는 품목으로서 동종 제품군의 가격 변동을 대표하면서 가격시계열 유지가 가능한 품목을 조사대상품목으로 선정.";
            //     this.scheduleList[index].country = "82"
            //     this.scheduleList[index].subtitle = "수출입물가지수"
            // }
            else if (newTitle.includes("수출입물가지수 및 무역지수") && this.type.value === "EVENT_DOMESTIC_INDICATOR") {
                this.scheduleList[index].explanation = "수출 및 수입상품의 가격변동을 측정하는 통계지표. 개별품목의 수출입액이 모집단거래액의 1/2,000이상의 거래비중을 가지는 품목으로서 동종 제품군의 가격 변동을 대표하면서 가격시계열 유지가 가능한 품목을 조사대상품목으로 선정. 무역지수는 수출입금액 변동을 가격과 물량요인으로 나누어 파악하기 위해 작성하는 통계.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "수출입물가지수 및 무역지수"
            }
            else if (newTitle.includes("수출입동향")) {
                this.scheduleList[index].explanation = "물품이 국가 간에 이동함으로써 국내 자원량의 증가 또는 감소를 가져오는 경우에 수출입통계로 계상하는 것이 원칙이며, 정상적인 통관시 수출입신고수리일을 기준으로 계상. 산업통상자원부는 매월 1일에 전월에 대한 수출입동향을 발표.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "수출입동향"
            }
            else if (newTitle.includes("소비자물가동향")) {
                this.scheduleList[index].explanation = "도시가계가 일상생활을 영위하기 위해 구입하는 상품가격과 서비스 요금의 변동을 종합적으로 측정하기 위해 작성하는 지수. 2020년을 기준(=100)으로 가계소비지출에서 차지하는 비중으로 1,000분비로 산출, 품목 458개를 대상으로 작성.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "소비자물가동향"
            }
            else if (newTitle.includes("소비자동향조사")) {
                this.scheduleList[index].explanation = "소비자의 경제상황에 대한 인식과 향후 소비지출전망 등을 설문조사하여 그 결과를 지수화한 거승로 기준값 100으로 하여 100보다 크면 장기평균보다 낙관적임을, 100보다 작으면 비관적임을 의미.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "소비자동향조사"
            }
            else if (newTitle.includes("선물/옵션 동시 만기일") && this.type.value === "EVENT_DOMESTIC_INDICATOR") {
                this.scheduleList[index].explanation = "선물 만기일과 옵션 만기일이 겹치는 날로 매년 3, 6, 9, 12월 두 번째 목요일.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "선물/옵션 동시 만기일"
            }
            else if (newTitle.includes("생산자물가지수") && this.type.value === "EVENT_DOMESTIC_INDICATOR") {
                this.scheduleList[index].explanation = "국내에서 생산하여 국내시장에 출하되는 모든 재화와 서비스요금(부가가치세를 제외한 공장도 가격)의 변동을 측정하기 위하여 작성하는 지수(기준년도 2015년=100). 국내출하액이 모집단금액의 1/10,000 이상인 781개 상품, 서비스의 경우 1/2,000 이상인 거래비중을 갖는 103개 품목.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "생산자물가지수"
            }
            else if (newTitle.includes("산업활동동향")) {
                this.scheduleList[index].explanation = "생산, 소비, 투자 및 경기 전반에 대한 통계로 매월 발표.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "산업활동동향"
            }
            else if (newTitle.includes("해외직접투자 동향")) {
                this.scheduleList[index].explanation = "거주자(개인 또는 법인)가 국외 기업에 경영참여를 목적으로 10%이상의 주식 또는 동등한 지분을 취득하거나 1년이상 기업에 대부하는 투자. 10% 미만이라도 임원 파견 등 일정 요건하의 투자와 외국환거래법시행령에 규정된 투자(해외자원개발)도 해외직접투자에 해당. 분기마다 발표.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "해외직접투자 동향"
            }
            else if (newTitle.includes("대외채권·채무 동향 발표")) {
                this.scheduleList[index].explanation = "한국의 대외건전성 등 대외지급능력 등을 판단하는 기초자료. 대외채무란 '일정시점에 일국의 거주자가 비거주자에 대해 미래특정 시점에 금융원금 또는 이자를 지급해야 하는 확정채무잔액'을 의미, 대외채권은 '일정시점에 일국의 거주자가 비거주자로부터 미래특정시점에 원금 또는 이자를 회수하게 될 확정채권잔액'을 뜻함.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "대외채권·채무 동향"
            }
            // else if (newTitle.includes("무역지수 및 교역조건")) {
            //     this.scheduleList[index].explanation = "무역지수는 수출입금액 변동을 가격과 물량요인으로 나누어 파악하기 위해 작성하는 통계이며, 교역조건지수는 수출품과 수입품의 교환 비율을 나타냄";
            //     this.scheduleList[index].country = "82"
            //     this.scheduleList[index].subtitle = "무역지수 및 교역조건"
            // }
            else if (newTitle.includes("담배시장 동향")) {
                this.scheduleList[index].explanation = "담배판매량을 보여주는 지표로 담배 소비형태에 따라 궐련과 궐련형 전자담배로 나뉨";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "담배시장 동향"
            }
            else if (newTitle.includes("기업경영분석")) {
                this.scheduleList[index].explanation = "국내법인 기업의 성장성, 수익성, 안전성, 현금흐름 등 기업들의 경영상황을 보여주는 지표";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "기업경영분석"
            }
            // else if (newTitle.includes("기업경기실사지수(BSI) 및 경제심리지수(ESI)")) {
            //     this.scheduleList[index].explanation = "BSI는 매월 기업경기 동향 파악 및 다음 달 전망을 위해 기업가의 현재 기업경영상황에 대한 판단과 향후 전망을 설문조사하여 지수화한 수치. ESI는 기업과 소비자 등 모든 민간 경제주체의 경제상황에 대한 심리를 종합적으로 파악하기 위해 BSI와 CSI를 합성한 수치. 양 지표 모두 수치가 100을 넘으면 경기 전망에 긍정으로 답한 사람이 부정으로 답한 사람보다 많음을 의미.";
            //     this.scheduleList[index].country = "82"
            //     this.scheduleList[index].subtitle = "기업경기실사지수(BSI) 및 경제심리지수(ESI)"
            // }
            else if (newTitle.includes("기업경기조사 결과 및 경제심리지수(ESI)")) {
                this.scheduleList[index].explanation = "기업경기조사는 매월 기업경기 동향 파악 및 다음 달 전망을 위해 기업가의 현재 기업경영상황에 대한 판단과 향후 전망을 조사하여 지수화한 수치. ESI는 기업과 소비자 등 모든 민간 경제주체의 경제상황에 대한 심리를 종합적으로 파악하기 위해 BSI와 CSI를 합성한 수치. 양 지표 모두 수치가 100을 넘으면 경기 전망에 긍정으로 답한 사람이 부정으로 답한 사람보다 많음을 의미.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "기업경기조사 결과 및 경제심리지수(ESI)"
            }
            else if (newTitle.includes("금융통화위원회")) {
                this.scheduleList[index].explanation = "기준금리 결정 등 한국은행의 통화신용정책에 관한 주요 사항을 심의·의결하는 정책결정회의. 회의 논의내용에 대해 의사록을 작성하고, 의사록 내용 중 통화신용정책에 관한 사항에 대해서는 외부에 공개.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "금융통화위원회"
                this.scheduleList[index].checkPoint = true
            }
            else if (newTitle.includes("금융시장 동향")) {
                this.scheduleList[index].explanation = "국내증시, 국고채금리, 은행 가계·기업대출 등 월별 금융시장 동향을 개괄적으로 보여주는 지표";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "금융시장 동향"
            }
            else if (newTitle.includes("금융기관 가중평균금리")) {
                this.scheduleList[index].explanation = "총수신금리, 총대출금리 등 금융기관 가중평균금리를 개괄적으로 보여주는 지표";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "금융기관 가중평균금리"
            }
            else if (newTitle.includes("국제투자대조표(잠정)")) {
                this.scheduleList[index].explanation = "일정시점 현재 한 나라 거주자의 비거주자에 대한 금융자산(대외투자) 및 금융부채(외국인투자) 잔액을 기록한 통계";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "국제투자대조표"
            }
            else if (newTitle.includes("국제수지(잠정)")) {
                this.scheduleList[index].explanation = "일정기간 거주자와 비거주자 사이에 발생한 모든 경제적 거래를 종합적으로 기록한 통계. 한국은 수출입 등 대외거래가 경제에서 차지하는 비중이 매우 큰 나라로 국제수지는 대외거래 동향을 파악할 수 있으며, 국가 경제정책 수립 및 정책효과 분석 등에 사용되는 지표임.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "국제수지"
            }
            else if (newTitle.includes("국제금융/외환시장 동향")) {
                this.scheduleList[index].explanation = "한국은행에서 발간된 월별 국제금융시장 및 외환시장의 거시적 현황 자료";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "국제금융/외환시장 동향"
            }
            else if (newTitle.includes("국세수입 현황 발표")) {
                this.scheduleList[index].explanation = "소득세, 부가가치세, 법인세 등 국세수입 현황을 개괄적으로 보여주는 지표로 향후 예산 집행 등에 참고자료로 활용되고 있음.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "국세수입 현황 발표"
            }
            else if (newTitle.includes("국민소득(잠정)")) {
                this.scheduleList[index].explanation = "한 나라의 가계, 기업, 정부 등 모든 경제주체가 일정기간동안 새로이 생산한 재화와 서비스의 가치를 시장가격으로 평가하여 합산한 지표";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "국민소득"
            }
            else if (newTitle.includes("국내 자동차 산업 동향")) {
                this.scheduleList[index].explanation = "한국수출입은행에서 발표하는 국내 자동차 산업 현황 자료로 국내를 포함한 글로벌 자동차 생산·판매량 및 향후 자동차 산업 전망 등의 내용을 포함하고 있음.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "국내 자동차 산업 동향"
            }
            else if (newTitle.includes("국고채 발행계획") && newTitle.includes("발행 실적 발표")) {
                this.scheduleList[index].explanation = "기재부에서 발표하는 월별 국고채 발행계획 및 전월 발행 실적 발표. 발행 예정인 국고채 물량에 따라 국채 공급 부담이 발생해 국채금리 상향요인으로 작용하기도 함.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "국고채 발행계획 및 발행 실적 발표"
            }
            else if (newTitle.includes("국고채 모집 방식 비경쟁인수 발행 여부 및 발행계획")) {
                this.scheduleList[index].explanation = "모집 방식 비경쟁인수란 옵션 방식 비경쟁인수에 따른 월별 발행물량 변동성 완화를 위해 일정 발행물량을 일정 발행금리로 발행하는 제도";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "국고채 모집 방식 비경쟁인수 발행 여부 및 발행계획"
            }
            else if (newTitle.includes("고용동향") && this.type.value === "EVENT_DOMESTIC_INDICATOR") {
                this.scheduleList[index].explanation = "경제활동참가율, 고용률, 실업률 등 국내 고용동향을 개괄적으로 보여주는 지표";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "고용동향"
            }
            else if (newTitle.includes("결제통화별 수출입(잠정)")) {
                this.scheduleList[index].explanation = "결제통화별 수출입통계는 한국의 수출입거래금액을 결제통화별로 구분하여 작성한 통계로서 무역정책수립등에 참고자료로 활용되고 있음.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "결제통화별 수출입"
            }  
            else if (newTitle.includes("가계신용(잠정)")) {          
                this.scheduleList[index].explanation = "가계대출 및 판매신용 등 가계신용을 개괄적으로 보여주는 지표로 최근 주담대 증가 등으로 역대 최대치를 기록하는 등 주목받고 있는 통계임.";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "가계신용"
            }
            else if (newTitle.includes("임금근로 일자리 동향")) {          
                this.scheduleList[index].explanation = "산업별 일자리 변동과 유형을 파악하기 위해 일자리 관련 정책 수립 등에 유용한 기초자료를 제공할 목적으로 분기별로 작성·공표하는 통계";
                this.scheduleList[index].country = "82"
                this.scheduleList[index].subtitle = "임금근로 일자리 동향"
            }
            // 일본
            else if (newTitle.includes("日)") && newTitle.includes("단칸 대형제조업체 업황판단지수")) {
                this.scheduleList[index].explanation = "약 1,200여개 대형 제조업체를 대상으로 실시한 설문조사를 통해 제조업 부문 경기 전반의 상대적 수준을 평가하는 지표. 지수가 0보다 큰 경우 경기가 호전중임을, 0보다 작은 경우 악화중임을 의미함";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 단칸 대형제조업체 업황판단지수"
            }
            else if (newTitle.includes("日)") && newTitle.includes("제조업 PMI 확정치")) {
                this.scheduleList[index].explanation = "제조업 부문 구매관리자의 활동 수준을 측정하는 지표. 50을 넘으면 경기 확장을, 50을 넘지 못하면 경기 위축을 의미함";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 제조업 PMI 확정치"
            }
            else if (newTitle.includes("日)") && newTitle.includes("본원통화")) {
                this.scheduleList[index].explanation = "중앙은행이 공급하는 현금통화. 중앙은행 지급준비금 계정에 예치된 금융기관 자금과 시중에서 유통되고 있는 현금을 합한 것을 말함";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 본원통화"
            }
            else if (newTitle.includes("日)") && newTitle.includes("서비스업 PMI 확정치")) {
                this.scheduleList[index].explanation = "서비스업 부문 구매관리자의 활동 수준을 측정하는 지표. 50을 넘으면 경기 확장을, 50을 넘지 못하면 경기 위축을 의미함";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 서비스업 PMI 확정치"
            }
            else if (newTitle.includes("日)") && newTitle.includes("가계지출")) {
                this.scheduleList[index].explanation = "소비자가 지출한 총 지출액의 인플레이션 조정치 변동을 측정한 지표";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 가계지출"
            }
            else if (newTitle.includes("日)") && newTitle.includes("경기동향지수(예비치)")) {
                this.scheduleList[index].explanation = "경기의 변화 방향을 파악하는 지수로, 경기국면과 전환점을 나타내는 지표로 사용됨";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 경기동향지수"
            }
            else if (newTitle.includes("日)") && newTitle.includes("외환보유액")) {
                this.scheduleList[index].explanation = "일본은행 및 국립은행에 예치된 일본 정부의 외국환 자산";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 외환보유액"
            }
            else if (newTitle.includes("日)") && newTitle.includes("경상수지(수정치)")) {
                this.scheduleList[index].explanation = "수출입 상품, 서비스 및 이자 지불 총액의 차이를 측정하는 지표";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 경상수지"
            }
            else if (newTitle.includes("日)") && newTitle.includes("경상수지")) {
                this.scheduleList[index].explanation = "수출입 상품, 서비스 및 이자 지불 총액의 차이를 측정하는 지표";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 경상수지"
            }
            else if (newTitle.includes("日)") && newTitle.includes("은행대출")) {
                this.scheduleList[index].explanation = "소비자와 기업에 제공된 은행 대출 총잔액의 변동을 측정하는 지표";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 은행대출"
            }
            else if (newTitle.includes("日)") && newTitle.includes("경기현황지수")) {
                this.scheduleList[index].explanation = "소비자에게 직접 서비스를 제공하는 이발사, 택시 운전사, 웨이터와 같은 사업군의 현재 경기 심리를 측정하는 지수. 수치가 50을 넘을 경우 낙관적인 전망을, 50을 하회하면 비관적인 전망을 의미함";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 경기현황지수"
            }
            else if (newTitle.includes("日)") && newTitle.includes("기업도산")) {
                this.scheduleList[index].explanation = "日 시장 동향 조사회사 '도쿄 상공 리서치'가 발표하는 일본 기업의 도산 건수";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 기업도산"
            }
            else if (newTitle.includes("日)") && newTitle.includes("공작기계수주(예비치)")) {
                this.scheduleList[index].explanation = "기계 공작 제조업 부문의 전체 신규 수주액 변화를 측정하는 지표. 일본의 선행경기를 나타내는 지표로 사용됨";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 공작기계수주"
            }
            else if (newTitle.includes("日)") && newTitle.includes("광의통화(M2)")) {
                this.scheduleList[index].explanation = "협의통화(M1)에 정기 예금이나 정기 적금과 같은 저축성 예금을 포함한 통화량을 의미. 중앙은행이 통화정책을 수립하고 시중의 통화량을 측정하는데 주요한 지표로 활용";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 광의통화(M2)"
            }
            else if (newTitle.includes("日)") && newTitle.includes("핵심기계류수주")) {
                this.scheduleList[index].explanation = "기계 제조업체의 수주 실적으로, 기업 설비투자의 선행지표로 사용됨.";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 핵심기계류수주"
            }
            else if (newTitle.includes("日)") && newTitle.includes("생산자물가지수(PPI)")) {
                this.scheduleList[index].explanation = "일본 생산자가 시장에 공급하는 상품 및 서비스 가격의 변동을 종합한 지수. 소비자물가지수와 함께 대표적 인플레이션 지표로 사용됨";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 생산자물가지수(PPI)"
            }
            else if (newTitle.includes("日)") && newTitle.includes("산업생산(수정치)")) {
                this.scheduleList[index].explanation = "제조업, 광산업 및 유틸리티 산업이 생산한 총산출액의 인플레이션 조정치 변동을 측정하는 지표. 일본 산업의 생산 활동 동향을 파악할 수 있는 지표로 사용되고 있음";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 산업생산"
            }
            else if (newTitle.includes("日)") && newTitle.includes("3차산업지수")) {
                this.scheduleList[index].explanation = "기업이 구매한 서비스 총액 변동을 측정하는 지표. 경제 건전성을 확인하는 지표로 사용됨";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 3차산업지수"
            }
            else if (newTitle.includes("日)") && newTitle.includes("소매판매(수정치)")) {
                this.scheduleList[index].explanation = "상품 및 서비스의 총 소매 판매량을 나타내는 지표. 휘발성이 높고 계절적으로 조정되며, 자동차 판매의 큰 변동을 제외한 수치는 소비 경향의 패턴을 잘 나타내기 때문에 중요한 지표로 사용되고 있음";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 소매판매"
            }
            else if (newTitle.includes("日)") && newTitle.includes("무역수지")) {
                this.scheduleList[index].explanation = "수출입 상품 간의 가치 차이를 측정하는 지표. 양수일 경우 수출이 더 많았음을, 음수일 경우 수입이 더 많았음을 의미";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 무역수지"
            }
            else if (newTitle.includes("日)") && newTitle.includes("공작기계수주(수정치)")) {
                this.scheduleList[index].explanation = "기계 공작 제조업 부문의 신규 수주액 변화를 나타내는 지표. 일본 선행경기를 나타내는 지표로 사용됨";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 공작기계수주"
            }
            else if (newTitle.includes("日)") && newTitle.includes("소비자물가지수(CPI)")) {
                this.scheduleList[index].explanation = "소비자가 구입하는 상품과 서비스의 가격변동을 측정하기 위한 지표로 대표적 인플레이션 지표로 활용됨";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 소비자물가지수(CPI)"
            }
            else if (newTitle.includes("日)") && newTitle.includes("서비스업 PMI 예비치")) {
                this.scheduleList[index].explanation = "서비스 부문 구매관리자들의 활동 수준을 측정하기 위한 지표. 수치가 50을 넘을 경우 서비스 부문 경기가 성장중임을, 50 미만일 경우 서비스 부문 경기 위축을 뜻함.";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 서비스업 PMI 예비치"
            }
            else if (newTitle.includes("日)") && newTitle.includes("제조업 PMI 예비치")) {
                this.scheduleList[index].explanation = "제조업 부문 구매관리자들의 활동 수준을 측정하기 위한 지표. 수치가 50을 넘을 경우 제조 부문 경기가 성장중임을, 50 미만일 경우 제조 부문 경기 위축을 뜻함.";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 제조업 PMI 예비치"
            }
            else if (newTitle.includes("日)") && newTitle.includes("경기동향지수(수정치)")) {
                this.scheduleList[index].explanation = "경기변동이 경제의 특정부문에서 어떻게 경제 전체에 파급되어 가는가 하는 과정을 파악하고자 각종 경제 통계지표의 변동을 지수화한 것. 경기동향지수가 기준선인 50보다 크면 경기는 확장국면에, 50보다 작으면 수축국면에 있는 것으로 판단";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 경기동향지수"
            }
            else if (newTitle.includes("日)") && newTitle.includes("기업서비스가격지수(CSP)")) {
                this.scheduleList[index].explanation = "기업 간의 서비스 거래 시 인플레이션 비율을 측정하는 지표. 수송과 통신 등 기업 사이에 거래하는 서비스 가격 수준을 종합적으로 표시";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 기업서비스가격지수(CSP)"
            }
            else if (newTitle.includes("日)") && newTitle.includes("일본은행(BOJ) 금융정책회의")) {
                this.scheduleList[index].explanation = "일본중앙은행의 최고 의사결정 기관인 정책위원회가 금융정책 및 업무관련 중요사항을 심의, 의결하는 회의. 단기 정책금리, 수익률 곡선 제어 정책상의 목표금리 등을 발표";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 일본은행(BOJ) 금융정책회의"
            }
            else if (newTitle.includes("日)") && newTitle.includes("일본은행(BOJ) 전망 보고서")) {
                this.scheduleList[index].explanation = "일본은행이 지배적인 경제 상황을 설명하고 일본 경제의 성장, 인플레이션 위험 및 근로 조건을 특징짓는 보고서. 물가전망, 성장률 전망치 등을 제공";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 일본은행(BOJ) 전망 보고서"
            }
            else if (newTitle.includes("日)") && newTitle.includes("도쿄지역 소비자물가지수(CPI)")) {
                this.scheduleList[index].explanation = "신선식품을 제외하고 도쿄 소비자들이 구매하는 상품 및 서비스 가격의 변동을 측정. 일본 전체 물가 추세의 선행지표 역할";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 도쿄지역 소비자물가지수(CPI)"
            }
            else if (newTitle.includes("日)") && newTitle.includes("건설수주")) {
                this.scheduleList[index].explanation = "일본의 대표적인 건설회사를 대상으로 수주액 변화를 측정하는 지표. 공공부문에서 받은 주문과 민간부문에서 받은 주문을 별도로 측정";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 건설수주"
            }
            else if (newTitle.includes("日)") && newTitle.includes("신규주택착공건수")) {
                this.scheduleList[index].explanation = "착공에 들어간 신규 주거용 건물수의 연율환산치 변동을 측정하며, 주택 부문 경기에 대한 선행지표. 신규주택 착공건수의 증가는 경제의 회복을 나타내는 신호이며 감소는 경기의 침체를 나타내는 신호로 판단할 수 있음";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 신규주택착공건수"
            }
            else if (newTitle.includes("日)") && newTitle.includes("산업생산(예비치)")) {
                this.scheduleList[index].explanation = "일정 기간 동안 이뤄진 산업생산활동의 수준을 나타내는 지표. 제조업, 광산업 및 유틸리티 산업이 생산한 총산출액의 인플레이션 조정치 변동을 측정";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 산업생산"
            }
            else if (newTitle.includes("日)") && newTitle.includes("소매판매(예비치)")) {
                this.scheduleList[index].explanation = "소비동향을 파악하기 위해 작성되는 지표로 소매 단계 총 판매액의 인플레이션 조정치 변동을 측정";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 소매판매"
            }
            else if (newTitle.includes("日)") && newTitle.includes("소비자신뢰지수")) {
                this.scheduleList[index].explanation = "약 5,000가구를 대상으로 하는 설문조사에서 수집한 자료에 근거한 지표로 소비자 심리의 척도로 활용. 소비자 지출과 밀접하게 관련되어 있으며 개인소득, 구매력, 고용, 업황과 높은 상관관계를 가짐. 50을 넘을 경우 향후 경기 전망에 낙관적, 50 미만일 경우 비관적인 것을 의미";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 소비자신뢰지수"
            }
            else if (newTitle.includes("日)") && newTitle.includes("자본지출")) {
                this.scheduleList[index].explanation = "기업 자본 투자 총액의 변동을 측정한 지표. 기업 경기 및 경제의 건실함에 대한 선행지표로 활용되며, 해당 분기와 전년도 동일 분기 사이의 변동을 측정";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 자본지출"
            }
            else if (newTitle.includes("日)") && newTitle.includes("GDP(수정치)")) {
                this.scheduleList[index].explanation = "국가경제가 생산한 모든 상품 및 서비스 가치의 인플레이션 조정치의 연율 환산 변동을 측정한 지표로 일본 내각부가 분기마다 집계해 2차례(예비치, 수정치)에 걸쳐 발표";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 GDP"
            }

            else if (newTitle.includes("日)") && newTitle.includes("일본은행(BOJ) 금융정책결정회의 의사록")) {
                this.scheduleList[index].explanation = "일본은행(BOJ) 금융정책결정회의 의사록은 일본은행이 발표하는 세부 기록으로 이자율 설정에 영향을 미친 경제 조건에 대한 분석이 이루어지며 통화 정책 방향성을 결정하는데 중요한 기준이 됨. 이에 일본은행(BOJ) 금융정책결정회의 의사록은 통화정책 결정 과정을 이해하는데 매우 중요한 자료임.";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 일본은행(BOJ) 금융정책결정회의 의사록"
            }

            else if (newTitle.includes("日)") && newTitle.includes("경기관측보고서")) {
                this.scheduleList[index].explanation = "경기관측보고서는 이발사, 택시 기사, 웨이터와 같은 직접적인 손님 서비스를 제공하는 사업체들의 현재 분위기를 측정. 약 2,000명의 근로자들로부터 수집된 데이터로, 발표치가 50.0 이상이면 긍정적인 분위기를, 그 아래면 부정적인 분위기를 나타냄. 예상보다 높은 발표치는 자국 통화에 긍정적이라고 간주되며 예상보다 낮은 발표치는 부정적이라고 간주.";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 경기관측보고서"
            }

            else if (newTitle.includes("日)") && newTitle.includes("실업률")) {
                this.scheduleList[index].explanation = "지난달에 일자리를 찾고 있었던 무직자의 비율을 나타냄. 일본 경제는 개인 소비보다 산업 분야에 더 의존하므로 다른 나라의 고용 데이터에 비해 영향력이 약함. 예상보다 높은 발표치는 자국 통화에 대해 부정적인 영향을 미치며 예상보다 낮은 발표치는 자국 통화에 긍정적인 영향을 미침.";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 실업률"
            }

            else if (newTitle.includes("日)") && newTitle.includes("일본은행(BOJ) 금융정책결정회의 요약본")) {
                this.scheduleList[index].explanation = "일본은행(BOJ) 금융정책결정회의에 대한 상세한 기록을 나타낸 요약본. 일본은행의 통화정책에 대한 상세한 내용을 파악할 수 있음";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 일본은행(BOJ) 금융정책결정회의 요약본"
            }
            else if (newTitle.includes("日)") && newTitle.includes("대형제조업체 기업경기실사지수")) {
                this.scheduleList[index].explanation = "약 1,200여개 대형 제조업체를 대상으로 실시한 설문조사를 토대로 산출한 경기 체감지수. 제조업 부문 경기 전반의 상대적 수준을 평가하며, 해당 지수가 0보다 큰 경우 경기가 호전, 0보다 작을 경우 악화중임을 의미";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 대형제조업체 기업경기실사지수"
            }
            else if (newTitle.includes("日)") && newTitle.includes("GDP(예비치)")) {
                this.scheduleList[index].explanation = "국가경제가 생산한 모든 상품 및 서비스 가치의 인플레이션 조정치의 연율 환산 변동을 측정한 지표로 일본 내각부가 분기마다 집계해 2차례(예비치, 수정치)에 걸쳐 발표";
                this.scheduleList[index].country = "81"
                this.scheduleList[index].subtitle = "일본 GDP"
            }
            // 영국
            else if (newTitle.includes("영국)") && newTitle.includes("CIPS / S&P글로벌 제조업 PMI 확정치(현지시간)")) {
                this.scheduleList[index].explanation = "제조업 부문 구매관리자의 활동 수준을 측정하기 위한 지표. 수치가 50을 넘는 경우 경기 확장을 나타내며, 50 미만은 경기 위축을 의미";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 CIPS / S&P글로벌 제조업 PMI 확정치"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("외환보유액(현지시간)")) {
                this.scheduleList[index].explanation = "중앙은행이나 정부가 국제수지 불균형을 보전하거나 외환시장 안정을 위해 언제든지 사용할 수 있도록 보유하고 있는 대외 지급준비자산";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 외환보유액"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("CIPS / S&P 글로벌 서비스업 PMI 확정치(현지시간)")) {
                this.scheduleList[index].explanation = "서비스업 부문 구매관리자의 활동 수준을 측정하기 위한 지표. 수치가 50을 넘는 경우 경기 확장을 나타내며, 50 미만은 경기 위축을 의미";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 CIPS / S&P 글로벌 서비스업 PMI 확정치"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("할리팩스 주택가격지수(현지시간)")) {
                this.scheduleList[index].explanation = "영국 최대 모기지 은행중 하나인 할리팩스 은행이 자금을 제공한 주택 및 부동산의 가격 변동을 측정한 지표. 주택 부문 건강도에 대한 선행지표로 활용";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 할리팩스 주택가격지수"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("실업률(현지시간)")) {
                this.scheduleList[index].explanation = "경제활동인구 중에서 실업자가 차지하는 비율로 영국 통계청이 매월 집계하여 발표";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 실업률"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("BOE 금융안정보고서(현지시간)")) {
                this.scheduleList[index].explanation = "금융 시스템의 상태와 금융 안정에 대한 잠재적인 위험에 대한 평가를 나타내는 보고서. 영란은행이 매년 상반기/하반기 제공";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 BOE 금융안정보고서"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("무역수지(현지시간)")) {
                this.scheduleList[index].explanation = "수출/수입 상품의 가치 차이를 측정하는 지표. 이 수치가 0보다 크면 수출 규모가 수입보다 많다는 의미로 무역수지 흑자를 나타냄";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 무역수지"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("산업생산(현지시간)")) {
                this.scheduleList[index].explanation = "제조업, 광업 및 공공사업이 생산한, 인플레이션 조정을 거친 총산출액의 변동을 측정한 지표. 실제 수치가 예상치보다 높은 경우 파운드화 가치 및 전망이 긍정적이라는 뜻이며, 낮은 경우 부정적임을 의미";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 산업생산"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("주택가격지수(현지시간)")) {
                this.scheduleList[index].explanation = "전국 주택가격의 평균가격 변동을 반영하기 위해 설계된 통계 지표. 영국에서 두번째로 큰 모기지 회사인 네이션와이드(Nationwide Housing Society)가 실시하며, 전국주택조합은 자신의 모기지 승인건수를 근거로 이 지수를 산출";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 주택가격지수"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("소비자물가지수(CPI)(현지시간)")) {
                this.scheduleList[index].explanation = "소비자가 구매하는 상품 및 서비스 가격의 변동을 측정하는 지수로 대표적 인플레이션 지표임";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 소비자물가지수(CPI)"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("생산자물가지수(PPI)(현지시간)")) {
                this.scheduleList[index].explanation = "생산자가 시장에 공급하는 상품 및 서비스 가격의 변동을 종합한 지수. 소비자물가지수와 함께 대표적 인플레이션 지표로 사용됨";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 생산자물가지수(PPI)"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("CIPS / S&P 글로벌 합성 PMI 예비치(현지시간)")) {
                this.scheduleList[index].explanation = "제조업과 서비스업의 구매관리자지수(PMI)를 합친 수치로 50을 기준으로 50보다 클 경우 경기 개선, 50 미만은 경기 위축을 나타냄";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 CIPS / S&P 글로벌 합성 PMI 예비치"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("GFK 소비자신뢰지수(현지시간)")) {
                this.scheduleList[index].explanation = "경제활동에 대한 소비자의 신뢰 수준을 측정하는 지표. 수치가 0보다 클 경우 낙관적 심리를, 0 미만일 경우 비관적 심리를 나타냄";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 GFK 소비자신뢰지수"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("공공부문 순차입/순상환(현지시간)")) {
                this.scheduleList[index].explanation = "공기업 및 중앙/지방정부 지출 및 수입의 전체 차액을 측정하는 지표";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 공공부문 순차입/순상환"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("소매판매(현지시간)")) {
                this.scheduleList[index].explanation = "소매 단계에서 인플레이션 조정을 거친 총 판매액의 변동을 측정하는 지표. 전체 경제 활동의 대부분을 차지하는 소비자 지출을 가장 잘 보여주는 지표임";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 소매판매"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("영란은행(BOE) 실질금리(현지시간)")) {
                this.scheduleList[index].explanation = "금융 기관으로부터 대부를 받은 사람이 실질적으로 부담하는 금리. 대부금에 대한 금리 이외에 정기 예금을 포함한 금리임";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 영란은행(BOE) 실질금리"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("CIPS / S&P글로벌 제조업 PMI 확정치(현지시간)")) {
                this.scheduleList[index].explanation = "제조업 부문 구매관리자들의 활동 수준을 측정하는 지표. 수치가 50을 넘을 경우 제조업 경기가 성장중임을, 50 미만일 경우 경기 위축을 의미";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 CIPS / S&P글로벌 제조업 PMI 확정치"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("영란은행(BOE) 기준금리결정(현지시간)")) {
                this.scheduleList[index].explanation = "기준금리 결정 등 영란은행(BOE)의 통화신용정책에 관한 주요 사항을 심의·의결하는 정책결정회의";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 영란은행(BOE) 기준금리결정"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("앤드류 베일리 잉글랜드은행(BOE) 총재 연설(현지시간)")) {
                this.scheduleList[index].explanation = "앤드류 베일리 잉글랜드은행 총재의 연설. 잉글랜드은행은 영국의 중앙은행으로 앤드류 베일리 총재의 발언은 시장에 유의미한 영향을 미침";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 앤드류 베일리 잉글랜드은행(BOE) 총재 연설"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("협의통화 및 준비잔고(현지시간)")) {
                this.scheduleList[index].explanation = "민간이 지닌 현금과 쉽게 현금화할 수 있는 예금인 결제성 예금으로 언제든지 바로 현금화 가능한 통화로, 단기금융시장의 유동성 수준을 파악하는 데 사용됨";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 협의통화 및 준비잔고"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("외환보유액(현지시간)")) {
                this.scheduleList[index].explanation = "중앙은행이나 정부가 국제수지 불균형을 보전하거나 외환시장 안정을 위해 언제든지 사용할 수 있도록 보유하고 있는 대외 지급준비자산";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 외환보유액"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("경상수지(현지시간)")) {
                this.scheduleList[index].explanation = "외국과 물건(재화)이나 서비스(용역)를 팔고 산 결과를 종합한 것으로 국가 간 상품 및 서비스의 수출입, 자본 노동 등 생산요소의 이동에 따른 대가의 수입과 지급을 종합적으로 나타낸 지표. 경상수지는 상품수지, 서비스수지, 소득수지, 경상이전수지 등으로 구분됨";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 경상수지"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("국내총생산(GDP) 확정치(현지시간)")) {
                this.scheduleList[index].explanation = "국가경제가 생산한 모든 상품 및 서비스 가치의 연율 환산 인플레이션 조정치 변동을 측정한 지표. 경제 활동에 대한 가장 광범위한 척도이며 경제의 건강도에 대한 중요한 지표임";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 국내총생산(GDP) 확정치"
            }
            else if (newTitle.includes("영국)") && newTitle.includes("국내총생산(GDP) 예비치(현지시간)")) {
                this.scheduleList[index].explanation = "국가경제가 생산한 모든 상품 및 서비스 가치의 연율 환산 인플레이션 조정치 변동을 측정한 지표. 경제 활동에 대한 가장 광범위한 척도이며 경제의 건강도에 대한 중요한 지표임";
                this.scheduleList[index].country = "44"
                this.scheduleList[index].subtitle = "영국 국내총생산(GDP) 예비치"
            }
            // 유로존
            else if (newTitle.includes("유로존)") && newTitle.includes("제조업 PMI 확정치(현지시간)")) {
                this.scheduleList[index].explanation = "제조업 부문 구매관리자들의 활동 수준을 나타내는 지표. 해당 수치가 50을 초과할 경우 제조업 부문 경기 개선을, 50 미만일 경우 경기 위축을 의미";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 제조업 PMI 확정치"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("크리스틴 라가르드 유럽중앙은행(ECB) 총재 연설(현지시간)")) {
                this.scheduleList[index].explanation = "크리스틴 라가르드 유럽중앙은행 총재의 연설. 유럽중앙은행 크리스틴 라가르드 총재의 발언은 시장에 유의미한 영향을 미침";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 크리스틴 라가르드 유럽중앙은행(ECB) 총재 연설"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("실업률(현지시간)")) {
                this.scheduleList[index].explanation = "실업상태에 있으며 적극적으로 구직활동을 하는 총노동력의 비율. 유로존에는 노동 상황과 관련해 더 일찍 발표되는 몇몇 지표가 있기 때문에, 해당 데이터는 다른 나라의 고용 데이터에 비해 일반적으로 영향력이 제한적임";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 실업률"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("고용률(현지시간)")) {
                this.scheduleList[index].explanation = "유로존 전체 경제에서의 취업 변동을 측정하는 지표로, Eurostat에서 발표됨";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 고용률"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("유로코인(EuroCOIN) 경제활동지수(현지시간)")) {
                this.scheduleList[index].explanation = "GDP성장률, 수출지표 등으로부터 변동주기 1년 이상의 중장기 요인을 추출해 분석대상지표를 투사시키는 방식을 사용하는 지표";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 유로코인(EuroCOIN) 경제활동지수"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("서비스업 PMI 확정치(현지시간)")) {
                this.scheduleList[index].explanation = "서비스업 부문 구매관리자들의 활동 수준으로 50은 전월 이후로 변동이 없음을, 50보다 클 경우 개선을, 50 미만은 위축을 의미";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 서비스업 PMI 확정치"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("생산자물가지수(PPI)(현지시간)")) {
                this.scheduleList[index].explanation = "생산자가 시장에 공급하는 상품 및 서비스 가격의 변동을 종합한 지수. 소비자물가지수와 함께 대표적 인플레이션 지표로 사용됨";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 생산자물가지수(PPI)"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("소매판매(현지시간)")) {
                this.scheduleList[index].explanation = "인플레이션 조정을 거친 소매 단계 총 판매액의 변동으로 전체 경제 활동의 상당 부분을 차지하는 소비자 지출을 가장 잘 나타내는 지표";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 소매판매"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("유럽중앙은행(ECB) 경제보고서(현지시간)")) {
                this.scheduleList[index].explanation = "정책결정권자들이 금리를 결정할 때 평가하는 통계 데이터를 포함하고 있으며, 중앙은행의 시각에서 현재 및 향후 경제 상황에 대한 자세한 분석을 제공";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 유럽중앙은행(ECB) 경제보고서"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("장기금리통계(현지시간)")) {
                this.scheduleList[index].explanation = "유로 지역 회원국에 대해 유로로 표시된 장기 국채의 금리와 발행 시점에 유로를 채택하지 않은 회원국에 대해 국가 통화로 표시된 금리 관련 지표";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 장기금리통계"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("국내총생산(GDP)")) {
                this.scheduleList[index].explanation = "국가 경제가 생산한 모든 상품 및 서비스를 인플레이션 조정한 액수의 연율 변동으로 경제 활동에 대한 가장 광범위한 척도이며 국가 경제의 건강도에 대한 1차 지표";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 국내총생산(GDP)"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("산업생산(현지시간)")) {
                this.scheduleList[index].explanation = "경제 산업부문 산출고의 척도로 제조업, 광업 및 공공산업을 포함한 지표. 국내총생산(GDP)에서 차지하는 부분은 크지 않으나, 금리와 소비자 수요에 매우 민감하며 미래의 GDP 및 경제실적을 예측하는 지표임";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 산업생산"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("무역수지(현지시간)")) {
                this.scheduleList[index].explanation = "당월 상품 및 서비스의 수입액 및 수출액의 차이를 측정한 지표";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 무역수지"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("소비자물가지수(CPI)확정치(현지시간)")) {
                this.scheduleList[index].explanation = "소비자가 구매하는 상품 및 서비스 가격의 변동을 측정하는 지수로 대표적 인플레이션 지표임";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 소비자물가지수(CPI)확정치"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("건설생산(현지시간)")) {
                this.scheduleList[index].explanation = "건설이 주업종인 기업이 실시한 건설공사를 포함한 지표";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 건설생산"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("경상수지(현지시간)")) {
                this.scheduleList[index].explanation = "수출, 수입된 상품/서비스 및 이자 지불액 총계 차이의 변화를 측정한 지표. 상품 부분은 월 무역수지 수치와 동일하며 유로존에서 수출하는 제품을 외국에서 유로화로 구매하므로, 해당 데이터는 유로화 가치에 상당한 영향을 줄 수 있음";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 경상수지"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("합성 PMI 예비치(현지시간)")) {
                this.scheduleList[index].explanation = "제조 및 서비스에 대한 구매관리자 지수(PMI). 민간 부문 제조업체 임원 300명 이상 및 민간 부문 서비스업체 임원 300명을 대상으로 한 설문조사에 근거하며 50 수준은 전월 이후로 변동이 없음을, 50보다 클 경우 개선을, 50 미만은 위축을 나타냄";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 합성 PMI 예비치"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("총통화(M3) 공급(현지시간)")) {
                this.scheduleList[index].explanation = "총통화(M2)에 종합금융회사·투자신탁회사 등 은행 이외 제2금융권의 각종 예수금(즉 비통화금융기관 예수금)과 금융기관이 발행하는 금융채, 양도성예금증서(CD), 상업어음매출과 환매조건부채권매도 등을 포함시킨 가장 넓은 의미의 통화지표";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 총통화(M3) 공급"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("경기체감지수(현지시간)")) {
                this.scheduleList[index].explanation = "기업 대상 월간 설문조사를 바탕으로 유로존 내에서의 주기적인 상황에 대해 평가한 지표";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 경기체감지수"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("유럽중앙은행(ECB) 통화정책회의 의사록(현지시간)")) {
                this.scheduleList[index].explanation = "유럽중앙은행(ECB) 통화정책회의에 대한 상세한 기록. 통화정책에 대한 ECB 입장을 자세히 살펴볼 수 있으며, 향후 금리 결정에 대한 단서를 얻을 수 있음";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 유럽중앙은행(ECB) 통화정책회의 의사록"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("소비자물가지수(CPI)예비치(현지시간)")) {
                this.scheduleList[index].explanation = "가구에서 일상생활을 영위하기 위해 구입하는 상품과 서비스의 가격변동을 측정하기 위하여 작성되는 지수로 대표적인 인플레이션 지표로 사용";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 소비자물가지수(CPI)예비치"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("유럽중앙은행(ECB) 기준금리결정(현지시간)")) {
                this.scheduleList[index].explanation = "기준금리 결정 등 유럽중앙은행(ECB)의 통화신용정책에 관한 주요 사항을 심의·의결하는 정책결정회의";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 유럽중앙은행(ECB) 기준금리결정"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("유럽연합(EU) 재무장관회의(현지시간)")) {
                this.scheduleList[index].explanation = "EU 회원국 재무장관들이 유럽 최근 경제현황에 대한 논의를 위해 소집되는 재무장관회의";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 유럽연합(EU) 재무장관회의"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("노동비용지수(현지시간)")) {
                this.scheduleList[index].explanation = "기업과 정부가 민간 노동에 대해 지불하는 가격의 변동을 측정하는 지표";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 노동비용지수"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("경제·재무장관 이사회(ECOFIN) 회의(현지시간)")) {
                this.scheduleList[index].explanation = "EU 회원국 재무장관들이 유럽 최근 경제현황에 대한 논의를 위해 소집되는 이사회 회의";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 경제·재무장관 이사회(ECOFIN) 회의"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("춘계 경제전망(현지시간)")) {
                this.scheduleList[index].explanation = "EU 집행위원회가 유로존의 현 경제상황에 대한 인식, 전망과 위험요인 등을 종합하여 GDP 성장률 전망치 등을 발표";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 춘계 경제전망"
            }
            else if (newTitle.includes("유로존)") && newTitle.includes("유럽중앙은행(ECB) 비통화정책회의(현지시간)")) {
                this.scheduleList[index].explanation = "통화정책 조치와 관련이 없는 법적 규제 및 유로 지역의 금융 시스템 규제 등을 논의하는 회의";
                this.scheduleList[index].country = "999"
                this.scheduleList[index].subtitle = "유로존 유럽중앙은행(ECB) 비통화정책회의"
            }
            // 독일
            else if (newTitle.includes("독일)") && newTitle.includes("실업률(현지시간)")) {
                this.scheduleList[index].explanation = "실업상태에 있으며 적극적으로 구직활동을 하는 총노동력의 비율을 측정하는 지표";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 실업률"
            }
            else if (newTitle.includes("독일)") && newTitle.includes("제조업 PMI 확정치(현지시간)")) {
                this.scheduleList[index].explanation = "제조업 부문 구매관리자의 활동 수준을 측정. 수치가 50을 넘는 경우 경기 확장을 나타내며, 50 미만은 경기 위축을 의미";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 제조업 PMI 확정치"
            }
            else if (newTitle.includes("독일)") && newTitle.includes("무역수지(현지시간)")) {
                this.scheduleList[index].explanation = "수출/수입 상품의 가치 차이를 측정하는 지표. 이 수치가 0보다 크면 수출 규모가 수입보다 많다는 의미로 무역수지 흑자를 나타냄";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 무역수지"
            }
            else if (newTitle.includes("독일)") && newTitle.includes("서비스업 PMI 확정치(현지시간)")) {
                this.scheduleList[index].explanation = "서비스 부문 구매관리자들의 활동 수준을 측정하기 위한 지표. 이 수치가 50을 넘을 경우 서비스 부문 경기가 성장중임을, 50 미만일 경우 서비스 부문 경기 위축을 뜻함";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 서비스업 PMI 확정치"
            }
            else if (newTitle.includes("독일)") && newTitle.includes("산업생산(현지시간)")) {
                this.scheduleList[index].explanation = "제조업, 광업 및 공공산업을 포함한 해당 국가 경제 산업부문 지표. 국내총생산(GDP)에서 차지하는 부분은 크지 않으나, 금리와 소비자 수요에 매우 민감하며 미래의 GDP 및 경제실적을 예측하는 도구로 활용";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 산업생산"
            }
            else if (newTitle.includes("독일)") && newTitle.includes("소비자물가지수(CPI) 확정치(현지시간)")) {
                this.scheduleList[index].explanation = "소비자가 구매하는 상품 및 서비스 가격의 변동을 측정하는 지수로 대표적 인플레이션 지표임";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 소비자물가지수(CPI)"
            }
            else if (newTitle.includes("독일)") && newTitle.includes("경상수지(현지시간)")) {
                this.scheduleList[index].explanation = "외국과 물건(재화)이나 서비스(용역)를 팔고 산 결과를 종합한 것으로 국가 간 상품 및 서비스의 수출입, 자본 노동 등 생산요소의 이동에 따른 대가의 수입과 지급을 종합적으로 나타낸 지표. 상품수지, 서비스수지, 소득수지, 경상이전수지 등으로 구분됨";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 경상수지"
            }
            else if (newTitle.includes("독일)") && newTitle.includes("ZEW 경기기대지수(현지시간)")) {
                this.scheduleList[index].explanation = "약 350여개 독일 기관 투자자 및 애널리스트를 대상으로 독일 6개월간의 경제전망을 측정해 수치화한 지수. 수치가 0보다 클 경우 낙관적인 전망을, 0보다 작으면 부정적인 전망을 의미함";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 ZEW 경기기대지수"
            }
            else if (newTitle.includes("독일)") && newTitle.includes("생산자물가지수(PPI)(현지시간)")) {
                this.scheduleList[index].explanation = "생산자가 시장에 공급하는 상품 및 서비스 가격의 변동을 종합한 지수. 소비자물가지수와 함께 대표적 인플레이션 지표로 사용됨";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 생산자물가지수(PPI)"
            }
            else if (newTitle.includes("독일)") && newTitle.includes("합성 PMI 예비치(현지시간)")) {
                this.scheduleList[index].explanation = "제조업과 서비스업의 구매관리자지수(PMI)를 합친 수치로 50을 기준으로 50보다 클 경우 경기 개선, 50 미만은 경기 위축을 나타냄";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 합성 PMI 예비치"
            }
            else if (newTitle.includes("독일)") && newTitle.includes("국내총생산(GDP) 확정치(현지시간)")) {
                this.scheduleList[index].explanation = "국가경제가 생산한 모든 상품 및 서비스 가치의 연율 환산 인플레이션 조정치 변동을 측정한 지표. 경제 활동에 대한 가장 광범위한 척도이며 경제의 건강도에 대한 중요한 지표임";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 국내총생산(GDP) 확정치"
            }
            else if (newTitle.includes("독일)") && newTitle.includes("국내총생산(GDP) 예비치(현지시간)")) {
                this.scheduleList[index].explanation = "국가경제가 생산한 모든 상품 및 서비스 가치의 연율 환산 인플레이션 조정치 변동을 측정한 지표. 경제 활동에 대한 가장 광범위한 척도이며 경제의 건강도에 대한 중요한 지표임";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 국내총생산(GDP) 예비치"
            }
            else if (newTitle.includes("독일)") && newTitle.includes("IFO 기업환경지수(현지시간)")) {
                this.scheduleList[index].explanation = "독일 기업의 현재 상황을 평가하며 다음 6개월간의 경기 예상을 측정하는 지수로, 제조업, 건설업, 도/소매 분야를 대상으로 실시한 설문조사 결과로 산정하는 종합지수임";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 IFO 기업환경지수"
            }
            else if (newTitle.includes("독일)") && newTitle.includes("GFK 소비자신뢰지수(현지시간)")) {
                this.scheduleList[index].explanation = "경제활동에 대한 소비자 신뢰 수준의 변동을 측정하는 지수로, 약 2,000명의 소비자로 하여금 과거 및 향후 경제 상황의 상대적 수준을 평가하게 하는 설문조사를 통해 산출됨";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 GFK 소비자신뢰지수"
            }
            else if (newTitle.includes("독일)") && newTitle.includes("소비자물가지수(CPI) 예비치(현지시간)")) {
                this.scheduleList[index].explanation = "소비자가 구매하는 상품 및 서비스 가격의 변동을 측정하는 지수로 대표적 인플레이션 지표";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 소비자물가지수(CPI)"
            }
            else if (newTitle.includes("독일)") && newTitle.includes("수출입물가지수(현지시간)")) {
                this.scheduleList[index].explanation = "수출 및 수입 상품의 종합적인 가격수준을 측졍하는 통계로 수출입업체의 수출채산성 변동이나 수입원가 부담 파악, 수출입물가지수의 상호 비교를 통한 교역조건 측정 등에 사용됨";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 수출입물가지수"
            }
            else if (newTitle.includes("독일)") && newTitle.includes("소매판매(현지시간)")) {
                this.scheduleList[index].explanation = "자동차와 주유소를 제외한 소매 단계에서 인플레이션 조정을 거친 총 판매액의 변동을 측정하는 지수로, 전체 경제 활동의 대부분을 차지하는 소비자 지출을 보여주는 1차적 지표임";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 소매판매"
            }
            else if (newTitle.includes("독일)") && newTitle.includes("공장수주(현지시간)")) {
                this.scheduleList[index].explanation = "내구재/비내구재를 모두 포함한 제조업 분야의 신규 구매 수주량 총액 변화를 측정하는 지수로, 생산 부문의 선행지표로서 사용됨";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 공장수주"
            }
            else if (newTitle.includes("독일)") && newTitle.includes("분데스방크 월간보고서(현지시간)")) {
                this.scheduleList[index].explanation = "독일 경제의 현 상황과 발전 가능성에 대해 기록하는 종합 보고서. 주로 경제 상황, 공공재정, 유가증권시장, 국제수지 등 4가지 중요 주제가 포함되어 있음";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 분데스방크 월간보고서"
            }
            else if (newTitle.includes("독일)") && newTitle.includes("요아힘 나겔 분데스방크 총재 연설(현지시간)")) {
                this.scheduleList[index].explanation = "요아힘 나겔 분데스방크 총재의 연설. 도이치 분데스방크는 유럽 경제의 핵심국인 독일의 중앙은행으로 나겔 총재의 발언은 시장에 유의미한 영향을 미침";
                this.scheduleList[index].country = "49"
                this.scheduleList[index].subtitle = "독일 요아힘 나겔 분데스방크 총재 연설"
            }
            // 20231010 기타 추가(어떤 국가에도 속하지않음 / 국가코드 777)
            else if (newTitle.includes("OPEC 월간 보고서(현지시간)")) {
                this.scheduleList[index].explanation = "석유수출국기구(OPEC) 월간 석유 시장 보고서는 글로벌 석유 시장에 영향을 미치는 주요 사안들을 다루며 내년 원유 시장 개발에 관한 전망을 제공함.";
                this.scheduleList[index].country = "777"
                this.scheduleList[index].subtitle = "OPEC 월간 보고서"
            }
        },
        // 22.12.13 SUYE 스케줄 형식 불러오기 
        applyForm(id){
            let params = {
                newsType: CONSTANTS.BOARD_TYPE[3].value, // BOARD_SCHEDULE_FORM
                id: id,
            }
            this.SELECT_ALL_SCHEDULE_FORM(params)
            .then((data) => {
                this.form = data.items
                if(this.form){ 
                    this.formTitle = this.form.title.split(this.cutPoint)[0]     // 형식제목 
                    this.scheduleTitle = this.form.title.split(this.cutPoint)[1] // 스케줄제목

                    this.scheduleList[0].title = this.scheduleTitle
                    this.scheduleList[0].content = this.form.content

                    const find = CONSTANTS.SCHEDULE_INDEX.find(e => e.value == this.form.flag)
                    this.type = []
                    this.type.name = find.label
                    this.type.value = find.type
                }
                // console.log('scheduleList', this.scheduleList)
                // console.log('form', this.form)
            })
            .catch((error) => {
                this.showError({ vc:this, error:error })
            })
        },
        initSchedule() {
            this.schedule = clone(this.defaultSchedule)
            this.scheduleList = []
            var s = clone(this.defaultSchedule)
            this.scheduleList.push(s)
        },
        // 카테고리 - 메모 일때 연도 넣어주기
        initYears(){
            let now = Number(moment(new Date()).format("YYYY"))
            for(var i=-2;i<19;i++){
                this.years.push(now+i)
            }
        },
        // 목록화면으로 이동하기
        goListPage() {
            this.initSchedule()
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        // 새 카드 추가하기
        addSchedule() {
            var s = clone(this.defaultSchedule)
            this.scheduleList.push(s)
        },
        // 카드 삭제
        deleteSchedule(index) {
            this.scheduleList.splice(index, 1)
        },
        finishUpload(success, fail, total) {
            Debug.log(
                'finishUpload s =' +
                    success +
                    ', fail=' +
                    fail +
                    ', total=' +
                    total
            )
            if (success + fail === total) {
                let message = this.isRegisterPage
                    ? '등록되었습니다. (총 ' +
                      total +
                      '중 ' +
                      success +
                      ' 성공)'
                    : success > fail
                    ? '수정되었습니다.'
                    : '수정에 실패하였습니다.'
                if (success > fail) {
                    this.showMessage({ vc: this, message: message })
                } else {
                    this.showError({ vc: this, error: message })
                }
                this.INIT_LIST()
                this.ASYNC_LIST()
                this.goListPage()
            }
        },
        /**
            API 통신
         */
        // 신규입력 or 수정
        goSave() {
            if (this.schedule.id === null && this.type === null) {
                this.showError({ vc: this, error: '카테고리를 선택하세요.' })
                return false
            }
            /* 
                220418 meej 날짜 validation 추가
            */
            if(this.endDate < this.eventDate){
                this.showError({ vc: this, error: "시작일자보다 이전일을 선택할 수 없습니다." })
                 return false
            }

            // 리스트 숫자만크
            this.showAlert({
                vc: this,
                message: this.isRegisterPage
                    ? '등록하시겠습니까?'
                    : '수정하시겠습니까?',
            }).then(() => {
                this.doWrite()
            })
        },
        doWrite() {
            let defaultParams = {}
            if (this.schedule.id !== null) {
                /* 
                    220425 meej 이벤트일자 검색 추가로 인한 수정 this.schedule.id -> this.schedule.pid
                 */
                defaultParams.id = this.schedule.pid
            }
            defaultParams.newsType = this.type.value
            Debug.log('defaultParams ', defaultParams)

            defaultParams.startDate = this.eventDate
            defaultParams.endDate = isEmpty(this.endDate)
                ? this.eventDate
                : this.endDate
            
            defaultParams.sequence = this.schedule.sequence ? 1 : 0
            let success = 0,
                fail = 0,
                total = this.scheduleList.length

            for (let i = 0; i < this.scheduleList.length; i++) {
                let schedule = this.scheduleList[i]
            Debug.log('!!!!!!! schedule = ', schedule)
                // 22.07.08 suye 제목&내용 둘다 필수값 -> 미입력시 등록 불가
                if (isEmpty(schedule.title) || isEmpty(schedule.content)) {
                    total--
                    this.showError({ vc: this, error: '제목과 내용은 필수 입력값입니다.' })
                    continue
                }
                let params = clone(defaultParams)
                params.title = schedule.title
                params.content = schedule.content
                params.zindex = schedule.zindex

                params.yearEvent = schedule.yearEvent ? 1 : 0
                params.notSend = schedule.notSend ? 1 : 0
                params.checkPoint = schedule.checkPoint ? 1 : 0
                params.note = schedule.note
                // 20230925
                params.country = schedule.country
                params.explanation = schedule.explanation
                // 20231220
                params.subtitle = schedule.subtitle
                params.themes = ''
                Debug.log('schedule.themes  = ', schedule.themes)
                for (let t_i = 0; t_i < schedule.themes.length; t_i++) {
                    params.themes +=
                        schedule.themes[t_i].code +
                        ':' +
                        schedule.themes[t_i].name +
                        ','
                }
                params.stocks = ''
                for (let s_i = 0; s_i < schedule.stocks.length; s_i++) {
                    Debug.log('schedule.stocks map = ', schedule.stocks[s_i])
                    params.stocks +=
                        schedule.stocks[s_i].code +
                        ':' +
                        schedule.stocks[s_i].name +
                        ','
                }
                Debug.log('params.stocks = ', params.stocks)

                this.WRITE_ITEMS(params)
                    .then((response) => {
                        Debug.log('response = ', response)
                        if (response.success) {
                            success++
                        } else {
                            fail++
                        }
                        this.finishUpload(success, fail, total)
                    })
                    .catch(() => {
                        this.finishUpload(success, fail++, total)
                    })
            }
        },
        deleteItem() {
            this.showAlert({ vc: this, message: '삭제하시겠습니까?' }).then(
                () => {
                    this.doDelete()
                }
            )
        },
        doDelete() {
            var params = {}
            /* 
                220425 meej 이벤트일자 검색 추가로 인한 수정 this.schedule.id -> this.schedule.pid
            */
            params.id = this.schedule.pid 
            params.newsType = this.schedule.newsType1
            Debug.log('deleteItem = ', this.schedule)
            this.DELETE_ITEMS(params)
                .then(() => {
                    this.showMessage({ vc: this, message: '삭제되었습니다.' })
                    this.INIT_LIST()
                     this.ASYNC_LIST()
                    this.goListPage()
                })
                .catch((err) => {
                    var error = err.message
                    this.showError({ vc: this, error: error })
                })
        },
        getStock(stock, extra) {
            Debug.log('getStock = ', stock, ', index = ', extra.sindex)
            let schedule = this.scheduleList[extra.sindex]
            schedule.stocks.push(stock)
            this.$set(this.scheduleList, extra.sindex, schedule)
        },
        getTheme(theme, extra) {
            let schedule = this.scheduleList[extra.sindex]
            // 221028 meej 테마 중복일경우 추가 안되도록 return
            if(schedule.themes.includes(theme)) return
            schedule.themes.push(theme)
            this.$set(this.scheduleList, extra.sindex, schedule)
        },
        /**
        Resource handler
         */
        requestResource() {
            if (this.isListPage) {
                this.setPageStatus(this.PAGE_STATUS.REGISTER)
            } else if (this.isDetailPage) {
                this.setPageStatus(this.PAGE_STATUS.EDIT)
            }
            Debug.log('requestResource = ', this.eventDate)
            this.$emit('event-input-date', this.eventDate)
            // if (this.resRequest === null ||
            //     this.resRequest.menu !== v ||
            //     force
            // ) {
            //     let req = { menu: v, index: index }
            //     if (v === 'Event') {
            //         req.eventDate = this.eventDate
            //         req.newsType = this.eventNewsOptions[0]
            //     }
            //     this.$nextTick(function() {
            //         this.REQUEST_RESOURCE(req)
            //     })
            // }
        },
        addResource: function(res) {
            Debug.log('addResource  = ', res)
            let schedule = this.scheduleList[res.index]
            if (res.menu === 'Theme') {
                const count = res.items.length
                for (let i = 0; i < count; i++) {
                    schedule.themes.push(res.items[i])
                }
                this.$set(this.scheduleList, res.index, schedule)
            } else if (res.menu === 'Stock') {
                const count = res.items.length
                for (let i = 0; i < count; i++) {
                    schedule.stocks.push(res.items[i])
                }
                this.$set(this.scheduleList, res.index, schedule)
            } else if (res.menu === 'Event') {
                this.lastIndex = res.zindex
                Debug.log('lastIndex = ', this.lastIndex)
                for (let i = 0; i < this.scheduleList.lenth; i++) {
                    let sch = this.scheduleList[i]
                    sch.zindex = '' + this.lastIndex + i
                    this.$set(this.scheduleList, i, sch)
                }
            }
        },
        removeTheme: function(index, t_index) {
            let schedule = this.scheduleList[index]
            schedule.themes.splice(t_index, 1)
            this.$set(this.scheduleList, index, schedule)
        },
        removeStock(index, s_index) {
            let schedule = this.scheduleList[index]
            schedule.stocks.splice(s_index, 1)
            this.$set(this.scheduleList, index, schedule)
        },
        // 입력 내용 바이트 계산하기
        typingCount(index) {
            var text = this.scheduleList[index].content

            // if (cardwrap == 'origin') {

            // } else if (cardwrap == 'new') {
            //     text = this.newCardList[index].content
            // }

            var byteSize = (byteSize = getByteSize(text))

            var message

            // 넘어가는 글자는 자른다.
            if (byteSize > this.MAX_BYTE) {
                message = this.MAX_BYTE + '자를 초과 입력할 수 없습니다.'
                this.showMessage({ vc: this, message: message })

                text = getMaxBytesText(text, this.MAX_BYTE)
                byteSize = getByteSize(text)
                this.scheduleList[index].content = text
                // if (cardwrap == 'origin') {

                // } else if (cardwrap == 'new') {
                //     this.newCardList[index].content = text
                // }
            }
            this.scheduleList[index]['textBytes'] = byteSize

            // if (cardwrap == 'origin') {
            //     this.scheduleList[index]['textBytes'] = byteSize
            // } else if(cardwrap == 'new') {
            //     this.newCardList[index]['textBytes'] = byteSize
            // }
        },
        getLastAnnounce(index) {
            if (
                this.type !== null &&
                this.scheduleList.length > 1 &&
                this.type.value !== 'EVENT_DOMESTIC_ANNOUNCE'
            ) {
                this.showError({
                    vc: this,
                    error: '실적발표 카테고리가 아닙니다.',
                })
                return
            }
            let schedule = this.scheduleList[index]
            if (schedule.stocks === undefined || schedule.stocks.length === 0) {
                this.showError({ vc: this, error: '종목을 선택해주세요 ' })
                return
            }
            if (schedule.stocks.length > 1) {
                this.showError({
                    vc: this,
                    error: '종목을 하나만 선택해주세요 ',
                })
                return
            }
            let params = { stockCode: schedule.stocks[0].code }
            this.GET_LAST_ANNOUNCE(params)
                .then((response) => {
                    Debug.log('response = ', response)
                    /* 
                        220404 meej 실적불러오기 조건 삭제 : if(this.type==null)
                        220502 meej 종목명 넣어주기 추가(제목, 내용)
                    */
                    this.type = this.eventNewsOptions.find(
                        (type) => type.value === response.newsType1
                    )
                    schedule.title = schedule.stocks[0].name + " 실적발표"
                    schedule.content = "- " + schedule.stocks[0].name +" "+ response.content
                    schedule.themes = response.themes
                    this.$set(this.scheduleList, index, schedule)
                    
                })
                .catch((err) => {
                    /* 
                        220412 meej 에러 메시지 수정, 관련종목 reset 추가
                    */
                    this.showError({ vc: this, error: schedule.stocks[0].name+"("+schedule.stocks[0].code+") 의 "+ err })
                    schedule.stocks = []
                })
        },
    },
    watch: {
        selectItem: function(val) {
            this.schedule = clone(val)
            this.schedule.createTime = FormatDate(val.createTime)
            this.schedule.lastUpdateTime = FormatDate(val.lastUpdateTime)
            
            this.eventDate = this.schedule.eventDate
            this.schedule.endDate = isEmpty(val.endDate)
                ? val.eventDate
                : val.endDate
            this.endDate = this.schedule.endDate  
            
            /* 
                220422 meej memo 일땐 year, month 따로 넣어줌
            */
            if(val.memoYear !== undefined){
                this.year = val.memoYear
            }
            if(val.memoMonth !== undefined){
                this.month = val.memoMonth
            }

            this.schedule.sequence = val.sequence === 2 ? true : false
            this.schedule.yearEvent = val.yearEvent === 1 ? true : false
            this.schedule.checkPoint = val.checkPoint === 1 ? true : false
            this.schedule.notSend = val.notSend === 1 ? true : false
            // 20230925
            this.schedule.country = val.newsType7
            this.schedule.subtitle = val.subtitle
            this.schedule.explanation = val.newsType8
            this.type = this.eventNewsOptions.find(
                (type) => type.value === val.newsType1
            )
            console.log('this.schedule.stocks')
            console.log(this.schedule.stocks)
            this.schedule.stocks.forEach((row) => {
                row.value0 = row.code
                row.name0 = row.name
            })
            this.scheduleList = []
            this.scheduleList.push(this.schedule)
            //this.requestResource(0, 'Event', true)
            Debug.log('watch schedule --------------', this.schedule)
        },
        eventDate: function(date) {
            //this.$emit('event-input-date', date)
            /* 
                220404 meej 조건 수정 : isEditPage -> isDetailPage
                220418 meej 날짜 크기 비교 추가
            */
            if(date > this.schedule.endDate){
                this.endDate = date
            }
            if (!this.isDetailPage) {
                Debug.log(
                    'watch eventDate --------------',
                    date,
                    ', endDate = ',
                    this.schedule.endDate
                )
                this.endDate = date
            }
            if (this.isRegisterPage) {
                Debug.log(
                    'watch this.isRegisterPage eventDate --------------',
                    date,
                    ', endDate = ',
                    this.schedule.endDate
                )

                this.requestResource(0, 'Event', true)
            }
        },
        /* 
            220422 meej memo일때 year, month -> eventDate 에 넣어주기
        */
        year: function(){
            let date = this.year+this.month
            this.eventDate = date
        },
        month: function(){
            let date = this.year+this.month
            this.eventDate = date
        },
        endDate: function(date) {
            /* 
                220418 meej 날짜 크기 비교 추가
            */
            if(date < this.eventDate){
                this.showError({ vc: this, error: "시작일자보다 이전일을 선택할 수 없습니다." })
            }
        },
        type: function(val) {
            /* 
                220422 메모 선택시 
            */
            if(val.value === "EVENT_MEMO"){
                if(this.years.length == 0){
                    // Years 옵션 넣어주기
                    this.initYears() 
                }

                let date = this.year+this.month
                this.eventDate = date
                this.isMemoType = true
            }else{
                // 메모에서 type변경시 eventDate 현재날짜로 set
                if(this.eventDate.length < 8){ 
                    let now = moment(new Date()).format("YYYYMMDD").toString()
                    this.eventDate = now
                    this.endDate = this.eventDate
                }
                this.isMemoType = false
            }
        }
    },
}
</script>
