<template>
    <article class="bg-grey-4 i-wrap">
        <div class="i-top-wrap">
        <div class="row q-my-xs">
          <div v-if="updateTime != null" class="q-pt-sm q-pr-sm"> 
            [ 등락률 적용 시간 : 
            {{ updateTime.substring(0, 2)+":"+updateTime.substring(2, 4)+":"+ updateTime.substring(4, 6)}} 
            ] 
          </div>
          <q-space/>
          <div> <i-btn label="등락률 업데이트" @click="updateRate()" /></div>
        </div>
            <div class="row q-my-xs">
                <div class="col-12">
                    <i-input label="검색" v-model="filter" ref="search" />
                </div>
            </div>
            <div
                :disabled="selected.length == 0"
                class="col-12 q-my-xs"
            >
                <i-btn label="적용" @click="apply()" class="full-width" />
            </div>
        </div>
        <div>
            <i-simple-table
                ref="tableRef"
                :columns="themeColums"
                :list="filterList"
                @row-click="handleClickedRow"
                @check-event="handleCheckList"
            />
        </div>
    </article>
</template>
<script>
import Input from '@/components/common/Input'
import SimpleTable from '@/components/common/SimpleTable'
import Button from '@/components/common/Button'
import { mapState, mapMutations, mapActions } from 'vuex'
import debug from '@/debug'
import { nowTime } from '@/util'
export default {
    name: 'ThemeRateTable',
    components: {
        'i-input': Input,
        'i-btn': Button,
        'i-simple-table': SimpleTable,
    },
    props:{
        initMenu:String
    },
    data() {
        return {
            filter: '',
            selected: [],
            themeColums: [
                {
                    name: 'name',
                    label: '테마명',
                    align: 'left',
                    field: (row) => row.name,
                    sortable : true,
                },
                {
                    name: 'rate',
                    label: '등락률',
                    align: 'left',
                    // 221031 meej 소수점 2자리까지 표시
                    field: (row) => row.rate > 0 ? "+"+(row.rate/100).toFixed(2) + '%':(row.rate/100).toFixed(2) + '%',
                    style: (val) =>
                        val.rate > 0 ? 'color: red;' : val.rate < 0 ? 'color: blue;':'color: black;',
                    sortable : true,
                    sort: (a, b) => parseFloat(a, 10) - parseFloat(b, 10),
                },
            ],
            resourceList: [],
            filterList: [],
            updateTime : null,
        }
    },
    created() {
        this.INIT_LIST()
        this.resourceList = []
        this.filterList = []
    },
    mounted() {
      this.getList()
    },
    computed: {
        ...mapState({
            themeRateList: (state) => state.theme.themeRateList,
            list: (state) => state.resource.resourceList,
            request: (state) => state.resource.request,
        }),
        
    },
    methods: {
        ...mapMutations('resource', [
            'INIT_CHECK',
            'INIT_LIST',
        ]),
        ...mapActions('resource', [
            'GET_THEME_RESOURCE_LIST',
        ]),
        ...mapActions({
            GET_THEME_RATE_LIST: 'theme/GET_THEME_RATE_LIST',
            GET_THEME_UPDATE_RATE_LIST: 'theme/GET_THEME_UPDATE_RATE_LIST',
            showError: 'alert/showError',
        }),
        // 221028 meej 테마 등락률 목록 조회
        getList() {
            this.updateTime = null
            const self = this
            const params = { type: 'PUBLIC' }
            this.GET_THEME_RATE_LIST(params)
            .then(() => {
                self.themeRateList.forEach(function(item) {
                    self.resourceList.push({
                        name: item.name,
                        rate: item.rate,
                        checked: false,
                    })
                })
                self.filterList = self.resourceList
                if(self.themeRateList != null && self.themeRateList.length > 0){
                  this.updateTime = self.themeRateList[0].lastUpdateTime
                }
            })
        },
        // 221028 meej 종목시세 업데이트 후 테마 등락률 조회
        getUpdateList() {
            this.updateTime = null
            const self = this
            const params = { type: 'PUBLIC' }
            this.GET_THEME_UPDATE_RATE_LIST(params)
            .then(() => {
                self.themeRateList.forEach(function(item) {
                    self.resourceList.push({
                        name: item.name,
                        rate: item.rate,
                        checked: false,
                    })
                })
                self.filterList = self.resourceList
                if(self.themeRateList != null && self.themeRateList.length > 0){
                  this.updateTime = self.themeRateList[0].lastUpdateTime
                }
            })
        },
        apply() {
            this.filter = ''
            let resource = {}
            if (this.request) {
                resource = this.request
            }
            resource.items = this.selected

            this.$parent.$emit('sendResource', resource)
            this.$refs.tableRef.parentClearCheck()
            this.selected = []
            this.$refs.search.$el.focus() // 22.09.06 suye  
        },
        handleClickedRow(item) { 
            debug.log('handleClickedRow = ', this)
            this.selected.push(item)
            this.apply()
            this.$refs.search.$el.focus() // 22.09.06 suye  
        },
        handleCheckList(list) {
          this.selected = list
        },
        updateRate(){
          // 221028 meej 장마감 이후 이거나 현재시간과 차이가 2분이내이면 리스트 재조회
          if(Number(this.updateTime) > 153000 ||
              Number(this.updateTime)+200 > Number(nowTime())){
                this.INIT_LIST()
                this.resourceList = []
                this.filterList = []
                this.getList()
          }else{
              this.INIT_LIST()
              this.resourceList = []
              this.filterList = []
              this.getUpdateList()
          }
        }
    },
    watch: {
        filter: function(val) {
            this.filterList = this.resourceList.filter(
                (v) => v.name.includes(val)
            )
        },
        resourceList: function(list) {
            debug.log('watch resourceList size = ', list.length)
            this.filterList = list
            console.log(this.filterList)
        },
    },
}
</script>
