<template>
  <!-- 홈페이지 조회수 통계 페이지 -->
  <div>
    <!-- select box -->
    <div>
      <q-select v-model="period" :options="options" label="조회 기간 선택" filled emit-value outlined map-options style="padding-top: 1%; width:15%; padding-left: 2%;" />
    </div>
    <!-- chart -->
    <div v-if="chart && chart.length > 0">
      <div style="font-size:x-small; color:gray; text-align: left; margin-left: 2%;">*선택한 조회 기간보다 데이터가 적을 경우 존재하는 최대 일수까지 조회됩니다. </div>
      <p style="font-size: medium; font-weight: bold; margin-top: -1%;">홈페이지 조회수</p>
      <D3LineChart class="d3chartAlign" :config="lineChartConfig" :datum="chart" />
    </div>
    <!-- table -->
    <div v-if="chart && chart.length > 0" class="q-pa-md" style="width: 35%; float: right; padding-right: 10%; padding-left: 2%;">
      <q-markup-table :separator="separator" flat bordered dense>
        <thead>
          <tr>
            <th class="text-center">날짜</th>
            <th class="text-center">조회수</th>
          </tr>
        </thead>
        <tbody v-for="item in chart" :key="item.date">
          <tr>
            <td class="text-center">{{ hitsDateFormat(item.date) }}</td>
            <td class="text-center" :class="{ hitHighColor : Number(item.hits) > 10000 }">{{ item.hits }}</td>
          </tr>
        </tbody>
      </q-markup-table>
    </div>
  </div>
</template>

<script>
import { D3LineChart } from 'vue-d3-charts'
import api from '../../services/InfostockService'

export default {
  data() {
    return {
      options: [ // 기간 선택옵션
        {label:'1주', value:7}, 
        {label:'2주', value:14}, 
        {label:'1달', value:31}, 
        {label:'6개월', value:182}, 
        {label:'1년', value:365} 
      ],
      period: '', // 기간 선택값
      chart: [],
      lineChartConfig: {
        values: ['hits',],
        date:{
            key:'date',
            inputFormat: '%Y%m%d', 
            outputFormat: '%Y-%m-%d',
        },
        color: {
            scheme: 'schemeTableau10', // schemeSet1 schemePaired schemeTableau10 schemeDark2 schemeAccent 
            current: 'green', 
            default: 'grey',
        },
        points: { // false 
            visibleSize: 3,
            hoverSize: 6,
        },
        axis: {
            yTitle: false,
            xTitle: false,
            yFormat: ".0f",
            xFormat: "%Y%m%d",
            yTicks: 30,
            xTicks: 7,
        },
        curve: "curveMonotoneX", // curveLinear curveMonotoneX curveCatmullRom 
        margin: {
            top: 20,
            right: 20,
            bottom: 20,
            left: 40
        },
        transition: { ease: "easeSinInOut" }, // easeBounceOut easeQuadInOut easeBackInOut easeSinInOut
      },
    };
  },
  components:{
    D3LineChart,
  },
  computed: {
  },
  mounted() {
  },
  watch:{
    period(val ,old){
      console.log('selected period', this.period)
      if(val && val !== '' && val !== old)
        this.chart = []
        this.getHitsMainGraph()
      },
  },
  methods: {
    // 조회수 불러오기 
    async getHitsMainGraph() {
        const params = {
          pageName: 'infostock_main',
          limit: Number(this.period),
        }
        await api.getHitsMainWlimit(params).then((res) => {
          if(res && res.data) {
            res.data.items.forEach(e => {
              this.chart.push({hits: e.hits, date: e.showDate})
            });
          }
        }).catch((err) => {
          console.log('getHitsMain err', err)
        })
      },
      // 조회 테이블 날짜 포맷
      hitsDateFormat(date){
        if(date){
          return date.substring(0,4) + '-' + date.substring(4,6) + '-' + date.substring(6,8)
        } else {
          return '-'
        }
      },
  },
};
</script>
 
<style>

.hitHighColor {
  color: #1976d2;
  font-weight: bold;
}

/* PC SCREEN CONTROL */
@media screen and (min-width: 1000px) { 
  .d3chartAlign {
      float: left;
      width: 65%;
      padding-left: 1%;
  }
}
/* MOBILE SCREEN CONTROL */
@media screen and (max-width:1000px) {

}

</style>