<template>
    <!-- 
        220413 meej max-height:742px 추가
    -->
    <q-table
        ref="table"
        dense
        :class="{ 'no-data': list.length == 0 }"
        row-key="index"
        :columns="columns"
        :data="items"
        :selection="selection"
        :selected="selected"
        :rows-per-page-options="[0]"
        hide-bottom
        virtual-scroll
        style="max-height:742px"
    >
        <template v-slot:top>
            <i-select
                v-if="typeOptions"
                :inputLabel="typeName"
                v-model="type"
                :options="typeOptions"
                option-label="name0"
                option-value="value0"
                @input="selectType"
            />
            <q-space />
            <div v-if="!ordering">
                <i-btn label="순서변경" @click="startOrder" dense />
            </div>
            <div v-else>
                <i-btn
                    v-if="!onlyOrder"
                    label="취소"
                    @click="cancelOrder"
                    dense
                />
                <i-btn class="q-ml-xs" label="저장" @click="doneOrder" dense />
                <q-btn
                    flat
                    color="dark"
                    icon="arrow_upward"
                    @click="moveUp()"
                />
                <q-btn
                    flat
                    color="dark"
                    icon="arrow_downward"
                    @click="moveDown()"
                />
            </div>
            <div v-if="multiCheck && !noDelete">
                <i-btn label="선택삭제" @click="deleteItems" dense />
            </div>
        </template>
        <template v-slot:header="props">
            <q-tr :props="props">
                <q-th class="text-left">
                        <q-checkbox
                            v-if="multiCheck && !noDelete"
                            v-model="allCheck"
                            size="xs"
                            @input="checkAll"
                        />
                </q-th>
                <!-- 220630 meej 줄 번호 추가 -->
                <q-th class="text-left">
                    <span>번호</span>
                </q-th>
                <q-th v-for="col in props.cols" :key="col.name" :props="props">
                    {{ col.label }}
                </q-th>
            </q-tr>
        </template>
        <template v-if="customCheck" v-slot:body="props"> 
            <q-tr :props="props">
                <q-td class="text-left">
                    <q-checkbox
                        v-if="ordering"
                        v-model="props.row.CHECK"
                        @input="check(props.row)"
                        size="xs"
                    />
                </q-td>
                <!-- 220630 meej 줄 번호 추가 -->
                <q-td class="text-left">
                     <span>{{props.row.idx}}</span>
                </q-td>
                <q-td
                    v-for="col in props.cols"
                    class="text-red"
                    :key="col.name"
                    :props="props"
                    @click="getItem(props.row)"
                >
                    {{ col.value }}
                </q-td>
            </q-tr>
        </template>
        <!-- 
            220413 meej checkSingle -> check로 변경 / ordering이 아닐경우 checkBox hidden처리" 
            220428 meej singleCheck 여부를 받아서 단일선택과 다중선택 중 선택가능하게 변경
        -->
       <template v-else v-slot:body="props">
            <q-tr :props="props" :class="setBgColor(props)">
                <q-td class="text-left">
                    <q-checkbox
                        :class ="{'chkHidden':!ordering}"
                        v-model="props.row.CHECK"
                        @input="singleCheck? checkSingle(props.row): check(props.row)"
                        size="xs"
                    />
                </q-td>
                <!-- 220630 meej 줄 번호 추가 -->
                <q-td class="text-left">
                     <span>{{props.row.idx}}</span>
                </q-td>
                <q-td
                    v-for="col in props.cols"
                    :class="setColor(col.name, col.value, col.CHECK)"
                    :key="col.name"
                    :props="props"
                    @click="getItem(props.row)"
                >
                    {{ col.value }}
                </q-td>
            </q-tr>
        </template> 
    </q-table>
</template>
<script>
import Button from '@/components/common/Button'
import { clone } from '@/util'
import Select from '@/components/common/Select'
import CONSTANTS from '@/constants'
import debug from '@/debug'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
    name: 'Table',
    components: {
        'i-btn': Button,
        'i-select': Select,
    },
    props: {
        columns: {
            type: Array,
        },
        list: {
            type: Array,
        },
        typeOptions: {
            type: Array,
            default: null,
        },
        typeName: {
            type: String,
            default: '구분',
        },
        select: {
            type: Boolean,
            default: true,
        },
        multiCheck: {
            type: Boolean,
            default: false,
        },
        onlyOrder: {
            type: Boolean,
            default: false,
        },
        noDelete: {
            type: Boolean,
            default: false,
        },
        customCheck: {
            type: Boolean,
            default: false,
        },
        singleCheck: {
            type: Boolean,
            default: false,
        },
        isScheduleOrder: {
            type: Boolean,
            default: false,
        },
        // isInit: {
        //     type: Boolean,
        //     default: false
        // },
        // parentSelected: {
        //     type: Array,
        //     default: []
        // }
    },
    data() {
        return {
            selected: [],
            ordering: false,
            items: [],
            selection: 'none',
            type: null,
            allCheck: false,
            checked: [],
        }
    },
    created() {
        this.items = clone(this.list)

        /* 
            220502 meej type 값 오류로 인해 state에 저장해서 사용처리
        */
        this.type = this.orderTbType
        // if (this.typeOptions !== null) {
        //     this.type = this.typeOptions[0]
        // }

        if (this.multiCheck) {
            this.selection = 'multiple'
        }
        if (this.onlyOrder) {
            this.startOrder()
        }
    },
    mounted() {
        // console.log(this.checked.length)
        // console.log(this.items.length)
    },
    computed: {
        /* 
            220502 meej type 값 오류로 인해 state에 저장해서 사용처리
         */
        ...mapState({ 
            orderTbType : (state) => state.resource.orderTbType
        }),
    },
    methods: {
        ...mapActions('alert', ['showMessage']),
        /* 
            220502 meej type 값 오류로 인해 state에 저장해서 사용처리
         */
        ...mapMutations({
            SET_ORDERTBTYPE : 'resource/SET_ORDERTBTYPE',
        }),
        // 220901 meej 테마종목 : 배경색 설정_준비(파랑), 편출(회색)
        setBgColor(val){
            if (val.row.label === '준비') {
                return 'bg-blue-2'
            } else if (val.row.label === '편출'){
                return 'bg-grey-4'
            }
            return ''
        },
        setColor(name, value, check) {
            let bg = check ? 'bg-gray ' : ''
            if (name === 'changeRate') {
                if (value.startsWith('-') && value.length > 1)
                    return bg + 'text-blue'
                else if (value === '0.00%' || value === '-')
                    return bg + 'text-black'
                return bg + 'text-red'
            } else if (name === 'tradingMarket') {
                return bg + (value === '코스피' ? 'text-blue' : 'text-red')
            } else if (name === 'type') {
                return bg + (value === '오전장' ? 'text-blue' : 'text-red')
            }
            return bg + 'text-black'
        },
        getItem(row) {
            debug.log('order table row click ', row)
            /* 
                220511 특징주, 특징테마 : 순서변경리스트일때 상세 내용 안나오는 오류 수정
            */
            // 22.07.11 suye 순서변경시에도 상세보기 
            // if (this.ordering && 
            //         (row.newsType1 == "STOCK_PM_FEATURED" 
            //             || row.newsType1 == "STOCK_AM_FEATURED" 
            //             || row.newsType1 == "THEME_FEATURED_PM" 
            //             || row.newsType1 == "THEME_FEATURED_AM" )
            //     || !this.ordering) {
                this.$emit('row-event', row)
            // } 
        },
        initSelected() {
            this.selected = []
            this.$emit('event-check-row', this.selected)
        },
        /* 
            220413 meej checked 초기화 추가
        */
        initChecked() {
            this.checked = []
            this.$emit('event-check-row', this.checked)
        },
        startOrder() {
            this.checkAll(false)
            this.ordering = true
            this.selection = 'single'
            this.initSelected()
            this.initChecked()
        },
        endOrder() {
            this.ordering = false
            if (this.multiCheck) {
                this.selection = 'multiple'
            } else {
                this.selection = 'none'
            }
            this.initSelected()
            this.initChecked()
        },
        cancelOrder() {
            // 기존 순서로 번경
            debug.log('cancel = ', this.list)
            this.items = clone(this.list)
            this.endOrder()
        },
        doneOrder() {
            this.$emit('order-event', this.items)
            this.endOrder()
        },
        swap(array, idx1, idx2) {
            const temp1 = this.items[idx1]
            const temp2 = this.items[idx2]
            this.$set(this.items, idx1, temp2)
            this.$set(this.items, idx2, temp1)
        },
        moveUp() {
            /* 
                220413 meej 순서 변경 시 여러개 이동 가능
            */
            // if (this.selected.length != 0) {
            //     let index = this.items.indexOf(this.selected[0])
            //     if (index === 0) return
            //     this.swap(this.items, index, index - 1)
            // }
            let arr = []
            for(var i=0;i<this.checked.length;i++){
                arr.push(parseInt(this.items.indexOf(this.checked[i])))
            }
            arr.sort(function(a,b){
                return a-b;
            })
            if(arr[0] === 0){
                this.showMessage({ vc:this, message:"더 이상 이동할 수 없습니다." })
                return false
            }
            for(var j=0;j<arr.length;j++){
                this.swap(this.items, arr[j], arr[j]-1)
            }
            // 220922 meej 스크롤 따라서 위로 이동
            this.$nextTick(function () {
                this.$refs.table.scrollTo(arr[0]-2);
            });
        },
        moveDown() {
            /* 
                220413 meej 순서 변경 시 여러개 이동 가능
            */
            // debug.log(this.items)
            // debug.log(this.selected)
            // if (this.selected.length != 0) {
            //     let index = this.items.indexOf(this.selected[0])
            //     if (index === this.items.length - 1) return
            //     this.swap(this.items, index, index + 1)
            // }
            let arr = []
            for(var i=0;i<this.checked.length;i++){
                arr.push(parseInt(this.items.indexOf(this.checked[i])))
            }
            arr.sort(function(a,b){
                return a-b;
            })
            if(arr[arr.length-1] === this.items.length-1) {
                this.showMessage({ vc:this, message:"더 이상 이동할 수 없습니다." })  
                return false
            }
            for(var j = arr.length-1;j>=0;j--){
                this.swap(this.items, arr[j], arr[j]+1)
            }

            // 220922 meej 스크롤 따라서 아래로 이동
            this.$nextTick(function () {
                this.$refs.table.scrollTo(arr[arr.length-1]+2);
            });
        },
        /* 
            220413 meej 순서 변경시에도 check 사용, 주석처리
            220428 meej 순서 변경시 단일, 다중 선택가능
        */
        checkSingle(item) {
            // debug.log('checkSingle ,', item)
            // this.selected = []
            // this.selected.push(item)

            // this.items.forEach((i) => {
            //     if (i.id !== item.id) i.CHECK = false
            // })
            // debug.log('checkSingle 222,', item)

            this.checked = []
            this.checked.push(item)

            this.items.forEach((i) => {
                if (i.index !== item.index) {
                    i.CHECK = false
                }
            })
        },
        selectType(val) {
            this.$emit('select-event', val)
        /* 
            220502 meej type 값 오류로 인해 state에 저장해서 사용처리
         */
            this.SET_ORDERTBTYPE(val)
        },
        deleteItems() {
            this.$emit('delete-event', this.selected)
        },
        check(item) {
            if (item.CHECK) {
                this.checked.push(item)
            } else {
                var index = this.checked.indexOf(item)
                if (index !== -1) {
                    this.checked.splice(index, 1)
                }
            }
            console.log('checked', this.checked)
            this.$emit('event-check-row', this.checked)
        },
        checkAll(val) {
            this.checked = []
            if (val) {
                for (var i = 0; i < this.items.length; i++) {
                    this.items[i].CHECK = val
                    this.checked.push(this.items[i])
                }
            } else {
                this.items.forEach((i) => {
                    i.CHECK = false
                })
            }
            this.$emit('event-check-row', this.checked)
        },
    },
    watch: {
        list: {
            deep: true,
            handler(val, oldVal) {
                console.log('list', val, oldVal)
                /* 
                    220413 meej check undefined 일 경우 false 값 넣어주기
                    220428 meej CHECK와 checked 다를 경우 일치시켜줌_확인필요
                    220630 meej 줄번호 추가 (내림차순)
                */
                for(var i=0;i<val.length;i++){
                    val[i].idx = val.length - i 
                    if(val[i].CHECK === undefined ){
                        val[i].CHECK = false
                    } else if(val[i].checked !== undefined && val[i].CHECK !== val[i].checked){
                        val[i].CHECK = val[i].checked
                    }
                }
                this.items = clone(val)
                
               // 22.12.26 SUYE 스케줄 ORDERTABLE에서만 정렬 적용  
               if(this.isScheduleOrder && this.isScheduleOrder !== undefined && this.isScheduleOrder !== null){
                console.log('isScheduleOrder?', this.isScheduleOrder)
                    // 22.12.09 SUYE 스케줄 순서변경 기준: 순서변경 > 카테고리 
                    this.items.forEach(item => {
                        CONSTANTS.SCHEDULE_INDEX.forEach(e => {
                            if(e.label == item.category) {
                                item.num = e.value
                            }
                        });
                    });
                    this.items.sort((a, b) => {

                        // 순서변경값
                        if(a.index > b.index) return 1
                        if(a.index < b.index) return -1

                        // 카테고리 우선순위
                        if(a.num > b.num) return 1
                        if(a.num < b.num) return -1
                    });
                }
                // console.log('items=', this.items)
            },
        },
        items: {
            deep: true,
            handler(val, oldVal) {
                if (this.customCheck) {
                    console.log('watch', val, oldVal)
                    this.checked = []
                    val.forEach((row) => {
                        if (row.CHECK) {
                            this.checked.push(row)
                        }
                    })
                    this.allCheck =
                        this.checked.length == this.items.length ? true : false
                }
            },
        },
        /* 
            220502 meej type 값 오류로 인해 state에 저장해서 사용처리
         */
        orderTbType: { 
            deep: true,
            handler() {
                this.type = this.orderTbType
            }
        }
    },
}
</script>
<style scoped>
.chkHidden{
    visibility: hidden;
}
</style>