<template>
    <section class="row">
        <i-list class="col-6 q-pa-md" :yearOptions="options" />
        <!-- <i-list
            v-show="isListPage || !showResource" :yearOptions="options"
            class="col-6 q-pa-md"
            /> -->
        <i-content class="col-6 q-pa-md" :yearOptions="options" />

    </section>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { thisYear } from '@/util'
import ClosedList from '@/components/schedule/closed/List.vue'
import ClosedContent from '@/components/schedule/closed/Content.vue'
export default {
    name: 'Closed',
    components: {
        'i-list' : ClosedList,
        'i-content' : ClosedContent
    },
    data () {
        return {
            options : []
        }
    },
    created () {
        this.ASYNC_CLOSED_OPTIONS()
        const tYear = parseInt(thisYear())
        //위로 2년, 향후 2년
        for(let i = -2; i < 3; i++) {
            let y = tYear + i
            let item = { name0: y+'년', value0:y+''}
            this.options.push(item)
        }
        this.setPageStatus(this.PAGE_STATUS.LIST)
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            request: state => state.resource.request
        }),
        ...mapGetters ({
            isListPage : 'page/isListPage',
            isEditPage : 'page/isEditPage',
            isRegisterPage : 'page/isRegisterPage'
        }),
        showResource () {
            return this.isRegisterPage || (this.isEditPage && (this.request !== null))
        }
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus'
        }),
        ...mapActions('schedule', ['ASYNC_CLOSED_OPTIONS']),
    },
}
</script>

<style>
    /* common */

    /* xs */
    @media only screen and (max-width: 599px) {
    }

    /* sm */
    @media only screen and (max-width: 1023px) {
    }

    /* md */
    @media only screen and (max-width: 1439px) {
    }

    /* lg */
    @media only screen and (max-width: 1919px) {
    }

    /* xl */
    @media only screen and (min-width: 1920px) {
    }

</style>


