<template>
    <article>
        <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : list.length == 0 }"
            :columns="columns" :data="list" row-key="RNUM" :rows-per-page-options="[0]" style="height:auto;" hide-pagination no-data-label="조회되는 데이터가 없습니다.">
            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th v-for="col in props.cols" :key="col.name" :props="props">{{ col.label }}</q-th>
                </q-tr>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td v-for="col in props.cols" :key="col.name" :props="props" @click="getView(props.row)" style="cursor:pointer;">
                        <div v-if="col.name == 'trnsmisLink'"><a :href="col.value" target="_blank">{{ col.value }}</a></div>
                        <div v-else>{{ col.value }}</div>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:no-data="{ icon, message }"><div class="full-width row flex-center q-gutter-sm"><q-icon size="2em" :name="icon"/> <span>{{message}}</span></div></template>
        </q-table>
    </article>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    data () {
        return {
            columns: [
                { headerStyle:'text-align:center;', style:'width:20%;', name: 'trnsmisNm', label: '업체명', align: 'left', field:'trnsmisNm' },
                { headerStyle:'text-align:center;', style:'width:*;', name: 'trnsmisCn', label: '줄광고 내용', align: 'left', field:'trnsmisCn' },
                { headerStyle:'text-align:center;', style:'width:30%;', name: 'trnsmisLink', label: '링크', align: 'left', field:'trnsmisLink' },
                { headerStyle:'text-align:center;', style:'width:5%;', name: 'trnsmisSn', label: '위치', align: 'center', field: (row) => row.trnsmisSn == 0 ? '랜덤' : row.trnsmisSn },
              ]
        }
    },
    computed : {
        ...mapState('manage', ['item', 'list', 'itemList']),
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            TRANS_TYPE : state => state.manage.transType,
        })
    },
    created () {
        this.getList();
    },
    methods: {
        ...mapMutations('manage', ['INIT_DATA', 'INIT_DATA_VIEW', 'SET_DATA_VIEW', 'SET_DATA']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions('manage', ['GET_CALL']),
        //리스트
        getList() {
            let param = {
                keyword: this.$parent.keyword,
                'trnsmisType': this.TRANS_TYPE.LINE_ADVRTS,
                CALL_API_URL: '/trnsmis/list'
            }
            this.GET_CALL(param).then((data) => {
                this.SET_DATA(data);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        //상세
        getView(item) {
            this.INIT_DATA_VIEW();
            var trnsmisId = "";
            if(item != null && item.trnsmisId != null){
                trnsmisId = item.trnsmisId;
            }
            let param = {
                'trnsmisId': trnsmisId,
                'trnsmisType': this.TRANS_TYPE.LINE_ADVRTS,
                'CALL_API_URL': '/trnsmis/view'
            }
            this.GET_CALL(param).then((data) => {
                var iCnt = 0;
                var ds_list = [];
                var ds_obj = {};
                data.itemList.forEach(row => {
                    if(iCnt == 3){
                        iCnt = 0;
                        ds_list.push(ds_obj);
                        ds_obj = {};
                    }
                    ds_obj['code'+iCnt] = row.code;
                    ds_obj['name'+iCnt] = row.name;
                    ds_obj['trnsmisChk'+iCnt] = row.trnsmisChk;
                    iCnt++;
                });
                ds_list.push(ds_obj);
                data.itemList = ds_list;
                if(data.item != null && data.item.trnsmisSn != null){
                    this.$parent.$refs.lineAdvrtsContent.getTrnsmisSnChk(data.item.trnsmisSn);
                }else{
                    this.$parent.$refs.lineAdvrtsContent.getTrnsmisSnChk(0);
                }
                this.SET_DATA_VIEW(data);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        }
    }
}

</script>
