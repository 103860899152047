import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store/index'
import './quasar'
import Debug from './debug'
import SimpeVueValidation from 'simple-vue-validator'
import VueMoment from "vue-moment"
import moment from "moment-timezone"
import VueCryptojs from 'vue-cryptojs'
import CKEditor from 'ckeditor4-vue'
import FullCalendar from 'vue-full-calendar'

Vue.prototype.$debug = Debug
//Vue.config.productionTip = false

Vue.use(SimpeVueValidation)
Vue.use(VueMoment, { moment })
Vue.use(VueCryptojs)
Vue.use(CKEditor);
Vue.use(FullCalendar);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

