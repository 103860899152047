<template>
    <article>
        <!-- 구분 -->
        <div class="row q-my-xs i-top-wrap">
            <div class="col-4 col-md-12 flex justify-start">
                <i-btn class="q-ma-xs" label="삭제" @click="deleteItems()" />
                <i-field check style="width:5%"
                        v-model="exclusion" inputLabel="제외종목 포함" @input="INIT_LIST(); getList()" />
            </div>
            <div class="col-4 col-md-6 flex q-pa-xs">
                <i-stock-search style="width:75%" @get="getStock"/>
                <i-btn style="width:25%" icon="search" @click="getSearch()" />
            </div>
            <div class="col-4 col-md-6 flex q-pa-xs">
                <i-select
                    v-model="flashType"
                    style="width:80%"
                    :options="flashTypeOptions" label="속보타입"
                    option-label="label" option-value="value"
                />
                <i-btn style="width:20%" label="속보" @click="makeFlash()" :disable="flashType === null" />
            </div>

        </div>
        <!-- 목록 -->
        <i-table :columns="columns" :list="list" :nextKey="nextKey"
            @row-click="getItem" @next-click="getList()" @check-event="handleCheckList" />

    </article>
</template>
<script>

import Debug from '@/debug'
import { FormatDate, CutDownText, clone } from '@/util'

import Button from '@/components/common/Button'
import StockSearch from '@/components/common/StockSearch'
import Table from '@/components/common/Table'
import Select from '@/components/common/Select'
import Field from '@/components/common/Field'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    components: {
        'i-btn' :  Button,
        'i-stock-search': StockSearch,
        'i-table' :  Table,
        'i-select': Select,
        'i-field': Field,
    },
    data () {
        return {
            newsType: 'STOCK_NEW_RECOMMEND',
            condition: {
                stock: ''
            },
            selected: [],
            columns: [
                { name: 'createTime', label: '추천일자', align: 'left', field: row => FormatDate(row.showDate) },
                { name: 'stock', label: '종목', align: 'left', field: row => `${row.stockName} ( ${row.stockCode} )` },
                { name: 'stockCompany', label: '증권사', align: 'left',field: row => {
                        var temp = this.stockCompanyOptions.find(v => v.code == row.STOCK_COMPANY0)
                        return temp ? temp.name : row.STOCK_COMPANY0
                    }
                },
                { name: 'recommendType', label: '추천구분', align: 'left',field: row => {
                    var temp = this.recommendTypeOptions.find(v => v.value0 == row.RECOMMEND_TYPE)
                        return temp ? temp.name0 : row.RECOMMEND_TYPE
                    }
                },
                { name: 'content', label: '내용', align: 'left', field: row => CutDownText(row.content, 10) }
            ],
            flashType: null,
            flashTypeOptions: [
                { label: '증권사별 추천종목(종합)', value: 'MARKET_FLASH_RECOMMEND_ALL'},
                { label: '증권사 신규추천 종목', value: 'MARKET_FLASH_NEW_RECOMMEND'}
            ],
            newsFlashData: {},
            exclusion:false
        }
    },
    created () {
        var params = {}
        params.newsType = this.newsType
        this.GET_STOCK_COMPANY(params)

        this.INIT_LIST()
        this.getList()
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
            stockCompanyOptions: state => state.resource.stockCompany,
            recommendTypeOptions: state => state.resource.recommendType,
            flashStatus: state => state.flash.status
        }),
        ...mapState( 'stock_new_recommend', ['list', 'nextKey']),

        ...mapGetters( 'page', ['isListPage', 'isDetailPage', 'isEditPage', 'isRegisterPage']),
    },
    methods: {
        ...mapMutations( 'page', ['setPageStatus']),
        ...mapMutations( 'flash', ['SET_PREVIEW']),
        ...mapMutations( 'stock_new_recommend', ['SET_LIST', 'SET_DETAIL', 'INIT_LIST', 'INIT_DETAIL']),

        ...mapActions( 'alert', ['showError', 'showMessage']),
        ...mapActions( 'resource', ['GET_STOCK_COMPANY']),
        ...mapActions( 'stock_new_recommend', ['GET_LIST','GET_DETAIL','DELETE_ITEMS']),
        ...mapActions( 'flash', ['WRITE_FLASH']),

        handleCheckList(list) {
            this.selected = list
        },
        getSearch() {
            this.condition.stock = ''

            this.INIT_LIST()
            this.getList()
        },
        getStock(stock) {
            this.condition.stock = stock

            this.INIT_LIST()
            this.getList()
        },
        goListPage() {
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        goRegisterPage() {
            this.setPageStatus(this.PAGE_STATUS.REGISTER)
        },
        // 목록 조회하기
        getList() {
            var params = {}

            if (this.condition.stock != null) {
                params.code = this.condition.stock.code
            }
            if(this.exclusion) {
                params.exclusion = 1
            }
            this.GET_LIST(params)
        },
        // 상세 조회하기
        getItem(item) {
            this.setPageStatus(this.PAGE_STATUS.DETAIL)
            
            // $('t-body').children('tr:eq'+ item.index + ')').css("color","red")
            let selectedItem = clone(item)
                selectedItem.STOCK_COMPANY0 = this.stockCompanyOptions.find(v=> v.code == item.STOCK_COMPANY0)
                selectedItem.RECOMMEND_TYPE = this.recommendTypeOptions.find(v=> v.name0 == item.RECOMMEND_TYPE)
                selectedItem.EXCLUSION0 = item.EXCLUSION0 == 1 ? true : false

            this.SET_DETAIL(selectedItem)
        },
        // 삭제하기
        deleteItems() {
            var message

            if (this.selected.length == 0) {
                message = '삭제할 항목을 선택해주세요.'
                this.showMessage({ vc:this, message:message })
                return
            }

            var params = {}
            params.ids = this.selected.map( x => x.id ).join(',')
            //신규추천은 delete~
            params.force = 1
            // 종목뉴스 삭제 API
            this.DELETE_ITEMS(params)
            .then(() => {
                message = '삭제되었습니다.'
                this.showMessage({ vc:this, message:message })

                this.INIT_DETAIL()

                this.INIT_LIST()
                this.getList()
                this.goListPage()
            })
            .catch((err) => {
                var error = err.message
                this.showError({ vc:this, error:error })
                Debug.log('error = ', error)
            })
        },
        // 속보 생성
        makeFlash() {
            let params = {}
            params.newsType = this.flashType.value
            params.title = this.flashType.label
            this.WRITE_FLASH(params)
            .then((res) => {
                Debug.log('data = ',res)
                this.showPreview();
            })
            .catch((err) => {
                Debug.log('error = ', err)
                this.showError({ vc:this, error: err })
            })
        },
        // 미리보기
        showPreview() {
            this.windowRef = window.open(
                    `/preview/popup.htm`,
                    '시황속보 미리보기',
                    'width=620,height=820'
                )
            if(this.windowRef) {
                this.windowRef.addEventListener('beforeunload', this.closePreview)
            }
        },
        // 미리보기 닫기
        closePreview() {
            this.windowRef = null
            if(this.flashStatus === 1) {
                this.showMessage({vc:this,message:"전송되었습니다."})
            }

        },
    },
    watch: {
        pageStatus(newVal, oldVal) {
            if (oldVal == this.PAGE_STATUS.DETAIL && newVal == this.PAGE_STATUS.LIST) {
                this.exclusion = false
            }
        }

    }
}
</script>


