<template>
    <section>
        <div class="row">
            <q-space />
            <!-- 221012 meej 테마시황 불러오기 버튼 추가 -->
            <span v-if="news.id==null || news.id == ''" class="q-pr-xs"><i-btn label="테마시황 불러오기" @click="getTodayTheme"/></span>
            <i-btn label="취소" @click="initNews"/>
        </div>
        <div class="row">
            <div class="col-12 q-pa-md">
                
                <q-card flat bordered>
                    <q-card-section>
                        <div class="q-pa-xs">
                            <i-input
                                v-model="news.content"
                                type="textarea"
                                :rows="rows"
                                autogrow
                                inputLabel="테마시황"
                            />
                        </div>
                        <div class="row q-pa-xs">
                            <i-btn :label="(isDetailPage?'수정':'등록')" @click="write" class="col" />
                        </div>
                        
                    </q-card-section >
                    <!-- <q-card-section v-if="isDetailPage">
                        <div class="q-pa-xs">
                            <i-input v-model="title" inputLabel="데일리테마 제목" />
                        </div>
                            <div class="row q-pa-xs">
                            <i-btn label="홈페이지 데일리테마 생성" @click="makeDaily" class="col q-ml-xs" />
                        </div>
                        <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : items.length == 0 }"
                    :columns="columns" :data="items" row-key="id" :rows-per-page-options="[0]" style="height:auto;" selection="multiple"
                    :selected.sync="selected"
                    hide-pagination no-data-label="조회되는 데이터가 없습니다.">
                    <template v-slot:header="props">
                        
                        <q-tr :props="props">
                            <q-th auto-width />
                            <q-th v-for="col in props.cols" :key="col.name" :props="props" v-html="col.label"></q-th>
                        </q-tr>
                    </template>
                    <template v-slot:body="props">
                        
                        <q-tr :props="props">
                            <q-td  class="text-left">
                            <q-checkbox
                                v-model="props.row.checked"
                                @input="handleCheckBox(props.row)"
                                size="xs"
                                :disable="props.row.crud == 'D'"
                            />
                            
                            </q-td>
                            <q-td v-for="col in props.cols" :key="col.name" :props="props" @click="getSelected(props.row)">
                                <div>{{ col.value }}</div>
                            </q-td>
                        </q-tr>
                        <q-tr v-if="props.row.checked" :props="props">
                            <q-td colspan="100%">
                                <q-file outlined bottom-slots v-model="props.row.upload" style="max-width: 360px" clearable dense>
                                    <template v-slot:prepend>
                                        <q-icon name="attach_file" />
                                    </template>
                                    <template v-slot:hint>
                                        이미지를 선택하세요 
                                    </template>
                            </q-file>
                            </q-td>
                        </q-tr>
                        
                    </template>
                </q-table>
                    </q-card-section> -->
                </q-card>
            </div>
        </div>
    </section>
</template>
<script>
import Debug from '@/debug'
import { today, clone } from '@/util'
//import Table from '@/components/common/Table'
import Button from '@/components/common/Button'
import Input from '@/components/common/Input'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Theme',
    components: {
        'i-btn': Button,
        'i-input': Input,
        //'i-otable': Table,
    },
    props: {
        newsType: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            title: '',
            ordering: false,
            createTime: '',
            writer: '',
            rows: 36,
            news: {
                id: null,
                content: '',
            },
            oriList: [],
            items: [],
            selected: [],
            selection: 'multiple',
            options: [
                {
                    label: '오전장',
                    themeType: 'THEME_FEATURED_AM',
                    marketType: 'MARKET_THEME_TRAND_AM',
                    flashType: 'MARKET_FLASH_THEME_AM',
                },
                {
                    label: '오후장',
                    themeType: 'THEME_FEATURED_PM',
                    marketType: 'MARKET_THEME_TRAND_PM',
                    flashType: 'MARKET_FLASH_THEME_PM',
                },
            ],
            themeType: 'THEME_FEATURED_ALL',
            columns: [
                {
                    name: 'themeName',
                    label: '테마명',
                    align: 'left',
                    field: (row) => row.themeName,
                },
                {
                    name: 'title',
                    label: '이슈요약',
                    align: 'left',
                    field: (row) => row.title,
                },
            ],
            isInit: false,
            isFirst: true
        }
    },
    created() {
        
        this.columns.splice(0, 0, {
            name: 'type',
            label: '시간구분',
            align: 'left',
            field: (row) =>
                row.newsType1 === this.options[0].themeType
                    ? '오전장'
                    : '오후장',
        })

        // 221012 meej 특징테마 제목 자동 불러오기 추가
        this.getFeaturedList()
        
        //this.getTrendNews()
    },
    updated() {
        Debug.log('ThmemMakarte updated>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
    },
    
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            detail: (state) => state.theme_featured.item,
        }),
        ...mapGetters('page', ['isEditPage', 'isRegisterPage', 'isListPage', 'isDetailPage']),
        
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
            REQUEST_RESOURCE: 'resource/REQUEST_RESOURCE',
            SET_PREVIEW: 'flash/SET_PREVIEW',
            
        }),
        ...mapMutations('theme_featured', ['INIT_LIST', 'SET_LIST', 'SET_ITEM']),
        ...mapActions('theme_featured', [
            'GET_FEATURED_THEME_LIST',
            'FEAURED_ORDER',
            'GET_MARKET_NEWS',
            'MAKE_DAILY',
        ]),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),

        ...mapActions('market_news', [
            'WRITE_ITEM',
            'GET_LIST'
        ]),
        initNews() {
            Debug.log('initNews~~~~~~~~~~~~~~~~~~')
            this.setPageStatus(this.PAGE_STATUS.LIST)
            this.news = { id: null, content: ''}
        },
        handleCheckBox(row) {
            if (row.checked) {
            this.selected.push(row);
            } else {
                var index = this.selected.indexOf(row);
                if (index !== -1) {
                this.selected.splice(index, 1);
                }
            }
        },
        getSelected(selected) {
            // this.items.forEach((row) => {
            //         var item = selected.find((v) => v.id === row.id)
            //         if (item != null) {
            //             row.CHECK = true
            //         } else {
            //             row.CHECK = false
            //         }
            // })
            this.selected = selected
        },
        getTrendNews() {
            let params = {
                newsType: this.newsType,
            }
            this.GET_MARKET_NEWS(params)
                .then((data) => {
                    Debug.log('getTrendNews , ', data)
                    if (data.items.length > 0) {
                        this.news = data.items[0]
                        this.isFirst = false
                        
                        //this.setPageStatus(this.PAGE_STATUS.EDIT)
                    }
                })
                .catch((err) => {
                    this.showError({ vc: this, error: err })
                })
        },
        // 221012 meej 테마시황 불러오기 버튼
        getTodayTheme(){
            if(this.news.content != undefined && this.news.content != null && this.news.content.trim().length > 0){
                this.showAlert({ vc: this, message: '현재 입력된 내용이 전부 지워집니다. <br/>다시 테마시황을 불러오시겠습니까?' })
                .then(() => {
                    this.getFeaturedList()
                })
                .catch(() => {
                    return
                })
            }else{
                this.getFeaturedList()
            }
        },
        // 특징 테마 조회
        getFeaturedList() {
            let date = today()
            let params = {
                newsType: this.themeType,
                date: date,
            }
            this.GET_FEATURED_THEME_LIST(params)
                .then((data) => {
                    this.items = data.items
                    //인덱스 추가
                    this.selected = []
                    this.initOrder()
                    this.news.content = ""
                    let checkDown = -1
                    this.items.forEach((row) => {
                        // 221012 meej 주석처리
                        // row.index = index
                        // if (row.CHECK === '1') {
                        //     row.checked = true
                            
                        //     this.selected.push(row)
                        // } else {
                        //     row.checked = false
                        // }
                        // Debug.log('check', row.checked)
                        // 221012 meej 특징테마 제목 자동 불러오기 추가_마지막 멘트 앞에 테마명+"테마" 추가
                        var keyword = ["급등", "강세", "상승", "급락", "약세", "하락"]
                        var kwIndex = [0, 0, 0, 0, 0, 0]
                        var maxIndex = -1
                        for(var msg in keyword){
                            kwIndex[msg] = row.title.lastIndexOf(keyword[msg])
                             if(kwIndex[msg] == -1) continue
                            if(maxIndex == -1) {
                                maxIndex = msg
                                continue
                            } 
                            if(msg >=1 && kwIndex[maxIndex]<=kwIndex[msg]) {
                                maxIndex = msg
                            }
                        }
                        if (checkDown == -1 && maxIndex >= 3) {
                            checkDown = maxIndex
                            this.news.content += "\n\n"
                        }
                        if (maxIndex != -1){
                            var title1 = row.title.slice(0, kwIndex[maxIndex])
                            var title2 = row.title.slice(kwIndex[maxIndex])


                            this.news.content += "▷"+title1+row.themeName+" 테마 "+title2+".\n\n"
                        }
                    })
                })
                .catch((err) => {
                    this.showError({ vc: this, error: err })
                })
        },
        // 순서 조정
        initOrder() {
            this.items.sort(function(a, b) {
                // 221012 meej 특징테마 순서와 동일하게 수정
                if(a.DAY_INDEX == undefined || b.DAY_INDEX == undefined ){
                    if(a.newsType1 === b.newsType1){
                        return a.ZINDEX - b.ZINDEX 
                    }else if(a.newsType1 < b.newsType1){
                        return 1
                    }else if(a.newsType1 > b.newsType1){
                        return -1
                    }
                    
                }else{
                    return a.DAY_INDEX - b.DAY_INDEX
                }
                // if (a.DAY_INDEX > b.DAY_INDEX) {
                //     return 1
                // }
                // if (a.DAY_INDEX < b.DAY_INDEX) {
                //     return -1
                // }
                // return 0
           })
        },
        // handleOrderList(list) {
        //     let orderList = []
        //     for (let i = 0; i < list.length; i++) {
        //         let item = list[i]
        //         let order = {
        //             newsType: item.newsType1,
        //             id: item.id,
        //             index: '' + i,
        //         }
        //         orderList.push(order)
        //     }
        //     this.showAlert({ vc: this, message: '순서를 변경하시겠습니까?' })
        //         .then(() => {
        //             this.$emit('event-order-featured', orderList)
        //             this.ordering = !this.ordering
        //         })
        //         .catch(() => {
        //             this.ordering = !this.ordering
        //         })
        // },
        // saveOrder() {
        //     let orderList = []
        //     for (let i = 0; i < this.items.length; i++) {
        //         const item = this.items[i]
        //         const orderItem = {
        //             index: '' + i,
        //             id: item.id,
        //             check: item.CHECK,
        //             newsType: item.newsType1,
        //         }
        //         orderList.push(orderItem)
        //     }

        //     const params = {
        //         newsType: this.type.marketType,
        //         items: orderList,
        //     }

        //     this.FEAURED_ORDER(params)
        //         .then(() => {
        //             this.showMessage({ vc: this, message: '완료되었습니다' })
        //             this.getFeaturedList()
        //         })
        //         .catch((err) => {
        //             this.showError({ vc: this, error: err })
        //         })
        // },
        // processDailyFeatured() {
        //     this.items.forEach((row) => {
        //         if (row.checked) {
        //             row.CHECK = '1'
        //         } else {
        //             row.CHECK = '0'
        //         }
        //     })
        //     this.saveOrder()
        // },
        // 등록
        write() {
            // 220727 meej 유효성체크 : 내용 조건 추가
            if (!this.news.content || this.news.content.trim() == "" ) {
                this.showMessage({ vc: this, message: '내용을 입력하세요.' })
                return
            }
            // 221125 meej 수정, 등록 메시지 구분 추가
            let message
            if (this.news.id != null) {
                message = "해당 내용을 수정하시겠습니까?"
            } else {
                message = "해당 내용을 등록하시겠습니까?"
            }
            this.showAlert({
                vc: this,
                message: message,
            }).then(() => {
                this.doWrite()
            })
        },
        doWrite() {
            // if (this.selected.length === 0) {
            //     this.showError({ vc: this, error: '테마를 선택해주세요' })
            //     return
            // }
            
            let params = {
                newsType: this.newsType,
                content: this.news.content,
                showDate: today(),
                showFlag: 1,
            }

            if (this.news.id != null) {
                params.id = this.news.id
            }

            
            this.WRITE_ITEM(params)
                .then(() => {
                    // 221125 meej 완료메시지 추가
                    let message
                    if (this.news.id != null) {
                        message = "수정되었습니다."
                    } else {
                        message = "등록되었습니다."
                    }
                    this.showMessage({ vc: this, message: message })

                    //this.processDailyFeatured()
                    this.isFirst = false
                    this.getList()
                })
                .catch((error) => {
                    this.showError({ vc: this, error: error })
                    Debug.log('error = ', error)
                })
        },
        makeDaily() {
            for(let i in this.selected) {
                let file = this.selected[i].upload
                Debug.log('file = ', file)
            }
        },
        // makeDaily() {
        //     if (this.selected.length === 0) {
        //         this.showError({ vc: this, error: '테마를 선택해주세요' })
        //         return
        //     }
        //     let params = {
        //         content: this.news.content,
        //         title: this.title,
        //     }
        //     let ids = ''
        //     for(let i in this.selected) {
        //         ids += this.selected[i].id + ','
        //     }
        //     //이미지 추가

            
        //     ids = ids.slice(0, -1)
        //     params.ids = ids
        //     this.MAKE_DAILY(params)
        //         .then(() => {
        //             this.showMessage({vc:this, message:'데일리테마가 생성되었습니다.'})
        //             this.$emit('event-made-daily')
        //         })
        //         .catch((error) => {
        //             this.showError({ vc: this, error: error })
        //             Debug.log('error = ', error)
        //         })
        // },
        getList() {
            //this.setPageStatus(this.PAGE_STATUS.LIST)
            this.INIT_LIST()
            var params = {
                newsType: this.newsType,
                showFlag:1
            }

            this.GET_LIST(params)
                    .then((data)=> {
                        Debug.log('GET_LIST = ', data)
                        this.$nextTick(function () {
                            this.SET_LIST(data)
                            // 221017 meej 등록 수정 후 item 다시 넣어주기
                            this.SET_ITEM(data.items[0])
                        })
                        
                    })
                    .catch((err) => {
                        this.showError({ vc: this, error: err })
                    })
        },
        onFileChange(e, row) {
            Debug.log(e, row)
        },
    },
    watch: {
        selected(val) {
            if (!this.ordering && this.isFirst) {
                if (this.newsType !== null) {
                    let tempTitle = '데일리 테마('
                    for (let i = 0; i < this.selected.length; i++) {
                        let item = val[i]
                        tempTitle += item.themeName + ','
                    }
                    this.title = tempTitle.slice(0, -1) + ')'
                }
            }
        },
        detail(val) {
            if(this.newsType === 'MARKET_THEME_NEWS') {
                Debug.log('watch!!! detail ',val)
                if(val == null){
                    // 221017 meej 초기 undefined 오류 수정
                    this.initNews()
                } else {
                    this.news = clone(val)
                }
                // 221017 meej 주석처리
                // if(this.pageStatus === this.PAGE_STATUS.DETAIL) {
                //      this.getFeaturedList()
                // }
            }
            
        }
    },
}
</script>

<style>
/* common */

/* xs */
@media only screen and (max-width: 599px) {
}

/* sm */
@media only screen and (max-width: 1023px) {
}

/* md */
@media only screen and (max-width: 1439px) {
}

/* lg */
@media only screen and (max-width: 1919px) {
}

/* xl */
@media only screen and (min-width: 1920px) {
}
</style>
