<template>
    <section>
        <i-list class="q-pa-md" />
    </section>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import List from '@/components/stock/stock-discl/List.vue'

export default {
    name: 'StockDiscl',
    components: {
        'i-list' : List
    },
    created () {
        this.setPageStatus(this.PAGE_STATUS.LIST)
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
        }),
        ...mapGetters( 'page', ['isListPage'])
    },
    methods: {
        ...mapMutations( 'page', ['setPageStatus'])
    }
}
</script>


