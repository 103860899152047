<template>
    <section class="row">
        <i-content class="col q-pa-md" />
        <i-flash v-show="isFlashPage" :isPopup="false" class="col q-pa-md" />
    </section>
</template>
<script>
import Content from '@/components/flash/market-today-summary/Content.vue'
import Flash from '@/components/common/PreviewPopup.vue'
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'MarketTodaySummary',
    components: {
        'i-content' : Content,
        'i-flash' : Flash
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
            newsType : state => state.resource.newsType,
        }),
        ...mapGetters( 'page', ['isListPage', 'isDetailPage', 'isFlashPage', 'isRegisterPage']),
        ...mapGetters ({
            today : 'calendar/today'
        }),
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus'
        })
    }
}
</script>


