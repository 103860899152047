import InfostockApi from './InfostockApi'
import { store } from '../store/index'
import Debug from '../debug'
import { Loading } from 'quasar'
import axios from 'axios'

export default {

    // 22.07.14 test 
    async getSuccessAdmin(){
        return this.callApi('/issuccessadmin')
    },
    
    /* 관리자 스케줄 */
    async adminSchedule(params){
        return this.callApi('/adminSchedule/tableList',params)
    },
    // 220824 meej 인포봇 수동 실행
    async infobotExe(params){
        return this.callApi('/adminSchedule/infobotExe',params)
    },
    // 220824 meej 인포봇 업무 조회
    async infobotSchedule(params){
        return this.callApi('/adminSchedule/scheduleList',params)
    },
    async adminScheduleCalendarList(params){
        return this.callApi('/adminSchedule/calendarList',params)
    },
    async adminScheduleWrite(params){
        return this.callApi('/adminSchedule/write',params)
    },
    async adminScheduleDelete(params){
        return this.callApi('/adminSchedule/delete',params)
    },
    /* 관리자 스케줄 */

    async signup(params) {
        return this.callApi('/signup', params)
    },
    async approve(params) {
        return this.callApi('/approve', params);
    },
    async login(params) {
        return this.callApi('/login', params)
    },
    async logout() {
        return this.callApi('/logout');
    },

    async findStaffIds(params) {
        return this.callApi('/adminlog/ids', params)
    },
    async adminLogLabels() {
        return this.callApi('/adminlog/labels')
    },
    async adminLog(params) {
        return this.callApi('/adminlog/list', params)
    },
    async staffList() {
        return this.callApi('/staff/list');
    },
    async staffStatus(params) {
        return this.callApi('/staff/status',params);
    },
    async staffRole(params) {
        return this.callApi('/staff/role', params);
    },
    async staffFails(params) {
        return this.callApi('/staff/fails',params);
    },
    async settingList() {
        return this.callApi('/setting/list');
    },
    async settingSet(params) {
        return this.callApi('/setting/set',params);
    },
    async passwd(params) {
        return this.callApi('/staff/passwd', params);
    },
    async hello() {
        return new Promise((resolve, reject) => {
            InfostockApi('/admin').post('/hello')
                .then((response) => {

                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
            })
    },
    // initial
    async initial() {
        return this.callApi('/initial');
    },
    // 뉴스타입
    newsType(params) {
        return this.callApi('/newstype', params);
    },
    async getTodayWorld(params) {
        return this.callApi('/todayworld/list', params);
    },
    async setTodayWorld(params) {
        return this.callApi('/todayworld/set', params);
    },
    /* 시황 */
    async marketNewsList(params) {
        return this.callApi('/marketnews/list', params);
    },
    async marketNewsDetail(params) {
        return this.callApi('/marketnews/detail', params);
    },
    // 220829 meej 주요 종목 공시 _ 인포봇으로 입력된 데이터 가져옴
    async marketNewsInitContent(params) {
        return this.callApi('/marketnews/getInitContent', params);
    },
    // 220829 meej 주요 종목 공시 _ 자동공시 수정
    async editAutoDiscl(params) {
        return this.callApi('/marketnews/editAutoDiscl', params);
    },
    async marketNewsWrite(params) {
        return this.callApi('/marketnews/write', params);
    },
    async marketNewsDelete(params) {
        return this.callApi('/marketnews/delete', params);
    },
    // 220923 meej marketNews 완전 삭체 처리
    async marketNewsForceDelete(params) {
        return this.callApi('/marketnews/forceDelete', params);
    },
    async marketNewsMultiDelete(params) {
        return this.callApi('/marketnews/multi/delete', params);
    },
    /* 
        220406 meej 거래소/코스닥 시황(월드) 일 때 전일시황내용 없이 빈칸으로 출력, 주석처리
    */
    // async marketNewsMultiDetail(params) {
    //     return this.callApi('/marketnews/multi/detail', params);
    // },
    // async marketNewsListBySendDate(params) {
    //     return this.callApi('/marketnews/list/senddate', params);
    // },
    async marketNewsSummaryRefer() {
        return this.callApi('/newsflash/summary/refer');
    },
    async marketNewsByDate(params) {
        return this.callApi('/marketnews/date', params);
    },
    async marketNewsRecent(params) {
        return this.callApi('/marketnews/recent', params);
    },
    /* 종목 */
    async stockSearch(params) {
        return this.callApi('/stock/search', params);
    },
    async stockList(params) {
        return this.callApi('/stock/list', params);
    },
    async stockDetail(params) {
        return this.callApi('/stock/detail', params);
    },
    async stockWrite(params) {
        return this.callApi('/stock/write', params);
    },
    async stockDelete(params) {
        return this.callApi('/stock/delete', params);
    },
    async stockThemeOrder(params) {
        return this.callApi('/stock/theme/order', params);
    },
    async stockHistory(params) {
        return this.callApi('/stock/history', params);
    },
    async stockCompanyList(params) {
        return this.callApi('/stock/company', params);
    },
    async stockPartList() {
        return this.callApi('/stock/part');
    },
    async stockCompanyWrite(params) {
        return this.callApi('/stock/company/write', params);
    },

    /* 종목 뉴스 */
    async stockNewsList(params) {
        return this.callApi('/stocknews/list', params);
    },
    // 230215 meej SPOT 입력 리스트 조회 API 추가
    async stockNewsSpotList(params) {
        return this.callApi('/stocknews/spotList', params);
    },
    async stockNewsExcelWrite(params) {
        return this.callApi('/stocknews/excelwrite', params);
    },
    async stockNewsDetail(params) {
        return this.callApi('/stocknews/detail', params);
    },
    async stockNewsWrite(params) {
        return this.callApi('/stocknews/write', params);
    },
    async stockNewsDelete(params) {
        return this.callApi('/stocknews/delete', params);
    },
    async stockNewsOpinionHistoryDelete(params) {
        return this.callApi('/stocknews/opinionHistoryDelete', params);
    },    
    async stockNewsMultiDelete(params) {
        return this.callApi('/stocknews/multi/delete', params);
    },
    async stockNewsDiscl(params) {
        return this.callApi('/stocknews/discl', params);
    },
    async stockNewsFeaturedWrite(params) {
        return this.callApi('/stocknews/featured/write', params);
    },
    // 220701 meej writeList 추가 : 특징주 등록시 여러개 등록 가능하게 수정
    async stockNewsFeaturedWriteList(params) {
        return this.callApi('/stocknews/featured/writeList', params);
    },
    async stockNewsListByDate(params) {
        return this.callApi('/stocknews/date', params);
    },
    async stockNewsChangeNewsType(params) {
        return this.callApi('/stocknews/featured/change', params);
    },
    // 220905 meej 특징주 - 이슈이동
    async stockNewsChangeIssue(params) {
        return this.callApi('/stocknews/featured/changeIssue', params);
    },
    async stockNewsIssue(params) {
        return this.callApi('/stocknews/featured/issue', params);
    },
    async stockNewsFeaturedOrder(params) {
        return this.callApi('/stocknews/featured/order', params);
    },
    async stockNewsFeaturedDetail(params) {
        return this.callApi('/stocknews/featured/detail', params);
    },
    async stockNewsFeaturedConfirm(params) {
        return this.callApi('/stocknews/featured/confirm', params);
    },
    async stockNewsConfirm(params) {
        return this.callApi('/stocknews/confirm', params);
    },
    
    async stockJumpList(params) {
        return this.callApi('/stocknews/stockJumplist', params);
    },

    /* 테마 */
    async themeList(params) {
        return this.callApi('/theme/list', params);
    },
    async themeDetail(params) {
        return this.callApi('/theme/detail', params);
    },
    async themeWrite(params) {
        return this.callApi('/theme/write', params);
    },
    async themeRate(params) {
        return this.callApi('/theme/insertRate', params);
    },    
    async themeStock(params) {
        return this.callApi('/theme/stock/list', params);
    },
    async themeStockDetail(params) {
        return this.callApi('/theme/stock/detail', params);
    },
    async themeStockOrder(params) {
        return this.callApi('/theme/stock/order', params);
    },
    async themeStockWrite(params) {
        return this.callApi('/theme/stock/write', params);
    },
    async themeFeatures(params) {
        return this.callApi('/themenews/featured/list', params);
    },
    async themeFeaturesDetail(params) {
        return this.callApi('/themenews/featured/detail', params);
    },
    async themeFeaturesWrite(params) {
        return this.callApi('/themenews/featured/write', params);
    },
    async themeFeaturesDelete(params) {
        return this.callApi('/themenews/delete', params);
    },
    async themeFeaturesOrder(params) {
        return this.callApi('/themenews/featured/order', params);
    },
    // 220811 meej 데일리테마 - 테마 종목 시세 가져오기
    async themeFeaturesPrice(params) {
        return this.callApi('/themenews/featured/daily/themeStockRate', params);
    },
    async themeNews(params) {
        return this.callApi('/themenews/list', params);
    },
    async themeNewsWrite(params) {
        return this.callApi('/themenews/write', params);
    },
    async themeNewsDelete(params) {
        return this.callApi('/themenews/delete', params);
    },
    async themeNewsMultiDelete(params) {
        return this.callApi('/themenews/multi/delete', params);
    },
    async themeFeaturedConfirm(params) {
        return this.callApi('/themenews/featured/confirm', params);
    },
    async themeFeaturedHistory(params) {
        return this.callApi('/themenews/featured/history', params);
    },
    async themeFeaturedDaily(params) {
        return this.callApi('/themenews/featured/daily', params);
    },
    async themeFeaturedDailyToday() {
        return this.callApi('/themenews/featured/daily/today', {});
    },
    async themeFeaturedDailyConfirm(param) {
        return this.callApi('/themenews/featured/daily/confirm', param);
    },
    async themeFeaturedDailyDelImage(param) {
        return this.callApi('/themenews/featured/daily/delete/img', param);
    },
    // 221025 meej 테마 히스토리 조회
    async themeNewsHistoryList(params) {
        return this.callApi('/themenews/history/list', params);
    },
    // 221025 meej themeNews 완전삭제
    async themeNewsDeleteHard(params) {
        return this.callApi('/themenews/deleteHard', params);
    },
    // 221028 meej 테마 등락률 목록 조회
    async themeRateList(params) {
        return this.callApi('/theme/rateList', params);
    },
    // 221028 meej 종목시세 업데이트 후 테마 등락률 조회
    async themeUpdateRateList(params) {
        return this.callApi('/theme/updateRateList', params);
    },

    
    /* 스케줄 */
    async scheduleList(params) {
        return this.callApi('/eventnews/list', params);
    },
    async scheduleListByDate(params) {
        return this.callApi('/eventnews/date', params);
    },
    async scheduleListByTypeDate(params) {
        return this.callApi('/eventnews/typeDate', params);
    },    
    async scheduleDetail(params) {
        return this.callApi('/eventnews/detail', params);
    },
    async scheduleWrite(params) {
        return this.callApi('/eventnews/write', params);
    },
    async scheduleDelete(params) {
        return this.callApi('/eventnews/delete', params);
    },
    async scheduleMultiDelete(params) {
        return this.callApi('/eventnews/multi/delete', params);
    },
    async scheduleOrder(params) {
        return this.callApi('/eventnews/order', params);
    },
    async closedList(params) {
        return this.callApi('/eventnews/closed/list', params);
    },
    async closedWrite(params) {
        return this.callApi('/eventnews/closed/write', params);
    },
    async closedOptions() {
        return this.callApi('/eventnews/closed/options', {});
    },
    async lastAnnounce(params) {
        return this.callApi('/eventnews/lastAnnounce', params);
    },
    /* 속보 */
    async flashList(params) {
        return this.callApiWithLoading('/newsflash/list', params, false);
    },
    async flashNewsList(params) {
        return this.callApiWithLoading('/newsflash/listbydate', params, false);
    },
    // 221101 meej 속보 목록 조회
    async flashNewsListByDay(params) {
        return this.callApi('/newsflash/listByDay', params);
    },
    async flashWrite(params) {
        return this.callApi('/newsflash/write', params);
    },
    async flashSend(params) {
        return this.callApi('/newsflash/send', params);
    },
    async flashReSend(params) {
        return this.callApi('/newsflash/resend', params);
    },
    async flashPreview(params) {
        return this.callApi('/newsflash/preview', params);
    },
    async deleteNewsFlash(params) {
        return this.callApi('/newsflash/delete', params);
    },
    async summaryPreview(params) {
        return this.callApi('/newsflash/summary/preview', params);
    },
    async infostockDailyIrNews(params) { 
        return this.callApi('/newsflash/dailynews', params);
    },
    async sentNewsFlashList() {
        return this.callApi('/sent/list',{});
    },
    async sentNewsFlashDetail(params) {
        return this.callApi('/sent/detail',params);
    },
    async stockFilteringList(params) {
        return this.callApi('/stock/filtering/list', params);
    },
    /*  주식신문 */
    async issueWrite(params) {
        return this.callApi('/newspapers/write/issue', params);
    },
    async issueUpdate(params) {
        return this.callApi('/newspapers/update/issue', params);
    },
    async issuePreview(params) {
        return this.callApi('/newspapers/preview/issue', params);
    },

    async newsPaperMake(params) {
        return this.callApi('/newspapers/makenewspaper', params);
    },
    async newsPaperPreview(params) {
        return this.callApi('/newspapers/preview/made', params);
    },
    async newsPaperConfirm(params) {
        return this.callApi('/newspapers/confirm', params);
    },
    async newsPaperSend(params) {
        return this.callApi('/newspapers/send', params);
    },
    // 20230906 재전송버튼
    async notnewsPaperSend(params) {
        return this.callApi('/newspapers/sendNotListsend', params);
    },
    async newsPaperShow(params) {
        return this.callApi('/newspapers/show', params);
    },
    
    async newsDisclList(params) {
        return this.callApi('/newspapers/discl/list', params);
    },

    // 220927 meej 주식신문 조회수 
    async getViewCountList(params) {
        return this.callApi('/newspapers/viewCount/list', params);
    },

    // 22.12.02 SUYE 신문 상단표
    async getCloseIndexList(params) {
        return this.callApi('/newspapers/closeIndex/list', params);
    },

    // 22.12.02 SUYE 신문 상단표 저장
    async saveCloseIndex(params) {
        return this.callApi('/newspapers/closeIndex/save', params);
    },

    /* 회원관리 */
    async memberList() {
        return this.callApi('/member/list')
    },
    
    // 20230905 미전송리스트
    async sendnotList() {
        return this.callApi('/member/sendnotList');
    },

    async memberDetail(params) {
        return this.callApi('/member/item', params)
    },
    // 220628 meej 회원수 현황
    async memberStatus(params) {
        return this.callApi('/member/status', params)
    },
    /* 실적 */
    async earningList(params) {
        return this.callApi('/earning/list', params)
    },
    async earningDetail(params) {
        return this.callApi('/earning/detail', params)
    },
    async earningWrite(params) {
        return this.callApi('/earning/write', params)
    },
    async earningMultiDelete(params) {
        return this.callApi('/earning/multi/delete', params);
    },
    async earningPreview(params) {
        return this.callApi('/earning/preview', params);
    },  
    async earningPaperDelete(params) {
        return this.callApi('/earning/deletePaper', params);
    },  
    async earningDelete(params) {
        return this.callApi('/earning/deleteEarning', params);
    },      
    async earningReserve(params) {
        return this.callApi('/earning/reserve', params);
    },     

    // 23.03.16 홈페이지 조회수 통계
    async getHitsMainWlimit(params) {
        return this.callApi('/board/hitsMainLimit', params)
    },
    
    /* 게시판 */
    async boardWrite(params) {
        return this.callApi('/board/write', params)
    },
    async boardList(params) {
        return this.callApi('/board/list', params)
    },
    async boardDetail(params) {
        return this.callApi('/board/detail', params)
    },
    async boardDelete(params) {
        return this.callApi('/board/delete', params)
    },
    async inBoardWrite(params) {
        return this.callApi('/inboard/save', params)
    },    
    // 스케줄 형식 
    async writeScheduleForm(params) { 
        return this.callApi('/board/writeForm', params)
    },
    async listScheduleForm(params) {
        return this.callApi('/board/listForm', params)
    },
    async selectScheduleForm(params) {
        return this.callApi('/board/selectForm', params)
    },
    /* 공통 호출 */
    async call(params) {
        return this.callApi(params.CALL_API_URL, params);
    },
    async callUpload(formData) {
        return this.upload(formData.get('CALL_API_URL'), formData);
    },
    async callDownload(params) {
        return this.callApi(params.CALL_API_URL, params);
    },
    /* common */
    async stockPrice (params) {
        return this.callApi('/price', params)
    },
    async settings () {
        return this.callApi('/setting/list')
    },
    async setSetting (params) {
        return this.callApi('/setting/set', params)
    },
    async macroUpload(params) {
        Debug.log('macroUpload')
        return this.callApi('/newsflash/upload', params)
    },
    async getPdf(params) {
        return this.callApi('/pdf', params)
    },
    // for test
    async writeTest (service, body) {
        let params = {
            body:body
        }
        return this.callApi('/'+service, params)
    },
    async stockInputTest(params) {
        return this.callApi('/stocknews/test/write', params);
    },
    async fileUpload(param) {
        return this.callApi('/ftp/put', param)
    },
     async pdfUpload(formData) {
        return this.callApi('/pdf/upload', formData)
    },
    // 220511 meej 특징주 : 등락률 업데이트 기능 추가
    async stockNewsChageRate(params) {
        return this.callApi('/stocknews/featured/changeRate', params);
    },
    
    // CALL APIhttp://<host>/admin/themenews/featured/list
    callApiFullPath (home, path, params) {
        return this.CALL_API(home, path, params, true)
    },
    callApi (path, params) {
        return this.CALL_API('/admin', path, params, true)
    },
    callApiWithLoading (path, params, loading) {
        return this.CALL_API('/admin', path, params, loading)
    },
    CALL_API (home, path, params, loading) {
        Debug.log('start home = ', home)
        Debug.log('start path = ', path)
        Debug.log('start api params = ', params)

        return new Promise((resolve, reject) => {
            if(loading){
                Loading.show()
            }
            InfostockApi(home).post(path, params)
                .then((response) => {
                    if(loading){
                        Loading.hide()
                    }
                    Debug.log('sevice success = [',path,']', response)
                    //TODO : 처리 필요
                    if(response.data.success) {
                        //성공일때
                        resolve(response.data)
                    } else {
                        //실패일때
                        if (response.data.message)
                            reject(response.data.message)
                        else
                            reject(response.data)
                    }
                })
                .catch((error) => {
                    if(loading){
                        Loading.hide()
                    }
                    Debug.log("----------------error", error.response)
                    if (error.response != undefined && error.response.data) {
                        if (error.response.data.status === '902') {
                            store.dispatch('session/invalid_session')
                            alert(error.response.data.message)
                        } else if (error.response.status === 500){
                            var errorMessage = '서버에 문제가 발생했습니다.'
                            reject(errorMessage)
                        } else if (error.response.data.status === '904') {
                            //permission denined --> Main 으로 이동 
                            store.dispatch('session/permission_denined')
                            alert(error.response.data.message)
                        } else {
                            Debug.log("response else ")
                            reject(error.response.data.message)
                        }
                    } else {
                        alert(error+'\n오류가 발생하였습니다. 잠시 후에 실행하세요.')
                    }
                })
            })
    },
   
    upload(path, formData) {
        
        return new Promise((resolve, reject) => {
            Loading.show()
            
            axios.post('/admin'+path, formData, {
                    headers: { 
                        Accept: 'application/json',
                        'Content-Type': 'application/json; charset=UTF-8',
                        "Access-Control-Allow-Origin": "*",
                        'enctype': 'multipart/form-data' }
                })
                .then((response) => {
                    Loading.hide()
                    Debug.log('upload sevice success = ', response)
                    //TODO : 처리 필요
                    if(response.data.success) {
                        //성공일때
                        resolve(response.data)
                    } else {
                        //실패일때
                        if (response.data.message)
                            reject(response.data.message)
                        else
                            reject(response.data)
                    }
                })
                .catch((error) => {
                    Loading.hide()
                    //Debug.log("----------------error", error.response)
                    if (error.response != undefined && error.response.data) {
                        if (error.response.data.status === '902') {
                            store.dispatch('session/invalid_session')
                            alert(error.response.data.message)
                        } else if (error.response.status === 500){
                            var errorMessage = '서버에 문제가 발생했습니다.'
                            reject(errorMessage)
                        } else if (error.response.data.status === '904') {
                            //permission denined --> Main 으로 이동 
                            store.commit('session/changePos', 'Main')
                            alert(error.response.data.message)

                        } else {
                            reject(error.response.data.message)
                        }
                    } else {
                        alert(error+'\n오류가 발생하였습니다. 잠시 후에 실행하세요.')
                    }
                })
            })
    },

    download(path, params) {
        Loading.show()
        return new Promise((resolve, reject) => {
            axios.post('/admin'+path, params, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json; charset=UTF-8',
                        "Access-Control-Allow-Origin": "*",
                        'enctype': 'multipart/form-data'
                    },
//                    responseType: 'blob'
                })
                .then((response) => {
                    Loading.hide();
                    Debug.log('response = ', response)
                    if(response.data.success) {
                        //성공일때
                        resolve(response.data)
                    } else {
                        //실패일때
                        if (response.data.message)
                            reject(response.data.message)
                        else
                            reject(response.data)
                    }
                    
                    
                    // const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
                    
                    // const link = document.createElement('a');
                    // link.href = url;
                    // link.setAttribute('download', params.FILE_NAME);
                    // document.body.appendChild(link);
                    // link.click();
                })
                .catch((error) => {
                    Loading.hide();
                    if (error.response != undefined) {
                        if (error.response.data.status === '902') {
                            store.dispatch('session/invalid_session');
                            alert(error.response.data.message);
                        } else if (error.response.status === 500){
                            alert('서버에 문제가 발생했습니다.');
                        } else {
                            alert(error.response.data.message);
                        }
                    } else {
                        alert(error+'\n오류가 발생하였습니다. 잠시 후에 실행하세요.');
                    }
                })
        })
    }
}
