<template>
    <section class="row">
        <div class="row q-pa-md col-12">
            <!-- 221026 meej useColor 추가 : 자주쓰는 문구, 옵션-->
            <i-table class="col-6 " :columns="columns" :list="freqUsedTitle"
                @order-event="handleOrderFavorite" :useColor="true" title="자주쓰는 문구"/>
            <i-table class="col-6 " :columns="columns" :list="optionTitle"
                @order-event="handleOrderOption" :useColor="true" title="옵션"/>
        </div>
        <div class="row q-pa-md  col-12">
            <i-table class="col-6 " :columns="columns" :list="upTitleOptions"
                @order-event="handleOrderTitleUp" title="상승 제목"/>
            <i-table class="col-6 " :columns="columns" :list="downTitleOptions"
                @order-event="handleOrderTitleDown" title="하락 제목"/>
        </div>

    </section>
</template>
<script>
import Table from '@/components/flash/Table.vue'
import { mapActions } from 'vuex'
import debug from '@/debug'
export default {
    name: 'StockFlashSetting',
    components: {
        'i-table' : Table,
    },
    data () {
        return {
            selectedStocks: [],
            freqUsedTitle: [],
            optionTitle: [],
            upTitleOptions: [],
            downTitleOptions: [],
            columns: [{ name: 'name', label: '문구', field: 'name', align:'left'}],
        }
    },
    created() {
        this.getSetting()
    },

    methods: {
        ...mapActions( 'resource', ['GET_SETTINGS','SET_SETTINGS']),
        ...mapActions( 'alert', ['showError', 'showMessage']),
        getSetting() {
            this.GET_SETTINGS()
                .then((res) => {
                    this.initial(res)
                })
                .catch((err) => {
                    this.showError({ vc:this, error:err})
                })
        },
        initial(res) {

            if(res.F_FAVORITE !== undefined)  {
                this.freqUsedTitle = []
                res.F_FAVORITE.split(',').forEach(e => {
                    // 221026 meej color 추가
                    // this.freqUsedTitle.push({name:e})
                    let arrF = e.split('|')
                    let objF = {
                        name: arrF[0],
                        color : arrF[1] != undefined? arrF[1] : 'teal'
                    }
                    this.freqUsedTitle.push(objF)
                })
            }
            if(res.F_OPTION !== undefined) {
                this.optionTitle = []
                res.F_OPTION.split(',').forEach(e => {
                    // 221026 meej color 추가
                    // this.optionTitle.push({name:e})
                    let arrO = e.split('|')
                    let objO = {
                        name: arrO[0],
                        color : arrO[1] != undefined? arrO[1] : 'teal'
                    }
                    this.optionTitle.push(objO)
                })
            }
            if(res.F_TITLE_UP !== undefined) {
                this.upTitleOptions = []
                res.F_TITLE_UP.split(',').forEach(e => {
                    this.upTitleOptions.push({name:e})
                })
            }
            if(res.F_TITLE_DOWN !== undefined) {
                this.downTitleOptions = []
                res.F_TITLE_DOWN.split(',').forEach(e => {
                    this.downTitleOptions.push({name:e})
                })

            }
        },
        handleOrderTitleUp(str) {
            this.saveSetting({
                items: [{F_TITLE_UP: str
                }]})
            debug.log(str)
        },
        handleOrderTitleDown(str) {
            this.saveSetting( {
                items: [{F_TITLE_DOWN: str
                }]})
            debug.log(str)
        },
        handleOrderOption(str) {
            this.saveSetting({
                items: [{F_OPTION: str
                }]})
            debug.log(str)
        },
        handleOrderFavorite(str) {
            debug.log(str)
            this.saveSetting({
                items: [{F_FAVORITE: str
                }]})
        },
        saveSetting(params) {

            this.SET_SETTINGS(params)
            .then(()=>{
                this.showMessage({vc:this, message:'업데이트 완료'})
                this.getSetting()
            })
            .catch((err) => {
                this.showError({ vc:this, error:err})
            })
        }
    }
}
</script>

<style>
    /* common */

    /* xs */
    @media only screen and (max-width: 599px) {
    }

    /* sm */
    @media only screen and (max-width: 1023px) {
    }

    /* md */
    @media only screen and (max-width: 1439px) {
    }

    /* lg */
    @media only screen and (max-width: 1919px) {
    }

    /* xl */
    @media only screen and (min-width: 1920px) {
    }

</style>


