<template>
    <article>
        <q-toolbar>
            <q-space />
            <!-- <i-btn label="취소" @click="goListPage()" /> -->
        </q-toolbar>
        <q-card flat bordered>
            <q-card-section>
                <div class="row q-my-sm">
                    <div class="col-12">
                        <i-input v-model="title" label="제목" />
                    </div>
                </div>
                <div class="row q-my-sm">
                    <div class="col-12">
                        <q-input
                            v-model="file"
                            @input="inputFile"
                            filled
                            type="file"
                            hint="only pdf file"
                            accept=".pdf"
                        />
                    </div>
                </div>
            </q-card-section>
        </q-card>
        <!-- 하단 버튼 -->
        <div class="q-my-md">
            <i-btn v-show="isListPage" class="full-width" label="등록" @click="uploadFile()"/>
        </div>
    </article>
</template>
<script>
import Debug from "@/debug"
// import CONSTANTS from "@/constants";

// import Input from "@/components/common/Input";
import Button from "@/components/common/Button"
import Input from "@/components/common/Input"
// import { FormatDate } from "@/util";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"

export default {
    name: "IRinsightContent",
    components: {
        "i-input": Input,
        "i-btn": Button,
    },
    props: {
        newsType: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            clickItem: [],
            selected: [],
            file: {
                name: "",
            },
            title: "",
        };
    },

    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
        }),
        ...mapGetters( 'page', ['isListPage', 'isEditPage', 'isRegisterPage']),
    },
    destroyed() {
        // this.doStoreReset();
    },
    mounted() {
        // // 파일 업로드 handler
        // const inputElement = document.getElementById("input-fileup");
        // inputElement.addEventListener("change", this.handleFiles, false);
    },
    methods: {
        ...mapMutations( 'page', ['setPageStatus']),
        ...mapMutations( 'iradveirnewsrtising', ['SET_DETAIL']),
        ...mapMutations( 'market_news', ['INIT_LIST']),

        ...mapActions( 'alert', ['showError', 'showMessage']),
        ...mapActions( 'market_news', ['ASYNC_LIST', 'UPLOAD_ITEM']),

        // resetEdit() {
        //     this.doStoreReset();
        // },
        goEditPage() {
            this.setPageStatus(this.PAGE_STATUS.EDIT);
        },
        file_upload() {
            document.getElementById("input-fileup").click();
        },
        handleFiles(event) {
            const file = event.target.files;

            this.file = file[0];

            Debug.log("FILE:::", this.file);
        },
        inputFile(val) {
            this.file = val[0]
        },
        uploadFile() {
            // 빈값 체크
            if (!this.newsType.value ) {
                this.showMessage({ vc:this, message:'관리자에게 연락해주세요.' })
                return
            }
            if (!this.title) {
                this.showMessage({ vc:this, message:'제목을 입력하세요.' })
                return
            }
            if (!this.file.name) {
                this.showMessage({ vc:this, message:'파일을 첨부하세요.' })
                return
            }
            // 220727 meej 첨부 파일 확장자 체크
            if(this.file.name.split(".").pop()!=="pdf"){
                this.showMessage({ vc:this, message:'pdf 파일을 첨부하세요.' })
                return
            }
            
            // 파라미터
            const reader = new FileReader();
            reader.onloadend = () => {
                // use a regex to remove data url part
                const base64String = reader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');

                this.doUpload(base64String)
            };
            reader.readAsDataURL(this.file)

            
        },
        doUpload(dataStr) {
            let param =  { newsType : this.newsType.value,
                        fdata:dataStr, 
                        title:this.title
                }
               
            // 업로드
            this.UPLOAD_ITEM(param)
            .then(() => {
                this.showMessage({ vc:this, message:'등록되었습니다.' })
                this.title = ''
                this.file = null

                this.INIT_LIST()

                var params = {}
                params.newsType = this.newsType.value

                this.ASYNC_LIST(params)
            })
            .catch((err) => {
                Debug.log('error = ', err)
                this.showError({ vc:this, error:err })
            })
        }
    },
};
</script>
