<template>
    <article>
        <q-dialog v-model="updatePopup" persistent>
            <q-card style="min-width: 80%;min-height:90%;">
                <q-card-section>
                    <div class="col q-gutter-sm">
                        <div class="row">
                            <div class="q-pa-xs">
                                <q-select v-model="stockCode" :options="stockOptions" option-label="name" @filter="filterFn" input-debounce="0" dense outlined :hide-selected="hide_selected" :fill-input="hide_selected" use-input hide-dropdown-icon options-dense>
                                    <q-tooltip anchor="top middle" self="bottom middle" :offset="[5, 5]">한글로 검색 시, enter를 눌러주세요.</q-tooltip>
                                    <template v-slot:no-option><q-item><q-item-section class="text-grey">검색된 데이터가 없습니다.</q-item-section></q-item></template>
                                    <template v-slot:before><label class="i-label">종목검색</label></template>
                                </q-select>
                            </div>
                            <div class="q-pa-xs"><i-select v-model="stockDivision" :options="stockDivisionOptions" option-value="value" option-label="name" style="width:120px;"/></div>
                            <div class="q-pa-xs"><i-select v-model="companyCode" :options="companyCodeOptions" option-value="code" option-label="name" style="width:140px;"/></div>
                            <div class="q-pa-xs"><i-select v-model="ifrs" :options="ifrsOptions" option-value="value" option-label="name" style="width:120px;"/></div>
                            <div class="q-pa-xs"><i-select v-model="unit" :options="unitOptions" option-value="value" option-label="name" style="width:120px;"/></div>
                            <div class="q-pa-xs row"><q-checkbox label="보고서 날짜 수동 입력" v-model="isTimeManual" size="xs" /></div>
                            <div v-if="isTimeManual" class="q-pa-xs"><i-datetime class="col-sm-6" v-model="reportDate" date /></div>
                            <div class="q-ma-xs"><p class="q-ma-sm" style="color: grey;"> ( ※ 입력 후, 왼쪽 Control 키 누르면 입력창에 반영됩니다.)</p></div>
                        </div>
                        <div class="q-ma-xs"><q-input class="full-width" dense outlined v-model="textLine" @keyup="textLineHandler" type="textarea" :input-style="{height:'70px'}" placeholder="Excel Header를 제외한 Data를 복사한 후 붙여넣기 하세요." /></div>
                        <div class="q-pa-xs">
                            <q-table flat bordered dense separator="cell" class="i-table" :columns="columns" :data="list" row-key="index" :rows-per-page-options="[0]" hide-pagination style="height:100%;">
                                <template v-slot:header="props">
                                    <q-tr :props="props">
                                        <q-th v-for="col in props.cols" :key="col.name" :props="props">
                                            <div v-if="col.name == 'name'">{{ col.label }}</div>
                                            <div v-else class="q-mx-auto select" style="display:table;"><i-select v-model="yearList[col.name]" :options="yearOptions" option-value="value" option-label="name" style="width:80px;"/></div>
                                        </q-th>
                                    </q-tr>
                                </template>
                                <template v-slot:body="props">
                                    <q-tr :props="props">
                                        <q-td v-for="col in props.cols" :key="col.name" :props="props">
                                            <div v-if="col.name == 'name'">{{ props.row[col.name] }}</div>
                                            <div v-else class="q-mx-auto number" style="display:table;"><q-input v-model="props.row[col.name]" type="number" dense outlined input-class="text-right"/></div>
                                        </q-td>
                                    </q-tr>
                                </template>
                            </q-table>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right" class="text-primary">
                    <div class="q-pa-xs"><q-btn color="white" text-color="black" label="닫기" v-close-popup/></div>
                    <div class="q-pa-xs"><q-btn label="등록" @click="getUpdatePopupSave()"/></div>
                </q-card-actions>
            </q-card>
        </q-dialog>
    </article>
</template>
<script>
import DateTime from '@/components/common/DateTime'
import CONSTANTS from '@/constants';
import { nvl, isEmpty } from '@/util';
import Select from "@/components/common/Select";
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Consensus-List',
    props: {
        hide_selected: {
            type: Boolean,
            default: true
        }
    },
    components: {
        'i-select' : Select,
        'i-datetime': DateTime,
    },
    data () {
        return {
            updatePopup: false,
            stockList: [],
            stockCode: '',
            stockOptions: [],
            yearOptions: [{'value':'', 'name':'연도'}],
            companyCode: {'code':'', 'name':'증권사'},
            companyCodeOptions: [{'code':'', 'name':'증권사'}],
            ifrs: {'value':'', 'name':'연결/별도'},
            ifrsOptions: [{'value':'', 'name':'연결/별도'}].concat(CONSTANTS.COM_IFRS),
            unit: {'value':'', 'name':'단위'},
            unitOptions: [{'value':'', 'name':'단위'}].concat(CONSTANTS.COM_UNIT),
            stockDivision: {'value':'', 'name':'구분'},
            stockDivisionOptions: [{'value':'', 'name':'구분'}].concat(CONSTANTS.COM_STOCK_DIV),
            isTimeManual: false,
            reportDate: '',
            colList: {
                'GENERAL' :
                [
                    { name:'매출액', code:'sales'},
                    { name:'영업이익', code:'operatingIncome'},
                    { name:'세전이익', code:'pretaxIncome'},
                    { name:'당기순이익', code:'netIncome'},
                    { name:'지배주주 순이익', code:'ciNetIncome'},
                    { name:'자산총계', code:'totalAsset'},
                    { name:'부채총계', code:'totalLiabilities'},
                    { name:'자본총계', code:'totalSTEquity'},
                    { name:'지배주주 지분 자본총계', code:'ciTotalSTEquity'},
                    { name:'영업현금흐름', code:'operatingCashFlow'},
                    { name:'투자현금흐름', code:'investCashFlow'},
                    { name:'재무현금흐름', code:'finacialCashFlow'},
                    { name:'총현금흐름', code:'totalCashFlow'},
                    { name:'순차입금', code:'netDebt'},
                    { name:'EBITDA', code:'ebitda'},
                    { name:'자산상각비', code:'assetAmort'},
                    { name:'주당 배당금(원)', code:'dps'}
                ],
                'BANK' :
                [
                    { name:'영업이익', code:'operatingIncome'},
                    { name:'세전이익', code:'pretaxIncome'},
                    { name:'당기순이익', code:'netIncome'},
                    { name:'지배주주 순이익', code:'ciNetIncome'},
                    { name:'자산총계', code:'totalAsset'},
                    { name:'부채총계', code:'totalLiabilities'},
                    { name:'자본총계', code:'totalSTEquity'},
                    { name:'지배주주 지분 자본총계', code:'ciTotalSTEquity'},
                    { name:'주당 배당금(원)', code:'dps'}
                ],
                'STOCK' :
                    [
                        { name:'영업이익', code:'operatingIncome'},
                        { name:'세전이익', code:'pretaxIncome'},
                        { name:'당기순이익', code:'netIncome'},
                        { name:'지배주주 순이익', code:'ciNetIncome'},
                        { name:'자산총계', code:'totalAsset'},
                        { name:'부채총계', code:'totalLiabilities'},
                        { name:'자본총계', code:'totalSTEquity'},
                        { name:'지배주주 지분 자본총계', code:'ciTotalSTEquity'},
                        { name:'주당 배당금(원)', code:'dps'}
                    ],
                'INSURANCE' :
                [
                    { name:'영업이익', code:'operatingIncome'},
                    { name:'세전이익', code:'pretaxIncome'},
                    { name:'당기순이익', code:'netIncome'},
                    { name:'지배주주 순이익', code:'ciNetIncome'},
                    { name:'자산총계', code:'totalAsset'},
                    { name:'부채총계', code:'totalLiabilities'},
                    { name:'자본총계', code:'totalSTEquity'},
                    { name:'지배주주 지분 자본총계', code:'ciTotalSTEquity'},
                    { name:'손해율', code:'lossRate'},
                    { name:'사업비율', code:'expenseRate'},
                    { name:'운용자산이익률(투자이익률)', code:'aumRate'},
                    { name:'주당 배당금(원)', code:'dps'},
                    // 20231116 cms추가
                    { name:'CSM', code:'csm'}
                ]
            },
            yearList: {
                value1: "연도",
                value2: "연도",
                value3: "연도",
                value4: "연도",
                value5: "연도",
            },
            columns: [
                    { headerStyle:'text-align:center;', style:'width:*;', name:'name',  label: '구분', align: 'center', field:'name'},
                    { headerStyle:'text-align:center;', style:'width:16%;', name:'value1', label: '년도', align: 'center', field:'value1'},
                    { headerStyle:'text-align:center;', style:'width:16%;', name:'value2', label: '년도', align: 'center', field:'value2'},
                    { headerStyle:'text-align:center;', style:'width:16%;', name:'value3', label: '년도', align: 'center', field:'value3'},
                    { headerStyle:'text-align:center;', style:'width:16%;', name:'value4', label: '년도', align: 'center', field:'value4'},
                    { headerStyle:'text-align:center;', style:'width:16%;', name:'value5', label: '년도', align: 'center', field:'value5'}
                ],
            list: [],
            textLine: '',
        }
    },
    watch: {
        stockDivision() {
            this.initList()
        }
    },
    computed : {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            stockItems : state => state.resource.stockItems
        })
    },
    created () {
        this.getInit();
    },
    methods: {
        ...mapMutations('manage', ['SET_DATA', 'INIT_DATA_VIEW', 'SET_DATA_VIEW']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),

        initList() {
            this.list = []

            if (this.stockDivision.value === '') return

            this.colList[this.stockDivision.value].forEach(row => {
                let arr = {};
                arr['name'] = row['name'];
                arr['code'] = row['code'];
                for(let i=1; i <= 5; i++) {
                    arr['value'+i] = '';
                }
                this.list.push(arr);
            });
        },
        initData() {
            this.stockCode = '';
            this.textLine = '';
            this.list = [];
            // 221011 meej reportDate 초기화 분리
            // this.reportDate = ''

            /*
             ** 연도 초기화
             */
            this.yearList = {
                value1: "연도",
                value2: "연도",
                value3: "연도",
                value4: "연도",
                value5: "연도",
            }

            // this.companyCode = {'code':'', 'name':'증권사'};
            // this.ifrs = {'value':'', 'name':'연결/별도'};
            // this.unit = {'value':'', 'name':'단위'};
            // this.stockDivision = {'value':'', 'name':'구분'};
        },

        //수정팝업
        getUpdatePopup() {
            this.initData()
            // 221011 meej reportDate 초기화 분리
            this.reportDate = ''

            this.updatePopup = true;
        },
        //수정팝업
        getUpdatePopupSave() {
            if (this.stockCode == null || this.stockCode === "") {
                this.showMessage({ vc:this, message:'종목을 선택하세요.' });
                return;
            }
            if (this.stockDivision.value === "") {
                this.showMessage({ vc:this, message:'구분을 선택하세요.' });
                return;
            }
            if (this.companyCode.code === "") {
                this.showMessage({ vc:this, message:'증권사를 선택하세요.' });
                return;
            }
            if (this.ifrs.value === "") {
                this.showMessage({ vc:this, message:'연결/별도를 선택하세요.' });
                return;
            }
            if (this.unit.value === "") {
                this.showMessage({ vc:this, message:'단위를 선택하세요.' });
                return;
            }
            this.showAlert({ vc:this, message:'<span style="color:#1976D2;">저장</span> 하시겠습니까?' }).then(()=>{
                // this.updatePopup = false;
                let sStockCode = this.stockCode.code;
                let sOptions = 'stockName:' + this.stockItems.filter(function (entry){ return entry.code === sStockCode; })[0].name;
                let ds_list = [];
                for(let i=1; i <= 5; i++) {
                    if(this.yearList['value'+i].value !== '' && this.yearList['value'+i].value !== undefined){
                        let arr = {};
                        arr['type'] = 'ESTIMATED';
                        arr['year'] = this.yearList['value'+i].value;
                        arr['updateTime'] = '';
                        arr['quarter'] = '';
                        arr['stockCode'] = sStockCode;
                        arr['stockDivision'] = this.stockDivision.value;
                        arr['companyCode'] = this.companyCode.code;
                        arr['ifrs'] = this.ifrs.value;
                        arr['unit'] = this.unit.value;
                        arr['options'] = sOptions;
                        this.list.forEach(row => {
                            if (row['value'+i] === "") {
                                /* 
                                    220506 meej 입력하지 않을 경우 null로 넣어줌
                                 */
                                row['value'+i] = null
                            }
                            arr[row['code']] = row['value'+i];
                        });
                        ds_list.push(arr);
                    }
                }
                let param = {
                    ds_list,
                    'CALL_API_URL': '/consensus/save'
                }
                // 날짜, 시간 수동으로 입력한 경우 추가
                if(this.isTimeManual) {
                    param.reportDate = this.reportDate
                }
                console.log("param=", param)

                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'저장 되었습니다.' });
                    this.initData()
                    this.$parent.getList();
                }).catch((err)=>{
                    this.showError({ vc:this, error:err });
                });
            }).catch(() => { });
        },
        /**
         * 검색관련
         */
        getInit(){
            // 221011 meej 추정컨센서스 연도 설정 변경
            let iYear = Number(new Date().getFullYear())+4;
            for(let i=iYear; i >= iYear-7; i--){
                this.yearOptions.push({
                    value: i+'',
                    name: i+''
                });
            }
            let list = [];
            try {
                list = JSON.parse(this.stockItems);
            } catch (e) {
                list = this.stockItems;
            }
            let ds_list = [];
            list.forEach(row => {
                ds_list.push({
                    code : row['code'],
                    name : row['name'] + ' (' + row['code'] + ')'
                });
            });
            this.stockList = ds_list;
            let param = {
                newsType: 'STOCK_CONSENSUS_COMPANY',
                CALL_API_URL: '/stock/company'
            }
            this.GET_CALL(param).then((data) => {
                this.companyCodeOptions = [{'code':'', 'name':'증권사'}].concat(data.items);
                // console.log('this.companyCodeOptions', this.companyCodeOptions)

                // 22.12.22 SUYE 담당자별 증권사 순서 조정 
                const userId = localStorage.getItem('userId') 
                const staffOrders = [ // 아이디별 담당증권사
                    // {id: 'dongdong2', code0: '030', code1:'002', code2: '003',}, // test
                    {id: 'dydrhkd333', code0: '030', code1:'050', code2: '009', code3: '022'}, // dydrhkd333
                    {id: 'woonge92', code0: '002', code1:'003', code2: '021', code3: '006'}, // woonge92 
                    {id: 'kyk2039', code0: '004', code1:'025', code2: '071', code3: '063', code4: '023'}, // kyk2039
                    {id: 'jb319', code0: '012', code1:'005', code2: '013', code3: '301'}, // jb319
                    {id: 'lucas', code0: '017', code1:'056', code2: '068', code3: '010', 
                    code4: '004', code5: '002', code6:'031', code7: '022', code8: '013'}, // lucas
                ]
                // 담당 증권사코드로 우선정렬
                staffOrders.forEach(e => {
                    if(e.id == userId){
                        this.companyCodeOptions.sort((a, b) => {

                            if(a.code == '' || b.code == '') return 1 // default 증권사

                            if(a.code == e.code0 && b.code !== e.code0) return -1
                            if(a.code !== e.code0 && b.code == e.code0) return 1
            
                            if(a.code == e.code1 && b.code !== e.code1) return -1
                            if(a.code !== e.code1 && b.code == e.code1) return 1

                            if(a.code == e.code2 && b.code !== e.code2) return -1
                            if(a.code !== e.code2 && b.code == e.code2) return 1

                            if(a.code == e.code3 && b.code !== e.code3) return -1
                            if(a.code !== e.code3 && b.code == e.code3) return 1

                            if(e.code4 || e.code5 || e.code6 || e.code7 || e.code8){

                                if(a.code == e.code4 && b.code !== e.code4) return -1
                                if(a.code !== e.code4 && b.code == e.code4) return 1
            
                                if(a.code == e.code5 && b.code !== e.code5) return -1
                                if(a.code !== e.code5 && b.code == e.code5) return 1
            
                                if(a.code == e.code6 && b.code !== e.code6) return -1
                                if(a.code !== e.code6 && b.code == e.code6) return 1

                                if(a.code == e.code7 && b.code !== e.code7) return -1
                                if(a.code !== e.code7 && b.code == e.code7) return 1
            
                                if(a.code == e.code8 && b.code !== e.code8) return -1
                                if(a.code !== e.code8 && b.code == e.code8) return 1
                            }

                        });
                    }
                });
                        
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        /**
         * 검색관련
         */
        filterFn (val, update, abort) {
            // 종목검색 시, 1자리 이하일 경우 검색되지 않음.
            if (val.length < 2) {
                if (val.length == 0) {
                    this.stockCode = '';
                }
                abort();
                return;
            }
            update(() => {
                const needle = val.toLowerCase();
                this.stockOptions = this.stockList.filter(
                    v => v.name.toLowerCase().indexOf(needle) > -1 || v.code.indexOf(val) > -1
                )
            })
        },
        /**
         * excel 처리 Handler
         */
        textLineHandler(e) {
            this.initList()

            const bufferArray = Object.assign([], this.list)
            console.log("what is e=", e)
            console.log("this.list=", this.list)
            console.log("bufferArray=", bufferArray)

            if (e.code == 'KeyV' || e.code == 'ControlLeft') {
                let iRow = -1;
                let bStrRow = false;
                let bYearRow = true;
                let row = this.textLine.split('\n');
                // 22.09.19 suye 추정컨센서스 입력값 () => - 변경 
                if(this.textLine.includes('(' || ')')) {
                    // console.warn('included!! ')
                    this.textLine = this.textLine.replaceAll('(', '-').replaceAll(')', '')
                }

                for(let i=0; i < row.length; i++) {
                    if (row[i] == null || row[i].length == 0){ continue; }
                    let cols = row[i].split('\t');

                    let iCnt = 1;
                    for(let k=0; k < cols.length; k++) {
                        let val = nvl(cols[k]).replace(/,/gi,"").trim();

                        if(!isNaN(val) && !isEmpty(val)){
                            if(bYearRow){
                                this.yearList['value'+iCnt] = this.yearOptions.filter(function (entry){ return entry.value === val; })[0];
                                bStrRow = true;
                            }else{
                                if(bufferArray[iRow] != null){
                                    bufferArray[iRow]['value'+iCnt] = val;
                                }
                            }
                            iCnt++;
                        }
                    }
                    if(bStrRow){
                        bYearRow = false;
                        iRow++;
                    }
                }

                console.log("bufferArray=", bufferArray)
                console.log("after List=", this.list)

            }

            this.list = [ ...bufferArray ]

            /**
             * 선택한 연도는 제외하는 부분
             * 기존 선택한 날짜 사라지는 문제가 있어서 추후 적용
             * 211006 이민수 작성
             */
            const getYearList = []
            const getYearOptionList = []

            for (const item of Object.keys(this.yearList)) {
                const value = this.yearList[item].value

                // 숫자형식일 때만 List push
                if(!isNaN(Number.parseInt(value))){
                    getYearList.push(value)
                }
            }

            for (const item of this.yearOptions) {
                const value = item.value

                // 숫자형식일 때만 List push
                if(!isNaN(Number.parseInt(value))){
                    getYearOptionList.push(value)
                }
            }

            const notChooseYearList = getYearOptionList.filter((item) => !getYearList.includes(item))

            const newYearOptions = []
            newYearOptions.push({"name" : "연도", "value" : ""})

            for(const year of notChooseYearList) {
                newYearOptions.push({"name" : year, "value" : year})
            }

            // this.yearOptions = newYearOptions
        }
    }
}

</script>
<style type="text/css">
.number .q-field__control{ padding:0 !important; height: 25px !important; }
.select .q-field{ height: 25px !important; min-height: 25px !important; }
.select .q-field__control{ min-height: 25px !important; background-color:#ffffff;}
.select .q-field__append{ height: 25px !important; }
.select .q-field__native{ padding:3px 0 0 0 !important; min-height: 25px !important; }
/* 221012 meej 가로 scroll 나오게 설정 */
.q-table__middle {
    max-width: 100%;
    overflow-y:scroll !important;
    overflow-x:scroll !important;
}
</style>
