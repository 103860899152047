<template>
    <div>
        <div class="title q-py-md">
            <h3 class="text-center"> {{ title }} </h3>
        </div>
        <div v-show="!registered" class="q-pa-md q-mx-auto" style="max-width: 400px" >
            <q-form class="q-gutter-md signup">
                <!-- <q-input filled square v-model.trim="uid" id="uid" type="text"
                    placeholder="아이디" required autofocus :disabled="registered" />
                <q-input filled square v-model.trim="password" id="password" type="password"
                    placeholder="비밀번호" required :disabled="registered" />
                <q-input filled square v-model.trim="password_confirm" id="password-confirm" type="password"
                    placeholder="비밀번호 확인" required :disabled="registered" />
                <q-input filled square v-model.trim="name" id="name" type="name"
                    placeholder="이름" required :disabled="registered" /> -->
                <i-input v-model.trim="uid" id="uid" type="text"
                    label="아이디" required autofocus :disabled="registered" />
                <i-input v-model.trim="password" id="password" type="password"
                    label="비밀번호" required :disabled="registered" />
                <i-input v-model.trim="password_confirm" id="password-confirm" type="password"
                    label="비밀번호 확인" required :disabled="registered" />
                <i-input v-model.trim="name" id="name" type="name"
                    label="이름" required :disabled="registered" />
                <div class="text-negative">
                    <p class="validation"> {{ validation.firstError('uid') }} </p>
                    <p class="validation" v-if="!validation.firstError('uid')" >
                        {{ validation.firstError('password') }}
                    </p>
                    <p class="validation"
                        v-if="!validation.firstError('uid') && !validation.firstError('password')">
                        {{ validation.firstError('password_confirm') }}
                    </p>
                    <p class="validation"
                        v-if="!validation.firstError('uid') && !validation.firstError('password') && !validation.firstError('password_confirm')">
                        {{ validation.firstError('name') }}
                    </p>
                </div>
                <div>
                    <q-btn unelevated class="full-width" :disabled="registered" @click="submit" label="등록" color="dark"/>
                </div>
            </q-form>
        </div>
        <q-card flat bordered v-show="registered" class="q-mt-lg q-pa-md q-mx-auto" style="max-width: 400px" >
            <q-chip outline color="teal" text-color="white"> {{ uid }} </q-chip>
            <div class="q-my-md">
                <p v-html="notiMessage"></p>
            </div>
            <q-btn unelevated class="full-width" @click="goLogin" label="로그인 " color="dark"/>
        </q-card>
    </div>
</template>
<script>
import CONSTANTS from '@/constants'

import Input from '@/components/common/Input'

import InfostockService from '../services/InfostockService'
import SimpleVueValidation from 'simple-vue-validator'
import { mapActions } from 'vuex'
import Debug from '../debug'

const Validator = SimpleVueValidation.Validator

export default {
    name: 'SignUp',
    components: {
        'i-input': Input
    },
    data () {
        return {
            title: CONSTANTS.MENU.SignUp,
            submitted: false,
            uid : '',
            name : '',
            password : '',
            password_confirm : '',
            registered: false,
            notiMessage: '신청이 완료되었습니다. <br>'
                        + '가입승인 처리 후, 사용이 가능합니다. <br>'
                        + '빠른 처리를 원하신다면 관리자에게 문의하시기 바랍니다. '
        }
    },
    validators: {
        uid: function(value) {
            Debug.log('validators uid')
            return Validator.value(value).required('ID를 입력하세요.');
        },
        name: function(value) {
            Debug.log('validators name')
            return Validator.value(value).required('이름을 입력하세요.');
        },
        password: function(value) {
            Debug.log('validators password')
            return Validator.value(value).required('비밀번호를 입력하세요.').minLength(5, '비밀번호는 5자리 이상 입력해주세요.');
        },
        'password_confirm, password': function (password_confirm, password) {
            if (this.submitted || this.validation.isTouched('password_confirm')) {
                return Validator.value(password_confirm).required('비밀번호를 입력하세요.').match(password,'비밀번호가 동일하지 않습니다.');
            }
        }
    },
    methods:{
        ...mapActions({
            push: 'history/push',
            replace: 'history/replace',
            showAlert: 'alert/showAlert',
            showError: 'alert/showError'
        }),
        submit: function () {

            this.submitted = true
            this.$validate()
            .then((success) => {
                if (success) {
                    this.signup()
                } else {
                    Debug.log('validation fail = ', this.validation.errors)
                    var error = this.validation.errors[0].message
                    this.showError({ vc:this, error:error })
                    this.submitted = false
                }
            })
            .catch((error) => {
                Debug.log('validation error = ', error)
                this.showError({ vc:this, error:error })
            })
        },
        signup: function () {
            let password = btoa(this.$CryptoJS.SHA256(this.password).toString())

            let data = {
                id: this.uid,
                name: this.name,
                password: password
            }
            InfostockService.signup(data)
            .then((response) => {
                if(response.success) {
                    this.registered = true
                }
            })
            .catch((error) => {
                Debug.log('error = ', error)

                this.showError({ vc:this, error:error })
            })
        },
        goLogin: function () {
            this.replace('login.htm')
        }
    }
}
</script>
<style>
</style>