<template>
    <div>
        <q-table
            flat
            bordered
            dense
            class="i-table"
            :class="{ 'no-data': list.length == 0 }"
            :columns="columns"
            :data="list"
            row-key="index"
            :rows-per-page-options="[0]"
            hide-bottom
            virtual-scroll
            :style="heightStyle"
            hide-pagination
            no-data-label="조회되는 데이터가 없습니다."
            :selected-rows-label="getSelectedString"
            selection="multiple"
            :selected.sync="selected"
        >
            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th v-if="needCheckBox" class="text-left">
                        <q-checkbox v-model="allCheck" size="xs" />
                    </q-th>
                    <q-th v-for="col in props.cols" :key="col.name" :props="props">
                        {{ col.label }}
                    </q-th>
                </q-tr>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props" @click="rowClick(props.row)">
                    <q-td v-if="needCheckBox" class="text-left">
                        <q-checkbox
                        v-model="props.row.checked"
                        @input="handleCheckBox(props.row)"
                        size="xs"
                        />
                    </q-td>
                    <q-td v-for="col in props.cols" :key="col.name" :props="props">
                        {{ col.value }}
                    </q-td>
                </q-tr>
            </template>
        </q-table>

    </div>
</template>
<script>
import debug from "@/debug";

// 페이지 처리 없는 단순 테이블
// 체크 박스 사용 여부

export default {
    name: "SimpleTable",
    props: {
        columns: {
            type: Array,
        },
        list: {
            type: Array,
        },
        heightStyle: {
            type: Object,
        },
        rows: {
            default: 1,
        },
        needCheckBox: {
            type: Boolean,
            default: true,
        },
    },
    data() {
    return {
        allCheck: false,
        selected: [],
    };
    },
    created() {
    },
    computed: {
    },
    methods: {
        getSelectedString() {
            debug.log("getSelectedString = ", this.selected.length);
            return this.selected.length === 0 ? "" : `${this.selected.length}개 선택`;
        },
        rowClick(item) {
            this.$emit("row-click", item)
        },
        handleCheckBox(item) {
            if (item.checked) {
            this.selected.push(item);
            } else {
                var index = this.selected.indexOf(item);
                if (index !== -1) {
                this.selected.splice(index, 1);
                }
            }
            this.$emit("check-event", this.selected);
        },
        parentClearCheck() {
            this.selected = [];
            this.list.forEach((i) => {
                i.checked = false;
            });
        //this.allCheck = false
        },
    },
    watch: {
        allCheck: function(val) {
            this.selected = [];
            if (val) {
                this.list.forEach( (i) => {
                    i.checked = val;
                })

                this.selected = this.list

            } else {
                this.list.forEach((i) => {
                    i.checked = false;
                });
            }
            this.$emit("check-event", this.selected);
            },
        list: function() {
            debug.log("table watch list!!!!!!!!!");
            this.allCheck = false;
            this.selected = [];
            this.list.forEach((i) => {
                i.checked = false;
            });
        },

    },
};
</script>
