<template>
    <article>
        <!-- 구분 -->
        <div v-show="newsType.value == 'BOARD_NOTICE'"
            class="flex justify-start q-pa-xs q-gutter-sm">
            <i-btn label="삭제" @click="deleteItems()" />
        </div>
        <!-- 목록 -->
        <i-table
            :columns="columns"
            :list="list"
            :nextKey="nextKey"
            @row-click="getItem"
            @next-click="getList()"
            @check-event="handleCheckList"
            :needCheckBox="newsType.value == 'BOARD_NOTICE' ?  true : false"
        />
    </article>
</template>

<script>
import Debug from '@/debug'
import { FormatDate } from '@/util'

import Button from '@/components/common/Button'
import Table from '@/components/common/Table'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    components: {
        'i-btn': Button,
        'i-table': Table,
    },
    props: {
        newsType: {
            type: Object,
            default: null,
        },
        noteVisible: {
            type: Boolean,
        },
    },
    data() {
        return {
            columns: [],
            selected: [],
            note: Boolean,
        }
    },
    created() {},
    mounted() {
        Debug.log('mounted newsType= ', this.newsType)
        this.INIT_LIST()
        this.getList()
        this.setColunms()
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
        }),
        ...mapState('manage', ['list', 'nextKey']),

        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isEditPage',
            'isRegisterPage',
        ]),
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('manage', ['INIT_LIST', 'SET_ITEM', 'SET_ITEMS', 'SET_LIST']),

        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions('manage', ['GET_BOARD_LIST', 'DELETE_ITEMS', 'GET_DETAIL']),

        // 목록 조회하기
        getList() {
            this.setPageStatus(this.PAGE_STATUS.LIST)
            /* 
                220512 meej 공지게시판 페이징 오류 수정
            */
            let param = {
                newsType: this.newsType.value,
                nextKey : this.nextKey
            }
            if(this.nextKey == null){
                this.INIT_LIST()
            }
            //   this.ASYNC_LIST(param);
            this.GET_BOARD_LIST(param)
            .then((data) => {
                /* 
                    220526 meej 조회결과가 있을 경우만 set해줌
                 */
                if(data.items.length > 0){
                    this.SET_LIST(data)
                    this.getItem(data.items[0])
                }
            })
            .catch((error) => {
                this.showError({ vc:this, error:error })
            })
        },
        // 상세 조회
        getItem(item) {
            if (this.newsType.value == 'BOARD_ONE_ON_ONE') {
                this.getItem_ONE_ON_ONE(item);
            } else if (this.newsType.value == 'BOARD_FAKE_NEWS_CHECK') {
                this.getItem_FAKE_NEWS_CHECK(item);
            } else {
                this.setPageStatus(this.PAGE_STATUS.DETAIL)
                this.SET_ITEM(item)
            }
        },
        // 1:1 문의 상세 조회
        getItem_ONE_ON_ONE(item) {
            var params = {}
            params.newsType = this.newsType.value
            params.id = item.id
            this.GET_DETAIL(params)
            .then((data) => {
                Debug.log('GET_DETAIL data', data)
                this.setPageStatus(this.PAGE_STATUS.DETAIL)
                this.SET_ITEMS(data.items)
            })
            .catch((err) => {
                Debug.log('error = ', err)
                var error = err.message
                this.showError({ vc:this, error:error })
            })
        },
        // 가짜 뉴스 체크 상세 조회
        getItem_FAKE_NEWS_CHECK(item) {
            var params = {}
            params.newsType = this.newsType.value
            params.id = item.id
            this.GET_DETAIL(params)
            .then((data) => {
                Debug.log('GET_DETAIL data', data)
                this.setPageStatus(this.PAGE_STATUS.DETAIL)
                this.SET_ITEMS(data.items)
            })
            .catch((err) => {
                Debug.log('error = ', err)
                var error = err.message
                this.showError({ vc:this, error:error })
            })
        },
        handleCheckList(list) {
            this.selected = list
        },
        // 삭제하기
        deleteItems() {
            if (this.selected.length == 0) {
                this.showMessage({
                    vc: this,
                    message: '삭제할 항목을 선택해주세요.',
                })
                return
            } else {
                this.showAlert({
                    vc: this,
                    message: '선택한 항목을 삭제 하시겠습니까?',
                }).then(() => {
                    this.doDelete()
                })
            }
        },
        doDelete() {
            var params = {}
            params.ids = this.selected.map( x => x.id ).join(',')
            params.force = 1
            this.DELETE_ITEMS(params)
            .then(() => {
                this.showMessage({ vc:this, message:'삭제되었습니다.' })
                this.INIT_LIST()
                this.getList()
            })
            .catch((err) => {
                Debug.log('error = ', err)
                var error = err.message
                this.showError({ vc:this, error:error })
            })
        },
        setColunms() {
            switch (this.newsType.value) {
                case 'BOARD_NOTICE':
                    // 공지사항 - 등록일자, 중요도, 제목
                    this.columns = [
                        {
                            name: 'createTime',
                            label: '등록일자',
                            align: 'left',
                            field: (row) => FormatDate(row.createTime),
                        },
                        {
                            name: 'flag',
                            label: '중요도',
                            align: 'left',
                            field: (row) => {
                                return row.flag.label
                            }
                        },
                        {
                            name: 'writer',
                            label: '작성자',
                            align: 'left',
                            field: (row) => {
                                return row.writer
                            }
                        },
                        {
                            name: 'title',
                            label: '제목',
                            align: 'left',
                            field: (row) => row.title,
                        },
                    ]
                    break
                case 'BOARD_ONE_ON_ONE':
                    // 1:1 문의게시판 - 등록일자, 제목, 작성자
                    // 220722 meej 내용 길어질경우 중간에 짤리는 오류 수정, style 추가
                    this.columns = [
                        {
                            name: 'createTime',
                            label: '등록일자',
                            align: 'left',
                            field: (row) => FormatDate(row.createTime),
                            style: "width:10%;"
                        },
                        {
                            name: 'flag',
                            label: '상태',
                            align: 'left',
                            field: (row) => row.flag.label,
                            style: "width:10%;"
                        },
                        {
                            name: 'title',
                            label: '제목',
                            align: 'left',
                            field: (row) => row.title,
                            style: "word-break:break-all; width:60%;white-space: normal;"
                        },
                        {
                            name: 'writer',
                            label: '작성자',
                            align: 'left',
                            field: (row) => row.writer,
                            style: "word-break:break-all; width:20%;white-space: normal;"
                        },
                    ]
                    break
                case 'BOARD_DEPOSIT':
                    // 입금자 게시판 - 등록일자, 상태, 입금자명, 현금영수증 타입, 현금영수증 번호
                    this.columns = [
                        {
                            name: 'createTime',
                            label: '등록일자',
                            align: 'left',
                            field: (row) => FormatDate(row.createTime),
                        },
                        {
                            name: 'flag',
                            label: '상태',
                            align: 'left',
                            field: (row) => row.flag.label,
                        },
                        {
                            name: 'DEPOSITOR',
                            label: '입금자명',
                            align: 'left',
                            field: (row) => row.DEPOSITOR,
                        },
                        {
                            name: 'ISSUE_TYPE',
                            label: '현금영수증 타입',
                            align: 'left',
                            field: (row) => row.ISSUE_TYPE,
                        },
                        {
                            name: 'ISSUE_NUMBER',
                            label: '현금영수증 번호',
                            align: 'left',
                            field: (row) => row.ISSUE_NUMBER,
                        },
                    ]
                    break
                case 'BOARD_FAKE_NEWS_CHECK':
                    // 가짜 뉴스 체크 게시판 - 등록일자, 상태, 제목, 작성자
                    // 220722 meej 내용 길어질경우 중간에 짤리는 오류 수정, style 추가
                    this.columns = [
                        {
                            name: 'createTime',
                            label: '등록일자',
                            align: 'left',
                            field: (row) => FormatDate(row.createTime),
                            style: "width:10%;"
                        },
                        {
                            name: 'flag',
                            label: '상태',
                            align: 'left',
                            field: (row) => row.flag.label,
                            style: "width:10%;"
                        },
                        {
                            name: 'title',
                            label: '제목',
                            align: 'left',
                            field: (row) => row.title,
                            style: "word-break:break-all; width:60%;white-space: normal;"
                        },
                        {
                            name: 'writer',
                            label: '작성자',
                            align: 'left',
                            field: (row) => row.writer,
                            style: "word-break:break-all; width:20%;white-space: normal;"
                        },
                    ]
                    break
            }
        },
        goRegisterPage() {
            this.setPageStatus(this.PAGE_STATUS.REGISTER)
        },
    },
    watch: {
        newsType(val, old) {
            if (val != old) {
                this.INIT_LIST()
                this.getList()
                this.setColunms()
            }
        },
    },
}
</script>