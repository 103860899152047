<template>
    <section class="row">
        <div class="col-6 q-pa-md">
            <i-list
                :newsType="newsType"
                :showFlag="showFlag"
                @event-check="selectItem"
            />
        </div>
        <i-content
            class="col-6 q-pa-md"
            :newsType="newsType"
            :showFlag="showFlag"
        />
    </section>
</template>
<script>
import CONSTANTS from "@/constants";
import List from "@/components/market/List.vue";
import Content from "@/components/market/market-us-theme/Content.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "MarketUSTheme",
    components: {
        "i-list": List,
        "i-content": Content,
    },
    data() {
        return {
            newsType: {
                value: "MARKET_FLASH_HTS_US_THEME",
                name: "미국시장 테마시황",
            },
            showFlag: CONSTANTS.MARKETNEWS_TYPE[0],
            selected: [],
        };
    },
    created() {
        this.INIT_LIST();
    },
    computed: {
        ...mapGetters("page", [
            "isListPage",
            "isDetailPage",
            "isFlashPage",
            "isRegisterPage",
            "isFlashPage",
            "isEditPage",
        ]),
    },
    methods: {
        ...mapMutations("market_news", ["INIT_LIST"]),
        // ...mapActions( 'market_news', ['ASYNC_LIST', 'DELETE_ITEMS']),

        selectItem(list) {
            this.selected = list;
        },
        // deleteItems() {
        //     if (this.selected.length == 0) {
        //         this.showMessage({ vc:this, message:'삭제할 항목을 선택해주세요.' })
        //         return
        //     }
        //     var params = {}
        //     params.ids = this.selected.map( x => x.id ).join(',')
        //     params.force = 1

        //     this.DELETE_ITEMS(params)
        //     .then(() => {
        //         this.showMessage({ vc:this, message:'삭제되었습니다.' })
        //         this.INIT_LIST()
        //         this.getList()
        //     })
        //     .catch((err) => {
        //         var error = err.message
        //         this.showError({ vc:this, error:error })
        //     })
        // },
        // 시황 목록 조회하기
        getList() {
            //this.setPageStatus(this.PAGE_STATUS.LIST)

            var params = {
                newsType: this.newsType.value,
                showFlag: this.showFlag.value,
            };

            this.ASYNC_LIST(params);
        },
    },
};
</script>
