<template>
    <article>
        <q-card>
            <q-card-section>
                <div class="col q-gutter-sm">
                    <div v-if="isDetailPage"  > 
                        <div class="row q-pa-xs" >
                            
                            <div class="col-10" style="width:80%" >
                                <div class="row">
                                <i-datetime
                                    v-model="sendTime"
                                    date
                                    dateLabel="전송일자"
                                    time
                                    timeLabel="전송시간"
                                />
                                <div class="row">
                                    <div >
                                        <i-stock-search @get="getStock" label="종목코드" />
                                    </div>
                                    <div class="flex justify-start">
                                        <q-chip v-for="(item,index) in selectedStocks" :key="'stock'+index"
                                            outline square color="black" text-color="white" size="sm" removable @remove="remove(index)">
                                            {{ item.name }} ( {{ item.code }} )
                                        </q-chip>
                                    </div>
                                </div>
                                </div>
                            </div>
                            
                            <div class="col" style="width:10%" >
                                <i-btn label="뉴스속보" @click="makeFlash" />
                            </div>
                        </div>
                        <p style="text-align:left;">주요이슈 미리보기</p>
                        <div style="height:580px">
                        <p class="q-pa-xs q-ma-xs "
                            style="text-align:left; overflow-y:scroll; width:100%; height:100%; background-color:white; border:1px solid; "
                            v-html="content"
                        />
                        </div>
                    </div>
                    <div v-else>
                        <p class="q-ma-xs row"> ※ 링크 수정 시, 해당 항목 드래그 후 Control + K 누르시면 링크정보 수정 가능합니다.
                            추가로 타겟 탭에서 새 창으로 하시면 링크 클릭 시, 새 창에서 열리게 됩니다.</p>
                        
                        <!-- 개장전/개장후 라디오버튼 -->
                        <div style="display:none;">
                            <input type="radio" v-model="radio" name="flag" value="B" :checked="radio == 'B' ? checked : ''">
                            <label for="개장전"> 개장전 </label>
                            <input type="radio" v-model="radio" name="flag" value="A" :checked="radio == 'A' ? checked : ''">
                            <label for="장마감"> 장마감 </label>
                        </div>

                        <div class="q-ma-xs">
                            <q-input
                                class="full-width"
                                dense
                                outlined
                                v-model="textLine"
                                @keyup="textLineHandler"
                                type="textarea"
                                :input-style="{ height: '70px' }"
                                placeholder="    Data를 복사한 후 붙여넣기 하세요."
                            />
                            <div v-if="!isDone && inputcontent && content" style="color:cadetblue;">기사를 불러오는 중입니다...</div>
                            <div v-if="isDone && notice"> {{ notice }} 
                                <!-- 기사생성 버튼 -->
                                <q-btn
                                    v-if="inputcontent && content"
                                    flat
                                    color="primary" 
                                    label="👉🏻기사생성"
                                    @click="onClickNews(newsList)"
                                    dense
                                />
                            </div>
                        </div>

                        <div class="q-pa-xs">
                            <i-editor :value="content" :height="390" @input-html="inputcontent"/>
                        </div>
                    </div>
                </div>
                <!-- <div class="q-pa-xs" style="text-align:left;"><q-checkbox v-model="check" dense label="바로 홈페이지 게시" v-show="item.id == null"/></div> -->
            </q-card-section>
        </q-card>
        <!-- 하단 버튼 -->
        <div class="q-my-xs">
            
            <div v-if="isDetailPage" class="row full-width">
                <!-- <div class="col q-pa-xs" ><i-btn class="full-width" label="홈페이지 게시" @click="getCnfirm" /></div> -->
                <div class="col q-pa-xs" ><i-btn class="full-width" label="수정" @click="goEditPage" /></div>
            </div>
            <div class="row" v-else>

            <q-btn @click="getNaverDataButton()" class="full-width">
                <span style="color: black;">링크생성하기</span>
            </q-btn>

            <i-btn
                class="full-width"
                :label="buttonName"
                @click="getSave"
            />
            </div>
        </div>
    </article>
</template>
<script>
// import api from '../../../services/InfostockService'
import axios from "axios"
import Editor from '@/components/common/Editor'
import Button from '@/components/common/Button'
import DateTime from '@/components/common/DateTime'
import StockSearch from '@/components/common/StockSearch'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { today, isEmpty } from '@/util'
import debug from '@/debug'
import https from "https"

export default {
    name: 'Content',
    components: {
        'i-datetime': DateTime,
        'i-editor': Editor,
        'i-btn': Button,
        'i-stock-search': StockSearch,
    },
    data() {
        return {
            textLine: '',
            content: '',
            check: false,
            showDate: today(),
            selectedStocks: [],
            sendTime: null,
            isDone: false, // 기사api완료여부
            newsList: [],
            radio: null, // 개장전,장마감 구분값
            checked: 'checked',
            countSuccess: 0, // api도는 횟수
            notice: '', // 기사입력완료 메시지 
            date: '',
        }
    },
    computed: {
        ...mapState({
            role: (state) => state.session.role,
            team: (state) => state.session.team,
            userId: (state) => state.session.userId,
            PAGE_STATUS: (state) => state.page.status,
        }),
        ...mapState('manage', ['item']),
        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isRegisterPage',
            'isFlashPage',
            'isEditPage',
        ]),
        buttonName() {
            return (this.isEditPage? '수정하기':'등록하기')
        }
    },
    
    created() {
        this.showDate = today()
        this.INIT_DATA_VIEW()  
        this.radio = null
    },
    mounted() {
        // 22.11.01 SUYE 개장전,장마감 라디오버튼 디폴트값 설정
        const today = new Date()
        const hours = today.getHours() // 시간
        if(this.radio == null){
            if(hours < 13) this.radio = 'B' // 개장전
            else this.radio = 'A' // 개장후
            console.warn('hours?', hours, 'radio?', this.radio)
        } 

        if(this.isEditPage && !(this.inputcontent && this.content)) this.isDone = true
    },
    beforeMount() {
        
    },
   
    methods: {
        ...mapActions('alert', [
            'showError',
            'showMessage',
            'showAlert',
            'showDeleteAlert',
        ]),
        ...mapActions('flash', ['WRITE_FLASH','SET_PREVIEW', ]),
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('manage', ['INIT_DATA_VIEW']),
        ...mapActions('manage', ['GET_CALL']),

        // 네이버뉴스검색API 인풋 제목 html태그변환 함수 
        escapeCharacter(string){ 
            var replaceChar = [
                {reg : '&', replace: '&amp;'},
                {reg : '"', replace: '&quot;'},
                {reg : '£', replace: '&pound;'},
                {reg : '€', replace: '&euro;'},
                {reg : 'é', replace: '&eacute;'},
                {reg : '–', replace: '&ndash;'},
                {reg : '®', replace: '&reg;'},
                {reg : '™', replace: '&trade;'},
                {reg : '‘', replace: '&lsquo;'},
                {reg : '’', replace: '&rsquo;'},
                {reg : '“', replace: '&ldquo;'},
                {reg : '”', replace: '&rdquo;'},
                {reg : '#', replace: '&#35;'},
                {reg : '©', replace: '&copy;'},
                {reg : '@', replace: '&commat;'},
                {reg : '$', replace: '&dollar;'},
                {reg : '\\(', replace: '&#40;'},
                {reg : '\\)', replace: '&#41;'},
                {reg : '<', replace: '&lt;'},
                {reg : '>', replace: '&gt;'},
                {reg : '…', replace: '&hellip;'},
                {reg : '‑', replace: '&#45;'},
                {reg : "'", replace: '&#39;'},
                {reg : '\\*', replace: '&#42;'},
                {reg : ',', replace: '&sbquo;'}, 
                {reg : '·', replace: '&middot;'}, 
                {reg : '→', replace: '&rarr;'},
                {reg : '', replace: '&nbsp;'}, 
                {reg : '%', replace: '&#37;'}, 
                {reg : '↑', replace: '&uarr;'},    
                {reg : 'α', replace: '&alpha;'},      
                {reg : '↓', replace: '&darr;'}, 
                {reg : '...', replace: '&hellip;'}, 
                {reg : '+', replace: '&#43;'},   
                // {reg : '', replace: ''},   
            ];
            var s = string;

            replaceChar.forEach(obj => {
                var regEx = new RegExp(obj.replace+"(?!([^<]+)?>)", "g");
                s = s.replaceAll(regEx, obj.reg);
            });
            console.log("4")

            return s
        },
        resetValues(){
            this.isDone = false 
            this.countSuccess = 0
        },
        
        // 1. 엑셀 인풋 기사 제목 분리 함수 (*기준)
        titleSeparator(text){ 
            console.log("2")
            this.resetValues()
            console.log("3")
            const clientInfo = [                                
                                {id: 'MTsup0TsZ2hY45Rg3RKQ', secret: '5o3BV8i7rT'},
                                {id: 'oFwbuk6VPZR1GOdGLsPw', secret: 'BHhgI6JJiI'},
                                {id: '89vW0hLYWsQBeBhkv2Dn', secret: 'GCVgmTxE5F'},
                                {id: 'NgfUGYpoR9HG2d3uCllE', secret: 'W0afyVmcXP'},
                                {id: 'UxS0BRIhiyHcAVucsKFX', secret: 'hPRNiZxs1W'},
                                {id: 'CZsofQgrUoHI4_WsCEjP', secret: 'EMiCM42NFo'},
                                {id: 'agSK0RvpbHl8jl_i4WPC', secret: '0Pmr1cpnZ5'},
                                {id: 'Cj0liLqN9bjMkc_CnjEu', secret: 'PYwmg4l3qp'},
                                {id: 'XxmMPXY2n5c5S0uAFPYW', secret: '_UR4txAYZ5'},
                                {id: 'CVm1P8KI3XqNoxm3ACli', secret: 'LfxV21Kb14'},]

            if(text && text.trim().length > 2){
                if(text.includes('*')){
                    text = this.escapeCharacter(text) 
                    // console.log('text', text)
                    let split = text.trim().split('*')
                    let list = []

                    split.forEach(e => {
                        if(e.length > 0) {
                            e = e.replaceAll('<br/>', '').replaceAll('<br />', '').replaceAll('\n', '').replaceAll('<br>', '')
                            .replaceAll('<strong>', '').replaceAll('</strong>', '')  
                            .replaceAll('<b>', '').replaceAll('</b>', '')
                            .replaceAll('[정치/사회/국내 기타]', '').replaceAll('[경제/증시/부동산]', '')
                            .replaceAll('[기업/산업]', '').replaceAll('[국제/해외]', '')
                            .trim()
                            
                            // indexOf() 리스트 중 해당 되는 첫 값만 제거
                            if(e.indexOf('뉴욕증시,') === -1 
                            && e.indexOf('국제유가($,배럴),') === -1 
                            && e.indexOf('국제금($,온스),') === -1 
                            && e.indexOf('달러 index,') === -1 
                            && e.indexOf('역외환율(원/달러),') === -1 
                            && e.indexOf('유럽증시,') === -1 
                            && e.indexOf('국내증시,') === -1 
                            && e.indexOf('원/달러 환율,') === -1 
                            && e.indexOf('아시아 증시,') === -1 
                            ) { 
                                list.push(e.trim())
                            }
                        }
                    });
                    
                    list.filter(e => e !== '');
                 
                    if(list && list.length > 0){
                        const items = [...list];

                        // console.log('items len?', items.length)
                        console.warn('1. titleSeparator')
                        let cnt = 0
                        for (let idx = 0; idx < items.length; idx++) {
                            cnt++
                            if(cnt > 9) {cnt -= 10}
                            // console.log('cnt?', cnt)
                            console.log("5")
                            this.getPythonApi(items[idx], clientInfo[cnt].id, clientInfo[cnt].secret, items.length)
                        }
                    
                    }
                }
            }
        },
         // 2. 네이버기사검색 API 
         async getPythonApi(title, id, secret, len){
            console.log("6")
            const httpsAgent = new https.Agent({
                rejectUnauthorized: false,
                requestCert: false,
                agent: false,
            });
            const date = this.showDate+this.radio
                const params = {
                    title: title,
                    date: date,
                    id: id,
                    secret: secret,
                }
                console.log("7")
                // const uri = 'https://infostockindex.co.kr/crawling/news'
                const uri = 'https://141.164.42.96/crawling/news'
                // console.log('callApi param', params)
                await axios
                    .get(uri, {params}, 
                    {   
                        httpsAgent,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                        },
                    })
                    .then((response) => {
                        console.log("8")
                        if(response.data && response.data.msg){
                            // console.log('getPythonApi response=', response.data.msg)
                            if(response.data.msg == 'success') {
                                this.countSuccess += 1
                            } else {
                                console.log('getPythonApi err')
                            }
                            // console.log('len=', len, 'countSuccess=', this.countSuccess)
                            if(len == this.countSuccess){
                                console.warn('2.getPythonApi')
                                // this.getNaverData(date)
                                this.date = date
                                this.showMessage({ vc:this, message:'완료되었습니다.' });
                            }
                        } else {
                            console.log('getPythonApi err')
                            this.showMessage({ vc:this, message:'완료되지못했습니다.' });
                        }
                    })
                    .catch(e => {
                        console.log('error', e)
                        this.showMessage({ vc:this, message:'새로고침 후 다시 시도해주세요' });
                    })
        },

        // 3. 날짜기준 기사데이터 받아오기
        async getNaverData(date){
            console.log("날짜기준 기사데이터 받아오기")
            const params = {
                date: date,
            }
            // const uri = 'https://infostockindex.co.kr/crawling/naver'
            const uri = 'https://141.164.42.96/crawling/naver'
            // console.log('callApi param', params)
            this.newsList = []
            await axios
                .get(uri, {params}, { 
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                    },
                }).then(response => {
                    if(response.data && response.data.naver_news_list){
                        console.warn('3.getNaverData')
                        const setItems = new Set(response.data.naver_news_list)
                        const items = Array.from(setItems)
                        items.forEach(e => {
                            // console.log('ori=', e)
                            if(e.title.includes('\\/')){
                                e.title = e.title.replaceAll('\\/', '/').trim()
                                // console.log('changed=', e)
                            }
                            if(e.originallink.includes('\\/')){
                                e.originallink = e.originallink.replaceAll('\\/', '/').trim()
                            }
                            if(e.link.includes('\\/')){
                                e.link = e.link.replaceAll('\\/', '/').trim()
                            }
                            this.newsList.push(e)
                        });
                        // console.log('newsList=', this.newsList.length)
                        // this.checkNaverItems(this.newsList)
                        this.onClickNews(this.newsList)
                    } else {
                            console.log('getNaverData err')
                    }
                })
                .catch(e => {
                    console.log('error', e)
                    this.showMessage({ vc:this, message:'새로고침 후 다시 시도해주세요' });
                })
        },
        getNaverDataButton(){
            this.getNaverData(this.date)
        },
        // // 4. 인포스탁데일리 기사 체크
        // async checkNaverItems(list){
        //     console.warn('4.checkNaverItems')    
        //     let param = null        
        //     list.forEach((e, idx) => {
        //         if(e.originallink == ''){
        //             param = {
        //                 title: e.originaltitle,
        //             }
        //         } 
        //         this.getDailyNewsItems(param, idx)
        //     });

        //     this.onClickNews(this.newsList)
        // },

        // // daily api
        // async getDailyNewsItems(param, idx){
        //     if(param !== null){
        //         let link = ''
        //         await api.infostockDailyIrNews(param)
        //             .then((res) => {
        //                 if(res && res.data && res.data.items) {
        //                     const item = res.data.items
        //                     if(item.options && this.newsList[idx].link == ''){
        //                         const splits = item.options.split(',')
        //                         // console.log('splits', splits)
        //                         splits.forEach(e => {
        //                             if(e.indexOf('link:') !== -1){
        //                                 // console.log('e', e)
        //                                 link = e.split('link:')[1]
        //                                 // console.log('linkText', link)
        //                                 this.newsList[idx].title = item.title
        //                                 this.newsList[idx].link = link
        //                                 this.newsList[idx].originallink = link
        //                                 // console.log('newsList[idx]=', this.newsList[idx])
        //                             }
        //                         });
                        
        //                     }
        //                 } else {
        //                     this.newsList[idx].link = 'none' 
        //                 }
        //             }).catch((err) =>{
        //                 console.log('infostockDailyIrNews err', err)
        //                 this.showMessage({ vc:this, message:'새로고침 후 다시 시도해주세요' });
        //             }) 
        //     } 

        //     // this.contentUrlMaker(this.newsList)
        // },

        // 5. 최종 출력값 url 생성 함수 
        contentUrlMaker(list){
            let cnt = 1
            if(list){ // [arr0, arr1, ...]
                console.warn('5.contentUrlMaker')
                // console.log('list? ', list)
                let htmlRemoveText = this.escapeCharacter(this.content)
                let urlText = ''
                list.forEach(e => {
                    if(e.originallink && e.originallink !== '' && e.link !== 'none'){ // 기사 검색 api 들어온 경우 
                        urlText = '<a href="' + e.originallink + '" target="_blank">* ' + e.originaltitle + '</a>' 
                    }  
                    else { // 기사 검색값 안들어온 경우 
                        urlText = '* ' + e.originaltitle 
                        cnt++
                        console.log('검색값x=>', e.originaltitle, e)
                    }

                    if(this.content && (htmlRemoveText.includes(e.originaltitle) || this.content.includes(e.originaltitle))){
                        htmlRemoveText = htmlRemoveText.replaceAll('* ' + e.originaltitle, urlText)
                    } 
                    // else if(e.title !== '' && (htmlRemoveText.includes(e.title) || this.content.includes(e.title))){
                    //     htmlRemoveText = htmlRemoveText.replaceAll('* ' + e.originaltitle, urlText)
                    // }
                    else {
                        // console.log('불일치=>', e.originaltitle, e)
                    }
                    });
                this.content = htmlRemoveText
                this.notice = '[완료] '+'전체: '+ list.length + ' 미검색: ' + cnt
                this.textLine = ''
                this.isDone = true
                // console.warn('content=', this.content)
                console.warn(this.notice)
            }
            return
        },  

        // 기사생성 버튼 클릭
        onClickNews(list){
            // console.log('onClickNews', list)
            this.contentUrlMaker(list) 
        },
        
        isShowDate(){
            return isEmpty(this.item.showDate)
        },
        checkEmpty(val) {
            return isEmpty(val)
        },
        inputcontent(val){
            // debug.log('inputcontent = ', val)
            this.content = val
        },
        goEditPage() {
            this.setPageStatus(this.PAGE_STATUS.EDIT)
            this.isDone = true 
        },
        getStock(stock) {
            if(stock.code0 === 'YYYYYY' || stock.code0 === 'KKKKKK') {
                this.title = stock.name + '지수'
            }
            this.selectedStocks.push(stock)
            this.$emit('event-select-stock', this.selectedStocks)
        },
        remove(index) {
            this.selectedStocks.splice(index,1)
            this.$emit('event-select-stock', this.selectedStocks)
        },
        //저장
        getSave() {
            if (!this.content) {
                this.showMessage({ vc: this, message: '내용을 입력하세요.' })
                return
            }
            debug.log('getSave = ', this.content)
            this.showAlert({
                vc: this,
                message:
                    '<span style="color:#1976D2;">저장</span> 하시겠습니까?',
            })
                .then(() => {
                    let sCrud = (this.isEditPage?'U':'C'); //((this.item.id !== undefined && this.item.id !== null && this.item.id !== '') ?'U':'C');
                    let sShowDate = (this.isEditPage?this.item.showDate:today());

                    // if(this.item.id !== undefined && this.item.id !== null && this.item.id !== '') {
                    //     sShowDate = this.item.showDate
                    // } else {
                    //     //if(this.check){
                    //         sShowDate = today();
                    //     //}
                    // }
                    
                    let param = {                        
                        newsType1: this.$parent.type.value,
                        title: this.content
                            .replace(
                                this.content.substring(
                                    this.content.indexOf('<a href'),
                                    this.content.indexOf('target="_blank">') +
                                        16
                                ),
                                ''
                            )
                            .replace('</a>', '')
                            .replace('<br />', '')
                            .substring(0, 40),
                        content: this.content,
                        showDate: sShowDate,
                        crud: sCrud,
                        CALL_API_URL: '/issue/save',
                    }
                    if(this.isEditPage)
                        param.id = this.item.id

                    this.GET_CALL(param)
                        .then(() => {
                            this.showMessage({
                                vc: this,
                                message: '저장 되었습니다.',
                            })
                            if (this.item.id != null) {
                                this.$parent.getView(this.item)
                            } else {
                                this.$parent.getView(null)
                            }
                            this.$parent.getList()
                            this.textLine = ''
                            this.content = ''
                            this.isDone = true 
                            this.setPageStatus(this.PAGE_STATUS.LIST)
                        })
                        .catch((err) => {
                            this.showError({ vc: this, error: err })
                        })
                })
                .catch(() => {})
        },
        //게시
        getCnfirm() {
            this.showAlert({ vc:this, message:'홈페이지에 ( 게시날자 : '+this.showDate+') <br> <span style="color:#1976D2;">게시</span></span> 하시겠습니까?' }).then(()=>{
                let sShowDate = sShowDate;
                let param = {
                    'id': this.item.id,
                    'crud': 'U',
                    'showDate': this.showDate,
                    'CALL_API_URL': '/issue/saveCnfirm'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'확인 되었습니다.' });
                    this.$parent.getView(null);
                    this.$parent.getList();
                }).catch((err)=>{
                    this.showError({ vc:this, error:err });
                });
            }).catch(() => { });
        },
        textLineHandler(e) {
            let text = ''
            this.resetValues()
            if (e.code == 'KeyV' || e.code == 'MetaLeft') {
                let param = {
                    textLine: this.textLine,
                    CALL_API_URL: '/issue/link',
                }
                this.GET_CALL(param)
                    .then((data) => {
                        this.textLine = ''
                        text = data.content
                        console.log("1")
                        this.titleSeparator(text)
                        // 20210824 shb &nbsp; 공백 제거
                        // console.log('textLineHandler this.content', this.content)
                        this.content += data.content + '<br><br>'
                        // console.log('textLineHandler data.content', data.content)
                        this.content = this.content.replace(/&nbsp;/gi, '')
                        // console.log('textLineHandler',this.content)                        
                    })
                    .catch((error) => {
                        this.showError({ vc: this, error: error })
                    })
            }
        },
        getViewItem(obj) {
            this.content = ''
            if (obj != null) {
                this.content = obj.content
            }
        },
        makeFlash() {
            let params = {}
            params.newsType = this.$parent.type.flash
            let stocks = ''
            for (let i in this.selectedStocks) {
                stocks +=
                    this.selectedStocks[i].code +
                    ':' +
                    this.selectedStocks[i].name +
                    ','
            }
            stocks.slice(0, -1)
            params.stocks = stocks
            params.sendTime = this.sendTime


            this.WRITE_FLASH(params)
            .then(() => {
                this.goFlashPage();
            })
            .catch((err) => {
                this.showError({vc:this, error:err})
            })
        },
        goFlashPage() {
            this.windowRef = window.open(
                `/preview/popup.htm`,
                '시황속보 미리보기',
                'width=620,height=820'
            )
            if(this.windowRef) {
                this.windowRef.addEventListener('beforeunload', this.closePreview)
            }
        },
        closePreview() {
            this.windowRef = null
            if (this.isSuccess) {
                this.SET_STATUS(0)
                this.showMessage({ vc: this, message: '전송되었습니다.' })
                debug.log('closePrevice success')
            }
        },

    },
}
</script>