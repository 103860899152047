<template>
    <article>
        <!-- 구분 -->
        <div class="flex justify-start q-pa-xs">
            <i-btn label="삭제" @click="deleteItems()" />
        </div>
        <!-- 목록 -->
        <i-table
            :columns="columns"
            :list="list"
            :nextKey="nextKey"
            @row-click="getItem"
            @next-click="getList()"
            @check-event="handleCheckList"
        />
    </article>
</template>
<script>
import Debug from '@/debug'
import { FormatDate, CutDownText } from '@/util'

import Button from '@/components/common/Button'
import Table from '@/components/common/Table'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    components: {
        'i-table': Table,
        'i-btn': Button,
    },
    props: {
        newsType: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            columns: [
                {
                    name: 'createTime',
                    label: '작성일자',
                    align: 'left',
                    field: (row) => FormatDate(row.createTime),
                },
                // TODO: 전송여부
                // { name: 'label', label: '전송여부', align: 'left',field: row => row.label },
                {
                    name: 'lastUpdateTime',
                    label: '변경일자',
                    align: 'left',
                    field: (row) => FormatDate(row.lastUpdateTime),
                },
                //20210726 shb 추가
                {
                    name: 'writer',
                    label: '작성자',
                    align: 'left',
                    field: (row) => row.writer,
                },
                //==============
                {
                    name: 'content',
                    label: '내용',
                    align: 'left',
                    field: (row) => CutDownText(row.content, 30),
                },
            ],
            selected: [],
        }
    },
    created() {},
    mounted() {
        Debug.log('mounted newsType= ', this.newsType)
        this.INIT_LIST()
        this.getList()
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
        }),
        ...mapState('market_news', ['list', 'nextKey']),
        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isEditPage',
            'isRegisterPage',
        ]),
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('market_news', ['INIT_LIST']),
        ...mapMutations({ SET_MARKETNEWS_ITEM: 'market_news/SET_ITEM' }),
        ...mapMutations('newspaper', ['SET_ITEM']),

        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions('market_news', ['ASYNC_LIST', 'DELETE_ITEMS']),

        // 목록 조회하기
        getList() {
            this.setPageStatus(this.PAGE_STATUS.LIST)
            let param = {
                newsType: this.newsType.value,
            }
            this.ASYNC_LIST(param)
        },
        // 상세 가져오기
        getItem(item) {
            if (this.newsType.value === 'MARKET_OVERTIME_COMMENT') {
                this.setPageStatus(this.PAGE_STATUS.EDIT)
                this.SET_MARKETNEWS_ITEM(item)
            } else if (this.newsType.value === 'MARKET_STOCK_DISC_MIDDLE' ||
                this.newsType.value === 'MARKET_STOCK_DISC_CLOSED'){
                this.setPageStatus(this.PAGE_STATUS.DETAIL)
                this.SET_MARKETNEWS_ITEM(item)
            }else {
                this.setPageStatus(this.PAGE_STATUS.CONFIRM)
                this.SET_ITEM(item)
            }
        },
        handleCheckList(list) {
            this.selected = list
        },
        // 삭제하기
        deleteItems() {
            if (this.selected.length == 0) {
                this.showMessage({
                    vc: this,
                    message: '삭제할 항목을 선택해주세요.',
                })
                return
            } else {
                this.showAlert({
                    vc: this,
                    message: '선택한 항목을 삭제 하시겠습니까?',
                }).then(() => {
                    this.doDelete()
                })
            }
        },
        doDelete() {
            var params = {}
            params.ids = this.selected.map((x) => x.id).join(',')
            // 주요이슈는 완전 삭제!
            // 이미 속보로 나간거는 속보용이므로 현재 데이터는 delete
            params.force = 1
            this.DELETE_ITEMS(params)
                .then(() => {
                    this.showMessage({ vc: this, message: '삭제되었습니다.' })

                    this.INIT_LIST()
                    this.getList()
                })
                .catch((err) => {
                    Debug.log('error = ', err)
                    var error = err.message
                    this.showError({ vc: this, error: error })
                })
        },
    },
    watch: {
        newsType(val, old) {
            if (val != old) {
                this.INIT_LIST()
                this.getList()
            }
        },
    },
}
</script>
