<template>
    <article>
        <q-toolbar>
            <q-space />
            <i-btn
                v-show="isDetailPage || isFlashPage"
                label="취소"
                @click="goListPage()"
            />
        </q-toolbar>
        <q-scroll-area class="i-content-wrap">
            <q-card flat bordered>
                <q-card-section>
                    <!-- 등록 / 상세 수정 화면 -->
                    <div v-show="isListPage || isDetailPage">
                        <div v-show="isListPage" class="row">
                            <div class="col-12 col-lg-6 q-pa-xs">
                                <i-stock-search
                                    @get="getStock"
                                    label="종목검색"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-6 q-pa-xs">
                                <i-input
                                    v-model="newRecommend.stockName"
                                    inputLabel="종목명"
                                    disable
                                />
                            </div>
                            <div class="col-12 col-lg-6 q-pa-xs">
                                <i-input
                                    v-model="newRecommend.stockCode"
                                    inputLabel="종목코드"
                                    disable
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-6 q-pa-xs">
                                <i-select
                                    v-model="newRecommend.STOCK_COMPANY0"
                                    :options="stockCompanyOptions"
                                    inputLabel="증권사"
                                    option-label="name"
                                    option-value="value"
                                />
                            </div>
                            <div class="col-12 col-lg-6 q-pa-xs">
                                <i-select
                                    v-model="newRecommend.RECOMMEND_TYPE"
                                    :options="option"
                                    inputLabel="추천구분"
                                    option-label="name0"
                                    option-value="value0"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-6" @click="dateToText = true">
                                <i-datetime
                                    date
                                    v-model="newRecommend.showDate"
                                    dateLabel="추천일"
                                />
                                <div v-if="dateToText">
                                <i-input
                                    label="추천일 직접입력"
                                    style="width: 77%; margin-left:23%"
                                    v-model="newRecommend.showDate"
                                />
                                </div>
                            </div>

                            <div class="col-12 col-lg-6 q-pa-xs">
                                <i-input
                                    v-model="newRecommend.PRICE0"
                                    inputLabel="추천가"
                                    label="미입력시 전거래일 종가"
                                />
                            </div>
                            <div class="col-12 q-pa-xs">
                                <i-input
                                    v-model="newRecommend.content"
                                    inputLabel="추천사유"
                                    type="textarea"
                                    :rows="10"
                                />
                            </div>
                        </div>
                        <div v-show="isDetailPage">
                            <div class="q-pa-xs">
                                <i-field
                                    check
                                    v-model="newRecommend.EXCLUSION0"
                                    inputLabel="제외하기"
                                />
                            </div>
                            <div class="row">
                                <div
                                    v-if="newRecommend.EXCLUSION0"
                                    class="col-12 col-lg-6"
                                    @click="dateToTextExc = true"
                                >
                                    <i-datetime
                                        date
                                        v-model="newRecommend.EXCLUDED_DATE0"
                                        dateLabel="제외일"
                                    />
                                <div v-if="dateToTextExc">
                                    <i-input
                                    label="제외일 직접입력"
                                    style="width: 77%; margin-left:23%"
                                    v-model="newRecommend.EXCLUDED_DATE0"
                                    />
                                </div>
                                </div>
                                <div
                                    v-if="newRecommend.EXCLUSION0"
                                    class="col-12 col-lg-6"
                                >
                                    <i-input
                                        v-model="newRecommend.EXCLUDED_PRICE0"
                                        inputLabel="제외가"
                                        label="미입력시 현재 종가"
                                    />
                                </div>
                                <div
                                    v-if="newRecommend.EXCLUSION0"
                                    class="col-12 q-pa-xs"
                                >
                                    <i-input
                                        v-model="newRecommend.CAUSE"
                                        inputLabel="제외사유"
                                        type="textarea"
                                        :rows="10"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </q-card-section>
            </q-card>
        </q-scroll-area>
        <!-- 하단 버튼 -->
        <div class="q-my-md">
            <i-btn class="full-width" :label="btnLabel" @click="write()" />
            <!-- <i-btn class="col-6" label="제외" @click="updateExclusion()"/> -->
            <!-- <i-btn v-show="isFlashPage" class="full-width" label="속보" @click="flash()"/> -->
        </div>
    </article>
</template>
<script>
import Debug from '@/debug'

import Button from '@/components/common/Button'
import Field from '@/components/common/Field'
import StockSearch from '@/components/common/StockSearch'
import Input from '@/components/common/Input'
import Select from '@/components/common/Select'
import DateTime from '@/components/common/DateTime'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { objToString } from '@/util'

export default {
    name: 'Content',
    components: {
        'i-btn': Button,
        'i-field': Field,
        'i-input': Input,
        'i-datetime': DateTime,
        'i-select': Select,
        'i-stock-search': StockSearch,
    },
    data() {
        return {
            newsType: 'STOCK_NEW_RECOMMEND',
            companyOption: [],
            option : [],
            dateToText: false, // 추천일 직접입력 활성화  
            dateToTextExc: false, // 제외일 직접입력 활성화  
        }
    },
    created() {
        // TODO: 증권사 목록 가져오기
        var params = {}
        params.newsType = this.newsType
        this.GET_STOCK_COMPANY(params)
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            newsTypeList: (state) => state.resource.newsType,
            recommendTypeOptions: (state) => state.resource.recommendType,
            newRecommend: (state) => state.stock_new_recommend.item,
            stockCompanyOptions: (state) => state.resource.stockCompany,
            // newsType: state => state.stock_new_recommend.newsType
        }),
        ...mapGetters('page', ['isListPage', 'isDetailPage', 'isFlashPage']),

        btnLabel: function() {
            if (this.isListPage) {
                return '등록'
            } else if (this.isDetailPage) {
                return '수정'
            } else {
                return null
            }
        },
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('stock_new_recommend', [
            'SET_LIST',
            'SET_DETAIL',
            'INIT_DETAIL',
            'INIT_LIST',
        ]),

        ...mapActions('alert', ['showError', 'showMessage']),
        ...mapActions('resource', ['GET_STOCK_COMPANY']),
        ...mapActions('flash', ['PREVIEW_ITEM']),
        ...mapActions('stock_new_recommend', ['GET_LIST', 'WRITE_ITEM']),

        getStock(stock) {
            this.newRecommend.stockCode = stock.code
            this.newRecommend.stockName = stock.name
        },
        goListPage() {
            this.INIT_DETAIL()
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        selectFlashType() {
            // TODO: 속보
            var params = {}
            params.newsType = this.flashType.value

            this.PREVIEW_ITEM(params)
                .then((data) => {
                    console.log(data)
                })
                .catch((error) => {
                    this.showError({ vc: this, error: error })
                    Debug.log('error = ', error)
                })
        },
        write() {
            var message
            // 빈값 체크 --> 추천가는 입력안하면 종가로 저장
            if (!this.newRecommend.stockCode || !this.newRecommend.content) {
                message = '내용을 입력해주세요.'
                this.showMessage({ vc: this, message: message })
                return
            }

            // 증권사 선택 체크
            if (!this.newRecommend.STOCK_COMPANY0) {
                message = '증권사 선택해주세요.'
                this.showMessage({ vc: this, message: message })
                return
            }

            // 추천구분 선택 체크
            if (!this.newRecommend.RECOMMEND_TYPE) {
                message = '추천구분 선택해주세요.'
                this.showMessage({ vc: this, message: message })
                return
            }

            var params = {
                stockCode: this.newRecommend.stockCode,
                content: this.newRecommend.content,
            }

            if (this.isDetailPage) {
                params.id = this.newRecommend.id
            }

            var options = {}
            options.STOCK_COMPANY0 = this.newRecommend.STOCK_COMPANY0.code
            options.RECOMMEND_TYPE = this.newRecommend.RECOMMEND_TYPE.value0
            options.PRICE0 =
                this.newRecommend.PRICE0 == null ? '' : this.newRecommend.PRICE0

            if (this.newRecommend.EXCLUSION0) {
                options.EXCLUSION0 = 1
                options.EXCLUDED_DATE0 = this.newRecommend.EXCLUDED_DATE0
                options.EXCLUDED_PRICE0 =
                    this.newRecommend.EXCLUDED_PRICE0 == null
                        ? ''
                        : this.newRecommend.EXCLUDED_PRICE0
                options.CAUSE = this.newRecommend.CAUSE
            } else {
                options.EXCLUSION0 = 0
                options.EXCLUDED_DATE0 = ''
                options.EXCLUDED_PRICE0 = ''
                options.CAUSE = ''
            }

            params.options = objToString(options)
            //추천일 추가
            params.showDate = this.newRecommend.showDate
            this.WRITE_ITEM(params)
                .then(() => {
                    if (this.isListPage) {
                        message = '등록되었습니다.'
                    } else if (this.isDetailPage) {
                        message = '수정되었습니다.'
                    }
                    this.showMessage({ vc: this, message: message })

                    this.INIT_LIST()
                    this.GET_LIST({})
                    this.goListPage()

                    // reset 
                    this.dateToText = false
                    this.dateToTextExc = false 
                })
                .catch((err) => {
                    Debug.log('error = ', err)
                    this.showError({ vc: this, error: err })
                })
        },

        // updateExclusion() {
        //     var message
        //     var params = {}
        //     params.id = this.newRecommend.id
        //     params.newsType = this.newsType
        //     params.stockCode = this.newRecommend.stockCode

        //     var options = {}
        //     options.EXCLUSION0 = 1

        //     params.options = objToString(options)

        //     this.WRITE_ITEM(params)
        //     .then(() => {
        //         message = '상태가 변경되었습니다.'
        //         this.showMessage({ vc:this, message:message })

        //         this.INIT_LIST()
        //         this.GET_LIST({})
        //         this.goListPage()
        //     })
        //     .catch((err) => {
        //         Debug.log('error = ', err)
        //         var error = err.message
        //         this.showError({ vc:this, error:error })
        //     })

        // },
        // flash() {},
    },
    watch: {
        newsTypeList: function() {
            var nt = this.newsTypeList.find((v) => v.value == this.newsType)
            Debug.log(nt)
        },
        recommendTypeOptions: function() {
            Debug.log(this.recommendTypeOptions)
            // 221116 meej 추천구분 2개씩 나오는 오류 수정 this.option = []
            this.option = []
            this.recommendTypeOptions.forEach(e => {
                // if(e.name0 == '대형주 추천종목' || e.name0 == '중소형주 추천종목'){
                //     console.log(e.name0)
                // }
                // else{
                //     this.option.push(e)
                // }
                if(e.name0 != '대형주 추천종목' && e.name0 != '중소형주 추천종목'){
                    this.option.push(e)
                }
                else{
                  //  this.option.push(e)
                }
            });
            Debug.log(this.option)
        },
        stockCompanyOptions: function() {
            Debug.log(this.newRecommend)
        },
    },
}
</script>
