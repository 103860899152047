<template>
    <article class="q-pa-md">
        <!-- 220630 meej 전체, 공개, 준비, 편출 종목수 추가 -->
        <div v-if="stockCount && stockCount.totalCnt" class="row q-pl-md">
            <label class="i-label">전체 : {{stockCount.totalCnt}}개</label>&nbsp;&nbsp;&nbsp;&nbsp;
            <label class="i-label">공개 : {{stockCount.publicCnt}}개</label>&nbsp;&nbsp;&nbsp;&nbsp;
            <label class="i-label">준비 : {{stockCount.readyCnt}}개</label>&nbsp;&nbsp;&nbsp;&nbsp;
            <label class="i-label">편출 : {{stockCount.deleteCnt}}개</label>&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <!-- 목록 -->
        <i-otable
            select
            :columns="columns"
            :list="themeStockList"
            :typeOptions="typeOptions"
            @order-event="handleOrderList"
            @row-event="goDetailPage"
            @select-event="selectType"
        />
    </article>
</template>
<script>
import Debug from '@/debug'
import { FormatDate, isEmpty } from '@/util'

import OrderTable from '@/components/common/OrderTable'

import { mapState, mapActions, mapMutations } from 'vuex'

export default {
    name: 'List',
    components: {
        'i-otable': OrderTable,
    },
    props: {
        order: Boolean,
        theme: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            columns: [
                {
                    name: 'stock',
                    label: '종목',
                    align: 'left',
                    field: (row) => `${row.stockName}(${row.stockCode})`,
                },
                {
                    name: 'label',
                    label: '상태',
                    align: 'left',
                    field: (row) => row.label,
                },
                {
                    name: 'createTime',
                    label: '입력날짜',
                    align: 'left',
                    field: (row) => FormatDate(row.createTime),
                },
                {
                    name: 'writer',
                    label: '작성자',
                    align: 'left',
                    field: (row) => row.writer,
                },
                {
                    name: 'lastUpdateTime',
                    label: '수정일',
                    align: 'left',
                    field: (row) => FormatDate(row.lastUpdateTime),
                },
            ],
            type: null,
            themeOptions: null,
            orders: [],
        }
    },
    created() {
        // this.type = this.typeOptions[0]
        /* 
            220502 meej type 값 오류로 인해 state에 저장해서 사용처리
         */
        if (!isEmpty(this.orderTbType)) {
            this.type = this.orderTbType
        }else{
            this.type = this.typeOptions[0]
            this.SET_ORDERTBTYPE(this.type)
        }
        this.getThemeStock()
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            themeStockList: (state) => state.theme_stock.items,
            themeStockType: (state) => state.resource.themeStockType,
            orderTbType : (state) => state.resource.orderTbType,
            // 220630 meej 전체, 공개, 준비, 편출 종목수 추가 
            stockCount : (state) => state.theme_stock.stockCount, 
        }),
        typeOptions() {
            return [{ name0: '전체', value0: '' }].concat(this.themeStockType)
        },
    },
    methods: {
        ...mapMutations({
            SET_ITEM: 'theme_stock/SET_ITEM',
            setPageStatus: 'page/setPageStatus',
            INIT_LIST: 'theme_stock/INIT_LIST',
            SET_ORDERTBTYPE : 'resource/SET_ORDERTBTYPE',
        }),

        ...mapActions({
            ASYNC_THEMESTOCK_LIST: 'theme_stock/ASYNC_THEMESTOCK_LIST',
            ORDER: 'theme_stock/ORDER',
            showError: 'alert/showError',
            showMessage: 'alert/showMessage',
            showAlert: 'alert/showAlert',
        }),

        // 테마 종목 조회
        getThemeStock(params) {
            Debug.log('getThemeStock params =', params)
            this.INIT_LIST()
            let mesg = '테마를 선택하세요'
            if (params === undefined) {
                if (this.theme === null || this.theme === undefined) {
                    Debug.log('getThemeStock mesg= ', mesg)
                    this.showMessage({ vc: this, message: mesg })
                    return
                }
                /* 
                    220502 meej type 값 오류로 인해 state에 저장해서 사용처리
                */
                params = {
                    themeCode: this.theme.code,
                    type: this.orderTbType.value0,
                }
            }
            this.INIT_LIST()
            this.ASYNC_THEMESTOCK_LIST(params)
        },
        goDetailPage(item) {
            this.SET_ITEM(item)
            this.setPageStatus(this.PAGE_STATUS.DETAIL)
        },
        selectType(val) {
            Debug.log('selectType val = ', val)
            this.type = val
            if (this.theme === null || this.theme === undefined) {
                this.showMessage({ vc: this, message: '테마를 선택하세요' })
                return
            }
            Debug.log('selectType 2')
            /* 
                220502 meej type 값 오류로 인해 state에 저장해서 사용처리, 필요없는 코드 주석처리
            */
            // let params = {
            //     themeCode: this.theme.code,
            //     type: val.value0,
            // }
            this.SET_ORDERTBTYPE(val)
            Debug.log('selectType 3 ', this.pageStatus)
            this.getThemeStock()
        },
        handleOrderList(list) {
            //순서 바뀐 리스트가 넘어옴
            let orderList = []
            for (let i = 0; i < list.length; i++) {
                let item = list[i]
                let order = {
                    stockCode: item.stockCode,
                    index: '' + i,
                }
                orderList.push(order)
            }
            this.showAlert({
                vc: this,
                message: '순서를 변경하시겠습니까?',
            }).then(() => {
                this.doOrder(orderList)
            })
        },
        doOrder(list) {
            let params = {
                themeCode: this.theme.code,
                items: list,
            }
            this.ORDER(params)
                .then(() => {
                    this.showMessage({ vc: this, message: '변경되었습니다.' })
                    this.getThemeStock()
                })
                .catch((err) => {
                    this.showError({ vc: this, error: err })
                })
        },
    },
    watch: {
        pageStatus: function(val, old) {
            if (val !== old) {
                if (val === this.PAGE_STATUS.LIST) {
                    // this.type = this.typeOptions[0]
                    this.getThemeStock()
                }
            }
        },
        theme(val, old) {
            if (val !== old) {
                if (val !== null) {
                    /* 
                        220502 meej type 값 오류로 인해 state에 저장해서 사용처리
                    */
                    this.SET_ORDERTBTYPE( this.typeOptions[0])
                    this.getThemeStock()
                }
            }
        },
    },
}
</script>
