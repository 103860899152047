<template>
    <section class="row q-pl-xs">
        <q-toolbar class="row q-mt-md text-left">
            <div class="q-pa-xs" style="width:40%;"><i-input v-model="keyword" ref="keyword"/></div>
            <div class="q-pa-xs"><i-btn icon="search" @click="getList()"/></div>
            <div class="q-pa-xs"><i-btn label="선택삭제" @click="getDeleteChk()"/></div>
            <div class="col q-pa-xs text-right"><i-btn label="신규등록" @click="$router.go()"/> </div>
            <!-- <div class="col q-pa-xs text-right"><i-btn label="신규등록" @click="getView(null)"/> </div> -->
        </q-toolbar>
        <i-list class="col-6 q-pa-md" ref="dividendList"/>
        <i-content class="col-6 q-pa-md" ref="dividendContent"/>
    </section>
</template>
<script>
import Input from '@/components/common/Input';
import Button from '@/components/common/Button';
import List from '@/components/stock/dividend/List.vue';
import Content from '@/components/stock/dividend/Content.vue';

export default {
    name: "Dividend",
    components: {
        "i-input" : Input,
        'i-btn': Button,
        "i-list": List,
        'i-content': Content
    },
    data() {
        return {
            keyword:''
        }
    },
    methods: {
        getList() {
            this.$refs.dividendList.getList();
        },
        getView(item) {
            this.$refs.dividendList.getView(item);
        },
        getDeleteChk() {
            this.$refs.dividendList.getDeleteChk();
        }
    }
};

</script>
