<template>
    <div>
       <div class="inputBox">
           <!-- 종목검색창 -->
           <input
               ref="headersearch"
               class="header-searchbar2"
               type="text"
               placeholder="종목검색"
               @input="inputText = $event.target.value"
               @keyup.enter="hitEnter"
               @keyup.esc="keyEscape"
               @keydown.down="keyDown"
               @keydown.up="keyUp"
           />
       </div>
       <ul v-if="toggle" class="autobox2" style="z-index: 9999; position: fixed; height: 700px;">
           <!-- 자동완성 리스트 -->
           <li
               v-for="item in stockOptions"
               :key="item.code"
               ref="li_refs"
               @click="onBoxClick(item.code)"
           >
               <span class="autoliItem">
                   <p>{{ item.name }}({{ item.code }})</p>
               </span>
           </li>
       </ul>
       </div>
   </template>
   <script>
   import { mapState, mapActions, mapMutations } from 'vuex'
   export default {
       name: 'StockSearch',
       props: { 
       // 22.10.28 SUYE 쿼사q-input버리고 새로 만들어서 props 대부분 사용하지 않음 
           extra: {
               type: Object,
               default: null,
           },
           label: {
               type: String,
               default: '종목',
           },
       },
       data() {
           return {
                stock: '',          // 선택된 종목 
                inputText: '',      // 사용자 입력값 
                currentFocus: -1,   // 키보드 선택시 포커스 제어 
                toggle: false,      // select박스토글
                stockOptions: [],   // 종목 리스트 
                searched: '',       // 검색값 표시
           }
       },
       computed: {
        ...mapState({
            stockItems: (state) => state.resource.stockItems,
        }),
       },

       created() {
           if (this.label) {
               this.innerLabel = ''
           }
           this.GET_INIT({ type: this.status }).then(() => {
               this.stockOptions = this.stockItems
            //    console.log('stockItems? ', this.stockItems)
           })
       },
   
       watch: {
           // 두글자 이상 입력해야 자동완성 랜더링 되도록 만들어주는 조건
           inputText() {
               console.log('inputText?', this.inputText)
               const parsedobj = this.stockItems && JSON.parse(JSON.stringify(this.stockItems))
               // console.log('parsedobj= ', parsedobj)
   
               if (this.inputText.length > 1) {

                // 22.11.11 SUYE 종목코드(숫자)6자리 입력시 클릭,엔터없이 자동 검색
                let check = /^[0-9]+$/; 
                const checkInput = this.inputText.trim()
                if (check.test(checkInput) && checkInput.length == 6) {
                    // console.log('checkInput len?', checkInput.length)
                    this.onBoxClick(checkInput)
                    return
                } else if (check.test(checkInput) && checkInput.length > 6){
                    console.log('입력값 '+checkInput+'이 잘못되었습니다.')
                }

                   this.toggle = true
                   // 230302 meej 선택된 종목 이름으로 넣어줌_주석처리
                   // this.SET_SEARCHED_STOCK_NAME(this.inputText)

                   // TEST 
                   const result =
                       parsedobj &&
                       parsedobj.filter(
                           (item) =>
                           item.code
                            .toLowerCase()
                            .includes(
                                this.inputText.toLowerCase().trim()) 
                            ||
                            item.name
                            .toLowerCase()
                            .includes(this.inputText.toLowerCase().trim())
                       )
                   // console.log('result ', result)
                   
                   if (result.length === 0) {
                       return [{ name: '해당되는 종목이 없습니다.' }]
                   } else {
                       // 22.10.31 SUYE 검색시 추천박스 정렬
                       result.sort((a, b) => {
      
                           if(a.name.length > b.name.length) { return 1 } 
                           if(a.name.length < b.name.length) { return -1 }
   
                           if(a.name > b.name) { return -1 } 
                           if(a.name < b.name) { return 1 }
   
                           if(a.name.localeCompare(b.name) > b.name.localeCompare(a.name)) { return -1 }
                           if(a.name.localeCompare(b.name) < b.name.localeCompare(a.name)) { return 1 }
   
                       }) 
                       // console.log('sorted result ', result)
                       this.stockOptions = result
                   }
               }
               this.currentFocus = -1
   
               if (this.$refs.li_refs !== undefined) {
                   this.removeActive()
               }
   
           },
           currentFocus(){
            if(this.currentFocus) console.log('currentFocus', this.currentFocus)
           },
       },
       beforeMount() {
           window.addEventListener('click', this.closeAllLists)
       },
       beforeDestroy() {
           window.removeEventListener('click', this.closeAllLists)
       },
       methods: {
        ...mapActions('resource', ['GET_INIT']),
        ...mapMutations('stock', ['SET_SEARCHED_STOCK_NAME']),

           hitEnter(event) {
               console.log('hitEnter')
               // 검색값 입력 제어 
               if (this.inputText.trim() === '' && this.toggle == false) {
                   alert('종목명을 입력해주세요.')
               } 
               else {
               // 검색값 존재시 
                   // selectBox 선택시
                   if (this.currentFocus > -1) {
                       const ref = this.$refs.li_refs
                       const selectedItem = ref.filter(
                           (item) => item.className === 'autocomplete-active'
                   )
   
                   if (selectedItem[0].textContent.trim() !== null 
                       && selectedItem[0].textContent.trim() !== undefined 
                       && selectedItem[0].textContent.trim() !== '') 
                   {
                       this.searched = selectedItem[0].textContent
                       console.log('searched=', this.searched)
                       this.stock = this.stockOptions[this.currentFocus]
                        // 230302 meej 선택된 종목 이름으로 넣어줌
                        this.SET_SEARCHED_STOCK_NAME(this.stockOptions[this.currentFocus].name)
                       this.sendStock(this.stock)
                   }
                   // selectBox 미선택시
                   } else if (this.currentFocus == -1) {
                       const ref = this.$refs.li_refs
                       ref[0].className = 'autocomplete-active'
                       const selectedItem = ref
                       if (
                           selectedItem[0].textContent.trim() !== null 
                           && selectedItem[0].textContent.trim() !== undefined 
                           && selectedItem[0].textContent.trim() !== '') 
                       {
                           this.searched = selectedItem[0].textContent
                           console.log('searched=', this.searched)
                           this.stock = this.stockOptions[0]
                           // 230302 meej 선택된 종목 이름으로 넣어줌
                           this.SET_SEARCHED_STOCK_NAME(this.stockOptions[0].name)
                           this.sendStock(this.stock) 
                       }
                   } 
                   this.inputText = ''
                   event.target.value = ''
                   this.toggle = false
               } 
            //    this.$refs.headersearch.value = '' // 검색필드 클리어
           },
   
           // 부모 @get에 선택된 종목 보냄
        sendStock(stock) {
            // console.log('stockSearch sendStock', stock)
            this.$emit('get', stock)
            this.currentFocus = -1
            this.$refs.headersearch.value = '' // 검색필드 클리어
        },
   
           // 자동완성 아이템 클릭 시, queryString으로 이동시키는 메소드
           onBoxClick(code) {
               if (code) {
                   this.inputText = ''
                   this.toggle = false
                   this.currentFocus = -1
                   // 230302 meej 한번 검색 후 다시 검색시 오류 발생 stockOptions -> stockItems으로 변경
                   // const tempList = this.stockOptions
                   const tempList = this.stockItems
                   const selectedItem = tempList.filter(
                       (item) => item.code === code
                   )
                   console.log('selectedItem', selectedItem[0])

                // 종목코드 6자리로 자동 검색시
                if(selectedItem[0] !== undefined){
                    console.log('clicked selectedItem', selectedItem[0].name)
                    this.stock = selectedItem[0]
                    this.searched = selectedItem[0].name
                    // 230302 meej 선택된 종목 이름으로 넣어줌
                    this.SET_SEARCHED_STOCK_NAME(selectedItem[0].name)
                    this.sendStock(this.stock)
                } else {
                    alert('검색값을 찾을 수 없습니다.')
                    this.$refs.headersearch.value = '' // 검색필드 클리어
                    this.inputText = ''
                }
               }
           },
   
           // 아래로 키보드 눌렀을 때, 스크롤 포커스도 따라가게 만드는 메소드
           outboundScrolling() {
               const ref = this.$refs.li_refs
   
               if (
                   ref[this.currentFocus].offsetTop <
                   ref[this.currentFocus].parentNode.scrollTop
               ) {
                   // Hidden on top, move scroll to show item
                   // Just to the top of item
                   ref[this.currentFocus].parentNode.scrollTop =
                       ref[this.currentFocus].offsetTop
               } else if (
                   ref[this.currentFocus].offsetTop >
                   ref[this.currentFocus].parentNode.scrollTop +
                       ref[this.currentFocus].parentNode.clientHeight -
                       ref[this.currentFocus].clientHeight
               ) {
                   // Hidden on bottom, move scroll to top of item + item height
                   ref[this.currentFocus].parentNode.scrollTop =
                       ref[this.currentFocus].offsetTop -
                       (ref[this.currentFocus].parentNode.clientHeight -
                           ref[this.currentFocus].clientHeight)
               }
           },
   
           // ese 눌렀을 때, 자동완성 hide
           keyEscape() {
               this.toggle = false
               this.currentFocus = -1
           },
   
           // 자동완성 아래방향 키입력
           keyDown() {
               const ref = this.$refs.li_refs
   
               if (ref !== undefined && ref.length - 1 > this.currentFocus) {
                   this.removeActive()
                   this.currentFocus++
                   ref[this.currentFocus].classList.add('autocomplete-active')
   
                   this.outboundScrolling()
               }
           },
   
           // 자동완성 아래방향 키입력
           keyUp() {
               const ref = this.$refs.li_refs
   
               if (this.currentFocus > 0) {
                   this.removeActive()
                   this.currentFocus--
                   ref[this.currentFocus].classList.add('autocomplete-active')
   
                   this.outboundScrolling()
               }
           },
   
           // autocomplete-active 클래스를 제거해주는 메소드
           removeActive() {
               for (let i = 0; i < this.$refs.li_refs.length; i++) {
                   this.$refs.li_refs[i].classList.remove('autocomplete-active')
               }
           },
           // 자동완성 부분 외의 부분을 클릭할 시, 자동완성 토글창 닫도록 만드는 조건
           closeAllLists(elem) {
               if (
                   elem.target.className !== 'autobox2' &&
                   elem.target.className !== 'header-searchbar2'
               ) {
                   this.toggle = false
               }
           }, 
       }
   }
   </script>
   <style>
   
   .autocomplete-active {
       background-color: #EEEEF3 !important;
       border-radius: 5px;
   }
   
   .header-searchbar2 {
       border-bottom: 2px solid slategrey;
       width: 150px;
       height: 40px;
       font-size: 15px;
       border: 0;
       border-radius: 4px;
       outline-color: #2196F3;
       padding-left: 10px;
       background-color: rgb(233, 233, 233);
   }
   .autobox2 {
       list-style : none;
       border-bottom: 1px solid #E2E2E7;
       padding: 4px 2px;
       width: fit-content;
       height: fit-content;
       margin-left: 7%;
       left: auto;
       z-index: 9999;
       border: 1px solid #D0D0D5;
       /* 리스트 크기에 맞춰서 박스도 제한되는 것 때문에 임시 positon: initial */
       position: absolute;
       /* position: initial;  */
       padding: 4px;
       overflow: auto;
       background: white;
       cursor: pointer;
   }
   
   </style>
   