<template>
    <section>
        <div class="row q-pa-xs" >
            <div class="col">
                <q-radio v-model="radioType" val="STOCK_OVERTIME_UNUSUAL" label="시간외 단일가 특이종목" />
            </div>
            <div class="col">
                <q-radio v-model="radioType" val="MARKET_OVERTIME_COMMENT" label="시간외 단일가 코멘트" />
            </div>
            <div class="col-6"></div>
        </div>
        <!-- 20210723 shb 수정 -->
        <div class="row">
            
            <div class="col-6 q-pa-md">
                
                <!-- <i-select
                    label="구분"
                    v-model="type"
                    :options="typeOptions"
                    option-label="name"
                    option-value="value"
                    class="full-width"
                /> -->

                <i-stock-list
                    :news-type="typeOptions[0]"
                    v-if="radioType === 'STOCK_OVERTIME_UNUSUAL'"
                />
                <i-list
                    :news-type="typeOptions[1]"
                    v-if="radioType === 'MARKET_OVERTIME_COMMENT'"
                />
            </div>
            <div class="col-6 q-pa-md">
                <i-content
                    :news-type="typeOptions[1]"
                    v-if="radioType === 'MARKET_OVERTIME_COMMENT'"
                    :unusual="true"
                    class="col-6"
                    
                />
                <i-stock-content
                    :news-type="typeOptions[0]"
                    v-if="radioType === 'STOCK_OVERTIME_UNUSUAL'"
                    class="col-6"
                    @event-refresh-list="handleRefreshList"
                />
            </div>
        </div>
        <!-- 20210723 shb 수정 -->
    </section>
</template>
<script>
import StockNewsList from '@/components/newspaper/StockNewsList.vue'
import StockContent from '@/components/stock/stock-excel-input/Content.vue'
import Content from '@/components/newspaper/Content.vue'
import List from '@/components/newspaper/List.vue'
//import Select from '@/components/common/Select.vue'

import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
//market_news
export default {
    name: 'Unusual',
    components: {
        'i-stock-list': StockNewsList,
        'i-list': List,
        'i-content': Content,
        'i-stock-content': StockContent,
//        'i-select': Select,
    },
    data() {
        return {
            radioType:'',
            typeOptions: [
                {
                    name: '시간외 단일가 특이종목',
                    value: 'STOCK_OVERTIME_UNUSUAL',
                },
                {
                    name: '시간외 단일가 코멘트',
                    value: 'MARKET_OVERTIME_COMMENT',
                },
            ],
        }
    },
    created() {
        this.radioType = this.typeOptions[0].value
        this.setPageStatus(this.PAGE_STATUS.LIST)
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
        }),
        ...mapGetters('page', ['isDetailPage', 'isEditPage']),
    },
    methods: {
        ...mapMutations('stock_news', [
            'INIT_LIST',
            'SET_DETAIL',
            'INIT_DETAIL',
        ]),
        ...mapMutations('page', ['setPageStatus']),
        ...mapActions('stock_news', [
            'ASYNC_LIST'] ),
        /*
        20210726 shb 수정
        구분별 데이터 수정 모드 일때 
        해당 하는 구분의 입력 창만 보여 줄수 있다.
        수정 모드 가 아닐때 == 신규입력 일때는
        모든 입력 창이 보이도록 수정.
        */
        conV() {
            if (this.isEditPage || this.isDetailPage) {
                if (this.isEditPage) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        },
        stockConV() {
            if (this.isEditPage || this.isDetailPage) {
                if (this.isDetailPage) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        },
        handleRefreshList() {
            //  전체 조회하기
            this.INIT_LIST()
            var params = {}
            params.newsType = 'STOCK_OVERTIME_UNUSUAL'
            this.ASYNC_LIST(params)
        }
        // ===============
    },
    watch: {
        radioType: function(newVal, oldVal) {
            console.log('watch radioType = ', newVal, ',',oldVal )
            if(newVal !== oldVal) {
                this.type = this.typeOptions.find((v)=> v.value === newVal)
            }
        },
    },
}
</script>
