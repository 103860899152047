<template>
    <article>
        <!-- 구분 -->
        <div class="row q-my-xs i-top-wrap">
            <div class="col-12 col-sm-6">
                <div class="flex justify-start">
                    <i-stock-search style="width:80%" @get="getStock"/>
                    <i-btn style="width:20%" icon="search" @click="getSearch()"/>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="flex justify-end">
                    <div class="q-pa-xs">
                        <i-btn label="삭제" @click="deleteItems()"/>
                    </div>
                    <div class="q-pa-xs">
                        <i-btn label="등록" @click="goRegisterPage()"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- 목록 -->
        <i-table :columns="columns" :list="list" :nextKey="nextKey"
            @row-click="getItem" @next-click="getList()" @check-event="handleCheckList"/>
    </article>
</template>
<script>
import Debug from '@/debug'
import { FormatDate, CutDownText } from '@/util'

import Button from '@/components/common/Button'
import Table from '@/components/common/Table'
import StockSearch from '@/components/common/StockSearch'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    components: {
        'i-btn' : Button,
        'i-stock-search': StockSearch,
        'i-table' : Table
    },
    data () {
        return {
            condition: {
                stock: ''
            },
            columns: [
                { name: 'createTime', label: '입력일자', align: 'left', field: row => FormatDate(row.createTime) },
                { name: 'stockName', label: '종목명', align: 'left',field: row => row.stockName },
                { name: 'stockCode', label: '종목코드', align: 'left', field: row => row.stockCode },
                { name: 'content', label: '내용', align: 'left', field: row => CutDownText(row.content, 30) },
            ],
            selected: []
        }
    },
    created () {
        this.INIT_LIST()
        this.getList()
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus
        }),
        ...mapState( 'stock_summary_extra', ['list', 'nextKey']),
        ...mapGetters( 'page', ['isListPage', 'isDetailPage', 'isEditPage', 'isRegisterPage']),
    },
    methods: {
        ...mapMutations( 'page', ['setPageStatus']),
        ...mapMutations( 'stock_summary_extra', ['INIT_LIST', 'INIT_DETAIL']),

        ...mapActions( 'alert', ['showError', 'showMessage']),
        ...mapActions( 'stock_summary_extra', ['GET_LIST', 'GET_DETAIL', 'DELETE_ITEMS']),

        handleCheckList(list) {
            this.selected = list
        },
        getSearch() {
            this.condition.stock = ''

            this.INIT_LIST()
            this.getList()
        },
        getStock(stock) {
            this.condition.stock = stock

            this.INIT_LIST()
            this.getList()
        },
        goRegisterPage() {
            this.INIT_DETAIL()
            this.setPageStatus(this.PAGE_STATUS.REGISTER)
        },
        // 목록 조회하기
        getList() {
            var params = {}

            if (this.condition.stock != null) {
                params.code = this.condition.stock.code
            }

            this.GET_LIST(params)
        },
        // 상세 조회하기
        getItem(item) {
            this.setPageStatus(this.PAGE_STATUS.DETAIL)

            var params = {}
            params.id = item.id

            this.GET_DETAIL(params)
        },
        // 삭제하기
        // TODO: 삭제 후 체크박스가 풀리지 않음. -> table 컴포넌트 확인 필요
        deleteItems() {

            var message

            if (this.selected.length == 0) {
                message = '삭제할 항목을 선택해주세요.'
                this.showMessage({ vc:this, message:message })
                return
            }

            var params = {}
            params.ids = this.selected.map( x => x.id ).join(',')

            console.log(params)

            this.DELETE_ITEMS(params)
            .then(() => {
                message = '삭제되었습니다.'
                this.showMessage({ vc:this, message:message })

                this.INIT_DETAIL()

                this.INIT_LIST()
                this.getList()
            })
            .catch((err) => {
                var error = err.message
                this.showError({ vc:this, error:error })
                Debug.log('error = ', error)
            })
        }
    }
}
</script>


