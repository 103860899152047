<template>
    <article>
        <q-toolbar>
            <q-space/>
            <i-btn v-show="isDetailPage" label="취소" @click="goListPage()"/>
        </q-toolbar>
        <q-card>
            <q-card-section>
                <div class="col q-gutter-sm">
                    <div v-if="isListPage" class="row q-my-sm">
                        <div class="col-12 col-lg-6">
                            <i-stock-search @get="getStock" label="종목검색"/>
                        </div>
                    </div>
                    <div class="row q-my-sm">
                        <div class="col-12 col-lg-6 q-pa-xs">
                            <i-input v-model="item.stockCode" inputLabel="종목코드" disable />
                        </div>
                        <div class="col-12 col-lg-6 q-pa-xs">
                            <i-input v-model="item.stockName" inputLabel="종목명" disable />
                        </div>
                    </div>
                    <div class="row q-my-sm">
                        <div class="col-6">
                            <i-datetime date
                                v-model="item.showDate" dateLabel="날짜" />
                        </div>
                    </div>
                </div>
            </q-card-section>
        </q-card>
        <div class="q-my-md">
            <i-btn class="full-width" :label="btnLabel" @click="write()"/>
        </div>
    </article>
    <!-- TODO: 수정 시 화면 필요 -->
</template>
<script>
import Debug from '@/debug'

import Button from '@/components/common/Button'
import Input from '@/components/common/Input'
import StockSearch from '@/components/common/StockSearch'
import DateTime from '@/components/common/DateTime'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Content',
    components: {
        'i-input': Input,
        'i-stock-search': StockSearch,
        'i-btn' :  Button,
        'i-datetime': DateTime,
    },
    data () {
        return {
        }
    },
    created () {
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
            item : state => state.earning.item
        }),
        ...mapGetters( 'page', ['isListPage', 'isDetailPage', 'isEditPage', 'isRegisterPage']),

        btnLabel: function() {
            if (this.isListPage) {
                return '등록'
            } else if (this.isDetailPage) {
                return '수정'
            } else {
                return null
            }
        }
    },
    methods: {
        ...mapMutations( 'page', ['setPageStatus']),
        ...mapMutations( 'earning', ['SET_DETAIL', 'INIT_ITEM', 'INIT_LIST']),

        ...mapActions( 'alert', ['showError', 'showMessage']),
        ...mapActions( 'earning', ['WRITE_DATE_ITEM', 'GET_DATE_LIST']),

        getStock(stock) {
            this.item.stockCode = stock.code
            this.item.stockName = stock.name
        },
        goListPage() {
            this.INIT_ITEM()
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        write() {
            var message

            // 빈값 체크
            if (!this.item.stockCode) {
                message = '종목을 입력해주세요.'
                this.showMessage({ vc:this, message:message })
                return
            }

            var params = {
                stockCode : this.item.stockCode,
                showDate : this.item.showDate,
            }

            if (this.isDetailPage) {
                params.id = this.item.id
            }

            this.WRITE_DATE_ITEM(params)
            .then(() => {
                message = this.btnLabel + '되었습니다.'
                this.showMessage({ vc:this, message:message })

                this.INIT_LIST()
                this.GET_DATE_LIST({})
                this.goListPage()
            })
            .catch((err) => {
                Debug.log('error = ', err)
                this.showError({ vc:this, error:err })
            })
        }
    }
}
</script>


