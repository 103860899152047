<template>
    <section class="row q-pa-lg">
        <q-toolbar class="i-top-wrap ">
            <div class="col-6 text-left">
            <p> 재전송 할 타입과 증권사를 선택하세요.</p>
            </div>
            
            <div class="col-6 ">
                <!-- kangwook todo: type이 컨센서스/월드인덱스/종목히스토리/이슈&테마스케줄/데일리테마/테마히스토리 일때 date 조회 되게 component 추가 -->               
                <div class="row">
                    <div v-if="showNewsFlash" class="row q-pa-xs">
                        <i-input v-model="startId" label="시작Id" />
                        <i-input v-model="endId" label="종료Id" />
                    </div>
                    <div v-else class="row q-pa-xs">
                        <i-datetime date v-model="startDate" dateLabel="시작일" />
                        <i-datetime date v-model="endDate" dateLabel="종료일" />
                    </div>
                    <div class="col q-pa-xs text-right"><i-btn label="재전송" @click="resend" /></div>
                </div>
            </div>
        </q-toolbar>
        <div class="col-6 q-pa-md">
            <q-table
                flat
                bordered
                class="i-table"
                :data="typeList"
                :columns="columns"
                row-key="value"
                selection="single"
                style="height:auto;"
                hide-bottom
                :rows-per-page-options="[0]"
                :selected.sync="selected"
                :selected-rows-label="getSelectedString"
                />

        </div>
        <div class="col-6 q-pa-md">
            <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : companyList.length == 0 }"
                            :columns="columns2" :data="companyList" row-key="code" :rows-per-page-options="[0]" style="height:auto;"
                            hide-pagination no-data-label="조회되는 데이터가 없습니다.">
                <template v-slot:header="props">
                    <q-tr :props="props">
                        <q-th v-for="col in props.cols" :key="col.name" :props="props" v-html="col.label"></q-th>
                    </q-tr>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td v-for="col in props.cols" :key="col.name" :props="props" @click="getCheckbox(props.row, col.name)">
                            <div v-if="col.name == 'check0'"><q-checkbox v-model="props.row.check0" :value ="props.row.check0" :true-value="props.row.code0" false-value="N" dense/></div>
                            <div v-else-if="col.name == 'check1' && props.row.code1 != null"><q-checkbox v-model="props.row.check1" :value ="props.row.check1" :true-value="props.row.code1" false-value="N" dense/></div>
                            <div v-else-if="col.name == 'check2' && props.row.code2 != null"><q-checkbox v-model="props.row.check2" :value ="props.row.check2" :true-value="props.row.code2" false-value="N" dense/></div>
                            <div v-else>{{ col.value }}</div>
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
            <!-- 하단 버튼 -->
            
        </div>
    </section>
</template>
<script>
import CONSTANTS from '@/constants'
import {  mapActions } from 'vuex'
import debug from '@/debug'
import Button from '@/components/common/Button'
import Input from '@/components/common/Input'
import DateTime from '@/components/common/DateTime'
//import { clone } from '@util'
export default {
    name: "B2bResend",
    components: {
        'i-btn': Button,
        'i-input' : Input,
        'i-datetime': DateTime,
    },
    data() {
        return {
            startId : '',
            endId : '',
            startDate : '',
            endDate : '',
            typeList : CONSTANTS.TRNSMISMANAGE_INFOSTOCK_B2B,
            columns: [
                { headerStyle:'text-align:center;', style:'width:*;', name: 'trnsmisNm', label: '타입', align: 'left', field: (row) => row.value },
              ],
            columns2: [
                { headerStyle:'text-align:center;', style:'width:*;', name: 'name0', label: '증권사', align: 'left', field:'name0' },
                { headerStyle:'text-align:center;', style:'width:10%;', name: 'check0', label: '전송여부', align: 'center' },
                { headerStyle:'text-align:center;', style:'width:*;', name: 'name1', label: '증권사', align: 'left', field:'name1' },
                { headerStyle:'text-align:center;', style:'width:10%;', name: 'check1', label: '전송여부', align: 'center' },
                { headerStyle:'text-align:center;', style:'width:*;', name: 'name2', label: '증권사', align: 'left', field:'name2' },
                { headerStyle:'text-align:center;', style:'width:10%;', name: 'check2', label: '전송여부', align: 'center' }
              ],
            companyList: [], 
            selected:[],
            companySelected:[],
        }
    },
    created() {
        this.getList()
    },
    computed : {
        showNewsFlash : function() {
            if (this.selected.length > 0) {
                return (this.selected[0].code === 'MARKET_FLASH'?true:false)
            } else {
                return false
            }
        },
    },
    methods: {
        ...mapActions( 'manage', ['GET_COMPANY_LIST','GET_CALL']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        getCheckbox(obj, name){
            debug.log('getCheckbox = ', obj, name)
            if(obj['check'+name.substring(4,5)] == 'N'){
                obj['check'+name.substring(4,5)] = obj['code'+name.substring(4,5)];
            }else{
                obj['check'+name.substring(4,5)] = 'N';
            }
        },
        getList() {
            this.GET_COMPANY_LIST()
                .then((data) => {
                    var iCnt = 0;
                    var ds_obj = {};
                    data.items.forEach(row => {
                        debug.log('row = ', row)
                        if(iCnt == 3){
                            iCnt = 0;
                            this.companyList.push(ds_obj);
                            ds_obj = {};
                        }
                        ds_obj['code'+iCnt] = row.code;
                        ds_obj['name'+iCnt] = row.name+'('+row.code+')';
                        ds_obj['check'+iCnt] = 'N';
                        iCnt++;
                    });
                    this.companyList.push(ds_obj);
                })
                .catch((err) => {
                    this.showError({ vc:this, error:err });
                })
        },
        getSelectedString() {
            debug.log('getSelectedString = ', this.selected.length)
            return this.selected.length === 0
                ? ''
                : `${this.selected.length}개 선택`
        },
        resend() {
            if(this.selected.length === 0) {
                this.showError({ vc:this, error:'재전송할 타입을 선택하세요.' });
                return
            }
            // 2022.01.12 suki - 재전송은 하나의 타입만 되게 수정
            let type = this.selected[0].code
            
            let companys = ''
            this.companyList.forEach(row => {
                for(let i =0; i < 3; i++) {
                    if(row['check'+i] && row['check'+i] !== 'N')
                    companys += row['check'+i] +';'
                }
                //if(row['check'+row.name.substring(4,5)] === 
            })

            debug.log('row = ', type)
            if(companys.length === 0) {
                this.showError({ vc:this, error:'재전송할 증권사를 선택하세요.' });
                return
            }
            companys = companys.slice(0, -1)
            
            // kangwook todo : 뉴스속보는 /newsflash/resend 에서 처리하게 수정 (parameter 값 확인 필요 )
            let param = {
                    companys:companys,
                    type:type,
                    startId:this.startId,
                    endId:this.endId,
                    startDate:this.startDate,
                    endDate:this.endDate,
                    'CALL_API_URL': '/trnsmis/resend'
                }
                debug.log("param=", param)

                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'전송을 요청하였습니다.' });
                }).catch((err)=>{
                    this.showError({ vc:this, error:err });
                });
        }
    },
};

</script>
