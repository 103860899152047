<template>
    <section class="row q-pl-xs">
        <q-toolbar class="row q-mt-md text-left">
            <div class="q-pa-xs"><q-input v-model="sendDate" bg-teal text-white outlined dense type="date"/></div>
            <div class="q-pa-xs"><i-select v-model="trnsmisId" :options="trnsmisIdOptions" option-value="trnsmisId" option-label="trnsmisNm" @input="getList" style="width:200px;"/></div>
            <div class="q-pa-xs"><i-btn icon="search" @click="getList()"/></div>
            <div class="q-pa-xs"><i-btn label="선택삭제" @click="getDeleteChk()"/> <i-btn label="선택확인" @click="getCnfirmChk()" v-show="role == 'superuser' || role == 'poweruser'"/></div>
        </q-toolbar>
        <div>
        <q-table flat bordered dense separator="cell" class="i-table" :class="{ 'no-data' : list.length == 0 }"
            :columns="columns" :data="list" row-key="RNUM" :rows-per-page-options="[0]" style="height:100%;"
            :pagination.sync="pagination" hide-pagination no-data-label="조회되는 데이터가 없습니다.">
            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th v-for="col in props.cols" :key="col.name" :props="props">
                        <div v-if="col.name == 'checked'"><q-checkbox v-model="allCheck" dense @input="getAllCheck()"/></div>
                        <div v-else>{{ col.label }}</div>
                    </q-th>
                </q-tr>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props" :style="(role == 'superuser' || role == 'poweruser') && props.row.status != 'cnfirm' ? 'background-color:rgb(220 240 230);' : 'background-color:white;'">
                    <q-td v-for="col in props.cols" :key="col.name" :props="props">
                        <div v-if="col.name == 'sendDate'">
                            {{ col.value }}
                            <div class="q-pa-xs" v-show="(role == 'superuser' || role == 'poweruser') && props.row.status != 'cnfirm'"><q-btn size="xs" label="확인" color="secondary" @click="getCnfirm(props.row)"/></div>
                        </div>
                        <div v-else-if="col.name == 'title'"><span @click="getViewPopup(props.row)" style="color:#551A8B;text-decoration:underline;cursor:pointer;">{{ col.value }}</span></div>
                        <div v-else-if="col.name == 'checked'"><q-checkbox v-model="props.row.checked" dense/></div>
                        <div v-else style="word-break:break-all;min-width:80px;white-space: pre-line;">{{ col.value }}</div>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:no-data="{ icon, message }"><div class="full-width row flex-center q-gutter-sm"><q-icon size="2em" :name="icon" /> <span>{{message}}</span></div></template>
        </q-table>
        <div v-show="list.length != 0" class="flex flex-center q-mt-md">
            <q-pagination
            v-model="pagination.page"
            :max="pagesNumber"
            :max-pages="pagination.maxPages"
            :direction-links="true"
            :boundary-numbers="false"
            :boundary-links="true"
            :ellipses="false"
            @click="getList()"
        />
        </div>
        <q-dialog v-model="viewPopup" persistent>
            <q-card style="min-width: 80%;overflow-y:hidden">
                <q-card-actions align="right" class="text-primary">
                    <q-btn color="white" text-color="black" label="닫기" v-close-popup/>
                </q-card-actions>
                <q-card-section v-html="viewContent" style="height:700px;overflow-y:scroll;"></q-card-section>
            </q-card>
        </q-dialog>
        </div>
    </section>
</template>
<script>
import Select from '@/components/common/Select.vue';
import Button from '@/components/common/Button';
import { FormatDate, FormatTime, stringToMapStockValue } from '@/util'
import { mapState, mapMutations, mapActions } from 'vuex'
import CONSTANTS from '@/constants'
import debug from '@/debug'
export default {
    name: "AdvertisingCnfirm",
    components: {
        'i-select' : Select,
        'i-btn': Button,
    },
    data() {
        return {
            trnsmisId: null,
            trnsmisIdOptions:[],
            sendDate: null,
            interval: null,
            viewPopup: false,
            viewContent: '',
            trClass: '',
            allCheck: false,
            selected: [],
            stock: '',
            stockOptions: [],
            stockList: [],
            columns: [
                { headerStyle:'text-align:center;', name: 'sendDate', label: '날짜', align: 'center', field: (row) => FormatDate(row.sendDate) },
                { headerStyle:'text-align:center;', name: 'sendTime', label: '시간', align: 'center', field: (row) => FormatTime(row.sendTime) },
                { headerStyle:'text-align:center;', name: 'trnsmisNm', label: '광고사', align: 'center', field: (row) => row.trnsmisNm },
                { headerStyle:'text-align:center;', name: 'newsType2', label: '광고번호', align: 'center', field: (row) => row.newsType2 },
                { headerStyle:'text-align:center;', name: 'stocks1',  label: '종목1', align: 'center', field: (row) => stringToMapStockValue('stocks1', row.stocks, this.stockList) },
                { headerStyle:'text-align:center;', name: 'stocks2',  label: '종목2', align: 'center', field: (row) => stringToMapStockValue('stocks2', row.stocks, this.stockList) },
                { headerStyle:'text-align:center;', name: 'stocks3',  label: '종목3', align: 'center', field: (row) => stringToMapStockValue('stocks3', row.stocks, this.stockList) },
                { headerStyle:'text-align:center;', name: 'stocks4',  label: '종목4', align: 'center', field: (row) => stringToMapStockValue('stocks4', row.stocks, this.stockList) },
                { headerStyle:'text-align:center;', name: 'stocks5',  label: '종목5', align: 'center', field: (row) => stringToMapStockValue('stocks5', row.stocks, this.stockList) },
                { headerStyle:'text-align:center;', name: 'title',  label: '제목', align: 'left', field:'title'},
                 { headerStyle:'text-align:center;', name: 'checked',  label: 'checked', align: 'center'}
              ],
              page: 1,
            pagination: {
                page: 1,
                rowsPerPage: CONSTANTS.LIST_COUNT_PER_PAGE,
                maxPages: 1
            },
        }
    },
    computed: {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            stockItems : state => state.resource.stockItems
        }),
        ...mapState('manage', ['list', 'totcount']),
        pagesNumber () {
            return Math.ceil(Number(this.totcount) / Number(this.pagination.rowsPerPage));
        }
    },
    created () {
        this.getPage()
        this.getSelectInit();
    },
    beforeDestroy: function(){
        clearInterval(this.interval);
    },
    methods: {
        ...mapMutations('manage', ['SET_DATA', 'INIT_DATA_VIEW', 'SET_DATA_VIEW']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        //페이지 검색
        getPage() {
            let param = {
                CALL_API_URL: '/adnews/page'
            }
            this.GET_CALL(param).then((data) => {
                this.trnsmisIdOptions = data.selectList;
                this.trnsmisId = this.trnsmisIdOptions[0];
                this.getList()
                this.ready()
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        //리스트
        getList() {
            debug.log('getlist page !!!!', this.page , this.pagination.page)
            var sTrnsmisId = "";
            if(this.trnsmisId != null){
                sTrnsmisId = this.trnsmisId.trnsmisId;
            }
            let nextKey = null
            if(this.page < this.pagination.page) {
                nextKey = this.list[this.list.length-1].lastUpdateTime
            } else if(this.page > this.pagination.page) {
                nextKey = this.list[0].lastUpdateTime
            }
            this.page = this.pagination.page

            if(this.page == 1) nextKey = null
            let param = {
                client: sTrnsmisId,
                sendDate: this.sendDate,
                pageStr: (Number(this.pagination.page) - 1) * Number(this.pagination.rowsPerPage),
                pageSize: this.pagination.rowsPerPage,
                nextKey:nextKey,
                CALL_API_URL: '/adnews/list'
            }
            this.GET_CALL(param).then((data) => {
                data.items.forEach(row => {
                    row.checked = this.allCheck;
                });
                this.SET_DATA(data);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        //선택삭제
        getDeleteChk() {
            var iCnt = 0;
            var ds_list = [];
            this.list.forEach(row => {
                if(row.checked){
                    iCnt++;
                    ds_list.push({
                        id : row['id']
                    });
                }
            });
            if(iCnt == 0){
                this.showMessage({ vc:this, message:'체크항목을 선택하세요.' });
                return;
            }
            this.showDeleteAlert({ vc:this, message: iCnt+'건 <span style="color:red;">선택삭제</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    ds_list,
                    'CALL_API_URL': '/adnews/deleteChk'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'선택삭제 되었습니다.' });
                    this.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },
        //확인
        getCnfirm(obj) {
            this.showAlert({ vc:this, message:'<span style="color:#1976D2;">확인</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    'id': obj.id,
                    'CALL_API_URL': '/adnews/saveCnfirm'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'확인 되었습니다.' });
                    this.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },
        //선택확인
        getCnfirmChk() {
            var iCnt = 0;
            var ds_list = [];
            this.list.forEach(row => {
                if(row.checked){
                    iCnt++;
                    ds_list.push({
                        id : row['id']
                    });
                }
            });
            if(iCnt == 0){
                this.showMessage({ vc:this, message:'체크항목을 선택하세요.' });
                return;
            }
            this.showAlert({ vc:this, message: iCnt+'건<span style="color:#1976D2;">선택확인</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    ds_list,
                    'CALL_API_URL': '/adnews/saveCnfirmChk'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'선택확인 되었습니다.' });
                    this.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },
        //전체선택
        getAllCheck(){
            this.list.forEach(row => {
                row.checked = this.allCheck;
            });
        },
        //팝업
        getViewPopup(obj) {
            this.viewContent = obj.content;
            this.viewPopup = true;
        },
        getSelectInit(){
            var list = [];
            try {
                list = JSON.parse(this.stockItems);
            } catch (e) {
                list = this.stockItems;
            }
            var ds_list = [];
            list.forEach(row => {
                ds_list.push({
                    code : row['code'],
                    name : row['name'] + ' (' + row['code'] + ')'
                });
            });
            this.stockList = ds_list;
        },
        ready() {
            this.fetch();

            // this.interval = setInterval(function () {
            //     this.fetch();
            // }.bind(this), 30000); 
        },
        fetch() {
            let param = {
                    'CALL_API_URL': '/adnews/fetch'
                }
                this.GET_CALL(param).then((data) => {
                    //debug.log(data)
                    if(data.items.length > 0) {
                        this.showMessage({ vc:this, message:'신규 광고가 등록되었습니다.' });
                        this.getList();
                    }
                    
                }).catch(() => {
                    //this.showError({ vc:this, error:error })
                });
            
        }
    }
};

</script>
