<template>
    <section class="row q-pl-xs">
        <q-toolbar class="row q-mt-md text-left">
            <div class="q-pa-xs"><q-input v-model="sendDate" bg-teal text-white outlined dense type="date"/></div>
            <div class="q-pa-xs"><i-select v-model="trnsmisId" :options="trnsmisIdOptions" option-value="trnsmisId" option-label="trnsmisNm" @input="getList" style="width:200px;" v-show="role == 'superuser' || role == 'poweruser'"/></div>
            <div class="q-pa-xs"><i-btn icon="search" @click="getList()"/></div>
            <!-- 220708 meej 리스트 재조회 버튼 추가 -->
            <div class="q-pa-xs"><q-btn round size="md" icon="refresh" @click="getListAll()"/></div>
            <div class="col q-pa-xs text-right"><i-btn label="신규등록" @click="getView(null)" v-show="view == 'N'"/></div>
        </q-toolbar>
        <!-- 220707 meej height 추가 -->
        <i-list style="height:100%" :class="listClass" ref="IRflashManageList"/>
        <i-content class="col-6 q-pr-md" ref="IRflashManageContent" v-show="view == 'Y'"/>
    </section>
</template>
<script>
import Select from '@/components/common/Select.vue';
import Button from '@/components/common/Button'
import List from "@/components/marketing/IRManage/List.vue";
import Content from "@/components/marketing/IRManage/Content.vue";
import { mapState, mapActions } from 'vuex'

export default {
    name: "IRManage",
    components: {
        'i-select' : Select,
        'i-btn': Button,
        "i-list": List,
        'i-content': Content
    },
    data() {
        return {
            trnsmisId: null,
            trnsmisIdOptions:[],
            sendDate: '',
            view:'N',
            listClass: 'col-12 q-pa-md'
        }
    },
    computed: {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId
        }),
    },
    created () {
        this.getPage();
    },
    methods: {
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        //페이지 검색
        getPage() {
            let param = {
                CALL_API_URL: '/ir/manage/page'
            }
            this.GET_CALL(param).then((data) => {
                // 221116 meej 건설경제 => 대한경제 변경
                data.selectList.forEach((el)=>{
                    if(el.trnsmisNm == "건설경제") {
                        el.trnsmisNm = "대한경제"
                    }
                })
                this.trnsmisIdOptions = data.selectList;
                this.trnsmisId = this.trnsmisIdOptions[0];
                this.getList();
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        // refresh 조회
        getListAll() {
            // 220708 meej 검색조건 초기화
            this.sendDate = ''
            this.$refs.IRflashManageList.getList();
        },
        getList() {
            this.$refs.IRflashManageList.getList();
        },
        getView(item) {
            this.$refs.IRflashManageList.getView(item);
        },
        getContentClose(val) {
            if(val == ''){
                this.view = 'N';
                this.listClass = 'col-12 q-pa-md';
            }else{
                this.view = 'Y';
                this.listClass = 'col-6 q-pa-md';
            }
        }
    }
};

</script>
