<template>
    <article>
        <!-- 구분 -->
        <div class="row i-top-wrap q-mb-sm">
            <div class="col-12 col-lg-8 q-pa-xs">
                <i-select v-model="gubun" :options="gubunOptions" label="분류"
                    @input="getList()" option-label="name" option-value="value"/>
            </div>
            <div v-if="gubun.value!=4" class="col-12 col-lg-2 q-pa-xs">
                <i-btn label="신문 올리기" @click="goPaper()"/>
            </div>
            <div v-if="gubun.value!=4" class="col-12 col-lg-2 q-pa-xs">
                <i-btn label="신문 내리기" @click="delPaper()"/>
            </div>       
            <div v-if="gubun.value==4" class="col-12 col-lg-2 q-pa-xs">
                <i-btn label="홈페이지 올리기" @click="gohomepage()"/>
            </div>
            <div v-if="gubun.value==4" class="col-12 col-lg-2 q-pa-xs">
                <i-btn label="홈페이지 내리기" @click="delhomepage()"/>
            </div>                     
        </div>
        <div class="row i-top-wrap q-mb-sm">
            <div class="col-12 col-lg-3 q-pa-xs">
                <i-input v-model="kospi" label="시총상위(코스피)" />
            </div>
            <div class="col-12 col-lg-3 q-pa-xs">
                <i-input v-model="kosdaq" label="시총상위(코스닥)" />
            </div>
            <div class="col-12 col-lg-4 q-px-xs">
                <i-datetime date v-model="consen" dateLabel="컨센 기준일" />
            </div>
            <div class="col-12 col-lg-2 q-pa-xs">
                <i-btn label="수정" @click="goEdit()"/>
            </div>
            <div class="col-12 col-lg-12 q-pa-xs">
                <i-input  type="textarea" v-model="memo" :rows="5" label="메모"/>
            </div>
        </div>
        <div v-if="gubun.value==1" id="data">
            <p v-html="data.table1"></p>           
        </div>
        <div v-if="gubun.value==2" id="data">
            <p v-html="data.table2"></p>          
        </div>      
        <div v-if="gubun.value==3" id="data">
            <p v-html="data.table3"></p>       
        </div>      
        <div v-if="gubun.value==4" id="data">
            <p v-html="data.table4"></p>       
        </div>             

    </article>
</template>
<script>
// import Debug from '@/debug'
// import CONSTANTS from '@/constants'
import { setDay, setQuarter, year, quarter, today } from '@/util'
import Select from '@/components/common/Select'
import Input from '@/components/common/Input'
import DateTime from '@/components/common/DateTime'
import Button from '@/components/common/Button'

import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    name: 'List',
    components: {
        'i-select' : Select,
        'i-input' : Input,
        'i-datetime': DateTime,
        'i-btn' : Button,
    },
    data () {
        return {
            kospi:'',
            kosdaq:'',
            consen:'',
            memo:'',
            // 220722 meej 실적 미리보기에서 사용_오후4시이후 다음날로 설정 (2,3번만 해당)
            gubun: { name: '1. 주요기업 영업이익 예상치 및 실적 발표 일정 ('+year(1,0)+'년 '+quarter(-1)+'분기, '+today("YYYY.MM.DD")+' 기준, 연결)', value: '1'},
            gubunOptions: [
                { name: '1. 주요기업 영업이익 예상치 및 실적 발표 일정 ('+year(1,0)+'년 '+quarter(-1)+'분기, '+today("YYYY.MM.DD")+' 기준, 연결)', value: '1'},
                { name: '2. 당일 '+setQuarter(1,-1)+'분기 실적 발표 예정 주요 기업('+setDay("YYYY.MM.DD")+' 발표 예정)', value: '2'},
                { name: '3. 전일 '+setQuarter(1,-1)+'분기 실적 발표 주요 기업(전거래일 발표)', value: '3'},
                { name: '(홈페이지) '+setQuarter(1,-1)+'분기 주요 기업 실적', value: '4'},
            //     { name: '2. 당일 '+quarter(-1)+'분기 실적 발표 예정 주요 기업('+today("YYYY.MM.DD")+' 발표 예정)', value: '2'},
            //     { name: '3. 전일 '+quarter(-1)+'분기 실적 발표 주요 기업(전거래일 발표)', value: '3'},
            ],
        }
    },
    created () {
        this.GET_SETTINGS()
        .then((res)=> {
            this.kospi = (res===undefined? '': res.RANKTOP_KOSPI);
            this.kosdaq = (res===undefined? '': res.RANKTOP_KOSDAQ);
            this.consen = (res===undefined? '': res.CONSENSUS_DATE);
            this.memo = (res===undefined? '': res.EARNING_MEMO);
        })      
        this.getList()  
    },
    computed : {
        ...mapState ({
            data : state => state.preview.data,
            settings: state => state.resource.settings,
        }),
    },
    methods: {
        ...mapMutations( 'page', ['setPageStatus']),
        ...mapActions( 'preview', ['SETTING_LIST','DELETE_PAPER','DELETE_EARNING']),
        ...mapActions( 'alert', ['showError', 'showMessage']),
        ...mapActions( 'resource', ['SET_SETTINGS', 'GET_SETTINGS']),
        // 목록 조회하기
        getList() {
                // var params = {
                //     gubun: this.gubun.value,
                //     title: this.gubun.name.substring(3),
                // }
                var params = {}
                params.gubun = this.gubun.value
                params.title = this.gubun.name.substring(3)
                // 220722 meej 실적 미리보기에서 사용_오후4시이후 다음날로 설정(2,3번만 해당)_다음날로 설정되었을경우 date 보내기
                if(this.gubun.value != "1" && setDay("YYYYMMDD") != today("YYYYMMDD")){
                    params.date = setDay("YYYYMMDD")
                }
                this.SETTING_LIST(params)
        },
        // 수정하기
        goEdit() {
            let params = {
                items:[{
                    RANKTOP_KOSPI: this.kospi
                },
                {
                    RANKTOP_KOSDAQ: this.kosdaq
                },
                {  
                    CONSENSUS_DATE: this.consen
                },
                {  
                    EARNING_MEMO: this.memo
                }]
            }
            this.SET_SETTINGS(params)
            .then(()=>{
                this.showMessage({vc:this, message:'수정 완료'})
                this.getList()
            })
            .catch((err) => {
                this.showError({ vc:this, error:err})
            })
        },
        goPaper(){
            var params = {
                gubun: '5',
                title: this.gubun.name.substring(3),
            }
            this.SETTING_LIST(params)  
            .then(()=>{
                this.showMessage({vc:this, message:'업데이트 완료'})

            })  
            .catch((err) => {
                this.showError({ vc:this, error:err})
            })        
        },
        gohomepage(){
            var params = {
                gubun: '6',
            }
            this.SETTING_LIST(params)  
            .then(()=>{
                this.showMessage({vc:this, message:'게시 완료'})

            })  
            .catch((err) => {
                this.showError({ vc:this, error:err})
            })        
        },
        delPaper(){
            var params = {
                gubun: '1'
            }
            this.DELETE_PAPER(params)  
            .then(()=>{
                this.showMessage({vc:this, message:'삭제 완료'})

            })  
            .catch((err) => {
                this.showError({ vc:this, error:err})
            }) 
            this.SETTING_LIST(params)        
        },
        delhomepage(){
            var params = {
                gubun: '4',
                year:year(1,0),
                quarter:quarter(-1)
            }
            this.DELETE_EARNING(params)  
            .then(()=>{
                this.showMessage({vc:this, message:'삭제 완료'})

            })  
            .catch((err) => {
                this.showError({ vc:this, error:err})
            }) 
            this.SETTING_LIST(params)        
        }        
    },
    watch: {
        settings(val, old) {
            if(val !== old) {
                this.kospi = (this.settings===undefined? '': this.settings.RANKTOP_KOSPI);
                this.kosdaq = (this.settings===undefined? '': this.settings.RANKTOP_KOSDAQ);
                this.consen = (this.settings===undefined? '': this.settings.CONSENSUS_DATE);
                this.memo = (this.settings===undefined? '': this.settings.EARNING_MEMO);
            }
        }
    }
}
</script>


