<template>
    <article>
      <!-- 221019 meej 테마 히스토리 등록 팝업 -->
        <q-scroll-area visible class="i-content-wrap">
            <q-card flat bordered>
                <q-card-section>
                  <div class="q-mb-xs">테마 히스토리</div>
                  <div class="q-mb-md bg-grey-4">
                    <h6>{{propsContent != null && propsContent.themeName != null ? propsContent.themeName:""}}</h6>
                  </div>
                    <i-datetime
                        class="row q-my-xs"
                        date
                        v-model="insertDate"
                        dateLabel="입력날짜"
                    />
                    <div class="row q-my-xs">
                        <q-btn
                            class="col-md-2 text-left focus-non"
                            flat
                            rounded
                            color="primary"
                            label="관련종목"
                        />
                        <div class="col-md-8 text-left">
                            <!-- <q-chip
                                v-for="(stock, i) in checkList.stocks"
                                :key="'stock' + i"
                                color="secondary"
                                text-color="white"
                                removable
                                @remove="removeStock(i)"
                            > -->
                            <q-chip
                                v-for="(stock, i) in checkList.stocks"
                                :key="'stock' + i"
                                color="secondary"
                                text-color="white"
                            >
                                {{ stock.stockName }}({{ stock.stockCode }})
                            </q-chip>
                        </div>
                    </div>
                    <div class="row q-my-xs">
                        <q-btn
                            class="col-md-2 text-left focus-non"
                            flat
                            rounded
                            color="primary"
                            label="주도주"
                        />
                        <div class="col-md-8 text-left">
                            <!-- <q-chip
                                v-for="(stock, i) in checkList.leads"
                                :key="'stock' + i"
                                color="secondary"
                                text-color="white"
                                removable
                                @remove="removeLEAD(i)"
                            > -->
                            <q-chip
                                v-for="(stock, i) in checkList.leads"
                                :key="'stock' + i"
                                color="secondary"
                                text-color="white"
                            >
                                {{ stock.stockName }}({{ stock.stockCode }})
                            </q-chip>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 q-pa-xs">
                        <i-input
                            v-model="content"
                            type="textarea"
                            inputLabel="히스토리"
                            :rows="15"
                            class="full-width"
                        />
                    </div>
                    <div class="col-12 ">
                        <i-field inputLabel="차트표시" check v-model="chart" />
                    </div>
                </q-card-section>
            </q-card>
        </q-scroll-area>
        <!-- 하단 버튼 -->
        <div class="q-mt-md">
            <i-btn
                v-if="propsContent && propsContent.showFlag==1 && propsContent.crud!='D'"
                class="full-width"
                label="수정"
                @click="register()"
            />
            <i-btn
                v-else
                class="full-width"
                label="등록"
                @click="register()"
            />
        </div>
    </article>
</template>
<script>
import Debug from '@/debug'
import {
    optionsValue,
    mapOptionsValue,
    objToString,
    stringToArray,
    FormatDate,
    isEmpty,
} from '@/util'

import Button from '@/components/common/Button'
import Field from '@/components/common/Field'
import Input from '@/components/common/Input'
import DateTime from '@/components/common/DateTime'

import { mapState, mapActions, mapMutations } from 'vuex'

export default {
    name: 'List',
    components: {
        'i-btn': Button,
        'i-field': Field,
        'i-input': Input,
        'i-datetime': DateTime,
    },
    props: {
        theme: {
            type: String,
            default: null,
        },
        propsContent : {
            type: Object,
            default : null,
        }
    },
    data() {
        return {
            content: '',
            chart: false,
            checkDelete: false,
            comment: '',
            disableDelete: false,
            insertDate: '',
        }
    },
    created() {

    },
    computed: {
        ...mapState({
            item: (state) => state.theme_news.item,
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            checkList: (state) => state.theme_stock.checkList,
            selectedDate: (state) => state.theme_stock.selectedDate,
        }),
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
        }),
        ...mapActions('theme_news', ['WRITE_ITEM', 'DELETE_HARD_ITEM', 'ASYNC_THEMENEWS_LIST', 'GET_THEMENEWS_LIST']),
        ...mapMutations('theme_stock', [
            'REMOVE_CHECKLIST_ITEM',
            'INIT_CHECKLIST',
            'SET_CHECKLIST',
            'SET_SELETED_DATE'
        ]),
        ...mapActions( 'theme_featured', ['GET_DETAIL']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showConfirm']),

        initItem() {
            if (this.propsContent !== undefined && this.propsContent !== null) {
                this.INIT_CHECKLIST()

                this.chart = this.propsContent.CHART == "0"? false : true
                let stocks = stringToArray(
                    this.propsContent.STOCKS,
                    'stockName',
                    'stockCode'
                )
                let leads = stringToArray(
                    this.propsContent.LEAD_STOCK,
                    'stockName',
                    'stockCode'
                )
                Debug.log('stocks ', stocks, 'ledads', leads)
                this.SET_CHECKLIST({
                    stocks: stocks ? stocks : [],
                    leads: leads ? leads : [],
                })

                this.content = this.propsContent.content
                this.checkDelete = this.propsContent.crud === 'D'
                this.comment = isEmpty(this.propsContent.COMMENT)
                    ? ''
                    : this.propsContent.COMMENT
                this.disableDelete = this.checkDelete
            }
        },
        register() {
            const params = {
                id : this.$route.query.id,
                newsType: 'THEME_HISTORY',
                themeCode: this.theme,
                content: this.content,
                createTime: this.insertDate,
            }
            params.options = this.makeOptions()
            this.showAlert({ vc: this, message: '등록하시겠습니까?' }).then(
                () => {
                    this.write(params, '등록되었습니다.')
                }
            )
        },
        makeOptions() {
            let options = {}
            if (this.checkList.stocks && this.checkList.stocks.length > 0) {
                var obj = {}
                for (var stock of this.checkList.stocks) {
                    obj[stock.stockCode] = stock.stockName
                }
                options.STOCKS = optionsValue(obj)
            }
            if (this.checkList.leads && this.checkList.leads.length > 0) {
                // var obj2 = {}
                // for (var lead of this.checkList.leads) {
                //     obj2[lead.stockCode] = lead.stockName
                // }
                // options.LEAD_STOCK = optionsValue(obj2)
                var obj2 = new Map();
                for (var lead of this.checkList.leads) {
                    obj2.set(lead.stockCode, lead.stockName);
                }
                options.LEAD_STOCK = mapOptionsValue(obj2)
            }
            options.CHART = this.chart ? 1 : 0

            return objToString(options)
        },
        write(params, message) {
            this.WRITE_ITEM(params)
                .then(() => {
                if(this.$route.query.id != this.propsContent.id){
                    const param = {
                        id: this.propsContent.id,
                        newsType: 'THEME_HISTORY',
                    }
                    this.DELETE_HARD_ITEM(param)
                }
                  this.showConfirm({ vc: this, message: message })
                      .then(() => { 
                        window.close(); 
                    })
                })
                .catch((err) => {
                    this.showError({ vc: this, error: err })
                })
        },
        removeStock(index) {
            this.REMOVE_CHECKLIST_ITEM({ stock: index })
        },
        removeLEAD(index) {
            this.REMOVE_CHECKLIST_ITEM({ LEAD: index })
        },
        formatDate(val) {
            return isEmpty(val) ? '' : FormatDate(val)
        },
    },
    watch: {
        item(val, old) {
            if (val !== old) {
                if (val !== null) {
                    this.initItem()
                }
            }
        },
        insertDate(val){
            this.SET_SELETED_DATE(val)
        },
        selectedDate(val){
            this.insertDate = val
        },
        propsContent(){
            if(this.propsContent != null){
                this.content = this.propsContent.content
                if(this.propsContent.showFlag == 1){
                    this.initItem()
                }
            } 
        },
    },
}
</script>
<style>
</style>
