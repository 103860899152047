<template>
    <article>
        <q-dialog v-model="detailsPopup" persistent>
            <q-card style="min-width: 70%;min-height:50%;" >
                <q-card-section >
                    <div class="row q-my-sm">
                        <div class="col-12 col-sm-3">
                            <div style="padding: 10px 0px 20px 30px;"><label class="i-label" style="color:gray; font-size: 15px;">요청자 {{requester}}</label></div>
                            
                        </div>
                        <div class="col-12 col-sm-3">
                            <div style="padding: 10px 0px 20px 30px;"><label class="i-label" style="color:gray; font-size: 15px;">요청일 {{request_date}}</label></div>
                        </div>
                        
                    </div>

                    <body class="wrap">
                        <div>
                            <div class="row q-my-sm" style="padding:5px 20px 10px 10px; font-size: 15px; font-family:Tahoma, Arial;"> <div style="color:gray; font-size: 15px; padding-right: 10px; ">부서</div> {{department}}</div>
                            <div class="row q-my-sm" style="padding:5px 20px 10px 10px; font-size: 15px; font-family:Tahoma, Arial;"> <div style="color:gray; font-size: 15px; padding-right: 10px; ">프로젝트명</div> {{project}}</div>
                            <div class="row q-my-sm" style="padding:5px 20px 10px 10px; font-size: 15px; font-family:Tahoma, Arial;"> <div style="color:gray; font-size: 15px; padding-right: 10px; ">내용</div> <div v-html="content2"></div></div>
                            <div class="row q-my-sm" style="padding:5px 20px 10px 10px; font-size: 15px; font-family:Tahoma, Arial;"> <div style="color:gray; font-size: 15px; padding-right: 10px; ">긴급도</div> {{urgency}}</div>
                            <div class="row q-my-sm" style="padding:5px 20px 10px 10px; font-size: 15px; font-family:Tahoma, Arial;"> <div style="color:gray; font-size: 15px; padding-right: 10px; ">중요도</div> {{importance}}</div>
                        </div>
                    </body>
                </q-card-section>
                <q-card-actions align="center" class="text-primary">
                    <q-btn color="white" text-color="black" label="닫기" v-close-popup/>
                </q-card-actions>
            </q-card>
        </q-dialog>
    </article>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { FormatDate} from '@/util'

export default {
    name: 'Workmanager-List',
    
    data () {
        return {
            detailsPopup: false,
            requester: '',
            request_date: '',
            department: '',
            project: '',
            content: '',
            importance: '',
            urgency: '',
        }
    },
    computed : {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
        }),

        content2() {return this.content.replace("\n", "<br />")}
    },
    
    methods: {

        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),


        initData() {
            this.requester = '';
            this.request_date ='';
            this.department ='';
            this.project = '';
            this.content = '';
            this.importance = '';
            this.urgency = '';
        },
        
        // 상세
        getDetailsPopup(item) {
            let param = {
                'request_id': item.request_id,
                'CALL_API_URL': '/request/detail'
            }
            
            this.GET_CALL(param).then((data) => {
                this.requester = data.requester,
                this.request_date = FormatDate(data.request_date),
                this.department = data.department,
                this.project = data.project,
                this.content = data.content.replaceAll('\n', '<br>'),
                this.importance = data.importance,
                this.urgency = data.urgency,
                this.detailsPopup = true;
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
            
            
        },

        //팝업닫기
        // getClosePopup() {
        //     this.initData(),
        //     this.detailsPopup = false;
        // },
    }

}

</script>
<style>

.wrap {
    border: 1px;
    padding: 0px 10px 10px 10px;
}
</style>