<template>
    <section class="row q-pl-xs">
        <div class="col-12 q-pa-md">
            <q-toolbar class="row q-mt-md">
                <div class="col q-pa-xs">
                <i-input type="textarea" v-model="note" 
                        :rows="10"
                        inputLabel="비고"
                        class="col-6"/>
                </div>
                <i-btn label="수정" @click="updateNote" dense />
                
            </q-toolbar>
            <q-table class="q-mt-xs" flat bordered dense separator="cell"  :class="{ 'no-data' : list.length == 0 }"
                    :columns="columns" :data="list" row-key="RNUM" :rows-per-page-options="[0]" style="height:auto;"
                    :pagination.sync="pagination" hide-pagination no-data-label="조회되는 데이터가 없습니다." >
                    <template v-slot:header="props">
                        <q-tr :props="props">
                            <q-th v-for="col in props.cols" :key="col.name" :props="props" >
                                <div v-if="col.name == 'stockCode'">
                                    <q-select v-model="stockCode" :options="stockOptions" option-label="name" @filter="filterFn" input-debounce="0" dense outlined :hide-selected="hide_selected" :fill-input="hide_selected" use-input hide-dropdown-icon options-dense >
                                        <q-tooltip anchor="top middle" self="bottom middle" :offset="[5, 5]">한글로 검색 시, enter를 눌러주세요.</q-tooltip>
                                        <template v-slot:no-option><q-item><q-item-section class="text-grey">검색된 데이터가 없습니다.</q-item-section></q-item></template>
                                    </q-select>
                                </div>
                                <div v-else-if="col.name == 'type'"><i-select v-model="type" :options="typeOptions" option-value="value" option-label="name" @input="selectType"/>
                                </div>
                                <div v-else-if="col.name == 'ifrs'"><i-select v-model="ifrs" :options="ifrsOptions" option-value="value" option-label="name" />
                                </div>
                                <div v-else-if="col.name == 'year'"><i-select v-model="year" :options="yearOptions" option-value="value" option-label="name" />
                                </div>
                                <div v-else-if="col.name == 'quarter'"><i-select v-model="quarter" :options="quarterOptions" option-value="value" option-label="name" />
                                </div>
                                <div v-else-if="col.name == 'updateTime'"><i-datetime time v-model="updateTime"/>
                                </div>
                                <div v-else-if="col.name == 'sales'"><q-input v-model="sales" type="number" outlined dense input-class="text-right"  label="매출액"/>
                                </div>
                                <div v-else-if="col.name == 'operatingIncome'"><q-input v-model="operatingIncome" type="number" outlined dense input-class="text-right" label="영업이익"/>
                                </div>
                                <div v-else-if="col.name == 'pretaxIncome'"><q-input v-model="pretaxIncome" type="number" outlined dense input-class="text-right" label="세전 영업이익"/>
                                </div>
                                <div v-else-if="col.name == 'netIncome'"><q-input v-model="netIncome" type="number" outlined dense input-class="text-right"  label="순이익"/>
                                </div>
                                <div v-else-if="col.name == 'ciNetIncome'"><q-input v-model="ciNetIncome" type="number" outlined dense input-class="text-right"  label="지배주주 순이익"/>
                                </div>
                                <div v-else-if="col.name == 'unit'"><i-select v-model="unit" :options="unitOptions" option-value="value" option-label="name" />
                                </div>
                                <div v-else-if="col.name == 'memo'"><i-input v-model="memo" label="비고"/>
                                </div>
                                <div v-else-if="col.name =='checked'" >
                                    <span class="q-pa-xs" v-show="btnChk == true"><q-btn size="xs" label="등록" @click="getSave()" color="dark" /></span>
                                    <span class="q-pa-xs" v-show="btnChk == false"><q-btn size="xs" label="수정" @click="getSave()" color="dark" /></span>
                                    <span class="q-pa-xs"><q-btn size="xs" icon="search" @click="getList()" /></span>

                                </div>

                            </q-th>
                        </q-tr>
                        <q-tr :props="props">
                            <q-th v-for="col in props.cols" :key="col.name" :props="props" :style="col.style">
                                <!-- <div v-if="col.name == 'checked'"><q-checkbox v-model="allCheck" dense @input="getAllCheck()"/></div>
                                <div v-else>{{ col.label }}</div> -->
                                {{ col.label }}
                            </q-th>
                        </q-tr>


                    </template>
                    <template v-slot:body="props">
                        <q-tr :props="props">
                            <q-td v-for="col in props.cols" :key="col.name" :props="props" @click="getView(props.row)" style="cursor:pointer;">
                                <!-- <div v-if="col.name == 'checked'"><q-checkbox v-model="props.row.checked" dense/></div>
                                <div v-else>{{ col.value }}</div> -->
                                <div v-if="col.name == 'checked'">
                                    <span class="q-pa-xs"><q-btn size="xs" outline color="primary"  label="수정" @click="getView(props.row)"/></span>
                                    <span class="q-pa-xs"><q-btn size="xs" label="삭제" color="red" @click.stop="getDelete(props.row)" /></span>
                                </div>
                                <div v-else>{{ col.value }}</div>

                            </q-td>
                        </q-tr>
                    </template>
                    <template v-slot:no-data="{ icon, message }"><div class="full-width row flex-center q-gutter-sm"><q-icon size="2em" :name="icon" /> <span>{{message}}</span></div></template>
                </q-table>
                <div v-show="list.length != 0" class="flex flex-center q-mt-md">
                    <q-pagination
                    v-model="pagination.page"
                    :max="pagesNumber"
                    :max-pages="pagination.maxPages"
                    :direction-links="true"
                    :boundary-numbers="false"
                    :boundary-links="true"
                    :ellipses="false"
                    @click="getList()"
                />
                </div>
        </div>
    </section>
</template>
<script>
import CONSTANTS from '@/constants'
import Select from '@/components/common/Select.vue';
import DateTime from "@/components/common/DateTime";
import Input from "@/components/common/Input";
import Button from "@/components/common/Button";
import debug from"@/debug"
import { FormatTime, numberFormat, stringToMapValue, stringToStockValue } from '@/util'
import { mapState, mapMutations, mapActions } from 'vuex'


export default {
    name: "Earning",
    props: {
        hide_selected: {
            type: Boolean,
            default: true
        }
    },
    components: {
        "i-input": Input,
        "i-btn": Button,
        'i-select' : Select,
        "i-datetime": DateTime,
    },
    data() {
        return {
            note:'',
            btnChk: true,
            id: '',
            sales: '',
            operatingIncome: '',
            pretaxIncome: '',
            netIncome: '',
            ciNetIncome: '',
            memo: '',
            updateTime: '00',
            stockList: [],
            stockCode: '',
            stockOptions: [],
            columns:[],
            year: {'value':'', 'name':'연도'},
            yearOptions: [{'value':'', 'name':'연도'}],
            quarter:{'value':'', 'name':'분기'},
            quarterOptions: [{'value':'', 'name':'분기'}].concat(CONSTANTS.COM_QUARTER),
            type: {'value':'', 'name':'구분'},
            typeOptions: [{'value':'', 'name':'구분'}].concat(CONSTANTS.EARNING_TYPE),
            ifrs: CONSTANTS.COM_IFRS[0],
            ifrsOptions: CONSTANTS.COM_IFRS,
            unit: CONSTANTS.COM_UNIT[3],
            unitOptions: CONSTANTS.COM_UNIT,
            allCheck: false,
            typeValue : CONSTANTS.EARNING_TYPE2,
            ifrsValue : CONSTANTS.COM_IFRS2,
            quarterValue : CONSTANTS.COM_QUARTER2,
            unitValue: CONSTANTS.COM_UNIT2,
            provEarningColumns: [
                    { headerStyle:'text-align:center;', name: 'stockCode', label: '종목', align: 'center', field: (row) => stringToStockValue(row.stockCode, this.stockItems) + ' (' + row.stockCode + ')' },
                    { headerStyle:'text-align:center;', name: 'type', label: '구분', align: 'center', field: (row) => this.typeValue[row.type] },
                    { headerStyle:'text-align:center;', name: 'ifrs',  label: '연결/별도', align: 'center', field: (row) => this.ifrsValue[row.ifrs] },
                    { headerStyle:'text-align:center;', name: 'year',  label: '연도', align: 'center', field:'year' },
                    { headerStyle:'text-align:center;', name: 'quarter',  label: '분기', align: 'center', field: (row) => this.quarterValue[row.quarter] },
                    { headerStyle:'text-align:center;', name: 'updateTime',  label: '발표시간', align: 'center', field: (row) => FormatTime(row.updateTime) },
                    { headerStyle:'text-align:center;', name: 'sales',  label: '매출액', align: 'right', field: (row) => row.sales == null ? 'N/A':numberFormat(row.sales) },
                    { headerStyle:'text-align:center;', name: 'operatingIncome',  label: '영업이익', align: 'right', field: (row) => row.operatingIncome == null ? 'N/A':numberFormat(row.operatingIncome) },
                    { headerStyle:'text-align:center;', name: 'netIncome',  label: '순이익', align: 'right', field: (row) => row.netIncome == null ? 'N/A':numberFormat(row.netIncome) },
                    { headerStyle:'text-align:center;', name: 'unit',  label: '단위', align: 'center', field: (row) => this.unitValue[row.unit] },
                    { headerStyle:'text-align:center;', name: 'checked',  label: 'checked', align: 'center'}
                ],
            earningColumns: [
                    { headerStyle:'text-align:center;', name: 'stockCode', label: '종목', align: 'center', field: (row) => stringToStockValue(row.stockCode, this.stockItems) + ' (' + row.stockCode + ')' },
                    { headerStyle:'text-align:center;', name: 'type', label: '구분', align: 'center', field: (row) => this.typeValue[row.type] },
                    { headerStyle:'text-align:center;', name: 'ifrs',  label: '연결/별도', align: 'center', field: (row) => this.ifrsValue[row.ifrs] },
                    { headerStyle:'text-align:center;', name: 'year',  label: '연도', align: 'center', field:'year' },
                    { headerStyle:'text-align:center;', name: 'quarter',  label: '분기', align: 'center', field: (row) => this.quarterValue[row.quarter] },
                    { headerStyle:'text-align:center;', name: 'updateTime',  label: '발표시간', align: 'center', field: (row) => FormatTime(row.updateTime) },
                    { headerStyle:'text-align:center;', name: 'sales',  label: '매출액', align: 'right', field: (row) => row.sales == null ? 'N/A':numberFormat(row.sales) },
                    { headerStyle:'text-align:center;', name: 'operatingIncome',  label: '영업이익', align: 'right', field: (row) => row.operatingIncome == null ? 'N/A':numberFormat(row.operatingIncome) },
                    { headerStyle:'text-align:center;', name: 'pretaxIncome',  label: '세전 영업이익', align: 'right', field: (row) => row.pretaxIncome == null ? 'N/A':numberFormat(row.pretaxIncome) },
                    { headerStyle:'text-align:center;', name: 'netIncome',  label: '순이익', align: 'right', field: (row) => row.netIncome == null ? 'N/A':numberFormat(row.netIncome) },
                    { headerStyle:'text-align:center;', name: 'ciNetIncome',  label: '지배주주 순이익', align: 'right', field: (row) => row.ciNetIncome == null ? 'N/A':numberFormat(row.ciNetIncome) },
                    { headerStyle:'text-align:center;', name: 'unit',  label: '단위', align: 'center', field: (row) => this.unitValue[row.unit] },
                    { headerStyle:'text-align:center;', name: 'memo',  label: '비고', align: 'center', field: (row) => stringToMapValue('memo', row.options) },
                    { headerStyle:'text-align:center;', name: 'checked',  label: 'checked', align: 'center'}
                ],
            consensusColumns: [
                    { headerStyle:'text-align:center;', name: 'stockCode', label: '종목', align: 'center', field: (row) => stringToStockValue(row.stockCode, this.stockItems) + ' (' + row.stockCode + ')' },
                    { headerStyle:'text-align:center;', name: 'type', label: '구분', align: 'center', field: (row) => this.typeValue[row.type] },
                    { headerStyle:'text-align:center;', name: 'ifrs',  label: '연결/별도', align: 'center', field: (row) => this.ifrsValue[row.ifrs] },
                    { headerStyle:'text-align:center;', name: 'year',  label: '연도', align: 'center', field:'year' },
                    { headerStyle:'text-align:center;', name: 'quarter',  label: '분기', align: 'center', field: (row) => this.quarterValue[row.quarter] },
                    { headerStyle:'text-align:center;', name: 'sales',  label: '매출액', align: 'right', field: (row) => row.sales == null ? 'N/A':numberFormat(row.sales) },
                    { headerStyle:'text-align:center;', name: 'operatingIncome',  label: '영업이익', align: 'right', field: (row) => row.operatingIncome == null ? 'N/A':numberFormat(row.operatingIncome) },
                    { headerStyle:'text-align:center;', name: 'pretaxIncome',  label: '세전 영업이익', align: 'right', field: (row) => row.pretaxIncome == null ? 'N/A':numberFormat(row.pretaxIncome) },
                    { headerStyle:'text-align:center;', name: 'netIncome',  label: '순이익', align: 'right', field: (row) => row.netIncome == null ? 'N/A':numberFormat(row.netIncome) },
                    { headerStyle:'text-align:center;', name: 'ciNetIncome',  label: '지배주주 순이익', align: 'right', field: (row) => row.ciNetIncome == null ? 'N/A':numberFormat(row.ciNetIncome) },
                    { headerStyle:'text-align:center;', name: 'unit',  label: '단위', align: 'center', field: (row) => this.unitValue[row.unit] },
                    { headerStyle:'text-align:center;', name: 'memo',  label: '비고', align: 'center', field: (row) => stringToMapValue('memo', row.options) },
                    { headerStyle:'text-align:center;', name: 'checked',  label: 'checked', align: 'center'}
                ],
            pagination: {
                page: 1,
                rowsPerPage: CONSTANTS.LIST_COUNT_PER_PAGE,
                nextCount: 0,
                maxPages: CONSTANTS.DEFAULT_PAGE_NUM
            }
        }
    },
    computed: {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            stockItems : state => state.resource.stockItems
        }),
        ...mapState('manage', ['list', 'totcount']),
        pagesNumber () {
            return Math.ceil(Number(this.totcount) / Number(this.pagination.rowsPerPage));
        }
    },
    created () {
        this.columns = this.consensusColumns
        this.getPage();
        this.getList()
        this.getSetting()
    },
    methods: {
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        ...mapMutations('manage', ['SET_DATA', 'INIT_DATA_VIEW', 'SET_DATA_VIEW']),
        ...mapActions('resource', ['GET_SETTINGS','SET_SETTINGS']),
        getPage() {
            let iYear = Number(new Date().getFullYear())+2;
            for(let i=iYear; i >= 2016; i--){
                this.yearOptions.push({
                    value: i+'',
                    name: i+''
                });
            }
            let list = [];
            try {
                list = JSON.parse(this.stockItems);
            } catch (e) {
                list = this.stockItems;
            }
            let ds_list = [];
            list.forEach(row => {
                ds_list.push({
                    code : row['code'],
                    name : row['name'] + ' (' + row['code'] + ')'
                });
            });
            this.stockList = ds_list;
        },
        filterFn (val, update, abort) {
            // 종목검색 시, 1자리 이하일 경우 검색되지 않음.
            if (val.length < 2) {
                if (val.length == 0) {
                    this.stockCode = '';
                }
                abort();
                return;
            }
            update(() => {
                const needle = val.toLowerCase();
                this.stockOptions = this.stockList.filter(
                    v => v.name.toLowerCase().indexOf(needle) > -1 || v.code.indexOf(val) > -1
                )
            })
        },

        getViewItem(obj){
            if(obj != null){
                this.id = obj.id;
                this.stockCode = this.stockList.filter(function (entry){ return entry.code === obj.stockCode; })[0];
                this.type = this.typeOptions.filter(function (entry){ return entry.value === obj.type; })[0];
                this.ifrs = this.ifrsOptions.filter(function (entry){ return entry.value === obj.ifrs; })[0];
                this.year = this.yearOptions.filter(function (entry){ return entry.value === obj.year; })[0];
                this.quarter = this.quarterOptions.filter(function (entry){ return entry.value === obj.quarter; })[0];
                this.unit = this.unitOptions.filter(function (entry){ return entry.value === obj.unit; })[0];
                this.memo = stringToMapValue('memo', obj.options);
                if(obj.updateTime == null || obj.updateTime == ''){
                    this.updateTime = '00';
                }else{
                    this.updateTime = obj.updateTime;
                }
                this.sales = obj.sales;
                this.operatingIncome = obj.operatingIncome;
                this.pretaxIncome = obj.pretaxIncome;
                this.netIncome = obj.netIncome;
                this.ciNetIncome = obj.ciNetIncome;
                this.btnChk = false;
            }else{
                this.id = '';
                this.stockCode = this.stockList.filter(function (entry){ return entry.code === ''; });
                // 등록 후, 드롭박스 옵션값 유지되도록 수정 : 211001 이민수 수정 - 확인 후 아래 코드 주석 처리

                /*
                    별도로 입력 뒤 다음 입력할 때 별도로 설정값 바뀌던데 이건 연결이 무조건 나오도록 수정 부탁드립니다.(10월5일)
                     요청으로 수정 : 211006 이민수
                     드롭박스 중, this.ifrsOptions[0] 만 초기화
                */

                // this.type = this.typeOptions[0];
                this.ifrs = this.ifrsOptions[0];
                // this.year = this.yearOptions[0];
                // this.quarter = this.quarterOptions[0];
                // this.unit = this.unitOptions[0];
                this.memo = '';
                this.updateTime = '00';
                this.sales = '';
                this.operatingIncome = '';
                this.pretaxIncome = '';
                this.netIncome = '';
                this.ciNetIncome = '';
                this.btnChk = true;
            }
        },
        //저장
        getSave() {

            if (this.stockCode == null || this.stockCode == "") {
                this.showMessage({ vc:this, message:'종목을 선택하세요.' });
                return;
            }
            if (this.type.value == "") {
                this.showMessage({ vc:this, message:'구분을 선택하세요.' });
                return;
            }
            if (this.ifrs.value == "") {
                this.showMessage({ vc:this, message:'연결/별도을 선택하세요.' });
                return;
            }
            if (this.year.value == "") {
                this.showMessage({ vc:this, message:'연도를 선택하세요.' });
                return;
            }
            if (this.quarter.value == "") {
                this.showMessage({ vc:this, message:'분기를 선택하세요.' });
                return;
            }
            if (this.unit.value == "") {
                this.showMessage({ vc:this, message:'단위를 선택하세요.' });
                return;
            }
            if (!this.sales) {
                this.sales = null;
            }
            if (!this.operatingIncome) {
                this.operatingIncome = null;
            }
            if (!this.pretaxIncome) {
                this.pretaxIncome = null;
            }
            if (!this.netIncome) {
                this.netIncome = null;
            }
            if (!this.ciNetIncome) {
                this.ciNetIncome = null;
            }
            if(this.type.value == 'EARNING' || this.type.value == 'EARNING_Q_B'){   //잠정실적
                if(this.updateTime == "00"){
                    this.showMessage({ vc:this, message:'시분을 선택하세요.' });
                    return;
                }
            }
            this.showAlert({ vc:this, message:'<span style="color:#1976D2;">저장</span> 하시겠습니까?' }).then(()=>{
                let stockCode = this.stockCode.code;
                let param = {
                    'id': this.id,
                    'stockCode': stockCode,
                    'type': this.type.value,
                    'ifrs': this.ifrs.value,
                    'year': this.year.value,
                    'quarter': this.quarter.value,
                    'updateTime': this.updateTime == "00" ? '' : this.updateTime,
                    'unit': this.unit.value,
                    'sales': this.sales,
                    'operatingIncome': this.operatingIncome,
                    'pretaxIncome': this.pretaxIncome,
                    'netIncome': this.netIncome,
                    'ciNetIncome': this.ciNetIncome,
                    'options': 'stockName:' + this.stockItems.filter(function (entry){ return entry.code === stockCode; })[0].name + ',memo:' + this.memo,
                    'CALL_API_URL': '/earning/save'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'저장 되었습니다.' });
                    this.getViewItem(null);
                    this.getList();
                }).catch((err)=>{
                    this.showError({ vc:this, error:err });
                });
            }).catch(() => { });
        },
        //리스트
        getList() {
            // 종목코드가 없으면 기본은 구분, 년도, 분기
            // 종목코드가 있으면    연결/별도 도 조회 조건으로 
            let param = {
                year: this.year.value,
                quarter: this.quarter.value,
                type: this.type.value,
                pageStr: (Number(this.pagination.page) - 1) * Number(this.pagination.rowsPerPage),
                pageSize: this.pagination.rowsPerPage,
                CALL_API_URL: '/earning/list'
            }
            if(this.stockCode != '') {
                debug.log('getList', this.stockCdoe)
                param.stockCode= this.stockCode.code
                param.ifrs= this.ifrs.value
            }

            this.GET_CALL(param).then((data) => {
                data.items.forEach(row => {
                    row.checked = this.allCheck;
                });
                this.SET_DATA(data);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        //상세
        getView(item) {
            this.INIT_DATA_VIEW();
            let sId = "";
            if(item != null && item.id != null){
                sId = item.id;
            }
            let param = {
                'id': sId,
                'CALL_API_URL': '/earning/view'
            }
            this.GET_CALL(param).then((data) => {
                this.getViewItem(data.item);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        getDelete(obj) {
            this.showDeleteAlert({ vc:this, message:'<span style="color:red;">삭제</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    'id': obj.id,
                    'CALL_API_URL': '/earning/delete'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'삭제 되었습니다.' });
                    this.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },
        //선택삭제
        getDeleteChk() {
            let iCnt = 0;
            let ds_list = [];
            this.list.forEach(row => {
                if(row.checked){
                    iCnt++;
                    ds_list.push({
                        id : row['id']
                    });
                }
            });
            if(iCnt == 0){
                this.showMessage({ vc:this, message:'체크항목을 선택하세요.' });
                return;
            }
            this.showDeleteAlert({ vc:this, message: iCnt+'건 <span style="color:red;">선택삭제</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    ds_list,
                    'CALL_API_URL': '/earning/deleteChk'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'선택삭제 되었습니다.' });
                    this.allCheck = false;
                    this.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },
        selectType(type) {
            if(type.value == 'EARNING') {
                this.columns = this.earningColumns
            } else if(type.value == 'CONSENSUS') {
                this.columns = this.consensusColumns
            } else if(type.value == 'EARNING_Q_B') {
                this.columns = this.provEarningColumns
            }
        },
        //전체선택
        getAllCheck(){
            this.list.forEach(row => {
                row.checked = this.allCheck;
            });
        },
        getSetting() {
            this.GET_SETTINGS()
                .then((res) => {
                debug.log(res.EARNING_NOTE)
                    // F_FAVORITE("F_FAVORITE"),
                    // F_OPTION("F_OPTION"),
                    // F_TITLE_UP("F_TITLE_UP"),
                    // F_TITLE_DOWN("F_TITLE_DOWN");
                    if (res.EARNING_NOTE !== undefined) {
                        this.note = res.EARNING_NOTE
                    }
                        
                })
                .catch((e) => {
                    debug.log('getSetting error = ', e)
                })
        },
        updateNote() {
            let params = {
                items: [
                {
                    EARNING_NOTE: this.note,
                },
                ],
            };
            this.SET_SETTINGS(params)
                .then(() => {
                this.showMessage({ vc: this, message: "업데이트 완료" });
                })
                .catch((err) => {
                this.showError({ vc: this, error: err });
                });
    },
    }
};

</script>
