<template>
    <article>
        <q-dialog v-model="updatePopup" persistent>
            <q-card style="min-width: 80%;min-height:90%;">
                <q-card-section>
                    <div class="col q-gutter-sm">
                        <div class="row q-my-sm">
                            <div class="col-12 col-sm-6">
                                <i-datetime date v-model="item.sendDate" dateLabel="전송날짜" style="width:70%;"/>
                            </div>
                            <div class="col-12 col-sm-6">
                                <q-input v-model="item.sendTime" mask="time" @input="getTime()" outlined dense input-class="text-center" style="width:50%;"><template v-slot:before><label class="i-label">전송시간</label></template></q-input>
                            </div>
                        </div>
                        <div class="row q-my-sm">
                            <div class="col-12 col-sm-12 row">
                                <div class="q-pa-xs" style="width:250px;">
                                    <q-select v-model="stocks1" :options="stockOptions" option-label="name" @input="getSelectChk('stocks1')" @filter="filterFn1" input-debounce="0" dense outlined :hide-selected="hide_selected" :fill-input="hide_selected" use-input hide-dropdown-icon options-dense>
                                        <q-tooltip anchor="top middle" self="bottom middle" :offset="[5, 5]">한글로 검색 시, enter를 눌러주세요.</q-tooltip>
                                        <template v-slot:no-option><q-item><q-item-section class="text-grey">검색된 데이터가 없습니다.</q-item-section></q-item></template>
                                    </q-select>
                                </div>
                                <div class="q-pa-xs" style="width:250px;">
                                    <q-select v-model="stocks2" :options="stockOptions" option-label="name" @input="getSelectChk('stocks2')" @filter="filterFn2" input-debounce="0" dense outlined :hide-selected="hide_selected" :fill-input="hide_selected" use-input hide-dropdown-icon options-dense>
                                        <q-tooltip anchor="top middle" self="bottom middle" :offset="[5, 5]">한글로 검색 시, enter를 눌러주세요.</q-tooltip>
                                        <template v-slot:no-option><q-item><q-item-section class="text-grey">검색된 데이터가 없습니다.</q-item-section></q-item></template>
                                    </q-select>
                                </div>
                                <div class="q-pa-xs" style="width:250px;">
                                    <q-select v-model="stocks3" :options="stockOptions" option-label="name" @input="getSelectChk('stocks3')" @filter="filterFn3" input-debounce="0" dense outlined :hide-selected="hide_selected" :fill-input="hide_selected" use-input hide-dropdown-icon options-dense>
                                        <q-tooltip anchor="top middle" self="bottom middle" :offset="[5, 5]">한글로 검색 시, enter를 눌러주세요.</q-tooltip>
                                        <template v-slot:no-option><q-item><q-item-section class="text-grey">검색된 데이터가 없습니다.</q-item-section></q-item></template>
                                    </q-select>
                                </div>
                                <div class="q-pa-xs" style="width:250px;">
                                    <q-select v-model="stocks4" :options="stockOptions" option-label="name" @input="getSelectChk('stocks4')" @filter="filterFn4" input-debounce="0" dense outlined :hide-selected="hide_selected" :fill-input="hide_selected" use-input hide-dropdown-icon options-dense>
                                        <q-tooltip anchor="top middle" self="bottom middle" :offset="[5, 5]">한글로 검색 시, enter를 눌러주세요.</q-tooltip>
                                        <template v-slot:no-option><q-item><q-item-section class="text-grey">검색된 데이터가 없습니다.</q-item-section></q-item></template>
                                    </q-select>
                                </div>
                                <div class="q-pa-xs" style="width:250px;">
                                    <q-select v-model="stocks5" :options="stockOptions" option-label="name" @input="getSelectChk('stocks5')" @filter="filterFn5" input-debounce="0" dense outlined :hide-selected="hide_selected" :fill-input="hide_selected" use-input hide-dropdown-icon options-dense>
                                        <q-tooltip anchor="top middle" self="bottom middle" :offset="[5, 5]">한글로 검색 시, enter를 눌러주세요.</q-tooltip>
                                        <template v-slot:no-option><q-item><q-item-section class="text-grey">검색된 데이터가 없습니다.</q-item-section></q-item></template>
                                    </q-select>
                                </div>
                            </div>
                        </div>
                        <div class="row q-my-sm">
                            <div class="col-12 col-sm-12">
                                <i-input v-model="item.title" label="제목" />
                            </div>
                        </div>
                        <div class="row q-my-sm">
                            <div class="col-12">
                                <i-editor v-model="item.content" :height="400" @input-html="inputcontent"/>
                            </div>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right" class="text-primary">
                    <div class="q-pa-xs"><q-btn color="white" text-color="black" label="닫기" v-close-popup/></div>
                    <div class="q-pa-xs" v-show="item.id == null"><q-btn label="등록" @click="getSave"/></div>
                    <div class="q-pa-xs" v-show="item.id != null"><q-btn label="수정" @click="getSave"/></div>
                </q-card-actions>
            </q-card>
        </q-dialog>
    </article>
</template>
<script>
import Editor from "@/components/common/Editor";
import Input from "@/components/common/Input";
import DateTime from "@/components/common/DateTime";
import { stringToMapValue } from '@/util'
import { mapState, mapMutations, mapActions } from "vuex";

export default {
    name: "Content",
    props: {
        hide_selected: {
            type: Boolean,
            default: true
        }
    },
    components: {
        "i-input": Input,
        "i-datetime": DateTime,
        "i-editor": Editor
    },
    data() {
        return {
            updatePopup: false,
            stockList: [],
            stockOptions: [],
            stocks1:null,
            stocks2:null,
            stocks3:null,
            stocks4:null,
            stocks5:null
        };
    },
    computed: {
        ...mapState ({
            role : state => state.session.role,
            userId : state => state.session.userId,
            stockItems : state => state.resource.stockItems
        }),
        ...mapState('manage', ['item', 'list', 'itemList']),
    },
    methods: {
        ...mapMutations('manage', ['INIT_ITEM', 'SET_ITEM', 'SET_LIST']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
        ...mapActions('manage', ['GET_CALL']),
        //수정팝업
        getUpdatePopup() {
            this.updatePopup = true;
        },
        //저장
        getSave() {
            if (this.item.sendTime == "") {
                this.showMessage({ vc:this, message:'전송시간을 입력하세요.' });
                return;
            }
            if (!this.item.title) {
                this.showMessage({ vc:this, message:'제목을 입력하세요.' });
                return;
            }
            if (!this.item.content) {
                this.showMessage({ vc:this, message:'내용을 입력하세요.' });
                return;
            }
            this.showAlert({ vc:this, message:'<span style="color:#1976D2;">저장</span> 하시겠습니까?' }).then(()=>{
                this.updatePopup = false;
                var sStocks = "";
                if(this.stocks1 != null && this.stocks1 != ""){ sStocks += "stocks1:" + this.stocks1.code + ","; }
                if(this.stocks2 != null && this.stocks2 != ""){ sStocks += "stocks2:" + this.stocks2.code + ","; }
                if(this.stocks3 != null && this.stocks3 != ""){ sStocks += "stocks3:" + this.stocks3.code + ","; }
                if(this.stocks4 != null && this.stocks4 != ""){ sStocks += "stocks4:" + this.stocks4.code + ","; }
                if(this.stocks5 != null && this.stocks5 != ""){ sStocks += "stocks5:" + this.stocks5.code + ","; }
                let param = {
                    'id': this.item.id,
                    'client': this.$parent.trnsmis.trnsmisId,
                    'title': this.item.title,
                    'content': this.item.content,
                    'sendDate': this.item.sendDate,
                    'sendTime': this.item.sendTime,
                    'stocks': sStocks,
                    'CALL_API_URL': '/irflash/save'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'저장 되었습니다.' });
                    this.$parent.getList();
                }).catch((err)=>{
                    this.showError({ vc:this, error:err });
                });
            }).catch(() => { });
        },
        getSelectInit(obj){
            var list = [];
            try {
                list = JSON.parse(this.stockItems);
            } catch (e) {
                list = this.stockItems;
            }
            var ds_list = [];
            list.forEach(row => {
                ds_list.push({
                    code : row['code'],
                    name : row['name'] + ' (' + row['code'] + ')'
                });
            });
            this.stockList = ds_list;
            var sStocks = "";
            if(obj != null){
                sStocks = obj.stocks;
            }
            this.stocks1 = this.stockList.filter(function (entry){ return entry.code === stringToMapValue('stocks1', sStocks) })[0];
            this.stocks2 = this.stockList.filter(function (entry){ return entry.code === stringToMapValue('stocks2', sStocks) })[0];
            this.stocks3 = this.stockList.filter(function (entry){ return entry.code === stringToMapValue('stocks3', sStocks) })[0];
            this.stocks4 = this.stockList.filter(function (entry){ return entry.code === stringToMapValue('stocks4', sStocks) })[0];
            this.stocks5 = this.stockList.filter(function (entry){ return entry.code === stringToMapValue('stocks5', sStocks) })[0];
        },
        filterFn1 (val, update, abort) {
            this.filterFn(val, update, abort, '1');
        },
        filterFn2 (val, update, abort) {
            this.filterFn(val, update, abort, '2');
        },
        filterFn3 (val, update, abort) {
            this.filterFn(val, update, abort, '3');
        },
        filterFn4 (val, update, abort) {
            this.filterFn(val, update, abort, '4');
        },
        filterFn5 (val, update, abort) {
            this.filterFn(val, update, abort, '5');
        },
        filterFn (val, update, abort, gb) {
            // 종목검색 시, 1자리 이하일 경우 검색되지 않음.
            if (val.length < 2) {
                abort();
                return;
            }
            update(() => {
                const needle = val.toLowerCase();
                this.stockOptions = this.stockList.filter(
                    v => v.name.toLowerCase().indexOf(needle) > -1 || v.code.indexOf(val) > -1
                );
                if(this.stockOptions.length == 1){
                    if(gb == '1'){
                        this.stocks1 = this.stockOptions[0];
                    }else if(gb == '2'){
                        this.stocks2 = this.stockOptions[0];
                    }else if(gb == '3'){
                        this.stocks3 = this.stockOptions[0];
                    }else if(gb == '4'){
                        this.stocks4 = this.stockOptions[0];
                    }else if(gb == '5'){
                        this.stocks5 = this.stockOptions[0];
                    }
                }
            })
        },
        //종목체크
        getSelectChk(name){
            var chk = false;
            var chk1 = this.stocks1 != null ? this.stocks1.code : "A";
            var chk2 = this.stocks2 != null ? this.stocks2.code : "B";
            var chk3 = this.stocks3 != null ? this.stocks3.code : "C";
            var chk4 = this.stocks4 != null ? this.stocks4.code : "D";
            var chk5 = this.stocks5 != null ? this.stocks5.code : "E";
            if(chk1 == chk2){ chk = true; }
            if(chk1 == chk3){ chk = true; }
            if(chk1 == chk4){ chk = true; }
            if(chk1 == chk5){ chk = true; }
            if(chk2 == chk3){ chk = true; }
            if(chk2 == chk4){ chk = true; }
            if(chk2 == chk5){ chk = true; }
            if(chk3 == chk4){ chk = true; }
            if(chk3 == chk5){ chk = true; }
            if(chk4 == chk5){ chk = true; }
            if(chk){
                this.showError({ vc: this, error: '같은 종목이 포함되어 있습니다.' });
                if(name == 'stocks1'){ this.stocks1 = null; }
                if(name == 'stocks2'){ this.stocks2 = null; }
                if(name == 'stocks3'){ this.stocks3 = null; }
                if(name == 'stocks4'){ this.stocks4 = null; }
                if(name == 'stocks5'){ this.stocks5 = null; }
            }
        },
        getTime(){
            this.item.sendTime = this.item.sendTime.replace(/:/gi,'');
        },
        inputcontent(val){
            // debug.log('inputcontent = ', val)
            this.item.content = val
        },        
    },
};

</script>

