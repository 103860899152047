<template>
    <article>
        <q-toolbar class="i-top-wrap justify-end">
            <div v-if="!isRegisterPage" class="q-mr-xs">
                <!-- 버튼 -->
                <q-btn-group outline>
                    <q-btn
                        outline
                        color="black"
                        label="신규"
                        @click="goRegisterPage()"
                    />
                    <q-btn
                        outline
                        color="black"
                        label="종목"
                        @click="goStock"
                    />
                    <q-btn
                        outline
                        color="black"
                        label="히스토리"
                        @click="goHistory"
                    />
                </q-btn-group>
            </div>
            <q-space v-else />
            <i-btn label="취소" @click="goListPage()" />
        </q-toolbar>
        <div class="i-content-wrap">
            <q-card flat bordered>
                <q-card-section v-if="!isRegisterPage" class="row">
                    <i-field
                        inputLabel="작성일자"
                        :text="createDate"
                        class="col-12 col-md-6"
                    />
                    <i-field
                        inputLabel="수정일자"
                        :text="lastUpdateTime"
                        class="col-12 col-md-6"
                    />
                </q-card-section>
                <i-field
                    inputLabel="작성자"
                    :text="themeDetail.writer"
                    class="col-12 q-px-md"
                />
                <q-card-section>
                    <i-input
                        v-model="themeName"
                        inputLabel="테마명"
                        @focus="goEditPage()"
                    />
                    <i-input
                        v-model="themeOutline"
                        inputLabel="테마개요"
                        type="textarea"
                        :rows="25"
                        @focus="goEditPage()"
                        class="q-mt-xs"
                    />
                    <div class="row q-mt-xs">
                        <i-select
                            v-model="type"
                            :options="typeOptions"
                            inputLabel="상태"
                            option-label="name0"
                            option-value="value0"
                            dense
                            @input="goEditPage()"
                            class="col-12 col-sm-6"
                        />
                        <i-field
                            check
                            v-model="popular"
                            inputLabel="인기"
                            class="col-12 col-sm-6 "
                            @focus="goEditPage()"
                        />
                    </div>
                </q-card-section>
                <q-card-section
                    v-if="isEditPage"
                    class="col-12 col-sm-12 q-pa-xs"
                >
                    <q-input outlined v-model="memo">
                        <template v-slot:before>
                            <label class="i-label">삭제사유</label>
                        </template>
                    </q-input>
                </q-card-section>
                <q-card-section
                    v-show="isDetailPage"
                    v-for="(list, index) in themeDetail.items"
                    :key="'editList-' + index"
                    class="col-12 col-sm-12 q-pa-xs"
                >
                    <q-field outlined>
                        <template v-slot:before>
                            <label class="i-label">
                                <span v-show="index == 0"
                                    >테마개요 수정내역</span
                                >
                            </label>
                        </template>
                        <template v-slot:control>
                            <div>
                                {{ list.content }}
                            </div>
                        </template>
                    </q-field>
                </q-card-section>
            </q-card>
            <!-- 버튼 -->
            <div v-if="!isDetailPage" class="full-width">
                <div class="col-12 col-sm-2 q-pa-xs">
                    <i-btn class="full-width" label="저장" @click="goSave()" />
                </div>
            </div>
        </div>
    </article>
</template>
<script>
import Debug from '@/debug'

import Button from '@/components/common/Button'
import Field from '@/components/common/Field'
import Input from '@/components/common/Input'
import Select from '@/components/common/Select'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { FormatDate } from '@/util'

export default {
    name: 'Content',
    components: {
        'i-btn': Button,
        'i-field': Field,
        'i-input': Input,
        'i-select': Select,
        // 'i-datetime': DateTime
    },
    props: {},
    data() {
        return {
            themeDetail: {},
            themeName: null,
            themeOutline: '',
            popular: false,
            type: null,
            memo: null,
        }
    },
    created() {
        if (!this.isRegisterPage) {
            Debug.log('created not register')
            if (this.selectTheme === null || this.selectTheme === undefined) {
                this.goListPage()
            } else {
                this.getThemeDetail(this.selectTheme.code)
            }
        }
    },
    computed: {
        ...mapState({
            selectTheme: (state) => state.theme.theme,
            themeType: (state) => state.resource.themeType,
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            allItems: (state) => state.theme.items, 
        }),
        ...mapGetters({
            isEditPage: 'page/isEditPage',
            isRegisterPage: 'page/isRegisterPage',
            isDetailPage: 'page/isDetailPage',
        }),
        typeOptions() {
            Debug.log('computed typeOption [', this.themeType)
            return [].concat(this.themeType)
        },
        createDate() {
            return this.themeDetail
                ? FormatDate(this.themeDetail.createTime)
                : ''
        },
        lastUpdateTime() {
            return this.themeDetail
                ? FormatDate(this.themeDetail.lastUpdateTime)
                : ''
        },
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
            SET_THEME: 'theme/SET_THEME',
        }),
        ...mapActions({
            WRITE_ITEMS: 'theme/WRITE_ITEMS',
            GET_CODE: 'theme/GET_CODE',
            GET_DETAIL: 'theme/GET_DETAIL',
        }),
        ...mapActions('alert', ['showError', 'showMessage']),
        goStock() {
            this.$emit('goThemeStock')
        },
        goHistory() {
            this.$emit('goThemeHistory')
        },
        // 목록화면으로 이동하기
        goListPage() {
            Debug.log('getThemeDetail --------------')
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        // 수정화면으로 이동하기
        goEditPage() {
            if (this.isDetailPage) {
                this.setPageStatus(this.PAGE_STATUS.EDIT)
                if(this.selectTheme.popular === '0') this.popular = false
                else this.popular = true
                
            }
        },
        // 등록화면으로 이동하기
        goRegisterPage() {
            this.themeName = ''
            this.themeOutline = ''
            this.type = this.themeType[1] // 22.09.07 suye 상태기본값 전체공개0 -> 준비1
            this.popular = false
            this.setPageStatus(this.PAGE_STATUS.REGISTER)
        },
        // 테마 상세 조회하기
        getThemeDetail(code) {
            var params = {
                code: code,
            }
            Debug.log('getThemeDetail params', params)
            this.GET_DETAIL(params)
                .then((res) => {
                    this.themeDetail = res
                    this.themeName = this.themeDetail.name
                    this.themeOutline = this.themeDetail.outline
                    // this.popular = this.themeDetail.popular
                    if(this.selectTheme.popular === '0') this.popular = false
                    else this.popular = true
                    this.type = this.themeType.find(
                        (v) => v.value0 === this.themeDetail.type
                    )
                })
                .catch((error) => {
                    this.showError({ vc: this, error: error })
                })
        },
        //신규입력 or 수정
        goSave() {
            
            // 22.07.05 suye 이미 존재하는 테마명일 경우 등록 불가 & 삭제된 상태의 테마명이면 등록 가능 
            let themeNameCheck = this.allItems.find(
                    (element) => element.name == this.themeName && element.type !== 'DELETE'
                )

            if(themeNameCheck !== undefined) {
                if(this.isRegisterPage){ // 등록 페이지
                    this.showMessage({ vc: this, message: '이미 존재하는 테마명입니다.'})
                    return
                } else if (this.isEditPage){ // 수정 페이지에서 
                    if(this.themeDetail.name !== this.themeName){ // 기존 테마명과 다른 테마명으로 수정 입력시 등록 제어 
                        this.showMessage({ vc: this, message: '변경된 테마명 ['+this.themeName+']은/는 이미 존재합니다.'})
                        return
                    }
                }
            }
        
            var message
            var params = {
                name: this.themeName,
                outline: this.themeOutline,
                popular: this.popular ? 1 : 0,
                type: this.type.value0,
            }

            if (this.isEditPage) {
                params.code = this.themeDetail.code
                params.memo = this.memo
                message = '수정되었습니다.'
            } else if (this.isRegisterPage) {
                message = '등록되었습니다.'
            }
            this.WRITE_ITEMS(params)
                .then(() => {
                    this.showMessage({ vc: this, message: message })

                    this.setPageStatus(this.PAGE_STATUS.LIST)
                })
                .catch((error) => {
                    this.showError({ vc: this, error: error })
                    Debug.log('error = ', error)
                })
        },
    },
    watch: {
        selectTheme(val, old) {
            if (val !== old) {
                this.getThemeDetail(val.code)
            }
        },
        pageStatus() {
            if (this.isRegisterPage) {
                // List.vue에서 신규 버튼을 누른 경우, 이전에 입력한 데이터 초기화
                this.goRegisterPage()
            }
        },
    },
}
</script>
