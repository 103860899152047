import { date } from 'quasar'

const state = () => {
    return {
        dateLocale: {
            days: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
            daysShort: ['일', '월', '화', '수', '목', '금', '토'],
            months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
            monthsShort: ['1 /', '2 /', '3 /', '4 /', '5 /', '6 /', '7 /', '8 /', '9 /', '10 /', '11 /', '12 /']
        },
        now: new Date(),
        dateFormat: 'YYYY-MM-DD'
    }
}

const getters = {
    today: state => date.formatDate(state.now, state.dateFormat),
    startDate: state => {
        let temp = date.subtractFromDate(state.now, { days: 6 })
        return date.formatDate(temp, state.dateFormat)
    },
    fullToday : state => date.formatDate(state.new, 'YYYYMMDDhhmmss')
}

export const calendar = {
    namespaced: true,
    state,
    getters
}
