<template>
    <div>
        <q-table
            flat
            bordered
            dense
            class="i-table"
            :class="{ 'no-data': list.length == 0 }"
            :columns="columns"
            :data="list"
            row-key="index"
            :virtual-scroll="verticalScroll"
            :style="heightStyle"
            :pagination.sync="pagination"
            hide-pagination
            no-data-label="조회되는 데이터가 없습니다."
            :selected-rows-label="getSelectedString"
            selection="multiple"
            :selected.sync="selected"
        >
            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th v-if="needCheckBox" class="text-left">
                        <q-checkbox v-model="allCheck" size="xs" />
                    </q-th>
                    <q-th
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                    >
                        {{ col.label }}
                    </q-th>
                </q-tr>
            </template>
            <template v-slot:body="props">
                <!-- 
                    220428 meej 테마히스토리에서 활성화된 row 오류로 인한 조건 추가 test_row != undefined
                 -->
                <q-tr :class="[(test_row == props.row.index && test_row != '' && test_row != undefined ? 'active_row' : '')]"
                    :props="props"
                    @click="
                        haveSubItem
                            ? (props.expand = !props.expand)
                            : getItem(props.row)
                    "
                >
                    <q-td v-if="needCheckBox" class="text-left">
                        <q-checkbox
                            v-model="props.row.checked"
                            @input="handleCheckBox(props.row)"
                            size="xs"
                            :disable="props.row.crud == 'D'"
                        />
                    </q-td>
                    <q-td
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                        :class="col.class"
                    >
                        {{ col.value }}
                    </q-td>
                </q-tr>
                <q-tr v-show="props.expand" :props="props">
                    <q-td colspan="100%">
                        <div
                            class="text-left"
                            style="word-break:break-all; min-width:500px;white-space: normal;"
                        >
                            {{ props.row.content }}
                        </div>
                    </q-td>
                </q-tr>
                <!-- 230206 meej SPOT 입력 내용 별도의 한줄로 생성, showSubContent -->
                <q-tr v-show="showSubContent" :props="props"
                    @click="
                            haveSubItem
                                ? (props.expand = !props.expand)
                                : getItem(props.row)
                        ">
                    <q-td class="bg-grey-5">
                        내용
                    </q-td>
                    <q-td class="bg-grey-3" colspan="100%">
                        <div
                            class="text-left"
                            style="word-break:break-all;white-space: normal"
                        >
                            {{ props.row.newsType1 === 'STOCK_FEATURED' ||
                                props.row.newsType1 === 'STOCK_AM_FEATURED' ||
                                props.row.newsType1 === 'STOCK_PM_FEATURED'
                             ? props.row.title
                             : props.row.content }}
                        </div>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:no-data="{ icon, message }"
                ><div class="full-width row flex-center q-gutter-sm">
                    <q-icon size="2em" :name="icon" />
                    <span>{{ message }}</span>
                </div></template
            >
        </q-table>
        <div class="row justify-center q-mt-md" v-if="showPagenation">
            <q-pagination
                v-model="pagination.page"
                :max="pagesNumber"
                :max-pages="pagination.maxPages"
            />
            <div>
                <q-btn
                    v-if="checkNextKey && nextKey"
                    flat
                    icon="navigate_next"
                    color="dark"
                    @click="goNext()"
                />
            </div>
        </div>
    </div>
</template>
<script>
import CONSTANTS from '@/constants'
import debug from '@/debug'
export default {
    name: 'Table',
    props: {
        columns: {
            type: Array,
        },
        list: {
            type: Array,
        },
        nextKey: {
            type: String,
        },
        checkNextKey: {
            type: Boolean,
            default: true,
        },
        haveSubItem: {
            type: Boolean,
            default: false,
        },
        needCheckBox: {
            type: Boolean,
            default: true,
        },
        dense: {
            type: Boolean,
            default: true,
        },
        verticalScroll: {
            type: Boolean,
            default: false,
        },
        heightStyle: {
            type: Object,
        },
        showPagenation: {
            type: Boolean,
            default: true,
        },
        rows: {
            default: 1,
        },
        showSubContent: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            pagination: {
                page: 1,
                rowsPerPage: CONSTANTS.LIST_COUNT_PER_PAGE,
                nextCount: 0,
                maxPages: CONSTANTS.DEFAULT_PAGE_NUM
            },
            allCheck: false,
            selected: [],
            active_row: '',
            test_row: ''
        }
    },
    created() {
        if (this.rows > CONSTANTS.LIST_COUNT_PER_PAGE) {
            this.pagination.rowsPerPage = this.rows
        }
    },
    computed: {
        pagesNumber() {
            let pageNum 
            if((this.list.length / this.pagination.rowsPerPage) < (this.pagination.rowsPerPage * this.pagination.maxPages)){
                pageNum = Math.ceil(this.list.length / this.pagination.rowsPerPage)
            }else{
                pageNum = (this.list.length / this.pagination.rowsPerPage)
            }
            // 230215 meej 리스트 새로 만들어질 경우 nextCount 리셋 
            this.handleNextCnt(pageNum)
            return pageNum
        },
    },
    methods: {
        // 230215 meej 리스트 새로 만들어질 경우 nextCount 리셋 
        handleNextCnt(val) {
            if(val == 0) this.pagination.nextCount = 0
            else return
        },
        getSelectedString() {
            debug.log('getSelectedString = ', this.selected.length)
            return this.selected.length === 0
                ? ''
                : `${this.selected.length}개 선택`
        },
        getItem(item) {
            //            debug.log('getItem item = ', item)
            this.$emit('row-click', item)
            this.active_row = item.index
            this.test_row = this.active_row
        },
        goNext() {
            this.pagination.nextCount++
            this.pagination.page = this.pagination.nextCount * 5 + 1
            // 230215 meej SPOT입력 리스트에서 페이징 처리시 this.pagination.page 필요
            //this.$emit('next-click')
            this.$emit('next-click', this.pagination.page)
        },
        handleCheckBox(item) {
            if (item.checked) {
                this.selected.push(item)
            } else {
                var index = this.selected.indexOf(item)
                if (index !== -1) {
                    this.selected.splice(index, 1)
                }
            }
            this.$emit('check-event', this.selected)
        },
        parentClearCheck() {
            this.selected = []
            this.list.forEach((i) => {
                i.checked = false
            })
            //this.allCheck = false
        },
    },
    watch: {
        allCheck: function(val) {
            this.selected = []
            if (val) {
                const start =
                    this.pagination.rowsPerPage * (this.pagination.page - 1)
                const last = Math.min(
                    this.pagination.rowsPerPage * this.pagination.page,
                    this.list.length
                )
                debug.log('start!!!', start, ', last = ', last)
                for (let i = start; i < last; i++) {
                    this.list[i].checked = val
                    this.selected.push(this.list[i])
                }
            } else {
                this.list.forEach((i) => {
                    i.checked = false
                })
            }
            this.$emit('check-event', this.selected)
        },
        list: function() {
            debug.log('table watch list!!!!!!!!!')
            this.allCheck = false
            this.selected = []
            //미리 checked 된것이 풀려서 수정 : 2021.09.30 suki
            this.list.forEach((i) => {
                if(!i.checked) i.checked = false
            })
        },
    },
}
</script>

<style>
.active_row {
    background-color: rgb(230, 230, 230);
}
</style>