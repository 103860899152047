import api from '@/services/InfostockService'
import Debug from '@/debug'
import { FormatDate } from '@/util'

const state = () => ({
    list: [],
    nextKey: null,
    totcount: 0,
    item: {},
    items: [],
    itemList: [],
    transType: {
        COPRTN_OXPR :'COPRTN_OXPR',
        ADVRTS_CTMMNY :'ADVRTS_CTMMNY',
        INFOSTOCKB2B :'INFOSTOCKB2B',
        NEWS_TITLE :'NEWS_TITLE',
        BANNER_ADVRTS :'BANNER_ADVRTS',
        LINE_ADVRTS :'LINE_ADVRTS'
    },
    cutPoint: '::::' // 스케줄형식 제목 SPLIT 구분값 ex) 형식제목+cutPoint+본문제목
})

const getters = {}

const mutations = {
    SET_LIST(state, data) {
        var fItem = data.items[0];
        console.log(fItem);
        if (fItem.newsType1 && (fItem.newsType1).includes('BOARD_NOTICE')) {
            data.items.forEach((el) => {
                var obj = {};
                if (el.flag == 0) {
                    obj.label = '일반';
                    obj.value = 0;
                } else {
                    obj.label = '강조';
                    obj.value = 1;
                }
                el.flag = obj;
            })
        } else if (fItem.newsType1 && (fItem.newsType1).includes('BOARD_ONE_ON_ONE')) {
            data.items.forEach((el) => {
                var obj = {};
                if (el.flag == 0) {
                    obj.label = '미완료';
                    obj.value = 0;
                } else {
                    obj.label = '답변완료';
                    obj.value = 1;
                }
                el.flag = obj;
            })
        } else if (fItem.newsType1 && (fItem.newsType1).includes('BOARD_DEPOSIT')) {
            data.items.forEach((el) => {
                var obj = {};
                if (el.flag == 0) {
                    obj.label = '미확인';
                    obj.value = 0;
                } else {
                    obj.label = '확인완료';
                    obj.value = 1;
                }
                el.flag = obj;
            })
        }
        /* 
            220512 meej 페이징 오류 수정
        */
        var arr = state.list
        arr.push(...data.items)
        
        state.list = arr;
        state.nextKey = data.nextKey;
    },
    SET_DATA_INBOARD(state, data) {
        let arrData = data.items
        let myMap = new Map()
        arrData.forEach((i, index) => {
            myMap.set(i.id,index)
        })   
        arrData.forEach((i, index) => {
            if(i.id != i.pid){
                if(myMap.get(i.pid)>index){
                    arrData.splice(myMap.get(i.pid)+1, 0,arrData[index]);
                    arrData.splice(index, 1);
                    i.title = '[Re:]'+i.title
                }
                else{
                    i.title = '┖☞[Re:]'+i.title
                }
            }
        })             
        state.list = arrData;
        if(data.totcount != null){
            state.totcount = data.totcount.cnt;
        }
    },    
    SET_DATA(state, data) {    
        state.list = data.items;
        if(data.totcount != null){
            state.totcount = data.totcount.cnt;
        }
    },    

    // SET_REQUEST_LIST(state, data) {
    
    //     state.list = data.items;
    //     if(data.totcount != null){
    //         state.totcount = data.totcount.cnt;
    //     }
    //     // console.log("items"+items)
    //     // state.list = data.items;
    // },

    SET_RESERVE(state, data) {
        state.list = data;
    },    
    INIT_DATA(state) {
        state.list = [];
        state.totcount = 0;
    },
    SET_DATA_VIEW(state, data) {
        var temp = { ...data.item };
        temp.createTime = FormatDate(temp.createTime);
        temp.lastUpdateTime = FormatDate(temp.lastUpdateTime);
        state.item = temp;
        if(data.itemList != null){
            state.itemList = data.itemList;
        }
    },
    INIT_DATA_VIEW(state) {
        state.item = {};
        state.itemList = [];
    },
    SET_ITEM(state, item) {
        var temp = { ...item };

        temp.createTime = FormatDate(temp.createTime);
        temp.lastUpdateTime = FormatDate(temp.lastUpdateTime);

        state.item = temp;
    },
    SET_ITEMS(state, items) {
        items.forEach((row) => {
            row.createTime = FormatDate(row.createTime);
            row.lastUpdateTime = FormatDate(row.lastUpdateTime);
        })
        state.items = items
    },

    

    INIT_LIST(state) {
        state.list = [];
        state.nextKey = null;
        state.totcount = 0;
    },
    INIT_ITEM(state) {
        state.item = {};
    }
}

const actions = {
    //공통 조회
    GET_CALL(_, params) {
        Debug.log('GET_CALL params', params);
        return new Promise((resolve, reject) => {
            api.call(params)
                .then((response) => {
                    resolve(response.data);
                    Debug.log('GET_CALL data', response.data);
                })
                .catch((error) => {
                    reject(error);
                })
        });
    },
    async GET_CALL_UPLOAD(_, params) {
        Debug.log('GET_CALL_UPLOAD params', params);
        const { data } = await api.inBoardWrite(params);
        Debug.log('GET_CALL_UPLOAD data', data);
    },
    GET_CALL_DOWNLOAD(_, params) {
        Debug.log('GET_CALL_DOWNLOAD params', params);
        return new Promise((resolve, reject) => {
            api.callDownload(params)
            .then((response) => {
                Debug.log('GET_CALL_DOWNLOAD data', response);
                const data = response.data
                resolve();
                const image = data.file
                    const byteCharacters = atob(image)
                    const byteNumbers = new Array(byteCharacters.length)
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i)
                    }
                    const byteArray = new Uint8Array(byteNumbers)
                    const file = new Blob([byteArray], {
                    type: 'application/image;base64',
                    })
                    
                    // 221101 meej 이미지 미리보기 오류 수정
                    let arr = data.FILE_NAME.split(".")
                    let setType
                    if(arr.length == 2){
                        setType = 'image/'+arr[1]+';base64'
                    }else{
                        setType = 'image/'+data.type.subtype+';base64'
                    }
                    const newFile = new File([file], data.FILE_NAME, {
                        // type: 'application/image;base64',
                        type: setType,
                    })

                    Debug.log('newFile=', newFile)

                    const fileURL = URL.createObjectURL(newFile)
                    Debug.log('fileURL=', fileURL)
                    Debug.log('data.FILE_NAME=', data.FILE_NAME)
                    window.open(fileURL, data.FILE_NAME)
                
            })
            .catch((error) => {
                reject(error);
            })
        })
    },
    // 등록 및 수정
    async WRITE_BOARD_ITEM(_, params) {
        Debug.log('WRITE_ITEM params', params)
        const { data } = await api.boardWrite(params)
        Debug.log('WRITE_ITEM data', data)
    },
    // 스케줄 형식 등록 및 수정
    async WRITE_SCHEDULE_FORM(_, params) {
        Debug.log('WRITE_SCHEDULE_FORM params', params)
        const { data } = await api.writeScheduleForm(params)
        Debug.log('WRITE_SCHEDULE_FORM data', data)
    },
    // 목록 조회
    async ASYNC_BOARD_LIST({ state, commit }, params) {
        params.nextKey = state.nextKey
        const { data } = await api.boardList(params)
        commit('SET_LIST', data)
    },
    // 목록 조회
    GET_BOARD_LIST(_, params) {
        Debug.log('GET_LIST params', params)

        return new Promise((resolve, reject) => {
            api.boardList(params)
                .then((response) => {
                    resolve(response.data)
                    Debug.log('GET_LIST data', response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 스케줄 형식 목록 조회
    GET_SCHEDULE_FORM_LIST(_, params) {
        Debug.log('GET_SCHEDULE_FORM_LIST params', params)

        return new Promise((resolve, reject) => {
            api.listScheduleForm(params)
                .then((response) => {
                    resolve(response.data)
                    Debug.log('GET_LIST data', response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 스케줄 형식 전체 조회
    SELECT_ALL_SCHEDULE_FORM(_, params) {
        Debug.log('SELECT_ALL_SCHEDULE_FORM params', params)

        return new Promise((resolve, reject) => {
            api.selectScheduleForm(params)
                .then((response) => {
                    resolve(response.data)
                    Debug.log('SELECT_ALL_SCHEDULE data', response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 상세 조회
    GET_DETAIL(_, params) {
        Debug.log('GET_DETAIL params', params)

        return new Promise((resolve, reject) => {
            api.boardDetail(params)
                .then((response) => {
                    resolve(response.data)
                    Debug.log('GET_DETAIL data', response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    // 증권사 조회
    async ASYNC_COMPANY_LIST({ commit }) {
        const { data } = await api.stockCompanyList({})
        Debug.log('GET_LIST data', data)

        data.items.forEach((i) => {
            i.flag = i.flag === 1 ? true : false
            i.ad_infostock = i.ad_infostock === 1 ? true : false
            i.ad_line = i.ad_line === 1 ? true : false

            i.newRecommend = (i.newRecommend == 1 ? true : false)
            i.opi = (i.opi == 2 ? true : false)
            i.strong = (i.strong == 4 ? true : false)
            i.consensus = (i.consensus == 8 ? true : false)
            i.adType = (i.adType == 16 ? true : false)
        })
        commit('SET_LIST', data)
        
    },
    GET_COMPANY_LIST() {
        return new Promise((resolve, reject) => {
            api.stockCompanyList({})
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    WRITE_STOCK_COMPANY({ dispatch }, params) {
        Debug.log('WRITE_STOCK_COMPANY params', params)
        return new Promise((resolve, reject) => {
            api.stockCompanyWrite(params)
                .then((response) => {
                    resolve(response.data)
                    dispatch('manage/ASYNC_COMPANY_LIST')
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    SET_STOCK_COMPANY(_, params) {
        return new Promise((resolve, reject) => {
            api.stockCompanyWrite(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 여러개 삭제
    DELETE_ITEMS(_, params) {
        return new Promise((resolve, reject) => {
            api.boardDelete(params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}

export const manage = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}