<template>
    <article style="width:100%;padding:10px;">
        <q-table flat bordered dense separator="cell" class="i-table" 
            :columns="columns" :data="list" style="height:auto;"
            :rows-per-page-options="[100]" no-data-label="조회되는 데이터가 없습니다.">
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td v-for="col in props.cols" :key="col.name" :props="props">
                        <!-- // 20230918 -->
                        <div v-if="col.name == 'sendTime'" class="userCss" :style="[col.value>nowTime ? {'color':'red'} : {'color':'black'}]">
                            {{ col.value.substring(0,2)+':'+col.value.substring(2,4) }}
                            <q-input v-model="props.row[col.name]" mask="time" @input="getTime(props.row)" outlined dense input-class="text-center"/>
                        </div>
                        <div v-else-if="col.name == 'title'"><span @click="getViewPopup(props.row)" style="font-weight:bold;cursor:pointer;">{{ col.value }}</span></div>
                        <div v-else-if="col.name == 'stocks1' || col.name == 'stocks2' || col.name == 'stocks3' || col.name == 'stocks4' || col.name == 'stocks5'" class="userCss" style="width:70px">
                            {{ col.value }}
                        </div>
                        <!-- // 20230918 -->
                        <div v-else-if="col.name == 'check'">
                            <span :style="props.row.SendCheck === '-1' ? {'color': 'red', 'font-weight': 'bold'} : {'color': 'blue', 'font-weight': 'bold'}">
                                {{ props.row.SendCheck === '-1' ? '미전송' : '전송완료' }}
                            </span>
                        </div>
                        <div v-else-if="col.name == 'button'" style="width:auto"> 
                            <q-btn size="xs" label="저장" color="blue" @click="getSave(props.row)"/>
                            <q-btn size="xs" label="삭제" color="red" @click="getDelete(props.row)"/>
                        </div>
                        <div v-else>{{ col.value }}</div>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:no-data="{ icon, message }"><div class="full-width row flex-center q-gutter-sm"><q-icon size="2em" :name="icon" /> <span>{{message}}</span></div></template>
        </q-table>

        <q-dialog v-model="viewPopup" persistent>
            <q-card style="min-width: 80%;overflow-y:hidden">
                <q-card-actions align="right" class="text-primary" style="height:50px;">
                    <q-btn color="white" text-color="black" label="닫기" v-close-popup/>
                </q-card-actions>
                <q-card-section v-html="viewContent" style="height:700px;overflow-y:scroll;"></q-card-section>
            </q-card>
        </q-dialog>
    </article>
</template>
<script>
//import Debug from '@/debug'
import { FormatDate, nowTime} from '@/util'
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
    name: 'List',
    props: {
        hide_selected: {
            type: Boolean,
            default: true
        },
        sendDate: null
    },
    data () {
        return {
            nowTime:nowTime(),
            viewPopup: false,
            viewContent: '',
            trClass: '',
            allCheck: false,
            selected: [],
            stock: '',
            stockOptions: [],
            stockList: [],
            columns: [
                { headerStyle:'text-align:center;', name: 'sendDate', label: '날짜', align: 'center', field: (row) => FormatDate(row.sendDate) },
                { headerStyle:'text-align:center;', name: 'sendTime', label: '시간', align: 'center', field: (row) => row.sendTime },
                { headerStyle:'text-align:center;', name: 'title',  label: '제목', align: 'left', field:'title'},
                { headerStyle:'text-align:center;', name: 'stocks1',  label: '종목1', align: 'center', field: (row) => this.stocks(0, row.stock_codes) },
                { headerStyle:'text-align:center;', name: 'stocks2',  label: '종목2', align: 'center', field: (row) => this.stocks(1, row.stock_codes) },
                { headerStyle:'text-align:center;', name: 'stocks3',  label: '종목3', align: 'center', field: (row) => this.stocks(2, row.stock_codes) },
                { headerStyle:'text-align:center;', name: 'stocks4',  label: '종목4', align: 'center', field: (row) => this.stocks(3, row.stock_codes) },
                { headerStyle:'text-align:center;', name: 'stocks5',  label: '종목5', align: 'center', field: (row) => this.stocks(4, row.stock_codes) },
                // 20230918
                { headerStyle:'text-align:center;', name: 'check',  label: '전송확인', align: 'center', field: (row) => {if(row.SendCheck === "-1") return "미전송"; else if(row.SendCheck === "0") return "전송완료";}},
                { headerStyle:'text-align:center;', name: 'button',  label: '관리', align: 'center',},
              ],

        }
    },
    computed: {
        ...mapState ({
            stockItems : state => state.resource.stockItems,
            list : state => state.manage.list,
        }),
        ...mapState('manage', ['list', 'totcount']),
    },
    created () {
        console.log("nowTime === ",this.nowTime)
        this.getList();
    },
    methods: {
        ...mapMutations('manage', ['SET_RESERVE', 'INIT_DATA_VIEW', 'SET_DATA_VIEW']),
        // 20230913
        ...mapActions('alert', ['showError', 'showMessage', 'showDeleteAlert', 'showAlert']),
        ...mapActions('manage', ['GET_CALL']),
        // stock_codes:270520;234920;054090;290720;019660
        stocks(key,val){
            if(val != null && val != ""){
                let obj = val.split(";")
                for(let i = 0; i < obj.length; i++){
                    if (key==i){
                        if(this.stockItems != null && this.stockItems.length != 0){
                            let itm = this.stockItems.filter(function (entry){ return entry.code === obj[i]; })[0];
                            if(itm != null){
                                return itm.name+'('+obj[i]+')';
                            }
                        }          
                    }
                }
            }         
        },        

        //리스트
        getList() {
            let param = {
                CALL_API_URL: '/newsreserve/list'
            }         
            this.GET_CALL(param).then((data) => {
                // data.items.forEach(row => {
                //     row.checked = this.allCheck;
                // });
                this.SET_RESERVE(data);
            }).catch((error) => {
                this.showError({ vc:this, error:error })
            });
        },
        //삭제
        getDelete(obj) {
            this.showDeleteAlert({ vc:this, message:'<span style="color:red;">삭제</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    'id': obj.id,
                    'CALL_API_URL': '/newsreserve/delete'
                }
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'삭제 되었습니다.' });
                    this.getList();
                }).catch((error) => {
                    this.showError({ vc:this, error:error })
                });
            }).catch(() => { });
        },
        //팝업
        getViewPopup(obj) {
            this.viewContent = obj.content;
            this.viewPopup = true;
        },
        // 20230918
        getTime(obj){
            obj["sendTime"] = obj.sendTime.replace(/:/gi,'');
        },
        // 20230918
        getSave(obj) {
            if (obj.sendTime == "") {
                this.showMessage({ vc:this, message:'전송시간을 입력하세요.' });
                return;
            }
            this.showAlert({ vc:this, message:'<span style="color:#1976D2;">저장</span> 하시겠습니까?' }).then(()=>{
                let param = {
                    'id': obj.id,
                    'sendTime': obj.sendTime,
                    'CALL_API_URL': '/newsreserve/save'
                }
                console.log("파람스 : ", param)
                this.GET_CALL(param).then(() => {
                    this.showMessage({ vc:this, message:'간편저장 되었습니다.' });
                    this.getList();
                }).catch((err)=>{
                    this.showError({ vc:this, error:err });
                });
            }).catch(() => { });
        },
    }, 
}

</script>