<template>
    <article>
        <q-toolbar class="i-top-wrap">
            <div class="row col-12">
                <i-select v-model="type" :options="options" class="col-md-6" dense
                    option-label="label" inputLabel="시간구분" @input="selectType"/>
                <q-space/>
            </div>
        </q-toolbar>
        <div class="i-content-wrap row" >
            <div class="q-pa-md col-12 col-lg-7">
                <!-- 테마동향 -->
                <q-card class="q-pa-md text-left" flat bordered>
                    <q-card-section>
                        <div class="col-12 col-md-12">
                        <q-btn class="col-md-2" flat rounded color="primary" @click="requestResource(0)" label="강세테마"/>
                        <i-input v-model="strong.content" @focus="requestResource(0)" :rows="rows" type="textarea" autofocus />
                    </div>
                    <div class="col-12 col-md-12">
                        <q-btn class="col-md-2" flat rounded color="primary" @click="requestResource(1)" label="약세테마"/>
                        <i-input v-model="weak.content" @focus="requestResource(1)" :rows="rows" type="textarea"  />
                    </div>
                    <div v-if="this.checkList.amData.length <= 1 && this.checkList.pmData.length <= 1" class="col-12 q-pa-md text-center">
                        <i-btn :label="regTitle" @click="write" />
                        <i-btn label="속보 미리보기" class="q-ml-md" @click="makeFlash" :disable="hasNews"/>
                    </div>
                    </q-card-section>
                </q-card>
                <!-- 220923 meej 중복입력된 내용 있을 경우 버튼 표출 -->
                <div class="q-pt-md" v-if="this.checkList.amData.length > 1 || this.checkList.pmData.length > 1">
                    <q-btn
                        flat
                        color="red"
                        label="중복된 내용이 존재합니다. 클릭하여 내용을 선택해주세요."
                        @click="handleOpen()"
                    />
                </div>
                <div class="q-pa-md col-6 col-md-6" v-if="amContent">
                    <p
                        class="q-pa-md text-left" style="height:100%; background-color:lightgrey"
                        v-html="amContent">
                    </p>
                </div>
                <div class="q-pa-md col-6 col-md-6" v-if="oriContent">
                    <p
                        class="q-pa-md text-left" style="height:100%; background-color:lightgrey"
                        v-html="oriContent">
                    </p>
                </div>
            </div>

            <div class="q-pa-md col-12 col-lg-5" >
                <!-- 테마 동향 -->
                <i-otable :columns="columns" :list="items"
                    @order-event="handleOrderList" @event-check-row="getSelected" />
            </div>
        </div>

        <!-- 220923 meej 중복입력된 내용 있을 경우 삭제할수 있는 팝업 -->
        <!-- ----- popup start ----- -->
        <div>
        <q-dialog v-model="dialog" persistent>
            <q-card>
                <q-card-section>
                    <div class="bg-grey-3 text-center" style="padding:7px">
                        <q-icon name="error_outline" color="red" size="1.7em"></q-icon>
                        <span> 중복된 내용이 존재합니다.</span>
                    </div>
                    <br/>
                    <div class="text-center">
                        <span>사용할 내용을 선택해주세요. 나머지 내용은 삭제됩니다.</span>
                    </div>
                </q-card-section>       
                <q-card-section v-if="checkList.amData.length > 1">
                    <q-markup-table class="table-align" style="width:100%">
                        <thead>
                        <tr style="height:40px" class="bg-grey-4">
                            <th class="text-center" style="font-weight: bold; font-size: 13px;width:5%">선택</th>
                            <th class="text-left" style="font-weight: bold; font-size: 13px;width:95%">오전장 테마 동향</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(a, i) in checkList.amData" :key="i">
                            <td v-if="a.title != null && a.title.trim() != ''" class="text-center" >
                                <q-checkbox v-model="a.checked" size="sm" @input="handleCheckBox(a)" />
                            </td>
                            <td v-else class="text-left"></td>
                            <td class="text-left"><p style="height:100%;margin:0px" v-html="a.content"/></td>
                        </tr>
                        </tbody>
                    </q-markup-table>
                </q-card-section>
                <q-card-section v-if="checkList.pmData.length > 1">
                    <q-markup-table class="table-align" style="width:100%">
                        <thead>
                        <tr style="height:40px" class="bg-grey-4">
                            <th class="text-center" style="font-weight: bold; font-size: 13px;width:5%">선택</th>
                            <th class="text-left" style="font-weight: bold; font-size: 13px;width:95%">오후장 테마 동향</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(a, i) in checkList.pmData" :key="i">
                            <td v-if="a.title != null && a.title.trim() != ''" class="text-center" >
                                <q-checkbox v-model="a.checked" size="sm" @input="handleCheckBox(a)" />
                            </td>
                            <td v-else class="text-left" ></td>
                            <td class="text-left"><p style="height:100%;margin:0px" v-html="a.content"/></td>
                        </tr>
                        </tbody>
                    </q-markup-table>
                </q-card-section>
                <q-card-actions align="center">
                    <q-btn flat color="primary" label="저장" @click="handleSave()" />
                    <q-btn flat color="primary" label="닫기" @click="handleClose()" />
                </q-card-actions>
            </q-card>
        </q-dialog>
        </div>
        <!-- ----- popup end ----- -->
    </article>
</template>
<script>
import Debug from '@/debug'
import { morning, isEmpty, today } from '@/util'

import Button from '@/components/common/Button'
import Select from '@/components/common/Select'
import Input from '@/components/common/Input'
import OrderTable from '@/components/common/OrderTable'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Content',
    components: {
        'i-btn' :  Button,
        'i-select': Select,
        'i-input': Input,
        'i-otable': OrderTable,
    },
    props: {
    },
    data () {
        return {
            ordering: false,
            createTime: '',
            writer: '',
            type: null,
            rows:5,
            strong: {
                id: null,
                content: ''
            },
            weak:{
                id: null,
                content: ''
            },
            daily: {
                title:'',
                content:''
            },
            oriContent: null,
            amContent: null,
            oriList:[],
            items:[],
            selected:[],
            selection: 'multiple',
            options:[
                    {label: '오전장', themeType: 'THEME_FEATURED_AM', marketType:'MARKET_THEME_TRAND_AM', flashType: 'MARKET_FLASH_THEME_AM'},
                    {label: '오후장', themeType: 'THEME_FEATURED_PM', marketType:'MARKET_THEME_TRAND_PM', flashType: 'MARKET_FLASH_THEME_PM'}
                ],
            columns: [
                {
                    name: "title",
                    label: "테마명",
                    align: "left",
                    field: (row) => row.themeName
                },
                {
                    name: "summary",
                    label: "이슈요약",
                    align: "left",
                    field: (row) => row.title
                }
            ],
            isInit: false,
            // 팝업에서 쓰이는 리스트
            checkList : {
                amData : [],
                pmData : [],
            },
            dialog : false // 팝업 상태
        }
    },
    created(){
        //오전 오늘 확인
        this.type = this.options[morning()]

        this.getFeaturedList()
        this.getTrendNews()

        //테마 동향 작성한게 있는지 확인하기!
        //있으면 수정!
        //MARKET_THEME_TRAND_AM
        if(this.type !== null &&this.type.themeType === 'THEME_FEATURED_PM') {
            //오후일때는 오전 가져오기!
            this.getAMTrendNews()
        }
        this.$parent.$on('sendResource', this.addResource)

        this.requestResource(0) // 22.09.06 suye test
    },
    computed : {
        ...mapState ({
            PAGE_STATUS : state => state.page.status,
            pageStatus : state => state.page.pageStatus,
        }),
        ...mapGetters( 'page', ['isEditPage', 'isRegisterPage']),
        hasNews() {
            Debug.log('hasNews' , (this.oriContent !== null))
            return (this.oriContent === null)
        },
        regTitle() {
            return (this.oriContent !== null?'수정':'등록')
        }
    },
    methods: {
        ...mapMutations({
            setPageStatus: 'page/setPageStatus',
            REQUEST_RESOURCE: 'resource/REQUEST_RESOURCE',
            SET_PREVIEW:'flash/SET_PREVIEW'
        }),
        ...mapActions('theme_featured', ['GET_FEATURED_THEME_LIST', 'FEAURED_ORDER', 'GET_MARKET_NEWS']),
        ...mapActions( 'alert', ['showError', 'showMessage', 'showAlert']),

        ...mapActions('market_news', ['WRITE_ITEM', 'DELETE_NEWS']),
        ...mapActions( 'flash', ['WRITE_FLASH']),

        getSelected(selected) {
            this.selected = selected
        },
        initSelected(val) {
            this.isInit = val
        },
        // 220923 meej 팝업 열기
        handleOpen(){
            this.dialog = true
        },
        // 220923 meej 팝업 체크리스트
        handleCheckBox(item){
            if(item.checked){
                if(item.newsType1 == "MARKET_THEME_TRAND_AM"){
                    this.checkList.amData.forEach((row)=>{
                        if(row.pid != item.pid) row.checked = false
                    })
                }else{
                    this.checkList.pmData.forEach((row)=>{
                        if(row.pid != item.pid) row.checked = false
                    })
                }
            }else{
                if(item.newsType1 == "MARKET_THEME_TRAND_AM"){
                    this.checkList.amData.forEach((row)=>{
                        if(row.pid == item.pid) row.checked = true
                    })
                }else{
                    this.checkList.pmData.forEach((row)=>{
                        if(row.pid == item.pid) row.checked = true
                    })
                }
            }
        },
        // 오전장 테마동향 가져오기
        getAMTrendNews() {
            this.GET_MARKET_NEWS({newsType: this.options[0].marketType})
                .then((data)=>{
                    this.amContent = '오전장★ 테마동향'
                    for(let i = 0; i < data.items.length; i++) {
                        let item = data.items[i]
                        if(item.id === item.pid) {
                            this.amContent += '<br/>강세테마 : '+item.content
                        } else {
                            this.amContent += '<br/>약세테마 : '+item.content
                        }
                    }
                    // 220923 meej 중복입력있을 경우 checkList에 담기
                    if(data.items.length > 2){
                        this.checkList.amData = []
                        data.items.forEach((row, i)=>{
                            if(row.pid === row.id ){
                                if(i==0) row.checked = true
                                else row.checked = false
                                this.checkList.amData.push(row)
                            } else {
                                this.checkList.amData.forEach((el)=>{
                                    if(el.pid == row.pid) el.content = "강세테마 :"+el.content + "<br/>약세테마: "+row.content
                                })
                            }
                        })
                    }
                })
                .catch((err)=> {
                    this.showError({vc:this, error:err})
                })
        },
        // 220923 meej 팝업 저장
        handleSave() {
            let deleteList = []
            // 체크 안되어 있는 항목 삭제 처리
            this.checkList.amData.forEach((row)=>{
                if(!row.checked){
                    deleteList.push(row.pid)
                }
            })
            this.checkList.pmData.forEach((row)=>{
                if(!row.checked){
                    deleteList.push(row.pid)
                }
            })
            const param = {
                pidList : deleteList
            }
            // 삭제하기
            this.DELETE_NEWS(param)
            .then(()=>{
                // 삭제 후 처리
                this.dialog = false;
                this.checkList= {
                    amData : [],
                    pmData : [],
                }
                this.getTrendNews()
                if(this.type !== null &&this.type.themeType === 'THEME_FEATURED_PM') {
                    //오후일때는 오전 가져오기!
                    this.getAMTrendNews()
                }
            })
            .catch((err)=>{
                this.showError({vc:this, error:err})
            })
        },
        // 220923 meej 팝업 close 
        handleClose() {
            this.dialog = false;
        },
        // 테마동향 / 데일리 테마 조회
        getTrendNews() {
            Debug.log('getTrendNews')
            let params ={
                newsType: this.type.marketType
            }
            this.GET_MARKET_NEWS(params)
                .then((data)=>{
                    if(data.items.length > 0) {
                        /* 
                            220322 meej 테마동향 제목 추가
                            220331 meej 테마동향 제목 수정 : ★ 추가
                        */
                        if(this.type.themeType === 'THEME_FEATURED_AM'){
                            this.oriContent = '오전장★ 테마동향' 
                        }else{
                            this.oriContent = '오후장★ 테마동향'
                        }
                        for(let i = 0; i < data.items.length; i++) {
                            let item = data.items[i]
                            if(item.id === item.pid) {
                                this.strong.id = item.id
                                this.strong.content = item.content
                                this.oriContent += '<br/>강세테마 : '+item.content
                            } else {
                                this.weak.id = item.id
                                this.weak.content = item.content
                                this.oriContent += '<br/>약세테마 : '+item.content
                            }
                        }
                        // 220923 meej 중복입력있을 경우 checkList에 담기
                        if(this.type.themeType === 'THEME_FEATURED_AM'){
                            if(data.items.length > 2){
                                this.checkList.amData = []
                                data.items.forEach((row, i)=>{
                                    if(i==0) row.checked = true
                                    else row.checked = false
                                    if(row.pid === row.id ){
                                        this.checkList.amData.push(row)
                                    } else {
                                        this.checkList.amData.forEach((el)=>{
                                            if(el.pid == row.pid) el.content = "강세테마 :"+el.content + "<br/>약세테마: "+row.content
                                        })
                                    }
                                })
                            }
                        }else{
                            if(data.items.length > 2){
                                this.checkList.pmData = []
                                data.items.forEach((row, i)=>{
                                    if(i == 0) row.checked = true
                                    else row.checked = false
                                    if(row.pid === row.id ){
                                        this.checkList.pmData.push(row)
                                    } else {
                                        this.checkList.pmData.forEach((el)=>{
                                            if(el.pid == row.pid) el.content = "강세테마 :"+el.content + "<br/>약세테마: "+row.content
                                        })
                                    }
                                })
                            }
                        }

                        this.setPageStatus(this.PAGE_STATUS.EDIT)
                    }
                })
                .catch((err)=>{
                    this.showError({vc:this, error:err})
                })
        },
        // 초기화
        init() {
            this.strong= {
                id: null,
                content: ''
            }
            this.weak = {
                id: null,
                content: ''
            }
            this.oriContent = null
            this.amContent = null
            this.dialog = false;
            this.checkList= {
                amData : [],
                pmData : [],
            }
        },
        // 타입 선택
        selectType(){
            this.init()
            this.getFeaturedList()
            this.getTrendNews()
            if(this.type !== null && this.type.themeType === 'THEME_FEATURED_PM') {
                //오후일때는 오전 가져오기!
                this.getAMTrendNews()
            }
        },
        // 특징 테마 조회
        getFeaturedList() {
            let params = {
                newsType: this.type.themeType,
                date: today()
            }
            this.GET_FEATURED_THEME_LIST(params)
                .then((data)=>{
                    this.items = data.items;
                    //인덱스 추가
                    this.items.forEach((row, index) => {
                        row.index = index
                        row.checked = false
                    })
                    this.initOrder()
                })
                .catch((err)=>{
                    this.showError({vc:this, error:err})
                })
        },
        // 순서 조정
        initOrder() {
            /* 
                220504 meej 테마동향 순서변경 오류 수정
             */
            this.items.sort(function (a, b) {
                return a.ZINDEX - b.ZINDEX
            })
            /* this.items.sort(function (a, b) {
                if (a.ZINDEX > b.ZINDEX) {
                    return 1
                }
                if (a.ZINDEX < b.ZINDEX) {
                    return -1
                }
                return 0
            } */
        },
        handleOrderList(list) {
            /* 
                220504 meej 테마동향 순서 변경 오류 수정, params를 여기서 set해주고 넘김
             */
            let params = {}
            let orderList = []
            for(let i = 0; i < list.length; i++) {
                let item = list[i]
                let order = {
                    newsType:item.newsType1,
                    id: item.id,
                    index : ''+i,
                }
                orderList.push(order)
            }
            params.items = orderList
            params.newsType = this.type.themeType
            this.showAlert({vc:this,message:'순서를 변경하시겠습니까?'})
                .then(()=>{
                    this.$emit("event-order-featured", params)
                    this.ordering = !this.ordering
                })
                .catch(()=>{
                    this.ordering = !this.ordering
                })
        },
        /* 
            220504 meej ThemeTrend.vue에서 처리, 주석처리
        */
        /* saveOrder() {
            let orderList = []
            for(let i = 0; i < this.items.length; i++) {
                const item = this.items[i]
                const orderItem = {
                    index: ''+i,
                    id: item.id,
                    check: item.check,
                    newsType:item.newsType1
                }
                orderList.push(orderItem)
            }

            const params = {
                newsType:this.type.marketType,
                items: orderList
            }

            this.FEAURED_ORDER(params)
                .then(()=> {
                    this.showMessage({vc:this, message:'완료되었습니다'})
                })
                .catch((err)=>{
                    this.showError({vc:this, error:err})
                })
        }, */
        processDailyFeatured() {
            this.items.forEach((row) => {
                row.check = '0'
                var item = this.selected.find(v => v.id === row.id)
                if (item != null) {
                    item.check = '1'
                }
                row.checked = false
            })
            this.saveOrder()
        },
        // 테마동향 파라미터
        makeTrandParam() {
            let weakItem = {
                newsType: this.type.marketType,
                content:this.weak.content,
            }
            if(!isEmpty(this.weak.id)){
                weakItem.id = this.weak.id
            }
            let params = {
                newsType: this.type.marketType,
                content: this.strong.content,
                items: [weakItem]
            }
            if(!isEmpty(this.strong.id)) {
                params.id = this.strong.id
            }
            return params
        },
        // 등록
        write() {
            if(this.checkList.amData.length > 1 || this.checkList.pmData.length > 1){
                this.showError({vc:this, error:"중복된 내용이 존재합니다. 아래 버튼을 클릭하여 내용을 선택해주세요."})
                return
            }
            this.showAlert({
                vc: this,
                message:'해당 내용을 등록하시겠습니까?'
                })
                .then(()=> {
                    this.doWrite()
                })
        },
        doWrite() {
            let params = this.makeTrandParam()

            this.WRITE_ITEM(params)
                .then(() => {
                    // 테마 동향
                    if(this.isEditPage){
                        this.showMessage({ vc:this,message:"수정되었습니다." })
                    } else {
                        this.showMessage({ vc:this,message:"등록되었습니다." })
                    }

                    //테마 동향 작성한게 있는지 확인하기!
                    //있으면 수정!
                    //MARKET_THEME_TRAND_AM
                    if(this.type !== null && this.type.themeType === 'THEME_FEATURED_PM') {
                        //오후일때는 오전 가져오기!
                        this.getAMTrendNews()
                    }

                    this.getTrendNews()
            })
            .catch((error) => {
                // 220916 meej 중복 입력 오류일 경우 에레메시지 변경
                if(error == "이미 등록한 데이터가 존재합니다."){
                    error += " 기존 내용을 조회합니다."
                }
                this.showError({ vc:this, error:error })
                // Debug.log('error = ', error)
                // 220916 meej 오류시 재조회 추가
                if(this.type !== null && this.type.themeType === 'THEME_FEATURED_PM') {
                    //오후일때는 오전 가져오기!
                    this.getAMTrendNews()
                }
                this.getTrendNews()
            })
        },
        send() {
        },
        requestResource(index) {
            let req = {menu:'Theme', index:index}
            this.$nextTick(function () {
                    this.REQUEST_RESOURCE(req)
                })
        },
        addResource(res) {
            Debug.log('addResource res = ', res)
            const count = res.items.length
            let tempTitle = ''
            for(let i = 0; i < count; i++) {
                let theme = res.items[i] 
                // 22.09.06 suye 테마 맨앞 공백 제거 & 다중선택시에도 띄어쓰기 
                tempTitle += ' ' + theme.name +',' 
                tempTitle = tempTitle.trim()
            }
            if(res.index === 0) {
                this.strong.content += (this.strong.content.length > 0?', ':' ') + tempTitle.slice(0, -1)
                this.strong.content = this.strong.content.trim() // 22.09.07 suye 테마 맨앞 공백 제거
            } else {
                this.weak.content += (this.weak.content.length > 0?', ':' ')+tempTitle.slice(0, -1)
                this.weak.content = this.weak.content.trim() // 22.09.07 suye 테마 맨앞 공백 제거
            }

        },
        makeFlash() {
            if(this.checkList.amData.length > 1 || this.checkList.pmData.length > 1){
                this.showError({vc:this, error:"중복된 내용이 존재합니다. 아래 버튼을 클릭하여 내용을 선택해주세요."})
                return
            }
            let params = {}
            params.newsType = this.type.flashType
            this.WRITE_FLASH(params)
            .then((res) => {
                Debug.log('data = ',res)
                this.showPreview();
            })
            .catch((err) => {
                Debug.log('error = ', err)
                this.showError({vc:this, error:err})
            })

        },
        showPreview() {
            this.windowRef = window.open(
                `/preview/popup.htm`,
                '시황속보 미리보기',
                'width=620,height=820'
            )
            if(this.windowRef) {
                this.windowRef.addEventListener('beforeunload', this.closePreview)
            }
        },
    },
    watch: {
    }
}
</script>